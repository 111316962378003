import React from "react";

function JobsIcon() {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="80" height="80" rx="40" fill="#28A0E8" />
      <path
        d="M47.1667 55V28.3333C47.1667 27.4493 46.8155 26.6014 46.1904 25.9763C45.5653 25.3512 44.7174 25 43.8334 25H37.1667C36.2827 25 35.4348 25.3512 34.8097 25.9763C34.1846 26.6014 33.8334 27.4493 33.8334 28.3333V55M27.1667 31.6667H53.8334C55.6743 31.6667 57.1667 33.1591 57.1667 35V51.6667C57.1667 53.5076 55.6743 55 53.8334 55H27.1667C25.3258 55 23.8334 53.5076 23.8334 51.6667V35C23.8334 33.1591 25.3258 31.6667 27.1667 31.6667Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default JobsIcon;
