import React from "react";

function OfficeIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25 3.55556V4.30556H8H15.25V15.25H0.75V0.75H7.25V3.55556ZM3.2 14.9722H3.95V14.2222V12.4444V11.6944H3.2H1.6H0.85V12.4444V14.2222V14.9722H1.6H3.2ZM3.2 11.4167H3.95V10.6667V8.88889V8.13889H3.2H1.6H0.85V8.88889V10.6667V11.4167H1.6H3.2ZM3.2 7.86111H3.95V7.11111V5.33333V4.58333H3.2H1.6H0.85V5.33333V7.11111V7.86111H1.6H3.2ZM3.2 4.30556H3.95V3.55556V1.77778V1.02778H3.2H1.6H0.85V1.77778V3.55556V4.30556H1.6H3.2ZM6.4 14.9722H7.15V14.2222V12.4444V11.6944H6.4H4.8H4.05V12.4444V14.2222V14.9722H4.8H6.4ZM6.4 11.4167H7.15V10.6667V8.88889V8.13889H6.4H4.8H4.05V8.88889V10.6667V11.4167H4.8H6.4ZM6.4 7.86111H7.15V7.11111V5.33333V4.58333H6.4H4.8H4.05V5.33333V7.11111V7.86111H4.8H6.4ZM6.4 4.30556H7.15V3.55556V1.77778V1.02778H6.4H4.8H4.05V1.77778V3.55556V4.30556H4.8H6.4ZM14.4 14.9722H15.15V14.2222V5.33333V4.58333H14.4H8H7.25V5.33333V7.11111V7.86111H8H8.85V8.13889H8H7.25V8.88889V10.6667V11.4167H8H8.85V11.6944H8H7.25V12.4444V14.2222V14.9722H8H14.4ZM11.95 8.13889V7.86111H12.05V8.13889H11.95ZM11.95 11.6944V11.4167H12.05V11.6944H11.95Z"
        fill="#384250"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default OfficeIcon;
