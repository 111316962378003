import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { MY_JOBS } from '@/rtk-apis/end-points';

const myJobsList = createAsyncThunk('myJobsList', async (body) => {
  try {
    const response = await httpService.post(MY_JOBS, body);
    return response?.data;
  } catch (error) {
    return error;
  }
});

export default myJobsList;
