import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { DELETE_CANDIDATE } from '@/rtk-apis/end-points';

const deleteCandidate = createAsyncThunk('deleteCandidate', async (body) => {
  try {
    const response = await httpService.put(DELETE_CANDIDATE, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default deleteCandidate;
