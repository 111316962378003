import React from "react";

const MicrosoftSvg = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 1H1V10H10V1Z" fill="#F25022" />
      <path d="M10 11H1V20H10V11Z" fill="#00A4EF" />
      <path d="M20 1H11V10H20V1Z" fill="#7FBA00" />
      <path d="M20 11H11V20H20V11Z" fill="#FFB900" />
    </svg>
  );
};
export default MicrosoftSvg;
