import React, { useEffect, useState } from "react";
import CloseIconGray from "@/assets/svgs/CloseIconGray";
import ArrowDown from "@/assets/svgs/ArrowDown";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import SearchSmallIcon from "@/assets/svgs/SearchSmallIcon";
import CloseIcon from "@/assets/svgs/CloseIcon";
import { modules, formats, editorStyle } from "../../utils/constants";

const AddNotesModal = ({
  openModal,
  closeModal,
  setNotesTitle,
  NotesTitle,
  shareWithArray,
  setNotesText,
  NotesText,
  handleOnAddEditNotes,
  selectedShareWithArray,
  setSelectedShareWith,
  EditNotesMode,
  jobData,
}) => {
  const handleTitleChange = (e) => {
    setNotesTitle(e?.target?.value);
  };

  const handleTextAreaChange = (value) => {
    if (value === "<p><br></p>") {
      setNotesText("");
    } else {
      setNotesText(value);
    }
  };

  const filteredShareWithArray = shareWithArray?.filter((item) => {
    const addedByIds = new Set(jobData?.notes?.map((note) => note.addedBy));
    return !addedByIds.has(item.id);
  });

  const optionsWithSelectAll = filteredShareWithArray?.length
    ? [{ id: "selectAll", value: "Select All" }, ...filteredShareWithArray]
    : [];
  const handleOptionChange = (event, newValue) => {
    if (newValue.some((item) => item.id === "selectAll")) {
      // "Select All" was selected
      if (selectedShareWithArray.length === filteredShareWithArray.length) {
        // If everything is already selected, deselect all
        setSelectedShareWith([]);
      } else {
        // Otherwise, select all
        setSelectedShareWith(filteredShareWithArray);
      }
    } else {
      setSelectedShareWith(newValue);
    }
  };

  return (
    <>
      <div
        className={`modal modalPrompt modalForm ${
          openModal ? "modal--open" : ""
        }`}
      >
        <div className="modal__dialog ">
          <div className="modal__content">
            <div className="modal__header">
              <h2 className="modal__header--title">
                {EditNotesMode ? "Update Note" : "New Note"}
              </h2>
              <button
                onClick={closeModal}
                className="btn btn__transparent btnClose"
              >
                <CloseIcon />
              </button>
            </div>
            <div className="modal__body modal-body-left">
              {/* SHARE WITH */}
              <div className="formField">
                <label className="formField__label">Share With</label>
                {/* <FormControl sx={{ width: "100%" }}>
                  <Select
                    id="multiple-checkbox"
                    multiple
                    value={selectedShareWithArray ?? []}
                    onChange={(e) => {
                      let originalArr = e?.target?.value;
                      let arr = e?.target?.value;
                      if (arr?.length) {
                        let selectedArray = [];
                        const lastItem = arr[arr.length - 1];
                        const checkArr = arr.slice(0, arr.length - 1);
                        selectedArray = checkArr?.length
                          ? checkArr.filter((item) => item?.id == lastItem.id)
                          : [];
                        if (selectedArray?.length) {
                          let RequiredArr = checkArr.filter(
                            (item) => item.id !== lastItem.id
                          );
                          setSelectedShareWith(RequiredArr);
                        } else {
                          setSelectedShareWith(originalArr);
                        }
                      }
                    }}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <span style={{ opacity: "0.8" }}>Select</span>;
                      } else {
                        const selectedNames = selectedShareWithArray
                          ?.map((item) => item?.value)
                          .join(", ");
                        return selectedNames;
                      }
                    }}
                  >
                    {filteredShareWithArray?.length &&
                      filteredShareWithArray?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            <Checkbox
                              checked={
                                selectedShareWithArray?.filter(
                                  (row) => row.id == item.id
                                )?.length >= 1
                              }
                            />
                            <ListItemText primary={item?.value} />
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl> */}
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={optionsWithSelectAll || []}
                  value={selectedShareWithArray}
                  name="shareWith"
                  onChange={handleOptionChange}
                  getOptionLabel={(option) => option?.value}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              option.id === "selectAll"
                                ? selectedShareWithArray.length ===
                                  filteredShareWithArray.length
                                : selectedShareWithArray.includes(option)
                            }
                          />
                        }
                        label={option.value}
                        onClick={() => {
                          if (option.id === "selectAll") {
                            // If "Select All" is clicked, handle all selection
                            if (
                              selectedShareWithArray.length ===
                              filteredShareWithArray.length
                            ) {
                              setSelectedShareWith([]);
                            } else {
                              setSelectedShareWith(filteredShareWithArray);
                            }
                          } else {
                            // Otherwise, handle individual selection
                            const currentIndex = selectedShareWithArray.indexOf(
                              option
                            );
                            const newChecked = [...selectedShareWithArray];

                            if (currentIndex === -1) {
                              newChecked.push(option);
                            } else {
                              newChecked.splice(currentIndex, 1);
                            }
                            setSelectedShareWith(newChecked);
                          }
                        }}
                      />
                    </li>
                  )}
                  renderInput={(params) => (
                    <div className="autocompleteSearchfield">
                      <TextField
                        {...params}
                        variant="filled"
                        placeholder="Search a Name"
                        className="autoCompleteInput"
                      />
                    </div>
                  )}
                />
              </div>
              {/* SUBJECT */}
              <div className="formField">
                <label className="formField__label">Subject</label>
                <input
                  type="text"
                  value={NotesTitle}
                  onChange={(e) => handleTitleChange(e)}
                  className="formField__input sm"
                  maxLength={30}
                  placeholder="Enter your subject"
                />
              </div>
              {/* NOTE */}
              <div className="formField">
                <label className="formField__label">Note</label>
                {/* <textarea
                  type="text"
                  rows={6}
                  className="formField__textarea"
                  maxLength={500}
                  value={NotesText}
                  onChange={(e) => handleTextAreaChange(e)}
                  placeholder="Enter your note here..."
                ></textarea> */}
                <ReactQuill
                  name="notesText"
                  style={editorStyle}
                  value={NotesText}
                  modules={modules}
                  formats={formats}
                  placeholder="Enter your note here..."
                  onChange={(newValue) => handleTextAreaChange(newValue)}
                />
              </div>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button
                  className="btn btn__primary"
                  onClick={handleOnAddEditNotes}
                  disabled={
                    !NotesTitle || !NotesText || !selectedShareWithArray?.length
                  }
                >
                  {EditNotesMode ? "Update" : "Add"}
                </button>
                <button className="btn btn__white" onClick={closeModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default AddNotesModal;
