import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CUSTOMER_GET_SEARCH_FIELD } from '@/rtk-apis/end-points';

const getSearchField = createAsyncThunk('getSearchField', async (body) => {
  try {
    const response = await httpService.post(CUSTOMER_GET_SEARCH_FIELD, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default getSearchField;
