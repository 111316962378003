import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { GET_STATE_CITY_DATA } from '@/rtk-apis/end-points';

const getStateCityData = createAsyncThunk(
  'getStateCityData',
  async (body) => {
    try {
      const response = await httpService.post(GET_STATE_CITY_DATA, body);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default getStateCityData;
