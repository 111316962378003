import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import BackArrow from '@/assets/svgs/BackArrow';
import ModalSuccessIcon from '@/assets/svgs/ModalSuccessIcon';
import { useDispatch } from 'react-redux';
import billingConfirmPayment from '@/rtk-apis/customer/billingAndPayments/billingConfirmPayment';

const BillingThankyouPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const BackToHomePage = () => {
    navigate('/customer-dashboard');
  };

  const confirmPayment = () => {
    dispatch(billingConfirmPayment(searchParams.get('session_id')));
  };

  useEffect(() => {
    confirmPayment();
  }, []);

  return (
    <>
      <div className="container__fullScreen bg--light">
        <div className={'modal modalSuccessfull modal--open'}>
          <div className="modal__dialog">
            <div className="modal__content">
              <div className="modal__header">
                <div className="modal__header--logo">
                  <ModalSuccessIcon />
                </div>
                <h2 className="modal__header--title">Thank You</h2>
              </div>
              <div className="modal__body">
                <h4 className="promptMessage">Payment done successfully.</h4>
              </div>
              <div className="modal__footer">
                <div className="btn__group">
                  <button
                    className="btn btn__white"
                    onClick={() => BackToHomePage()}
                  >
                    <BackArrow />
                    back to home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingThankyouPage;
