import { createSlice } from '@reduxjs/toolkit';
import jobAcceptDecline from '@/rtk-apis/jobRequests/jobAcceptDecline';

const initialState = {
  message: '',
  data: {},
  meta: {},
  loading: false,
  error: ''
};

const jobAcceptDeclineSlice = createSlice({
  name: 'jobAcceptDecline',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(jobAcceptDecline.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(jobAcceptDecline.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(jobAcceptDecline.rejected, (state, action) => {
        state.loading = false;
      });
  }

});

export default jobAcceptDeclineSlice.reducer;

export const { closeRtkModal } = jobAcceptDeclineSlice.actions;
