import { GetUserDetails } from '@/utils/helper';

const onRequest = (config) => {
  const user = GetUserDetails();
  const SessionId = user?.sessionid ?? '';
  if (SessionId) {
    config.headers.sessionId = SessionId;
    config.headers.role = user?.customer?.role ? user?.customer?.role : user?.usertype;
  }
  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = async (response) => {
  return response?.data;
};

const onResponseError = async (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    const condition = window.navigator.onLine ? 'online' : 'offline';
    if (
      condition === 'offline' &&
      error?.code &&
      error?.code === 'ERR_NETWORK'
    ) {
      console.log('Oops!', 'No Internet Connectivity!', 'error');
    } else {
      console.log('Oops!', 'Something went wrong!', 'error');
    }
  }
  if (error.response) {
    // Access Token was expired
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
