import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PlayIcon from "@/assets/svgs/PlayIcon";
import { RecordingModal } from "@/components/modals/recording-modal";
import fetchRecording from "@/rtk-apis/interviews/fetchRecording";
import {
  formatPhoneNumber,
  firstLetterCapital,
  getUTCRecordingTimeSlot,
} from "@/utils/helper";
import moment from "moment";
import Loader from "@/elements/Loader";

function RecordingInterviews({
  selectedInterview,
  selectedCandidate,
  hideDetail,
}) {
  const dispatch = useDispatch();
  const [openRecordingModal, setOpenRecordingModal] = useState(false);
  const [recordingLink, setRecordingLink] = useState("");

  const { data, loading } = useSelector((globalState) => {
    return globalState.fetchRecordingResponse;
  });

  useEffect(() => {
    getCandidateRecordingListAPIData();
  }, []);

  const getCandidateRecordingListAPIData = () => {
    dispatch(
      fetchRecording({
        interviewId: selectedInterview.interviewId,
      })
    );
  };

  const viewRecording = (recordingLink) => {
    setOpenRecordingModal(true);
    setRecordingLink(recordingLink);
  };

  return (
    <>
      <div className="dataCol">
        <div className="page page__full">
          <div className="page__body bg--white">
            <div className="dataCol__head head--inner head--responsive">
              <div className="item">
                <div className="initials initials__lg">
                  {firstLetterCapital(selectedCandidate?.firstName[0])}
                </div>
                <div className="item__info">
                  <h4 className="item__info--title">
                    {firstLetterCapital(selectedCandidate?.firstName) +
                      " " +
                      firstLetterCapital(selectedCandidate?.lastName)}
                  </h4>
                  <div className="details__row">
                    <div className="details__row--item">
                      <span>Phone:</span>
                      {formatPhoneNumber(selectedCandidate?.phoneNumber)}
                    </div>
                    <div className="details__row--item">
                      <span>Email:</span>
                      {selectedCandidate?.email}
                    </div>
                  </div>
                </div>
              </div>
              <div className="dataCol__head--actions">
                <button
                  onClick={() => hideDetail()}
                  className="btn btn__white w_fitContent"
                >
                  Back
                </button>
              </div>
            </div>

            <div className="details">
              <h4 className="details__section--title">Interview Recordings</h4>
              <div className="tabContainer">
                <TableContainer
                  component={Paper}
                  className="tableContainer p_0"
                >
                  <Table className="table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    {data?.recordings?.length ? (
                      <TableBody>
                        {data?.recordings?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell>
                              <div className="cellText">
                                <span className="cellText__dark">
                                  {moment(row?.createdAt).format(
                                    "D MMM , YYYY"
                                  )}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="cellText">
                                <span className="cellText__dark">
                                  {getUTCRecordingTimeSlot(
                                    row?.createdAt,
                                    row?.timeSlot
                                  )}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell align="right">
                              <div className="actions flex--end">
                                <button
                                  className="btn btn__white"
                                  onClick={() => viewRecording(row?.link)}
                                >
                                  View Rec.
                                  <PlayIcon />
                                </button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <div className="no-data floating">No Results Found!</div>
                    )}
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RecordingModal
        openModal={openRecordingModal}
        closeModal={() => setOpenRecordingModal(false)}
        recordingLink={recordingLink}
      />
      {loading && <Loader />}
    </>
  );
}

export default RecordingInterviews;
