import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import LinkIcon from "@/assets/svgs/LinkIcon";
import candidateDetail from "@/rtk-apis/customer/myJobs/candidates/getCandidate";
import { firstLetterCapital, formatPhoneNumber } from "@/utils/helper";

function CandidatesDetails({ candidateData }) {
  const dispatch = useDispatch();
  const { candidateId } = candidateData;
  const { data } = useSelector((globalState) => {
    return globalState.candidateDetail;
  });

  useEffect(() => {
    getCandidateListAPIData();
  }, []);

  const getCandidateListAPIData = () => {
    dispatch(candidateDetail(candidateId));
  };
  return (
    <>
      <div className="details bg--light">
        <h4 className="details__section--title">Candidate Details</h4>
        <section className="details__section">
          <h4 className="details__section--title">Basic Details</h4>
          <div className="fieldBox flexRow">
            <div className="flexCol">
              <label className="flexCol__label">First Name</label>
              <div className="flexCol__text">
                {data?.firstName ? firstLetterCapital(data?.firstName) : "--"}
              </div>
            </div>
            <div className="flexCol">
              <label className="flexCol__label">Last Name</label>
              <div className="flexCol__text">
                {data?.lastName ? firstLetterCapital(data?.lastName) : "--"}
              </div>
            </div>
          </div>
          <div className="fieldBox flexRow">
            <div className="flexCol">
              <label className="flexCol__label">Email Id</label>
              <div className="flexCol__text">
                {data?.email ? firstLetterCapital(data?.email) : "--"}
              </div>
            </div>
            <div className="flexCol">
              <label className="flexCol__label">Phone Number</label>
              <div className="flexCol__text">
                {data?.phoneNumber
                  ? "+1 " + formatPhoneNumber(data?.phoneNumber)
                  : "--"}
              </div>
            </div>
          </div>
        </section>

        <section className="details__section">
          <h4 className="details__section--title">Educational Details</h4>
          <div className="fieldBox flexRow">
            <div className="flexCol">
              <label className="flexCol__label">Attach Resume</label>
              <Link
                to={data?.resumeUrl ? data?.resumeUrl : "#"}
                className="flexCol__text text--primary"
                target="_blank"
              >
                {data?.resumeUrl ? data?.resumeUrl : "--"}{" "}
                {data?.resumeUrl && <LinkIcon />}
              </Link>
            </div>
            <div className="flexCol">
              <label className="flexCol__label">Highest Education</label>
              <div className="flexCol__text">
                {data?.education ? data?.education : "--"}
              </div>
            </div>
          </div>
          <div className="fieldBox flexRow">
            <div className="flexCol">
              <label className="flexCol__label">Name of Institution</label>
              <div className="flexCol__text">
                {data?.institutionName ? data?.institutionName : "--"}
              </div>
            </div>
            <div className="flexCol">
              <label className="flexCol__label">Graduation Year</label>
              <div className="flexCol__text">
                {data?.graduationYear ? data?.graduationYear : "--"}
              </div>
            </div>
          </div>
        </section>

        <section className="details__section">
          <h4 className="details__section--title">Professional Details</h4>
          <div className="fieldBox flexRow">
            <div className="flexCol">
              <label className="flexCol__label">Experience</label>
              <div className="flexCol__text">
                {data?.experience
                  ? data?.experience <= 1
                    ? data?.experience + " Year"
                    : data?.experience + " Years"
                  : "--"}
              </div>
            </div>
            <div className="flexCol">
              <label className="flexCol__label">LinkedIn Account</label>
              <Link
                to={data?.linkedin ? data?.linkedin : "#"}
                className="flexCol__text text--primary"
                target="_blank"
              >
                {data?.linkedin ? data?.linkedin : "--"}{" "}
                {data?.linkedin && <LinkIcon />}{" "}
              </Link>
            </div>
          </div>
          <div className="fieldBox flexRow">
            <div className="flexCol">
              <label className="flexCol__label">Role</label>
              <div className="flexCol__text">
                {data?.role ? data?.role : "--"}
              </div>
            </div>
          </div>
          <div className="fieldBox">
            <label className="flexCol__label">Skill set</label>
            <div className="flexCol__text">
              {data?.skillset ? data?.skillset?.join(", ") : "--"}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default CandidatesDetails;
