import { createSlice } from "@reduxjs/toolkit";
import createInterview from "@/rtk-apis/interviews/createInterview";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const createInterviewSlice = createSlice({
  name: "createInterview",
  initialState,
  reducers: {
    removeNylusData(state) {
      state.data = {};
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createInterview.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createInterview.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(createInterview.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default createInterviewSlice.reducer;

export const { removeNylusData } = createInterviewSlice.actions;
