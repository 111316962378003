import React, { useRef, useState, useEffect } from "react";
import CopyIcon from "@/assets/svgs/CopyIcon";
import {
  firstLetterCapital,
  formatPhoneNumber,
  getInitial,
} from "@/utils/helper";
import Dot from "@/assets/svgs/Dot";
import { INSTITUTION_LOGO_BUCKET } from "@/utils/constants";
import InstitutionLogo from "@/assets/images/university.svg";
import WishIcon from "@/assets/svgs/WishIcon";
import GiveFeedbackModal from "@/components/modals/GiveFeedbackModal";
import Rating from "@mui/material/Rating";
import StarIcon from "@/assets/svgs/StarIcon";
import { useDispatch, useSelector } from "react-redux";
import getRecruiterDetails from "@/rtk-apis/customer/myJobs/getRecruiterDetails";
import Loader from "@/elements/Loader";
import moment from "moment";
import { GetUserDetails } from "@/utils/helper";
import { checkForAdminAndHiringLead } from "@/utils/helper";

function AssignedRecruiterDetails({
  recruiterDetails,
  setOpenRecruiterDetailPage,
  setRecruiterDetailPageCheck,
  selectedJob,
}) {
  const dispatch = useDispatch();
  const loggedInUser = GetUserDetails();
  const role = loggedInUser?.customer?.role;
  const [OpenReviewRatingsModal, setOpenReviewRatingsModal] = useState(false);
  const [refreshRecruiterDetails, setRefreshRecruiterDetails] = useState(false);
  const [showFullNotes, setShowFullNotes] = useState({});
  const [ReviewRatingDetails, setReviewRatingDetails] = useState([]);
  const [ViewReviewRating, setViewReviewRating] = useState(false);
  const copyLinkedinText = useRef(null);

  const { data, loading } = useSelector((globalState) => {
    return globalState.getRecruiterDetailsResponse;
  });

  useEffect(() => {
    if (data?.ratings?.length) {
      const Details = data?.ratings?.filter(
        (item) =>
          item?.jobId === selectedJob?.jobId &&
          item?.recruiterId === recruiterDetails?.recruiterId &&
          item?.companyId === loggedInUser?.customer?.company?.id
      );
      if (Details?.length > 0) {
        setReviewRatingDetails(Details);
        setViewReviewRating(true);
      }
    }
  }, [data]);

  useEffect(() => {
    getRecruiterData(recruiterDetails?.recruiterId);
  }, [refreshRecruiterDetails]);

  function copyToClipboard() {
    copyLinkedinText.current.select();
    document.execCommand("copy");
  }
  function toTitleCase(text) {
    return text.replace(/\w\S*/g, function(word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  }

  const getGraduationYear = (qualification) => {
    const gradYear = `${toTitleCase(qualification?.startMonth)} ${
      qualification?.startYear
    } - ${toTitleCase(qualification?.endMonth)} ${qualification?.endYear}`;
    return gradYear;
  };

  const getRecruiterData = async (recruiterid) => {
    await dispatch(getRecruiterDetails(recruiterid));
  };

  const toggleReadMore = (index) => {
    setShowFullNotes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const filteredRatings = data?.ratings?.filter((rating) => !rating?.hide);

  return (
    <>
      <div className="details bg--light">
        <section className="details__section">
          <h4 className="details__section--title">
            Recruiter Details
            <div className="bntActions">
              {checkForAdminAndHiringLead(selectedJob, role) && (
                <button
                  className="btn btn__primary"
                  onClick={() => {
                    setOpenReviewRatingsModal(true);
                  }}
                >
                  {ViewReviewRating
                    ? "Edit Review / Rating"
                    : "Give Review / Rating"}
                </button>
              )}
              <button
                className="btn btn__white btn__back"
                onClick={() => {
                  setOpenRecruiterDetailPage(false);
                  setRecruiterDetailPageCheck({ recruiterPage: false });
                }}
              >
                Back
              </button>
            </div>
          </h4>
          <div className="initials initials__lg">
            {getInitial(data?.firstName)}
          </div>
          <div className="details__section--name details__section--name--assignRecruiter d-flex item-align">
            {firstLetterCapital(data?.firstName)} {""}
            {firstLetterCapital(data?.lastName)}
            {filteredRatings?.length ? (
              <div className="reviewCountInfo">
                <span className="reviewCountInfo__totalRating">
                  {data?.ratingCount?.toFixed(1)} Rating
                </span>
                <StarIcon /> <span>{data?.reviewCount} Reviews</span>
              </div>
            ) : (
              ""
            )}
            {data?.deleteStatus && (
              <div className="red-bg-50 red-text-500 alert-tag ml16">
                Account Deactivated
              </div>
            )}
          </div>
          <div className="details__row">
            <div className="details__row--item">
              <span>Phone:</span>
              {data?.phoneNumber
                ? "+1 " + formatPhoneNumber(data?.phoneNumber)
                : "--"}
            </div>
            <div className="details__row--item">
              <span>Email:</span>
              {data?.email ? data?.email : "--"}
            </div>
          </div>
          <ul className="tags__list">
            {data?.experience && (
              <li className="tags__list--item">
                {data?.experience &&
                  (data?.experience <= 1
                    ? data?.experience + " year experience"
                    : data?.experience + " years experience")}
              </li>
            )}
            {data?.assignedJobs && (
              <li className="tags__list--item">
                {data?.assignedJobs + " Assigned Jobs"}
              </li>
            )}
            {data?.acceptedJobs && (
              <li className="tags__list--item">
                {data?.acceptedJobs} Accepted Jobs
              </li>
            )}
          </ul>
        </section>

        <section className="details__section">
          <h4 className="details__section--title">Professional Details</h4>
          <div className="fieldBox">
            <label className="flexCol__label">Recruiting Field</label>
            <ul className="tags__list m_0">
              {data?.skillSet?.map((skillName) => {
                return <li className="tags__list--item">{skillName}</li>;
              })}
            </ul>
          </div>
          <div className="fieldBox m_0">
            <label className="flexCol__label">LinkedIn Account</label>
            <div className="formField__group sm">
              <input
                type="text"
                className="formField__input"
                placeHolder="Linkedin"
                ref={copyLinkedinText}
                disabled={data?.linkedin ? false : true}
                value={data?.linkedin ? data?.linkedin : ""}
                readOnly
              />
              <span
                className={`formField__icon cursor--pointer ${
                  data?.linkedin ? "" : "iconDisabled"
                }`}
                onClick={copyToClipboard}
              >
                <CopyIcon />
              </span>
            </div>
          </div>
        </section>

        <section className="details__section">
          <h4 className="details__section--title">Educational Details</h4>
          <div className="fieldBox flexRow w__100">
            {data?.qualificationArray?.map((qualification, index) => {
              return (
                <div className="flexCol">
                  <div className="educationInfobox">
                    {qualification?.logoName || InstitutionLogo ? (
                      <div className="educationInfobox__educationLogo">
                        <img
                          src={
                            qualification?.logoName
                              ? INSTITUTION_LOGO_BUCKET +
                                qualification?.logoName
                              : InstitutionLogo
                          }
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="initials initials__lg">
                        {qualification?.institutionName
                          ?.charAt(0)
                          .toUpperCase()}
                      </div>
                    )}

                    <div className="educationInfobox__detail">
                      <h3 className="title">
                        {qualification?.institutionName}
                      </h3>
                      <div className="subtitle">
                        <span>{qualification?.degree}</span>
                        <span>
                          <Dot />
                          {qualification?.fieldOfStudy}
                        </span>
                      </div>
                      <div className="subtitle">
                        <span>{getGraduationYear(qualification)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>

        {filteredRatings?.length ? (
          <section className="details__section">
            <h4 className="details__section--title">Reviews and Ratings</h4>
            <div className="reviewSec">
              <h5 className="viewAllReview">All Reviews</h5>
              <div className="reviews">
                {data?.ratings
                  ?.filter((rating) => !rating?.hide)
                  .map((rating, index) => {
                    const isLongNote = rating?.notes?.length > 250;
                    const shouldShowFullNote = showFullNotes[index];
                    const displayedNote =
                      isLongNote && !shouldShowFullNote
                        ? `${rating?.notes.substring(0, 250)}...`
                        : `${rating?.notes} `;
                    return (
                      <div className="reviews__row" key={index}>
                        <div className="reviewDetails">
                          <div
                            className={`pic ${
                              rating?.company?.companyLogo ? "" : "pic__rounded"
                            }`}
                          >
                            {rating?.company?.companyLogo ? (
                              <>
                                <img
                                  className="pic__img"
                                  src={rating?.company?.companyLogo}
                                  alt=""
                                />
                                <span className="wishIcon">
                                  <WishIcon />
                                </span>
                              </>
                            ) : (
                              <span className="initials">
                                {getInitial(rating?.company?.companyName)}
                              </span>
                            )}
                          </div>
                          <div className="details details--widthAuto">
                            <h5 className="userName">
                              <span>
                                {rating?.company?.companyName}{" "}
                                <span className="reviewDate">
                                  {moment(rating?.updatedAt).format(
                                    "D MMM, YYYY"
                                  )}
                                </span>
                              </span>
                            </h5>
                            <div className="ratingsInfo">
                              <span className="ratings">
                                <Rating
                                  precision={0.5}
                                  name="read-only"
                                  value={rating?.averageRating}
                                  readOnly
                                  sx={{
                                    "& .MuiRating-iconFilled": {
                                      color: "#28A0E8",
                                    },
                                  }}
                                />
                              </span>
                              {rating?.averageRating} Rating
                            </div>
                            <p className="recrutingCunsultationText">
                              <strong>Recruiting:</strong> {rating?.job?.title}
                            </p>
                          </div>
                        </div>
                        <p>
                          {displayedNote}
                          {isLongNote && (
                            <button
                              onClick={() => toggleReadMore(index)}
                              className="readMoreButton"
                            >
                              {shouldShowFullNote ? "Read Less" : "Read More"}
                            </button>
                          )}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </div>
      {OpenReviewRatingsModal && (
        <GiveFeedbackModal
          selectedJob={selectedJob}
          recruiterDetails={data}
          closeModal={() => setOpenReviewRatingsModal(false)}
          setOpenRecruiterDetailPage={setOpenRecruiterDetailPage}
          setRefreshRecruiterDetails={setRefreshRecruiterDetails}
          refreshRecruiterDetails={refreshRecruiterDetails}
          setOpenReviewRatingsModal={setOpenReviewRatingsModal}
          ViewReviewRating={ViewReviewRating}
          ReviewRatingDetails={ReviewRatingDetails}
        />
      )}
      {loading && <Loader />}
    </>
  );
}

export default AssignedRecruiterDetails;
