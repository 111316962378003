import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { ADD_NPS_RATING } from "@/rtk-apis/end-points";

const addNPSRating = createAsyncThunk("addNPSRating", async (body) => {
  try {
    const response = await httpService.put(ADD_NPS_RATING, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default addNPSRating;
