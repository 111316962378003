import React, { useState } from "react";
import ArrowDownBlue from "@/assets/svgs/ArrowUpBlue";
import { Link } from "react-router-dom";
import LinkedinIcon from "@/assets/svgs/LinkedinIcon";
import ArrowLeft from "@/assets/svgs/ArrowLeft";
import jobAcceptDecline from "@/rtk-apis/jobRequests/jobAcceptDecline";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import DefaultImg from "@/assets/images/default-img.png";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import Loader from "@/elements/Loader";
import jobRequestsList from "@/rtk-apis/jobRequests/jobRequestsList";
import { closeRtkModal } from "@/store/slices/jobAcceptDeclineSlice";
import { SuccessModal } from "@/components/modals/SuccessModal";
import { GetUserDetails, formattedDate } from "@/utils/helper";
import { Tooltip } from "@mui/material";
import { firstLetterCapital } from "@/utils/helper";
import DetailModal from "@/components/modals/DetailModal";
import NotificationButton from "../../components/elements/notificationButton";

function JobRequestDetails({ selectedJobRequest, removeResponsiveClass }) {
  const user = GetUserDetails();
  const dispatch = useDispatch();

  const [acceptMessage, setAcceptMessage] = useState(true);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [OpenNotificationModal, setOpenNotificationModal] = useState(false);

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [jtmDetails, setjtmDetails] = useState({});

  const { meta, loading } = useSelector((globalState) => {
    return globalState.jobAcceptDecline;
  });

  const getCompensationValue = (job) => {
    if (job?.baseSalary === "Range") {
      return `$ ${job?.minCompensation} - $ ${job?.maxCompensation} ${job?.frequency}`;
    } else if (job?.baseSalary === "Starting at") {
      return `$ ${job?.minCompensation} ${job?.frequency}`;
    } else if (job?.baseSalary === "Up to") {
      return `$ ${job?.maxCompensation} ${job?.frequency}`;
    } else if (job?.baseSalary === "Exact Pay") {
      return `$ ${job?.maxCompensation} ${job?.frequency}`;
    } else {
      return `${"--"}`;
    }
  };

  const valuesArray = [
    selectedJobRequest?.company?.address,
    selectedJobRequest?.company?.city,
    selectedJobRequest?.company?.state,
    selectedJobRequest?.company?.zipcode,
    selectedJobRequest?.company?.country,
  ].filter((value) => value);
  const formattedAddress = valuesArray.join(", ");

  const jobAcceptReject = () => {
    dispatch(
      jobAcceptDecline({
        jobId: selectedJobRequest?.jobId,
        recruiterId: user.recruiter.recruiterId,
        status: acceptMessage ? "accepted" : "rejected",
      })
    );
    setOpenConfirmationModal(false);
  };

  const openModal = (value) => {
    if (value === "accepted") {
      setAcceptMessage(true);
    } else {
      setAcceptMessage(false);
    }
    setOpenConfirmationModal(true);
  };

  const closeModal = () => {
    dispatch(
      jobRequestsList({
        recruiterId: user.recruiter.recruiterId,
        page: 1,
        limit: 20,
        status: null,
        search: "",
      })
    );
    dispatch(closeRtkModal());
  };

  return (
    <>
      {selectedJobRequest?.title ? (
        <div className="dataCol">
          <div className="page page__full">
            <div className="page__head">
              <div className="page__head--title">
                <button
                  onClick={() => removeResponsiveClass()}
                  className="btn btn__transparent btn__back border_0 p_0"
                >
                  <ArrowLeft />
                </button>
                {selectedJobRequest?.title}
              </div>
              <div className="listCol__head--actions ml_auto">
                <button
                  onClick={() => openModal("rejected")}
                  className={` btn btn__white w_fitContent ${
                    selectedJobRequest?.status === "Closed" ||
                    selectedJobRequest?.status === "paused"
                      ? "disabled"
                      : ""
                  }`}
                >
                  Reject
                </button>
                <button
                  onClick={() => openModal("accepted")}
                  className={` btn btn__primary w_fitContent ${
                    selectedJobRequest?.status === "Closed" ||
                    selectedJobRequest?.status === "paused"
                      ? "disabled"
                      : ""
                  }`}
                >
                  Accept
                </button>
                <NotificationButton
                  OpenNotificationModal={OpenNotificationModal}
                  setOpenNotificationModal={setOpenNotificationModal}
                />
              </div>
            </div>
            <div className="page__body">
              <div className="details">
                <div className="details__section">
                  <h3 className="details__section--title">Job Details</h3>
                  <div className="jobsProfile">
                    <div className="jobsProfile__profile">
                      <div className="pic">
                        <img
                          src={
                            selectedJobRequest?.company?.companyLogo
                              ? selectedJobRequest?.company?.companyLogo
                              : DefaultImg
                          }
                          alt=""
                        />
                      </div>
                      <div className="profileDetail">
                        <h4>{selectedJobRequest?.company?.companyName}</h4>
                        <p>{selectedJobRequest?.company?.industryType}</p>
                        <p>{formattedAddress}</p>
                      </div>
                    </div>
                    {selectedJobRequest?.linkedin ||
                      (selectedJobRequest?.company?.status === "delete" && (
                        <div className="jobsProfile__actions">
                          {selectedJobRequest?.company?.status === "delete" && (
                            <div
                              className="red-bg-50 red-text-500 alert-tag ml16"
                              style={{ fontSize: "13px" }}
                            >
                              Account Deactivated
                            </div>
                          )}
                          {selectedJobRequest?.linkedin && (
                            <Tooltip title="LinkedIn" placement="top" arrow>
                              <Link
                                target={"_blank"}
                                to={selectedJobRequest?.linkedin}
                                className="btn btn__primary"
                              >
                                <LinkedinIcon />
                              </Link>
                            </Tooltip>
                          )}
                        </div>
                      ))}
                  </div>
                  <div className="fieldBox flexRow">
                    <div className="flexCol">
                      <label className="flexCol__label">Department</label>
                      <div className="flexCol__text">
                        {selectedJobRequest?.department}
                      </div>
                    </div>
                    <div className="flexCol">
                      <label className="flexCol__label">Location</label>
                      <div className="flexCol__text">
                        {selectedJobRequest?.location}
                      </div>
                    </div>
                  </div>
                  <div className="fieldBox flexRow">
                    <div className="flexCol">
                      <label className="flexCol__label">Employment Type</label>
                      <div className="flexCol__text">
                        {selectedJobRequest?.employmentType.join(", ")}
                      </div>
                    </div>
                    <div className="flexCol">
                      <label className="flexCol__label">Base Salary</label>
                      <div className="flexCol__text">
                        {getCompensationValue(selectedJobRequest)}
                      </div>
                    </div>
                  </div>
                  <div className="fieldBox flexRow">
                    <div className="flexCol">
                      <label className="flexCol__label">Experience</label>
                      {selectedJobRequest?.minExperience ? (
                        <div className="flexCol__text">
                          {selectedJobRequest?.minExperience <= 1
                            ? selectedJobRequest?.minExperience + " Year"
                            : selectedJobRequest?.minExperience + " Years"}{" "}
                          -{" "}
                          {selectedJobRequest?.maxExperience <= 1
                            ? selectedJobRequest?.maxExperience + " Year"
                            : selectedJobRequest?.maxExperience + " Years"}
                        </div>
                      ) : (
                        "--"
                      )}
                    </div>
                    <div className="flexCol">
                      <label className="flexCol__label">
                        Application Deadline
                      </label>
                      <div className="flexCol__text">
                        {formattedDate(
                          moment(selectedJobRequest?.updatedAt).add(2, "days")
                        ) || "--"}
                      </div>
                    </div>
                  </div>
                  <div className="fieldBox flexRow">
                    <div className="flexCol">
                      <label className="flexCol__label">No of Hires</label>
                      <div className="flexCol__text">
                        {selectedJobRequest?.noOfHires}
                      </div>
                    </div>
                    <div className="flexCol">
                      <label className="flexCol__label">
                        Required Qualification
                      </label>
                      <div className="flexCol__text">
                        {selectedJobRequest?.qualification
                          ? selectedJobRequest?.qualification.join(", ")
                          : "--"}
                      </div>
                    </div>
                  </div>

                  <div className="fieldBox flexRow">
                    <div className="flexCol">
                      <label className="flexCol__label">Job Team Member</label>
                      {selectedJobRequest?.jobTeamMember?.map((jtm, index) => {
                        return (
                          <React.Fragment key={index}>
                            <Link
                              className="link link__primary"
                              onClick={(e) => {
                                setOpenDetailModal(true);
                                setjtmDetails(jtm);
                              }}
                            >
                              {firstLetterCapital(jtm?.firstName) +
                                " " +
                                firstLetterCapital(jtm?.lastName)}
                            </Link>
                            {index <
                              selectedJobRequest.jobTeamMember.length - 1 &&
                              ", "}
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <div className="flexCol">
                      <label className="flexCol__label">Hiring Lead</label>
                      <Link
                        className="link link__primary"
                        onClick={(e) => {
                          setOpenDetailModal(true);
                          setjtmDetails(selectedJobRequest?.hiringLead[0]);
                        }}
                      >
                        {firstLetterCapital(
                          selectedJobRequest?.hiringLead[0]?.firstName
                        ) +
                          " " +
                          firstLetterCapital(
                            selectedJobRequest?.hiringLead[0]?.lastName
                          )}
                      </Link>
                    </div>
                  </div>

                  <div className="fieldBox flexRow">
                    <div className="flexCol">
                      <label className="flexCol__label">
                        Company's Website
                      </label>
                      <div className="flexCol__text">
                        {selectedJobRequest?.company?.websiteLink ? (
                          <Link
                            target="_blank"
                            className="link link__primary link__center"
                            to={selectedJobRequest?.company?.websiteLink}
                          >
                            {selectedJobRequest?.company?.websiteLink}
                            {<ArrowDownBlue />}
                          </Link>
                        ) : (
                          "--"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="fieldBox ">
                    <label className="flexCol__label">Skill set</label>
                    <div className="flexCol__text">
                      {selectedJobRequest?.skillSet
                        ? selectedJobRequest?.skillSet.join(", ")
                        : "--"}
                    </div>
                  </div>
                  <div className="fieldBox">
                    <label className="flexCol__label">Job Description</label>
                    <div className="flexCol__text">
                      <div
                        className="showQuillTags"
                        dangerouslySetInnerHTML={{
                          __html: selectedJobRequest?.description
                            ? selectedJobRequest?.description
                            : "--",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-data">No Results Found!</div>
      )}
      {loading && <Loader />}
      {meta?.status && (
        <SuccessModal
          sucessfullMsg={meta.msg}
          Heading={acceptMessage ? "Job accepted!" : "Job rejected!"}
          closeModal={closeModal}
        />
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          closeModal={() => setOpenConfirmationModal(false)}
          onSubmit={jobAcceptReject}
          heading={acceptMessage ? "Accept Job!" : "Reject Job!"}
          confirmationMessage={
            acceptMessage
              ? `Are you sure you want to accept this job for the ${selectedJobRequest?.company?.companyName}?`
              : `Are you sure you want to reject this job for the ${selectedJobRequest?.company?.companyName}?`
          }
        />
      )}
      <DetailModal
        openModal={openDetailModal}
        closeModal={() => setOpenDetailModal(false)}
        data={jtmDetails}
      />
    </>
  );
}

export default JobRequestDetails;
