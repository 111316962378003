import React from 'react';

function EditIconNew() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2879_9938)">
        <path
          d="M7.55551 14.7721H14.6666M11.1111 1.73506C11.4254 1.42073 11.8517 1.24414 12.2962 1.24414C12.5164 1.24414 12.7343 1.28749 12.9377 1.37173C13.141 1.45596 13.3258 1.57942 13.4814 1.73506C13.6371 1.8907 13.7605 2.07547 13.8448 2.27883C13.929 2.48218 13.9724 2.70014 13.9724 2.92025C13.9724 3.14036 13.929 3.35831 13.8448 3.56166C13.7605 3.76502 13.6371 3.94979 13.4814 4.10543L3.60489 13.982L0.444397 14.7721L1.23452 11.6116L11.1111 1.73506Z"
          stroke="#150823"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2879_9938">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EditIconNew;
