import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { ADD_REVIEW_RATINGS_FOR_RECRUITER_BY_CUSTOMER } from "@/rtk-apis/end-points";

const addReviewsRatingsToRecruiter = createAsyncThunk(
  "addReviewsRatingsToRecruiter",
  async (body) => {
    try {
      const response = await httpService.post(
        ADD_REVIEW_RATINGS_FOR_RECRUITER_BY_CUSTOMER,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default addReviewsRatingsToRecruiter;
