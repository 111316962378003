import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { GET_COMPANY_TAGS } from "@/rtk-apis/end-points";

const getCustomerAllTags = createAsyncThunk(
  "getCustomerAllTags",
  async (body) => {
    try {
      const response = await httpService.post(GET_COMPANY_TAGS, body);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default getCustomerAllTags;
