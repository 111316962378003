import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BankingImg from "@/assets/images/banking-icon.png";
import { decryptData, GetUserDetails } from "@/utils/helper";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import VerifyACHPaymentModal from "@/components/modals/VerifyACHPaymentModal";
import removeACHDebitDetails from "@/rtk-apis/customer/payments/removeACHDebitDetails";
import setPrimaryDebitMethod from "@/rtk-apis/customer/payments/setPrimaryDebitMethod";
import { closeRtkModal } from "@/store/slices/customer/payments/removeACHDebitDetailsSlice";
import { closeRtkModal as closePrimaryRtkModal } from "@/store/slices/customer/payments/setPrimaryDebitMethodSlice";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import Loader from "@/elements/Loader";

const PaymentMethodsList = ({
  PaymentMethodList,
  PrimaryPaymentMethod,
  openACHDetailModal,
  openPaymentModal,
  refreshPage,
}) => {
  const dispatch = useDispatch();
  const user = GetUserDetails();
  const [openRemoveConf, setOpenRemoveConf] = useState(false);
  const [methodId, setMethodId] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState(false);
  const [primaryMethodDetails, setPrimaryMethodDetails] = useState({});
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationHeading, setConfirmationHeading] = useState("");
  const [confirmationModalType, setConfirmationModalType] = useState("");
  const [openVerifyDataModal, setOpenVerifyDataModal] = useState(false);
  const [verifyPaymentMethodId, setVerifyPaymentMethodId] = useState("");

  const removeACHDebitDetailsData = useSelector((globalState) => {
    return globalState.removeACHDebitDetailsRes;
  });

  const setPrimaryDebitMethodData = useSelector((globalState) => {
    return globalState.setPrimaryDebitMethod;
  });

  const removeDebitACHMethod = () => {
    dispatch(
      removeACHDebitDetails({
        methodId: methodId,
        companyId: user?.customer?.companyId,
        paymentMethodId: paymentMethodId,
      })
    );
    setOpenRemoveConf(false);
  };

  const openRemoveConfirmation = (item) => {
    setMethodId(item.id);
    setPaymentMethodId(item?.stripeDetails?.id);
    setConfirmationModalType("Remove");
    setConfirmationHeading("Remove Payment Method!");
    setConfirmationMessage("Are you sure you want remove payment method?");
    setOpenRemoveConf(true);
  };

  const closeModal = () => {
    setOpenRemoveConf(false);
    if (confirmationModalType === "Remove") {
      dispatch(closeRtkModal());
    } else {
      dispatch(closePrimaryRtkModal());
    }
    refreshPage();
  };

  const openSetPrimaryConfirmation = (item) => {
    setPrimaryMethodDetails({
      methodId: item?.id,
      paymentMethodId: item?.stripeDetails?.id,
    });
    setConfirmationModalType("Primary");
    setConfirmationHeading("Set Primary Method!");
    setConfirmationMessage(
      "Are you sure you want to make this method primary?"
    );
    setOpenRemoveConf(true);
  };

  const addPrimaryDebitMethod = () => {
    const obj = {
      methodId: primaryMethodDetails?.methodId,
      paymentMethodId: primaryMethodDetails?.paymentMethodId,
      companyId: user?.customer?.companyId,
    };
    dispatch(setPrimaryDebitMethod(obj));
    setOpenRemoveConf(false);
  };

  const openVerifyModal = (item) => {
    setVerifyPaymentMethodId(item?.id);
    setOpenVerifyDataModal(true);
  }

  const closeVerifyModal = () => {
    setOpenVerifyDataModal(false);
  }

  return (
    <>
      {PaymentMethodList?.length || PrimaryPaymentMethod?.length ? (
        <section className="details__section">
          {PrimaryPaymentMethod?.length ? (
            <>
              <h4 className="details__section--title">
                Payment methods
                <button
                  className="btn btn__primary btn__outlined"
                  onClick={() => openPaymentModal()}
                >
                  Add a method
                </button>
              </h4>
              <h4 className="details__section--title primaryAccount">
                Primary Account
                <button className="btn btn__primary btn__outlined">
                  Preferred
                </button>
              </h4>
              <div className="paymentlist">
                <div className="paymentlist__item">
                  <div className="infoBox">
                    <div className="infoBox__icon">
                      <img
                        className="infoBox__icon__img"
                        src={BankingImg}
                        alt="Bank Img"
                      />
                    </div>
                    <div className="infoBox__detail">
                      {PrimaryPaymentMethod[0]["methodType"] === "Card" ? (
                        <h5>
                          Credit card ending in{" "}
                          {`${decryptData(
                            PrimaryPaymentMethod[0]["cardNumber"]
                          ).slice(-4)}`}
                        </h5>
                      ) : (
                        <h5>
                          Bank account ending in{" "}
                          {`${decryptData(
                            PrimaryPaymentMethod[0]["accountNumber"]
                          ).slice(-4)}`}{" "}
                        </h5>
                      )}
                      <p className="greenText">Active</p>
                    </div>
                  </div>
                  {/* <div className="bntActions">
                    <button className="btnEdit" type="button" onClick={() => openACHDetailModal(PrimaryPaymentMethod[0])}>
                      Edit
                    </button>
                    <button className="btnRemove" type="button" onClick={() => openRemoveConfirmation(PrimaryPaymentMethod[0]["id"])}>
                      Remove
                    </button>
                  </div> */}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {PaymentMethodList?.length ? (
            <h4 className="details__section--title primaryAccount">
              Additional Account
            </h4>
          ) : (
            ""
          )}
          <div className="paymentlist">
            {PaymentMethodList?.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            )?.map((item, index) => (
              <div className="paymentlist__item" key={index}>
                <div className="infoBox">
                  <div className="infoBox__icon">
                    <img
                      className="infoBox__icon__img"
                      src={BankingImg}
                      alt="Bank Img"
                    />
                  </div>
                  <div className="infoBox__detail">
                    {item["methodType"] === "ACH" ? (
                      <h5>
                        Bank account ending in{" "}
                        {`${decryptData(item["accountNumber"])?.slice(-4)}`}
                      </h5>
                    ) : (
                      <h5>
                        Credit Card ending in{" "}
                        {`${decryptData(item["cardNumber"])?.slice(-4)}`}
                      </h5>
                    )}
                    <p className="greenText">Active</p>
                  </div>
                </div>
                <div className="bntActions">
                  
                  {/* <button
                    className="btnEdit"
                    type="button"
                    onClick={() => openACHDetailModal(item)}
                  >
                    Edit
                  </button> */}
                  {item["methodType"] === "ACH" && item["verify"] === false ? (
                    <button
                      className="btnSetAsPrimary"
                      type="button"
                      onClick={() => openVerifyModal(item)}
                    >
                      Verify
                    </button>
                  ) : (
                    <button
                      className="btnSetAsPrimary"
                      type="button"
                      onClick={() => openSetPrimaryConfirmation(item)}
                    >
                      Set as primary
                    </button>
                  )}
                  <button
                    className="btnRemove"
                    type="button"
                    onClick={() => openRemoveConfirmation(item)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
          {(removeACHDebitDetailsData?.loading ||
            setPrimaryDebitMethodData?.loading) && <Loader />}
          {openRemoveConf && (
            <ConfirmationModal
              closeModal={() => setOpenRemoveConf(false)}
              onSubmit={
                confirmationModalType === "Remove"
                  ? removeDebitACHMethod
                  : addPrimaryDebitMethod
              }
              heading={confirmationHeading}
              confirmationMessage={confirmationMessage}
            />
          )}
          {(removeACHDebitDetailsData?.meta?.status ||
            setPrimaryDebitMethodData?.meta?.status) && (
            <SuccessModal
              closeModal={closeModal}
              sucessfullMsg={
                removeACHDebitDetailsData?.meta?.msg ||
                setPrimaryDebitMethodData?.meta?.msg
              }
              Heading={
                confirmationModalType === "Remove"
                  ? "Payment Details!"
                  : "Primary Details Added!"
              }
            />
          )}
          {(removeACHDebitDetailsData?.meta?.status === false ||
            setPrimaryDebitMethodData?.meta?.status === false) && (
            <ErrorModal
              errorMessage={
                removeACHDebitDetailsData?.meta?.msg ||
                setPrimaryDebitMethodData?.meta?.msg
              }
              closeModal={closeModal}
              footerVisible={true}
            />
          )}
          {openVerifyDataModal && <VerifyACHPaymentModal
            openModal={openVerifyDataModal}
            closeModal={closeVerifyModal}
            refreshPage={refreshPage}
            paymentMethodId={verifyPaymentMethodId}
          />}
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default PaymentMethodsList;
