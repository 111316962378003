import { createSlice } from "@reduxjs/toolkit";
import addTagsToJob from "@/rtk-apis/customer/tags/jobTags/addTagsToJob";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const addTagsToJobSlice = createSlice({
  name: "addTagsToJobSlice",
  initialState,
  reducers: {
    closeJobTagModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTagsToJob.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addTagsToJob.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(addTagsToJob.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default addTagsToJobSlice.reducer;

export const { closeJobTagModal } = addTagsToJobSlice.actions;
