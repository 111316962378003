import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { PAUSE_A_JOB } from "@/rtk-apis/end-points";

const PauseAJobPost = createAsyncThunk("PauseAJobPost", async (body) => {
  try {
    const response = await httpService.put(PAUSE_A_JOB, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default PauseAJobPost;
