import { createSlice } from "@reduxjs/toolkit";
import candidateSignUpStepTwo from "../../../rtk-apis/candidate-rtk-apis/candidateSignUpStepTwo";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const candidateSignUpStepTwoSlice = createSlice({
  name: "candidateSignUpStepTwoSlice",
  initialState,
  reducers: {
    closeCandidateStepTwoModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(candidateSignUpStepTwo.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(candidateSignUpStepTwo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(candidateSignUpStepTwo.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default candidateSignUpStepTwoSlice.reducer;

export const {
  closeCandidateStepTwoModal,
} = candidateSignUpStepTwoSlice.actions;
