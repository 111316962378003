import getCustomerNotes from "@/rtk-apis/customer/myJobs/notes/getCustomerNotes";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const getCustomerNotesSlice = createSlice({
  name: "getCustomerNotesSlice",
  initialState,
  reducers: {
    closeGetNoteModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerNotes.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCustomerNotes.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(getCustomerNotes.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default getCustomerNotesSlice.reducer;

export const { closeGetNoteModal } = getCustomerNotesSlice.actions;
