import React from "react";

function BilledAmountIcon() {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="80" height="80" rx="40" fill="#150823" />
      <path
        d="M38.7639 17.083V20.7739C33.8404 21.614 30.0833 25.9866 30.0833 31.1856C30.0833 34.3187 31.5278 36.7357 33.447 38.2919C35.3662 39.8482 37.6788 40.7158 39.8489 41.5972C42.0191 42.4786 44.0468 43.3738 45.3828 44.4618C46.7188 45.5498 47.4444 46.6516 47.4444 48.8138C47.4444 52.6149 44.3316 55.8651 40.5 55.8651C36.648 55.8651 33.5555 52.725 33.5555 48.8138V47.051H30.0833V48.8138C30.0833 54.0403 33.8404 58.3853 38.7639 59.2254V62.9163H42.2361V59.2254C47.1732 58.3785 50.9166 53.9714 50.9166 48.8138C50.9166 45.6738 49.4722 43.2705 47.5529 41.7074C45.6337 40.1443 43.3212 39.2284 41.151 38.347C38.9809 37.4656 36.9532 36.6186 35.6172 35.5375C34.2812 34.4564 33.5555 33.334 33.5555 31.1856C33.5555 27.3225 36.6548 24.1343 40.5 24.1343C44.352 24.1343 47.4444 27.2743 47.4444 31.1856V32.9484H50.9166V31.1856C50.9166 25.9591 47.1596 21.614 42.2361 20.7739V17.083H38.7639Z"
        fill="white"
      />
    </svg>
  );
}

export default BilledAmountIcon;
