import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIconGray from "@/assets/svgs/CloseIconGray";
import {
  getMonthLastWednesday,
  decryptData,
  isNumber,
  GetUserDetails,
} from "@/utils/helper";
import { Formik, Form, Field } from "formik";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDown from "@/assets/svgs/ArrowDown";
import MenuItem from "@mui/material/MenuItem";
import { WITHDRAWL_AMOUNT } from "@/utils/constants";
import { WithdrawlScheduleSchema } from "@/components/schema/schema";
import addWithdrawlDetails from "@/rtk-apis/payments/addWithdrawlDetails";
import { closeRtkModal } from "@/store/slices/payments/addWithdrawlDetailsSlice";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import { EmailRegex } from "@/utils/regex";

const SetupWithdrawalScheduleModal = ({
  openModal,
  closeModal,
  refreshPage,
  withdrawlMethods = [],
  defaultWithdrawalSchedule,
}) => {
  const userDetails = GetUserDetails();
  const dispatch = useDispatch();

  const getDefaultWithdrawlSchedule = (defaultWithdrawalData) => {
    let withdrawalScheduleMethodId = "";
    if(defaultWithdrawalData?.["methodDetail"]?.["methodType"] === "Paypal"){
      withdrawalScheduleMethodId = defaultWithdrawalData["methodDetail"]["email"]
    }
    else{
      withdrawalScheduleMethodId = defaultWithdrawalData?.["methodDetail"]?.["accountNumber"]
        ? decryptData(defaultWithdrawalData["methodDetail"]["accountNumber"])
        : "";
    }
    return withdrawalScheduleMethodId;
  }

  let initialValues = {
    scheduleOption: "Monthly",
    withdrawalAmount: defaultWithdrawalSchedule?.withdrawalAmount ? defaultWithdrawalSchedule?.withdrawalAmount : 1000,
    withdrawalScheduleMethod: getDefaultWithdrawlSchedule(defaultWithdrawalSchedule)
  };

  const closeScheduleModal = () => {
    closeModal();
    refreshPage();
  };

  const addWithdrawlData = useSelector((globalState) => {
    return globalState.addWithdrawlDetails;
  });

  const getMethodId = (acntNo) => {
    if(EmailRegex.test(acntNo)){
      let data = withdrawlMethods.filter(
        (item) => item.email === acntNo
      );
      return data?.[0]?.["id"];
    }
    else{
      let data = withdrawlMethods.filter(
        (item) => item.accountNumber === acntNo
      );
      return data?.[0]?.["id"];
    }
  };

  const handleSubmit = (values) => { 
    values.recruiterId = userDetails?.recruiter?.recruiterId;
    if (values?.withdrawalScheduleMethod) {
      values.withdrawalMethodId = getMethodId(values?.withdrawalScheduleMethod);
    }
    dispatch(addWithdrawlDetails(values));
  };

  const closeInfoModal = () => {
    dispatch(closeRtkModal());
    closeModal();
    refreshPage();
  };

  return (
    <>
      <div
        className={`modal modalPaymentWithdrawal ${
          openModal ? "modal--open" : ""
        }`}
      >
        <div className="modal__dialog">
          <Formik
            initialValues={initialValues}
            validationSchema={WithdrawlScheduleSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              touched,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form>
                <div className="modal__content modal__content--xxxl">
                  <div className="modal__header">
                    <h2 className="modal__header--title">
                      Setup withdrawal schedule{" "}
                      <span className="modal__header--title__steptext">
                        (Step 2 of 2)
                      </span>
                    </h2>
                    <button
                      className="btn btn__transparent btnClose"
                      onClick={() => closeScheduleModal()}
                    >
                      <CloseIconGray />
                    </button>
                  </div>
                  <div className="modal__body modal-body-left">
                    <div className="setupWithdrawalScheduleDetial">
                      <div className="fieldRow">
                        {withdrawlMethods?.length > 1 ? (
                          <div className="fieldRow_col">
                            <h4>Preferred Withdrawal Method</h4>
                            <div className="formField">
                              <FormControl
                                sx={{ width: "100%" }}
                                size="small"
                                className="formField__select"
                              >
                                <Select
                                  IconComponent={() => <ArrowDown />}
                                  name="withdrawalScheduleMethod"
                                  displayEmpty
                                  value={values?.withdrawalScheduleMethod}
                                  onChange={handleChange}
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return <span>Select</span>;
                                    }
                                    else{ 
                                      if(EmailRegex.test(selected)){
                                        return `Paypal - ${selected}`;
                                      }
                                      else{
                                        return `Bank account ending with ${selected.slice(-4)}`;
                                      }
                                    }
                                  }}
                                >
                                  {withdrawlMethods?.map((item) => {
                                    if (item?.methodType === "Paypal") {
                                      return (
                                        <MenuItem
                                          key={item["email"]}
                                          value={item["email"]}
                                        >
                                          {`Paypal - ${item["email"]}`}
                                        </MenuItem>
                                      );
                                    } else {
                                      return (
                                        <MenuItem
                                          key={item["accountNumber"]}
                                          value={decryptData(
                                            item["accountNumber"]
                                          )}
                                        >
                                          {`Bank account ending with ${decryptData(
                                            item["accountNumber"]
                                          ).slice(-4)}`}
                                        </MenuItem>
                                      );
                                    }
                                  })}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        ) : (
                          <div className="fieldRow_col">
                            <h4>Withdrawal Method</h4>
                            {withdrawlMethods[0]["methodType"] === "Paypal" ? (
                              <h5>Paypal - {withdrawlMethods[0]["email"]}</h5>
                            ) : (
                              <h5>
                                Bank account ending in{" "}
                                {isNumber(withdrawlMethods[0]["accountNumber"])
                                  ? withdrawlMethods[0]["accountNumber"].slice(
                                      -4
                                    )
                                  : decryptData(
                                      withdrawlMethods[0]["accountNumber"]
                                    ).slice(-4)}
                              </h5>
                            )}
                            <p>
                              Earning will be send here according to the
                              following schedule
                            </p>
                          </div>
                        )}
                        <div className="fieldRow_col">
                          <h4>Withdrawal Fee</h4>
                          <h5>$0.00 per payment</h5>
                        </div>
                      </div>
                      <div className="fieldRow">
                        <div className="fieldRow_col">
                          <h4>Preferred withdrawal schedule</h4>
                          <p>Earnings will be released upon your request.</p>
                          <div
                            className={`formField ${
                              errors?.scheduleOption && touched?.scheduleOption
                                ? "fieldError"
                                : ""
                            }`}
                          >
                            <FormControl
                              sx={{ width: "100%" }}
                              size="small"
                              className="formField__select"
                            >
                              <Select
                                IconComponent={() => <ArrowDown />}
                                name="scheduleOption"
                                displayEmpty
                                value={values?.scheduleOption}
                                onChange={handleChange}
                                renderValue={(selected) => {
                                  if (!selected) {
                                    return <span>Select</span>;
                                  }
                                  if (selected === "Monthly") {
                                    return (
                                      <span className="pl5">
                                        <span>Monthly</span> (last Wednesday of
                                        each month)
                                      </span>
                                    );
                                  } else {
                                    return selected;
                                  }
                                }}
                              >
                                <MenuItem key={"Monthly"} value={"Monthly"}>
                                  <span className="pl5">
                                    <span>Monthly</span> (last Wednesday of each
                                    month)
                                  </span>
                                </MenuItem>
                              </Select>
                              <span className="formField__error">
                                {errors?.scheduleOption}
                              </span>
                            </FormControl>
                            {/* <div className="formField__checkbox">
                          <FormControlLabel className="pb15" control={<Checkbox />} />
                          <span className="pl5"><span>Quarterly</span> (Jun 24, 2024)</span>
                        </div>
                        <div className="formField__checkbox">
                          <FormControlLabel
                            className="pb15"
                            control={<Checkbox />}
                          />
                          <span className="pl5"><span>Monthly</span> (last Wednesday of each month)</span>
                        </div>
                        <div className="formField__checkbox">
                          <FormControlLabel
                            className="pb15"
                            control={<Checkbox />}
                          />
                          <span className="pl5"><span>Twice per month</span> (1st and 3rd Wednesday of each month)</span>
                        </div> */}
                          </div>
                        </div>
                        <div className="fieldRow_col">
                          <h4>Minimum withdrawal amount</h4>
                          <p>
                            We’ll only withdraw your payment if your balance is larger than this amount.
                          </p>
                          <div className="formField">
                            <FormControl
                              sx={{ width: "100%" }}
                              size="small"
                              className="formField__select"
                            >
                              <Select
                                IconComponent={() => <ArrowDown />}
                                name="withdrawalAmount"
                                displayEmpty
                                value={values?.withdrawalAmount}
                                onChange={handleChange}
                                renderValue={(selected) => {
                                  if (!selected) {
                                    return <span>Select</span>;
                                  }

                                  return "$" + selected;
                                }}
                              >
                                {WITHDRAWL_AMOUNT.map((item) => (
                                  <MenuItem key={item} value={item}>
                                    ${item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {/* <span className="formField__error">
                          {errors?.withdrawalAmount}
                        </span> */}
                          </div>
                        </div>
                      </div>
                      <div className="fieldRow">
                        <div className="fieldRow_col">
                          <h4>Next withdrawal</h4>
                          <h5>{getMonthLastWednesday()}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="btn__group">
                      <button
                        onClick={() => closeScheduleModal()}
                        className="btn btn__white"
                      >
                        Cancel
                      </button>
                      <button className="btn btn__primary" type="submit">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      {addWithdrawlData?.meta?.status && (
        <SuccessModal
          closeModal={closeInfoModal}
          sucessfullMsg={addWithdrawlData?.meta?.msg}
          Heading={"Withdrawal Method!"}
        />
      )}
      {addWithdrawlData?.meta?.status === false && (
        <ErrorModal
          errorMessage={addWithdrawlData?.meta?.msg}
          closeModal={closeInfoModal}
          footerVisible={true}
        />
      )}
    </>
  );
};

export default SetupWithdrawalScheduleModal;
