import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDown from "@/assets/svgs/ArrowDown";
import DatePickerIcon from "@/assets/svgs/DatePickerIcon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik, Form, Field } from "formik";
import Loader from "@/elements/Loader";
import { GetUserDetails, decryptData, isButtonDisabled } from "@/utils/helper";
import moment from "moment";
import dayjs from "dayjs";
import staticData from "@/rtk-apis/staticData";
import { TAX_CLASSIFICATION } from "@/utils/constants";
import { taxIdentificationSchema } from "@/components/schema/schema";
import updateIdentificationInfo from "@/rtk-apis/tax/updateIdentificationInfo";
import { closeRtkModal } from "@/store/slices/tax/updateTaxIdentificationInfoSlice";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const TaxPayeridentificationEdit = ({
  changeTaxPayerIdentificationView,
  taxDetails,
  residenceCountry,
}) => {
  const dispatch = useDispatch();
  const user = GetUserDetails();
  let agreementArray = [];
  if (taxDetails?.documnetDigitally) {
    agreementArray.push("documnetDigitally");
  }
  if (taxDetails?.electronicSignature) {
    agreementArray.push("electronicSignature");
  }
  const initialValues = {
    citizenType: taxDetails?.taxPayerCitizenType
      ? taxDetails?.taxPayerCitizenType
      : "",
    legalnameoftaxpayer: taxDetails?.taxPayerName
      ? taxDetails?.taxPayerName
      : "",
    taxType: taxDetails?.taxClassification ? taxDetails?.taxClassification : "",
    identificationNumberType: taxDetails?.idenficationNumberType
      ? taxDetails?.idenficationNumberType
      : "",
    ssnein: taxDetails?.idenficationNumber
      ? decryptData(taxDetails?.idenficationNumber)
      : "",
    signaturefield: taxDetails?.signature ? taxDetails?.signature : "",
    dateOfBirth: taxDetails?.dob ? taxDetails?.dob : "",
    citizenShipCountry: taxDetails?.citizenShipCountry
      ? taxDetails?.citizenShipCountry
      : "",
    agreement: agreementArray,
    businessName: taxDetails?.businessName ? taxDetails?.businessName : ""
  };
  const [showCitizenShipNote, setShowCitizenShipNote] = useState(false);

  const staticDataDetails = useSelector((globalState) => {
    return globalState.static;
  });

  const updateTaxIdentificationData = useSelector((globalState) => {
    return globalState.updateTaxIdentificationInfo;
  });

  useEffect(() => {
    dispatch(staticData());
  }, []);

  const handleSubmit = (values) => {
    if (values?.citizenType === "us-person") {
      let obj = {
        recruiterId: user?.recruiter?.recruiterId,
        usCitizenStatus: values?.citizenType === "us-person" ? true : false,
        taxPayerIdentification: {
          taxPayerCitizenType: values?.citizenType,
          taxPayerName: values?.legalnameoftaxpayer,
          taxClassification: values?.taxType,
          idenficationNumberType: values?.identificationNumberType,
          idenficationNumber: values?.ssnein,
          signature: values?.signaturefield,
          date: moment(new Date(), "YYYY-MM-DD"),
          electronicSignature: values?.agreement
            ? values.agreement.includes("electronicSignature")
            : "",
          documnetDigitally: values?.agreement
            ? values.agreement.includes("documnetDigitally")
            : "",
          businessName: values?.businessName    
        },
      };
      dispatch(updateIdentificationInfo(obj));
    }

    if (values?.citizenType === "none-us-person") {
      let obj = {
        recruiterId: user?.recruiter?.recruiterId,
        usCitizenStatus: values?.citizenType === "us-person" ? true : false,
        taxPayerIdentification: {
          taxPayerCitizenType: values?.citizenType,
          taxPayerName: values?.legalnameoftaxpayer,
          taxClassification: values?.taxType,
          dob: values?.dateOfBirth ? values?.dateOfBirth : "",
          citizenShipCountry: values?.citizenShipCountry
            ? values?.citizenShipCountry
            : "",
          businessName: values?.businessName  
        },
      };
      dispatch(updateIdentificationInfo(obj));
    }
  };

  const closeModal = () => {
    changeTaxPayerIdentificationView(false);
    dispatch(closeRtkModal());
  };

  const today = new Date();
  const eighteenYearsAgo = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={taxIdentificationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          errors,
          values,
          handleChange,
          handleBlur,
          touched,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            <section className="details__section">
              <h4 className="details__section--title">
                Taxpayer Identification
              </h4>
              <p>
                Your taxpayer identification information will be included as 
                {values?.citizenType === "us-person"
                  ? " W-9"
                  : values?.citizenType === "none-us-person"
                  ? " W-8"
                  : " W-9 or W-8"}{" "}
                form.
              </p>
              <div className="formRow">
                <div className="formField">
                  <div className="formField__checkbox">
                    <FormControlLabel
                      name="citizenType"
                      value="none-us-person"
                      onChange={(e) => {
                        if (residenceCountry === "United States") {
                          setShowCitizenShipNote(true);
                        } else {
                          if (values?.citizenType === "none-us-person") {
                            setFieldValue("citizenType", "");
                          } else {
                            setFieldValue("citizenType", "none-us-person");
                          }
                          setFieldValue("taxType", "");
                        }
                      }}
                      className="pb15"
                      control={<Checkbox />}
                      checked={values?.citizenType === "none-us-person"}
                    />
                    <span className="pl5">I am a non U.S. citizen</span>
                  </div>
                  {residenceCountry === "United States" &&
                    showCitizenShipNote && (
                      <div className="formField__error">
                        Note: You can’t select this option with a U.S. address.
                        Update your address if you’re a non-U.S. citizen.
                      </div>
                    )}
                  <div className="formField__checkbox">
                    <FormControlLabel
                      name="citizenType"
                      value="us-person"
                      onChange={(e) => {
                        if (values?.citizenType === "us-person") {
                          setFieldValue("citizenType", "");
                        } else {
                          setFieldValue("citizenType", "us-person");
                        }
                        setFieldValue("taxType", "");
                      }}
                      className="pb15"
                      control={<Checkbox />}
                      checked={values?.citizenType === "us-person"}
                    />
                    <span className="pl5">I am a U.S. citizen</span>
                  </div>
                </div>
              </div>
              <p>
                Before withdrawing funds, all U.S. persons must provide their
                {values?.citizenType === "us-person"
                  ? " W-9"
                  : values?.citizenType === "none-us-person"
                  ? " W-8"
                  : " W-9"}{" "}
                tax information.
              </p>
              <div className="formRow formRow__2">
                <div className="formCol">
                  <div className="formField">
                    <label className="formField__label">
                      Name of entity/individual
                    </label>
                    <div className="formField__group sm">
                      <Field
                        type="text"
                        className="formField__input sm"
                        name="legalnameoftaxpayer"
                        placeholder="Provide the same name as shown on your tax return"
                      />
                    </div>
                    {/* <span className="formField__error">
                      {errors?.legalnameoftaxpayer}
                    </span> */}
                  </div>
                </div>
                <div className="formCol">
                  <div className="formField">
                    <label className="formField__label">
                      Business name
                    </label>
                    <div className="formField__group sm">
                      <Field
                        type="text"
                        className="formField__input sm"
                        name="businessName"
                        placeholder="Enter business name"
                      />
                    </div>
                     {/* <span className="formField__error">
                      {errors?.legalnameoftaxpayer}
                    </span> */}
                  </div>
                </div>
              </div>
              <div className="formRow formRow__2">
                <div className="formCol">
                  <div className="formField">
                    <label className="formField__label">
                      Tax Classification
                    </label>
                    {values?.citizenType === "none-us-person" ? (
                      <FormControl
                        fullWidth
                        size="small"
                        className="formField__select"
                      >
                        <Select
                          IconComponent={() => <ArrowDown />}
                          displayEmpty
                          name="taxType"
                          value={values?.taxType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <span style={{ opacity: "0.8" }}>
                                  Select tax classification
                                </span>
                              );
                            }
                            if (selected) {
                              return selected?.value ?? "";
                            }
                          }}
                        >
                          {TAX_CLASSIFICATION.map((item) => {
                            return (
                              <MenuItem key={item?.value} value={item}>
                                {item?.value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {/* <span className="formField__error">
                          {errors?.taxType}
                        </span> */}
                      </FormControl>
                    ) : (
                      <FormControl
                        fullWidth
                        size="small"
                        className="formField__select"
                      >
                        <Select
                          IconComponent={() => <ArrowDown />}
                          displayEmpty
                          name="taxType"
                          value={values?.taxType}
                          onChange={handleChange}
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <span style={{ opacity: "0.8" }}>
                                  Select tax classification
                                </span>
                              );
                            }
                            if (selected) {
                              return selected?.value ?? "";
                            }
                          }}
                        >
                          {staticDataDetails?.data?.taxClassification &&
                            staticDataDetails?.data?.taxClassification.map(
                              (item) => {
                                return (
                                  <MenuItem key={item?.id} value={item}>
                                    {item?.value}
                                  </MenuItem>
                                );
                              }
                            )}
                        </Select>
                        {/* <span className="formField__error">
                          {errors?.taxType}
                        </span> */}
                      </FormControl>
                    )}
                  </div>
                </div>
              </div>
              {values?.citizenType === "none-us-person" && (
                <div className="formRow formRow__2">
                  {Object.keys(values?.taxType).length &&
                  values?.taxType?.value === "Individual" ? (
                    <div className="formCol">
                      <div className="formField">
                        <label className="formField__label">
                          Date of Birth
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            onChange={(value) => {
                              let originalDateString = JSON.stringify(value);
                              let date = new Date(
                                originalDateString.split("T")[0]
                              );
                              let formatedDate = moment(
                                date,
                                "MM-DD-YYYY"
                              ).format("MM-DD-YYYY");
                              setFieldValue("dateOfBirth", formatedDate);
                            }}
                            onBlur={handleBlur}
                            name="dateOfBirth"
                            className="formField__datepicker"
                            slots={{ openPickerIcon: DatePickerIcon }}
                            value={dayjs(new Date(values?.dateOfBirth))}
                            disableFuture={true}
                            maxDate={dayjs(eighteenYearsAgo)}
                          />
                        </LocalizationProvider>
                        {/* <span className="formField__error">
                          {errors?.dateOfBirth}
                        </span> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {Object.keys(values?.taxType).length &&
                  values?.taxType?.value !== "" ? (
                    <div className="formCol">
                      <div className="formField">
                        <label className="formField__label">
                          Country of {values?.taxType?.value === "Individual" ? "Citizenship" : "incorporation"}  
                        </label>
                        <Autocomplete
                          id="tags-filled"
                          options={staticDataDetails?.data?.taxCountry}
                          getOptionLabel={(option) => option?.name || option}
                          defaultValue={values?.citizenShipCountry || ""}
                          value={values?.citizenShipCountry}
                          name="citizenShipCountry"
                          onChange={(event, newValue) => {
                            setFieldValue("citizenShipCountry", newValue);
                          }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              placeholder="Select"
                              className="autoCompleteInput"
                              onBlur={handleBlur}
                            />
                          )}
                        />
                      </div>
                      {/* <span className="formField__error">
                      {errors?.citizenShipCountry}
                    </span> */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {values?.citizenType == "" ||
              values?.citizenType === "us-person" ? (
                <>
                  <div className="formRow">
                    <div className="formField">
                      <label className="formField__label mb_1">
                        Taxpayer identification number type
                      </label>
                      <div className="formField__checkbox">
                        <FormControlLabel
                          name="identificationNumberType"
                          value="SSN"
                          onChange={() =>
                            setFieldValue("identificationNumberType", "SSN")
                          }
                          checked={values?.identificationNumberType === "SSN"}
                          className="pb15"
                          control={<Checkbox />}
                        />
                        <span className="pl5 grayText">
                          Social security number (SSN)
                        </span>
                      </div>
                      <div className="formField__checkbox">
                        <FormControlLabel
                          name="identificationNumberType"
                          value="EIN"
                          onChange={() =>
                            setFieldValue("identificationNumberType", "EIN")
                          }
                          checked={values?.identificationNumberType === "EIN"}
                          className="pb15"
                          control={<Checkbox />}
                        />
                        <span className="pl5 grayText">
                          Employer identification number (EIN)
                        </span>
                      </div>
                      {/* <span className="formField__error">
                        {errors?.identificationNumberType}
                      </span> */}
                    </div>
                  </div>
                  <div className="formRow formRow">
                    <div className="formCol">
                      <div 
                        className={`formField ${
                          (errors?.ssnein && values?.identificationNumberType) ? "fieldError" : ""
                        }`}
                      >
                        <label className="formField__label">SSN/EIN #</label>
                        <div className="formField__group sm">
                          <Field
                            type="text"
                            className="formField__input sm"
                            name="ssnein"
                            placeholder=""
                            value={values?.ssnein}
                            onChange={(e)=>{
                              if((values?.identificationNumberType === "SSN" && e?.target?.value?.length <= 11) || (values?.identificationNumberType === "EIN" && e?.target?.value?.length <= 10)){
                                  setFieldValue("ssnein", e?.target?.value)
                                }
                              }}
                          />
                        </div>
                        <span className="formField__error">
                          {errors?.ssnein
                            ? values?.identificationNumberType === "SSN"
                              ? "SSN should be in xxx-xx-xxxx format."
                              :  values?.identificationNumberType === "EIN" ? "EIN should be in xx-xxxxxxx format."
                            : "" : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="certificationInfoBox">
                    <h4>
                      Tax certification and confirmation of unchanged status
                    </h4>
                    <p>
                      Please read and scroll to the bottom of the W-9
                      certification, and consent to be able to confirm
                      electronic delivery of the document.
                    </p>

                    {/* <p>Please read and scroll to the bottom of the W-8BEN certification.</p> */}

                    <div className="certificationInfoBox__dataBox">
                      <h4>W-9 certification</h4>
                      <p>Under penalties of perjury, I certify that:</p>
                      <p>
                        1. The number shown on this form is my correct taxpayer
                        identification number (or I am waiting for a number to
                        be issued to me); and
                      </p>
                      <p>
                        2. I am not subject to backup withholding because:
                        <span>
                          (a) I am exempt from backup withholding, or{" "}
                        </span>
                        <span>
                          (b) I have not been notified by the Internal Revenue
                          Service (IRS) that I am subject to backup withholding
                          as a result of a failure to report all interest or
                          dividends, or
                        </span>
                        <span>
                          (c) the IRS has notified me that I am no longer
                          subject to backup withholding; and
                        </span>
                      </p>
                      <p>
                        3. I am a U.S. citizen or other U.S. person (defined
                        below); and
                      </p>
                      <p>
                        4. The FATCA code(s) entered on this form (if any)
                        indicating that I am exempt from FATCA reporting is
                        correct.
                      </p>
                      <p>Electronic consent</p>
                      <p>
                        By checking the below box, you acknowledge that you have
                        read, understand, and affirmatively agree to this
                        Consent. You consent that you can access electronic tax
                        statements and documents (including Form 1099).
                      </p>
                      <p>
                        This includes the ability to access, retain, and print
                        these documents.
                      </p>
                      <p>
                        You agree that the information may be communicated
                        through e-mail, or by posting statements, notices,
                        disclosures on the account page of our website.
                      </p>
                      <p>
                        Your consent is effective until withdrawn. If you do not
                        specifically consent to the electronic delivery of tax
                        related documents, or wish to withdraw this consent, you
                        may do so by sending a notification to &nbsp;
                        <a href="mailto:support@team.laila.co">
                          support@team.laila.co
                        </a>
                      </p>
                      <p>
                        Depending on the timing that electronic consent was
                        provided, we may have already processed your tax
                        documents for physical delivery, and in such cases you
                        will also receive a copy of your tax documents in the
                        mail.
                      </p>
                      <p>
                        Should you choose to refuse or withdraw consent, you
                        will receive paper copies of all required tax-related
                        documents (including Form 1099) at your tax residence
                        address at the time these documents will be delivered.
                      </p>
                    </div>
                  </div>
                  <div className="formRow formRow__2">
                    <div className="formCol">
                      <div className="formField">
                        <label className="formField__label">
                          Signature (Type your full name)
                          <span className="formField__label__asterisk">*</span>
                        </label>
                        <div className="formField__group sm">
                          <Field
                            type="text"
                            className="formField__input sm"
                            name="signaturefield"
                            placeholder="Enter your full name"
                          />
                        </div>
                        {/* <span className="formField__error">
                          {errors?.signaturefield}
                        </span> */}
                      </div>
                    </div>
                    <div className="formCol">
                      <div className="formField w_30">
                        <label className="formField__label">Date</label>
                        <span className="dateLabelDisable">{moment(new Date()).format("MM-DD-YYYY")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="formRow">
                    <div className="formField">
                      <div className="formField__checkbox">
                        <FormControlLabel
                          name="agreement"
                          value="electronicSignature"
                          onChange={handleChange}
                          className="pb15"
                          control={<Checkbox />}
                          checked={values?.agreement?.includes(
                            "electronicSignature"
                          )}
                        />
                        <span className="pl5">
                          I consent to provide an electronic signature by typing
                          my signature above.
                        </span>
                      </div>
                      <div className="formField__checkbox">
                        <FormControlLabel
                          name="agreement"
                          value="documnetDigitally"
                          onChange={handleChange}
                          className="pb15"
                          control={<Checkbox />}
                          checked={values?.agreement?.includes(
                            "documnetDigitally"
                          )}
                        />
                        <span className="pl5">
                          I consent to receive tax documents digitally.
                        </span>
                      </div>
                      {/* <span className="formField__error">
                        {errors?.agreement}
                      </span> */}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="formRow">
                <div className="btn__group btn__group--end">
                  <button
                    className="btn btn__white"
                    onClick={() => changeTaxPayerIdentificationView(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn__primary"
                    type="submit"
                    // disabled={
                    //   values?.citizenType === "none-us-person" ||
                    //   (values?.agreement?.includes("electronicSignature") &&
                    //     values?.citizenType === "us-person")
                    //     ? false
                    //     : true
                    // }
                    disabled={isButtonDisabled(errors, values, initialValues)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </section>
          </Form>
        )}
      </Formik>
      {updateTaxIdentificationData?.loading && <Loader />}
      {updateTaxIdentificationData?.meta?.status && (
        <SuccessModal
          closeModal={closeModal}
          sucessfullMsg={updateTaxIdentificationData?.meta?.msg}
          Heading={"Taxpayer Identification!"}
        />
      )}
      {updateTaxIdentificationData?.meta?.status === false && (
        <ErrorModal
          errorMessage={updateTaxIdentificationData?.meta?.msg}
          closeModal={closeModal}
          footerVisible={true}
        />
      )}
    </div>
  );
};

export default TaxPayeridentificationEdit;
