import React from 'react';

export default function ActivateIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_112_2946)">
        <path
          d="M14.6364 19.1815V17.3633C14.6364 16.3989 14.2532 15.474 13.5713 14.792C12.8893 14.1101 11.9644 13.727 11 13.727H4.63636C3.67194 13.727 2.74702 14.1101 2.06507 14.792C1.38312 15.474 1 16.3989 1 17.3633V19.1815M15.5455 10.0906L17.3636 11.9088L21 8.27242M11.4545 6.45423C11.4545 8.46254 9.82649 10.0906 7.81818 10.0906C5.80987 10.0906 4.18182 8.46254 4.18182 6.45423C4.18182 4.44593 5.80987 2.81787 7.81818 2.81787C9.82649 2.81787 11.4545 4.44593 11.4545 6.45423Z"
          stroke="#039855"
          strokeWidth="1.81818"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_112_2946">
          <rect
            width="21.8182"
            height="21.8182"
            fill="white"
            transform="translate(0.0909424 0.0908203)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
