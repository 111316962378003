import React from 'react';

function PlusIcon() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <path
        d="M10.0003 4.16699V15.8337M4.16699 10.0003H15.8337"
        stroke="#150823"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
        />
    </svg>
  )
}

export default PlusIcon;