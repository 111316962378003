import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DatePickerIcon from "@/assets/svgs/DatePickerIcon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik, Form, Field } from "formik";
import Loader from "@/elements/Loader";
import { GetUserDetails, decryptData } from "@/utils/helper";
import moment from "moment";
import staticData from "@/rtk-apis/staticData";
import { taxIdentificationNumberSchema } from "@/components/schema/schema";
import updateIdentificationInfo from "@/rtk-apis/tax/updateIdentificationInfo";
import { closeRtkModal } from "@/store/slices/tax/updateTaxIdentificationInfoSlice";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";

const TaxPayeridentificationNumberEdit = ({
  changeTaxPayerIdentificationView,
  taxDetails,
}) => {
  const dispatch = useDispatch();
  const user = GetUserDetails();
  const initialValues = {
    tinNumber: taxDetails?.tinNumber ? decryptData(taxDetails?.tinNumber) : "",
    signature1: taxDetails?.signature ? taxDetails?.signature : "",
    agreement: taxDetails?.agreement ? taxDetails?.agreement : "",
    signature2: taxDetails?.signature ? taxDetails?.signature : "",
  };

  const updateTaxIdentificationData = useSelector((globalState) => {
    return globalState.updateTaxIdentificationInfo;
  });

  useEffect(() => {
    dispatch(staticData());
  }, []);

  const handleSubmit = (values) => {
    let prevObj = {
      recruiterId: user?.recruiter?.recruiterId,
      usCitizenStatus: values?.citizenType === "us-person" ? true : false,
      taxPayerIdentification: taxDetails,
    };
    let obj = JSON.parse(JSON.stringify(prevObj));
    obj["taxPayerIdentification"]["tinNumber"] = values?.tinNumber;
    obj["taxPayerIdentification"]["agreement"] = values?.agreement;
    obj["taxPayerIdentification"]["signature"] = values?.signature1;
    obj["taxPayerIdentification"]["affidavitSignature"] = values?.signature2;
    obj["taxPayerIdentification"]["date"] = moment(new Date(), "YYYY-MM-DD");
    obj["taxPayerIdentification"]["affidavitDate"] = moment(
      new Date(),
      "YYYY-MM-DD"
    );

    dispatch(updateIdentificationInfo(obj));
  };

  const closeModal = () => {
    changeTaxPayerIdentificationView(false);
    dispatch(closeRtkModal());
  };

  const isButtonDisabled = (error, value) => {
    let status = false;
    if (
      value?.agreement &&
      value?.agreement?.length &&
      value?.signature1 &&
      value?.signature2 &&
      value?.tinNumber &&
      Object.keys(error)?.length != 0
    ) {
      status = false;
    } else if (value?.signature1 === "" && value?.signature2 === "") {
      status = true;
    } else if (Object.keys(error)?.length != 0) {
      status = true;
    } else if (
      Object.keys(error)?.length == 0 &&
      _.isEqual(value, initialValues)
    ) {
      status = true;
    } else if (value?.signature1 === "" || value?.signature2 === "") {
      status = true;
    }
    return status;
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={taxIdentificationNumberSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          errors,
          values,
          handleChange,
          handleBlur,
          touched,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            <section className="details__section">
              <h4 className="details__section--title">
                Taxpayer Identification Number
              </h4>
              <p>
                Please provide your tax identification Number (TIN). If you
                don't have a TIN, consult a local tax professional or your tax
                advisor.
              </p>
              <div className="formRow">
                <div className="formCol">
                  <div className="formField">
                    <label className="formField__label">
                      Tax Identification Number (TIN)
                    </label>
                    <div className="formField__group sm">
                      <Field
                        type="text"
                        className="formField__input sm"
                        name="tinNumber"
                        placeholder="Enter your TIN number"
                        value={values?.tinNumber}
                        onChange={(e) => {
                          if (e?.target?.value?.length <= 11) {
                            setFieldValue("tinNumber", e?.target?.value);
                          }
                        }}
                      />
                    </div>
                    {/* <div className="taxidentificationText">
                      Your TIN has 10 characters, and should look like this:
                      LLLLL9999L.
                    </div> */}
                    {/* <span className="formField__error">
                      {errors?.tinNumber}
                    </span> */}
                  </div>
                </div>
              </div>
              <div className="certificationInfoBox">
                <h4>Tax certification and confirmation of unchanged status</h4>
                <p>
                  Please read and scroll to the bottom of the W-8BEN
                  certification.
                </p>
                <div className="certificationInfoBox__dataBox">
                  <h4>W-8BEN certification</h4>
                  <p>
                    Under penalties of perjury, I declare that I have examined
                    the information on this form and to the best of my knowledge
                    and belief it is true, correct, and complete. I further
                    certify under penalties of perjury that:
                  </p>
                  <ul>
                    <li>
                      I am the individual that is the beneficial owner (or am
                      authorized to sign for the individual that is the
                      beneficial owner) of all the income or proceeds to which
                      this form relates or am using this form to document myself
                      for chapter 4 purposes;
                    </li>
                    <li>
                      The person named on line 1 of this form is not a U.S.
                      person;
                    </li>
                    <li>This form relates to:</li>
                  </ul>
                  <p>
                    <span>
                      (a) income not effectively connected with the conduct of a
                      trade or business in the United States;
                    </span>
                    <span>
                      (b) income effectively connected with the conduct of a
                      trade or business in the United States but is not subject
                      to tax under an applicable income tax treaty;
                    </span>
                    <span>
                      (c) the partner’s share of a partnership’s effectively
                      connected taxable income; or
                    </span>
                    <span>
                      (d) the partner’s amount realized from the transfer of a
                      partnership interest subject to withholding under section
                      1446(f);
                    </span>
                  </p>
                  <ul>
                    <li>
                      The person named on line 1 of this form is a resident of
                      the treaty country listed on line 9 of the form (if any)
                      within the meaning of the income tax treaty between the
                      United States and that country; and
                    </li>
                    <li>
                      For broker transactions or barter exchanges, the
                      beneficial owner is an exempt foreign person as defined in
                      the instructions.
                    </li>
                  </ul>
                  <p>
                    Furthermore, I authorize this form to be provided to any
                    withholding agent that has control, receipt, or custody of
                    the income of which I am the beneficial owner or any
                    withholding agent that can disburse or make payments of the
                    income of which I am the beneficial owner. I agree that I
                    will submit a new form within 30 days if any certification
                    made on this form becomes incorrect.
                  </p>
                  <p>
                    <span>Statement of source</span>
                    Only sign below if these statements are true; otherwise
                    contact Laila customer support.
                  </p>
                  <p>
                    The services performed on this Laila account have not and
                    will not be physically performed in the United States. I
                    will provide written notice and update the tax information
                    on file for my Laila account before performing any services
                    in the United States.
                  </p>
                  <p>
                    The Internal Revenue Service requires your certification
                    only to establish your status as a non-U.S. individual and,
                    if applicable, obtain a reduced rate of withholding.
                  </p>
                </div>
              </div>
              <div className="formRow formRow__2">
                <div className="formCol">
                  <div
                    className={`formField ${
                      touched?.signature1 &&
                      touched?.signature2 &&
                      errors?.signature1
                        ? "fieldError"
                        : ""
                    }`}
                  >
                    <label className="formField__label">
                      Signature (Type your full name)
                      <span className="formField__label__asterisk">*</span>
                    </label>
                    <div className="formField__group sm">
                      <Field
                        type="text"
                        className="formField__input sm"
                        name="signature1"
                        placeholder="Enter your full name"
                      />
                    </div>
                    <span className="formField__error">
                      {touched?.signature1 &&
                      touched?.signature2 &&
                      errors?.signature1
                        ? errors?.signature1
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="formCol">
                  <div className="formField w_30">
                    <label className="formField__label">Date</label>
                    <span className="dateLabelDisable">{moment(new Date()).format("MM-DD-YYYY")}</span>
                  </div>
                </div>
              </div>
              <div className="formRow">
                <div className="formField">
                  <div className="formField__checkbox">
                    <FormControlLabel
                      name="agreement"
                      value="electronicSignature"
                      onChange={handleChange}
                      className="pb15"
                      control={<Checkbox />}
                      checked={values?.agreement?.includes(
                        "electronicSignature"
                      )}
                    />
                    <span className="pl5">
                      I certify that I have the capacity to sign for the person
                      identified on the line 1 of this form. And I consent to
                      provide an electronic signature by typing my signature
                      above.
                    </span>
                  </div>
                  {/* <span className="formField__error">{errors?.agreement}</span> */}
                </div>
              </div>
              <div className="affidavitDataBox">
                <h4>Affidavit of unchanged status</h4>
                <p>
                  Under penalties of perjury, I declare that I have examined and
                  signed the Form W-8BEN, Form W-8BEN-E, etc and the information
                  and certifications contained therein have remained the same
                  and have remained unchanged throughout calendar year January
                  1, 2024 through the date on this form and were true, correct
                  and complete for this year.If these facts and certifications
                  do not apply to you, or if you have general questions about
                  this unchanged status affidavit.
                </p>
                <div className="formRow formRow__2">
                  <div className="formCol">
                    <div
                      className={`formField ${
                        touched?.signature1 &&
                        touched?.signature2 &&
                        errors?.signature2
                          ? "fieldError"
                          : ""
                      }`}
                    >
                      <label className="formField__label">
                        Signature (Type your full name)
                        <span className="formField__label__asterisk">*</span>
                      </label>
                      <div className="formField__group sm">
                        <Field
                          type="text"
                          className="formField__input sm"
                          name="signature2"
                          placeholder="Enter your full name"
                        />
                      </div>
                      <span className="formField__error">
                        {touched?.signature1 &&
                        touched?.signature2 &&
                        errors?.signature2
                          ? errors?.signature2
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="formCol">
                    <div className="formField w_30">
                      <label className="formField__label">Date</label>
                      <span className="dateLabelDisable">{moment(new Date()).format("MM-DD-YYYY")}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="formRow">
                <div className="btn__group btn__group--end">
                  <button
                    className="btn btn__white"
                    onClick={() => changeTaxPayerIdentificationView(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn__primary"
                    type="submit"
                    disabled={isButtonDisabled(errors, values)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </section>
          </Form>
        )}
      </Formik>
      {updateTaxIdentificationData?.loading && <Loader />}
      {updateTaxIdentificationData?.meta?.status && (
        <SuccessModal
          closeModal={closeModal}
          sucessfullMsg={updateTaxIdentificationData?.meta?.msg}
          Heading={"Taxpayer Identification Number!"}
        />
      )}
      {updateTaxIdentificationData?.meta?.status === false && (
        <ErrorModal
          errorMessage={updateTaxIdentificationData?.meta?.msg}
          closeModal={closeModal}
          footerVisible={true}
        />
      )}
    </div>
  );
};

export default TaxPayeridentificationNumberEdit;
