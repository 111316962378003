import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { CUSTOMER_ACTIVATE_EMPLOYEE } from "@/rtk-apis/end-points";

const customerActivateEmployee = createAsyncThunk(
  "customerActivateEmployee",
  async (body) => {
    try {
      const response = await httpService.put(CUSTOMER_ACTIVATE_EMPLOYEE, body);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default customerActivateEmployee;
