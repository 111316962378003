import React from "react";

function CandidatesRecruitedIcon() {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="80" height="80" rx="40" fill="#6C737F" />
      <path
        d="M21.75 54.5836H17.5833V52.5003C17.5833 48.6173 20.2391 45.3546 23.8333 44.4295M28 37.5614C25.5725 36.7034 23.8333 34.3883 23.8333 31.667C23.8333 28.9457 25.5725 26.6306 28 25.7726M59.25 54.5836H63.4167V52.5003C63.4167 48.6173 60.7609 45.3546 57.1667 44.4295M53 25.7726C55.4275 26.6306 57.1667 28.9457 57.1667 31.667C57.1667 34.3883 55.4275 36.7034 53 37.5614M36.3333 44.167H44.6667C49.269 44.167 53 47.898 53 52.5003V54.5837H28V52.5003C28 47.898 31.731 44.167 36.3333 44.167ZM46.75 31.667C46.75 35.1188 43.9518 37.917 40.5 37.917C37.0482 37.917 34.25 35.1188 34.25 31.667C34.25 28.2152 37.0482 25.417 40.5 25.417C43.9518 25.417 46.75 28.2152 46.75 31.667Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CandidatesRecruitedIcon;
