import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BankingImg from "@/assets/images/banking-icon.png";
import PaypalImg from "@/assets/images/paypal-icon.png";
import { decryptData, GetUserDetails } from "@/utils/helper";
import AddaBankAccountModal from "@/components/modals/AddaBankAccountModal";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import deleteBankDetails from "@/rtk-apis/payments/deleteBankDetails";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import { closeRtkModal as deleteCloseRtkModal } from "@/store/slices/payments/deleteBankDetailsSlice";

const WithdrawMethodsView = ({ AchInfo, refreshPage, addNewMethod }) => {
  const dispatch = useDispatch();
  const user = GetUserDetails();
  const [openAddBankAccountModal, setOpenAddBankAccountModal] = useState(false);
  const [
    openSetupWithdrawalScheduleModal,
    setOpenSetupWithdrawalScheduleModal,
  ] = useState(false);
  const [editACHData, setEditACHData] = useState({});
  const [openRemoveConf, setOpenRemoveConf] = useState(false);
  const [methodId, setMethodId] = useState(false);

  const deleteBankDetailsData = useSelector((globalState) => {
    return globalState.deleteBankDetails;
  });

  const openBankDetailModal = (item) => {
    setEditACHData(item);
    setOpenAddBankAccountModal(true);
  };

  const closeBankDetailModal = (closeAction = false) => {
    setOpenAddBankAccountModal(false);
    if (!closeAction) {
      refreshPage();
    }
  };

  const openRemoveConfirmation = (id) => {
    setMethodId(id);
    setOpenRemoveConf(true);
  };

  const removeWithdrawlMethod = () => {
    dispatch(
      deleteBankDetails({
        recruiterId: user?.recruiter?.recruiterId,
        methodId,
      })
    );
  };

  const closeModal = () => {
    setOpenRemoveConf(false);
    dispatch(deleteCloseRtkModal());
    refreshPage();
  };

  return (
    <>
      <section className="details__section">
        <h4 className="details__section--title">
          Withdrawal Methods
          <button
            className="btn btn__primary btn__outlined"
            onClick={() => addNewMethod()}
          >
            Add a method
          </button>
        </h4>
        <div className="paymentlist">
          {AchInfo?.length
            ? AchInfo.map((item) => (
                <div key={item['id']} className="paymentlist__item">
                  {item?.methodType === "Paypal" ? (
                    <>
                    <div className="infoBox">
                      <div className="infoBox__icon">
                        <img
                          className="infoBox__icon__img"
                          src={PaypalImg}
                          alt="Bank Img"
                        />
                      </div>
                      <div className="infoBox__detail">
                        <h5>
                          Paypal - {item["email"]}
                        </h5>
                        <p className="greenText">Active</p>
                      </div>
                    </div>
                    <div className="bntActions">
                      <button
                        className="btnRemove"
                        type="button"
                        onClick={() => openRemoveConfirmation(item?.id)}
                      >
                        Remove
                      </button>
                    </div>
                  </>
                  ) : (
                    <>
                      <div className="infoBox">
                        <div className="infoBox__icon">
                          <img
                            className="infoBox__icon__img"
                            src={BankingImg}
                            alt="Bank Img"
                          />
                        </div>
                        <div className="infoBox__detail">
                          <h5>
                            Bank account ending in{" "}
                            {decryptData(item["accountNumber"]).slice(-4)}
                          </h5>
                          <p className="greenText">Active</p>
                        </div>
                      </div>
                      <div className="bntActions">
                        <button
                          className="btnEdit"
                          type="button"
                          onClick={() => openBankDetailModal(item)}
                        >
                          Edit
                        </button>
                        <button
                          className="btnRemove"
                          type="button"
                          onClick={() => openRemoveConfirmation(item?.id)}
                        >
                          Remove
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ))
            : ""}
        </div>
      </section>
      {openAddBankAccountModal && (
        <AddaBankAccountModal
          openModal={openAddBankAccountModal}
          closeModal={closeBankDetailModal}
          setOpenSetupWithdrawalScheduleModal={
            setOpenSetupWithdrawalScheduleModal
          }
          AchData={editACHData}
        />
      )}
      {openRemoveConf && (
        <ConfirmationModal
          closeModal={() => setOpenRemoveConf(false)}
          onSubmit={removeWithdrawlMethod}
          heading={"Remove Withdrawl Method!"}
          confirmationMessage={"Are you sure you want remove withdrawl method?"}
        />
      )}
      {deleteBankDetailsData?.meta?.status && (
        <SuccessModal
          closeModal={closeModal}
          sucessfullMsg={deleteBankDetailsData?.meta?.msg}
          Heading={"Bank Details!"}
        />
      )}
      {deleteBankDetailsData?.meta?.status === false && (
        <ErrorModal
          errorMessage={deleteBankDetailsData?.meta?.msg}
          closeModal={closeModal}
          footerVisible={true}
        />
      )}
    </>
  );
};

export default WithdrawMethodsView;
