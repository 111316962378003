import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { SOCIAL_SIGN_IN_STEP_TWO } from "@/rtk-apis/end-points";

const socialSignInStepTwo = createAsyncThunk(
  "socialSignInStepTwo",
  async (body) => {
    try {
      const response = await httpService.post(SOCIAL_SIGN_IN_STEP_TWO, body);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default socialSignInStepTwo;
