import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import httpService from "@/rtk-apis/axios-config/http-service";
import { useDispatch } from "react-redux";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import LogoIcon from "@/assets/svgs/LogoIcon";
import LockSvg from "@/assets/svgs/LockSvg";
import LogoutSvg from "@/assets/svgs/LogoutSvg";
import SettingsSvg from "@/assets/svgs/SettingsSvg";
import TaxInformationSvg from "@/assets/svgs/TaxInformationSvg";
import PaymentsSvg from "@/assets/svgs/PaymentsSvg";
import ArrowDown from "@/assets/svgs/ArrowDown";
import { logoutUser } from "@/store/slices/SignInSlice";
import { firstLetterCapital, getInitial, GetUserDetails } from "@/utils/helper";
import {
  RECRUITER_SIDEBAR_MENU,
  CUSTOMER_SIDEBAR_MENU,
  COMPANY_FINANCE_ADMIN_MENUS,
  COMPANY_FINANCE_AND_JTM_MENUS,
  COMPANY_ADMIN_MENUS,
  RECRUITER_MENUS,
  EMPLOYEE_MENUS,
  JOB_CREATER_MENUS,
  APP_VERSION,
  CANDIDATE_MENUS,
  CANDIDATE_SIDEBAR_MENU,
} from "@/utils/constants";
import SideBarMenu from "@/components/layout/SideBarMenu";
import ProfileIcon from "@/assets/svgs/ProfileIcon";
import DotOrangeIcon from "@/assets/svgs/DotOrangeIcon";
import DotRedIcon from "@/assets/svgs/DotRedIcon";
import TagsSvg from "@/assets/svgs/TagsSvg";
import { logoutSocialSignedInUser } from "../../store/slices/socialSignInStepTwoSlice";
import { logoutSocialUser } from "../../store/slices/socialSignInSlice";

const SideBar = () => {
  const user = GetUserDetails();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(
    state?.activeTab ? state?.activeTab : "individual"
  );
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [subActiveTab, setSubActiveTab] = useState("");

  useEffect(() => {
    const newarray = location.pathname.split("/");
    if (newarray.find((e) => e === "job-requests")) {
      setActiveTab("job-requests");
    } else if (newarray.find((e) => e === "candidates")) {
      setActiveTab("candidates");
    } else if (
      !!newarray.find((e) => e === "my-jobs") ||
      !!newarray.find((e) => e === "customer-my-jobs")
    ) {
      setActiveTab("my-jobs");
    } else if (newarray.find((e) => e === "interviews")) {
      setActiveTab("interviews");
    } else if (
      !!newarray.find((e) => e === "my-profile") ||
      !!newarray.find((e) => e === "customer-my-profile")
    ) {
      setActiveTab("my-profile");
    } else if (newarray.find((e) => e === "change-password")) {
      setActiveTab("change-password");
    } else if (newarray.find((e) => e === "customer-interviews")) {
      setActiveTab("customer-interviews");
    } else if (newarray.find((e) => e === "customer-company-profile")) {
      setActiveTab("customer-company-profile");
    } else if (newarray.find((e) => e === "customer-billing-payments")) {
      setActiveTab("customer-billing-payments");
    } else if (newarray.find((e) => e === "recruiter-billing-payments")) {
      setActiveTab("recruiter-billing-payments");
    } else if (newarray.find((e) => e === "taxinfo")) {
      setActiveTab("settings");
      setSubActiveTab("taxinfo");
      handleSubMenuToggle();
    } else if (newarray.find((e) => e === "payment")) {
      setActiveTab("settings");
      setSubActiveTab("payment");
      handleSubMenuToggle();
    } else if (newarray.find((e) => e === "tags")) {
      setActiveTab("settings");
      setSubActiveTab("tags");
      handleSubMenuToggle();
    } else {
      setActiveTab("dashboard");
    }
  }, [location.pathname]);

  const logout = async () => {
    setOpenLogoutModal(false);
    try {
      const response = await httpService.post("/laila/admin/logout", {});
      const { meta } = response;
      if (meta?.status) {
        dispatch(logoutUser());
        dispatch(logoutSocialSignedInUser());
        dispatch(logoutSocialUser());
        localStorage.clear();
        if (window.$chatwoot && typeof window.$chatwoot.reset === "function") {
          window.$chatwoot.reset();
        }
        navigate("/signin");
      }
    } catch (error) {
      console.log("axios error --->", error);
    }
  };

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleSubMenuToggle = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <>
      <div className="layoutSidemenu">
        <div className="layoutSidemenu__logo">
          <LogoIcon />
          {(process.env.REACT_APP_NODE_ENV === "development" ||
            process.env.REACT_APP_NODE_ENV === "staging") && (
            <div className="portalIconDot">
              {user?.usertype === "customer" ? (
                <DotOrangeIcon />
              ) : (
                <DotRedIcon />
              )}
            </div>
          )}
        </div>
        <div className="layoutSidemenu__fullHeight">
          {user?.usertype === "recruiter" && (
            <SideBarMenu
              menu={RECRUITER_SIDEBAR_MENU}
              activeTab={activeTab}
              activeMenus={RECRUITER_MENUS}
            />
          )}
          {user?.usertype === "customer" && (
            <SideBarMenu
              menu={CUSTOMER_SIDEBAR_MENU}
              activeTab={activeTab}
              activeMenus={
                user?.customer?.role === "Finance Admin" &&
                user?.customer?.jobAssigned
                  ? COMPANY_FINANCE_AND_JTM_MENUS
                  : user?.customer?.role === "Finance Admin" &&
                    !user?.customer?.jobAssigned
                  ? COMPANY_FINANCE_ADMIN_MENUS
                  : user?.customer?.role === "Employee"
                  ? EMPLOYEE_MENUS
                  : user?.customer?.role === "Job Creator"
                  ? JOB_CREATER_MENUS
                  : COMPANY_ADMIN_MENUS
              }
            />
          )}

          {user?.usertype === "candidate" && (
            <SideBarMenu
              menu={CANDIDATE_SIDEBAR_MENU}
              activeTab={activeTab}
              activeMenus={CANDIDATE_MENUS}
            />
          )}

          {user?.usertype !== "candidate" && (
            <ul className="layoutSidemenu__list">
              {user?.usertype === "recruiter" && (
                <li
                  className={
                    activeTab === "my-profile"
                      ? "layoutSidemenu__list--item layoutSidemenu__selected"
                      : "layoutSidemenu__list--item"
                  }
                >
                  <Link to="/my-profile" className="layoutSidemenu__list--link">
                    <ProfileIcon />
                    <span>My Profile</span>
                  </Link>
                </li>
              )}
              {user?.usertype === "customer" && (
                <li
                  className={
                    activeTab === "my-profile"
                      ? "layoutSidemenu__list--item layoutSidemenu__selected"
                      : "layoutSidemenu__list--item"
                  }
                >
                  <Link
                    to="/customer-my-profile"
                    className="layoutSidemenu__list--link"
                  >
                    <ProfileIcon />
                    <span>My Profile</span>
                  </Link>
                </li>
              )}
              <li
                className={
                  activeTab === "change-password"
                    ? "layoutSidemenu__list--item layoutSidemenu__selected"
                    : "layoutSidemenu__list--item"
                }
              >
                <Link
                  to="/change-password"
                  className="layoutSidemenu__list--link"
                >
                  <LockSvg />
                  <span>Change Password</span>
                </Link>
              </li>
              {(user?.usertype === "recruiter" ||
                (user?.usertype === "customer" &&
                  (user?.customer?.role === "Company Admin" ||
                    user?.customer?.role === "Finance Admin"))) && (
                <li
                  className={
                    activeTab === "settings"
                      ? "layoutSidemenu__list--item layoutSidemenu__selected"
                      : "layoutSidemenu__list--item"
                  }
                >
                  <Link
                    className={`layoutSidemenu__list--link ${
                      isSubMenuOpen ? "open" : ""
                    }`}
                    onClick={handleSubMenuToggle}
                  >
                    <SettingsSvg />
                    <span>Settings</span>
                    <span className="arrowDown" onClick={handleSubMenuToggle}>
                      <ArrowDown />
                    </span>
                  </Link>
                  <ul
                    className={`layoutSidemenu__list layoutSidemenu__list--submenu border_0 ${
                      isSubMenuOpen ? "open" : ""
                    }`}
                  >
                    {user?.usertype === "recruiter" && (
                      <li
                        className={
                          subActiveTab === "taxinfo"
                            ? "layoutSidemenu__list--item layoutSidemenu__selected"
                            : "layoutSidemenu__list--item"
                        }
                      >
                        <Link
                          to="/settings/taxinfo"
                          className="layoutSidemenu__list--link"
                          onClick={() => setSubActiveTab("taxinfo")}
                        >
                          <TaxInformationSvg />
                          <span>Tax Information</span>
                        </Link>
                      </li>
                    )}
                    <li
                      className={
                        subActiveTab === "payment"
                          ? "layoutSidemenu__list--item layoutSidemenu__selected"
                          : "layoutSidemenu__list--item"
                      }
                    >
                      <Link
                        to={`${
                          user?.usertype === "recruiter"
                            ? "/settings/payment"
                            : "/settings/customer-payment"
                        }`}
                        className="layoutSidemenu__list--link"
                        onClick={() => setSubActiveTab("payment")}
                      >
                        <PaymentsSvg />
                        <span>Add Payment Method</span>
                      </Link>
                    </li>
                    {user?.usertype === "customer" && (
                      <li
                        className={
                          subActiveTab === "tags"
                            ? "layoutSidemenu__list--item layoutSidemenu__selected"
                            : "layoutSidemenu__list--item"
                        }
                      >
                        <Link
                          to={"/settings/tags"}
                          className="layoutSidemenu__list--link"
                          onClick={() => setSubActiveTab("tags")}
                        >
                          <TagsSvg />
                          <span>Tags</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
            </ul>
          )}
          <div className="layoutSidemenu__logout">
            <button type="button" onClick={() => setOpenLogoutModal(true)}>
              <LogoutSvg />
              <span>Logout</span>
            </button>
          </div>
          <ul className="layoutSidemenu__list border_0">
            <li className="layoutSidemenu__list--item">
              <span className="version">{APP_VERSION}</span>
            </li>
          </ul>
        </div>
        <div className="layoutSidemenu__user">
          <div className="layoutSidemenu__user--initials">
            {user?.usertype === "recruiter"
              ? getInitial(user?.recruiter?.firstName[0])
              : getInitial(user?.customer?.firstName[0])}
          </div>
          <h4 className="layoutSidemenu__user--name">
            {user?.usertype === "recruiter"
              ? `${firstLetterCapital(
                  user?.recruiter?.firstName
                )} ${firstLetterCapital(user?.recruiter?.lastName)}`
              : user?.usertype === "customer"
              ? `${firstLetterCapital(
                  user?.customer?.firstName
                )} ${firstLetterCapital(user?.customer?.lastName)}`
              : `${firstLetterCapital(
                  user?.candidate?.firstName
                )} ${firstLetterCapital(user?.candidate?.lastName)}`}
            {(process.env.REACT_APP_NODE_ENV === "development" ||
              process.env.REACT_APP_NODE_ENV === "staging") && (
              <span>{user?.usertype === "recruiter" ? "Recruiter" : ""}</span>
            )}
            <span>
              {firstLetterCapital(user?.customer?.company?.companyName)}
            </span>
          </h4>
        </div>
      </div>
      {openLogoutModal && (
        <ConfirmationModal
          closeModal={() => setOpenLogoutModal(false)}
          onSubmit={logout}
          heading={"Logout"}
          confirmationMessage={"Are you sure want to logout?"}
        />
      )}
    </>
  );
};

export default SideBar;
