import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { SOCIAL_SIGN_IN } from "@/rtk-apis/end-points";

const socialSignIn = createAsyncThunk("socialSignIn", async (body) => {
  try {
    const response = await httpService.post(SOCIAL_SIGN_IN, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default socialSignIn;
