import { createSlice } from '@reduxjs/toolkit';
import removeACHDebitDetails from '@/rtk-apis/customer/payments/removeACHDebitDetails';

const initialState = {
  message: '',
  data: {},
  meta: {},
  loading: false,
  error: ''
};

const removeACHDebitDetailsSlice = createSlice({
  name: 'removeACHDebitDetails',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeACHDebitDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(removeACHDebitDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
      })
      .addCase(removeACHDebitDetails.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  }

});

export default removeACHDebitDetailsSlice.reducer;

export const { closeRtkModal } = removeACHDebitDetailsSlice.actions;
