import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import FilterIcon from "@/assets/svgs/FilterIcon";
import SearchIcon from "@/assets/svgs/SearchIcon";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Search from "@/components/Search";
import TabPanel from "@mui/lab/TabPanel";
import PlusIcon from "@/assets/svgs/PlusIcon";
import CandidateFilters from "@/pages/Candidates/CandidateFilters";
import CustomPagination from "@/elements/pagination/customPagination";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader from "@/elements/Loader";
import Dot from "@/assets/svgs/Dot";
import {
  GetUserDetails,
  formatPhoneNumber,
  firstLetterCapital,
} from "@/utils/helper";
import candidatesList from "@/rtk-apis/candidates/candidatesList";

function CandidatesListing({
  selectedIndex,
  changeIndex,
  setFormScreen,
  updateList,
  editCandidateMode,
  refreshList,
  setTabValue,
  value,
  setEditCandidateMode,
}) {
  const user = GetUserDetails();
  const dispatch = useDispatch();
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [candidateList, setCandidateList] = useState([]);
  const [page, setPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [applyFilters, setApplyFilters] = useState(false);
  const [FilterApplied, setFilterApplied] = useState(false);
  const [education, setEducation] = useState([]);
  const [experience, setExperience] = useState([0, 99]);
  const [skillSet, setSkillSet] = useState([]);

  const { data, loading } = useSelector((globalState) => {
    return globalState.candidatesListData;
  });

  useEffect(() => {
    candidatesListAPIData();
  }, [search, page, value, applyFilters, refreshList]);

  useEffect(() => {
    setCandidateList(data?.rows);
    updateList(data?.rows);
  }, [data]);

  const candidatesListAPIData = () => {
    dispatch(
      candidatesList({
        recruiterId: value === "1" ? "" : user?.recruiter?.recruiterId,
        page: search ? 1 : page,
        limit: 20,
        status: null,
        search,
        skillSet,
        education,
        experience,
      })
    );
  };

  const closeSearch = () => {
    setShowSearch(false);
    setSearch("");
    setPage(1);
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  const handleTablistChange = (e, value) => {
    setTabValue(value);
  };

  const apply = () => {
    setFilterApplied(true);
    setApplyFilters(!applyFilters);
    setShowFilter(false);
  };

  const clearFilters = () => {
    setSkillSet([]);
    setEducation([]);
    setExperience([0, 99]);
    setShowFilter(false);
    setFilterApplied(false);
    setApplyFilters(false);
  };

  const filterCount = () => {
    let filterCount = 0;
    if (education.length > 0) {
      filterCount += 1;
    }
    if (experience[0] !== 0 || experience[1] != 99) {
      filterCount += 1;
    }
    if (skillSet.length > 0) {
      filterCount += 1;
    }
    return `(${filterCount})`;
  };

  return (
    <div className="layoutGrid__col">
      <div
        className={`listCol ${showFilter && "listCol__filter"} ${
          showSearch && "listCol__search"
        }`}
      >
        <div className="listCol__head listing__head">
          <Search
            closeSearch={closeSearch}
            searchTerm={(value) => setSearch(value)}
            placeholder={"a candidate"}
          />
          <h2 className="listCol__head--title">Candidates</h2>
          <div className="listCol__head--actions">
            <button
              onClick={() => {
                setShowSearch(true);
              }}
              className="btn btn__white p_0"
            >
              <SearchIcon />
            </button>
            <button
              onClick={() => setShowFilter(true)}
              className="btn btn__white p_0"
            >
              <FilterIcon />
            </button>
            <button
              onClick={() => {
                setFormScreen(true);
                setEditCandidateMode(false);
              }}
              className="btn btn__white p_0"
              disabled={editCandidateMode ? true : false}
            >
              <PlusIcon />
            </button>
          </div>
        </div>
        <CandidateFilters
          experience={experience}
          closeFilter={() => setShowFilter(false)}
          education={education}
          experienceChange={(value) => setExperience(value)}
          skillSet={skillSet}
          skillSetChange={(val) => setSkillSet(val)}
          educationChange={(val) => setEducation(val)}
          apply={() => apply()}
          clearFilters={() => clearFilters()}
        />
        {FilterApplied && (
          <div className="listColTitle filterClear">
            <p>Filters applied {filterCount()}</p>
            <button className="filterClear__btn" onClick={() => clearFilters()}>
              Clear filter
            </button>
          </div>
        )}
        <Box className="tabContainer">
          <TabContext value={value}>
            <Box className="tabContainer__head">
              <TabList onChange={handleTablistChange}>
                <Tab label="Candidates" value="1" />
                <Tab label="My Candidates" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" className="tabContainer__item">
              <ul className="listCol__list listing__list">
                {candidateList?.length ? (
                  candidateList.map((candidate, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          selectedIndex === index
                            ? "listCol__list--item listCol__item--selected"
                            : "listCol__list--item"
                        }
                        onClick={() => changeIndex(index)}
                      >
                        <div className="item item--recruiter">
                          <div className="initials initials__md">
                            {candidate?.firstName?.charAt(0).toUpperCase()}
                          </div>
                          <div className="item__info">
                            <h4 className="item__info--title">
                              {firstLetterCapital(candidate?.firstName) +
                                " " +
                                firstLetterCapital(candidate?.lastName)}
                            </h4>
                            <div className="item__info--subtitle">
                              <p>{candidate?.education}</p>
                              <span>
                                <Dot />
                                {candidate?.experience > 0
                                  ? candidate?.experience === 1
                                    ? candidate?.experience + " Yr Exp"
                                    : candidate?.experience + " Yrs Exp"
                                  : "Fresher"}
                              </span>
                              {candidate?.role && (
                                <span>
                                  <Dot />
                                  {candidate?.role}
                                </span>
                              )}
                            </div>
                            <div className="item__info--subtitle">
                              <p>
                                <b>P:</b>
                                {candidate?.countryCode}{" "}
                                {formatPhoneNumber(candidate?.phoneNumber)}
                              </p>
                              <span>
                                <b>E:</b>
                                {candidate?.email}
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <div className="no-data">No Results Found!</div>
                )}
              </ul>
            </TabPanel>
            <TabPanel value="2" className="tabContainer__item">
              <ul className="listCol__list listing__list">
                {candidateList?.length ? (
                  candidateList.map((candidate, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          selectedIndex === index
                            ? "listCol__list--item listCol__item--selected"
                            : "listCol__list--item"
                        }
                        onClick={() => changeIndex(index)}
                      >
                        <div className="item item--recruiter">
                          <div className="initials initials__md">
                            {candidate?.firstName?.charAt(0).toUpperCase()}
                          </div>
                          <div className="item__info">
                            <h4 className="item__info--title">
                              {firstLetterCapital(candidate?.firstName) +
                                " " +
                                firstLetterCapital(candidate?.lastName)}
                            </h4>
                            <div className="item__info--subtitle">
                              <p>{candidate?.education}</p>
                              <span>
                                <Dot />
                                {candidate?.experience > 0
                                  ? candidate?.experience === 1
                                    ? candidate?.experience + " Yr Exp"
                                    : candidate?.experience + " Yrs Exp"
                                  : "Fresher"}
                              </span>
                              {candidate?.role && (
                                <span>
                                  <Dot />
                                  {candidate?.role}
                                </span>
                              )}
                            </div>
                            <div className="item__info--subtitle">
                              <p>
                                <b>P:</b>
                                {candidate?.countryCode}{" "}
                                {formatPhoneNumber(candidate?.phoneNumber)}
                              </p>
                              <span>
                                <b>E:</b>
                                {candidate?.email}
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <div className="no-data">No Results Found!</div>
                )}
              </ul>
            </TabPanel>
            <div className="pagination pagination__center">
              {candidateList?.length ? (
                <CustomPagination
                  page={page}
                  totalPageCount={Math.ceil(data?.count / 20)}
                  changePage={(event, value) => changePage(event, value)}
                />
              ) : (
                ""
              )}
            </div>
          </TabContext>
        </Box>
      </div>
      {loading && <Loader />}
    </div>
  );
}

export default CandidatesListing;
