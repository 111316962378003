import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { RECRUITER_DETAILS_ON_SIGNUP } from '@/rtk-apis/end-points';

const recruiterDetailsOnSignUp = createAsyncThunk('recruiterDetailsOnSignUp', async (email) => {
  try {
    const response = await httpService.get(`${RECRUITER_DETAILS_ON_SIGNUP}/${email}`, {});
    return response;
  } catch (error) {
    return error;
  }
});

export default recruiterDetailsOnSignUp;
