import React, { useState } from "react";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import LinkIcon from "@/assets/svgs/LinkIcon";
import EditIcon from "@/assets/svgs/EditIcon";
import { firstLetterCapital, formatPhoneNumber } from "@/utils/helper";
import { useDispatch, useSelector } from "react-redux";
import BackIcon from "@/assets/svgs/BackIcon";
import deleteCandidate from "@/rtk-apis/candidates/deleteCandidate";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import { closeRtkModal } from "@/store/slices/candidates/deleteCandidateSlice";
import NotificationButton from "../../components/elements/notificationButton";

function CandidateDetails(props) {
  const dispatch = useDispatch();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [OpenNotificationModal, setOpenNotificationModal] = useState(false);

  const deleteSelectedCandidate = () => {
    setOpenConfirmationModal(false);
    dispatch(
      deleteCandidate({
        candidateId: props?.SelectedCandidateData?.candidateId,
      })
    );
  };

  const deleteCandidateResponse = useSelector((globalState) => {
    return globalState.deleteCandidateResponse;
  });

  const closeModal = () => {
    dispatch(closeRtkModal());
  };

  return (
    <>
      {props?.candidateList?.length ? (
        <div className="dataCol">
          <div className="page page__full">
            <div className="page__head">
              <h2 className="page__head--title">
                <button
                  className="btn btn__transparent btn__back border_0 p_0"
                  type="button"
                  onClick={() => props.removeResponsiveClass()}
                >
                  <BackIcon />
                </button>
                {firstLetterCapital(props?.SelectedCandidateData?.firstName) +
                  " " +
                  firstLetterCapital(props?.SelectedCandidateData?.lastName)}
              </h2>
              <div className="listCol__head--actions">
                <NotificationButton
                  OpenNotificationModal={OpenNotificationModal}
                  setOpenNotificationModal={setOpenNotificationModal}
                />
                {props?.value === "2" && (
                  <Tooltip title="Edit" placement="bottom" arrow>
                    <button
                      onClick={props.openEditForm}
                      type="button"
                      className="btn btn__white btn__edit p_0"
                    >
                      <EditIcon />
                    </button>
                  </Tooltip>
                )}
              </div>
            </div>

            <div className="page__body">
              <div className="details">
                <section className="details__section">
                  <h4 className="details__section--title">Basic Details</h4>
                  <div className="fieldBox flexRow">
                    <div className="flexCol">
                      <label className="flexCol__label">First Name</label>
                      <div className="flexCol__text">
                        {props?.SelectedCandidateData?.firstName
                          ? firstLetterCapital(
                              props?.SelectedCandidateData?.firstName
                            )
                          : ""}
                      </div>
                    </div>
                    <div className="flexCol">
                      <label className="flexCol__label">Last Name</label>
                      <div className="flexCol__text">
                        {firstLetterCapital(
                          props?.SelectedCandidateData?.lastName
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="fieldBox flexRow">
                    <div className="flexCol">
                      <label className="flexCol__label">Email Id</label>
                      <div className="flexCol__text">
                        {props?.SelectedCandidateData?.email}
                      </div>
                    </div>
                    <div className="flexCol">
                      <label className="flexCol__label">Phone Number</label>
                      <div className="flexCol__text">
                        {props?.SelectedCandidateData?.countryCode}{" "}
                        {formatPhoneNumber(
                          props?.SelectedCandidateData?.phoneNumber
                        )}
                      </div>
                    </div>
                  </div>
                </section>
                <section className="details__section">
                  <h4 className="details__section--title">
                    Educational Details
                  </h4>
                  <div className="fieldBox flexRow">
                    <div className="flexCol">
                      <label className="flexCol__label">Attach Resume</label>
                      {props?.SelectedCandidateData?.resumeUrl ? (
                        <Link
                          to={props?.SelectedCandidateData?.resumeUrl}
                          target="_blank"
                          className="link link__primary link__center"
                        >
                          {
                            props?.SelectedCandidateData?.resumeUrl.split(
                              "/"
                            )[4]
                          }
                          <LinkIcon />
                        </Link>
                      ) : (
                        "--"
                      )}
                    </div>
                    <div className="flexCol">
                      <label className="flexCol__label">
                        Highest Education
                      </label>
                      <div className="flexCol__text">
                        {props?.SelectedCandidateData?.education || "--"}
                      </div>
                    </div>
                  </div>
                  <div className="fieldBox flexRow">
                    <div className="flexCol">
                      <label className="flexCol__label">
                        Name of Institution
                      </label>
                      <div className="flexCol__text">
                        {props?.SelectedCandidateData?.institutionName || "--"}
                      </div>
                    </div>
                    <div className="flexCol">
                      <label className="flexCol__label">Graduation Year</label>
                      <div className="flexCol__text">
                        {props?.SelectedCandidateData?.graduationYear || "--"}
                      </div>
                    </div>
                  </div>
                </section>
                <section className="details__section">
                  <h4 className="details__section--title">
                    Professional Details
                  </h4>
                  <div className="fieldBox flexRow">
                    <div className="flexCol">
                      <label className="flexCol__label">Experience</label>
                      <div className="flexCol__text">
                        {props?.SelectedCandidateData?.experience
                          ? props?.SelectedCandidateData?.experience <= 1
                            ? props?.SelectedCandidateData?.experience + " Year"
                            : props?.SelectedCandidateData?.experience +
                              " Years"
                          : "--"}
                      </div>
                    </div>
                    <div className="flexCol">
                      <label className="flexCol__label">LinkedIn Account</label>
                      <div className="flexCol__text">
                        {props?.SelectedCandidateData?.linkedin ? (
                          <Link
                            target="blank_"
                            to={
                              props?.SelectedCandidateData?.linkedin
                                ? props?.SelectedCandidateData?.linkedin
                                : "#"
                            }
                            className="link link__primary link__center"
                          >
                            {props?.SelectedCandidateData?.linkedin}
                            <LinkIcon />
                          </Link>
                        ) : (
                          "--"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="fieldBox flexRow">
                    <div className="flexCol">
                      <label className="flexCol__label">Role</label>
                      <div className="flexCol__text">
                        {props?.SelectedCandidateData?.role || "--"}
                      </div>
                    </div>
                  </div>
                  <div className="fieldBox">
                    <label className="flexCol__label">Skill set</label>
                    <div className="flexCol__text">
                      {props?.SelectedCandidateData?.skillset?.length
                        ? props?.SelectedCandidateData?.skillset.map(
                            (item, i) => {
                              return (
                                <span key={i}>
                                  {i + 1 ===
                                  props?.SelectedCandidateData?.skillset?.length
                                    ? item
                                    : `${item}, `}
                                </span>
                              );
                            }
                          )
                        : "--"}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-data">No Results Found!</div>
      )}
      {deleteCandidateResponse?.meta?.status && (
        <SuccessModal
          closeModal={closeModal}
          Heading={"Candidate deleted!"}
          sucessfullMsg={deleteCandidateResponse?.meta?.msg}
        />
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          closeModal={() => setOpenConfirmationModal(false)}
          onSubmit={deleteSelectedCandidate}
          heading={"Logout"}
          confirmationMessage={"Are you sure want to delete candidate?"}
        />
      )}
    </>
  );
}

export default CandidateDetails;
