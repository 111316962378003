import { createSlice } from '@reduxjs/toolkit';
import dashboardData from '@/rtk-apis/customer/dashboard/getDashboardData';

const initialState = {
  message: '',
  data: {},
  meta: {},
  token: '',
  loading: false,
  error: ''
};

const getDashboardDataSlice = createSlice({
  name: 'getDashboardDataSlice',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(dashboardData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(dashboardData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(dashboardData.rejected, (state, action) => {
        state.loading = false;
      });
  }

});

export default getDashboardDataSlice.reducer;

export const { closeRtkModal } = getDashboardDataSlice.actions;
