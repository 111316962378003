import React from "react";

const UserSearchSvg = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.77778 10.8958H4.88889C2.74112 10.8958 1 12.668 1 14.8542V16.8333H8.77778M12.6667 4.95833C12.6667 7.14446 10.9256 8.91667 8.77778 8.91667C6.63 8.91667 4.88889 7.14446 4.88889 4.95833C4.88889 2.77221 6.63 1 8.77778 1C10.9256 1 12.6667 2.77221 12.6667 4.95833Z"
        stroke="#6C737F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.667 17.6665L15.8545 15.854M16.8337 13.4998C16.8337 15.3408 15.3413 16.8332 13.5003 16.8332C11.6594 16.8332 10.167 15.3408 10.167 13.4998C10.167 11.6589 11.6594 10.1665 13.5003 10.1665C15.3413 10.1665 16.8337 11.6589 16.8337 13.4998Z"
        stroke="#6C737F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default UserSearchSvg;
