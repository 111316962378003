import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { RECRUITER_DEACTIVATE_ACCOUNT } from '@/rtk-apis/end-points';

const deactivateRecruiter = createAsyncThunk('deactivateRecruiter', async (body) => {
  try {
    const response = await httpService.post(RECRUITER_DEACTIVATE_ACCOUNT, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default deactivateRecruiter;