import { createSlice } from "@reduxjs/toolkit";
import staticData from "@/rtk-apis/staticData";

const initialState = {
  message: "",
  data: {},
  loading: false,
  error: "",
};

const staticDataSlice = createSlice({
  name: "staticData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(staticData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(staticData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(staticData.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default staticDataSlice.reducer;
