import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { ADD_ACH_DEBIT_DETAILS } from '@/rtk-apis/end-points';

const addACHDebitDetails = createAsyncThunk('addACHDebitDetails', async (body) => {
  try {
    const response = await httpService.post(ADD_ACH_DEBIT_DETAILS, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default addACHDebitDetails;
