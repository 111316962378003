import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import EducationSvg from "@/assets/svgs/EducationSvg";
import InstitutionSvg from "@/assets/svgs/InstitutionSvg";
import { firstLetterCapital, getYears } from "@/utils/helper";
import { Formik, Form, Field } from "formik";
import staticData from "@/rtk-apis/staticData";
import { useDispatch, useSelector } from "react-redux";
import { addEducationFormSchema } from "@/components/schema/schema";
import { GRADUATION_MONTH } from "@/utils/constants";
import ArrowDown from "@/assets/svgs/ArrowDown";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { INSTITUTION_LOGO_BUCKET } from "@/utils/constants";
import UniversitySvg from "@/assets/svgs/UniversitySvg";

const EducationalModal = ({ closeModal, onSubmit, data = [] }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    dispatch(staticData());
  };

  const { data: staticDetails } = useSelector((globalState) => {
    return globalState.static;
  });

  let initialValues = {
    education: "",
    institutionName: "",
    graduationYear: "",
    majorStudy: "",
  };

  const handleFormSubmit = async (values) => {
    onSubmit(values);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const getUnivercityLogo = (univercityName) => {
    let logo =
      staticDetails?.nameOfInstitutionObject?.length &&
      staticDetails?.nameOfInstitutionObject.find(
        (company) => company.value === univercityName
      )?.logoName;
    return logo;
  };

  return (
    <>
      <div className={"modal modalEducation modal--open"}>
        <div className="modal__dialog">
          <Formik
            initialValues={initialValues}
            validationSchema={addEducationFormSchema}
            onSubmit={(values) => handleFormSubmit(values)}
          >
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              touched,
              setFieldValue,
            }) => (
              <Form className="form" onKeyPress={handleKeyPress}>
                <div className="modal__content modal__content--lg">
                  <div className="modal__header">
                    <h2 className="modal__header--title">
                      Add Educational Details
                    </h2>
                  </div>
                  <div className="modal__body">
                    <div className="fieldBox fieldBox--row">
                      <div className="flexCol">
                        <div
                          className={`formField ${
                            errors?.education ? "fieldError" : ""
                          }`}
                        >
                          <label className="formField__label">Education</label>
                          <div className="formField__autoComplete">
                            <Autocomplete
                              id="tags-filled"
                              options={staticDetails?.qualification}
                              defaultValue={values?.education}
                              name="education"
                              onChange={(event, newValue) => {
                                setFieldValue("education", newValue);
                              }}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  placeholder="Select"
                                  className="autoCompleteInput"
                                  onBlur={(e) => {
                                    if (params?.inputProps?.value) {
                                      setFieldValue(
                                        "education",
                                        params.inputProps.value
                                      );
                                    }
                                  }}
                                />
                              )}
                            />
                            {/* <span className="formField__icon">
                            <EducationSvg />
                          </span> */}
                          </div>

                          <span className="formField__error">
                            {errors?.education}
                          </span>
                        </div>
                      </div>
                      <div className="flexCol">
                        <div
                          className={`formField ${
                            errors?.institutionName ? "fieldError" : ""
                          }`}
                        >
                          <label className="formField__label">
                            Name of Institution
                          </label>
                          <div className="formField__autoComplete formField__autoComplete--withLogo">
                            {/* <Autocomplete
                              id="tags-filled"
                              options={staticDetails?.nameOfInstitutionObject}
                              getOptionLabel={(option) => option?.value || ""}
                              defaultValue={values?.institutionName || ""}
                              name="institutionName"
                              onChange={(event, newValue) => {
                                setFieldValue(
                                  "institutionName",
                                  newValue?.value
                                );
                                setFieldValue(
                                  "institutionNameLogo",
                                  newValue?.logoName
                                );
                              }}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  placeholder="Select"
                                  className="autoCompleteInput"
                                  onBlur={(e) => {
                                    if (params?.inputProps?.value) {
                                      setFieldValue(
                                        "institutionName",
                                        params.inputProps.value
                                      );
                                    }
                                  }}
                                />
                              )}
                            /> */}
                            <Autocomplete
                              id="tags-filled"
                              options={staticDetails?.nameOfInstitutionObject}
                              getOptionLabel={(option) => option?.value || ""}
                              defaultValue={values?.institutionName || ""}
                              name="institutionName"
                              onChange={(event, newValue) => {
                                setFieldValue(
                                  "institutionName",
                                  newValue?.value
                                );
                                setFieldValue(
                                  "institutionNameLogo",
                                  newValue?.logoName
                                );
                                setFieldValue(
                                  "institutionName",
                                  newValue?.value
                                );
                              }}
                              freeSolo
                              renderOption={(props, option) => (
                                <li {...props}>
                                  {option.logoName ? (
                                    <img
                                      src={
                                        INSTITUTION_LOGO_BUCKET +
                                        option.logoName
                                      }
                                      alt={option.value}
                                      style={{
                                        width: 25,
                                        marginRight: 5,
                                      }}
                                    />
                                  ) : (
                                    <div className="autocompletelogo">
                                      <UniversitySvg />
                                    </div>
                                  )}
                                  {option?.value || ""}{" "}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  placeholder="Select"
                                  className="autoCompleteInput"
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <>
                                        {params.InputProps.startAdornment}
                                        {params.inputProps.value &&
                                        getUnivercityLogo(
                                          params.inputProps.value
                                        ) ? (
                                          <img
                                            className="autocompletelogo"
                                            src={
                                              params.inputProps.value &&
                                              INSTITUTION_LOGO_BUCKET +
                                                getUnivercityLogo(
                                                  params.inputProps.value
                                                )
                                            }
                                            alt="Selected"
                                          />
                                        ) : (
                                          <div className="autocompletelogo">
                                            <UniversitySvg />
                                          </div>
                                        )}
                                      </>
                                    ),
                                  }}
                                  onBlur={(e) => {
                                    if (params?.inputProps?.value) {
                                      setFieldValue(
                                        "institutionName",
                                        params.inputProps.value
                                      );
                                    }
                                  }}
                                />
                              )}
                            />
                          </div>
                          {/*                           
                          <span className="formField__icon">
                            <InstitutionSvg />
                          </span> */}
                          <span className="formField__error">
                            {errors?.institutionName}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="fieldBox fieldBox--row">
                      <div className="flexCol">
                        <div
                          className={`formField ${
                            errors?.graduationStartMonth ? "fieldError" : ""
                          }`}
                        >
                          <label className="formField__label">
                            Start Month
                          </label>
                          <FormControl
                            sx={{ width: "100%" }}
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              name="graduationStartMonth"
                              onChange={(event) => {
                                setFieldValue(
                                  "graduationStartMonth",
                                  event?.target?.value
                                );
                              }}
                              IconComponent={() => <ArrowDown />}
                              displayEmpty
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ opacity: "0.8" }}>
                                      Select Month
                                    </span>
                                  );
                                }

                                return selected;
                              }}
                            >
                              {staticDetails?.yearMonth?.month.map(
                                (item, index) => (
                                  <MenuItem value={item} key={index}>
                                    {item}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                          <span className="formField__error">
                            {errors?.graduationStartMonth}
                          </span>
                        </div>
                      </div>
                      <div className="flexCol">
                        <div
                          className={`formField ${
                            errors?.graduationStartYear ? "fieldError" : ""
                          }`}
                        >
                          <label className="formField__label">Start Year</label>
                          <FormControl
                            sx={{ width: "100%" }}
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              name="graduationStartYear"
                              onChange={(event) => {
                                setFieldValue(
                                  "graduationStartYear",
                                  event?.target?.value
                                );
                              }}
                              IconComponent={() => <ArrowDown />}
                              displayEmpty
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ opacity: "0.8" }}>
                                      Select Year
                                    </span>
                                  );
                                }

                                return selected;
                              }}
                            >
                              {getYears(
                                staticDetails?.yearMonth?.startYear
                              ).map((item, index) => (
                                <MenuItem value={item} key={index}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <span className="formField__error">
                            {errors?.graduationStartYear}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="fieldBox fieldBox--row">
                      <div className="flexCol">
                        <div
                          className={`formField ${
                            errors?.graduationEndMonth ? "fieldError" : ""
                          }`}
                        >
                          <label className="formField__label">End Month</label>
                          <FormControl
                            sx={{ width: "100%" }}
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              name="graduationEndMonth"
                              onChange={(event) => {
                                setFieldValue(
                                  "graduationEndMonth",
                                  event?.target?.value
                                );
                              }}
                              IconComponent={() => <ArrowDown />}
                              displayEmpty
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ opacity: "0.8" }}>
                                      Select Month
                                    </span>
                                  );
                                }

                                return selected;
                              }}
                            >
                              {staticDetails?.yearMonth?.month.map(
                                (item, index) => (
                                  <MenuItem value={item} key={index}>
                                    {item}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                          <span className="formField__error">
                            {errors?.graduationEndMonth}
                          </span>
                        </div>
                      </div>
                      <div className="flexCol">
                        <div
                          className={`formField ${
                            errors?.graduationEndYear ? "fieldError" : ""
                          }`}
                        >
                          <label className="formField__label">End Year</label>
                          <FormControl
                            sx={{ width: "100%" }}
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              name="graduationEndYear"
                              onChange={(event) => {
                                setFieldValue(
                                  "graduationEndYear",
                                  event?.target?.value
                                );
                              }}
                              IconComponent={() => <ArrowDown />}
                              displayEmpty
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ opacity: "0.8" }}>
                                      Select Year
                                    </span>
                                  );
                                }

                                return selected;
                              }}
                            >
                              {getYears(
                                staticDetails?.yearMonth?.startYear
                              ).map((item, index) => (
                                <MenuItem value={item} key={index}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <span className="formField__error">
                            {errors?.graduationEndYear}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="fieldBox w_100">
                      <div
                        className={`formField ${
                          errors?.majorStudy &&
                          touched?.majorStudy &&
                          errors?.majorStudy
                            ? "fieldError"
                            : ""
                        }`}
                      >
                        <label className="formField__label">
                          Major/Field of Study
                        </label>
                        <div className="formField__autoComplete">
                          <Autocomplete
                            id="tags-filled"
                            options={staticDetails?.majorFieldOfStudy}
                            defaultValue={""}
                            name="majorStudy"
                            onChange={(event, newValue) => {
                              setFieldValue("majorStudy", newValue);
                            }}
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                placeholder="Select"
                                className="autoCompleteInput"
                                onBlur={(e) => {
                                  if (params?.inputProps?.value) {
                                    setFieldValue(
                                      "majorStudy",
                                      params.inputProps.value
                                    );
                                  }
                                }}
                              />
                            )}
                          />
                        </div>

                        {/* <span className="formField__icon">
                          <EducationSvg />
                        </span> */}
                        <span className="formField__error">
                          {errors?.majorStudy}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="modal__footer">
                    <div className="btn__group">
                      <button className="btn btn__primary" type="submit">
                        Add
                      </button>
                      <button
                        className="btn btn__white"
                        onClick={() => closeModal()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default EducationalModal;
