import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { ADD_TAG_TO_CANDIDATE } from '@/rtk-apis/end-points';

const addTagToCandidate = createAsyncThunk('addTagToCandidate', async (body) => {
  try {
    const response = await httpService.post(ADD_TAG_TO_CANDIDATE, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default addTagToCandidate;
