import React, { useEffect } from "react";
import moment from "moment";
import MeetingStatus from "@/components/HOCs/MeetingStatus";

function InterviewsLailaListing({
  selectedIndex,
  selectedRecruiterInterview,
  data,
  setAddResponsiveClass,
}) {
  useEffect(() => {
    if (data?.rows?.length) selectedRecruiterInterview(0, data?.rows[0]);
  }, [data]);

  return (
    <>
      <ul className="listCol__list listing__list">
        {data?.rows?.length ? (
          data?.rows?.map((selectedInterview, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  selectedRecruiterInterview(index, selectedInterview);
                  setAddResponsiveClass(true);
                }}
                className={
                  selectedIndex === index
                    ? "listCol__list--item listCol__item--selected"
                    : "listCol__list--item"
                }
              >
                <div className="item item--recruiter item--jobs">
                  <div className="item__info">
                    <h4 className="item__info--title">
                      {selectedInterview?.title}
                    </h4>
                    <div className="item__info--subtitle">
                      <p>
                        {moment(selectedInterview?.meeting_start_time).format(
                          "D MMM, YYYY"
                        )}
                      </p>
                    </div>
                    <div className="item__info--subtitle">
                      <span className="status">
                        Status:{" "}
                        <span className="text--success">
                          <MeetingStatus
                            time={selectedInterview.meeting_end_time}
                            interviewStatus={selectedInterview?.status}
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <div className="no-data">No Results Found!</div>
        )}
      </ul>
    </>
  );
}

export default InterviewsLailaListing;
