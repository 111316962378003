import React from "react";

const TaxInformationSvg = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_317_6398)">
        <path
          d="M0.625 0.625V12.338H10.1012L12.2849 14.5206C12.6044 14.8401 13.0035 15 13.4028 15C13.8021 15 14.2012 14.8401 14.5206 14.5206C14.8401 14.2012 15 13.8021 15 13.4028C15 13.0035 14.8401 12.6044 14.5206 12.2849L12.338 10.1012V0.625H0.625ZM1.68981 1.68981H11.2731V9.03641L10.3685 8.13174L7.5463 7.5463L8.13174 10.3685L9.03641 11.2731H1.68981V1.68981ZM3.28704 3.28704V4.35185H9.67593V3.28704H3.28704ZM3.28704 5.41667V6.48148H7.5463V5.41667H3.28704ZM8.93035 8.93035L9.83606 9.09048L13.775 13.0305C13.988 13.2435 13.988 13.5621 13.775 13.775C13.5621 13.988 13.2435 13.988 13.0305 13.775L9.09048 9.83606L8.93035 8.93035Z"
          fill="#6C737F"
        />
      </g>
      <defs>
        <clipPath id="clip0_317_6398">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default TaxInformationSvg;
