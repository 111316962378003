import React from "react";
import { useSelector, useDispatch } from "react-redux";
import FeedbackPage from "@/components/HOCs/FeedbackPage";
import { SuccessModal } from "@/components/modals/SuccessModal";
import Loader from "@/elements/Loader";
import approveRejectInterviewCandidate from "@/rtk-apis/customer/myJobs/interview/approveRejectinterviewCandidate";
import { closeRtkModal } from "@/store/slices/customer/myJobs/interview/approveRejectInterviewCandidateSlice";
import { getApproveOrRejectRole, GetUserDetails } from "@/utils/helper";
import ErrorModal from "@/components/modals/error-modal";

function InterviewsFeedback({
  changeInterviewViewType,
  interviewData,
  selectedJobData,
}) {
  const user = GetUserDetails();
  const dispatch = useDispatch();

  const { loading, meta } = useSelector((globalState) => {
    return globalState.approveRejectInterviewCandidate;
  });

  const closeModal = () => {
    dispatch(closeRtkModal());
    changeInterviewViewType("list", interviewData);
  };

  const closeErrorModal = () => {
    dispatch(closeRtkModal());
  };

  const navigateToBack = () => {
    changeInterviewViewType("list", interviewData);
  };

  const rejectOrApproveCandidate = (feedbackData) => {
    dispatch(
      approveRejectInterviewCandidate({
        interviewId: interviewData?.interviewId,
        companyId: user?.customer?.companyId,
        jobId: interviewData?.jobId,
        recruiterId: interviewData?.recruiterId,
        candidateId: interviewData?.candidateId,
        employeeId: user?.customer?.employeeId,
        screeningId: interviewData?.screeningId,
        role: getApproveOrRejectRole(selectedJobData),
        jmtFeedback: [
          {
            employeeId: user?.customer?.employeeId,
            firstName: user?.customer?.firstName,
            lastName: user?.customer?.lastName,
            status: interviewData?.action,
            feedBackData: feedbackData,
          },
        ],
      })
    );
  };

  return (
    <>
      <FeedbackPage
        heading={
          interviewData?.action == "Rejected"
            ? "Reason to reject"
            : "Send us your feedback!"
        }
        onPageSubmit={rejectOrApproveCandidate}
        btnTitle={interviewData?.action == "Rejected" ? "Reject" : "Approve"}
        onBackClick={navigateToBack}
      />
      {loading && <Loader />}
      {meta?.status && (
        <SuccessModal
          sucessfullMsg={
            interviewData?.action == "Approved"
              ? "Candidate has been approved successfully."
              : "Candidate has been rejected successfully."
          }
          Heading={
            interviewData?.action == "Approved"
              ? "Candidate Approved!"
              : "Candidate Rejected!"
          }
          closeModal={closeModal}
        />
      )}
      {meta?.status === false && (
        <ErrorModal
          errorMessage={meta?.msg}
          closeModal={closeErrorModal}
          footerVisible={true}
        />
      )}
    </>
  );
}

export default InterviewsFeedback;
