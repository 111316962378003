import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { GET_CUSTOMER_COMPANY_PROFILE } from '@/rtk-apis/end-points';

const companyProfileDetails = createAsyncThunk('companyProfileDetails', async (companyId) => {
  try {
    const response = await httpService.get(`${GET_CUSTOMER_COMPANY_PROFILE}${companyId}`, {});
    return response;
  } catch (error) {
    return error;
  }
});

export default companyProfileDetails;
