import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CUSTOMER_BILLING_CANCEL_PAYMENT } from '@/rtk-apis/end-points';

const billingCancelPayment = createAsyncThunk('billingCancelPayment', async (sessionId) => {
  try {
    const response = await httpService.get(`${CUSTOMER_BILLING_CANCEL_PAYMENT}${sessionId}`, {});
    return response;
  } catch (error) {
    return error;
  }
});

export default billingCancelPayment;
