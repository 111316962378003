import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import SearchSmallIcon from "@/assets/svgs/SearchSmallIcon";
import PlayIcon from "@/assets/svgs/PlayIcon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  GetUserDetails,
  firstLetterCapital,
  saveInterviewDetails,
  checkForAdminAndHiringLead,
} from "@/utils/helper";
import { useSelector, useDispatch } from "react-redux";
import candidateList from "@/rtk-apis/customer/myJobs/candidates/candidateList";
import checkSchedulePage from "@/rtk-apis/interviews/checkSchedulePage";
import { closertkmodal } from "@/store/slices/interviews/checkSchedulePageSlice";
import CustomPagination from "@/elements/pagination/customPagination";
import GoTOPage from "@/elements/gotopage/gotopage";
import ScheduleInterviewModal from "@/components/modals/ScheduleInterview";
import Loader from "@/elements/Loader";
import _ from "lodash";
import ErrorModal from "@/components/modals/error-modal";
import getCustomersEmployeeList from "@/rtk-apis/customer/myJobs/getCustomersEmployeeList";
import { APP_URL } from "@/utils/constants";
import SortColumn from "@/elements/SortColumn";

function CandidatesListing({ changeCandidateViewType, selectedJobData }) {
  const dispatch = useDispatch();
  const user = GetUserDetails();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [nylusData, setNylusData] = useState({});
  const [OpenErrorModal, setOpenErrorModal] = useState(false);

  const [sortByASC, setSortByASC] = useState(true);
  const [sortByColoumn, setSortByColoumn] = useState("");

  const { data, loading } = useSelector((globalState) => {
    return globalState.candidateListing;
  });

  const checkSchedulePageResponse = useSelector((globalState) => {
    return globalState.checkSchedulePage;
  });

  useEffect(() => {
    let body = {
      companyId: user?.customer?.companyId,
      from: "addjob",
      limit: 20,
      page: 1,
      search: "",
    };
    dispatch(getCustomersEmployeeList(body));
  }, []);

  const EmployeeList = useSelector((globalState) => {
    return globalState.customerEmployeeList;
  });

  const getMembers = () => {
    const employees = EmployeeList?.data?.rows?.filter(
      (item) => item?.role == "Company Admin"
    );
    let members = selectedJobData?.jobTeamMember
      ?.concat(selectedJobData?.hiringLead)
      ?.concat(employees);
    let uniqueMembers = _.uniqBy(members, "employeeId");
    return uniqueMembers;
  };

  useEffect(() => {
    getCandidateListAPIData();
  }, [search, page, selectedJobData?.jobId, sortByASC, sortByColoumn]);

  const getCandidateListAPIData = () => {
    dispatch(
      candidateList({
        jobId: selectedJobData?.jobId,
        page: search ? 1 : page,
        limit: 20,
        search,
        columnName: sortByColoumn,
        sort: sortByASC ? "ASC" : "DESC",
      })
    );
  };

  const getSortByData = (columnName) => {
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  const scheduleInterview = (employeeId) => {
    let jtmMemberIds = employeeId.map((item) => item.employeeId);
    let jtmMemberEmail = employeeId.map((item) => item.email);
    setNylusData({ ...nylusData, employeeId });
    hitNylus(jtmMemberEmail, jtmMemberIds);
    setOpenScheduleModal(false);
  };

  const hitNylus = (jtmMemberEmail, jtmMemberIds) => {
    saveInterviewDetails({
      companyId: selectedJobData?.companyId,
      jobId: selectedJobData?.jobId,
      recruiterId: selectedJobData?.recruiter?.recruiterId,
      jmtId: jtmMemberIds,
      candidateId: nylusData?.candidateId,
      employeeId: user?.customer?.employeeId,
      screeningId: nylusData?.screeningId,
    });
    const token = JSON.parse(localStorage.getItem("token"));
    let { nylasAccessToken } = token?.customer;
    dispatch(
      checkSchedulePage({
        accessToken: nylasAccessToken,
        jtmMember: jtmMemberEmail,
        name: nylusData?.candidateName,
        email: nylusData?.candidateEmail,
        url: `${APP_URL}customer-thankyou`,
        meetingUrl: `${APP_URL}meeting`,
      })
    );
  };

  const openScheduleInterviewModal = (data) => {
    setNylusData({
      ...nylusData,
      candidateId: data?.candidateId,
      screeningId: data?.sreeningId,
      candidateName:
        data?.candidate?.firstName + " " + data?.candidate?.lastName,
      candidateEmail: data?.candidate?.email,
    });
    setOpenScheduleModal(true);
  };

  const removeNylusUrl = () => {
    dispatch(closertkmodal());
  };

  useEffect(() => {
    if (typeof checkSchedulePageResponse?.data == "string") {
      window.open(`${checkSchedulePageResponse?.data}`, "_blank");
      removeNylusUrl();
    }
  }, [checkSchedulePageResponse?.data]);

  const searchCandidate = useCallback(
    _.debounce((searchText) => setSearch(searchText), 500),
    []
  );

  return (
    <>
      <div>
        <div className="tabContainer__item--head">
          <div className="listCol__head--actions w_100">
            <div className="formRow formRow__4 formRow--responsive m_0 w_100">
              <div className="formCol">
                <div className="formField">
                  <div className="formField__group sm formSearch">
                    <span className="formField__icon">
                      <SearchSmallIcon />
                    </span>
                    <input
                      type="text"
                      className="formField__input"
                      placeholder="Search a candidate"
                      onChange={(e) => searchCandidate(e?.target?.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TableContainer
          component={Paper}
          className="tableContainer"
          style={{ height: "calc(100dvh - 249px)" }}
        >
          <Table className="table tableClickable">
            <TableHead>
              <TableRow>
                <TableCell>S.</TableCell>
                <TableCell onClick={() => getSortByData("firstName")}>
                  <SortColumn thName={"Name"} sortByASC={sortByASC} />
                </TableCell>
                <TableCell onClick={() => getSortByData("email")}>
                  <SortColumn thName={"Email"} sortByASC={sortByASC} />
                </TableCell>
                <TableCell>Feedbacks</TableCell>
                {checkForAdminAndHiringLead(
                  selectedJobData,
                  user?.customer?.role
                ) && <TableCell>Interviews</TableCell>}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.rows?.length
                ? data?.rows?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          changeCandidateViewType("candidateDetail", row)
                        }
                      >
                        <div className="profile">
                          <div className="profile__initials">
                            {row?.candidate?.firstName
                              ?.charAt(0)
                              ?.toUpperCase()}
                          </div>
                          <span className="cellText__title">
                            {firstLetterCapital(row?.candidate?.firstName) +
                              " " +
                              firstLetterCapital(row?.candidate?.lastName)}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <span className="cellText__dark">
                            {row?.candidate?.email}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <Link
                            onClick={() =>
                              changeCandidateViewType("candidateFeedBack", row)
                            }
                            className="cellText__dark cell--link"
                          >
                            Feedback
                          </Link>
                        </div>
                      </TableCell>
                      {checkForAdminAndHiringLead(
                        selectedJobData,
                        user?.customer?.role
                      ) && (
                        <TableCell>
                          <div className="cellText">
                            <button
                              type="button"
                              onClick={() => openScheduleInterviewModal(row)}
                              className={"cellText__dark cell--link"}
                              // className={`cellText__dark cell--link ${selectedJobData?.status ===
                              //   "Closed" ||
                              //   selectedJobData?.status ===
                              //     "paused"}  ? formField__disabled : ""`}
                              // disabled={
                              //   selectedJobData?.status === "Closed" ||
                              //   selectedJobData?.status === "paused"
                              //     ? true
                              //     : false
                              // }
                            >
                              Schedule
                            </button>
                          </div>
                        </TableCell>
                      )}
                      <TableCell align="right">
                        <div className="actions flex--end">
                          <Link
                            to={
                              row?.candidate?.resumeUrl
                                ? row?.candidate?.resumeUrl
                                : "#"
                            }
                            onClick={(e) => {
                              if (!row?.candidate?.resumeUrl) {
                                e.preventDefault();
                                setOpenErrorModal(true);
                              }
                            }}
                            target="_blank"
                            className="btn btn__white"
                          >
                            Resume
                          </Link>
                          <button
                            to=""
                            target="_blank"
                            className="btn btn__white"
                            onClick={() =>
                              changeCandidateViewType("candidateRecording", row)
                            }
                          >
                            View Rec.
                            <PlayIcon />
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
          {!data?.rows?.length && (
            <div className="no-data">No Results Found!</div>
          )}
        </TableContainer>
        <div className="pagination">
          <div className="pagination__result">
            {`${data?.count > page * 20 ? 20 : data?.count} of ${
              data?.count
            } results`}
          </div>
          {data?.count ? (
            <CustomPagination
              totalPageCount={Math.ceil(data?.count / data?.count)}
              changePage={(event, value) => changePage(event, value)}
            />
          ) : (
            ""
          )}
          {data?.count ? (
            <div className="pagination__actions">
              <GoTOPage changePage={changePage} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {openScheduleModal && (
        <ScheduleInterviewModal
          closeModal={() => setOpenScheduleModal(false)}
          onSubmit={scheduleInterview}
          data={getMembers()}
        />
      )}
      {OpenErrorModal && (
        <ErrorModal
          errorMessage={"Resume is not avaliable!"}
          closeModal={() => setOpenErrorModal(false)}
          footerVisible={true}
        />
      )}
      {(loading || checkSchedulePageResponse?.loading) && <Loader />}
    </>
  );
}

export default CandidatesListing;
