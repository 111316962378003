import React, { useState } from "react";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomPagination from "@/elements/pagination/customPagination";
import GoTOPage from "@/elements/gotopage/gotopage";
import { firstLetterCapital, getInitial, titleCase } from "@/utils/helper";
import Loader from "@/elements/Loader";
import ErrorModal from "@/components/modals/error-modal";
import SortColumn from "@/elements/SortColumn";
import Tooltip from "@mui/material/Tooltip";
import Approve from "@/assets/svgs/Approve";
import Reject from "@/assets/svgs/Reject";
import FilterIcon from "@/assets/svgs/FilterIcon";
import CloseIconCircle from "@/assets/svgs/CloseIconCircle";
import MultiSelectTagFilter from '@/components/Filters/multiSelectTagFilter';
function ApprovedCandidatesListings({
  approvedCandidateData,
  setOpenFeedbackScreen,
  page,
  changePage,
  loading,
  setCandidateData,
  getSortByData,
  sortByASC,
  sortByColoumn,
  allFilterTag,
  handleTagFilterChange,
  handleTagFilterClose
}) {
  const [OpenErrorModal, setOpenErrorModal] = useState(false);
  return (
    <>
      <TableContainer
        component={Paper}
        className="tableContainer"
        style={{ height: "calc(100dvh - 182px)" }}
      >
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>S.</TableCell>
              <TableCell onClick={() => getSortByData("firstName")}>
                <SortColumn thName={"Name"} sortByASC={sortByASC} />
              </TableCell>
              <TableCell onClick={() => getSortByData("email")}>
                <SortColumn thName={"Email"} sortByASC={sortByASC} />
              </TableCell>
              <TableCell>Resume</TableCell>
              <TableCell>Feedbacks</TableCell>
              <TableCell>
                <div className="sortingDiv tagFilter">
                  Tags{" "}
                  <MultiSelectTagFilter
                    labels={allFilterTag?.data?.rows}
                    applyFilter={handleTagFilterChange}
                    clearFilter={handleTagFilterClose}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {approvedCandidateData?.rows?.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    1
                  </TableCell>
                  <TableCell>
                    <div className="profile">
                      <div className="profile__initials">
                        {getInitial(row?.candidate?.firstName)}
                      </div>
                      <span className="profile__name">
                        {firstLetterCapital(row?.candidate?.firstName)}{" "}
                        {firstLetterCapital(row?.candidate?.lastName)}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="cellText">
                      <span className="cellText__dark">
                        {row?.candidate?.email}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="actions">
                      <Link
                        className="btn btn__white"
                        to={
                          row?.candidate?.resumeUrl
                            ? row?.candidate?.resumeUrl
                            : "#"
                        }
                        target="_blank"
                        onClick={(e) => {
                          if (!row?.candidate?.resumeUrl) {
                            e.preventDefault();
                            setOpenErrorModal(true);
                          }
                        }}
                      >
                        Resume
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="cellText">
                      <span
                        className="cellText__dark cell--link"
                        onClick={() => {
                          setOpenFeedbackScreen(true);
                          setCandidateData(row);
                        }}
                      >
                        Feedbacks
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className="widthAuto">
                  <div className="cellText cellText--tag">
                    {row?.interview?.tag?.map(item =>(<span key={`tag${item?.id}`} className="btn btn__primary btn__rounded" style={{background: item?.colorCode}}>
                      {titleCase(item?.tagName)}
                    </span>))}
                  </div>
                </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {!approvedCandidateData?.rows?.length && (
          <div className="no-data">No Results Found!</div>
        )}
      </TableContainer>
      <div className="pagination">
        <div className="pagination__result">
          {`${
            approvedCandidateData?.count > page * 20
              ? 20
              : approvedCandidateData?.count
          } of ${approvedCandidateData?.count} results`}
        </div>
        {approvedCandidateData?.count ? (
          <CustomPagination
            totalPageCount={Math.ceil(
              approvedCandidateData?.count / approvedCandidateData?.count
            )}
            changePage={(event, value) => changePage(event, value)}
          />
        ) : (
          ""
        )}
        {approvedCandidateData?.count ? (
          <div className="pagination__actions">
            <GoTOPage changePage={changePage} />
          </div>
        ) : (
          ""
        )}
      </div>
      {OpenErrorModal && (
        <ErrorModal
          errorMessage={"Resume is not avaliable!"}
          closeModal={() => setOpenErrorModal(false)}
          footerVisible={true}
        />
      )}
      {loading && <Loader />}
    </>
  );
}

export default ApprovedCandidatesListings;
