import getSearchField from '@/rtk-apis/customer/myJobs/getSearchField';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: '',
  data: {},
  meta: {},
  token: '',
  loading: false,
  error: ''
};

const getSearchFieldSlice = createSlice({
  name: 'getSearchField',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearchField.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSearchField.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(getSearchField.rejected, (state, action) => {
        state.loading = false;
      });
  }

});

export default getSearchFieldSlice.reducer;

export const { closeRtkModal } = getSearchFieldSlice.actions;
