import React from "react";

function AssignedIcon() {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="80" height="80" rx="40" fill="#12B76A" />
      <path
        d="M48.8333 58.75V54.5833C48.8333 52.3732 47.9553 50.2536 46.3925 48.6908C44.8297 47.128 42.7101 46.25 40.5 46.25H25.9166C23.7065 46.25 21.5869 47.128 20.0241 48.6908C18.4613 50.2536 17.5833 52.3732 17.5833 54.5833V58.75M50.9166 37.9167L55.0833 42.0833L63.4166 33.75M41.5416 29.5833C41.5416 34.1857 37.8107 37.9167 33.2083 37.9167C28.6059 37.9167 24.875 34.1857 24.875 29.5833C24.875 24.981 28.6059 21.25 33.2083 21.25C37.8107 21.25 41.5416 24.981 41.5416 29.5833Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AssignedIcon;
