import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { ADD_PAYPAL_DETAILS } from '@/rtk-apis/end-points';

const addPaypalDetails = createAsyncThunk('addPaypalDetails', async (body) => {
  try {
    const response = await httpService.put(ADD_PAYPAL_DETAILS, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default addPaypalDetails;
