import React, { useState } from "react";
import SideBar from "@/components/layout/SideBar";
import RecruiterBillingJobsListing from "@/pages/RecruiterBillingPayments/RecruiterBillingJobsListing";
import RecruiterBillingCandidateListing from "@/pages/RecruiterBillingPayments/RecruiterBillingCandidateListing";
import { useSelector } from "react-redux";

const RecruiterBillingpayments = () => {
  const [ActiveJobId, setActiveJobId] = useState("");
  const [status, setStatus] = useState("All");
  const [search, setSearch] = useState("");
  const [TotalRecievedAmount, setTotalRecievedAmount] = useState("");
  const [addResponsiveClass, setAddResponsiveClass] = useState(false);
  const { data, loading } = useSelector((globalState) => {
    return globalState.getRecuiterBillingJobList;
  });

  const onJobSelect = (jobid, amount) => {
    setActiveJobId(jobid);
    setSearch("");
    setStatus("All");
    setTotalRecievedAmount(amount);
  };
  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid">
          <RecruiterBillingJobsListing
            onJobSelect={(jobid, amount) => onJobSelect(jobid, amount)}
            ActiveJobId={ActiveJobId}
            setAddResponsiveClass={setAddResponsiveClass}
          />
          <RecruiterBillingCandidateListing
            jobs={data}
            ActiveJobId={ActiveJobId}
            setStatus={setStatus}
            status={status}
            setSearch={setSearch}
            search={search}
            TotalRecievedAmount={TotalRecievedAmount}
            setAddResponsiveClass={setAddResponsiveClass}
            addResponsiveClass={addResponsiveClass}
          />
        </div>
      </div>
    </>
  );
};

export default RecruiterBillingpayments;
