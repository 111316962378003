import { createContext } from "react";
import { getSocket } from "./socket";
import React, { useState, useEffect } from "react";

export const TriggerContext = createContext();

export const TriggerProvider = ({ children }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    const socket = getSocket();
    const handleNotificationCount = (trigger) => {
      if (trigger) {
        setTrigger(trigger);
      }
    };
    socket.on("notificationStatus", handleNotificationCount);
    return () => {
      socket.off("notificationStatus", handleNotificationCount);
    };
  }, [setTrigger]);

  return (
    <TriggerContext.Provider value={{ trigger, setTrigger }}>
      {children}
    </TriggerContext.Provider>
  );
};
