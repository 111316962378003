import { createSlice } from "@reduxjs/toolkit";
import getCustomerAllTags from "@/rtk-apis/customer/settings/customerTags/getCustomerAllTags";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const getCustomerAllTagsSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    closeRtkModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerAllTags.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCustomerAllTags.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(getCustomerAllTags.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default getCustomerAllTagsSlice.reducer;

export const { closeRtkModal } = getCustomerAllTagsSlice.actions;
