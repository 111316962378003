import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { ADD_WITHDRAWL_DETAILS } from '@/rtk-apis/end-points';

const addWithdrawlDetails = createAsyncThunk('addWithdrawlDetails', async (body) => {
  try {
    const response = await httpService.put(ADD_WITHDRAWL_DETAILS, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default addWithdrawlDetails;
