import React from "react";

function PlusIconCircle() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.3125"
        y="0.3125"
        width="15.375"
        height="15.375"
        rx="7.6875"
        fill="white"
      />
      <rect
        x="0.3125"
        y="0.3125"
        width="15.375"
        height="15.375"
        rx="7.6875"
        stroke="#150823"
        stroke-width="0.625"
      />
      <path
        d="M8.00002 4.35352V11.6452M4.35419 7.99935H11.6459"
        stroke="#150823"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PlusIconCircle;
