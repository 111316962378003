import React from "react";
import SideBar from "@/components/layout/SideBar";

const CandidateDashboard = () => {
  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid layoutGrid--full">
          <div className="layoutGrid__col">
            <div className="page page__full">
              <div className="page__head">
                <h2 className="listCol__head--title">Home</h2>
              </div>
              <div className="page__body bg--white">
                <div className="cardContainer">
                  <ul className="cardList">
                    <li>COMING SOON....</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateDashboard;
