import React, { useState } from "react";
import TaxPayeridentificationEdit from "@/pages/Settings/TaxInformation/TaxPayeridentificationEdit";
import TaxPayerIdentificationView from "@/pages/Settings/TaxInformation/TaxPayerIdentificationView";

const TaxPayerIdentificationDetails = ({
  taxInfoStatus,
  taxDetails,
  refreshTaxInfo,
  residenceCountry
}) => {
  const [
    openTaxPayerIdentificationEditPage,
    setOpenTaxPayerIdentificationEditPage,
  ] = useState(false);
  const openTaxPayerIdentificationEdit = (value) => {
    setOpenTaxPayerIdentificationEditPage(value);
    if (!value) {
      refreshTaxInfo();
    }
  };
  return (
    <>
      {openTaxPayerIdentificationEditPage ? (
        <TaxPayeridentificationEdit
          changeTaxPayerIdentificationView={openTaxPayerIdentificationEdit}
          taxDetails={taxDetails}
          residenceCountry={residenceCountry}
        />
      ) : (
        <TaxPayerIdentificationView
          changeTaxPayerIdentificationView={openTaxPayerIdentificationEdit}
          taxInfoStatus={taxInfoStatus}
          taxDetails={taxDetails}
        />
      )}
    </>
  );
};

export default TaxPayerIdentificationDetails;
