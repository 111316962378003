import React from "react";

function ProfileSvg() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1663 18V16.3333C17.1663 15.4493 16.8152 14.6014 16.19 13.9763C15.5649 13.3512 14.7171 13 13.833 13H7.16634C6.28229 13 5.43444 13.3512 4.80932 13.9763C4.1842 14.6014 3.83301 15.4493 3.83301 16.3333V18M13.833 6.33333C13.833 8.17428 12.3406 9.66667 10.4997 9.66667C8.65873 9.66667 7.16634 8.17428 7.16634 6.33333C7.16634 4.49238 8.65873 3 10.4997 3C12.3406 3 13.833 4.49238 13.833 6.33333Z"
        stroke="#384250"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ProfileSvg;
