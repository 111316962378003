import { createSlice } from "@reduxjs/toolkit";
import profileDeactivate from "@/rtk-apis/customer/myProfile/profileDeactivate";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: ""
};

const profileDeactivateSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    closeDeactivateModal (state, action) {
      state.meta = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(profileDeactivate.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(profileDeactivate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(profileDeactivate.rejected, (state, action) => {
        state.loading = false;
      });
  }
});

export default profileDeactivateSlice.reducer;

export const { closeDeactivateModal } = profileDeactivateSlice.actions;
