import React from "react";

const LinkedinIcon = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3332 5.66675C13.6593 5.66675 14.931 6.19353 15.8687 7.13121C16.8064 8.0689 17.3332 9.34067 17.3332 10.6667V16.5001H13.9998V10.6667C13.9998 10.2247 13.8242 9.8008 13.5117 9.48824C13.1991 9.17568 12.7752 9.00008 12.3332 9.00008C11.8911 9.00008 11.4672 9.17568 11.1547 9.48824C10.8421 9.8008 10.6665 10.2247 10.6665 10.6667V16.5001H7.33317V10.6667C7.33317 9.34067 7.85996 8.0689 8.79764 7.13121C9.73532 6.19353 11.0071 5.66675 12.3332 5.66675Z"
        fill="white"
      />
      <path
        d="M3.99984 6.50008H0.666504V16.5001H3.99984V6.50008Z"
        fill="white"
      />
      <path
        d="M2.33317 4.00008C3.25365 4.00008 3.99984 3.25389 3.99984 2.33341C3.99984 1.41294 3.25365 0.666748 2.33317 0.666748C1.4127 0.666748 0.666504 1.41294 0.666504 2.33341C0.666504 3.25389 1.4127 4.00008 2.33317 4.00008Z"
        fill="white"
      />
    </svg>
  );
};
export default LinkedinIcon;
