import React, { useEffect, useContext } from "react";
import NotificationIndex from "@/customer-pages/Notifications/NotificationIndex";
import NotificationIcon from "@/assets/svgs/NotificationIcon";
import { GetUserDetails } from "@/utils/helper";
import getAllNotificationsList from "@/rtk-apis/customer/notifications/getAllNotificationsList";
import { useDispatch, useSelector } from "react-redux";
import { TriggerContext } from "./socketTriggerContext";

const NotificationButton = ({
  OpenNotificationModal,
  setOpenNotificationModal,
}) => {
  const loggedInUser = GetUserDetails();
  const dispatch = useDispatch();
  const { trigger, setTrigger } = useContext(TriggerContext);
  console.log(trigger, "trigger");

  const { data } = useSelector((globalState) => {
    return globalState.getAllNotificationsResponse;
  });

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getAllNotifications();
  //   }, 60000);

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    if (trigger) {
      getAllNotifications();
    }
  }, [trigger]);

  const callNotificationApi = () => {
    getAllNotifications();
  };

  const getAllNotifications = () => {
    if (loggedInUser?.usertype === "customer") {
      let body = {
        notificationFor: loggedInUser?.usertype,
        companyId: loggedInUser?.customer?.company?.id,
        employeeId: loggedInUser?.customer?.employeeId,
        page: 1,
        limit: 10,
        filterOption: "All",
        startDate: null,
        endDate: null,
      };
      dispatch(getAllNotificationsList(body));
    } else {
      let body = {
        id: loggedInUser?.recruiter?.recruiterId,
        notificationFor: loggedInUser?.usertype,
        page: 1,
        limit: 10,
        filterOption: "All",
        startDate: null,
        endDate: null,
      };
      dispatch(getAllNotificationsList(body));
    }
    setTrigger(false);
  };

  return (
    <>
      <button
        className={`btn  btn__white btn--notification ${
          OpenNotificationModal ? "btn--notification--active" : ""
        }`}
        type="button"
        onClick={() => setOpenNotificationModal(true)}
      >
        <NotificationIcon />
        <span className="notificationCount">{data?.unreadCount}</span>
      </button>
      <NotificationIndex
        openModal={OpenNotificationModal}
        closeModal={() => setOpenNotificationModal(false)}
        callNotificationApi={callNotificationApi}
      />
    </>
  );
};

export default NotificationButton;
