import React from 'react'

function LockSvg() {
  return (
    <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83333 9.83301V6.49967C5.83333 5.39461 6.27232 4.3348 7.05372 3.5534C7.83512 2.77199 8.89493 2.33301 10 2.33301C11.1051 2.33301 12.1649 2.77199 12.9463 3.5534C13.7277 4.3348 14.1667 5.39461 14.1667 6.49967V9.83301M4.16667 9.83301H15.8333C16.7538 9.83301 17.5 10.5792 17.5 11.4997V17.333C17.5 18.2535 16.7538 18.9997 15.8333 18.9997H4.16667C3.24619 18.9997 2.5 18.2535 2.5 17.333V11.4997C2.5 10.5792 3.24619 9.83301 4.16667 9.83301Z"
      stroke="#6C737F"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  )
}

export default LockSvg