import { createSlice } from '@reduxjs/toolkit';
import candidateResumeUpload from '@/rtk-apis/candidates/candidateResumeUpload';

const initialState = {
  message: '',
  data: {},
  meta: {},
  loading: false,
  error: ''
};

const candidateResumeUploadSlice = createSlice({
  name: 'candidateResumeUpload',
  initialState,
  reducers: {
    closeResumeModal (state, action) { state.meta = {}; state.data = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(candidateResumeUpload.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(candidateResumeUpload.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(candidateResumeUpload.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  }

});

export default candidateResumeUploadSlice.reducer;

export const { closeResumeModal } = candidateResumeUploadSlice.actions;
