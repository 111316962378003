import React from "react";
import InterviewsListing from "@/customer-pages/MyJobs/Interviews/InterviewsListing";
import AllInterviews from "@/customer-pages/MyJobs/Interviews/AllInterviews";
import { getMyInterviewButtonAccess } from "@/utils/helper";

function Interviews({ jobData, interviewType, changeInterviewViewType }) {
  return (
    <>
      {getMyInterviewButtonAccess(jobData) && !interviewType ? (
        <AllInterviews
          changeInterviewViewType={changeInterviewViewType}
          jobData={jobData}
        />
      ) : (
        <InterviewsListing
          changeInterviewViewType={changeInterviewViewType}
          jobData={jobData}
        />
      )}
    </>
  );
}

export default Interviews;
