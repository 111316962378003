import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import getFeedbackQuestionslist from "@/rtk-apis/customer/myJobs/shortlistedCandidates/feedbackQuestionsList";
import FeedbackPage from "@/components/HOCs/FeedbackPage";
import Loader from "@/elements/Loader";
import { SuccessModal } from "@/components/modals/SuccessModal";

function ShortlistedCandidatesFeedback({
  setOpenCandidateFeedbackScreen,
  candidateStatus,
  ApproveRejectCandidate,
  ApproveCandidateResponse,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    getFeedbackQuestions();
  }, []);

  const getFeedbackQuestions = async () => {
    dispatch(getFeedbackQuestionslist());
  };

  const closeModal = () => {
    dispatch(closeRtkModal());
  };

  return (
    <>
      <FeedbackPage
        heading={
          candidateStatus === "Approved"
            ? "Send us your feedback!"
            : "Reason to reject"
        }
        onPageSubmit={(feedbackData) => ApproveRejectCandidate(feedbackData)}
        onBackClick={() => {
          setOpenCandidateFeedbackScreen(false);
        }}
        btnTitle={candidateStatus === "Approved" ? "Approve" : "Reject"}
      />
      {ApproveCandidateResponse?.loading && <Loader />}
      {ApproveCandidateResponse?.meta?.status && (
        <SuccessModal
          sucessfullMsg={"Candidate has been rejected successfully."}
          Heading={"Candidate Rejected!"}
          closeModal={closeModal}
        />
      )}
    </>
  );
}

export default ShortlistedCandidatesFeedback;
