import { createSlice } from "@reduxjs/toolkit";
import updateReviewsRatings from "@/rtk-apis/customer/myJobs/reviewsRatings/updateReviewsRatings";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const updateReviewsRatingsSlice = createSlice({
  name: "updateReviewsRatingsSlice",
  initialState,
  reducers: {
    closeupdateReviewsRatingsModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateReviewsRatings.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateReviewsRatings.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(updateReviewsRatings.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default updateReviewsRatingsSlice.reducer;

export const {
  closeupdateReviewsRatingsModal,
} = updateReviewsRatingsSlice.actions;
