import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { RECRUITER_CANCEL_DEACTIVATE_ACCOUNT } from '@/rtk-apis/end-points';

const cancelDeactivateRecruiter = createAsyncThunk('cancelDeactivateRecruiter', async (body) => {
  try {
    const response = await httpService.put(`${RECRUITER_CANCEL_DEACTIVATE_ACCOUNT}${body}/Recruiter`);
    return response;
  } catch (error) {
    return error;
  }
});

export default cancelDeactivateRecruiter;