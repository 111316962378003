import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { ACTIVATE_DEACTIVATE_CUSTOMER_TAGS } from "@/rtk-apis/end-points";

const activateDeactivateCustomerTags = createAsyncThunk(
  "activateDeactivateCustomerTags",
  async (body) => {
    try {
      const response = await httpService.put(
        ACTIVATE_DEACTIVATE_CUSTOMER_TAGS,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default activateDeactivateCustomerTags;
