import React from 'react';

const ArrowDownTag = () => {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.27026 5.16211L7.73437 0.662109H0.806162L4.27026 5.16211Z"
        fill="#1F2A37"
      />
    </svg>
  );
};
export default ArrowDownTag;
