import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { ADD_CANDIDATE } from '@/rtk-apis/end-points';

const addCandidate = createAsyncThunk('addCandidate', async (body) => {
  try {
    const response = await httpService.post(ADD_CANDIDATE, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default addCandidate;
