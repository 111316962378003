import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { DELETE_JOB_POST } from '@/rtk-apis/end-points';

const deleteCustomerJobPost = createAsyncThunk('deleteCustomerJobPost', async (body) => {
  try {
    const response = await httpService.put(DELETE_JOB_POST, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default deleteCustomerJobPost;
