import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowDown from "@/assets/svgs/ArrowDown";
import logo from "@/assets/images/logo.svg";
import EditIconNew from "@/assets/svgs/EditIconNew";
import PlusIcon from "@/assets/svgs/PlusIcon";
import staticData from "@/rtk-apis/staticData";
import { useDispatch, useSelector } from "react-redux";
import { getYears } from "@/utils/helper";
import ReactQuill from "react-quill";
import { modules, formats, editorStyle } from "@/utils/constants";
import { useNavigate, useLocation } from "react-router";
import ErrorModal from "@/components/modals/error-modal";
import Loader from "@/elements/Loader";
import { SuccessModal } from "@/components/modals/SuccessModal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import candidateSignUpStepTwo from "@/rtk-apis/candidate-rtk-apis/candidateSignUpStepTwo";
import { closeCandidateStepTwoModal } from "@/store/slices/candidateSlices/candidateSignUpStepTwoSlice";
import { candidateSignUpProfileDetailSchema } from "../schema/candidate-schema";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { INSTITUTION_LOGO_BUCKET } from "@/utils/constants";
import UniversitySvg from "@/assets/svgs/UniversitySvg";
import { Link } from "react-router-dom";

const CandidateProfileDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [showEducationForm, setShowEducationForm] = useState(true);
  const [showEducationBox, setShowEducationBox] = useState(false);
  const [EducationArray, setEducationArray] = useState([]);
  const [currentlyAttendHere, setcurrentlyAttendHere] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(false);
  const [DisableField, setDisableField] = useState(false);

  const [initialValues, setInitialValues] = useState({
    institution: "",
    degree: "",
    majorFieldOfStudy: "",
    startMonth: "",
    startYear: "",
    endMonth: "",
    endYear: "",
    description: "",
    currentlyAttendHere: false,
  });

  const { meta, loading } = useSelector((globalState) => {
    return globalState.candidateSignUpStepTwoRes;
  });

  const { data: staticDataRes } = useSelector((globalState) => {
    return globalState.static;
  });

  useEffect(() => {
    getStaticData();
  }, []);

  const getStaticData = () => {
    dispatch(staticData());
  };

  const handleFormSubmit = (values, resetForm) => {
    if (EducationArray?.length && !editMode) {
      let array = [...EducationArray, values];
      let updatedEducationArray = Array.from(
        new Set(array.map(JSON.stringify))
      ).map(JSON.parse);
      let body = {
        candidateId: state?.candidateData?.candidateId,
        jobDetails: [],
        qualificationDetail: updatedEducationArray,
      };
      dispatch(candidateSignUpStepTwo(body));
    } else if (editMode) {
      const updatedEducation = [...EducationArray, values];
      setEducationArray(updatedEducation);
      setEditMode(false);
      setEditIndex(null);
      let body = {
        candidateId: state?.candidateData?.candidateId,
        jobDetails: [],
        qualificationDetail: updatedEducation,
      };
      dispatch(candidateSignUpStepTwo(body));
    } else if (EducationArray?.length <= 0) {
      let body = {
        candidateId: state?.candidateData?.candidateId,
        jobDetails: [],
        qualificationDetail: [values],
      };
      dispatch(candidateSignUpStepTwo(body));
    }
    resetForm();
  };

  const handleCheckboxChange = (value, setFieldValue) => {
    setcurrentlyAttendHere(!currentlyAttendHere);
    if (!currentlyAttendHere) {
      setEducationArray((prevEducation) => {
        return prevEducation.map((education) => ({
          ...education,
          currentlyAttendHere: false,
        }));
      });
    }
    if (value) {
      setFieldValue("endMonth", "");
      setFieldValue("endYear", "");
      setDisableField(true);
    } else {
      setDisableField(false);
    }
  };

  const handleAddAnotherEducation = (values, resetForm) => {
    setEditMode(false);
    // resetForm();
    setInitialValues({
      institution: "",
      degree: "",
      majorFieldOfStudy: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      description: "",
      currentlyAttendHere: false,
    });
    setcurrentlyAttendHere(false);
    setShowEducationForm(false);
    setShowEducationBox(true);
    setEducationArray((prevEdu) => [...prevEdu, values]);
  };

  const handleAddAnotherForm = (resetForm) => {
    resetForm();
    setShowEducationForm(true);
    setShowEducationBox(true);
    setEditMode(true);
  };

  const handleEditEducation = (editIndex) => {
    setEditMode(true);
    setEditIndex(editIndex);
    setShowEducationForm(true);
    setShowEducationBox(true);
    const educationToEdit = EducationArray[editIndex];
    setInitialValues(educationToEdit);
    setEducationArray((prevEdu) =>
      prevEdu.filter((_, index) => index !== editIndex)
    );
  };

  const isButtonDisabled = (error, value, initialValues) => {
    let status = false;
    if (showEducationForm) {
      if (Object.keys(error)?.length != 0) {
        status = true;
      } else if (
        Object.keys(error)?.length == 0 &&
        _.isEqual(value, initialValues)
      ) {
        status = true;
      } else if (value?.description === "<p><br></p>") {
        status = true;
      }
      return status;
    } else {
      return status;
    }
  };

  const closeRtkModal = () => {
    dispatch(closeCandidateStepTwoModal());
    if (meta?.status) {
      localStorage.removeItem("signupSteponeValues");
      navigate("/signin");
    }
  };

  const getUnivercityLogo = (univercityName) => {
    let logo =
      staticDataRes?.nameOfInstitutionObject?.length &&
      staticDataRes?.nameOfInstitutionObject.find(
        (company) => company.value === univercityName
      )?.logoName;
    return logo;
  };

  return (
    <>
      <div className="layoutGrid layoutGrid--full">
        <div className="layoutGrid__col">
          <div className="dataCol">
            <div className="page page__full page--candidateSignUpProfile">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={candidateSignUpProfileDetailSchema}
                onSubmit={(values, { resetForm }) =>
                  handleFormSubmit(values, resetForm)
                }
              >
                {({
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  touched,
                  setFieldValue,
                  setErrors,
                  resetForm,
                  setTouched,
                }) => {
                  return (
                    <Form className="form p_0">
                      <div className="page__head">
                        <h2 className="page__head--title">
                          <div className="branding">
                            <div className="branding__logo">
                              <img
                                src={logo}
                                alt="Logo | Laila"
                                className="branding__logo__img"
                              />
                            </div>
                          </div>
                        </h2>
                      </div>
                      <div className="page__body">
                        <section className="form__section">
                          <div className="candidateSignup">
                            <div className="candidateSignup__header">
                              <h4 className="title">
                                Provide Your Profile Details
                              </h4>
                              <p>
                                Please enter your profile details to help us
                                providing you with better services
                              </p>
                            </div>
                            {showEducationBox &&
                              EducationArray?.map((education, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="candidateSignup__infoBox"
                                  >
                                    <div className="title">
                                      <h3>
                                        {education?.degree},{" "}
                                        {education?.majorFieldOfStudy}
                                      </h3>
                                      <button
                                        className="link link--dark"
                                        type="button"
                                        onClick={() =>
                                          handleEditEducation(index)
                                        }
                                        disabled={editMode}
                                      >
                                        <EditIconNew />
                                        Edit
                                      </button>
                                    </div>
                                    <h4>{education?.institution}</h4>
                                    <h5>
                                      {`${education?.startMonth} ${education?.startYear}`}{" "}
                                      {education?.endMonth && education?.endYear
                                        ? ` -${education?.endMonth} ${education?.endYear}`
                                        : ""}
                                    </h5>
                                    <p>
                                      <div
                                        key={new Date().getTime()}
                                        className="showQuillTags"
                                        dangerouslySetInnerHTML={{
                                          __html: education?.description
                                            ? education?.description
                                            : "--",
                                        }}
                                      ></div>
                                    </p>
                                  </div>
                                );
                              })}
                            {showEducationForm ? (
                              <div className="candidateSignup__body">
                                {EducationArray?.length > 0 ? (
                                  <h4 className="form__section--title">
                                    Another Education
                                  </h4>
                                ) : (
                                  ""
                                )}
                                <div className="formRow">
                                  <div className="formCol">
                                    <div
                                      className={`formField ${
                                        errors?.institution &&
                                        touched?.institution
                                          ? "fieldError"
                                          : ""
                                      }`}
                                    >
                                      <label className="formField__label">
                                        School/College/University
                                      </label>
                                      <div className="formField__autoComplete formField__autoComplete--withLogo">
                                        <Autocomplete
                                          id="tags-filled"
                                          options={
                                            staticDataRes?.nameOfInstitutionObject
                                              ? staticDataRes?.nameOfInstitutionObject
                                              : []
                                          }
                                          getOptionLabel={(option) =>
                                            option?.value
                                              ? option?.value
                                              : option
                                          }
                                          defaultValue={values?.institution}
                                          name="institution"
                                          onChange={(event, newValue) => {
                                            setFieldValue(
                                              "institution",
                                              newValue?.value
                                            );
                                            setFieldValue(
                                              "institutionLogo",
                                              newValue?.logoName
                                            );
                                          }}
                                          freeSolo
                                          renderOption={(
                                            props,
                                            option,
                                            index
                                          ) => (
                                            <li
                                              {...props}
                                              key={`${option.value}-${index}`}
                                            >
                                              {option.logoName ? (
                                                <img
                                                  src={
                                                    INSTITUTION_LOGO_BUCKET +
                                                    option.logoName
                                                  }
                                                  alt={option.value}
                                                  style={{
                                                    width: 25,
                                                    marginRight: 5,
                                                  }}
                                                />
                                              ) : (
                                                <div className="autocompletelogo">
                                                  <UniversitySvg />
                                                </div>
                                              )}
                                              {option?.value || ""}
                                            </li>
                                          )}
                                          renderInput={(params) => (
                                            <TextField
                                              placeholder="Select"
                                              className="autoCompleteInput"
                                              {...params}
                                              InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                  <>
                                                    {
                                                      params.InputProps
                                                        .startAdornment
                                                    }
                                                    {params.inputProps.value &&
                                                    getUnivercityLogo(
                                                      params.inputProps.value
                                                    ) ? (
                                                      <img
                                                        className="autocompletelogo"
                                                        src={
                                                          params.inputProps
                                                            .value &&
                                                          INSTITUTION_LOGO_BUCKET +
                                                            getUnivercityLogo(
                                                              params.inputProps
                                                                .value
                                                            )
                                                        }
                                                        alt="Selected"
                                                      />
                                                    ) : (
                                                      <div className="autocompletelogo">
                                                        <UniversitySvg />
                                                      </div>
                                                    )}
                                                  </>
                                                ),
                                              }}
                                              onBlur={(e) => {
                                                if (params?.inputProps?.value) {
                                                  setFieldValue(
                                                    "institution",
                                                    params.inputProps.value
                                                  );
                                                }
                                                handleBlur(e);
                                                setTouched({
                                                  ...touched,
                                                  institution: true,
                                                });
                                              }}
                                            />
                                          )}
                                        />
                                      </div>
                                      <span className="formField__error">
                                        {errors?.institution &&
                                          touched?.institution &&
                                          errors?.institution}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="formRow">
                                  <div className="formCol">
                                    <div
                                      className={`formField ${
                                        errors?.degree && touched?.degree
                                          ? "fieldError"
                                          : ""
                                      }`}
                                    >
                                      <label className="formField__label">
                                        Education
                                      </label>
                                      <div className="formField__autoComplete">
                                        <Autocomplete
                                          id="tags-filled"
                                          options={
                                            staticDataRes?.qualification
                                              ? staticDataRes?.qualification
                                              : []
                                          }
                                          defaultValue={values?.degree}
                                          name="degree"
                                          onChange={(event, newValue) => {
                                            setFieldValue("degree", newValue);
                                          }}
                                          freeSolo
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="filled"
                                              placeholder="Select"
                                              className="autoCompleteInput"
                                              onBlur={(e) => {
                                                handleBlur(e);
                                                setTouched({
                                                  ...touched,
                                                  degree: true,
                                                });
                                                if (params?.inputProps?.value) {
                                                  setFieldValue(
                                                    "degree",
                                                    params.inputProps.value
                                                  );
                                                }
                                              }}
                                            />
                                          )}
                                        />
                                      </div>
                                      <span className="formField__error">
                                        {errors?.degree &&
                                          touched?.degree &&
                                          errors?.degree}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="formRow">
                                  <div className="formCol">
                                    <div
                                      className={`formField ${
                                        errors?.majorFieldOfStudy &&
                                        touched?.majorFieldOfStudy
                                          ? "fieldError"
                                          : ""
                                      }`}
                                    >
                                      <label className="formField__label">
                                        Major/Field of Study
                                      </label>
                                      <div className="formField__autoComplete">
                                        <Autocomplete
                                          id="tags-filled"
                                          options={
                                            staticDataRes?.majorFieldOfStudy
                                          }
                                          defaultValue={
                                            values?.majorFieldOfStudy
                                          }
                                          name="majorFieldOfStudy"
                                          onChange={(event, newValue) => {
                                            setFieldValue(
                                              "majorFieldOfStudy",
                                              newValue
                                            );
                                          }}
                                          freeSolo
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="filled"
                                              placeholder="Select"
                                              className="autoCompleteInput"
                                              onBlur={(e) => {
                                                handleBlur(e);
                                                setTouched({
                                                  ...touched,
                                                  majorFieldOfStudy: true,
                                                });
                                                if (params?.inputProps?.value) {
                                                  setFieldValue(
                                                    "majorFieldOfStudy",
                                                    params.inputProps.value
                                                  );
                                                }
                                              }}
                                            />
                                          )}
                                        />
                                      </div>
                                      <span className="formField__error">
                                        {errors?.majorFieldOfStudy &&
                                          touched?.majorFieldOfStudy &&
                                          errors?.majorFieldOfStudy}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="formRow formRow__2">
                                  <div className="formCol">
                                    <div
                                      className={`formField ${
                                        errors?.startMonth &&
                                        touched?.startMonth
                                          ? "fieldError"
                                          : ""
                                      }`}
                                    >
                                      <label className="formField__label">
                                        Start Month
                                      </label>
                                      <FormControl
                                        sx={{ width: "100%" }}
                                        size="small"
                                        className="formField__select"
                                      >
                                        <Select
                                          name="startMonth"
                                          value={values?.startMonth}
                                          onBlur={(e) => {
                                            if (e) {
                                              setTouched({
                                                ...touched,
                                                startMonth: true,
                                              });
                                            }
                                          }}
                                          onChange={(event) => {
                                            setFieldValue(
                                              "startMonth",
                                              event?.target?.value
                                            );
                                          }}
                                          IconComponent={() => <ArrowDown />}
                                          displayEmpty
                                          renderValue={(selected) => {
                                            if (!selected) {
                                              return (
                                                <span
                                                  style={{ opacity: "0.8" }}
                                                >
                                                  Select Month
                                                </span>
                                              );
                                            }

                                            return selected;
                                          }}
                                        >
                                          {staticDataRes?.yearMonth?.month.map(
                                            (item, index) => (
                                              <MenuItem
                                                value={item}
                                                key={index}
                                              >
                                                {item}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                      <span className="formField__error">
                                        {errors?.startMonth &&
                                          touched?.startMonth &&
                                          errors?.startMonth}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="formCol">
                                    <div
                                      className={`formField ${
                                        errors?.startYear && touched?.startYear
                                          ? "fieldError"
                                          : ""
                                      }`}
                                    >
                                      <label className="formField__label">
                                        Start Year
                                      </label>
                                      <FormControl
                                        sx={{ width: "100%" }}
                                        size="small"
                                        className="formField__select"
                                      >
                                        <Select
                                          name="startMonth"
                                          value={values?.startYear}
                                          onChange={(event) => {
                                            setFieldValue(
                                              "startYear",
                                              event?.target?.value
                                            );
                                          }}
                                          onBlur={(e) => {
                                            if (e) {
                                              setTouched({
                                                ...touched,
                                                startYear: true,
                                              });
                                            }
                                          }}
                                          IconComponent={() => <ArrowDown />}
                                          displayEmpty
                                          renderValue={(selected) => {
                                            if (!selected) {
                                              return (
                                                <span
                                                  style={{ opacity: "0.8" }}
                                                >
                                                  Select Year
                                                </span>
                                              );
                                            }

                                            return selected;
                                          }}
                                        >
                                          {getYears(
                                            staticDataRes?.yearMonth?.startYear
                                          ).map((item, index) => (
                                            <MenuItem value={item} key={index}>
                                              {item}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <span className="formField__error">
                                        {errors?.startYear &&
                                          touched?.startYear &&
                                          errors?.startYear}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="formRow formRow__2">
                                  {/* END MONTH */}
                                  <div className="formCol">
                                    <div
                                      className={`formField ${
                                        errors?.endMonth ? "fieldError" : ""
                                      }`}
                                    >
                                      <label className="formField__label">
                                        End Month
                                      </label>
                                      <FormControl
                                        sx={{ width: "100%" }}
                                        size="small"
                                        className="formField__select"
                                      >
                                        <Select
                                          name="endMonth"
                                          value={values?.endMonth}
                                          disabled={DisableField}
                                          onChange={(event) => {
                                            setFieldValue(
                                              "endMonth",
                                              event?.target?.value
                                            );
                                          }}
                                          IconComponent={() => <ArrowDown />}
                                          displayEmpty
                                          renderValue={(selected) => {
                                            if (!selected) {
                                              return (
                                                <span
                                                  style={{ opacity: "0.8" }}
                                                >
                                                  Select Month
                                                </span>
                                              );
                                            }

                                            return selected;
                                          }}
                                        >
                                          {staticDataRes?.yearMonth?.month.map(
                                            (item, index) => (
                                              <MenuItem
                                                value={item}
                                                key={index}
                                              >
                                                {item}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                      <span className="formField__error">
                                        {errors?.endMonth}
                                      </span>
                                    </div>
                                  </div>
                                  {/* END YEAR */}
                                  <div className="formCol">
                                    <div
                                      className={`formField ${
                                        errors?.endYear ? "fieldError" : ""
                                      }`}
                                    >
                                      <label className="formField__label">
                                        End Year
                                      </label>
                                      <FormControl
                                        sx={{ width: "100%" }}
                                        size="small"
                                        className="formField__select"
                                      >
                                        <Select
                                          name="endYear"
                                          disabled={DisableField}
                                          value={values?.endYear}
                                          onChange={(event) => {
                                            setFieldValue(
                                              "endYear",
                                              event?.target?.value
                                            );
                                          }}
                                          IconComponent={() => <ArrowDown />}
                                          displayEmpty
                                          renderValue={(selected) => {
                                            if (!selected) {
                                              return (
                                                <span
                                                  style={{ opacity: "0.8" }}
                                                >
                                                  Select Year
                                                </span>
                                              );
                                            }

                                            return selected;
                                          }}
                                        >
                                          {getYears(
                                            staticDataRes?.yearMonth?.startYear
                                          ).map((item, index) => (
                                            <MenuItem value={item} key={index}>
                                              {item}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <span className="formField__error">
                                        {errors?.endYear}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="formRow">
                                  <div className="formCol">
                                    <div className="formField">
                                      <ReactQuill
                                        name="description"
                                        value={values?.description}
                                        modules={modules}
                                        formats={formats}
                                        style={editorStyle}
                                        onChange={(newValue) =>
                                          setFieldValue("description", newValue)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="candidateSignup__footer">
                              {showEducationForm ? (
                                <div className="formField">
                                  <div className="formField__checkbox">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={values?.currentlyAttendHere}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "currentlyAttendHere",
                                              e.target.checked
                                            );
                                            handleCheckboxChange(
                                              e?.target?.checked,
                                              setFieldValue
                                            );
                                          }}
                                          name="currentlyAttendHere"
                                        />
                                      }
                                      label="I currently attend here"
                                    />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="formField">
                                {showEducationForm ? (
                                  <button
                                    className="btn btn--addExperience p_0"
                                    type="button"
                                    disabled={isButtonDisabled(
                                      errors,
                                      values,
                                      initialValues
                                    )}
                                    onClick={() =>
                                      handleAddAnotherEducation(
                                        values,
                                        resetForm
                                      )
                                    }
                                  >
                                    <PlusIcon /> Add More Education
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn--addExperience p_0"
                                    type="button"
                                    onClick={() =>
                                      handleAddAnotherForm(resetForm)
                                    }
                                  >
                                    <PlusIcon /> Add
                                  </button>
                                )}
                              </div>
                              <div className="formField ">
                                <button
                                  type="submit"
                                  className="btn btn__primary btn--xl w_100"
                                  disabled={isButtonDisabled(
                                    errors,
                                    values,
                                    initialValues
                                  )}
                                >
                                  Submit
                                </button>
                              </div>
                              <div className="candidateSignup__footer__text">
                                <span>Or</span>
                              </div>
                              <div className="candidateSignup__footer__text">
                                <Link
                                  to="/signup"
                                  state={{
                                    signUpAs: "candidate",
                                    isStepTwo: true,
                                    candidateData: state?.candidateData,
                                  }}
                                  className="link link__primary"
                                >
                                  Click here for Employment Details
                                </Link>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {meta?.status && (
        <SuccessModal
          closeModal={closeRtkModal}
          sucessfullMsg={meta?.msg}
          Heading={"Candidate Details added!"}
        />
      )}
      {meta?.status === false && (
        <ErrorModal
          errorMessage={meta?.msg}
          closeModal={closeRtkModal}
          footerVisible={true}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default CandidateProfileDetails;
