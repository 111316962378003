import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import LinkIcon from "@/assets/svgs/LinkIcon";
import {
  GetUserDetails,
  formatPhoneNumber,
  firstLetterCapital,
  saveInterviewDetails,
} from "@/utils/helper";
import { useDispatch, useSelector } from "react-redux";
import createInterview from "@/rtk-apis/interviews/createInterview";
import Loader from "@/elements/Loader";
import { removeNylusData } from "@/store/slices/interviews/createInterviewSlice";
import { APP_URL, LAILA_LOGO } from "@/utils/constants";

function InterviewsDetails({ candidate, hideDetail, selectedJob }) {
  const user = GetUserDetails();
  const dispatch = useDispatch();

  const { data, loading } = useSelector((globalState) => {
    return globalState.createInterviewResponse;
  });

  const scheduleMeeting = () => {
    const token = JSON.parse(localStorage.getItem("token"));
    let { nylasAccessToken } = token?.recruiter;
    dispatch(
      createInterview({
        accessToken: nylasAccessToken,
      })
    );
  };

  const hitNylus = () => {
    saveInterviewDetails({
      companyId: selectedJob?.companyId,
      jobId: selectedJob?.jobId,
      candidateId: candidate?.candidateId,
      employeeId: null,
      meetingTitle: data?.title,
      meetingId: data?.id,
      recruiterId: user?.recruiter?.recruiterId,
    });

    setTimeout(() => {
      const token = JSON.parse(localStorage.getItem("token"));
      let { nylasAccessToken } = token?.recruiter;
      window.nylas.scheduler.show({
        pageDomain: "",
        auth: {
          accessToken: nylasAccessToken,
        },

        style: {
          tintColor: false ? "#FF0000" : "#32325d",
          backgroundColor: false ? "#424242" : "white",
          modalTitle: "Laila Interview",
        },
        defaults: {
          event: {
            title: "Interview",
            location: `${APP_URL}meeting/${data?.id}`,
            duration: 60,
          },
          appearance: {
            thank_you_redirect: `${APP_URL}thankyou`,
            company_name: "Laila",
            logo: LAILA_LOGO,
          },
          booking: {
            confirmation_emails_to_host: true,
            confirmation_emails_to_guests: true,
            calendar_invite_to_guests: true,
            min_booking_notice: 120,
            min_buffer: 0,
            additional_guests_hidden: true,
            available_days_in_future: 360,
          },
        },
      });
      dispatch(removeNylusData());
    }, 1000);
  };

  useEffect(() => {
    if (data?.id) hitNylus();
  }, [data]);

  return (
    <div className="details bg--light">
      <section className="details__section">
        <button
          onClick={() => hideDetail()}
          className="btn btn__white btn__back"
        >
          Back
        </button>
        <div className="initials initials__lg">
          {firstLetterCapital(candidate?.firstName[0])}
        </div>
        <div className="details__section--name">
          {firstLetterCapital(candidate?.firstName) +
            " " +
            firstLetterCapital(candidate?.lastName)}
        </div>
        <div className="details__row">
          <div className="details__row--item">
            <span>Phone:</span>
            {candidate?.countryCode}{" "}
            {formatPhoneNumber(candidate?.phoneNumber) || "--"}
          </div>
          <div className="details__row--item">
            <span>Email:</span>
            {candidate?.email}
          </div>
        </div>
      </section>

      <section className="details__section">
        <h4 className="details__section--title">Educational Details</h4>
        <div className="fieldBox flexRow">
          <div className="flexCol">
            <label className="flexCol__label">Candidate Resume</label>
            {candidate?.resumeUrl ? (
              <Link
                to={candidate?.resumeUrl ? candidate?.resumeUrl : "#"}
                target="_blank"
                className="btn btn__white btn__link w_fitContent"
                style={
                  !candidate?.resumeUrl
                    ? { pointerEvents: "none", opacity: "0.5" }
                    : null
                }
              >
                {" "}
                <span>{candidate?.resumeUrl?.split("/")[4]}</span>
                {candidate?.resumeUrl && <LinkIcon />}{" "}
              </Link>
            ) : (
              "--"
            )}
          </div>
          <div className="flexCol">
            <label className="flexCol__label">Highest Qualification</label>
            <div className="flexCol__text">{candidate?.education || "--"}</div>
          </div>
        </div>
        <div className="fieldBox flexRow">
          <div className="flexCol">
            <label className="flexCol__label">Name of Institution</label>
            <div className="flexCol__text">
              {candidate?.institutionName || "--"}
            </div>
          </div>
          <div className="flexCol">
            <label className="flexCol__label">Graduation Year</label>
            <div className="flexCol__text">
              {candidate?.graduationYear || "--"}
            </div>
          </div>
        </div>
      </section>

      <section className="details__section">
        <h4 className="details__section--title">Professional Details</h4>
        <div className="fieldBox flexRow">
          <div className="flexCol">
            <label className="flexCol__label">Experience</label>
            <div className="flexCol__text">
              {candidate?.experience
                ? candidate?.experience <= 1
                  ? candidate?.experience + " Year"
                  : candidate?.experience + " Years"
                : "--"}
            </div>
          </div>
          <div className="flexCol">
            <label className="flexCol__label">LinkedIn Account</label>
            {candidate?.linkedIn ? (
              <Link
                to={candidate?.linkedIn}
                className="flexCol__text text--primary"
              >
                {candidate?.linkedIn} <LinkIcon />{" "}
              </Link>
            ) : (
              "--"
            )}
          </div>
        </div>
        <div className="fieldBox">
          <label className="flexCol__label">Skill set</label>
          <div className="flexCol__text">
            {candidate?.skillset?.length
              ? candidate?.skillset.join(", ")
              : "--"}
          </div>
        </div>
        <button onClick={scheduleMeeting} className="btn btn__primary">
          Schedule an Interview
        </button>
      </section>
      {loading && <Loader />}
    </div>
  );
}

export default InterviewsDetails;
