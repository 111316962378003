import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from '@/rtk-apis/axios-config/http-service';
import {STATIC_DATA} from '@/rtk-apis/end-points';

const staticData = createAsyncThunk('staticData', async (body) => {
    let response = "";
    try {
        if(body){
            response = await httpService.get(STATIC_DATA+"?"+body, {});
        }
        else{
            response = await httpService.get(STATIC_DATA, body);
        }
        return response?.data;
    } catch (error) {
        return error;
    }
});

export default staticData;