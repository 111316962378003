import React, { useState, useEffect } from "react";
import SignUpStepOne from "@/components/signUp/SignUpStepOne";
import SignUpStepTwo from "@/components/signUp/SignUpStepTwo";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader from "@/elements/Loader";
import signUpRecruiter from "@/rtk-apis/signUpRecruiter";
import recruiterDetailsOnSignUp from "@/rtk-apis/signUp/recruiterDeailsOnSignUp";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import { closeRtkModal } from "@/store/slices/AuthSlice";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import CustomerSignUpSteptwo from "./CustomerSignUpSteptwo";
import CandidateSignUpStepTwo from "./CandidateSignUpStepTwo";
import newCustomerSignUp from "../../rtk-apis/customer/newCustomerSignUp";
import { closenewCustModal } from "../../store/slices/customer/NewCustomerSignUpSlice";
import addCompanyOnSocialSignIn from "../../rtk-apis/addCompanyOnSocialSignIn";
import { closeSocialCustomerSignInModal } from "../../store/slices/addCompanyOnSocialSignInSlice";
import { firstLetterCapital } from "@/utils/helper";

function SignUp() {
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isStepTwo, setStepTwo] = useState(
    state?.isStepTwo ? state?.isStepTwo : false
  );
  const [stepOneValues, setStepOneValues] = useState({});
  const [CandidateData, setCandidateData] = useState(
    state?.candidateData ? state?.candidateData : {}
  );

  const { loading, meta } = useSelector((globalState) => {
    return globalState.auth;
  });

  const { data } = useSelector((globalState) => {
    return globalState.recruiterDetailsOnSignUpResponse;
  });

  const NewCustomerSignUpResponse = useSelector((globalState) => {
    return globalState.NewCustomerSignUpRes;
  });

  const socialCustomerSignIn = useSelector((globalState) => {
    return globalState.addCompanyOnSocialSignInRes;
  });

  const goToStepTwo = (props) => {
    setStepOneValues(props);
    setCandidateData(props?.candidateData);
    setStepTwo(true);
  };

  const closeModal = () => {
    if (state?.signUpAs === "customer") {
      dispatch(closenewCustModal());
      dispatch(closeSocialCustomerSignInModal());
      if (socialCustomerSignIn?.meta?.status) {
        localStorage.removeItem("socialSignInData");
        navigate("/customer-dashboard");
      } else {
        navigate("/signin");
      }
    } else {
      dispatch(closeRtkModal());
      navigate("/signin");
    }
  };

  const recruiterSignUp = ({
    education,
    institutionName,
    majorStudy,
    skillSet,
    experience,
    linkedin,
    graduationYear,
    qualificationArray,
  }) => {
    const { firstName, lastName, email, phoneNumber, password } = stepOneValues;
    dispatch(
      signUpRecruiter({
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        education,
        institutionName,
        majorStudy,
        skillSet,
        experience: experience.toString(),
        linkedin,
        graduationYear,
        countryCode: "+1",
        role: "recruiter",
        qualificationArray,
      })
    );
  };

  const customerSignUp = (values) => {
    if (state?.signInData?.registrationProcess === "incomplete") {
      let body = {
        companyName: values?.companyName,
        industryType: values?.industryType,
        country: values?.countryCode,
        countryName: values?.country,
        stateName: values?.state,
        zipcode: values?.zipcode,
        state: values?.stateCode,
        city: values?.city,
        address: values?.address,
        companyLogo: values?.companyLogo,
        websiteLink: values?.websiteLink,
        linkedin: values?.linkedin,
        employeeId: state?.signInData?.employeeId,
        id_token: state?.signInData?.id_token,
        csrf_token: state?.signInData?.csrf_token,
        provider: state?.signInData?.provider,
        flowId: state?.signInData?.flowId,
        sub: state?.signInData?.sub,
      };
      dispatch(addCompanyOnSocialSignIn(body));
    } else {
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
      } = stepOneValues;
      let body = {
        companyName: values?.companyName,
        industryType: values?.industryType,
        country: values?.countryCode,
        countryName: values?.country,
        stateName: values?.state,
        zipcode: values?.zipcode,
        state: values?.stateCode,
        city: values?.city,
        address: values?.address,
        companyLogo: values?.companyLogo,
        websiteLink: values?.websiteLink,
        linkedin: values?.linkedin,
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        termAndCondition: true,
        countryCode: "+91",
        phoneNumber: phoneNumber,
        companyId: null,
      };
      dispatch(newCustomerSignUp(body));
    }
  };

  const closeErrorModal = () => {
    if (state?.signUpAs === "customer") {
      dispatch(closenewCustModal());
      dispatch(closeSocialCustomerSignInModal());
      if (socialCustomerSignIn?.meta?.status === false) {
        navigate("/");
      }
    } else {
      dispatch(closeRtkModal());
    }
  };

  useEffect(() => {
    if (searchParams?.size && searchParams.get("email")) {
      getSignUpDetails(searchParams.get("email"));
    }
  }, []);

  const getSignUpDetails = (email) => {
    dispatch(recruiterDetailsOnSignUp(email));
  };

  return (
    <>
      {!isStepTwo && (
        <SignUpStepOne
          data={data}
          goToStepTwo={(props) => goToStepTwo(props)}
        />
      )}
      {isStepTwo && state?.signUpAs === "recruiter" ? (
        <SignUpStepTwo
          data={data}
          finishSignUp={(props) => recruiterSignUp(props)}
          goBack={() => setStepTwo(false)}
        />
      ) : isStepTwo && state?.signUpAs === "customer" ? (
        <CustomerSignUpSteptwo
          finishSignUp={(props) => customerSignUp(props)}
          goBack={() => setStepTwo(false)}
          signUpRes={NewCustomerSignUpResponse}
        />
      ) : (
        isStepTwo &&
        state?.signUpAs === "candidate" && (
          <CandidateSignUpStepTwo candidateData={CandidateData} />
        )
      )}
      {(loading ||
        NewCustomerSignUpResponse?.loading ||
        socialCustomerSignIn?.loading) && <Loader />}
      {(meta?.status ||
        NewCustomerSignUpResponse?.meta?.status ||
        socialCustomerSignIn?.meta?.status) && (
        <SuccessModal
          sucessfullMsg={
            state?.signUpAs === "customer"
              ? NewCustomerSignUpResponse?.meta?.msg
                ? NewCustomerSignUpResponse?.meta?.msg
                : socialCustomerSignIn?.meta?.msg
              : meta.msg
          }
          Heading={`${firstLetterCapital(
            state?.signUpAs
          )} signed up successfully!`}
          closeModal={closeModal}
        />
      )}
      {(meta?.status === false ||
        NewCustomerSignUpResponse?.meta?.status === false ||
        socialCustomerSignIn?.meta?.status === false) && (
        <ErrorModal
          errorMessage={
            state?.signUpAs === "customer"
              ? NewCustomerSignUpResponse?.meta?.msg
                ? NewCustomerSignUpResponse?.meta?.msg
                : socialCustomerSignIn?.meta?.msg
              : meta.msg
          }
          closeModal={closeErrorModal}
          footerVisible={true}
        />
      )}
    </>
  );
}

export default SignUp;
