import React, { useEffect } from "react";
import Dot from "@/assets/svgs/Dot";
import { formatPhoneNumber, firstLetterCapital } from "@/utils/helper";

function InterviewsCandidatesListing({
  selectedIndex,
  changeIndex,
  candidateList,
  setAddResponsiveClass,
}) {
  useEffect(() => {
    if (candidateList?.length) changeIndex(0, candidateList[0]);
  }, [candidateList?.length]);

  return (
    <>
      <ul className="listCol__list listing__list">
        {candidateList?.length ? (
          candidateList.map((candidate, index) => {
            return (
              <li
              key={index}
                className={
                  selectedIndex === index
                    ? "listCol__list--item listCol__item--selected"
                    : "listCol__list--item"
                }
                onClick={() => {
                  changeIndex(index, candidate);
                  setAddResponsiveClass(true);
                }}
              >
                <div className="item item--recruiter">
                  <div className="initials initials__md">
                    {candidate?.firstName?.charAt(0).toUpperCase()}
                  </div>
                  <div className="item__info">
                    <h4 className="item__info--title">
                      {firstLetterCapital(candidate?.firstName) +
                        " " +
                        firstLetterCapital(candidate?.lastName)}
                    </h4>
                    <div className="item__info--subtitle">
                      {candidate?.education && <p>{candidate?.education}</p>}
                      <span>
                        {candidate?.education && <Dot />}
                        {candidate?.experience && candidate?.experience > 0
                          ? candidate?.experience === 1
                            ? `${candidate?.experience} Yr Exp`
                            : `${candidate?.experience} Yrs Exp`
                          : "Fresher"}
                      </span>
                      {candidate?.role && (
                        <span>
                          {candidate?.experience && <Dot />}
                          {candidate?.role}
                        </span>
                      )}
                    </div>
                    <div className="item__info--subtitle">
                      <p>
                        <b>P:</b>
                        {candidate?.countryCode}{" "}
                        {formatPhoneNumber(candidate?.phoneNumber)}
                      </p>
                      <span>
                        <b>E:</b>
                        {candidate?.email}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <div className="no-data">No Results Found!</div>
        )}
      </ul>
    </>
  );
}

export default InterviewsCandidatesListing;
