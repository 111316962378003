import React from 'react'

function USAFlag() {
  return ( 
    <svg
        className="flagIcon"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_831_13011)">
        <path
            d="M24.0861 20.3621H0.913812C0.685297 20.3621 0.5 20.1768 0.5 19.9483V5.05175C0.5 4.82324 0.685297 4.63794 0.913812 4.63794H24.0862C24.3147 4.63794 24.5 4.82324 24.5 5.05175V19.9483C24.5 20.1768 24.3147 20.3621 24.0861 20.3621Z"
            fill="#F5F5F5"
        />
        <path
            d="M24.5 5.84755H0.5V5.05175C0.5 4.82324 0.685297 4.63794 0.913812 4.63794H24.0862C24.3147 4.63794 24.5 4.82324 24.5 5.05175L24.5 5.84755Z"
            fill="#FF4B55"
        />
        <path
            d="M24.5 9.47607H0.5V10.6856H24.5V9.47607Z"
            fill="#FF4B55"
        />
        <path
            d="M24.5 7.05688H0.5V8.26644H24.5V7.05688Z"
            fill="#FF4B55"
        />
        <path
            d="M24.5 13.1049H0.913812C0.685297 13.1049 0.5 12.9196 0.5 12.6911V11.8953H24.5V13.1049Z"
            fill="#FF4B55"
        />
        <path
            d="M24.5 16.7338H0.5V17.9433H24.5V16.7338Z"
            fill="#FF4B55"
        />
        <path
            d="M24.0861 20.362H0.913812C0.685297 20.362 0.5 20.1767 0.5 19.9482V19.1525H24.5V19.9483C24.5 20.1768 24.3147 20.362 24.0861 20.362Z"
            fill="#FF4B55"
        />
        <path
            d="M24.5 14.3146H0.5V15.5241H24.5V14.3146Z"
            fill="#FF4B55"
        />
        <path
            d="M11.2586 4.63794H0.913812C0.685297 4.63794 0.5 4.82319 0.5 5.0517V12.6909C0.5 12.9194 0.685297 13.1047 0.913812 13.1047H11.2586C11.4872 13.1047 11.6724 12.9194 11.6724 12.6909V5.0517C11.6724 4.82319 11.4871 4.63794 11.2586 4.63794Z"
            fill="#41479B"
        />
        <path
            d="M1.66186 5.58783L1.75345 5.86252L2.043 5.86472C2.0805 5.86505 2.09602 5.91287 2.06587 5.93513L1.83295 6.10716L1.92033 6.38321C1.93167 6.41893 1.89094 6.44855 1.86042 6.42671L1.62488 6.25838L1.38933 6.42676C1.35881 6.44855 1.31817 6.41897 1.32942 6.38326L1.4168 6.10721L1.18388 5.93518C1.15374 5.91287 1.16925 5.8651 1.20675 5.86477L1.4963 5.86257L1.58789 5.58788C1.5997 5.55226 1.64995 5.55226 1.66186 5.58783Z"
            fill="#F5F5F5"
        />
        <path
            d="M1.66186 7.02457L1.75345 7.29925L2.043 7.30146C2.0805 7.30179 2.09602 7.3496 2.06587 7.37186L1.83295 7.54389L1.92033 7.81994C1.93167 7.85566 1.89094 7.88528 1.86042 7.86344L1.62488 7.69511L1.38933 7.86349C1.35881 7.88528 1.31817 7.85571 1.32942 7.81999L1.4168 7.54394L1.18388 7.37191C1.15374 7.3496 1.16925 7.30183 1.20675 7.3015L1.4963 7.2993L1.58789 7.02461C1.5997 6.98904 1.64995 6.98904 1.66186 7.02457Z"
            fill="#F5F5F5"
        />
        <path
            d="M1.66186 8.46158L1.75345 8.73627L2.043 8.73847C2.0805 8.7388 2.09602 8.78661 2.06587 8.80888L1.83295 8.98091L1.92033 9.25695C1.93167 9.29267 1.89094 9.3223 1.86042 9.30045L1.62488 9.13212L1.38933 9.3005C1.35881 9.3223 1.31817 9.29272 1.32942 9.257L1.4168 8.98095L1.18388 8.80892C1.15374 8.78661 1.16925 8.73884 1.20675 8.73852L1.4963 8.73631L1.58789 8.46163C1.5997 8.42605 1.64995 8.42605 1.66186 8.46158Z"
            fill="#F5F5F5"
        />
        <path
            d="M1.66186 9.8985L1.75345 10.1732L2.043 10.1754C2.0805 10.1757 2.09602 10.2235 2.06587 10.2458L1.83295 10.4178L1.92033 10.6939C1.93167 10.7296 1.89094 10.7592 1.86042 10.7374L1.62488 10.569L1.38933 10.7374C1.35881 10.7592 1.31817 10.7296 1.32942 10.6939L1.4168 10.4179L1.18388 10.2458C1.15374 10.2235 1.16925 10.1758 1.20675 10.1754L1.4963 10.1732L1.58789 9.89855C1.5997 9.86293 1.64995 9.86293 1.66186 9.8985Z"
            fill="#F5F5F5"
        />
        <path
            d="M1.66186 11.3354L1.75345 11.6101L2.043 11.6123C2.0805 11.6126 2.09602 11.6605 2.06587 11.6827L1.83295 11.8548L1.92033 12.1308C1.93167 12.1665 1.89094 12.1961 1.86042 12.1743L1.62488 12.006L1.38933 12.1743C1.35881 12.1961 1.31817 12.1666 1.32942 12.1308L1.4168 11.8548L1.18388 11.6828C1.15374 11.6605 1.16925 11.6127 1.20675 11.6124L1.4963 11.6102L1.58789 11.3355C1.5997 11.2998 1.64995 11.2998 1.66186 11.3354Z"
            fill="#F5F5F5"
        />
        <path
            d="M2.77709 6.29215L2.86869 6.56684L3.15823 6.56904C3.19573 6.56937 3.21125 6.61718 3.18111 6.63945L2.94819 6.81148L3.03556 7.08752C3.04691 7.12324 3.00617 7.15287 2.97566 7.13102L2.74011 6.96265L2.50456 7.13102C2.47405 7.15282 2.43341 7.12324 2.44466 7.08752L2.53203 6.81148L2.29911 6.63945C2.26897 6.61713 2.28449 6.56937 2.32199 6.56904L2.61153 6.56684L2.70313 6.29215C2.71489 6.25662 2.76523 6.25662 2.77709 6.29215Z"
            fill="#F5F5F5"
        />
        <path
            d="M2.77709 7.7292L2.86869 8.00389L3.15823 8.00609C3.19573 8.00642 3.21125 8.05423 3.18111 8.07649L2.94819 8.24853L3.03556 8.52457C3.04691 8.56029 3.00617 8.58992 2.97566 8.56807L2.74011 8.3997L2.50456 8.56807C2.47405 8.58987 2.43341 8.56034 2.44466 8.52457L2.53203 8.24853L2.29911 8.07649C2.26897 8.05418 2.28449 8.00642 2.32199 8.00609L2.61153 8.00389L2.70313 7.7292C2.71489 7.69362 2.76523 7.69362 2.77709 7.7292Z"
            fill="#F5F5F5"
        />
        <path
            d="M2.77709 9.16609L2.86869 9.44077L3.15823 9.44298C3.19573 9.44331 3.21125 9.49112 3.18111 9.51338L2.94819 9.68541L3.03556 9.96146C3.04691 9.99718 3.00617 10.0268 2.97566 10.005L2.74011 9.83659L2.50456 10.005C2.47405 10.0268 2.43341 9.99718 2.44466 9.96146L2.53203 9.68541L2.29911 9.51338C2.26897 9.49107 2.28449 9.44331 2.32199 9.44298L2.61153 9.44077L2.70313 9.16609C2.71489 9.13051 2.76523 9.13051 2.77709 9.16609Z"
            fill="#F5F5F5"
        />
        <path
            d="M2.77709 10.6029L2.86869 10.8776L3.15823 10.8798C3.19573 10.8802 3.21125 10.928 3.18111 10.9502L2.94819 11.1223L3.03556 11.3983C3.04691 11.434 3.00617 11.4637 2.97566 11.4418L2.74011 11.2734L2.50456 11.4418C2.47405 11.4636 2.43341 11.434 2.44466 11.3983L2.53203 11.1223L2.29911 10.9502C2.26897 10.9279 2.28449 10.8802 2.32199 10.8798L2.61153 10.8776L2.70313 10.6029C2.71489 10.5674 2.76523 10.5674 2.77709 10.6029Z"
            fill="#F5F5F5"
        />
        <path
            d="M3.89233 5.58772L3.98392 5.86241L4.27347 5.86461C4.31097 5.86494 4.32648 5.91275 4.29634 5.93501L4.06342 6.10705L4.1508 6.38309C4.16214 6.41881 4.12141 6.44844 4.09089 6.42659L3.85534 6.25822L3.6198 6.42659C3.58928 6.44839 3.54864 6.41881 3.55989 6.38309L3.64727 6.10705L3.41434 5.93501C3.3842 5.9127 3.39972 5.86494 3.43722 5.86461L3.72677 5.86241L3.81836 5.58772C3.83017 5.55214 3.88047 5.55214 3.89233 5.58772Z"
            fill="#F5F5F5"
        />
        <path
            d="M3.89233 7.02457L3.98392 7.29926L4.27347 7.30146C4.31097 7.30179 4.32648 7.3496 4.29634 7.37187L4.06342 7.5439L4.1508 7.81995C4.16214 7.85567 4.12141 7.88529 4.09089 7.86345L3.85534 7.69507L3.6198 7.86345C3.58928 7.88524 3.54864 7.85567 3.55989 7.81995L3.64727 7.5439L3.41434 7.37187C3.3842 7.34956 3.39972 7.30179 3.43722 7.30146L3.72677 7.29926L3.81836 7.02457C3.83017 6.98904 3.88047 6.98904 3.89233 7.02457Z"
            fill="#F5F5F5"
        />
        <path
            d="M3.89233 8.46158L3.98392 8.73627L4.27347 8.73848C4.31097 8.7388 4.32648 8.78662 4.29634 8.80888L4.06342 8.98091L4.1508 9.25696C4.16214 9.29268 4.12141 9.3223 4.09089 9.30046L3.85534 9.13208L3.6198 9.30046C3.58928 9.32226 3.54864 9.29268 3.55989 9.25696L3.64727 8.98091L3.41434 8.80888C3.3842 8.78657 3.39972 8.7388 3.43722 8.73848L3.72677 8.73627L3.81836 8.46158C3.83017 8.42605 3.88047 8.42605 3.89233 8.46158Z"
            fill="#F5F5F5"
        />
        <path
            d="M3.89233 9.89851L3.98392 10.1732L4.27347 10.1754C4.31097 10.1757 4.32648 10.2235 4.29634 10.2458L4.06342 10.4178L4.1508 10.6939C4.16214 10.7296 4.12141 10.7592 4.09089 10.7374L3.85534 10.569L3.6198 10.7374C3.58928 10.7592 3.54864 10.7296 3.55989 10.6939L3.64727 10.4178L3.41434 10.2458C3.3842 10.2235 3.39972 10.1757 3.43722 10.1754L3.72677 10.1732L3.81836 9.89851C3.83017 9.86293 3.88047 9.86293 3.89233 9.89851Z"
            fill="#F5F5F5"
        />
        <path
            d="M3.89233 11.3354L3.98392 11.6101L4.27347 11.6123C4.31097 11.6127 4.32648 11.6605 4.29634 11.6827L4.06342 11.8548L4.1508 12.1308C4.16214 12.1665 4.12141 12.1962 4.09089 12.1743L3.85534 12.0059L3.6198 12.1743C3.58928 12.1961 3.54864 12.1665 3.55989 12.1308L3.64727 11.8548L3.41434 11.6827C3.3842 11.6604 3.39972 11.6127 3.43722 11.6123L3.72677 11.6101L3.81836 11.3354C3.83017 11.2998 3.88047 11.2998 3.89233 11.3354Z"
            fill="#F5F5F5"
        />
        <path
            d="M5.00756 6.29215L5.09916 6.56684L5.3887 6.56904C5.4262 6.56937 5.44172 6.61718 5.41158 6.63945L5.17866 6.81148L5.26603 7.08752C5.27737 7.12324 5.23664 7.15287 5.20612 7.13102L4.97058 6.96265L4.73503 7.13102C4.70452 7.15282 4.66388 7.12324 4.67513 7.08752L4.7625 6.81148L4.52958 6.63945C4.49944 6.61713 4.51495 6.56937 4.55245 6.56904L4.842 6.56684L4.93359 6.29215C4.94536 6.25662 4.9957 6.25662 5.00756 6.29215Z"
            fill="#F5F5F5"
        />
        <path
            d="M5.00756 7.7292L5.09916 8.00389L5.3887 8.00609C5.4262 8.00642 5.44172 8.05423 5.41158 8.07649L5.17866 8.24853L5.26603 8.52457C5.27737 8.56029 5.23664 8.58992 5.20612 8.56807L4.97058 8.3997L4.73503 8.56807C4.70452 8.58987 4.66388 8.56034 4.67513 8.52457L4.7625 8.24853L4.52958 8.07649C4.49944 8.05418 4.51495 8.00642 4.55245 8.00609L4.842 8.00389L4.93359 7.7292C4.94536 7.69362 4.9957 7.69362 5.00756 7.7292Z"
            fill="#F5F5F5"
        />
        <path
            d="M5.00756 9.16609L5.09916 9.44077L5.3887 9.44298C5.4262 9.44331 5.44172 9.49112 5.41158 9.51338L5.17866 9.68541L5.26603 9.96146C5.27737 9.99718 5.23664 10.0268 5.20612 10.005L4.97058 9.83659L4.73503 10.005C4.70452 10.0268 4.66388 9.99718 4.67513 9.96146L4.7625 9.68541L4.52958 9.51338C4.49944 9.49107 4.51495 9.44331 4.55245 9.44298L4.842 9.44077L4.93359 9.16609C4.94536 9.13051 4.9957 9.13051 5.00756 9.16609Z"
            fill="#F5F5F5"
        />
        <path
            d="M5.00756 10.6029L5.09916 10.8776L5.3887 10.8798C5.4262 10.8802 5.44172 10.928 5.41158 10.9502L5.17866 11.1223L5.26603 11.3983C5.27737 11.434 5.23664 11.4637 5.20612 11.4418L4.97058 11.2734L4.73503 11.4418C4.70452 11.4636 4.66388 11.434 4.67513 11.3983L4.7625 11.1223L4.52958 10.9502C4.49944 10.9279 4.51495 10.8802 4.55245 10.8798L4.842 10.8776L4.93359 10.6029C4.94536 10.5674 4.9957 10.5674 5.00756 10.6029Z"
            fill="#F5F5F5"
        />
        <path
            d="M6.12284 5.58772L6.21444 5.86241L6.50398 5.86461C6.54148 5.86494 6.557 5.91275 6.52686 5.93501L6.29394 6.10705L6.38131 6.38309C6.39266 6.41881 6.35192 6.44844 6.32141 6.42659L6.08581 6.25822L5.85027 6.42659C5.81975 6.44839 5.77911 6.41881 5.79036 6.38309L5.87774 6.10705L5.64481 5.93501C5.61467 5.9127 5.63019 5.86494 5.66769 5.86461L5.95724 5.86241L6.04883 5.58772C6.06069 5.55214 6.11098 5.55214 6.12284 5.58772Z"
            fill="#F5F5F5"
        />
        <path
            d="M6.12284 7.02457L6.21444 7.29926L6.50398 7.30146C6.54148 7.30179 6.557 7.3496 6.52686 7.37187L6.29394 7.5439L6.38131 7.81995C6.39266 7.85567 6.35192 7.88529 6.32141 7.86345L6.08581 7.69507L5.85027 7.86345C5.81975 7.88524 5.77911 7.85567 5.79036 7.81995L5.87774 7.5439L5.64481 7.37187C5.61467 7.34956 5.63019 7.30179 5.66769 7.30146L5.95724 7.29926L6.04883 7.02457C6.06069 6.98904 6.11098 6.98904 6.12284 7.02457Z"
            fill="#F5F5F5"
        />
        <path
            d="M6.12284 8.46158L6.21444 8.73627L6.50398 8.73848C6.54148 8.7388 6.557 8.78662 6.52686 8.80888L6.29394 8.98091L6.38131 9.25696C6.39266 9.29268 6.35192 9.3223 6.32141 9.30046L6.08581 9.13208L5.85027 9.30046C5.81975 9.32226 5.77911 9.29268 5.79036 9.25696L5.87774 8.98091L5.64481 8.80888C5.61467 8.78657 5.63019 8.7388 5.66769 8.73848L5.95724 8.73627L6.04883 8.46158C6.06069 8.42605 6.11098 8.42605 6.12284 8.46158Z"
            fill="#F5F5F5"
        />
        <path
            d="M6.12284 9.89851L6.21444 10.1732L6.50398 10.1754C6.54148 10.1757 6.557 10.2235 6.52686 10.2458L6.29394 10.4178L6.38131 10.6939C6.39266 10.7296 6.35192 10.7592 6.32141 10.7374L6.08581 10.569L5.85027 10.7374C5.81975 10.7592 5.77911 10.7296 5.79036 10.6939L5.87774 10.4178L5.64481 10.2458C5.61467 10.2235 5.63019 10.1757 5.66769 10.1754L5.95724 10.1732L6.04883 9.89851C6.06069 9.86293 6.11098 9.86293 6.12284 9.89851Z"
            fill="#F5F5F5"
        />
        <path
            d="M6.12284 11.3354L6.21444 11.6101L6.50398 11.6123C6.54148 11.6127 6.557 11.6605 6.52686 11.6827L6.29394 11.8548L6.38131 12.1308C6.39266 12.1665 6.35192 12.1962 6.32141 12.1743L6.08581 12.0059L5.85027 12.1743C5.81975 12.1961 5.77911 12.1665 5.79036 12.1308L5.87774 11.8548L5.64481 11.6827C5.61467 11.6604 5.63019 11.6127 5.66769 11.6123L5.95724 11.6101L6.04883 11.3354C6.06069 11.2998 6.11098 11.2998 6.12284 11.3354Z"
            fill="#F5F5F5"
        />
        <path
            d="M7.23803 6.29215L7.32963 6.56684L7.61917 6.56904C7.65667 6.56937 7.67219 6.61718 7.64205 6.63945L7.40912 6.81148L7.4965 7.08752C7.50784 7.12324 7.46711 7.15287 7.43659 7.13102L7.20105 6.96265L6.9655 7.13102C6.93498 7.15282 6.89434 7.12324 6.90559 7.08752L6.99297 6.81148L6.76005 6.63945C6.72991 6.61713 6.74542 6.56937 6.78292 6.56904L7.07247 6.56684L7.16406 6.29215C7.17592 6.25662 7.22617 6.25662 7.23803 6.29215Z"
            fill="#F5F5F5"
        />
        <path
            d="M7.23803 7.7292L7.32963 8.00389L7.61917 8.00609C7.65667 8.00642 7.67219 8.05423 7.64205 8.07649L7.40912 8.24853L7.4965 8.52457C7.50784 8.56029 7.46711 8.58992 7.43659 8.56807L7.20105 8.3997L6.9655 8.56807C6.93498 8.58987 6.89434 8.56034 6.90559 8.52457L6.99297 8.24853L6.76005 8.07649C6.72991 8.05418 6.74542 8.00642 6.78292 8.00609L7.07247 8.00389L7.16406 7.7292C7.17592 7.69362 7.22617 7.69362 7.23803 7.7292Z"
            fill="#F5F5F5"
        />
        <path
            d="M7.23803 9.16609L7.32963 9.44077L7.61917 9.44298C7.65667 9.44331 7.67219 9.49112 7.64205 9.51338L7.40912 9.68541L7.4965 9.96146C7.50784 9.99718 7.46711 10.0268 7.43659 10.005L7.20105 9.83659L6.9655 10.005C6.93498 10.0268 6.89434 9.99718 6.90559 9.96146L6.99297 9.68541L6.76005 9.51338C6.72991 9.49107 6.74542 9.44331 6.78292 9.44298L7.07247 9.44077L7.16406 9.16609C7.17592 9.13051 7.22617 9.13051 7.23803 9.16609Z"
            fill="#F5F5F5"
        />
        <path
            d="M7.23803 10.6029L7.32963 10.8776L7.61917 10.8798C7.65667 10.8802 7.67219 10.928 7.64205 10.9502L7.40912 11.1223L7.4965 11.3983C7.50784 11.434 7.46711 11.4637 7.43659 11.4418L7.20105 11.2734L6.9655 11.4418C6.93498 11.4636 6.89434 11.434 6.90559 11.3983L6.99297 11.1223L6.76005 10.9502C6.72991 10.9279 6.74542 10.8802 6.78292 10.8798L7.07247 10.8776L7.16406 10.6029C7.17592 10.5674 7.22617 10.5674 7.23803 10.6029Z"
            fill="#F5F5F5"
        />
        <path
            d="M8.35327 5.58772L8.44486 5.86241L8.73441 5.86461C8.77191 5.86494 8.78742 5.91275 8.75728 5.93501L8.52436 6.10705L8.61173 6.38309C8.62308 6.41881 8.58234 6.44844 8.55183 6.42659L8.31628 6.25822L8.08074 6.42659C8.05022 6.44839 8.00958 6.41881 8.02083 6.38309L8.1082 6.10705L7.87528 5.93501C7.84514 5.9127 7.86066 5.86494 7.89816 5.86461L8.1877 5.86241L8.2793 5.58772C8.29106 5.55214 8.34141 5.55214 8.35327 5.58772Z"
            fill="#F5F5F5"
        />
        <path
            d="M8.35327 7.02457L8.44486 7.29926L8.73441 7.30146C8.77191 7.30179 8.78742 7.3496 8.75728 7.37187L8.52436 7.5439L8.61173 7.81995C8.62308 7.85567 8.58234 7.88529 8.55183 7.86345L8.31628 7.69507L8.08074 7.86345C8.05022 7.88524 8.00958 7.85567 8.02083 7.81995L8.1082 7.5439L7.87528 7.37187C7.84514 7.34956 7.86066 7.30179 7.89816 7.30146L8.1877 7.29926L8.2793 7.02457C8.29106 6.98904 8.34141 6.98904 8.35327 7.02457Z"
            fill="#F5F5F5"
        />
        <path
            d="M8.35327 8.46158L8.44486 8.73627L8.73441 8.73848C8.77191 8.7388 8.78742 8.78662 8.75728 8.80888L8.52436 8.98091L8.61173 9.25696C8.62308 9.29268 8.58234 9.3223 8.55183 9.30046L8.31628 9.13208L8.08074 9.30046C8.05022 9.32226 8.00958 9.29268 8.02083 9.25696L8.1082 8.98091L7.87528 8.80888C7.84514 8.78657 7.86066 8.7388 7.89816 8.73848L8.1877 8.73627L8.2793 8.46158C8.29106 8.42605 8.34141 8.42605 8.35327 8.46158Z"
            fill="#F5F5F5"
        />
        <path
            d="M8.35327 9.89851L8.44486 10.1732L8.73441 10.1754C8.77191 10.1757 8.78742 10.2235 8.75728 10.2458L8.52436 10.4178L8.61173 10.6939C8.62308 10.7296 8.58234 10.7592 8.55183 10.7374L8.31628 10.569L8.08074 10.7374C8.05022 10.7592 8.00958 10.7296 8.02083 10.6939L8.1082 10.4178L7.87528 10.2458C7.84514 10.2235 7.86066 10.1757 7.89816 10.1754L8.1877 10.1732L8.2793 9.89851C8.29106 9.86293 8.34141 9.86293 8.35327 9.89851Z"
            fill="#F5F5F5"
        />
        <path
            d="M8.35327 11.3354L8.44486 11.6101L8.73441 11.6123C8.77191 11.6127 8.78742 11.6605 8.75728 11.6827L8.52436 11.8548L8.61173 12.1308C8.62308 12.1665 8.58234 12.1962 8.55183 12.1743L8.31628 12.0059L8.08074 12.1743C8.05022 12.1961 8.00958 12.1665 8.02083 12.1308L8.1082 11.8548L7.87528 11.6827C7.84514 11.6604 7.86066 11.6127 7.89816 11.6123L8.1877 11.6101L8.2793 11.3354C8.29106 11.2998 8.34141 11.2998 8.35327 11.3354Z"
            fill="#F5F5F5"
        />
        <path
            d="M9.46948 6.29215L9.56107 6.56684L9.85062 6.56904C9.88812 6.56937 9.90363 6.61718 9.87349 6.63945L9.64057 6.81148L9.72795 7.08752C9.73929 7.12324 9.69855 7.15287 9.66804 7.13102L9.43249 6.96265L9.19695 7.13102C9.16643 7.15282 9.12579 7.12324 9.13704 7.08752L9.22441 6.81148L8.99149 6.63945C8.96135 6.61713 8.97687 6.56937 9.01437 6.56904L9.30391 6.56684L9.39551 6.29215C9.40732 6.25662 9.45766 6.25662 9.46948 6.29215Z"
            fill="#F5F5F5"
        />
        <path
            d="M9.46948 7.7292L9.56107 8.00389L9.85062 8.00609C9.88812 8.00642 9.90363 8.05423 9.87349 8.07649L9.64057 8.24853L9.72795 8.52457C9.73929 8.56029 9.69855 8.58992 9.66804 8.56807L9.43249 8.3997L9.19695 8.56807C9.16643 8.58987 9.12579 8.56034 9.13704 8.52457L9.22441 8.24853L8.99149 8.07649C8.96135 8.05418 8.97687 8.00642 9.01437 8.00609L9.30391 8.00389L9.39551 7.7292C9.40732 7.69362 9.45766 7.69362 9.46948 7.7292Z"
            fill="#F5F5F5"
        />
        <path
            d="M9.46948 9.16609L9.56107 9.44077L9.85062 9.44298C9.88812 9.44331 9.90363 9.49112 9.87349 9.51338L9.64057 9.68541L9.72795 9.96146C9.73929 9.99718 9.69855 10.0268 9.66804 10.005L9.43249 9.83659L9.19695 10.005C9.16643 10.0268 9.12579 9.99718 9.13704 9.96146L9.22441 9.68541L8.99149 9.51338C8.96135 9.49107 8.97687 9.44331 9.01437 9.44298L9.30391 9.44077L9.39551 9.16609C9.40732 9.13051 9.45766 9.13051 9.46948 9.16609Z"
            fill="#F5F5F5"
        />
        <path
            d="M9.46948 10.6029L9.56107 10.8776L9.85062 10.8798C9.88812 10.8802 9.90363 10.928 9.87349 10.9502L9.64057 11.1223L9.72795 11.3983C9.73929 11.434 9.69855 11.4637 9.66804 11.4418L9.43249 11.2734L9.19695 11.4418C9.16643 11.4636 9.12579 11.434 9.13704 11.3983L9.22441 11.1223L8.99149 10.9502C8.96135 10.9279 8.97687 10.8802 9.01437 10.8798L9.30391 10.8776L9.39551 10.6029C9.40732 10.5674 9.45766 10.5674 9.46948 10.6029Z"
            fill="#F5F5F5"
        />
        <path
            d="M10.5847 5.58772L10.6763 5.86241L10.9659 5.86461C11.0034 5.86494 11.0189 5.91275 10.9887 5.93501L10.7558 6.10705L10.8432 6.38309C10.8545 6.41881 10.8138 6.44844 10.7833 6.42659L10.5477 6.25822L10.3122 6.42659C10.2817 6.44839 10.241 6.41881 10.2523 6.38309L10.3396 6.10705L10.1067 5.93501C10.0766 5.9127 10.0921 5.86494 10.1296 5.86461L10.4191 5.86241L10.5107 5.58772C10.5226 5.55214 10.5728 5.55214 10.5847 5.58772Z"
            fill="#F5F5F5"
        />
        <path
            d="M10.5847 7.02457L10.6763 7.29926L10.9659 7.30146C11.0034 7.30179 11.0189 7.3496 10.9887 7.37187L10.7558 7.5439L10.8432 7.81995C10.8545 7.85567 10.8138 7.88529 10.7833 7.86345L10.5477 7.69507L10.3122 7.86345C10.2817 7.88524 10.241 7.85567 10.2523 7.81995L10.3396 7.5439L10.1067 7.37187C10.0766 7.34956 10.0921 7.30179 10.1296 7.30146L10.4191 7.29926L10.5107 7.02457C10.5226 6.98904 10.5728 6.98904 10.5847 7.02457Z"
            fill="#F5F5F5"
        />
        <path
            d="M10.5847 8.46158L10.6763 8.73627L10.9659 8.73848C11.0034 8.7388 11.0189 8.78662 10.9887 8.80888L10.7558 8.98091L10.8432 9.25696C10.8545 9.29268 10.8138 9.3223 10.7833 9.30046L10.5477 9.13208L10.3122 9.30046C10.2817 9.32226 10.241 9.29268 10.2523 9.25696L10.3396 8.98091L10.1067 8.80888C10.0766 8.78657 10.0921 8.7388 10.1296 8.73848L10.4191 8.73627L10.5107 8.46158C10.5226 8.42605 10.5728 8.42605 10.5847 8.46158Z"
            fill="#F5F5F5"
        />
        <path
            d="M10.5847 9.89851L10.6763 10.1732L10.9659 10.1754C11.0034 10.1757 11.0189 10.2235 10.9887 10.2458L10.7558 10.4178L10.8432 10.6939C10.8545 10.7296 10.8138 10.7592 10.7833 10.7374L10.5477 10.569L10.3122 10.7374C10.2817 10.7592 10.241 10.7296 10.2523 10.6939L10.3396 10.4178L10.1067 10.2458C10.0766 10.2235 10.0921 10.1757 10.1296 10.1754L10.4191 10.1732L10.5107 9.89851C10.5226 9.86293 10.5728 9.86293 10.5847 9.89851Z"
            fill="#F5F5F5"
        />
        <path
            d="M10.5847 11.3354L10.6763 11.6101L10.9659 11.6123C11.0034 11.6127 11.0189 11.6605 10.9887 11.6827L10.7558 11.8548L10.8432 12.1308C10.8545 12.1665 10.8138 12.1962 10.7833 12.1743L10.5477 12.0059L10.3122 12.1743C10.2817 12.1961 10.241 12.1665 10.2523 12.1308L10.3396 11.8548L10.1067 11.6827C10.0766 11.6604 10.0921 11.6127 10.1296 11.6123L10.4191 11.6101L10.5107 11.3354C10.5226 11.2998 10.5728 11.2998 10.5847 11.3354Z"
            fill="#F5F5F5"
        />
        </g>
        <defs>
        <clipPath id="clip0_831_13011">
            <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5 0.5)"
            />
        </clipPath>
        </defs>
    </svg> 
  )
}

export default USAFlag