import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PlayIcon from "@/assets/svgs/PlayIcon";
import { RecordingModal } from "@/components/modals/recording-modal";
import {
  formatPhoneNumber,
  firstLetterCapital,
  getUTCRecordingTimeSlot,
} from "@/utils/helper";
import moment from "moment";

function RecordingInterviews({ selectedInterview, hideDetail }) {
  const [openRecordingModal, setOpenRecordingModal] = useState(false);
  const [recordingLink, setRecordingLink] = useState("");
  const [allRecordings, setAllRecordings] = useState([]);

  useEffect(() => {
    getFilteredRecording();
  }, []);

  const getFilteredRecording = () => {
    if (selectedInterview?.recordings) {
      const key = "link";
      const arrayUniqueByKey = [
        ...new Map(
          selectedInterview?.recordings.map((item) => [item[key], item])
        ).values(),
      ];
      setAllRecordings(arrayUniqueByKey);
    }
  };

  const viewRecording = (recordingLink) => {
    setOpenRecordingModal(true);
    setRecordingLink(recordingLink);
  };

  return (
    <>
      <div className="dataCol">
        <div className="page page__full">
          <div className="page__body bg--white">
            <div className="dataCol__head head--inner head--responsive">
              <div className="item">
                <div className="initials initials__lg">
                  {firstLetterCapital(
                    selectedInterview?.candidate?.firstName[0]
                  )}
                </div>
                <div className="item__info">
                  <h4 className="item__info--title">
                    {firstLetterCapital(
                      selectedInterview?.candidate?.firstName
                    ) +
                      " " +
                      firstLetterCapital(
                        selectedInterview?.candidate?.lastName
                      )}
                  </h4>
                  <div className="details__row">
                    <div className="details__row--item">
                      <span>Phone:</span>
                      {formatPhoneNumber(
                        selectedInterview?.candidate?.phoneNumber
                      )}
                    </div>
                    <div className="details__row--item">
                      <span>Email:</span>
                      {selectedInterview?.candidate?.email}
                    </div>
                  </div>
                </div>
              </div>
              <div className="dataCol__head--actions">
                <button
                  onClick={() => hideDetail()}
                  className="btn btn__white w_fitContent"
                >
                  Back
                </button>
              </div>
            </div>

            <div className="details">
              <h4 className="details__section--title">Interview Recordings</h4>
              <TableContainer
                component={Paper}
                className="tableContainer p_0"
                sx={{ height: "auto !important" }}
              >
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <TableCell>S.</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allRecordings?.length ? (
                      allRecordings?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell>
                            <div className="cellText">
                              <span className="cellText__dark">
                                {moment(row?.createdAt).format("D MMM , YYYY")}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="cellText">
                              <span className="cellText__dark">
                                {getUTCRecordingTimeSlot(
                                  row?.createdAt,
                                  row?.timeSlot
                                )}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="actions flex--end">
                              <button
                                className="btn btn__white"
                                onClick={() => viewRecording(row?.link)}
                              >
                                View Rec.
                                <PlayIcon />
                              </button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <div className="no-data">No Results Found!</div>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
      <RecordingModal
        openModal={openRecordingModal}
        closeModal={() => setOpenRecordingModal(false)}
        recordingLink={recordingLink}
      />
    </>
  );
}

export default RecordingInterviews;
