import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { CUSTOMER_DEACTIVATE_EMPLOYEE } from "@/rtk-apis/end-points";

const customerDeactivateEmployee = createAsyncThunk(
  "customerDeactivateEmployee",
  async (body) => {
    try {
      const response = await httpService.put(
        CUSTOMER_DEACTIVATE_EMPLOYEE,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default customerDeactivateEmployee;
