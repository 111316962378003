import React from "react";

function WishIcon() {
  return (
    <svg
      width="6"
      height="5"
      viewBox="0 0 6 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.88531 0.550128C4.75226 0.416615 4.59416 0.31068 4.42008 0.238397C4.246 0.166114 4.05937 0.128906 3.87088 0.128906C3.68239 0.128906 3.49576 0.166114 3.32168 0.238397C3.1476 0.31068 2.9895 0.416615 2.85645 0.550128L2.65224 0.756992L2.44803 0.550128C2.31498 0.416615 2.15688 0.31068 1.9828 0.238397C1.80872 0.166114 1.62209 0.128906 1.4336 0.128906C1.24511 0.128906 1.05848 0.166114 0.884399 0.238397C0.71032 0.31068 0.552223 0.416615 0.419173 0.550128C-0.143072 1.11237 -0.177549 2.06182 0.530561 2.78319L2.65224 4.90487L4.77392 2.78319C5.48203 2.06182 5.44755 1.11237 4.88531 0.550128Z"
        fill="#28A0E8"
      />
    </svg>
  );
}

export default WishIcon;
