import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SideBar from "@/components/layout/SideBar";
import Loader from "@/elements/Loader";
import SearchSmallIcon from "@/assets/svgs/SearchSmallIcon";
import PlusIcon from "@/assets/svgs/PlusIcon";
import EditIcon from "@/assets/svgs/EditIcon";
import ActivateIcon from "@/assets/svgs/ActivateIcon";
import DeactivateIcon from "@/assets/svgs/DeactivateIcon";
import customerEmployeeList from "@/rtk-apis/customer/myProfile/customerEmployeeList";
import CustomPagination from "@/elements/pagination/customPagination";
import GoTOPage from "@/elements/gotopage/gotopage";
import SortColumn from "@/elements/SortColumn";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import {
  GetUserDetails,
  firstLetterCapital,
  formatPhoneNumber,
  getInitial,
} from "@/utils/helper";
import BackIcon from "@/assets/svgs/BackIcon.jsx";
import customerActivateEmployee from "@/rtk-apis/customer/companyProfile/customerActivateEmploee";
import customerDeactivateEmployee from "@/rtk-apis/customer/companyProfile/customerDeactivateEmployee";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import { closeActivateEmployeeModal } from "@/store/slices/customer/companyProfile/customerActivateEmployeeSlice";
import { closeDeactivateEmployeeModal } from "@/store/slices/customer/companyProfile/customerDeactivateEmployeeSlice";

const EmployeeList = ({ setOpenEmployeeList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = GetUserDetails();
  const [tablePage, setTablePage] = useState(1);
  const [search, setSearch] = React.useState("");
  const [selecetedEmployeeId, setSelecetedEmployeeId] = React.useState("");
  const [ActionType, setActionType] = React.useState("");
  const [sortByASC, setSortByASC] = React.useState(true);
  const [OpenConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );
  const [sortByColoumn, setSortByColoumn] = React.useState("");

  const { data, loading } = useSelector((globalState) => {
    return globalState.getCompanyEmployeeList;
  });
  const EmployeeActivateResponse = useSelector((globalState) => {
    return globalState.customerActivateEmployeeRes;
  });
  const EmployeeDeactivateResponse = useSelector((globalState) => {
    return globalState.customerDeactivateEmployeeRes;
  });

  useEffect(() => {
    getEmployeeList();
  }, [tablePage, EmployeeActivateResponse, EmployeeDeactivateResponse]);

  const changePage = (event, value) => {
    setTablePage(value);
  };

  const getEmployeeList = (
    page = tablePage,
    search = "",
    columnName = sortByColoumn,
    sort = sortByASC
  ) => {
    let body = {
      companyId: user?.customer?.companyId,
      employeeId: user?.customer?.employeeId,
      limit: 20,
      page,
      search,
      columnName,
      sort: sort ? "ASC" : "DESC",
    };
    dispatch(customerEmployeeList(body));
  };

  const searchInEmployeeList = useCallback(
    _.debounce((searchText) => getEmployeeList(tablePage, searchText), 500),
    []
  );

  const valuesArray = [
    user?.customer?.company?.address,
    user?.customer?.company?.city,
    user?.customer?.company?.stateName,
    user?.customer?.company?.zipcode,
    user?.customer?.company?.country,
  ].filter((value) => value);
  const formattedAddress = valuesArray.join(", ");

  const getSortByData = (columnName) => {
    getEmployeeList(tablePage, search, columnName, sortByASC);
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
  };
  const goToEmployeeDetails = (employeeId) => {
    navigate(`/customer-employee-detail/${employeeId}`);
  };

  const handleOpenAddEmployeeScreen = () => {
    navigate("/customer-add-employee");
  };

  const handleEditEmployee = (employee) => {
    navigate("/customer-add-employee", {
      state: {
        editEmployeeDetails: employee,
        editEmployeeMode: true,
      },
    });
  };

  const OnSelectEmployee = (employeeId, type) => {
    setSelecetedEmployeeId(employeeId);
    setActionType(type);
  };
  const activateDeactivateEmployee = async () => {
    let body = {
      employeeId: selecetedEmployeeId,
    };
    if (ActionType === "activate") {
      dispatch(customerActivateEmployee(body));
      setOpenConfirmationModal(false);
    } else if (ActionType === "deactivate") {
      dispatch(customerDeactivateEmployee(body));
      setOpenConfirmationModal(false);
    }
  };

  const closeModal = () => {
    if (ActionType === "activate") {
      dispatch(closeActivateEmployeeModal());
    } else {
      dispatch(closeDeactivateEmployeeModal());
    }
  };

  const getEmployeeStatus = (employee) => {
    if (employee?.status === "delete") {
      return "Deactivated";
    } else if (employee?.status === "active") {
      if (employee?.signUpStatus === false) {
        return "Invited";
      }else{
        return "Activated"
      }
    }
  };

  return (
    <>
      <div
        className="page__body"
        style={{ height: "calc(100dvh - 115px)", overflowY: "hidden" }}
      >
        <div className="tabContainer">
          <div className="page__head border_0" style={{ height: "115px" }}>
            <h2 className="page__head--title">
              <button
                className="btn btn__transparent btn__back border_0 p_0"
                type="button"
                onClick={() => setOpenEmployeeList(false)}
              >
                <BackIcon />
              </button>
            </h2>
            <div className="itemEmployeeHead">
              {user?.customer?.company?.companyLogo ? (
                <div className="itemEmployeeHead__logo">
                  <img src={user?.customer?.company?.companyLogo} alt="Logo" />
                </div>
              ) : (
                <div className="initials initials__lg">
                  {user?.customer?.company?.companyName
                    ?.charAt(0)
                    .toUpperCase()}
                </div>
              )}
              <div className="itemEmployeeHead__info">
                <h4 className="itemEmployeeHead__info--title">
                  {firstLetterCapital(user?.customer?.company?.companyName)}
                </h4>
                <div className="itemEmployeeHead__info--subtitle">
                  {user?.customer?.company?.industryType.join(",")}
                </div>
                <div className="itemEmployeeHead__info--desc">
                  {formattedAddress}
                </div>
              </div>
            </div>
          </div>
          <div className="tabContainer__item--head tabContaineremaployeeList">
            <div className="listCol__head--actions flex--between w_100">
              <div className="formRow formRow__3 m_0 w_100">
                <div className="formCol">
                  <div className="formField">
                    <div className="formField__group sm formSearch">
                      <span className="formField__icon">
                        <SearchSmallIcon />
                      </span>
                      <input
                        type="text"
                        className="formField__input"
                        placeholder="Search an employee"
                        onChange={(e) => searchInEmployeeList(e?.target?.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="btn btn__white w_fitContent"
                onClick={() => handleOpenAddEmployeeScreen()}
              >
                <PlusIcon />
                Add Employee
              </button>
            </div>
          </div>
          <TableContainer
            component={Paper}
            className="tableContainer"
            style={{ height: "calc(100dvh - 249px)" }}
          >
            <Table className="table tableClickable">
              <TableHead>
                <TableRow>
                  <TableCell>S.</TableCell>
                  <TableCell onClick={() => getSortByData("firstName")}>
                    <SortColumn thName={"Name"} sortByASC={sortByASC} />
                  </TableCell>
                  <TableCell onClick={() => getSortByData("email")}>
                    <SortColumn
                      thName={"Email Address"}
                      sortByASC={sortByASC}
                    />
                  </TableCell>
                  <TableCell onClick={() => getSortByData("phoneNumber")}>
                    <SortColumn thName={"Phone Number"} sortByASC={sortByASC} />
                  </TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.rows?.length
                  ? data?.rows?.map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          className={`${
                            row?.status === "active" ? "" : "tableRowError"
                          }`}
                        >
                          <TableCell component="th" scope="row">
                            {tablePage > 1
                              ? (tablePage - 1) * 20 + (index + 1)
                              : index + 1}
                          </TableCell>
                          <TableCell
                            onClick={() => goToEmployeeDetails(row?.employeeId)}
                          >
                            <div className="profile">
                              <div className="profile__initials">
                                {getInitial(row?.firstName)}
                              </div>
                              <span className="profile__name">
                                {firstLetterCapital(row?.firstName) +
                                  " " +
                                  firstLetterCapital(row?.lastName)}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell> {row?.email}</TableCell>
                          <TableCell>
                            {" "}
                            {row?.countryCode && row?.phoneNumber
                              ? row?.countryCode +
                                " " +
                                formatPhoneNumber(row?.phoneNumber)
                              : "--"}
                          </TableCell>
                          <TableCell>
                            {" "}
                            {user?.customer?.employeeId === row?.employeeId
                              ? row?.role + "(Myself)"
                              : row?.role}
                          </TableCell>
                          <TableCell>
                            <div className="cellText">
                              <span
                                className={`cellText__dark ${
                                  getEmployeeStatus(row) === "Activated"
                                    ? "text--primary"
                                    : "text--danger"
                                }`}
                              >
                                {getEmployeeStatus(row)}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="actions">
                              <Tooltip title="Edit" placement="bottom" arrow>
                                <button
                                  className="btn p_0 border_0"
                                  onClick={() => handleEditEmployee(row)}
                                >
                                  <EditIcon />
                                </button>
                              </Tooltip>
                              {row?.status === "active" ? (
                                <Tooltip
                                  title="Disable User"
                                  placement="bottom"
                                  arrow
                                >
                                  <button
                                    className="btn p_0 border_0"
                                    onClick={() => {
                                      setOpenConfirmationModal(true);
                                      OnSelectEmployee(
                                        row?.employeeId,
                                        "deactivate"
                                      );
                                    }}
                                  >
                                    <DeactivateIcon />
                                  </button>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title="Enable User"
                                  placement="bottom"
                                  arrow
                                >
                                  <button
                                    className="btn p_0 border_0"
                                    onClick={() => {
                                      setOpenConfirmationModal(true);
                                      OnSelectEmployee(
                                        row?.employeeId,
                                        "activate"
                                      );
                                    }}
                                  >
                                    <ActivateIcon />
                                  </button>
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
            {!data?.rows?.length && (
              <div className="no-data">No Results Found!</div>
            )}
          </TableContainer>
          <div className="pagination">
            <div className="pagination__result">
              {`${data?.count > tablePage * 20 ? 20 : data?.count} of ${
                data?.count
              } results`}
            </div>
            {data?.count ? (
              <CustomPagination
                page={tablePage}
                totalPageCount={Math.ceil(data?.count / 20)}
                changePage={(event, value) => changePage(event, value)}
              />
            ) : (
              ""
            )}
            {data?.count ? (
              <div className="pagination__actions">
                <GoTOPage changePage={changePage} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loading && <Loader />}
      {OpenConfirmationModal && (
        <ConfirmationModal
          closeModal={() => setOpenConfirmationModal(false)}
          onSubmit={activateDeactivateEmployee}
          heading={`${
            ActionType === "deactivate" ? "Deactivate" : "Activate"
          } Employee!`}
          confirmationMessage={`Are you sure want to ${
            ActionType === "deactivate" ? "deactivate" : "activate"
          } this account?`}
        />
      )}
      {(EmployeeActivateResponse?.meta?.status ||
        EmployeeDeactivateResponse?.meta?.status) && (
        <SuccessModal
          closeModal={closeModal}
          sucessfullMsg={
            ActionType === "activate"
              ? EmployeeActivateResponse?.meta?.msg
              : EmployeeDeactivateResponse?.meta?.msg
          }
          Heading={
            ActionType === "activate"
              ? "Employee Activated!"
              : "Employee Deactivated!"
          }
        />
      )}
      {(EmployeeActivateResponse?.meta?.status === false ||
        EmployeeDeactivateResponse?.meta?.status === false) && (
        <ErrorModal
          errorMessage={
            ActionType === "activate"
              ? EmployeeActivateResponse?.meta?.msg
              : EmployeeDeactivateResponse?.meta?.msg
          }
          closeModal={closeModal}
          footerVisible={true}
        />
      )}
    </>
  );
};

export default EmployeeList;
