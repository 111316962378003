import { createSlice } from '@reduxjs/toolkit';
import CompanyDeactivateAccount from '@/rtk-apis/customer/companyProfile/companyDeactivateAccount';

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: ""
};

const CompanyDeactivateAccountSlice = createSlice({
  name: 'CompanyDeactivateAccountSlice',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(CompanyDeactivateAccount.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(CompanyDeactivateAccount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(CompanyDeactivateAccount.rejected, (state, action) => {
        state.loading = false;
      });
  }
});

export default CompanyDeactivateAccountSlice.reducer;

export const { closeRtkModal } = CompanyDeactivateAccountSlice.actions;
