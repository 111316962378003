import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CUSTOMER_APPROVED_CANDIDATE_LISTING } from '@/rtk-apis/end-points';

const getApprovedCandidatesList = createAsyncThunk(
  'getApprovedCandidatesList',
  async (body) => {
    try {
      const response = await httpService.post(CUSTOMER_APPROVED_CANDIDATE_LISTING, body);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default getApprovedCandidatesList;
