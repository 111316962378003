import { createSlice } from '@reduxjs/toolkit';
import removeTagDetails from '@/rtk-apis/customer/tags/removeTagDetails';

const initialState = {
  message: '',
  data: {},
  meta: {},
  loading: false,
  error: ''
};

const removeTagDetailsSlice = createSlice({
  name: 'removeTagDetails',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeTagDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(removeTagDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
      })
      .addCase(removeTagDetails.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  }
});

export default removeTagDetailsSlice.reducer;

export const { closeRtkModal } = removeTagDetailsSlice.actions;
