import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { firstLetterCapital, getInitial, GetUserDetails } from "@/utils/helper";
import { checkForAdminAndHiringLead } from "@/utils/helper";

function ApprovedCandidatesFeedback({
  setOpenFeedbackScreen,
  jobData,
  status,
  selectedJob,
}) {
  const user = GetUserDetails();
  const role = user?.customer?.role;
  const [SelectedRadioValue, setSelectedRadioValue] = useState("1");
  const [QuestionsData, setQuestionsData] = useState([]);

  useEffect(() => {
    handleRadioClick();
  }, [SelectedRadioValue]);

  const handleRadioClick = () => {
    if (SelectedRadioValue === "1") {
      setQuestionsData(filterFeedbackData(jobData?.jmtFeedback, "Hiring Lead"));
    } else if (SelectedRadioValue === "2") {
      setQuestionsData(
        jobData?.screening?.feedback?.filter((x) => x.role === "Admin")
      );
    } else if (SelectedRadioValue === "3") {
      setQuestionsData(filterFeedbackData(jobData?.jmtFeedback, "Employee"));
    }
  };

  const filterFeedbackData = (obj, role) => {
    let filterData = obj?.filter((a) => a.role == role);
    return filterData;
  };

  const handleRadioChange = (e) => {
    setSelectedRadioValue(e.target.value);
  };

  return (
    <>
      <div className="dataCol__head feedback--head">
        <div className="notes__head w_100">
          <FormControl>
            <RadioGroup>
              {checkForAdminAndHiringLead(selectedJob, role) && (
                <FormControlLabel
                  name="Hiring Lead"
                  value="1"
                  checked={SelectedRadioValue === "1" ? true : false}
                  control={<Radio />}
                  label="Hiring Lead"
                  onChange={(e) => handleRadioChange(e)}
                />
              )}
              <FormControlLabel
                name="Admin"
                value="2"
                control={<Radio />}
                label="Laila"
                checked={SelectedRadioValue === "2" ? true : false}
                onChange={(e) => handleRadioChange(e)}
              />
              <FormControlLabel
                name="Job team member"
                control={<Radio />}
                value="3"
                label="Job team member"
                checked={SelectedRadioValue === "3" ? true : false}
                onChange={(e) => handleRadioChange(e)}
              />
            </RadioGroup>
          </FormControl>
          <button
            className="btn btn__white"
            type="button"
            onClick={() => {
              setOpenFeedbackScreen(false);
            }}
          >
            Back
          </button>
        </div>
        <div className="formField__label mt_1">
          This feedback will be shared with <b>Laila</b>
        </div>
      </div>

      {QuestionsData?.map((row, index) => (
        <div key={`question-data-${index}`}>
          <React.Fragment key={`question-${index}`}>
            <div className="dataCol__head feedback--head">
              <div className="profile">
                <div className="profile__initials">
                  {getInitial(row?.firstName)}
                </div>
                <span className="profile__name">
                  {firstLetterCapital(row?.firstName)}{" "}
                  {firstLetterCapital(row?.lastName)}
                </span>
                <div
                  className={`profile__alert ${
                    row?.status === "Approved"
                      ? "alert--success"
                      : "alert--danger"
                  }`}
                >
                  {row?.status}
                </div>
              </div>
            </div>
            <form key={`form-${index}`} className="form">
              <div className="formRow formRow__2">
                {row?.feedBackData?.map((item, itemIndex) => (
                  <div
                    key={`row-${index}-item-${itemIndex}`}
                    className="formCol"
                  >
                    <div className="formField">
                      <label className="formField__label">
                        {item?.question}
                      </label>
                      <textarea
                        className="formField__textarea"
                        value={item?.answer}
                        rows={3}
                        readOnly
                      ></textarea>
                    </div>
                  </div>
                ))}
              </div>
            </form>
          </React.Fragment>
        </div>
      ))}

      {!QuestionsData?.length && (
        <div className="tableContainer">
          <div className="no-data floating">No Results Found!</div>
        </div>
      )}
    </>
  );
}

export default ApprovedCandidatesFeedback;
