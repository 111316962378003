import { createSlice } from "@reduxjs/toolkit";
import approvedCandidatesList from "@/rtk-apis/myJobs/approvedCandidatesList";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const approvedCandidatesListSlice = createSlice({
  name: "approvedCandidatesList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(approvedCandidatesList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(approvedCandidatesList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
      })
      .addCase(approvedCandidatesList.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default approvedCandidatesListSlice.reducer;
