import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { REMOVE_TAG_DETAILS } from '@/rtk-apis/end-points';

const removeTagDetails = createAsyncThunk('removeTagDetails', async (body) => {
  try {
    const response = await httpService.put(REMOVE_TAG_DETAILS, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default removeTagDetails;
