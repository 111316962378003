import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { FETCH_DEBIT_PAYMENT_METHODS } from '@/rtk-apis/end-points';
import { GetUserDetails } from '@/utils/helper';

const getPaymentMethods = createAsyncThunk('getPaymentMethods', async () => {
  const user = GetUserDetails();
  try {
    const response = await httpService.get(`${FETCH_DEBIT_PAYMENT_METHODS}${user?.customer?.companyId}`, {});
    return response;
  } catch (error) {
    return error;
  }
});

export default getPaymentMethods;
