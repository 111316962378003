import React from "react";

const GoogleSvg = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.8289 10.0759C19.8289 9.39215 19.7676 8.73468 19.6536 8.10352H10.5718V11.8335H15.7614C15.5378 13.0389 14.8585 14.0602 13.8372 14.7439V17.1634H16.9536C18.777 15.4847 19.8289 13.0126 19.8289 10.0759Z"
        fill="#4285F4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5718 19.4992C13.1754 19.4992 15.3582 18.6357 16.9536 17.163L13.8372 14.7435C12.9737 15.3221 11.8692 15.664 10.5718 15.664C8.06027 15.664 5.93446 13.9677 5.17618 11.6885H1.95459V14.1869C3.54128 17.3383 6.80232 19.4992 10.5718 19.4992Z"
        fill="#34A853"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.17595 11.689C4.98309 11.1105 4.87352 10.4924 4.87352 9.85689C4.87352 9.22133 4.98309 8.60332 5.17595 8.02474V5.52637H1.95436C1.30128 6.82815 0.928711 8.30088 0.928711 9.85689C0.928711 11.4129 1.30128 12.8856 1.95436 14.1874L5.17595 11.689Z"
        fill="#FBBC05"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5718 4.04909C11.9875 4.04909 13.2586 4.53562 14.258 5.49114L17.0237 2.72539C15.3538 1.16939 13.171 0.213867 10.5718 0.213867C6.80232 0.213867 3.54128 2.37474 1.95459 5.5262L5.17618 8.02458C5.93446 5.74536 8.06027 4.04909 10.5718 4.04909Z"
        fill="#EA4335"
      />
    </svg>
  );
};
export default GoogleSvg;
