import React from "react";

function MeetingStatus({ time, interviewStatus }) {
  const meetingTimePast = (time) => {
    const today = new Date();
    return today > new Date(time);
  };

  return (
    <>
      {interviewStatus === "cancelled" ? (
        <span className="cellText__dark text--danger">Cancel</span>
      ) : meetingTimePast(time) ? (
        <span className="cellText__dark text--danger">Past</span>
      ) : (
        <span className="cellText__dark text--success">Upcoming</span>
      )}
    </>
  );
}

export default MeetingStatus;
