import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CUSTOMER_DASHBOARD_DATA } from '@/rtk-apis/end-points';

const dashboardData = createAsyncThunk('dashboardData', async (obj) => {
  try {
    const response = await httpService.get(`${CUSTOMER_DASHBOARD_DATA}${obj?.companyId}/${obj?.employeeId}`, {});
    return response;
  } catch (error) {
    return error;
  }
});

export default dashboardData;
