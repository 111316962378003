import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { firstLetterCapital } from "@/utils/helper";

export default function MultiSelectEmployeeFilter({
  labels = [],
  applyFilter,
  clearFilter,
}) {
  const [options, setOptions] = useState([]);
  const [retainOptions, setRetainOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filterSeacrhText, setFilterSeacrhText] = useState("");
  const [filteranchorEl, setFilterAnchorEl] = useState(null);

  const filterOpen = Boolean(filteranchorEl);
  const filterPopoverid = filterOpen ? "simple-popover" : undefined;

  useEffect(() => {
    setOptions(labels);
    setRetainOptions(labels);
  }, [labels]);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const searchInFilterList = (searchText = "") => {
    setFilterSeacrhText(searchText);
    if (searchText) {
      let rgxp = new RegExp(searchText.toLowerCase(), "g");

      function findFilterNames() {
        return retainOptions.filter((x) =>
          x?.firstName?.toLowerCase().match(rgxp)
        );
      }
      setOptions(findFilterNames());
    } else {
      setOptions(retainOptions);
    }
  };

  const selectionInFilterListing = (e) => {
    let newArray = selectedOptions;
    if (newArray.includes(e?.target?.value)) {
      const arrIndex = newArray.indexOf(e?.target?.value);
      if (arrIndex > -1) {
        newArray.splice(arrIndex, 1);
      }
    } else {
      newArray.push(e?.target?.value);
    }
    setSelectedOptions(newArray);
  };

  return (
    <div className="customMultiSelect">
      <button
        className="btn btn__white p_0"
        aria-describedby={filterPopoverid}
        onClick={handleFilterClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M18.3337 2.5H1.66699L8.33366 10.3833V15.8333L11.667 17.5V10.3833L18.3337 2.5Z"
            stroke="#150823"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {selectedOptions?.length ? (
          <span className="tagCount">{selectedOptions?.length}</span>
        ) : (
          ""
        )}
      </button>
      <Popover
        id={filterPopoverid}
        open={filterOpen}
        anchorEl={filteranchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{ root: "filterPopover" }}
      >
        <div className="formField">
          <label className="formField__label">
            <b>Filter</b>
          </label>
          <label className="formField__label">Created By</label>
          <div className="formField__group sm">
            <input
              type="text"
              className="formField__input"
              placeholder="Search"
              onChange={(e) => searchInFilterList(e?.target?.value)}
              value={filterSeacrhText}
            />
          </div>
        </div>
        <ul className="menuPopover__list">
          {options &&
            options.map((item) => {
              return (
                <li
                  key={`jobPostFilter${item?.employeeId}`}
                  className="menuPopover__list--item"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={item?.employeeId}
                        onChange={(e) => selectionInFilterListing(e)}
                        defaultChecked={selectedOptions?.includes(
                          item?.employeeId
                        )}
                      />
                    }
                    label={`${firstLetterCapital(
                      item?.firstName
                    )} ${firstLetterCapital(item?.lastName)}`}
                  />
                </li>
              );
            })}
        </ul>
        <div className="formField__select--actions">
          <button
            className="btn btn__transparent"
            onClick={() => {
              setSelectedOptions([]);
              setOptions(retainOptions);
              setFilterSeacrhText("");
              clearFilter();
              handleFilterClose();
            }}
          >
            Clear
          </button>
          <button
            className="btn btn__primary"
            onClick={(e) => {
              applyFilter(selectedOptions);
              handleFilterClose();
            }}
          >
            Apply
          </button>
        </div>
      </Popover>
    </div>
  );
}
