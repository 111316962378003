import { createSlice } from '@reduxjs/toolkit';
import UpdateCompanyProfile from '@/rtk-apis/customer/companyProfile/updateCompanyProfileDetails';

const initialState = {
  message: '',
  data: {},
  meta: {},
  token: '',
  loading: false,
  error: ''
};

const updateCompanyProfileSlice = createSlice({
  name: 'getStateCityData',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(UpdateCompanyProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(UpdateCompanyProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(UpdateCompanyProfile.rejected, (state, action) => {
        state.loading = false;
      });
  }

});

export default updateCompanyProfileSlice.reducer;

export const { closeRtkModal } = updateCompanyProfileSlice.actions;
