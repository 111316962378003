import { createSlice } from "@reduxjs/toolkit";
import recruiterCandidateList from "@/rtk-apis/recruiterBilling/recruiterCandidateList";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const recruiterCandidateListSlice = createSlice({
  name: "recruiterJobListSlice",
  initialState,
  reducers: {
    closeRecruiterCandidateListModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(recruiterCandidateList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(recruiterCandidateList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(recruiterCandidateList.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default recruiterCandidateListSlice.reducer;

export const {
  closeRecruiterCandidateListModal,
} = recruiterCandidateListSlice.actions;
