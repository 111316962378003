import { createSlice } from "@reduxjs/toolkit";
import getAllTagsList from "@/rtk-apis/customer/tags/getAllTagsList";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const getAllTagsListSlice = createSlice({
  name: "getAllTagsList",
  initialState,
  reducers: {
    closeRtkModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTagsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllTagsList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(getAllTagsList.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default getAllTagsListSlice.reducer;

export const { closeCreditCardModal } = getAllTagsListSlice.actions;
