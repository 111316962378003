import BagSvg from "@/assets/svgs/BagSvg";
import HomeSvg from "@/assets/svgs/HomeSvg";
import UserSearchSvg from "@/assets/svgs/UserSearchSvg";
import CompanyIcon from "@/assets/svgs/CompanyIcon";
import BillPaymentIcon from "@/assets/svgs/BillPaymentIcon";
import InterviewsIcon from "@/assets/svgs/InterviewsIcon";
import JobRequestIcon from "@/assets/svgs/JobRequestIcon";
export const API_URL =
  process.env.REACT_APP_NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_NODE_ENV === "staging"
    ? process.env.REACT_APP_API_URL_STAGING
    : process.env.REACT_APP_API_URL_PRODUCTION;

export const APP_URL =
  process.env.REACT_APP_NODE_ENV === "development"
    ? process.env.REACT_APP_APP_URL_DEV
    : process.env.REACT_APP_NODE_ENV === "staging"
    ? process.env.REACT_APP_APP_URL_STAGING
    : process.env.REACT_APP_APP_URL_PRODUCTION;

export const LAILA_LOGO = `https://storage.googleapis.com/laila-${process.env.REACT_APP_BUCKET_ENV}-assets/Laila_logo.png`;

export const INSTITUTION_LOGO_BUCKET = process.env.REACT_APP_INSTITUTION_BUCKET;

export const PAYPAL_API_KEY = process.env.REACT_APP_PAYPAL_API_KEY;

export const APP_VERSION = "v1.0.0";

export const CREDIT_CARD_IMAGE =
  "https://storage.googleapis.com/laila-dev-assets/credit-card.png";

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MaxHeight = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  classes: {
    paper: "custom-popover-paper",
  },
};

// ***********************VALIDATION CONSTANTS*******************************************

export const FIRST_NAME = {
  label: "First Name",
  validation: {
    valid: "First name is required.",
  },
};
export const ROLE = {
  label: "ROLE",
  validation: {
    valid: "Role is required.",
  },
};

export const GRADUATION_YEAR = {
  label: "Graduation Year",
  validation: {
    valid: "Graduation year should be between 1929 and 2034.",
  },
};

export const LAST_NAME = {
  label: "Last Name",
  validation: {
    valid: "Last name is required.",
  },
};

export const COUNTRY = {
  label: "COUNTRY",
  values: {
    US: "United States",
    CA: "Canada",
  },
  validation: {
    valid: "Please select the country",
  },
};
export const ZIP_CODE = {
  label: "Zip code",
  validation: {
    valid: "Please enter a valid zip code.",
  },
};
export const POSTAL_CODE = {
  label: "Postal code",
  validation: {
    valid: "Please enter a valid postal code.",
  },
};
export const MIN_EXPERIENCE = {
  label: "Min Experience",
  validation: {
    required: "Min experience is required.",
    valid: "Min experience must be greater than or equal to 0.",
    maxValid: "Min experience must be less than or equal to 99.",
  },
};

export const MAX_EXPERIENCE = {
  label: "Man Experience",
  validation: {
    required: "Max experience is required.",
    valid: "Max exp must be equal to or greater than min exp.",
    maxValid: "Max experience must be less than or equal to 99.",
  },
};
export const MIN_COMPENSATION = {
  label: "Min Compensation",
  validation: {
    valid: "Min must be greater than 0.",
  },
};
export const MAX_COMPENSATION = {
  label: "Max Compensation",
  validation: {
    valid: "Max must be greater than or equal to min.",
  },
};
export const PHONE_NUMBER = {
  label: "Phone number",
  validation: {
    valid: "Please enter a valid 10-digit phone number.",
  },
};

export const EMAIL = {
  label: "Email",
  validation: {
    required: "Email is required",
    valid: "Please enter a valid email address.",
  },
};
export const PASSWORD = {
  label: "Password",
  validation: {
    required: "Password is required.",
    valid: "Password must be at least 8 characters.",
    match: "Password and Confirm Password does not match.",
  },
};
export const CONFIRM_PASSWORD = {
  label: "Confirm Password",
  validation: {
    required: "Confirm Password is required.",
    match: "Password and Confirm Password does not match.",
  },
};

export const SELECTED_SKILL_SET = {
  label: "Recruiting Fields",
  validation: {
    valid: "Recruiting fields field must have at least 1 items.",
  },
};

export const EXPERIENCE = {
  validation: {
    valid: "Experience is required.",
  },
};

export const JOBTITLE = {
  label: "Job Title",
  validation: {
    valid: "Please enter job title.",
  },
};
export const ZIPCODE = {
  label: "Zipcode",
  validation: {
    valid: "Please enter valid zipcode.",
  },
};
export const WEBSITE_LINK = {
  label: "Zipcode",
  validation: {
    valid: "Please enter valid website url.",
  },
};
export const LINKEDIN = {
  label: "Linkedin",
  validation: {
    valid: "Please enter a valid linkedIn address.",
  },
};
export const COMPANYNAME = {
  label: "Company Name",
  validation: {
    valid: "Please enter company name.",
  },
};
export const JOBDESCRIPTION = {
  label: "Job Description",
  validation: {
    valid: "Please enter job description.",
  },
};
export const LOCATION = {
  label: "Location",
  validation: {
    valid: "Please enter location.",
  },
};

export const EMPLOYMENT_TYPE = {
  label: "Employment Type",
  validation: {
    valid: "Employment type must have at least 1 item.",
  },
};

export const INDUSTRY_TYPE = {
  label: "Industry Type",
  validation: {
    valid: "Industry type must have at least 1 item.",
  },
};

export const REQUIRED_QUALIFICATION = {
  label: "Required Qualification",
  validation: {
    valid: "Required qualification must have at least 1 item.",
  },
};

export const APPLICATION_DEADLINE = {
  label: "Application Deadline",
  validation: {
    valid: "Application deadline is required.",
  },
};

export const JOB_TEAM_MEMBER = {
  label: "Job Team Member",
  validation: {
    required: "Job team member is a required.",
    valid: "Job team member must have at least 1 member.",
  },
};

export const HIRING_LEAD = {
  label: "Hiring Lead",
  validation: {
    required: "Hiring lead is a required.",
    valid: "Hiring lead must have at least 1 member.",
  },
};

export const SKILL_SET = {
  label: "Skill set",
  validation: {
    valid: "Skill set must have at least 1 item.",
  },
};

export const INSTITUTION = {
  label: "institution",
  validation: {
    valid: "Institution is required.",
  },
};

export const DEGREE = {
  label: "degree",
  validation: {
    valid: "Degree is required.",
  },
};

export const MAJOR_FIELD_OF_STUDY = {
  label: "majorFieldOfStudy",
  validation: {
    valid: "Major or Field of study is required.",
  },
};

// *******************************SIDEBAR MENUS**************************************

export const COMPANY_FINANCE_ADMIN_MENUS = [
  "dashboard",
  "customer-billing-payments",
];

export const COMPANY_FINANCE_AND_JTM_MENUS = [
  "dashboard",
  "customer-my-jobs",
  "customer-interviews",
  "customer-billing-payments",
];

export const JOB_CREATER_MENUS = [
  "dashboard",
  "customer-my-jobs",
  "customer-interviews",
];

export const COMPANY_ADMIN_MENUS = [
  "dashboard",
  "customer-my-jobs",
  "customer-interviews",
  "customer-billing-payments",
  "customer-company-profile",
];

export const EMPLOYEE_MENUS = [
  "dashboard",
  "customer-my-jobs",
  "customer-interviews",
];

export const RECRUITER_MENUS = [
  "dashboard",
  "job-requests",
  "my-jobs",
  "candidates",
  "interviews",
  "recruiter-billing-payments",
];

export const CANDIDATE_MENUS = ["dashboard"];

export const NAME = {
  label: "Name",
  validation: {
    valid: "Please enter your name.",
  },
};

// **********************************CUSTOMER/RECUITER MENUS*********************************************
export const RECRUITER_SIDEBAR_MENU = [
  {
    label: "Home",
    menuName: "dashboard",
    activeMenuName: "dashboard",
    link: "/dashboard",
    icon: <HomeSvg />,
  },
  {
    label: "Job Request",
    menuName: "job-requests",
    activeMenuName: "job-requests",
    link: "/job-requests",
    icon: <JobRequestIcon />,
  },
  {
    label: "My Jobs",
    menuName: "my-jobs",
    activeMenuName: "my-jobs",
    link: "/my-jobs",
    icon: <BagSvg />,
  },
  {
    label: "Candidates",
    menuName: "candidates",
    activeMenuName: "candidates",
    link: "/candidates",
    icon: <UserSearchSvg />,
  },
  {
    label: "Interviews",
    menuName: "interviews",
    activeMenuName: "interviews",
    link: "/interviews",
    icon: <InterviewsIcon />,
  },
  {
    label: "Billing & Payments",
    menuName: "recruiter-billing-payments",
    activeMenuName: "recruiter-billing-payments",
    link: "/recruiter-billing-payments",
    icon: <BillPaymentIcon />,
  },
];

export const CANDIDATE_SIDEBAR_MENU = [
  {
    label: "Home",
    menuName: "dashboard",
    activeMenuName: "dashboard",
    link: "/candidate-dashboard",
    icon: <HomeSvg />,
  },
];

export const CUSTOMER_SIDEBAR_MENU = [
  {
    label: "Home",
    menuName: "dashboard",
    activeMenuName: "dashboard",
    link: "/customer-dashboard",
    icon: <HomeSvg />,
  },
  {
    label: "My Jobs",
    menuName: "customer-my-jobs",
    activeMenuName: "my-jobs",
    link: "/customer-my-jobs",
    icon: <BagSvg />,
  },
  {
    label: "Interviews",
    menuName: "customer-interviews",
    activeMenuName: "customer-interviews",
    link: "/customer-interviews",
    icon: <InterviewsIcon />,
  },
  {
    label: "Billing & Payments",
    menuName: "customer-billing-payments",
    activeMenuName: "customer-billing-payments",
    link: "/customer-billing-payments",
    icon: <BillPaymentIcon />,
  },
  {
    label: "Company Profile",
    menuName: "customer-company-profile",
    activeMenuName: "customer-company-profile",
    link: "/customer-company-profile",
    icon: <CompanyIcon />,
  },
];

export const ADDRESS = {
  label: "Address",
  validation: {
    valid: "Please enter an address.",
  },
};

export const CITY = {
  label: "City",
  validation: {
    valid: "Please select a city.",
  },
};

export const STATE = {
  label: "State",
  validation: {
    valid: "Please select a state.",
  },
};

export const TAX_PAYER_NAME = {
  label: "Tax Payer Name",
  validation: {
    valid: "Tax payer name is required.",
  },
};

export const TAX_CLASSIFICATIONS = {
  label: "Tax Classification",
  validation: {
    valid: "Tax classification is required.",
  },
};

export const TAX_IDENTIFICATION_NUMBER = {
  label: "Tax Identification Number",
  validation: {
    valid: "Tax identification number is required.",
  },
};

export const SIGNATURE = {
  label: "signature",
  validation: {
    valid: "Signature is required.",
  },
};

export const ACCOUNT_HOLDER_NAME = {
  label: "Account Holder Name",
  validation: {
    valid: "Account holder name is required.",
  },
};

export const ACCOUNT_TYPE = {
  label: "Account Type",
  validation: {
    valid: "Account type is required.",
  },
};

export const ROUTING_NUMBER = {
  label: "Routing Number",
  validation: {
    valid: "Routing number is required.",
    minValid: "Routing number is invalid.",
  },
};

export const ACCOUNT_NUMBER = {
  label: "Account Number",
  validation: {
    valid: "Account number is required.",
    minValid: "Account number is invalid.",
    match: "Account number not matched",
  },
};
export const CARD_NUMBER = {
  label: "Card Number",
  validation: {
    required: "Card number is required.",
    valid: "Enter a valid 14-15, or 16 digit card number.",
  },
};
export const SECURITY_CODE = {
  label: "Security Code",
  validation: {
    required: "Security Code is required.",
    valid: "Enter a valid 3 or 4 digit security code.",
  },
};

export const BANK_NAME = {
  label: "Bank Name",
  validation: {
    valid: "Bank name is required.",
  },
};

export const GRADUATION_MONTH = [
  "Jan",
  "Feb",
  "March",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export const TAX_CLASSIFICATION = [
  { id: 1, value: "Individual" },
  { id: 2, value: "Corporation" },
  { id: 3, value: "Other" },
];
export const BANK_ACCOUNT_TYPE = [
  "Personal checking",
  "Personal savings",
  "Business checking",
  "Business savings",
];
export const WITHDRAWL_AMOUNT = [500, 1000, 2000];
export const MONTHS_IN_NUMBERS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const VALID_CUSTOMER_CREDIT_CARD = [
  "mastercard",
  "visa",
  "discover",
  "american-express",
  "diners-club",
];

export const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

export const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

export const editorStyle = {
  width: "100%",
};
