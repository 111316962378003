import React, { useState, useEffect } from "react";
import SearchIcon from "@/assets/svgs/SearchIcon";
import FilterIcon from "@/assets/svgs/FilterIcon";
import Search from "@/components/Search";
import InterviewsFilter from "@/pages/Interviews/InterviewsFilter";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GetUserDetails } from "@/utils/helper";
import InterviewsCandidatesListing from "@/pages/Interviews/InterviewsCandidatesListing";
import InterviewsLailaListing from "@/pages/Interviews/InterviewsLailaListing";
import candidateInterviews from "@/rtk-apis/interviews/candidateInterviews";
import Loader from "@/elements/Loader";
import CustomPagination from "@/elements/pagination/customPagination";

function InterviewsListing({
  selectedIndex,
  selectedCandidate,
  changeIndex,
  changeTab,
  selectedRecruiterInterview,
}) {
  const user = GetUserDetails();
  const dispatch = useDispatch();
  const [value, setValue] = useState("1");
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [applyFilters, setApplyFilters] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [candidateList, setCandidateList] = useState([]);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("All");

  const { data, loading } = useSelector((globalState) => {
    return globalState.candidateInterviewsResponse;
  });

  const handleTablistChange = (event, newValue) => {
    setValue(newValue);
    changeTab(newValue);
  };

  useEffect(() => {
    if (value === "1") {
      candidatesListAPIData();
    }
  }, [search, page, value, applyFilters]);

  useEffect(() => {
    setCandidateList(data?.rows);
  }, [data]);

  const candidatesListAPIData = () => {
    dispatch(
      candidateInterviews({
        recruiterId: user?.recruiter?.recruiterId,
        page: search ? 1 : page,
        limit: 20,
        search,
        startTime: startDate,
        endTime: endDate,
        status,
        type: "candidate",
      })
    );
  };

  const filterCount = () => {
    let filterCount = 0;
    if (startDate || endDate) {
      filterCount += 1;
    }
    if (status !== "All") {
      filterCount += 1;
    }
    return `(${filterCount})`;
  };

  const closeSearch = () => {
    setShowSearch(false);
    setSearch("");
    setPage(1);
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  const apply = () => {
    setApplyFilters(true);
    setShowFilter(false);
  };

  const clearFilters = () => {
    setApplyFilters(false);
    setStartDate("");
    setEndDate("");
    setStatus("All");
    setShowFilter(false);
  };

  return (
    <>
      <div
        className={`listCol ${showFilter && "listCol__filter"} ${
          showSearch && "listCol__search"
        }`}
      >
        <div className="listCol__head listing__head">
          <Search
            closeSearch={closeSearch}
            searchTerm={(value) => setSearch(value)}
            placeholder={value === "1" ? "a candidate" : "an interview title"}
          />
          <h2 className="listCol__head--title">Interviews</h2>
          <div className="listCol__head--actions">
            <button
              onClick={() => {
                setShowSearch(true);
              }}
              className="btn btn__white p_0"
            >
              <SearchIcon />
            </button>
            <button
              onClick={() => setShowFilter(true)}
              className="btn btn__white p_0"
            >
              <FilterIcon />
            </button>
          </div>
        </div>

        <InterviewsFilter
          closeFilter={() => setShowFilter(false)}
          apply={() => apply()}
          clearFilters={() => clearFilters()}
          startDateChange={(val) => setStartDate(val)}
          endDateChange={(val) => setEndDate(val)}
          statusChange={(val) => setStatus(val)}
          status={status}
        />

        {applyFilters && (
          <div className="listColTitle filterClear">
            <p>Filters applied {filterCount()}</p>
            <button className="filterClear__btn" onClick={() => clearFilters()}>
              Clear filter
            </button>
          </div>
        )}

        <Box className="tabContainer">
          <TabContext value={value}>
            <Box className="tabContainer__head">
              <TabList onChange={handleTablistChange}>
                <Tab label="Candidates" value="1" />
                <Tab label="Laila" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" className="tabContainer__item">
              <InterviewsCandidatesListing
                changeIndex={(index, candidate, makeResponsive) =>
                  changeIndex(index, candidate, makeResponsive)
                }
                selectedCandidate={selectedCandidate}
                selectedIndex={selectedIndex}
                candidateList={candidateList}
              />
            </TabPanel>
            <TabPanel value="2" className="tabContainer__item">
              <InterviewsLailaListing
                selectedRecruiterInterview={(index, value) =>
                  selectedRecruiterInterview(index, value)
                }
                selectedIndex={selectedIndex}
              />
            </TabPanel>
            <div className="pagination pagination__center">
              {candidateList?.length ? (
                <CustomPagination
                  page={page}
                  totalPageCount={Math.ceil(data?.count / 20)}
                  changePage={(event, value) => changePage(event, value)}
                />
              ) : (
                ""
              )}
            </div>
            {loading && <Loader />}
          </TabContext>
        </Box>
      </div>
    </>
  );
}

export default InterviewsListing;
