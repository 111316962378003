import React, { useState, useEffect } from "react";
import ShortlistedCandidatesListing from "@/customer-pages/MyJobs/ShortlistedCandidates/ShortlistedCandidatesListing";
import ShortlistedCandidatesFeedback from "@/customer-pages/MyJobs/ShortlistedCandidates/ShortlistedCandidatesFeedback";
import customershortlistedCandidates from "@/rtk-apis/customer/myJobs/shortlistedCandidates/customershortlistedCandidates";
import { useDispatch, useSelector } from "react-redux";
import { GetUserDetails } from "@/utils/helper";
import approveRejectInterviewCandidate from "@/rtk-apis/customer/myJobs/interview/approveRejectinterviewCandidate";
import { closeRtkModal } from "@/store/slices/customer/myJobs/interview/approveRejectInterviewCandidateSlice";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ApprovedCandidatesFeedback from "@/customer-pages/MyJobs/ApprovedCandidates/ApprovedCandidatesFeedback";
import { getApproveOrRejectRole } from "@/utils/helper";
import getAllTagsList from "@/rtk-apis/customer/tags/getAllTagsList";

function ShortlistedCandidates({ selectedJob, changeCandidateViewType }) {
  const dispatch = useDispatch();
  const userDetails = GetUserDetails();
  const [OpenConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [status, setStatus] = useState("All");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [candidateStatus, setCandidateStatus] = useState("");
  const [candidateData, setCandidateData] = useState({});
  const [candidateFeedbackData, setCandidateFeedbackData] = useState({});
  const [sortByASC, setSortByASC] = useState(true);
  const [sortByColoumn, setSortByColoumn] = useState("");
  const [selectedTagArr, setSelectedTagArr] = useState([]);

  const [OpenCandidateFeedbackScreen, setOpenCandidateFeedbackScreen] =
    useState(false);

  const [OpenFeedbackScreen, setOpenFeedbackScreen] = useState(false);
  const [refreshList, setRefreshList] = useState(false);

  useEffect(() => {
    getShortlistedCandidatesList();
  }, [search, page, status, refreshList, sortByASC, sortByColoumn]);

  const changePage = (event, value) => {
    setPage(value);
  };

  const getRole = () => {
    const role = userDetails?.customer?.role;
    if (role === "Company Admin") {
      return role;
    } else if (
      selectedJob?.hiringLeadArray?.includes(userDetails?.customer?.employeeId)
    ) {
      return "Hiring Lead";
    } else {
      return role;
    }
  };

  const getShortlistedCandidatesList = async (tagArr=selectedTagArr) => {
    let body = {
      role: getRole(),
      employeeId: userDetails?.customer?.employeeId,
      companyId: userDetails?.customer?.companyId,
      jobId: selectedJob?.jobId,
      status,
      page,
      limit: 20,
      tagArray: tagArr,
      columnName:sortByColoumn,
      sort: sortByASC ? "ASC" : "DESC",
    };
    dispatch(customershortlistedCandidates(body));
  };

  const getSortByData = (columnName) => {
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
  };

  const { data, loading } = useSelector((globalState) => {
    return globalState.customerShortlistedcandidatesList;
  });

  const ApproveRejectCandidate = (feedBackData) => {
    setOpenConfirmationModal(false);
    const obj = {
      interviewId: candidateData?.interviewId,
      companyId: candidateData?.companyId,
      jobId: candidateData?.jobId,
      recruiterId: candidateData?.recruiterId,
      candidateId: candidateData?.candidateId,
      employeeId: userDetails?.customer?.employeeId,
      screeningId: candidateData?.screeningId,
      role: getApproveOrRejectRole(selectedJob),
      jmtFeedback: [
        {
          employeeId: userDetails?.customer?.employeeId,
          status: candidateStatus,
          firstName: userDetails?.customer?.firstName,
          lastName: userDetails?.customer?.lastName,
          feedBackData: feedBackData ?? [],
        },
      ],
    };
    dispatch(approveRejectInterviewCandidate(obj));
  };

  const ApproveCandidateResponse = useSelector((globalState) => {
    return globalState.approveRejectInterviewCandidate;
  });

  const closeModal = () => {
    dispatch(closeRtkModal());
    setOpenCandidateFeedbackScreen(false);
    setRefreshList(true);
  };

  const allFilterTag = useSelector((globalState) => {
    return globalState?.getAllTagsListRes;
  });

  useEffect(() => {
    dispatch(
      getAllTagsList({
        companyId: userDetails?.customer?.companyId,
        tagName: "",
        page: "1",
        limit: "20",
        from: "addTagFetch",
        status: "activate"
      })
    );
  }, []);

  const handleTagFilterChange = (selectedOption) => {
    setSelectedTagArr(selectedOption);
    getShortlistedCandidatesList(selectedOption);
  }

  const handleTagFilterClose = () => {
    setSelectedTagArr([]);
    getShortlistedCandidatesList([]);
  }

  return (
    <>
      {OpenCandidateFeedbackScreen ? (
        <ShortlistedCandidatesFeedback
          setOpenCandidateFeedbackScreen={setOpenCandidateFeedbackScreen}
          ApproveRejectCandidate={ApproveRejectCandidate}
          candidateStatus={candidateStatus}
          ApproveCandidateResponse={ApproveCandidateResponse}
        />
      ) : OpenFeedbackScreen ? (
        <ApprovedCandidatesFeedback
          setOpenFeedbackScreen={setOpenFeedbackScreen}
          jobData={candidateFeedbackData}
          status={"Approved"}
          selectedJob={selectedJob}
        />
      ) : (
        <ShortlistedCandidatesListing
          setOpenConfirmationModal={setOpenConfirmationModal}
          shortlistedCandidateList={data}
          setOpenCandidateFeedbackScreen={setOpenCandidateFeedbackScreen}
          page={page}
          changePage={changePage}
          loading={loading}
          setCandidateStatus={setCandidateStatus}
          setCandidateData={setCandidateData}
          setOpenFeedbackScreen={setOpenFeedbackScreen}
          setCandidateFeedbackData={setCandidateFeedbackData}
          selectedJobData={selectedJob}
          changeCandidateViewType={changeCandidateViewType}
          getSortByData={(columnName)=>getSortByData(columnName)}
          sortByASC={sortByASC}
          sortByColoumn={sortByColoumn}
          allFilterTag={allFilterTag}
          handleTagFilterChange={handleTagFilterChange}
          handleTagFilterClose={handleTagFilterClose}
        />
      )}
      {OpenConfirmationModal && (
        <ConfirmationModal
          closeModal={ApproveRejectCandidate}
          onSubmit={() => {
            setOpenCandidateFeedbackScreen(true);
            setOpenConfirmationModal(false);
          }}
          heading={"Feedback"}
          confirmationMessage={"Want to add a feedback?"}
          NoBtnText={"No, Approve"}
        />
      )}
      {ApproveCandidateResponse?.meta?.status && (
        <SuccessModal
          closeModal={closeModal}
          sucessfullMsg={
            candidateStatus === "Approved"
              ? ApproveCandidateResponse?.meta?.msg
              : "Candidate has been rejected successfully."
          }
          Heading={
            candidateStatus === "Approved"
              ? "Candidate Approved!"
              : "Candidate Rejected!"
          }
        />
      )}
    </>
  );
}

export default ShortlistedCandidates;
