import React from "react";

function CheckIconWhite() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path
        fill="white"
        d="M9.293 17.293l-4.95-4.95a1 1 0 0 1 1.414-1.414L9 14.586l8.243-8.243a1 1 0 0 1 1.414 1.414l-9 9a1 1 0 0 1-1.414 0z"
      />
    </svg>
  );
}

export default CheckIconWhite;
