import { createSlice } from "@reduxjs/toolkit";
import addCompanyOnSocialSignIn from "@/rtk-apis/addCompanyOnSocialSignIn";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const addCompanyOnSocialSignInSlice = createSlice({
  name: "addCompanyOnSocialSignInSlice",
  initialState,
  reducers: {
    closeSocialCustomerSignInModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCompanyOnSocialSignIn.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addCompanyOnSocialSignIn.fulfilled, (state, { payload }) => {
        state.data = payload?.data;
        state.token = payload.data?.session_token;
        state.meta = payload?.meta;
        if (Object.keys(payload?.data).length > 0) {
          const localData = {
            token: payload?.data?.session_token,
            recruiter: payload?.data?.recruiterDetails,
            sessionid: payload?.data?.session?.id,
            Oryid: payload?.data?.session?.identity?.id,
            usertype: payload?.data?.recruiterDetails
              ? "recruiter"
              : payload?.data?.candidateDetails
              ? "candidate"
              : "customer",
            customer: payload?.data?.employeeDetails,
            candidate: payload?.data?.candidateDetails,
          };
          localStorage.setItem("token", JSON.stringify(localData));
        }
        state.loading = false;
      })
      .addCase(addCompanyOnSocialSignIn.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.meta?.msg;
      });
  },
});

export default addCompanyOnSocialSignInSlice.reducer;

export const {
  closeSocialCustomerSignInModal,
} = addCompanyOnSocialSignInSlice.actions;
