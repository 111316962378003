import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { RECRUITER_DETAILS } from '@/rtk-apis/end-points';

const recruiterDetails = createAsyncThunk('recruiterDetails', async (recruiterId) => {
  try {
    const response = await httpService.get(`${RECRUITER_DETAILS}/${recruiterId}`, {});
    return response;
  } catch (error) {
    return error;
  }
});

export default recruiterDetails;
