import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CUSTOMER_LOGO_UPLOAD } from '@/rtk-apis/end-points';

const uploadCustomerLogo = createAsyncThunk(
  'uploadCustomerLogo',
  async (body) => {
    try {
      const response = await httpService.post(CUSTOMER_LOGO_UPLOAD, body);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default uploadCustomerLogo;
