import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Approve from "@/assets/svgs/Approve";
import Reject from "@/assets/svgs/Reject";
import interviewList from "@/rtk-apis/customer/myJobs/interview/interviewList";
import {
  GetUserDetails,
  firstLetterCapital,
  getApproveOrRejectRole,
} from "@/utils/helper";
import CustomPagination from "@/elements/pagination/customPagination";
import GoTOPage from "@/elements/gotopage/gotopage";
import MeetingStatus from "@/components/HOCs/MeetingStatus";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import { closeRtkModal } from "@/store/slices/customer/myJobs/interview/approveRejectInterviewCandidateSlice";
import { closeRtkModal as closeRtkModalInterview } from "@/store/slices/customer/myJobs/interview/interviewDetailPageDataSlice";
import approveRejectInterviewCandidate from "@/rtk-apis/customer/myJobs/interview/approveRejectinterviewCandidate";
import interviewDetailPageData from "@/rtk-apis/customer/myJobs/interview/interviewDetailPageData";
import Loader from "@/elements/Loader";
import SortColumn from "@/elements/SortColumn";

function InterviewsListing({ changeInterviewViewType, jobData }) {
  const dispatch = useDispatch();
  const user = GetUserDetails();
  const [page, setPage] = useState(1);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [candidateData, setCandidateData] = useState({});
  const [candidateAction, setCandidateAction] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [sortByASC, setSortByASC] = useState(true);
  const [sortByColoumn, setSortByColoumn] = useState("");

  const { data, loading } = useSelector((globalState) => {
    return globalState.myJobInterviewList;
  });

  const { data: approveData, meta: approveMeta } = useSelector(
    (globalState) => {
      return globalState.approveRejectInterviewCandidate;
    }
  );

  const interviewRecordingData = useSelector((globalState) => {
    return globalState.interviewDetailPageResponse;
  });

  useEffect(() => {
    let listObj = {
      limit: 20,
      page: page,
      companyId: jobData?.companyId,
      columnName: sortByColoumn,
      sort: sortByASC ? "ASC" : "DESC",
    };
    listObj.role = "Employee";
    listObj.companyId = user?.customer?.companyId;
    listObj.employeeId = user?.customer?.employeeId;
    listObj.jobId = jobData?.jobId;
    getInterviewListAPIData(listObj);
  }, [page, jobData?.jobId, sortByColoumn, sortByASC]);

  const getSortByData = (columnName) => {
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
  };

  useEffect(() => {
    if (interviewRecordingData?.meta?.status) {
      if (candidateAction == "Approve") {
        if (interviewRecordingData?.data?.recordings?.length) {
          const customer = interviewRecordingData?.data?.recordings?.filter(
            (item) => item?.addedFrom === "Customer"
          );
          if (customer?.length) {
            setOpenFeedbackModal(true);
          } else {
            setShowErrorModal(true);
          }
        } else {
          setShowErrorModal(true);
        }
      } else if (candidateAction == "Reject") {
        rejectCandidate(candidateData);
      }
    }
  }, [interviewRecordingData?.data]);

  const getInterviewListAPIData = (obj) => {
    dispatch(interviewList(obj));
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  const approveCandidate = () => {
    const obj = {
      interviewId: candidateData?.interviewId,
      companyId: candidateData?.companyId,
      jobId: candidateData?.jobId,
      recruiterId: candidateData?.recruiterId,
      candidateId: candidateData?.candidateId,
      screeningId: candidateData?.screeningId,
      employeeId: user?.customer?.employeeId,
      jmtFeedback: [
        {
          employeeId: user?.customer?.employeeId,
          firstName: user?.customer?.firstName,
          lastName: user?.customer?.lastName,
          status: "Approved",
          feedBackData: [],
        },
      ],
    };
    dispatch(approveRejectInterviewCandidate(obj));
    let listObj = { limit: 20, page: page };
    if (user?.customer?.role == "Company Admin") {
      listObj.role = "Company Admin";
    }
    getInterviewListAPIData(listObj);
    dispatch(closeRtkModalInterview());
    setOpenFeedbackModal(false);
  };

  const closeModal = () => {
    dispatch(closeRtkModal());
  };

  const approveWithFeedbackCandidate = () => {
    dispatch(closeRtkModalInterview());
    const approvedObj = { ...candidateData, action: "Approved" };
    changeInterviewViewType("interviewFeedback", approvedObj);
  };

  const rejectCandidate = (candidateData) => {
    const rejectObj = { ...candidateData, action: "Rejected" };
    changeInterviewViewType("interviewFeedback", rejectObj);
  };

  const checkActionPerformed = (actionPerformed) => {
    let employeeId = user?.customer?.employeeId;
    return actionPerformed.find((employee) => employee === employeeId)
      ? false
      : true;
  };

  const checkRecordingExist = (interviewData) => {
    let obj = {
      interviewId: interviewData?.interviewId,
      screeningId: interviewData?.screeningId,
    };
    dispatch(interviewDetailPageData(obj));
  };

  return (
    <>
      <TableContainer
        component={Paper}
        className="tableContainer"
        style={{ height: "calc(100dvh - 182px)" }}
      >
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>S.</TableCell>
              <TableCell onClick={() => getSortByData("firstName")}>
                <SortColumn thName={"Name"} sortByASC={sortByASC} />
              </TableCell>
              <TableCell onClick={() => getSortByData("email")}>
                <SortColumn thName={"Email"} sortByASC={sortByASC} />
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.rows?.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell>
                  <div className="profile">
                    <div className="profile__initials">
                      {row?.candidate?.firstName?.charAt(0)?.toUpperCase()}
                    </div>
                    <span className="profile__name">
                      {firstLetterCapital(row?.candidate?.firstName) +
                        " " +
                        firstLetterCapital(row?.candidate?.lastName)}
                    </span>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="cellText">
                    <span className="cellText__dark">
                      {row?.candidate?.email}
                    </span>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="cellText">
                    <MeetingStatus time={row.meeting_end_time} />
                  </div>
                </TableCell>
                <TableCell>
                  <div className="cellText">
                    <Link
                      onClick={() => {
                        changeInterviewViewType("interviewDetail", row);
                      }}
                      className="cellText__dark cell--link"
                    >
                      View Details
                    </Link>
                  </div>
                </TableCell>
                <TableCell>
                  {checkActionPerformed(row?.actionPerformed) && (
                    <div className="actions flex--end">
                      <Tooltip title="Approve" placement="top" arrow>
                        <button
                          className="btn btn__transparent border_0 approve"
                          onClick={() => {
                            setCandidateAction("Approve");
                            setCandidateData(row);
                            checkRecordingExist(row);
                          }}
                        >
                          <Approve />
                        </button>
                      </Tooltip>

                      <Tooltip title="Reject" placement="top" arrow>
                        <button
                          className="btn btn__transparent border_0 reject"
                          onClick={() => {
                            setCandidateAction("Reject");
                            setCandidateData(row);
                            checkRecordingExist(row);
                          }}
                        >
                          <Reject />
                        </button>
                      </Tooltip>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!data?.rows?.length && (
          <div className="no-data">No Results Found!</div>
        )}
      </TableContainer>
      <div className="pagination">
        <div className="pagination__result">
          {`${data?.count > page * 20 ? 20 : data?.count} of ${
            data?.count
          } results`}
        </div>
        {data?.count ? (
          <CustomPagination
            totalPageCount={Math.ceil(data?.count / data?.count)}
            changePage={(event, value) => changePage(event, value)}
          />
        ) : (
          ""
        )}
        {data?.count ? (
          <div className="pagination__actions">
            <GoTOPage changePage={changePage} />
          </div>
        ) : (
          ""
        )}
      </div>
      {(loading || interviewRecordingData?.loading) && <Loader />}
      {openFeedbackModal && (
        <ConfirmationModal
          closeModal={approveCandidate}
          onSubmit={approveWithFeedbackCandidate}
          heading={"Feedback"}
          confirmationMessage={"Want to add a feedback?"}
          NoBtnText={"No, Approve"}
          showCloseBtn={true}
          closeBtnAction={() => {
            dispatch(closeRtkModalInterview());
            setOpenFeedbackModal(false);
          }}
        />
      )}
      {approveMeta.status && (
        <SuccessModal
          closeModal={closeModal}
          sucessfullMsg={"Candidate has been approved successfully."}
          Heading={"Candidate Approved!"}
        />
      )}
      {showErrorModal && (
        <ErrorModal
          errorMessage={"Interview recording not available."}
          footerVisible={true}
          closeModal={() => {
            dispatch(closeRtkModalInterview());
            setShowErrorModal(false);
          }}
        />
      )}
    </>
  );
}

export default InterviewsListing;
