import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { GET_CARD_TYPE } from '@/rtk-apis/end-points';

const getCardType = createAsyncThunk('getCardType', async (body) => {
  try {
    const response = await httpService.post(GET_CARD_TYPE, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default getCardType;
