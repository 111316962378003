import React from "react";
import ProfileIcon from "@/assets/svgs/ProfileIcon";
import EmailIcon from "@/assets/svgs/EmailIcon";
import PhoneIcon from "@/assets/svgs/PhoneIcon";
import { Formik, Form, Field } from "formik";
import { myProfileSchema } from "@/components/schema/customer-schema";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDown from "@/assets/svgs/ArrowDown";
import MenuItem from "@mui/material/MenuItem";
import { formatPhoneNumber, GetUserDetails } from "@/utils/helper";
import updateMyProfileDetails from "@/rtk-apis/customer/myProfile/updateMyProfile";
import { closeRtkModal } from "@/store/slices/customer/myProfile/updateMyProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "@/elements/Loader";
import { SuccessModal } from "@/components/modals/SuccessModal";

function EditProfile({ userDetails, setEditMode }) {
  const dispatch = useDispatch();
  const loggedInUser = GetUserDetails();
  const { loading, meta } = useSelector((state) => {
    return state.updateMyProfile;
  });

  let initialValues = {
    firstName: userDetails?.firstName ? userDetails?.firstName : "",
    lastName: userDetails?.lastName ? userDetails?.lastName : "",
    email: userDetails?.email ? userDetails?.email : "",
    phoneNumber: userDetails?.phoneNumber ? userDetails?.phoneNumber : "",
    role: userDetails?.role ? userDetails?.role : "",
    countryCode: "+1",
  };

  const handleSubmit = async (values) => {
    let body = {
      employeeId: loggedInUser?.customer?.employeeId,
      firstName: values?.firstName,
      lastName: values?.lastName,
      phoneNumber: values?.phoneNumber,
      countryCode: values?.countryCode,
    };
    dispatch(updateMyProfileDetails(body));
  };

  const closeModal = () => {
    dispatch(closeRtkModal());
    setEditMode(false);
  };
  return (
    <>
      <div className="layoutGrid layoutGrid--full">
        <div className="layoutGrid__col">
          <div className="dataCol">
            <div className="page page__full">
              <Formik
                initialValues={initialValues}
                validationSchema={myProfileSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ errors, values, handleChange, handleBlur, touched }) => (
                  <Form className="form p_0">
                    <div className="page__head">
                      <h2 className="page__head--title">Edit Profile</h2>
                      <div className="page__head--actions">
                        <button
                          className="btn btn__white"
                          type="button"
                          onClick={() => setEditMode(false)}
                        >
                          Cancel
                        </button>
                        <button className="btn btn__primary" type="submit">
                          Update
                        </button>
                      </div>
                    </div>

                    <div className="page__body">
                      <div className="form">
                        <section className="form__section">
                          <h4 className="form__section--title">
                            Basic Details
                          </h4>
                          <div className="formRow formRow__2">
                            <div
                              className={`formField ${
                                errors?.firstName && touched?.firstName
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <div className="formField">
                                <label className="formField__label">
                                  First Name
                                </label>
                                <div className="formField__group sm">
                                  <Field
                                    type="text"
                                    className="formField__input sm"
                                    placeholder="First Name"
                                    name="firstName"
                                    onBlur={handleBlur}
                                  />
                                  <span className="formField__icon">
                                    <ProfileIcon />
                                  </span>
                                </div>
                                <span className="formField__error">
                                  {errors?.firstName &&
                                    touched?.firstName &&
                                    errors?.firstName}
                                </span>
                              </div>
                            </div>

                            <div className="formCol">
                              <div
                                className={`formField ${
                                  errors?.lastName && touched?.lastName
                                    ? "fieldError"
                                    : ""
                                }`}
                              >
                                <label className="formField__label">
                                  Last Name
                                </label>
                                <div className="formField__group sm">
                                  <Field
                                    type="text"
                                    className="formField__input sm"
                                    placeholder="Last Name"
                                    name="lastName"
                                    onBlur={handleBlur}
                                  />
                                  <span className="formField__icon">
                                    <ProfileIcon />
                                  </span>
                                </div>
                                <span className="formField__error">
                                  {errors?.lastName &&
                                    touched?.lastName &&
                                    errors?.lastName}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="formRow formRow__2">
                            <div className="formCol">
                              <div className="formField">
                                <label className="formField__label">
                                  Email Id
                                </label>
                                <div className="formField__group sm">
                                  <Field
                                    type="text"
                                    className="formField__input sm formField__disabled"
                                    placeholder="Email Id"
                                    name="email"
                                    disabled={true}
                                    onBlur={handleBlur}
                                  />
                                  <span className="formField__icon">
                                    <EmailIcon />
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="formCol">
                              <div
                                className={`formField ${
                                  errors?.phoneNumber && touched?.phoneNumber
                                    ? "fieldError"
                                    : ""
                                }`}
                              >
                                <label className="formField__label">
                                  Phone Number
                                </label>
                                <div className="formField__group formField__phoneNumber sm">
                                  <FormControl
                                    fullWidth
                                    size="small"
                                    className="formField__select"
                                  >
                                    <Select
                                      onChange={(e) => handleChange(e)}
                                      IconComponent={() => <ArrowDown />}
                                      name="countryCode"
                                      value={values?.countryCode ?? null}
                                      displayEmpty
                                    >
                                      <MenuItem value={"+1"}>{"+1"}</MenuItem>
                                    </Select>
                                  </FormControl>
                                  <Field
                                    type="text"
                                    className="formField__input sm"
                                    placeholder="Phone Number"
                                    maxLength="10"
                                    name="phoneNumber"
                                    onBlur={handleBlur}
                                    value={formatPhoneNumber(
                                      values?.phoneNumber
                                    )}
                                  />
                                  <span className="formField__icon">
                                    <PhoneIcon />
                                  </span>
                                </div>
                                <span className="formField__error">
                                  {errors?.phoneNumber &&
                                    touched?.phoneNumber &&
                                    errors?.phoneNumber}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="formRow formRow__2">
                            <div className="formCol">
                              <div className="formField">
                                <label className="formField__label">Role</label>
                                <div className="formField__group sm">
                                  <Field
                                    type="text"
                                    className="formField__input sm formField__disabled"
                                    placeholder="Role"
                                    name="role"
                                    disabled={true}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {meta?.status && (
        <SuccessModal
          closeModal={closeModal}
          sucessfullMsg={meta?.msg}
          Heading={"Profile Updated!"}
        />
      )}
      {loading && <Loader />}
    </>
  );
}

export default EditProfile;
