import React from "react";

const ArrowPaymentSelectDown = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66663 4.66699L11.3333 11.3337M11.3333 11.3337V4.66699M11.3333 11.3337H4.66663"
        stroke="#384250"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ArrowPaymentSelectDown;
