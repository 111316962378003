import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CUSTOMER_CANDIDATE_LIST } from '@/rtk-apis/end-points';

const candidateList = createAsyncThunk('candidateList', async (body) => {
  try {
    const response = await httpService.post(CUSTOMER_CANDIDATE_LIST, body);
    return response?.data;
  } catch (error) {
    return error;
  }
});

export default candidateList;
