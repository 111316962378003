import React from "react";

function LinkIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83331 14.1673L14.1666 5.83398M14.1666 5.83398H5.83331M14.1666 5.83398V14.1673"
        stroke="#28A0E8"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LinkIcon;
