import React from "react";
import { Link } from "react-router-dom";
import PlayIcon from "@/assets/svgs/PlayIcon";
import LinkIcon from "@/assets/svgs/LinkIcon";
import {
  GetUserDetails,
  formatPhoneNumber,
  firstLetterCapital,
  saveInterviewDetails,
} from "@/utils/helper";
import moment from "moment";

function RecentInterviews({
  selectedInterview,
  hideDetail,
  openRecordingPage,
}) {
  const navigateToRescheduleUrl = () => {
    saveInterviewDetails({
      companyId: selectedInterview?.companyId,
      jobId: selectedInterview?.jobId,
      candidateId: selectedInterview?.candidateId,
      recruiterId: selectedInterview?.recruiterId,
      employeeId: null,
      adminId: null,
    });
    window.open(
      `https://schedule.nylas.com/${selectedInterview?.meetingInfo?.page_slug}/reschedule/${selectedInterview?.meetingInfo?.edit_hash}?`,
      "_blank"
    );
  };

  const isUpcoming = (time) => {
    const today = new Date();
    return today > new Date(time);
  };

  return (
    <>
      <div className="dataCol">
        <div className="page page__full">
          <div className="page__body bg--white">
            <div className="dataCol__head head--inner head--responsive">
              <div className="item">
                <div className="initials initials__lg">
                  {firstLetterCapital(
                    selectedInterview?.candidate?.firstName[0]
                  )}
                </div>
                <div className="item__info">
                  <h4 className="item__info--title">
                    {firstLetterCapital(
                      selectedInterview?.candidate?.firstName
                    ) +
                      " " +
                      firstLetterCapital(
                        selectedInterview?.candidate?.lastName
                      )}
                  </h4>
                  <div className="details__row">
                    <div className="details__row--item">
                      <span>Phone:</span>
                      {formatPhoneNumber(
                        selectedInterview?.candidate?.phoneNumber
                      )}
                    </div>
                    <div className="details__row--item">
                      <span>Email:</span>
                      {selectedInterview?.candidate?.email}
                    </div>
                  </div>
                </div>
              </div>

              <div className="dataCol__head--actions">
                <button
                  onClick={() => hideDetail()}
                  className="btn btn__white w_fitContent"
                >
                  Back
                </button>
                {selectedInterview?.recordings?.length <= 0 &&  !isUpcoming(selectedInterview?.meeting_end_time) ? (
                  <>
                    <button
                      onClick={navigateToRescheduleUrl}
                      className="btn btn__white w_fitContent"
                    >
                      Reschedule
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={openRecordingPage}
                      className="btn btn__white w_fitContent"
                    >
                      View Rec.
                      <PlayIcon />
                    </button>
                  </>
                )}
              </div>
            </div>

            <div className="details">
              <h4 className="details__section--title">Interview Details</h4>
              <div className="fieldBox flexRow">
                <div className="flexCol">
                  <label className="flexCol__label">Interview Title</label>
                  <div className="flexCol__text">
                    {selectedInterview?.title}
                  </div>
                </div>
                <div className="flexCol">
                  <label className="flexCol__label">Interview Date</label>
                  <div className="flexCol__text">
                    {moment(selectedInterview?.meeting_start_time).format(
                      "D MMM , YYYY"
                    )}
                  </div>
                </div>
              </div>
              <div className="fieldBox flexRow">
                <div className="flexCol">
                  <label className="flexCol__label">Interview Time</label>
                  <div className="flexCol__text">
                    {moment(selectedInterview?.meeting_start_time).format(
                      "hh:mm A"
                    ) +
                      " - " +
                      moment(selectedInterview?.meeting_end_time).format(
                        "hh:mm A"
                      )}
                  </div>
                </div>
                <div className="flexCol">
                  <label className="flexCol__label">Interview URL</label>
                  <Link
                    onCopy={(e) => {
                      if (isUpcoming(selectedInterview?.meeting_end_time)) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    target={"_blank"}
                    to={selectedInterview?.meetingInfo?.location}
                    className={`flexCol__text text--primary  ${
                      isUpcoming(selectedInterview?.meeting_end_time)
                        ? "formField__disabled"
                        : ""
                    }`}
                  >
                    {selectedInterview?.meetingInfo?.location}
                    <LinkIcon />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecentInterviews;
