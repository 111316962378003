import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { UPDATE_REVIEW_RATINGS_FOR_RECRUITER_BY_CUSTOMER } from "@/rtk-apis/end-points";

const updateReviewsRatings = createAsyncThunk(
  "updateReviewsRatings",
  async (body) => {
    try {
      const response = await httpService.put(
        UPDATE_REVIEW_RATINGS_FOR_RECRUITER_BY_CUSTOMER,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default updateReviewsRatings;
