import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import socialSignInStepTwo from "@/rtk-apis/socialSignInStepTwo";
import Loader from "@/elements/Loader";
import { closesocialSignInStepTwoModal } from "@/store/slices/socialSignInStepTwoSlice";
import ErrorModal from "@/components/modals/error-modal";
import { firstLetterCapital } from "@/utils/helper";
import { SuccessModal } from "@/components/modals/SuccessModal";

const SocialSignIn = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [OpenSuccessModal, setOpenSuccessModal] = useState(false);

  const signInData = JSON.parse(localStorage.getItem("socialSignInData"));

  const socialSignInStepTwoResponse = useSelector((globalState) => {
    return globalState.socialSignInStepTwoRes;
  });

  useEffect(() => {
    if (socialSignInStepTwoResponse?.meta?.status) {
      if (signInData?.role === "customer") {
        if (
          socialSignInStepTwoResponse?.data?.registrationComplete ===
          "incomplete"
        ) {
          const signInDetails = {
            ...signInData,
            registrationProcess:
              socialSignInStepTwoResponse?.data?.registrationComplete,
            employeeId: socialSignInStepTwoResponse?.data?.employeeId,
            id_token: socialSignInStepTwoResponse?.data?.id_token,
            sub: socialSignInStepTwoResponse?.data?.sub,
          };
          navigate("/signup", {
            state: {
              isStepTwo: true,
              signUpAs: "customer",
              signInData: signInDetails,
            },
          });
        } else {
          setUserInChatwoot();
          navigate("/customer-dashboard");
        }
      } else if (signInData?.role === "candidate") {
        setOpenSuccessModal(true);
        // navigate("/candidate-dashboard");
      } else {
        navigate("/dashboard");
      }
    }
  }, [socialSignInStepTwoResponse?.meta?.status]);

  const setUserInChatwoot = () => {
    if (window.$chatwoot && typeof window.$chatwoot.reset === "function") {
      let obj = {
        id:
          user?.usertype === "recruiter"
            ? user?.recruiter?.recruiterId
            : user?.customer?.employeeId,
        name:
          user?.usertype === "recruiter"
            ? `${user?.recruiter?.firstName} ${user?.recruiter?.lastName}`
            : `${user?.customer?.firstName} ${user?.customer?.lastName}`,
        email:
          user?.usertype === "recruiter"
            ? `${user?.recruiter?.email}`
            : user?.customer?.email,
        phone_number:
          user?.usertype === "recruiter"
            ? `${user?.recruiter?.countryCode}${user?.recruiter?.phoneNumber}`
            : `${user?.customer?.countryCode}${user?.customer?.phoneNumber}`,
        company_name:
          user?.usertype === "recruiter"
            ? null
            : user?.customer?.company?.companyName,
        account_role: user?.usertype,
      };
      window.$chatwoot.setUser(obj.id, {
        name: obj.name,
        email: obj.email,
        phone_number: obj.phone_number,
        company_name: obj.company_name,
      });
      window.$chatwoot.setCustomAttributes({
        account_role: obj.account_role,
      });
    }
  };

  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      const state = params.get("state");
      const code = params.get("code");

      if (state && code) {
        let body = {
          code: code,
          state: state,
          csrf_token: signInData?.csrf_token,
          flowId: signInData?.flowId,
          provider: signInData?.provider,
          role: signInData?.role,
        };
        dispatch(socialSignInStepTwo(body));
      }
    }
  }, [location]);

  const closeErrorModal = () => {
    dispatch(closesocialSignInStepTwoModal());
    navigate("/signin");
  };

  const closeSuccessModal = () => {
    dispatch(closesocialSignInStepTwoModal());
    navigate("/candidate-dashboard");
  };

  return (
    <>
      {" "}
      {socialSignInStepTwoResponse?.meta?.status === false && (
        <ErrorModal
          errorMessage={socialSignInStepTwoResponse?.meta?.msg}
          closeModal={closeErrorModal}
          footerVisible={true}
        />
      )}
      {OpenSuccessModal && (
        <SuccessModal
          closeModal={closeSuccessModal}
          sucessfullMsg={socialSignInStepTwoResponse?.meta?.msg}
          Heading={`SignUp successfully!`}
        />
      )}
      {socialSignInStepTwoResponse?.loading && <Loader />}
    </>
  );
};

export default SocialSignIn;
