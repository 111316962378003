import { createSlice } from "@reduxjs/toolkit";
import deleteNotifications from "@/rtk-apis/customer/notifications/deleteNotifications";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const deleteNotificationsSlice = createSlice({
  name: "deleteNotificationsSlice",
  initialState,
  reducers: {
    closeDeleteNotificationsModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteNotifications.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteNotifications.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(deleteNotifications.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default deleteNotificationsSlice.reducer;

export const {
  closeDeleteNotificationsModal,
} = deleteNotificationsSlice.actions;
