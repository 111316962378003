import { createSlice } from '@reduxjs/toolkit';
import changePassword from '@/rtk-apis/changePassword';

const initialState = {
  data: {},
  meta: {},
  loading: false,
  error: ''
};

const changePasswordSlice = createSlice({
  name: 'ChangePassword',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state, { payload }) => {
        state.meta = payload?.meta;
        state.loading = false;
      })
      .addCase(changePassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.meta?.msg;
      });
  }

});

export default changePasswordSlice.reducer;

export const { closeRtkModal } = changePasswordSlice.actions;
