import * as Yup from "yup";
import * as CONSTANTS from "@/utils/constants";

export const candidateSignUpJobDetailSchema = Yup.object().shape({
  jobTitle: Yup.string()
    .min(1, CONSTANTS.JOBTITLE.validation.valid)
    .required(CONSTANTS.JOBTITLE.validation.valid),
  company: Yup.string()
    .min(1, CONSTANTS.COMPANYNAME.validation.valid)
    .required(CONSTANTS.COMPANYNAME.validation.valid),
  startMonth: Yup.string().required("Start month is required."),
  startYear: Yup.string().required("Start year is required."),
  // endMonth: Yup.string().required("End month is required."),
  endYear: Yup.string()
    // .required("End year is required.")
    .test(
      "valid-end-year",
      "End year should not be less than start year.",
      function(value, { parent }) {
        if (!value) return true; // Skip validation if endYear is not provided
        const startYear = parseInt(parent.startYear, 10);
        const endYear = parseInt(value, 10);
        return !isNaN(startYear) && !isNaN(endYear) && endYear >= startYear;
      }
    )
    .test(
      "valid-end-month",
      "The start date cannot be later than the end date.",
      function(value, { parent }) {
        const startYear = parseInt(parent.startYear, 10);
        const endYear = parseInt(value, 10);
        if (startYear === endYear) {
          const startMonth = parent.startMonth;
          const endMonth = parent.endMonth;
          const month = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          return month.indexOf(endMonth) >= month.indexOf(startMonth);
        } else {
          return true;
        }
      }
    ),
});

export const candidateSignUpProfileDetailSchema = Yup.object().shape({
  institution: Yup.string()
    .min(1, CONSTANTS.INSTITUTION.validation.valid)
    .required(CONSTANTS.INSTITUTION.validation.valid),
  degree: Yup.string()
    .min(1, CONSTANTS.DEGREE.validation.valid)
    .required(CONSTANTS.DEGREE.validation.valid),
  majorFieldOfStudy: Yup.string()
    .min(1, CONSTANTS.MAJOR_FIELD_OF_STUDY.validation.valid)
    .required(CONSTANTS.MAJOR_FIELD_OF_STUDY.validation.valid),
  startMonth: Yup.string().required("Start month is required."),
  startYear: Yup.string().required("Start year is required."),
  // endMonth: Yup.string().required("End month is required."),
  endYear: Yup.string()
    // .required("End year is required.")
    .test(
      "valid-end-year",
      "End year should not be less than start year.",
      function(value, { parent }) {
        if (!value) return true;
        const startYear = parseInt(parent.startYear, 10);
        const endYear = parseInt(value, 10);
        return !isNaN(startYear) && !isNaN(endYear) && endYear >= startYear;
      }
    )
    .test(
      "valid-end-month",
      "The start date cannot be later than the end date.",
      function(value, { parent }) {
        const startYear = parseInt(parent.startYear, 10);
        const endYear = parseInt(value, 10);
        if (startYear === endYear) {
          const startMonth = parent.startMonth;
          const endMonth = parent.endMonth;
          const month = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          return month.indexOf(endMonth) >= month.indexOf(startMonth);
        } else {
          return true;
        }
      }
    ),
});
