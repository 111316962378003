import React, { useState } from "react";
import EditIcon from "@/assets/svgs/EditIcon";

const TaxResidenceView = ({
  changeTaxResidentView,
  taxInfoStatus,
  taxDetails = {},
  citizenType
}) => {
  return (
    <>
      <section className="details__section">
        <h4 className="details__section--title">
          {taxInfoStatus ? (
            <span className="titleWithMessage">
              Tax Residence <span className="successMessage">Completed</span>
            </span>
          ) : (
            <span className="titleWithMessage">
              Tax Residence <span className="errorMessage">Not Completed</span>
            </span>
          )}
          <button
            type="button"
            className="btn btn__white btn--sm"
            onClick={() => changeTaxResidentView(true)}
          >
            <EditIcon />
          </button>
        </h4>
        <p>
          Your tax residence information is part of the Laila W-9 or W-8 form process.
        </p>
        <div className="typerinfolist">
          {Object.keys(taxDetails).length ? (
            <div className="typerinfolist__item">
              <h5>Address</h5>
              <p>
                {taxDetails?.addressLine1}
                <br></br>
                {taxDetails?.city + ", " + taxDetails?.stateName}
                {taxDetails?.zipCode ? <br></br> : ""}
                {taxDetails?.zipCode}
                <br></br>
                {taxDetails?.countryName}
                <br></br>
              </p>
            </div>
          ) : ""}
        </div>
      </section>
    </>
  );
};

export default TaxResidenceView;
