import { createSlice } from "@reduxjs/toolkit";
import activateDeactivateCustomerTags from "@/rtk-apis/customer/settings/customerTags/activateDeactivateCustomerTags";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const activateDeactivateCustomerTagsSlice = createSlice({
  name: "activateDeactivateCustomerTagsSlice",
  initialState,
  reducers: {
    closeActivateDeactivateTagsModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(activateDeactivateCustomerTags.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        activateDeactivateCustomerTags.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.meta = payload?.meta;
          state.data = payload?.data;
        }
      )
      .addCase(
        activateDeactivateCustomerTags.rejected,
        (state, { payload }) => {
          state.data = payload?.data;
          state.loading = false;
        }
      );
  },
});

export default activateDeactivateCustomerTagsSlice.reducer;

export const {
  closeActivateDeactivateTagsModal,
} = activateDeactivateCustomerTagsSlice.actions;
