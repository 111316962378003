import React, { useState, useEffect } from "react";
import SideBar from "@/components/layout/SideBar";
import { ProfileDetailPage } from "@/customer-pages/Profile/ProfileDetailPage";
import EditProfile from "@/customer-pages/Profile/EditProfile";
import { GetUserDetails } from "@/utils/helper";
import { useDispatch, useSelector } from "react-redux";
import customerEmployeeDetail from "@/rtk-apis/customer/myProfile/customerEmployeeDetail";
import Loader from "@/elements/Loader";

const Profile = () => {
  const LoggedInUser = GetUserDetails();
  const dispatch = useDispatch();
  const [EditMode, setEditMode] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(false);

  const { data, loading } = useSelector((globalState) => {
    return globalState.employeeDetails;
  });

  const getEmployeeDetails = async () => {
    const employeeid = LoggedInUser?.customer?.employeeId;
    dispatch(customerEmployeeDetail(employeeid));
  };

  useEffect(() => {
    getEmployeeDetails();
  }, [EditMode, refreshDetails]);

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        {EditMode ? (
          <EditProfile userDetails={data} setEditMode={setEditMode} />
        ) : (
          <ProfileDetailPage
            userDetails={data}
            setEditMode={setEditMode}
            setRefreshDetails={setRefreshDetails}
            refreshDetails={refreshDetails}
          />
        )}
      </div>
      {loading && <Loader />}
    </>
  );
};

export default Profile;
