import { createSlice } from "@reduxjs/toolkit";
import getRecruiterDetails from "@/rtk-apis/customer/myJobs/getRecruiterDetails";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const getRecruiterDetailsSlice = createSlice({
  name: "getRecruiterDetailsSlice",
  initialState,
  reducers: {
    getRecruiterDetailsModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecruiterDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRecruiterDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(getRecruiterDetails.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default getRecruiterDetailsSlice.reducer;

export const { getRecruiterDetailsModal } = getRecruiterDetailsSlice.actions;
