import { createSlice } from '@reduxjs/toolkit';
import updateTaxResidenceInfo from '@/rtk-apis/tax/updateTaxResidenceInfo';

const initialState = {
  message: '',
  data: {},
  meta: {},
  loading: false,
  error: ''
};

const updateTaxResidenceInfoSlice = createSlice({
  name: 'updateTaxResidenceInfo',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateTaxResidenceInfo.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateTaxResidenceInfo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
      })
      .addCase(updateTaxResidenceInfo.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  }

});

export default updateTaxResidenceInfoSlice.reducer;

export const { closeRtkModal } = updateTaxResidenceInfoSlice.actions;
