import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIconGray from "@/assets/svgs/CloseIconGray";
import LightOnSvg from "@/assets/svgs/LightOnSvg";
import { Formik, Form, Field } from "formik";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDown from "@/assets/svgs/ArrowDown";
import CloseEye from "@/assets/svgs/CloseEye";
import OpenEye from "@/assets/svgs/OpenEye";
import MenuItem from "@mui/material/MenuItem";
import { GetUserDetails, decryptData, isButtonDisabled } from "@/utils/helper";
import { BankAccountDetailSchema } from "@/components/schema/schema";
import addBankDetails from "@/rtk-apis/payments/addBankDetails";
import updateBankDetails from "@/rtk-apis/payments/updateBankDetails";
import { BANK_ACCOUNT_TYPE } from "@/utils/constants";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import { closeRtkModal as addCloseRtkModal } from "@/store/slices/payments/addBankDetailsSlice";
import { closeRtkModal as updateCloseRtkModal } from "@/store/slices/payments/updateBankDetailsSlice";
import RoutingJson from "@/assets/rountingNoDetails/bank_routing_details";

const AddaBankAccountModal = ({
  openModal,
  closeModal,
  setOpenSetupWithdrawalScheduleModal,
  AchData = {},
  refreshPage,
  showSeccondStep,
}) => {
  const userDetails = GetUserDetails();
  const dispatch = useDispatch();
  const [eyeForPassword, seteyeForPassword] = useState(true);
  const [eyeForConfirmPassword, seteyeForConfirmPassword] = useState(true);
  const [bankName, setBankName] = useState("");
  const [initialValues, setInitialValues] = useState({
    accountHolderName: AchData?.accountHolderName
      ? AchData?.accountHolderName
      : "",
    accountType: AchData?.accountType ? AchData?.accountType : "",
    routingNumber: AchData?.routingNumber
      ? decryptData(AchData?.routingNumber)
      : "",
    accountNumber: AchData?.accountNumber
      ? decryptData(AchData?.accountNumber)
      : "",
    confirmAccountNumber: AchData?.confirmAccountNumber
      ? decryptData(AchData?.confirmAccountNumber)
      : "",
    bankName: AchData?.bankName
    ? AchData?.bankName
    : "",
  });

  const addBankDetailsData = useSelector((globalState) => {
    return globalState.addBankDetails;
  });

  const updateBankDetailsData = useSelector((globalState) => {
    return globalState.updateBankDetails;
  });

  useEffect(() => {
    if (Object.keys(AchData)?.length) {
      setInitialValues({
        accountHolderName: AchData?.accountHolderName
          ? AchData?.accountHolderName
          : "",
        accountType: AchData?.accountType ? AchData?.accountType : "",
        routingNumber: AchData?.routingNumber ? AchData?.routingNumber : "",
        accountNumber: AchData?.accountNumber ? AchData?.accountNumber : "",
        confirmAccountNumber: AchData?.confirmAccountNumber
          ? AchData?.confirmAccountNumber
          : "",
      });
    }
  }, [AchData]);

  const handleSubmit = (values) => {
    values.recruiterId = userDetails?.recruiter?.recruiterId;
    if (AchData?.id) {
      values.achDetailId = AchData?.id;
      dispatch(updateBankDetails(values));
    } else {
      dispatch(addBankDetails(values));
    }
    if (showSeccondStep) {
      setOpenSetupWithdrawalScheduleModal(values);
      dispatch(addCloseRtkModal());
    }
  };

  const closeErrorModal = () => {
    if (AchData?.id) {
      dispatch(updateCloseRtkModal());
    } else {
      dispatch(addCloseRtkModal());
    }
  };

  const closeSuccessModal = () => {
    if (AchData?.id) {
      dispatch(updateCloseRtkModal());
    } else {
      dispatch(addCloseRtkModal());
      refreshPage();
    }
    closeModal();
  };

  return (
    <>
      <div
        className={`modal modalPaymentWithdrawal ${
          openModal ? "modal--open" : ""
        }`}
      >
        <div className="modal__dialog">
          <Formik
            initialValues={initialValues}
            validationSchema={BankAccountDetailSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              touched,
              setFieldValue,
              setFieldTouched,
              setFieldError
            }) => (
              <Form>
                <div className="modal__content modal__content--xxxl">
                  <div className="modal__header">
                    <h2 className="modal__header--title">
                      {AchData?.id ? "Update" : "Add"} Bank Account
                      <span className="modal__header--title__steptext">
                        (Step 1 of 2)
                      </span>
                    </h2>
                    <button
                      className="btn btn__transparent btnClose"
                      onClick={() => closeModal(true)}
                    >
                      <CloseIconGray />
                    </button>
                  </div>
                  <div className="modal__body modal-body-left">
                    <div className="modal__body__topInfo">
                      <h4 className="title">
                        Get access to your funds in 3-5 business days with no
                        fees.
                      </h4>
                      <p>
                        Incorrect information can mean a delay in receiving
                        funds.
                      </p>
                    </div>
                    <div className="formRow formRow__2 w_100">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.accountHolderName &&
                            touched?.accountHolderName
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            Account holder name
                          </label>
                          <div className="formField__group sm">
                            <Field
                              value={values?.accountHolderName}
                              type="text"
                              className="formField__input sm"
                              placeholder=""
                              name="accountHolderName"
                            />
                          </div>
                          <span className="formField__error">
                            {errors?.accountHolderName &&
                              touched?.accountHolderName &&
                              errors?.accountHolderName}
                          </span>
                        </div>
                      </div>
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.accountType && touched?.accountType
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            Account type
                          </label>
                          <FormControl
                            sx={{ width: "100%" }}
                            size="small"
                            className="formField__select sm"
                          >
                            <Select
                              IconComponent={() => <ArrowDown />}
                              name="accountType"
                              displayEmpty
                              value={values?.accountType}
                              onChange={handleChange}
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ opacity: "0.8" }}>
                                      Select
                                    </span>
                                  );
                                }

                                return selected;
                              }}
                            >
                              {BANK_ACCOUNT_TYPE.map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <span className="formField__error">
                            {errors?.accountType &&
                              touched?.accountType &&
                              errors?.accountType}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="formRow w_100">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.routingNumber && touched?.routingNumber
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            Routing Number
                          </label>
                          <div className="formField__group sm">
                            <Field
                              type="text"
                              className="formField__input sm"
                              placeholder=""
                              name="routingNumber"
                              onChange={(e) => {
                                if (e?.target?.value?.length <= 9) {
                                  if (e?.target?.value?.length === 9) {
                                    const routingNo = e?.target?.value;
                                    if (RoutingJson[routingNo]) {
                                      setFieldValue("bankName", "true");
                                    } else {
                                      setFieldValue("bankName", "");
                                    }
                                    setBankName(RoutingJson[routingNo]);
                                  } else {
                                    setBankName("");
                                  }
                                  setFieldValue(
                                    "routingNumber",
                                    e?.target?.value
                                  );
                                }
                              }}
                            />
                          </div>
                          <span className="formField__error">
                            {errors?.routingNumber &&
                              touched?.routingNumber &&
                              errors?.routingNumber}
                          </span>
                          <span className="BankInfo">
                            {bankName ? `Bank Name : ${bankName}` : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                    {
                      values?.routingNumber && !bankName && !errors?.routingNumber ? <div className="formRow w_100">
                      <div className="formCol">
                        <div className="formField">
                          <label className="formField__label">
                            Bank Name
                          </label>
                          <div className="formField__group sm">
                            <Field
                              type="text"
                              className="formField__input sm"
                              placeholder="Enter bank name"
                              name="bankName"
                            />
                          </div>
                          <span className="formField__error">
                            {errors?.bankName &&
                              touched?.bankName &&
                              errors?.bankName}
                          </span>
                        </div>
                      </div>
                    </div> : <Field
                              type="hidden"
                              className="formField__input sm"
                              placeholder="Enter bank name"
                              name="bankName"
                            />
                    }
                    <div className="formRow formRow__2 w_100">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.accountNumber && touched?.accountNumber
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            Account Number
                          </label>
                          <div className="formField__group sm">
                            <Field
                              type={eyeForPassword ? "text" : "password"}
                              className="formField__input sm"
                              placeholder=""
                              name="accountNumber"
                            />
                            <div
                              onClick={() =>
                                seteyeForPassword(!eyeForPassword)
                              }
                              className="formField__icon"
                            >
                              <span>
                                {eyeForPassword ? (
                                  <OpenEye />
                                ) : (
                                  <CloseEye />
                                )}
                              </span>
                            </div>
                          </div>
                          <span className="formField__error">
                            {errors?.accountNumber &&
                              touched?.accountNumber &&
                              errors?.accountNumber}
                          </span>
                        </div>
                      </div>
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.confirmAccountNumber &&
                            touched?.confirmAccountNumber
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            Confirm Account Number
                          </label>
                          <div className="formField__group sm">
                            <Field
                              type={eyeForConfirmPassword ? "text" : "password"}
                              className="formField__input sm"
                              placeholder=""
                              name="confirmAccountNumber"
                            />
                            <div
                              onClick={() =>
                                seteyeForConfirmPassword(!eyeForConfirmPassword)
                              }
                              className="formField__icon"
                            >
                              <span>
                                {eyeForConfirmPassword ? (
                                  <OpenEye />
                                ) : (
                                  <CloseEye />
                                )}
                              </span>
                            </div>
                          </div>
                          <span className="formField__error">
                            {errors?.confirmAccountNumber &&
                              touched?.confirmAccountNumber &&
                              errors?.confirmAccountNumber}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="bottomInfobox">
                      <p>
                        By adding this bank account you are confirming that you
                        are the owner and have full authorization to this bank
                        account.
                      </p>
                      <h6 className="infoIcon">
                        <LightOnSvg /> This payment method will become active within 3 business days.
                      </h6>
                    </div>
                    <div className="btn__group">
                      <button
                        onClick={() => closeModal(true)}
                        className="btn btn__white"
                      >
                        Cancel
                      </button>
                      <button className="btn btn__primary" type="submit" disabled={isButtonDisabled(errors, values, initialValues)}>
                        {AchData?.id ? "Update" : "Add"} bank account
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      {(addBankDetailsData?.meta?.status ||
        updateBankDetailsData?.meta?.status) &&
        !showSeccondStep && (
          <SuccessModal
            closeModal={closeSuccessModal}
            sucessfullMsg={
              addBankDetailsData?.meta?.msg || updateBankDetailsData?.meta?.msg
            }
            Heading={"Bank Details!"}
          />
        )}
      {(addBankDetailsData?.meta?.status === false ||
        updateBankDetailsData?.meta?.status === false) &&
        !showSeccondStep && (
          <ErrorModal
            errorMessage={
              addBankDetailsData?.meta?.msg || updateBankDetailsData?.meta?.msg
            }
            closeModal={closeErrorModal}
            footerVisible={true}
          />
        )}
    </>
  );
};

export default AddaBankAccountModal;
