import { createSlice } from "@reduxjs/toolkit";
import addTagToCustomer from "@/rtk-apis/customer/settings/customerTags/addTagsToCustomer";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const addTagToCustomerSlice = createSlice({
  name: "addTagToCustomerSlice",
  initialState,
  reducers: {
    closeAddCustomerTagsModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTagToCustomer.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addTagToCustomer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(addTagToCustomer.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default addTagToCustomerSlice.reducer;

export const { closeAddCustomerTagsModal } = addTagToCustomerSlice.actions;
