import { createSlice } from "@reduxjs/toolkit";
import candidatesList from "@/rtk-apis/candidates/candidatesList";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const candidatesListSlice = createSlice({
  name: "candidatesList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidatesList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(candidatesList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(candidatesList.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default candidatesListSlice.reducer;
