import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { EDIT_ACH_DEBIT_DETAILS } from '@/rtk-apis/end-points';

const editACHDebitDetails = createAsyncThunk('editACHDebitDetails', async (body) => {
  try {
    const response = await httpService.put(EDIT_ACH_DEBIT_DETAILS, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default editACHDebitDetails;
