import React from 'react'

function KeyLockSvg() {
  return (
    <svg
    width="114"
    height="49"
    viewBox="0 0 114 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_141_30854)">
      <path
        d="M0.299316 0.333008C3.3609 0.333008 6.3697 0.333008 9.44888 0.333008C9.44888 16.0632 9.44888 31.7406 9.44888 47.4884C6.40489 47.4884 3.39609 47.4884 0.299316 47.4884C0.299316 31.7934 0.299316 16.0984 0.299316 0.333008Z"
        fill="#6C737F"
      />
      <path
        d="M64.1704 0.333008C67.232 0.333008 70.2408 0.333008 73.32 0.333008C73.32 16.0632 73.32 31.7406 73.32 47.4884C70.276 47.4884 67.2672 47.4884 64.1704 47.4884C64.1704 31.811 64.1704 16.0984 64.1704 0.333008Z"
        fill="#6C737F"
      />
      <path
        d="M46.3462 45.2006V16.5379C46.3462 15.5877 46.9093 14.7784 47.7187 14.4088C47.7363 14.3209 47.7363 14.2505 47.7539 14.1625C45.4313 14.1625 42.8976 14.1625 40.3286 14.1625C40.3286 15.5525 40.3286 16.837 40.3286 18.4206C39.9767 18.0863 39.836 17.9807 39.7128 17.8399C33.6776 10.837 21.3609 12.2798 16.4518 19.9514C13.7245 24.2094 13.0911 28.8722 13.6894 33.7989C14.5515 40.9602 19.2671 46.4499 26.0061 47.8751C31.4078 49.0188 36.2289 48.0687 39.8184 43.4235C39.924 43.3003 40.0823 43.2124 40.4166 42.966C40.4166 44.6376 40.4166 46.0628 40.4166 47.488C43.0383 47.488 45.5193 47.488 47.8067 47.488C47.8067 47.4352 47.7891 47.3825 47.7891 47.3473C46.9445 46.9954 46.3462 46.1684 46.3462 45.2006ZM38.4284 37.5115C35.9826 40.3619 32.7099 40.9426 29.2612 39.9572C25.7421 38.9543 24.0354 36.227 23.402 32.7784C22.9973 30.6317 23.2084 28.5203 24.0002 26.4792C25.3023 23.1537 27.9943 21.3062 31.5662 21.3062C35.9826 21.3062 39.185 23.8047 40.1879 28.1332C40.9445 31.4939 40.7861 34.7842 38.4284 37.5115Z"
        fill="#6C737F"
      />
      <path
        d="M55.5837 47.4886H50.745C49.4605 47.4886 48.4048 46.4505 48.4048 45.1484V16.4857C48.4048 15.2012 49.4429 14.1455 50.745 14.1455H55.5837C56.8681 14.1455 57.9238 15.1836 57.9238 16.4857V45.1484C57.9238 46.4505 56.8681 47.4886 55.5837 47.4886Z"
        fill="#6C737F"
      />
      <path
        d="M53.1555 9.83447C55.7793 9.83447 57.9063 7.7075 57.9063 5.08374C57.9063 2.45998 55.7793 0.333008 53.1555 0.333008C50.5318 0.333008 48.4048 2.45998 48.4048 5.08374C48.4048 7.7075 50.5318 9.83447 53.1555 9.83447Z"
        fill="#6C737F"
      />
      <path
        d="M113.701 47.488C111.414 47.488 106.874 47.488 104.252 47.488C104.252 46.0628 104.252 44.6376 104.252 42.966C103.918 43.2124 103.742 43.3003 103.654 43.4235C100.065 48.0687 95.2437 49.0188 89.8419 47.8751C83.1205 46.4499 78.3874 40.9426 77.5252 33.7989C76.9269 28.8722 77.578 24.2094 80.2877 19.9514C85.2143 12.2798 97.5135 10.837 103.549 17.8399C103.672 17.9807 103.813 18.1039 104.164 18.4206C104.164 16.837 104.164 15.5349 104.164 14.1625C106.733 14.1625 111.343 14.1625 113.666 14.1625M95.402 21.2886C91.8478 21.2886 89.1381 23.1361 87.836 26.4616C87.0442 28.5027 86.8507 30.6317 87.2378 32.7608C87.8712 36.2094 89.578 38.9543 93.097 39.9396C96.5457 40.9074 99.8184 40.3443 102.264 37.4939C104.622 34.749 104.78 31.4763 104.006 28.098C103.021 23.8047 99.8184 21.2886 95.402 21.2886Z"
        fill="#6C737F"
      />
    </g>
    <defs>
      <clipPath id="clip0_141_30854">
        <rect
          width="113.402"
          height="48"
          fill="white"
          transform="translate(0.299316 0.333008)"
        />
      </clipPath>
    </defs>
  </svg>
  )
}

export default KeyLockSvg