import { createSlice } from '@reduxjs/toolkit';
import recruiterDetailsOnSignUp from '@/rtk-apis/signUp/recruiterDeailsOnSignUp';

const initialState = {
  message: '',
  data: {},
  meta: {},
  loading: false,
  error: ''
};

const recruiterDetailsOnSignUpSlice = createSlice({
  name: 'recruiterDetailsOnSignUp',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(recruiterDetailsOnSignUp.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(recruiterDetailsOnSignUp.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(recruiterDetailsOnSignUp.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  }
});

export default recruiterDetailsOnSignUpSlice.reducer;

export const { closeRtkModal } = recruiterDetailsOnSignUpSlice.actions;
