import { createSlice } from '@reduxjs/toolkit';
import companyProfileDetails from '@/rtk-apis/customer/companyProfile/getCompanyProfileDetails';

const initialState = {
  message: '',
  data: {},
  meta: {},
  token: '',
  loading: false,
  error: ''
};

const getCompanyProfileDetailsSlice = createSlice({
  name: 'getCompanyProfileDetailsSlice',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(companyProfileDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(companyProfileDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(companyProfileDetails.rejected, (state, action) => {
        state.loading = false;
      });
  }
});

export default getCompanyProfileDetailsSlice.reducer;

export const { closeRtkModal } = getCompanyProfileDetailsSlice.actions;
