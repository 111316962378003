import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { GET_CUSTOMERS_EMPLOYEE_LIST } from '@/rtk-apis/end-points';

const getCustomersEmployeeList = createAsyncThunk(
  'getCustomersEmployeeList',
  async (body) => {
    try {
      const response = await httpService.post(
        GET_CUSTOMERS_EMPLOYEE_LIST,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default getCustomersEmployeeList;
