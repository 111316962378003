import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import SignUpStepOne from "@/components/signUp/SignUpStepOne";
import signUpCustomer from "@/rtk-apis/customer/signUpCustomer";
import Loader from "@/elements/Loader";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import { closeRtkModal } from "@/store/slices/customer/SignUpCustomerSlice";

const SignUp = () => {
  const {
    customer,
    firstname,
    lastname,
    email,
    role,
    phonenumber,
  } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, meta } = useSelector((globalState) => {
    return globalState.signUpCustomer;
  });

  const closeModal = () => {
    dispatch(closeRtkModal());
    if (meta?.status) {
      localStorage.removeItem("signupSteponeValues");
      navigate("/signin");
    }
  };

  const customerSignUp = (props) => {
    const { firstName, lastName, email, phoneNumber, password } = props;
    dispatch(
      signUpCustomer({
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        role,
        termAndCondition: true,
        countryCode: "+1",
      })
    );
  };

  return (
    <>
      <SignUpStepOne
        goToStepTwo={(props) => customerSignUp(props)}
        firstname={firstname}
        lastname={lastname}
        email={email}
        phoneNumber={phonenumber}
        signUpAs={customer}
      />
      {loading && <Loader />}
      {meta?.status && (
        <SuccessModal
          sucessfullMsg={meta.msg}
          Heading={"Successfully Signed Up!"}
          closeModal={closeModal}
        />
      )}
      {meta?.status === false && meta?.msg && (
        <ErrorModal
          errorMessage={meta?.msg}
          closeModal={closeModal}
          footerVisible={true}
        />
      )}
    </>
  );
};

export default SignUp;
