import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { NEW_CUSTOMER_SIGNUP } from "@/rtk-apis/end-points";

const newCustomerSignUp = createAsyncThunk(
  "newCustomerSignUp",
  async (body) => {
    try {
      const response = await httpService.post(NEW_CUSTOMER_SIGNUP, body);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default newCustomerSignUp;
