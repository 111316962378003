import { createSlice } from '@reduxjs/toolkit';
import setPrimaryDebitMethod from '@/rtk-apis/customer/payments/setPrimaryDebitMethod';

const initialState = {
  message: '',
  data: {},
  meta: {},
  loading: false,
  error: ''
};

const setPrimaryDebitMethodSlice = createSlice({
  name: 'setPrimaryDebitMethod',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setPrimaryDebitMethod.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(setPrimaryDebitMethod.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
      })
      .addCase(setPrimaryDebitMethod.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  }

});

export default setPrimaryDebitMethodSlice.reducer;

export const { closeRtkModal } = setPrimaryDebitMethodSlice.actions;
