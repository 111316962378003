import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { SET_PRIMARY_DEBIT_METHOD } from '@/rtk-apis/end-points';

const setPrimaryDebitMethod = createAsyncThunk('setPrimaryDebitMethod', async (body) => {
  try {
    const response = await httpService.put(SET_PRIMARY_DEBIT_METHOD, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default setPrimaryDebitMethod;
