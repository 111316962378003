import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { MYJOB_INTERVIEW_DETAILPAGE } from '@/rtk-apis/end-points';

const interviewDetailPageData = createAsyncThunk('interviewDetailPageData', async (body) => {
  try {
    const response = await httpService.post(MYJOB_INTERVIEW_DETAILPAGE, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default interviewDetailPageData;
