import React from 'react';
import { Link } from 'react-router-dom';

const SideBarMenu = ({ menu, activeTab, activeMenus }) => {
  return (
    <>
      <ul className="layoutSidemenu__list">
        {menu.map((item) =>
          activeMenus?.includes(item?.menuName)
            ? (
              <li
                key={item.menuName}
                className={
                  activeTab === item?.activeMenuName
                    ? 'layoutSidemenu__list--item layoutSidemenu__selected'
                    : 'layoutSidemenu__list--item'
                }
              >
                <Link to={item?.link} className="layoutSidemenu__list--link">
                  {item?.icon}
                  <span>{item?.label}</span>
                </Link>
              </li>
              )
            : null
        )}
      </ul>
    </>
  );
};

export default SideBarMenu;
