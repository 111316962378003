import React from 'react'

function OpenEye() {
  return (
    <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_225_1436)">
      <path
        d="M0.666504 8.66634C0.666504 8.66634 3.33317 3.33301 7.99984 3.33301C12.6665 3.33301 15.3332 8.66634 15.3332 8.66634C15.3332 8.66634 12.6665 13.9997 7.99984 13.9997C3.33317 13.9997 0.666504 8.66634 0.666504 8.66634Z"
        stroke="#28A0E8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99984 10.6663C9.10441 10.6663 9.99984 9.77091 9.99984 8.66634C9.99984 7.56177 9.10441 6.66634 7.99984 6.66634C6.89527 6.66634 5.99984 7.56177 5.99984 8.66634C5.99984 9.77091 6.89527 10.6663 7.99984 10.6663Z"
        stroke="#28A0E8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_225_1436">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.666016)"
        />
      </clipPath>
    </defs>
  </svg>
  )
}

export default OpenEye