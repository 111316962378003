import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SideBar from "@/components/layout/SideBar";
import {
  GetUserDetails,
  getInitial,
  firstLetterCapital,
  formatPhoneNumber,
} from "@/utils/helper";
import { useDispatch, useSelector } from "react-redux";
import customerEmployeeDetail from "@/rtk-apis/customer/myProfile/customerEmployeeDetail";
import customerEmployeeJobs from "@/rtk-apis/customer/myProfile/customerEmployeeJobs";
import Loader from "@/elements/Loader";

const EmployeeDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { employeeId } = useParams();

  const { data, loading } = useSelector((globalState) => {
    return globalState.employeeDetails;
  });

  const jobData = useSelector((globalState) => {
    return globalState.getCompanyEmployeeJobs;
  });

  const getEmployeeDetails = async () => {
    dispatch(customerEmployeeDetail(employeeId));
  };

  useEffect(() => {
    getEmployeeDetails();
    getEmployeeJobs();
  }, []);

  const getEmployeeJobs = () => {
    const jobsObj = {
      employeeId,
      limit: 100,
      page: 1,
    };
    dispatch(customerEmployeeJobs(jobsObj));
  };

  const getJobRole = (data) => {
    let jobRole = [];
    if (data?.hiringLeadArray.includes(employeeId)) {
      jobRole.push("Hiring Lead");
    } else if (data?.jobTeamMemberArray.includes(employeeId)) {
      jobRole.push("Job Team Member");
    }
    return jobRole.toString();
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid layoutGrid--full">
          <div className="layoutGrid__col">
            <div className="userDetailsCol">
              <div className="page page__full">
                <div className="page__head">
                  <h2 className="page__head--title">Employee Details</h2>
                  <div className="page__head--actions">
                    <button
                      className="btn btn__white"
                      onClick={() =>
                        navigate("/customer-company-profile", {
                          state: {
                            OpenEmployeeList: true,
                          },
                        })
                      }
                    >
                      Back
                    </button>
                  </div>
                </div>

                <div className="page__body">
                  <div className="details">
                    <section className="details__section">
                      <div className="initials initials__lg">
                        {getInitial(data?.firstName)}
                      </div>
                      <div className="details__section--name">
                        {firstLetterCapital(data?.firstName)}{" "}
                        {firstLetterCapital(data?.lastName)}
                      </div>
                      <ul className="tags__list mt_1">
                        {data?.phoneNumber && (
                          <li className="tags__list--item">
                            <span>Phone:</span>
                            +1 {formatPhoneNumber(data?.phoneNumber)}
                          </li>
                        )}
                        {data?.email && (
                          <li className="tags__list--item">
                            <span>Email:</span>
                            {data?.email}
                          </li>
                        )}
                      </ul>
                    </section>
                    <section className="details__section">
                      <div className="tabContainer">
                        <TableContainer
                          component={Paper}
                          className="tableContainer"
                        >
                          <Table className="table tableClickable">
                            <TableHead>
                              <TableRow>
                                <TableCell>S.</TableCell>
                                <TableCell>Job Title</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Created On</TableCell>
                                <TableCell>Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {jobData?.data?.rows?.length
                                ? jobData?.data?.rows?.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell component="th" scope="row">
                                        {index + 1}
                                      </TableCell>
                                      <TableCell> {row?.title}</TableCell>
                                      <TableCell> {getJobRole(row)}</TableCell>
                                      <TableCell>
                                        {" "}
                                        {moment(row?.createdAt).format(
                                          "D MMM, YYYY"
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        <div className="cellText">
                                          <span
                                            className={`cellText__dark ${
                                              row?.status === "active"
                                                ? "text--primary"
                                                : "text--danger"
                                            }`}
                                          >
                                            {row?.status === "active"
                                              ? "Open"
                                              : row?.status === "paused"
                                              ? "Paused"
                                              : "Closed"}
                                          </span>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                : null}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                      {!jobData?.data?.rows?.length && (
                        <div className="no-data floating">
                          No Results Found!
                        </div>
                      )}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default EmployeeDetail;
