import React from "react";

function CandidatesfeedbackFiltered({ goBack, feedback }) {
  const feedbackFiltered =
    feedback.length > 0
      ? feedback.filter((item) => item.role === "Recruiter")
      : [];
  return (
    <>
      <div className="dataCol__head feedbackFiltered--head mt_1 pb_1">
        <div className="notes__head w_100">
          <h4 className="details__section--title m_0">My feedback</h4>
          <button className="btn btn__white" onClick={goBack}>
            Back
          </button>
        </div>
      </div>
      {feedbackFiltered?.length &&
      feedbackFiltered[0].feedBackData?.length > 0 ? (
        <div className="form">
          <div className="formRow formRow__2">
            {feedbackFiltered.map((arr) => {
              return arr.feedBackData.map((item, index) => {
                return (
                  <div key={index} className="formCol">
                    <div className="formField">
                      <label className="formField__label">
                        {item.question}
                      </label>
                      <textarea
                        value={item.answer}
                        className="formField__textarea"
                        rows={3}
                        readOnly
                      ></textarea>
                    </div>
                  </div>
                );
              });
            })}
          </div>
        </div>
      ) : (
        <div className="tableContainer">
          <div className="no-data floating">No Results Found!</div>
        </div>
      )}
    </>
  );
}

export default CandidatesfeedbackFiltered;
