import React from "react";

const ArrowDownBlue = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.8335 14.1668L14.1668 5.8335M14.1668 5.8335H5.8335M14.1668 5.8335V14.1668"
        stroke="#28A0E8"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ArrowDownBlue;
