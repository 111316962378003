import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { DOWNLOAD_JD } from '@/rtk-apis/end-points';

const downloadJd = createAsyncThunk('downloadJd', async (body) => {
  try {
    const response = await httpService.post(DOWNLOAD_JD, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default downloadJd;
