import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CANDIDATE_SIGN_UP } from '@/rtk-apis/end-points';

const candidateSignUp = createAsyncThunk('candidateSignUp', async (body) => {
  try {
    const response = await httpService.post(CANDIDATE_SIGN_UP, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default candidateSignUp;
