import React, { useState, useEffect, useRef } from "react";
import SearchCross from "@/assets/svgs/SearchCross";
import DefaultImg from "@/assets/images/default-img.png";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDown from "@/assets/svgs/ArrowDown";
import LocationIcon from "@/assets/svgs/LocationIcon copy";
import OfficeIcon from "@/assets/svgs/OfficeIcon";
import HyperLinkIcon from "@/assets/svgs/HyperLinkIcon";
import AddressIcon from "@/assets/svgs/AddressIcon";
import { Formik, Form, Field } from "formik";
import staticData from "@/rtk-apis/staticData";
import { useDispatch, useSelector } from "react-redux";
import getStateCityData from "@/rtk-apis/customer/companyProfile/getStateCityData";
import getCityData from "@/rtk-apis/customer/companyProfile/getCityData";
import uploadCustomerLogo from "@/rtk-apis/customer/companyProfile/uploadCustomerLogo";
import { removeLogo } from "@/store/slices/customer/companyProfile/uploadLogoSlice";
import { GetUserDetails, replaceLast } from "@/utils/helper";
import UpdateCompanyProfile from "@/rtk-apis/customer/companyProfile/updateCompanyProfileDetails";
import { editCompanyProfileSchema } from "@/components/schema/customer-schema";
import { SuccessModal } from "@/components/modals/SuccessModal";
import { closeRtkModal } from "@/store/slices/customer/companyProfile/updateCompanyDetailsSlice";
import ErrorModal from "@/components/modals/error-modal";
import { closeUploadErrorModal } from "@/store/slices/customer/companyProfile/uploadLogoSlice";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";

function CompanyProfileEdit({ data, setOpenEditCompanyPage }) {
  const dispatch = useDispatch();
  const userDetails = GetUserDetails();
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const add3Ref = useRef();
  const add1DivRef = useRef();
  const add3DivRef = useRef();
  // states******************************
  const [Country, setCountry] = useState({value: "", name: ""});
  const [selectedState, setSelectedState] = useState("");

  const [addressCountry, setAddressCountry] = useState("");
  const [addressCountryCode, setAddressCountryCode] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressStateCode, setAddressStateCode] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressZipcode, setAddressZipcode] = useState("");
  const [addressAddress1, setAddressAddress1] = useState("");
  const [showAddressFiels, setShowAddressFiels] = useState(
    data?.address ? true : false
  );
  const [currentTime, setCurrentTime] = useState(new Date());

  const handleAddressChange = (value, inputName) => {
    if (value?.length >= 3 && inputName === "address3") {
      add3DivRef.current.style.display = "none";
      add1DivRef.current.style.display = "block";
      inputRef.current.focus();
    } else if (value?.length < 3 && inputName === "address") {
      add1DivRef.current.style.display = "none";
      add3DivRef.current.style.display = "block";
      add3Ref.current.focus();
    } else {
      if (value?.length < 3) {
        add1DivRef.current.style.display = "none";
        add3DivRef.current.style.display = "block";
        add3Ref.current.focus();
      } else {
        add3DivRef.current.style.display = "none";
        add1DivRef.current.style.display = "block";
        inputRef.current.focus();
      }
    }
  };

  useEffect(() => {
    if (add3DivRef.current) {
      add3DivRef.current.style.display = "none";
    }
    const options = {
      componentRestrictions: {
        country: Country?.value ? Country?.value : "US",
      },
    };
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function() {
      setShowAddressFiels(true);
      const place = await autoCompleteRef.current.getPlace();
      let addressComponents = place["address_components"];
      let address1 = place["formatted_address"];
      let street = "";
      let city = "";
      let state = "";
      let stateCode = "";
      let country = "";
      let countryCode = "";
      let zipCode = "";

      for (let i = 0; i < addressComponents.length; i++) {
        let types = addressComponents[i]["types"];
        if (types.includes("street_number") || types.includes("route")) {
          street = addressComponents[i]["long_name"];
        } else if (types.includes("locality")) {
          city = addressComponents[i]["long_name"];
        } else if (types.includes("administrative_area_level_1")) {
          state = addressComponents[i]["long_name"];
          stateCode = addressComponents[i]["short_name"];
        } else if (types.includes("country")) {
          country = addressComponents[i]["long_name"];
          countryCode = addressComponents[i]["short_name"];
        } else if (
          types.includes("postal_code") ||
          types.includes("postal_code_prefix")
        ) {
          zipCode = addressComponents[i]["long_name"].replace(/\s/g, "");
        }
      }
      const addressSplit = address1.split(city);
      let modifiedAddress = "";
      if (addressSplit.length && addressSplit[0].length) {
        modifiedAddress = replaceLast(addressSplit[0], ", ", "");
      }
      let streetAddress = modifiedAddress.includes(place["name"])
        ? modifiedAddress
        : `${place["name"]}`;
      setAddressCountry(country);
      setAddressCountryCode(countryCode);
      setAddressState(state);
      setAddressStateCode(stateCode);
      setAddressCity(city);
      setAddressZipcode(zipCode);
      setAddressAddress1(streetAddress);
      setCurrentTime(new Date());
    });
  }, [Country]);

  // Responses********************************
  const uploadLogoResponse = useSelector((globalState) => {
    return globalState.uploadLogo;
  });

  const [ImageUrl, setImageUrl] = useState(
    data?.companyLogo ? data?.companyLogo : ""
  );

  const StaticData = useSelector((globalState) => {
    return globalState.static;
  });

  const updateCompanyProfileResponse = useSelector((globalState) => {
    return globalState.updateCompanyProfile;
  });

  // const cityData = useSelector((globalState) => {
  //   return globalState.cityData;
  // });

  // const stateCityData = useSelector((globalState) => {
  //   return globalState.stateCityData;
  // });

  let initialValues = {
    companyLogo: data?.companyLogo ? data?.companyLogo : "",
    companyName: data?.companyName ? data?.companyName : "",
    websiteLink: data?.websiteLink ? data?.websiteLink : "",
    linkedin: data?.linkedin ? data?.linkedin : "",
    industryType: data?.industryType ? data?.industryType : [],
    country: data?.countryName ? data?.countryName : "",
    zipcode: data?.zipcode ? data?.zipcode : "",
    state: data?.stateName ? data?.stateName : "",
    city: data?.city ? data?.city : "",
    address: data?.address ? data?.address : "",
  };

  const getStateData = () => {
    let body = {
      type: "state",
      countryCode: Country?.value,
    };
    dispatch(getStateCityData(body));
  };

  useEffect(() => {
    if (uploadLogoResponse?.data?.url) {
      setImageUrl(uploadLogoResponse?.data?.url);
    }
  }, [uploadLogoResponse]);

  // city data
  const getCities = () => {
    let body = {
      type: "city",
      countryCode: Country?.value,
      stateCode: selectedState?.value,
    };
    dispatch(getCityData(body));
  };

  // logo upload
  const handleFileUpload = (e) => {
    let file = e?.target?.files;
    let formData = new FormData();
    formData.append("imgFile", file[0]);
    dispatch(uploadCustomerLogo(formData));
  };

  const handleRemoveLogo = () => {
    setImageUrl("");
    dispatch(removeLogo());
  };

  const handleSubmit = (values) => { 
    let body = {
      ...values,
      companyLogo: ImageUrl,
      companyId: userDetails?.customer?.companyId,
      countryName: values?.country ? values?.country : "",
      country: addressCountryCode,
      state: addressStateCode,
      stateName: values?.state,
    };
    dispatch(UpdateCompanyProfile(body));
  };

  useEffect(() => {
    dispatch(staticData());
  }, []);

  useEffect(() => {
    if (uploadLogoResponse?.data?.url) {
      setImageUrl(uploadLogoResponse?.data?.url);
    }
  }, [uploadLogoResponse]);

  // useEffect(() => {
  //   getStateData();
  // }, [Country, data?.country]);

  // useEffect(() => {
  //   getCities();
  // }, [selectedState]);

  const closeModal = () => {
    dispatch(closeRtkModal());
    setOpenEditCompanyPage(false);
  };

  const closeErrorModal = () => {
    dispatch(closeUploadErrorModal());
  };

  const isButtonDisabled = (error, value, initialValues) => {
    let status = false;
    if (value?.state) {
      delete error["state"];
    }
    if (value?.city) {
      delete error["city"];
    }
    if (value?.zipcode) {
      delete error["zipcode"];
    }
    if (Object.keys(error)?.length != 0) {
      status = true;
    } else if (
      Object.keys(error)?.length == 0 &&
      _.isEqual(value, initialValues)
    ) {
      status = true;
    }
    if (
      Object.keys(error)?.length == 0 &&
      value?.country === "United States" &&
      !value?.zipcode
    ) {
      status = true;
    }
    return status;
  };

  return (
    <>
      <div className="layoutGrid layoutGrid--full">
        <div className="layoutGrid__col">
          <div className="dataCol">
            <div className="page page__full">
              <Formik
                initialValues={initialValues}
                validationSchema={editCompanyProfileSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  touched,
                  setFieldValue,
                  setFieldError
                }) => {
                  useEffect(() => {
                    if (addressCountry) {
                      //setFieldValue("country", addressCountry);
                      setFieldValue("state", addressState);
                      setFieldValue("city", addressCity);
                      setFieldValue("zipcode", addressZipcode);
                      setFieldValue("address", addressAddress1);
                    }
                  }, [
                    addressCountry,
                    addressState,
                    addressCity,
                    addressZipcode,
                    addressAddress1,
                    currentTime,
                  ]);

                  return (
                    <Form className="form p_0">
                      <div className="page__head">
                        <h2 className="page__head--title">
                          {data?.companyName}
                        </h2>
                        <div className="page__head--actions">
                          <button
                            className="btn btn__white"
                            type="button"
                            onClick={() => setOpenEditCompanyPage(false)}
                          >
                            Cancel
                          </button>
                          <button className="btn btn__primary" type="submit" disabled={isButtonDisabled(errors, values, initialValues)}>
                            Update
                          </button>
                        </div>
                      </div>

                      <div className="page__body">
                        <div className="details">
                          <section className="form__section">
                            <h4 className="form__section--title">
                              Company Information
                            </h4>
                            <div className="formRow">
                              <div className="formCol">
                                <div className="formField">
                                  <div className="uploadField">
                                    <div className="uploadImage">
                                      <img
                                        src={ImageUrl ? ImageUrl : DefaultImg}
                                        alt="Company"
                                        width="140"
                                        height="140"
                                      />
                                      {ImageUrl && !DefaultImg && (
                                        <button
                                          className="uploadImage__remove"
                                          onClick={handleRemoveLogo}
                                          type="button"
                                        >
                                          <SearchCross />
                                        </button>
                                      )}
                                    </div>

                                    <div className="uploadAction">
                                      <label htmlFor="uploadFile">
                                        Upload logo
                                        <input
                                          type="file"
                                          onChange={(e) => handleFileUpload(e)}
                                          id="uploadFile"
                                          name="companyLogo"
                                          accept={
                                            "image/png,image/jpeg,image/jpg"
                                          }
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="formRow formRow__2">
                              <div className="formCol">
                                <div
                                  className={`formField ${
                                    errors?.companyName && touched?.companyName
                                      ? "fieldError"
                                      : ""
                                  }`}
                                >
                                  <label className="formField__label">
                                    Company Name
                                  </label>
                                  <div className="formField__group sm">
                                    <Field
                                      type="text"
                                      className="formField__input sm"
                                      placeholder="Company Name"
                                      name="companyName"
                                      onBlur={handleBlur}
                                    />
                                    <span className="formField__icon">
                                      <OfficeIcon />
                                    </span>
                                  </div>
                                  <span className="formField__error">
                                    {errors?.companyName &&
                                      touched?.companyName &&
                                      errors?.companyName}
                                  </span>
                                </div>
                              </div>

                              <div className="formCol">
                                <div
                                  className={`formField ${
                                    errors?.websiteLink && touched?.websiteLink
                                      ? "fieldError"
                                      : ""
                                  }`}
                                >
                                  <label className="formField__label">
                                    Company Website
                                  </label>
                                  <div className="formField__group sm">
                                    <Field
                                      type="text"
                                      className="formField__input sm"
                                      placeholder="Company Website"
                                      name="websiteLink"
                                      onBlur={handleBlur}
                                    />
                                    <span className="formField__icon">
                                      <HyperLinkIcon />
                                    </span>
                                  </div>
                                  <span className="formField__error">
                                    {errors?.websiteLink &&
                                      touched?.websiteLink &&
                                      errors?.websiteLink}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="formRow formRow__2">
                              <div className="formCol">
                                <div
                                  className={`formField ${
                                    errors?.linkedin && touched?.linkedin
                                      ? "fieldError"
                                      : ""
                                  }`}
                                >
                                  <label className="formField__label">
                                    Company LinkedIn
                                  </label>
                                  <div className="formField__group sm">
                                    <Field
                                      type="text"
                                      className="formField__input sm"
                                      placeholder="Company LinkedIn"
                                      name="linkedin"
                                      onBlur={handleBlur}
                                    />
                                    <span className="formField__icon">
                                      <HyperLinkIcon />
                                    </span>
                                  </div>
                                  <span className="formField__error">
                                    {errors?.linkedin &&
                                      touched?.linkedin &&
                                      errors?.linkedin}
                                  </span>
                                </div>
                              </div>

                              <div className="formCol">
                                <div className="formField">
                                  <label className="formField__label">
                                    Industry Type
                                  </label>
                                  <Autocomplete
                                    multiple
                                    id="tags-filled"
                                    options={
                                      StaticData?.data?.industryType?.length
                                        ? StaticData?.data?.industryType
                                        : []
                                    }
                                    defaultValue={values?.industryType}
                                    name="industryType"
                                    onChange={(event, newValue) => {
                                      setFieldValue("industryType", newValue);
                                    }}
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                      value.map((option, index) => (
                                        <Chip
                                          variant="outlined"
                                          label={option}
                                          {...getTagProps({ index })}
                                        />
                                      ))
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="filled"
                                        placeholder="Select"
                                        className="autoCompleteInput"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </section>

                          <section className="form__section">
                            <h4 className="form__section--title">Address</h4>
                            <div className="formRow">
                              <div className="formCol">
                                <div className={`formField ${
                                    errors?.country && touched?.country
                                      ? "fieldError"
                                      : ""
                                  }`}>
                                  <label className="formField__label">
                                    Country
                                  </label>
                                  <Autocomplete
                                    id="tags-filled"
                                    options={StaticData?.data?.taxCountry}
                                    getOptionLabel={(option) =>
                                      option?.name || option
                                    }
                                    defaultValue={values?.country || ""}
                                    value={values?.country}
                                    name="country"
                                    onChange={(event, newValue) => {
                                      if(newValue === null){
                                        setFieldError("country", "Please select country");
                                        setFieldError("state", "Please enter state");
                                        setFieldError("city", "Please enter city");
                                        setFieldError("zipcode", "Please enter zipcode");
                                      }
                                      setCountry(newValue);
                                      setFieldValue("country", newValue?.name);
                                      setFieldValue("state", "");
                                      setFieldValue("city", "");
                                      setFieldValue("zipcode", "");
                                      setFieldValue("address", "");
                                      setShowAddressFiels(false);
                                    }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="filled"
                                        placeholder="Select"
                                        className="autoCompleteInput"
                                      />
                                    )}
                                  />
                                  <span className="formField__error">
                                        {errors?.country &&
                                          touched?.country &&
                                          errors?.country}
                                    </span>
                                </div>
                              </div>
                            </div>
                            {values?.country && (
                              <div className="formRow" ref={add1DivRef}>
                                <div className="formCol">
                                  <div className={`formField ${
                                    errors?.address && touched?.address
                                      ? "fieldError"
                                      : ""
                                  }`}>
                                    <label className="formField__label">
                                      Company Address
                                    </label>
                                    <div className="formField__group sm">
                                      <input
                                        type="text"
                                        className="formField__input sm"
                                        placeholder="Company Address"
                                        name="address"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          handleAddressChange(
                                            e?.target?.value,
                                            "address"
                                          );
                                          setFieldValue(
                                            "address",
                                            e?.target?.value
                                          );
                                        }}
                                        value={values?.address}
                                        ref={inputRef}
                                      />
                                      <span className="formField__icon">
                                        <AddressIcon />
                                      </span>
                                      <span className="formField__error">
                                        {errors?.address &&
                                          touched?.address &&
                                          errors?.address}
                                    </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {values?.country && (
                              <div className="formRow" ref={add3DivRef}>
                                <div className="formCol">
                                  <div className={`formField ${
                                    errors?.address && touched?.address
                                      ? "fieldError"
                                      : ""
                                  }`}>
                                    <label className="formField__label">
                                      Company Address
                                    </label>
                                    <div className="formField__group sm">
                                      <input
                                        type="text"
                                        className="formField__input sm"
                                        placeholder="Company Address"
                                        name="address3"
                                        value={values?.address}
                                        ref={add3Ref}
                                        onChange={(e) => {
                                          handleAddressChange(
                                            e?.target?.value,
                                            "address3"
                                          );
                                          setFieldValue(
                                            "address",
                                            e?.target?.value
                                          );
                                        }}
                                      />
                                      <span className="formField__icon">
                                        <AddressIcon />
                                      </span>
                                      <span className="formField__error">
                                        {errors?.address &&
                                          touched?.address &&
                                          errors?.address}
                                    </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {showAddressFiels && (
                              <div className="formRow formRow__2">
                                <div className="formCol">
                                  <div className={`formField ${
                                    errors?.state && touched?.state
                                      ? "fieldError"
                                      : ""
                                  }`}>
                                    <label className="formField__label">
                                      State
                                    </label>
                                      <Field
                                        type="text"
                                        className="formField__input sm"
                                        placeholder="State"
                                        name="state"
                                        onBlur={handleBlur}
                                      />
                                      <span className="formField__error">
                                        {errors?.state &&
                                          touched?.state &&
                                          errors?.state}
                                    </span>
                                  </div>
                                </div>
                                <div className="formCol">
                                  <div className={`formField ${
                                    errors?.city && touched?.city
                                      ? "fieldError"
                                      : ""
                                  }`}>
                                    <label className="formField__label">
                                      City
                                    </label>
                                      <Field
                                        type="text"
                                        className="formField__input sm"
                                        placeholder="City"
                                        name="city"
                                        onBlur={handleBlur}
                                      />
                                    <span className="formField__error">
                                        {errors?.city &&
                                          touched?.city &&
                                          errors?.city}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                            {showAddressFiels && (
                              <div className="formRow formRow__2">
                                <div className="formCol">
                                  <div
                                    className={`formField ${
                                      errors?.zipcode && touched?.zipcode
                                        ? "fieldError"
                                        : ""
                                    }`}
                                  >
                                    <label className="formField__label">
                                      Zipcode
                                    </label>
                                    <div className="formField__group sm">
                                      <Field
                                        type="text"
                                        className="formField__input sm"
                                        name="zipcode"
                                        placeholder="Zipcode"
                                        onBlur={handleBlur}
                                      />
                                      <span className="formField__icon">
                                        <LocationIcon />
                                      </span>
                                    </div>
                                    <span className="formField__error">
                                      {errors?.zipcode &&
                                        touched?.zipcode &&
                                        errors?.zipcode}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </section>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {updateCompanyProfileResponse?.meta?.status && (
        <SuccessModal
          closeModal={closeModal}
          sucessfullMsg={updateCompanyProfileResponse?.meta?.msg}
          Heading={"Company Profile Updated!"}
        />
      )}
      {uploadLogoResponse?.meta?.status === false &&
        uploadLogoResponse?.meta?.msg && (
          <ErrorModal
            errorMessage={uploadLogoResponse?.meta?.msg}
            closeModal={closeErrorModal}
            footerVisible={true}
          />
        )}
    </>
  );
}

export default CompanyProfileEdit;
