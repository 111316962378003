import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CANDIDATE_LIST } from '@/rtk-apis/end-points';

const candidatesList = createAsyncThunk('candidatesList', async (body) => {
  try {
    const response = await httpService.post(CANDIDATE_LIST, body);
    return response?.data;
  } catch (error) {
    return error;
  }
});

export default candidatesList;
