import React from "react";

const LogoIcon = () => {
  return (
    <svg
    width="71"
    height="30"
    viewBox="0 0 71 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_261_1471)">
      <path
        d="M0 0C1.91349 0 3.79399 0 5.71848 0C5.71848 9.83138 5.71848 19.6298 5.71848 29.4721C3.81598 29.4721 1.93548 29.4721 0 29.4721C0 19.6628 0 9.85337 0 0Z"
        fill="#150823"
      />
      <path
        d="M39.9189 0C41.8324 0 43.7129 0 45.6374 0C45.6374 9.83138 45.6374 19.6298 45.6374 29.4721C43.7349 29.4721 41.8544 29.4721 39.9189 29.4721C39.9189 19.6738 39.9189 9.85337 39.9189 0Z"
        fill="#150823"
      />
      <path
        d="M28.7791 28.0424V10.1282C28.7791 9.53433 29.1311 9.02846 29.6369 8.79752C29.6479 8.74254 29.6479 8.69855 29.6589 8.64356C28.2073 8.64356 26.6237 8.64356 25.0181 8.64356C25.0181 9.51233 25.0181 10.3151 25.0181 11.3049C24.7982 11.0959 24.7102 11.0299 24.6333 10.942C20.8613 6.56512 13.1633 7.46688 10.0951 12.2616C8.39058 14.9229 7.99469 17.8371 8.36859 20.9163C8.90745 25.3921 11.8547 28.8232 16.0665 29.7139C19.4426 30.4288 22.4558 29.8349 24.6992 26.9317C24.7652 26.8547 24.8642 26.7997 25.0731 26.6458C25.0731 27.6905 25.0731 28.5812 25.0731 29.472C26.7117 29.472 28.2623 29.472 29.6919 29.472C29.6919 29.439 29.6809 29.406 29.6809 29.384C29.153 29.1641 28.7791 28.6472 28.7791 28.0424ZM23.8305 23.2367C22.3019 25.0182 20.2564 25.3811 18.101 24.7653C15.9016 24.1384 14.8349 22.4339 14.439 20.2785C14.186 18.9368 14.318 17.6172 14.8129 16.3415C15.6267 14.2631 17.3092 13.1084 19.5416 13.1084C22.3019 13.1084 24.3033 14.67 24.9302 17.3752C25.403 19.4757 25.3041 21.5321 23.8305 23.2367Z"
        fill="#150823"
      />
      <path
        d="M34.5522 29.4723H31.528C30.7253 29.4723 30.0654 28.8234 30.0654 28.0096V10.0954C30.0654 9.29264 30.7143 8.63281 31.528 8.63281H34.5522C35.355 8.63281 36.0148 9.28164 36.0148 10.0954V28.0096C36.0148 28.8234 35.355 29.4723 34.5522 29.4723Z"
        fill="#150823"
      />
      <path
        d="M33.0346 5.93842C34.6745 5.93842 36.0038 4.60906 36.0038 2.96921C36.0038 1.32936 34.6745 0 33.0346 0C31.3948 0 30.0654 1.32936 30.0654 2.96921C30.0654 4.60906 31.3948 5.93842 33.0346 5.93842Z"
        fill="#28A0E8"
      />
      <path
        d="M70.8757 29.472C69.4461 29.472 66.6088 29.472 64.9703 29.472C64.9703 28.5812 64.9703 27.6905 64.9703 26.6458C64.7613 26.7997 64.6514 26.8547 64.5964 26.9317C62.353 29.8349 59.3398 30.4288 55.9637 29.7139C51.7628 28.8232 48.8046 25.3811 48.2657 20.9163C47.8918 17.8371 48.2987 14.9229 49.9923 12.2616C53.0715 7.46688 60.7584 6.56512 64.5304 10.942C64.6074 11.0299 64.6954 11.1069 64.9153 11.3049C64.9153 10.3151 64.9153 9.50133 64.9153 8.64356C66.5209 8.64356 69.4021 8.64356 70.8537 8.64356M59.4388 13.0974C57.2174 13.0974 55.5238 14.2521 54.71 16.3305C54.2152 17.6062 54.0942 18.9368 54.3361 20.2675C54.732 22.4229 55.7987 24.1384 57.9981 24.7543C60.1536 25.3591 62.199 25.0072 63.7276 23.2257C65.2012 21.5101 65.3002 19.4647 64.8163 17.3532C64.2005 14.67 62.199 13.0974 59.4388 13.0974Z"
        fill="#150823"
      />
    </g>
    <defs>
      <clipPath id="clip0_261_1471">
        <rect width="70.8761" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
  );
};
export default LogoIcon;
