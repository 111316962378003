import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CANDIDATE_RESUME_UPLOAD } from '@/rtk-apis/end-points';

const candidateResumeUpload = createAsyncThunk('candidatesResumeUpload', async (body) => {
  try {
    const response = await httpService.post(CANDIDATE_RESUME_UPLOAD, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default candidateResumeUpload;
