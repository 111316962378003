import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { ADD_CREDIT_CARD_DETAILS } from "@/rtk-apis/end-points";

const addCreditCardDetails = createAsyncThunk(
  "addCreditCardDetails",
  async (body) => {
    try {
      const response = await httpService.post(ADD_CREDIT_CARD_DETAILS, body);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default addCreditCardDetails;
