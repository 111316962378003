import React, { useState, useEffect } from "react";
import moment from "moment";
import Search from "@/components/Search";
import SearchIcon from "@/assets/svgs/SearchIcon";
import CustomPagination from "@/elements/pagination/customPagination";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader from "@/elements/Loader";
import { GetUserDetails, formattedDate } from "@/utils/helper";
import jobRequestsList from "@/rtk-apis/jobRequests/jobRequestsList";

function JobRequestsListing({ selectedIndex, changeIndex }) {
  const user = GetUserDetails();
  const dispatch = useDispatch();
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const { data, loading } = useSelector((globalState) => {
    return globalState.jobRequestsListData;
  });

  useEffect(() => {
    getJobRequestsAPIData();
  }, [search, page]);

  const getJobRequestsAPIData = () => {
    dispatch(
      jobRequestsList({
        recruiterId: user?.recruiter?.recruiterId,
        page: search ? 1 : page,
        limit: 20,
        status: null,
        search,
      })
    );
  };

  const closeSearch = () => {
    setShowSearch(false);
    setSearch("");
    setPage(1);
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  return (
    <div className="layoutGrid__col">
      <div className={`listCol ${showSearch && "listCol__search"}`}>
        <div className="listCol__head listing__head">
          <Search
            closeSearch={closeSearch}
            searchTerm={(value) => setSearch(value)}
          />
          <h2 className="listCol__head--title">
            <span>Job Requests</span>
          </h2>
          <div className="listCol__head--actions">
            <div className="formField">
              <button
                onClick={() => {
                  setShowSearch(true);
                }}
                className="btn btn__white p_0"
              >
                <SearchIcon />
              </button>
            </div>
          </div>
        </div>
        <ul className="listCol__list listing__list">
          {data?.rows?.length ? (
            data.rows.map((row, index) => {
              return (
                <li
                  onClick={() => changeIndex(index)}
                  key={index}
                  className={`listCol__list--item sm ${
                    selectedIndex === index && "listCol__item--selected"
                  }`}
                >
                  <div className="item item--recruiter">
                    <div className="item__info">
                      <h4 className="item__info--title">{row.title}</h4>
                      <div className="item__info--subtitle">
                        <p>{row?.company?.companyName}</p>
                      </div>
                      <div className="item__info--desc">
                        <p>{row?.department}</p>
                      </div>
                      {row.applicationDeadline && (
                        <div className="item__info--desc">
                          <p>
                            Last date:{" "}
                            {formattedDate(
                              moment(row.assigndDate).add(2, "days")
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              );
            })
          ) : (
            <div className="no-data">No Results Found!</div>
          )}
        </ul>
        <div className="pagination pagination__center">
          {data?.rows?.length ? (
            <CustomPagination
              page={page}
              totalPageCount={Math.ceil(data?.count / 20)}
              changePage={(event, value) => changePage(event, value)}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
}

export default JobRequestsListing;
