import React from 'react'

function CloseIconGray() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 1L1 20M1 1L20 20" stroke="#150823" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export default CloseIconGray