import React, { useState } from "react";
import TaxResidenceView from "@/pages/Settings/TaxInformation/TaxResidenceView.jsx";
import TaxResidenceEdit from "@/pages/Settings/TaxInformation/TaxResidenceEdit";

const TaxResidentDetails = ({ taxInfoStatus, taxDetails, refreshTaxInfo, citizenType }) => {
  const [openTaxResidenceEditPage, setOpenTaxResidenceEditPage] = useState(
    false
  );
  const openTaxResidenceEdit = (value) => {
    setOpenTaxResidenceEditPage(value);
    if (!value) {
      refreshTaxInfo();
    }
  };
  return (
    <>
      {openTaxResidenceEditPage ? (
        <TaxResidenceEdit
          changeTaxResidentView={openTaxResidenceEdit}
          taxDetails={taxDetails}
          citizenType={citizenType}
        />
      ) : (
        <TaxResidenceView
          changeTaxResidentView={openTaxResidenceEdit}
          taxInfoStatus={taxInfoStatus}
          taxDetails={taxDetails}
          citizenType={citizenType}
        />
      )}
    </>
  );
};

export default TaxResidentDetails;
