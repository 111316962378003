import React, { useState } from "react";
import EditIcon from "@/assets/svgs/EditIcon";
import OpenEye from "@/assets/svgs/OpenEye";
import CloseEye from "@/assets/svgs/CloseEye";
import { maskSSNnumber, decryptData } from "@/utils/helper";

const TaxPayerIdentificationNumberView = ({
  changeTaxPayerIdentificationView,
  taxInfoStatus,
  taxDetails = {},
  formCompletedStatus = "",
}) => {
  const [showNumbers, setShowNumbers] = useState(false);

  const changeViewType = () => {
    setShowNumbers(!showNumbers);
  };

  return (
    <section
      className={`details__section ${
        taxInfoStatus === false ? "sectionDisable" : ""
      }`}
    >
      <h4 className="details__section--title">
        <span className="titleWithMessage">
          Taxpayer Identification Number
          {Object.keys(taxDetails).length && formCompletedStatus === "full" ? (
            <span className="successMessage">Completed</span>
          ) : (
            <span className="errorMessage">Not Completed</span>
          )}
        </span>
        {taxInfoStatus !== false ? (
          <button
            type="button"
            className="btn btn__white btn--sm"
            onClick={() => changeTaxPayerIdentificationView(true)}
          >
            <EditIcon />
          </button>
        ) : (
          ""
        )}
      </h4>
      <p>
        Please provide your tax identification Number (TIN). If you don't have a
        TIN, consult a local tax professional or your tax advisor.
      </p>
      {Object.keys(taxDetails).length ? (
        <div className="typerinfolist">
          <div className="typerinfolist__item">
            {taxDetails?.tinNumber ? (
              <>
                <h5>Tax identification number submitted</h5>
                <div className="typerinfolist__item__passwordText">
                  {showNumbers ? (
                    <>
                      <p>{decryptData(taxDetails?.tinNumber)}</p>
                      <button
                        className="btn btn__primary btn__outlined"
                        onClick={() => changeViewType()}
                      >
                        <CloseEye />
                      </button>
                    </>
                  ) : (
                    <>
                      <p>{maskSSNnumber(taxDetails?.tinNumber, "TIN")}</p>
                      <button
                        className="btn btn__primary btn__outlined"
                        onClick={() => changeViewType()}
                      >
                        <OpenEye />
                      </button>
                    </>
                  )}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          {taxDetails?.signature ? (
            <div className="typerinfolist__item">
              <h5>Signature of certificate</h5>
              <p>{taxDetails?.signature}</p>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </section>
  );
};

export default TaxPayerIdentificationNumberView;
