import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { BILLING_RECRUITER_CANDIDATE_LIST } from "@/rtk-apis/end-points";

const recruiterCandidateList = createAsyncThunk(
  "recruiterCandidateList",
  async (body) => {
    try {
      const response = await httpService.post(
        BILLING_RECRUITER_CANDIDATE_LIST,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default recruiterCandidateList;
