import React from "react";

export default function EmailIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6668 3.99984C14.6668 3.2665 14.0668 2.6665 13.3335 2.6665H2.66683C1.9335 2.6665 1.3335 3.2665 1.3335 3.99984M14.6668 3.99984V11.9998C14.6668 12.7332 14.0668 13.3332 13.3335 13.3332H2.66683C1.9335 13.3332 1.3335 12.7332 1.3335 11.9998V3.99984M14.6668 3.99984L8.00016 8.6665L1.3335 3.99984"
        stroke="#384250"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
