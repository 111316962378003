import { createSlice } from '@reduxjs/toolkit';
import candidateList from '@/rtk-apis/customer/myJobs/candidates/candidateList';

const initialState = {
  message: '',
  data: {},
  meta: {},
  token: '',
  loading: false,
  error: ''
};

const candidateListSlice = createSlice({
  name: 'candidateList',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(candidateList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(candidateList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
        state.meta = payload?.meta;
      })
      .addCase(candidateList.rejected, (state, action) => {
        state.loading = false;
      });
  }

});

export default candidateListSlice.reducer;

export const { closeRtkModal } = candidateListSlice.actions;
