import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { ADD_JOB_NOTES } from "@/rtk-apis/end-points";

const addCustomerNotes = createAsyncThunk("addCustomerNotes", async (body) => {
  try {
    const response = await httpService.post(ADD_JOB_NOTES, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default addCustomerNotes;
