import ArrowDown from "@/assets/svgs/SortingArrowDown";
import ArrowUp from "@/assets/svgs/SortingArrowUp";

const SortColumn = ({ thName, sortByASC }) => {
  return (
    <div className="sortingDiv">
      {thName}
      <span className="arrowHoverHideShow">
        {sortByASC ? <ArrowUp /> : <ArrowDown />}
      </span>
    </div>
  );
};

export default SortColumn;
