import React from "react";

function NotificationIcon() {
  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 8.40096C13.5 14.7021 16 16.5024 16 16.5024H1C1 16.5024 3.5 14.7021 3.5 8.40096C3.5 6.96853 4.02678 5.59478 4.96447 4.5819C5.90215 3.56903 7.17392 3 8.5 3M9.94167 20.103C9.79516 20.3758 9.58487 20.6023 9.33185 20.7597C9.07884 20.9171 8.79198 21 8.5 21C8.20802 21 7.92116 20.9171 7.66814 20.7597C7.41513 20.6023 7.20484 20.3758 7.05833 20.103"
        stroke="#150823"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="13.5" cy="3.5" r="3.5" fill="#F32C2C" />
    </svg>
  );
}

export default NotificationIcon;
