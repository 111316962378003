import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { CUSTOMER_ADD_EMPLOYEE } from "@/rtk-apis/end-points";

const customerAddEmployee = createAsyncThunk(
  "customerAddEmployee",
  async (body) => {
    try {
      const response = await httpService.post(CUSTOMER_ADD_EMPLOYEE, body);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default customerAddEmployee;
