import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { DELETE_BANK_INFORMATION } from '@/rtk-apis/end-points';

const deleteBankDetails = createAsyncThunk('deleteBankDetails', async (body) => {
  try {
    const response = await httpService.put(DELETE_BANK_INFORMATION, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default deleteBankDetails;
