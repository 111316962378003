import React, { useState } from "react";

function GoTOPage({ changePage }) {
  const [goToPage, setGoToPage] = useState();
  const onInputChange = (e) => {
    if (e?.target?.value && e?.target?.value != 0) {
      const value = Number(e?.target?.value.replace(/\D/g, ""));
      setGoToPage(value);
    } else {
      setGoToPage("");
    }
  };
  return (
    <>
      <h6>Go to page</h6>
      <input
        type="text"
        value={goToPage}
        className="formField__input sm"
        onChange={(e) => onInputChange(e)}
      />
      <button
        disabled={!goToPage}
        className="btn btn__primary"
        onClick={(e) => changePage(e, goToPage)}
      >
        Go
      </button>
    </>
  );
}

export default GoTOPage;
