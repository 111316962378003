import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { INTERVIEW_CREATE_MEETING } from '@/rtk-apis/end-points';

const createInterview = createAsyncThunk('createInterview', async (body) => {
  try {
    const response = await httpService.post(INTERVIEW_CREATE_MEETING, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default createInterview;
