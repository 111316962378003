import React, { useState, useEffect } from "react";
import Search from "@/components/Search";
import SearchIcon from "@/assets/svgs/SearchIcon";
import { useLocation } from "react-router-dom";
import FilterIcon from "@/assets/svgs/FilterIcon";
import Dot from "@/assets/svgs/Dot";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Loader from "@/elements/Loader";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MyJobsFilter from "@/pages/MyJobs/MyJobsFilter";
import CustomPagination from "@/elements/pagination/customPagination";
import { formattedDate, GetUserDetails } from "@/utils/helper";
import { useDispatch, useSelector } from "react-redux";
import myJobsList from "@/rtk-apis/myJobs/myJobs";

function MyJobsListing({
  selectedIndex,
  changeIndex,
  changeTab,
  setNewValue,
  setSelectedIndex,
}) {
  const user = GetUserDetails();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [value, setValue] = useState("1");
  const [showSearch, setShowSearch] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [applyFilters, setApplyFilters] = useState(false);
  const [validityStatus, setValidityStatus] = useState("All");
  const [qualification, setQualification] = useState([]);
  const [experience, setExperience] = useState([0, 99]);

  const myJobsListAPIData = () => {
    dispatch(
      myJobsList({
        recruiterId: user?.recruiter?.recruiterId,
        page: search ? 1 : page,
        limit: 20,
        status: value === "1" ? "accepted" : "rejected",
        search,
        validityStatus,
        qualification,
        experience,
      })
    );
  };

  const { data, loading } = useSelector((globalState) => {
    return globalState.myJobsListResponse;
  });

  const handleTablistChange = (event, newValue) => {
    setNewValue("1");
    setSelectedIndex(0);
    setValue(newValue);
    changeTab(newValue);
  };

  const closeSearch = () => {
    setShowSearch(false);
    setSearch("");
    setPage(1);
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    myJobsListAPIData();
  }, [search, page, value, applyFilters]);

  const apply = () => {
    setApplyFilters(!applyFilters);
    setShowFilter(false);
  };

  const clearFilters = () => {
    setValidityStatus("All");
    setQualification([]);
    setExperience([0, 99]);
    setShowFilter(false);
    setApplyFilters(!applyFilters);
  };

  useEffect(() => {
    if (state?.activeTab) {
      setValue(state.activeTab);
    }
    myJobsListAPIData();
  }, [state]);

  const filterCount = () => {
    let filterCount = 0;
    if (
      validityStatus === "Open" ||
      validityStatus === "Closed" ||
      validityStatus === "Paused" ||
      validityStatus === "Reopened"
    ) {
      filterCount += 1;
    }
    if (experience[0] !== 0 || experience[1] != 99) {
      filterCount += 1;
    }
    if (qualification.length > 0) {
      filterCount += 1;
    }
    return `(${filterCount})`;
  };

  const getJobStatus = (approvedRecruiter) => {
    const recruiterId = user?.recruiter?.recruiterId;
    let desiredRecruiter = approvedRecruiter?.find(
      (recruiter) => recruiter.recruiterId === recruiterId
    );
    const status =
      desiredRecruiter?.status === "active"
        ? "Open"
        : desiredRecruiter?.status === "paused"
        ? "Paused"
        : desiredRecruiter?.status === "reopen"
        ? "Reopen"
        : "Closed";
    return status;
  };

  return (
    <div className="layoutGrid__col">
      <div
        className={`listCol ${showFilter && "listCol__filter"} ${showSearch &&
          "listCol__search"}`}
      >
        <div className="listCol__head listing__head">
          <Search
            closeSearch={closeSearch}
            searchTerm={(value) => setSearch(value)}
          />
          <h2 className="listCol__head--title">My Jobs</h2>
          <div className="listCol__head--actions">
            <button
              onClick={() => {
                setShowSearch(true);
              }}
              className="btn btn__white p_0"
            >
              <SearchIcon />
            </button>
            {value === "1" && (
              <button
                onClick={() => setShowFilter(true)}
                className="btn btn__white p_0"
              >
                <FilterIcon />
              </button>
            )}
          </div>
        </div>

        <MyJobsFilter
          experience={experience}
          qualification={qualification}
          validityStatus={validityStatus}
          educationChange={(val) => setQualification(val)}
          validityStatusChange={(value) => setValidityStatus(value)}
          experienceChange={(value) => setExperience(value)}
          closeFilter={() => setShowFilter(false)}
          apply={() => apply()}
          clearFilters={() => clearFilters()}
        />
        {applyFilters && (
          <div className="listColTitle filterClear">
            <p>Filters applied {filterCount()}</p>
            <button className="filterClear__btn" onClick={() => clearFilters()}>
              Clear filter
            </button>
          </div>
        )}

        <Box className="tabContainer">
          <TabContext value={value}>
            <Box className="tabContainer__head m_0">
              <TabList onChange={handleTablistChange}>
                <Tab label="Accepted" value="1" />
                <Tab label="Rejected" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" className="tabContainer__item">
              <ul className="listCol__list listing__list">
                {data?.rows?.length ? (
                  data?.rows.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          selectedIndex === index
                            ? "listCol__list--item listCol__item--selected"
                            : "listCol__list--item"
                        }
                        onClick={() => changeIndex(index)}
                      >
                        <div className="item item--recruiter">
                          <div className="item__info">
                            <h4 className="item__info--title">{item?.title}</h4>
                            <div className="item__info--subtitle">
                              <p>{item?.company?.companyName}</p>
                            </div>
                            <div className="item__info--subtitle">
                              <span>{item?.department}</span>
                              <span>
                                <Dot />
                                {item?.minExperience ? (
                                  <div className="">
                                    {item?.minExperience <= 1
                                      ? item?.minExperience + " Yr"
                                      : item?.minExperience + " Yrs"}{" "}
                                    -{" "}
                                    {item?.maxExperience <= 1
                                      ? item?.maxExperience + " Yr"
                                      : item?.maxExperience + " Yrs"}
                                  </div>
                                ) : (
                                  "--"
                                )}
                              </span>
                              <span>
                                <Dot />
                                {item?.noOfHires} Positions
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              <span>
                                Application Date:{" "}
                                {formattedDate(item.applicationDeadline)}
                              </span>
                              <span className="status">
                                <Dot />
                                Status:{" "}
                                <span
                                  className={
                                    getJobStatus(item?.approvedRecruiter) ===
                                      "Open" ||
                                    getJobStatus(item?.approvedRecruiter) ===
                                      "Reopen"
                                      ? "text--primary"
                                      : getJobStatus(
                                          item?.approvedRecruiter
                                        ) === "Paused"
                                      ? "text--warning "
                                      : "text--danger"
                                  }
                                >
                                  {getJobStatus(item?.approvedRecruiter)}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <div className="no-data">No Results Found!</div>
                )}
              </ul>
            </TabPanel>
            <TabPanel value="2" className="tabContainer__item">
              <ul className="listCol__list listing__list">
                {data?.rows?.length ? (
                  data?.rows.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          selectedIndex === index
                            ? "listCol__list--item listCol__item--selected"
                            : "listCol__list--item"
                        }
                        onClick={() => changeIndex(index)}
                      >
                        <div className="item item--recruiter">
                          <div className="item__info">
                            <h4 className="item__info--title">{item?.title}</h4>
                            <div className="item__info--subtitle">
                              <p>{`${item?.company?.companyName}`}</p>
                            </div>
                            <div className="item__info--subtitle">
                              <span>{item?.department}</span>
                              <span>
                                <Dot />
                                {item?.minExperience ? (
                                  <div className="">
                                    {item?.minExperience} Years -{" "}
                                    {item?.maxExperience} Years
                                  </div>
                                ) : (
                                  "--"
                                )}
                              </span>
                              <span>
                                <Dot />
                                {item?.noOfHires} Positions
                              </span>
                            </div>
                            <div className="item__info--subtitle">
                              <span>
                                Application Date:{" "}
                                {formattedDate(item.applicationDeadline)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <div className="no-data">No Results Found!</div>
                )}
              </ul>
            </TabPanel>
            <div className="pagination pagination__center">
              {data?.rows?.length ? (
                <CustomPagination
                  page={page}
                  totalPageCount={Math.ceil(data?.count / 20)}
                  changePage={(event, value) => changePage(event, value)}
                />
              ) : (
                ""
              )}
            </div>
          </TabContext>
        </Box>
      </div>
      {loading && <Loader />}
    </div>
  );
}

export default MyJobsListing;
