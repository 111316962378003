import React, { useEffect, useState } from "react";
import logo from "@/assets/images/logo.svg";
import { Formik, Form, Field } from "formik";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import PlusIcon from "@/assets/svgs/PlusIcon";
import { registerStepTwoSchema } from "@/components/schema/schema";
import staticData from "@/rtk-apis/staticData";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ArrowDown from "@/assets/svgs/ArrowDown";
import Select from "@mui/material/Select";
import DeleteIcon from "@/assets/svgs/DeleteIcon";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import { getYears } from "@/utils/helper";
import { LinkedInRegex } from "@/utils/regex";
import * as CONSTANTS from "@/utils/constants";
import UniversitySvg from "@/assets/svgs/UniversitySvg";
import { INSTITUTION_LOGO_BUCKET } from "@/utils/constants";

function SignUpStepTwo(props) {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    education: "",
    institutionName: "",
    majorStudy: "",
    skillSet: [],
    experience: "",
    linkedin: "",
    graduationYear: "",
    qualificationArray: [
      {
        institutionName: "",
        degree: "",
        fieldOfStudy: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
      },
    ],
  });
  const [formError, setFormError] = useState([]);

  const { data } = useSelector((globalState) => {
    return globalState.static;
  });

  useEffect(() => {
    let initialValuesCopy = initialValues;
    initialValuesCopy.education = props?.data?.education
      ? props?.data?.education
      : "";
    initialValuesCopy.institutionName = props?.data?.institutionName
      ? props?.data?.institutionName
      : "";
    initialValuesCopy.majorStudy = props?.data?.majorStudy
      ? props?.data?.majorStudy
      : "";
    initialValuesCopy.experience = props?.data?.experience
      ? props?.data?.experience
      : "";
    initialValuesCopy.linkedin = props?.data?.linkedin
      ? props?.data?.linkedin
      : "";
    initialValuesCopy.graduationYear = props?.data?.graduationYear
      ? props?.data?.graduationYear
      : "";
    initialValuesCopy.skillSet =
      props?.data?.skillSet?.length > 0 ? props?.data?.skillSet : [];
    initialValuesCopy.qualificationArray =
      props?.data?.qualificationArray?.length > 0
        ? props?.data?.qualificationArray
        : [
            {
              institutionName: "",
              degree: "",
              fieldOfStudy: "",
              startMonth: "",
              startYear: "",
              endMonth: "",
              endYear: "",
            },
          ];
    setInitialValues(initialValuesCopy);
  }, [props?.data]);

  useEffect(() => {
    dispatch(staticData());
  }, []);

  const finishSignUp = (values) => {
    localStorage.removeItem("signupSteponeValues");
    values = { ...values, graduationYear: values.graduationYear.toString() };
    props.finishSignUp(values);
  };

  const handleEduDetailsChange = (
    index,
    field,
    newValue,
    valuesQualificationArray
  ) => {
    let qualificationArray = JSON.parse(
      JSON.stringify(valuesQualificationArray)
    );
    if (field == "institutionName") {
      qualificationArray[index]["logoName"] = getUnivercityLogo(newValue);
    }
    qualificationArray[index][field] = newValue;
    return qualificationArray;
  };

  const addEducationalDetails = (valuesQualificationArray) => {
    let qualificationArray = JSON.parse(
      JSON.stringify(valuesQualificationArray)
    );
    qualificationArray.splice(0, 0, {
      institutionName: "",
      degree: "",
      fieldOfStudy: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
    });
    return qualificationArray;
  };

  const deleteEducationalDetail = (index, valuesQualificationArray) => {
    let qualificationArray = JSON.parse(
      JSON.stringify(valuesQualificationArray)
    );
    qualificationArray.splice(index, 1);
    return qualificationArray;
  };

  function isEmpty(arr) {
    let arrIndex = 0;
    let status = true;
    let errors = [];
    for (const item of arr?.qualificationArray) {
      arrIndex++;
      for (const value in item) {
        if (!item[value]) {
          if (value == "degree") {
            errors[
              `qualificationArray${arrIndex - 1}degree`
            ] = `Education name is required`;
          } else if (value == "institutionName") {
            errors[
              `qualificationArray${arrIndex - 1}institutionName`
            ] = `Institution name is required`;
          } else if (value == "fieldOfStudy") {
            errors[
              `qualificationArray${arrIndex - 1}fieldOfStudy`
            ] = `Major/Field of study is required`;
          } else if (value == "startMonth") {
            errors[
              `qualificationArray${arrIndex - 1}startMonth`
            ] = `Start month is required`;
          } else if (value == "startYear") {
            errors[
              `qualificationArray${arrIndex - 1}startYear`
            ] = `Start year is required`;
          } else if (value == "endMonth") {
            errors[
              `qualificationArray${arrIndex - 1}endMonth`
            ] = `End month is required`;
          } else if (value == "endYear") {
            errors[
              `qualificationArray${arrIndex - 1}endYear`
            ] = `End year is required`;
          } else {
            errors[
              `qualificationArray${arrIndex - 1}${value}`
            ] = `${value} is required`;
          }
          status = false;
        }
      }
      if (
        item["startMonth"] &&
        item["startYear"] &&
        item["endMonth"] &&
        item["endYear"]
      ) {
        if (item["startYear"] === item["endYear"]) {
          let startMonthIndex = item["startMonth"]
            ? data?.yearMonth?.month?.indexOf(item["startMonth"]) + 1
            : "";
          let endMonthIndex = item["endMonth"]
            ? data?.yearMonth?.month?.indexOf(item["endMonth"]) + 1
            : "";
          if (
            startMonthIndex &&
            endMonthIndex &&
            startMonthIndex > endMonthIndex
          ) {
            errors[`qualificationArray${arrIndex - 1}endYear`] =
              "The start date cannot be later than the end date.";
            status = false;
          }
        }
      }
    }
    if (!arr?.skillSet?.length) {
      errors["skillSet"] = CONSTANTS.SELECTED_SKILL_SET.validation.valid;
      status = false;
    }
    if (
      !arr?.experience ||
      (arr?.experience && arr?.experience > 99) ||
      (arr?.experience && arr?.experience < 1)
    ) {
      errors["experience"] = CONSTANTS.EXPERIENCE.validation.valid;
      status = false;
    }
    if (arr?.linkedin) {
      if (!LinkedInRegex.test(arr?.linkedin)) {
        errors["linkedin"] = CONSTANTS.LINKEDIN.validation.valid;
        status = false;
      }
    }
    setFormError(errors);
    return status;
  }

  const getUnivercityLogo = (univercityName) => {
    let logo =
      data?.nameOfInstitutionObject?.length &&
      data?.nameOfInstitutionObject.find(
        (company) => company.value === univercityName
      )?.logoName;
    return logo;
  };

  return (
    <div>
      <section className="signup signup--stepTwo">
        <div className="signup__item signup__item--form">
          <Formik
            initialValues={initialValues}
            //validationSchema={registerStepTwoSchema}
            onSubmit={(values) => {
              const status = isEmpty(values);
              if (!status) {
                return false;
              }
              finishSignUp(values);
            }}
          >
            {({
              errors,
              values,
              handleBlur,
              touched,
              setFieldValue,
              setFieldTouched,
              setErrors,
            }) => (
              <Form>
                <div className="branding branding--flex">
                  <div className="branding__logo">
                    <img
                      src={logo}
                      alt="Logo | Laila"
                      className="branding__logo__img"
                    />
                  </div>
                  <div className="branding__info branding__info--withbutton">
                    {" "}
                    Step 2 of 2{" "}
                    <button
                      type="button"
                      className="btn btn__white"
                      onClick={() => {
                        props.goBack();
                      }}
                    >
                      Go Back
                    </button>
                  </div>
                </div>
                <div className="formRow">
                  <div className="formRow__boxSignup">
                    <h4 className="titleForm titleForm--flex">
                      <span>
                        Educational Details <sup className="asterisk">*</sup>
                      </span>
                      <button
                        type="button"
                        onClick={() => {
                          let newArray = addEducationalDetails(
                            values?.qualificationArray
                          );
                          setFieldValue("qualificationArray", newArray);
                          const newErrors = errors;
                          newErrors[`qualificationArray0degree`] =
                            "Education is required";
                          newErrors[`qualificationArray0institutionName`] =
                            "Institution name is required";
                          newErrors[`qualificationArray0majorStudy`] =
                            "Major/Field of study is required";
                          newErrors[`qualificationArray0startMonth`] =
                            "Start month is required";
                          newErrors[`qualificationArray0startYear`] =
                            "Start Year is required";
                          newErrors[`qualificationArray0endMonth`] =
                            "End month is required";
                          newErrors[`qualificationArray0endYear`] =
                            "End year is required";
                          setErrors(newErrors);
                        }}
                        className="btn btn__white btn--sm"
                      >
                        <PlusIcon />
                      </button>
                    </h4>
                    <div className="form__sectionlist">
                      {values?.qualificationArray?.map((item, index) => (
                        <div className="form__section">
                          <div className="fieldBox fieldBox--row">
                            <div className="flexCol">
                              <div
                                className={`formField formField__mb5 ${
                                  errors[`qualificationArray${index}degree`] ||
                                  formError[`qualificationArray${index}degree`]
                                    ? "fieldError"
                                    : ""
                                }`}
                              >
                                <label className="formField__label">
                                  Education
                                </label>
                                <Autocomplete
                                  id="tags-filled"
                                  options={data?.qualification}
                                  value={item?.degree}
                                  name={`qualificationArray${index}degree`}
                                  onChange={(event, newValue) => {
                                    const newErrors = errors;
                                    const formNewErrors = formError;
                                    const newArray = handleEduDetailsChange(
                                      index,
                                      "degree",
                                      newValue,
                                      values?.qualificationArray
                                    );
                                    setFieldValue(
                                      "qualificationArray",
                                      newArray
                                    );
                                    if (!newValue) {
                                      newErrors[
                                        `qualificationArray${index}degree`
                                      ] = "Education is required";
                                      setErrors(newErrors);
                                    } else {
                                      delete newErrors[
                                        `qualificationArray${index}degree`
                                      ];
                                      setErrors(newErrors);
                                      delete formNewErrors[
                                        `qualificationArray${index}degree`
                                      ];
                                      setFormError(formNewErrors);
                                    }
                                  }}
                                  freeSolo
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="filled"
                                      placeholder="Select"
                                      className="autoCompleteInput"
                                      onBlur={(e) => {
                                        if (params?.inputProps?.value) {
                                          const newArray = handleEduDetailsChange(
                                            index,
                                            "degree",
                                            params.inputProps.value,
                                            values?.qualificationArray
                                          );
                                          setFieldValue(
                                            "qualificationArray",
                                            newArray
                                          );
                                        }
                                        if (!params?.inputProps?.value) {
                                          const newErrors = errors;
                                          newErrors[
                                            `qualificationArray${index}degree`
                                          ] = "Education is required";
                                          setErrors(newErrors);
                                        }
                                      }}
                                    />
                                  )}
                                />
                                <span className="formField__error">
                                  {errors[`qualificationArray${index}degree`] ||
                                    formError[
                                      `qualificationArray${index}degree`
                                    ]}
                                </span>
                              </div>
                            </div>
                            <div className="flexCol">
                              <div
                                className={`formField__autoComplete formField__autoComplete--withLogo formField formField__mb5 ${
                                  errors[
                                    `qualificationArray${index}institutionName`
                                  ] ||
                                  formError[
                                    `qualificationArray${index}institutionName`
                                  ]
                                    ? "fieldError"
                                    : ""
                                }`}
                              >
                                <label className="formField__label">
                                  Name of Institution
                                </label>
                                <Autocomplete
                                  id="tags-filled"
                                  options={data?.nameOfInstitutionObject}
                                  getOptionLabel={(option) =>
                                    typeof option === "object"
                                      ? option?.value
                                      : option
                                      ? option
                                      : ""
                                  }
                                  value={item?.institutionName}
                                  name="institutionName"
                                  onChange={(event, newValue) => {
                                    const newErrors = errors;
                                    const formNewErrors = formError;
                                    const newArray = handleEduDetailsChange(
                                      index,
                                      "institutionName",
                                      newValue,
                                      values?.qualificationArray
                                    );
                                    setFieldValue(
                                      "qualificationArray",
                                      newArray
                                    );
                                    if (!newValue) {
                                      newErrors[
                                        `qualificationArray${index}institutionName`
                                      ] = "Institution name is required";
                                      setErrors(newErrors);
                                    } else {
                                      delete newErrors[
                                        `qualificationArray${index}institutionName`
                                      ];
                                      setErrors(newErrors);
                                      delete formNewErrors[
                                        `qualificationArray${index}institutionName`
                                      ];
                                      setFormError(formNewErrors);
                                    }
                                  }}
                                  freeSolo
                                  renderOption={(props, option) => (
                                    <li {...props}>
                                      {option.logoName ? (
                                        <img
                                          src={
                                            INSTITUTION_LOGO_BUCKET +
                                            option.logoName
                                          }
                                          alt={option.value}
                                          style={{
                                            width: 25,
                                            marginRight: 5,
                                          }}
                                        />
                                      ) : (
                                        <div className="autocompletelogo">
                                          <UniversitySvg />
                                        </div>
                                      )}
                                      {option?.value || ""}{" "}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="filled"
                                      placeholder="Select"
                                      className="autoCompleteInput"
                                      InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                          <>
                                            {params.InputProps.startAdornment}
                                            {params.inputProps.value &&
                                            getUnivercityLogo(
                                              params.inputProps.value
                                            ) ? (
                                              <img
                                                className="autocompletelogo"
                                                src={
                                                  params.inputProps.value &&
                                                  INSTITUTION_LOGO_BUCKET +
                                                    getUnivercityLogo(
                                                      params.inputProps.value
                                                    )
                                                }
                                                alt="Selected"
                                              />
                                            ) : (
                                              <div className="autocompletelogo">
                                                <UniversitySvg />
                                              </div>
                                            )}
                                          </>
                                        ),
                                      }}
                                      onBlur={(e) => {
                                        const newArray = handleEduDetailsChange(
                                          index,
                                          "institutionName",
                                          params?.inputProps?.value,
                                          values?.qualificationArray
                                        );
                                        setFieldValue(
                                          "qualificationArray",
                                          newArray
                                        );
                                        if (!params?.inputProps?.value) {
                                          const newErrors = errors;
                                          newErrors[
                                            `qualificationArray${index}institutionName`
                                          ] = "Institution name is required";
                                          setErrors(newErrors);
                                        }
                                      }}
                                    />
                                  )}
                                />
                                <span className="formField__error">
                                  {errors[
                                    `qualificationArray${index}institutionName`
                                  ] ||
                                    formError[
                                      `qualificationArray${index}institutionName`
                                    ]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="formRow formRow__4">
                            <div
                              className={`formField ${
                                errors[
                                  `qualificationArray${index}startMonth`
                                ] ||
                                formError[
                                  `qualificationArray${index}startMonth`
                                ]
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                Start Month
                              </label>
                              <FormControl
                                sx={{ width: "100%" }}
                                size="small"
                                className="formField__select"
                              >
                                <Select
                                  name={`qualificationArray${index}startMonth`}
                                  onChange={(event) => {
                                    const newArray = handleEduDetailsChange(
                                      index,
                                      "startMonth",
                                      event?.target?.value,
                                      values?.qualificationArray
                                    );
                                    setFieldValue(
                                      "qualificationArray",
                                      newArray
                                    );
                                    if (event?.target?.value) {
                                      const newErrors = errors;
                                      delete newErrors[
                                        `qualificationArray${index}startMonth`
                                      ];
                                      setErrors(newErrors);

                                      const newFormError = formError;
                                      delete newFormError[
                                        `qualificationArray${index}startMonth`
                                      ];
                                      setFormError(newFormError);
                                    }
                                  }}
                                  IconComponent={() => <ArrowDown />}
                                  displayEmpty
                                  value={item?.startMonth}
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <span style={{ opacity: "0.8" }}>
                                          Select Month
                                        </span>
                                      );
                                    }

                                    return selected;
                                  }}
                                >
                                  {data?.yearMonth?.month.map((item) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))}
                                </Select>
                                <span className="formField__error">
                                  {errors[
                                    `qualificationArray${index}startMonth`
                                  ] ||
                                    formError[
                                      `qualificationArray${index}startMonth`
                                    ]}
                                </span>
                              </FormControl>
                            </div>
                            <div
                              className={`formField ${
                                errors[`qualificationArray${index}startYear`] ||
                                formError[`qualificationArray${index}startYear`]
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                Start Year
                              </label>
                              <FormControl
                                sx={{ width: "100%" }}
                                size="small"
                                className="formField__select"
                              >
                                <Select
                                  onChange={(event) => {
                                    const newArray = handleEduDetailsChange(
                                      index,
                                      "startYear",
                                      event?.target?.value,
                                      values?.qualificationArray
                                    );
                                    setFieldValue(
                                      "qualificationArray",
                                      newArray
                                    );
                                    if (event?.target?.value) {
                                      const newErrors = errors;
                                      delete newErrors[
                                        `qualificationArray${index}startYear`
                                      ];
                                      setErrors(newErrors);

                                      const newFormError = formError;
                                      delete newFormError[
                                        `qualificationArray${index}startYear`
                                      ];
                                      setFormError(newFormError);
                                    }
                                  }}
                                  IconComponent={() => <ArrowDown />}
                                  displayEmpty
                                  value={item?.startYear}
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <span style={{ opacity: "0.8" }}>
                                          Select Year
                                        </span>
                                      );
                                    }

                                    return selected;
                                  }}
                                >
                                  {getYears(data?.yearMonth?.startYear).map(
                                    (item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                              <span className="formField__error">
                                {errors[
                                  `qualificationArray${index}startYear`
                                ] ||
                                  formError[
                                    `qualificationArray${index}startYear`
                                  ]}
                              </span>
                            </div>
                            <div
                              className={`formField ${
                                errors[`qualificationArray${index}endMonth`] ||
                                formError[`qualificationArray${index}endMonth`]
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                End Month
                              </label>
                              <FormControl
                                sx={{ width: "100%" }}
                                size="small"
                                className="formField__select"
                              >
                                <Select
                                  onChange={(event) => {
                                    const newArray = handleEduDetailsChange(
                                      index,
                                      "endMonth",
                                      event?.target?.value,
                                      values?.qualificationArray
                                    );
                                    setFieldValue(
                                      "qualificationArray",
                                      newArray
                                    );
                                    if (event?.target?.value) {
                                      const newErrors = errors;
                                      delete newErrors[
                                        `qualificationArray${index}endMonth`
                                      ];
                                      setErrors(newErrors);

                                      const newFormError = formError;
                                      delete newFormError[
                                        `qualificationArray${index}endMonth`
                                      ];
                                      setFormError(newFormError);
                                    }
                                  }}
                                  IconComponent={() => <ArrowDown />}
                                  displayEmpty
                                  value={item?.endMonth}
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <span style={{ opacity: "0.8" }}>
                                          Select Month
                                        </span>
                                      );
                                    }
                                    return selected;
                                  }}
                                >
                                  {data?.yearMonth?.month.map((item) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <span className="formField__error">
                                {errors[`qualificationArray${index}endMonth`] ||
                                  formError[
                                    `qualificationArray${index}endMonth`
                                  ]}
                              </span>
                            </div>
                            <div
                              className={`formField ${
                                errors[`qualificationArray${index}endYear`] ||
                                formError[`qualificationArray${index}endYear`]
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                End Year
                              </label>
                              <FormControl
                                sx={{ width: "100%" }}
                                size="small"
                                className="formField__select"
                              >
                                <Select
                                  onChange={(event) => {
                                    const newArray = handleEduDetailsChange(
                                      index,
                                      "endYear",
                                      event?.target?.value,
                                      values?.qualificationArray
                                    );
                                    setFieldValue(
                                      "qualificationArray",
                                      newArray
                                    );
                                    if (event?.target?.value) {
                                      const newErrors = errors;
                                      delete newErrors[
                                        `qualificationArray${index}endYear`
                                      ];
                                      setErrors(newErrors);

                                      const newFormError = formError;
                                      delete newFormError[
                                        `qualificationArray${index}endYear`
                                      ];
                                      setFormError(newFormError);
                                    }
                                  }}
                                  IconComponent={() => <ArrowDown />}
                                  displayEmpty
                                  value={item?.endYear}
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <span style={{ opacity: "0.8" }}>
                                          Select Year
                                        </span>
                                      );
                                    }

                                    return selected;
                                  }}
                                >
                                  {getYears(data?.yearMonth?.startYear).map(
                                    (item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                              <span className="formField__error">
                                {errors[`qualificationArray${index}endYear`] ||
                                  formError[
                                    `qualificationArray${index}endYear`
                                  ]}
                              </span>
                            </div>
                          </div>
                          <div
                            className={`formField formField__mb5 ${
                              errors[`qualificationArray${index}majorStudy`] ||
                              formError[
                                `qualificationArray${index}fieldOfStudy`
                              ]
                                ? "fieldError"
                                : ""
                            }`}
                          >
                            <label className="formField__label">
                              Major/Field of Study
                            </label>
                            <Autocomplete
                              id="tags-filled"
                              options={data?.majorFieldOfStudy}
                              value={item?.fieldOfStudy}
                              name="majorStudy"
                              onChange={(event, newValue) => {
                                const newArray = handleEduDetailsChange(
                                  index,
                                  "fieldOfStudy",
                                  newValue,
                                  values?.qualificationArray
                                );
                                setFieldValue("qualificationArray", newArray);
                                if (!newValue) {
                                  const newErrors = errors;
                                  newErrors[
                                    `qualificationArray${index}majorStudy`
                                  ] = "Major/Field of Study is required";
                                  setErrors(newErrors);
                                } else {
                                  const newErrors = errors;
                                  delete newErrors[
                                    `qualificationArray${index}majorStudy`
                                  ];
                                  setErrors(newErrors);

                                  const newFormError = formError;
                                  delete newFormError[
                                    `qualificationArray${index}fieldOfStudy`
                                  ];
                                  setFormError(newFormError);
                                }
                              }}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  placeholder="Select"
                                  className="autoCompleteInput"
                                  onBlur={(e) => {
                                    const newArray = handleEduDetailsChange(
                                      index,
                                      "fieldOfStudy",
                                      params?.inputProps?.value,
                                      values?.qualificationArray
                                    );
                                    setFieldValue(
                                      "qualificationArray",
                                      newArray
                                    );
                                    if (!params?.inputProps?.value) {
                                      const newErrors = errors;
                                      newErrors[
                                        `qualificationArray${index}majorStudy`
                                      ] = "Major/Field of Study is required";
                                      setErrors(newErrors);
                                    }
                                  }}
                                />
                              )}
                            />
                            <span className="formField__error">
                              {errors[`qualificationArray${index}majorStudy`] ||
                                formError[
                                  `qualificationArray${index}fieldOfStudy`
                                ]}
                            </span>
                          </div>
                          <div className="fieldBox fieldBox__signupDelete">
                            {values?.qualificationArray?.length > 1 && (
                              <button
                                type="button"
                                className="btn btn__danger btn--sm"
                                onClick={() => {
                                  const newArray = deleteEducationalDetail(
                                    index,
                                    values?.qualificationArray
                                  );
                                  setFieldValue("qualificationArray", newArray);
                                }}
                              >
                                <DeleteIcon />
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="formRow__boxSignup">
                    <h4 className="titleForm">Professional Details</h4>
                    <div className="form__sectionlist">
                      <div className="form__section">
                        <div className="fieldBox fieldBox--row">
                          <div className="flexCol">
                            <div className={`formField formField__mb5`}>
                              <label className="formField__label">
                                Recruiting Fields
                              </label>
                              <Autocomplete
                                style={
                                  errors?.skillSet || formError[`skillSet`]
                                    ? {
                                        border: "1px solid #F04438",
                                        borderRadius: "6px",
                                      }
                                    : {}
                                }
                                multiple
                                id="tags-filled"
                                options={data?.skillSet}
                                defaultValue={values?.skillSet}
                                value={values?.skillSet}
                                name="skillSet"
                                onChange={(event, newValue) => {
                                  setFieldValue("skillSet", newValue);
                                }}
                                onBlur={() => setFieldTouched("skillSet", true)}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="filled"
                                    placeholder="Select"
                                    className="autoCompleteInput"
                                  />
                                )}
                              />
                              <span className="formField__error">
                                {errors?.skillSet &&
                                  touched?.skillSet &&
                                  errors?.skillSet}{" "}
                                {formError[`skillSet`]}
                              </span>
                            </div>
                          </div>
                          <div className="flexCol">
                            <div
                              className={`formField formField__mb5 ${
                                (errors?.experience && touched?.experience) ||
                                formError[`experience`]
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                Years of experience
                              </label>
                              <Field
                                name="experience"
                                type="number"
                                className="formField__input fieldMin sm"
                                placeholder="Experience"
                                onBlur={handleBlur}
                              />
                              <span className="formField__error">
                                {(errors?.experience &&
                                  touched?.experience &&
                                  errors?.experience) ||
                                  formError[`experience`]}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`formField ${
                            (errors?.linkedin && touched?.linkedin) ||
                            formError[`linkedin`]
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            LinkedIn Account
                          </label>
                          <div className="formField__group sm">
                            <Field
                              type="text"
                              className="formField__input"
                              onBlur={handleBlur}
                              placeholder=""
                              name="linkedin"
                            />
                          </div>
                          <span className="formField__error">
                            {(errors?.linkedin &&
                              touched?.linkedin &&
                              errors?.linkedin) ||
                              formError[`linkedin`]}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="formField formField__mb5 ">
                  <button
                    type="submit"
                    className="btn btn__primary btn--xl w_100"
                  >
                    Finish
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </div>
  );
}

export default SignUpStepTwo;
