import { createSlice } from "@reduxjs/toolkit";
import customerEditEmployee from "@/rtk-apis/customer/companyProfile/customerEditEmployee";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const customerEditEmployeeSlice = createSlice({
  name: "customerEditEmployeeSlice",
  initialState,
  reducers: {
    closeEditEmployeeModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customerEditEmployee.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(customerEditEmployee.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(customerEditEmployee.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default customerEditEmployeeSlice.reducer;

export const { closeEditEmployeeModal } = customerEditEmployeeSlice.actions;
