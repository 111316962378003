import React from 'react';

export default function DeactivateIcon() {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6364 17.7274V15.9092C14.6364 14.9448 14.2532 14.0199 13.5713 13.3379C12.8893 12.656 11.9644 12.2729 11 12.2729H4.63636C3.67194 12.2729 2.74702 12.656 2.06507 13.3379C1.38312 14.0199 1 14.9448 1 15.9092V17.7274M11.4545 5.00013C11.4545 7.00844 9.82649 8.6365 7.81818 8.6365C5.80987 8.6365 4.18182 7.00844 4.18182 5.00013C4.18182 2.99182 5.80987 1.36377 7.81818 1.36377C9.82649 1.36377 11.4545 2.99182 11.4545 5.00013Z"
        stroke="#F04438"
        strokeWidth="1.81818"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6364 5.20308H16.4444M16.4444 5.20308H22.9091M16.4444 5.20308V10.8596C16.4444 11.074 16.5296 11.2795 16.6811 11.431C16.8327 11.5826 17.0382 11.6677 17.2525 11.6677H21.2929C21.5072 11.6677 21.7128 11.5826 21.8643 11.431C22.0159 11.2795 22.101 11.074 22.101 10.8596V5.20308H16.4444ZM17.6566 5.20308V4.39499C17.6566 4.18068 17.7417 3.97514 17.8932 3.8236C18.0448 3.67205 18.2503 3.58691 18.4646 3.58691H20.0808C20.2951 3.58691 20.5007 3.67205 20.6522 3.8236C20.8037 3.97514 20.8889 4.18068 20.8889 4.39499V5.20308M18.4646 7.22328V9.64752M20.0808 7.22328V9.64752"
        stroke="#F04438"
        strokeWidth="0.808081"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
