import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import SearchSmallIcon from "@/assets/svgs/SearchSmallIcon";
import Approve from "@/assets/svgs/Approve";
import Reject from "@/assets/svgs/Reject";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import FeedbackModal from "@/components/modals/feedback-modal";
import MeetingStatus from "@/components/HOCs/MeetingStatus";
import {
  GetUserDetails,
  formatPhoneNumber,
  firstLetterCapital,
} from "@/utils/helper";
import scheduledInterviews from "@/rtk-apis/myJobs/scheduledInterviews";
import candidateApproveReject from "@/rtk-apis/myJobs/candidateApproveReject";
import CustomPagination from "@/elements/pagination/customPagination";
import { useSelector, useDispatch } from "react-redux";
import Loader from "@/elements/Loader";
import { closeRtkModal } from "@/store/slices/myJobs/candidateApproveRejectSlice";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import fetchRecording from "@/rtk-apis/interviews/fetchRecording";
import { closeRtkModal as closeRtkModalInterview } from "@/store/slices/interviews/fetchRecordingSlice";
import SortColumn from "@/elements/SortColumn";

function ScheduledInterviewsListing({
  selectedJob,
  showDetails,
  openFeedbackPage,
}) {
  const user = GetUserDetails();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [btnName, setBtnName] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [recordingChecked, setRecordingChecked] = useState(false);
  const [sortByASC, setSortByASC] = useState(true);
  const [sortByColoumn, setSortByColoumn] = useState("");
  const [JobStatus, setJobStatus] = useState("");

  const { data, loading } = useSelector((globalState) => {
    return globalState.scheduledInterviewsResponse;
  });

  const candidateApproveRejectResponse = useSelector((globalState) => {
    return globalState.candidateApproveRejectResponse;
  });

  const interviewRecordingData = useSelector((globalState) => {
    return globalState.fetchRecordingResponse;
  });

  useEffect(() => {
    scheduledInterviewsAPIData();
  }, [search, page, sortByASC, sortByColoumn]);

  const scheduledInterviewsAPIData = () => {
    dispatch(
      scheduledInterviews({
        jobId: selectedJob?.jobId,
        recruiterId: user?.recruiter?.recruiterId,
        page: search ? 1 : page,
        limit: 20,
        search,
        columnName: sortByColoumn,
        sort: sortByASC ? "ASC" : "DESC",
      })
    );
  };

  const getSortByData = (columnName) => {
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
  };

  const closeModal = () => {
    dispatch(closeRtkModal());
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  const withFeedback = (row) => {
    showDetails(row);
    openFeedbackPage(btnName);
    dispatch(closeRtkModalInterview());
  };

  const withoutFeedback = (row) => {
    if (btnName == "Approve") {
      approveCandidateInterview(row);
    } else {
      rejectCandidateInterview(row);
    }
    dispatch(closeRtkModalInterview());
  };

  const approveCandidateInterview = async (row) => {
    setOpenFeedbackModal(false);
    await dispatch(
      candidateApproveReject({
        interviewId: row.interviewId,
        status: "approved",
        feedback: [],
      })
    );
    scheduledInterviewsAPIData();
  };

  const rejectCandidateInterview = async (row) => {
    setOpenConfirmationModal(false);
    await dispatch(
      candidateApproveReject({
        interviewId: row.interviewId,
        status: "rejected",
        feedback: [],
      })
    );
    scheduledInterviewsAPIData();
  };

  useEffect(() => {
    if (interviewRecordingData?.meta?.status && recordingChecked) {
      if (interviewRecordingData?.data?.recordings?.length) {
        setOpenFeedbackModal(true);
      } else {
        setShowErrorModal(true);
      }
    }
  }, [interviewRecordingData?.data]);

  const checkRecordingExist = (interview) => {
    setRecordingChecked(true);
    dispatch(
      fetchRecording({
        interviewId: interview.interviewId,
      })
    );
  };

  useEffect(() => {
    getJobStatus(selectedJob);
  }, [selectedJob]);

  const getJobStatus = (JobData) => {
    const recruiterId = user?.recruiter?.recruiterId;
    let desiredRecruiter = JobData?.approvedRecruiter?.find(
      (recruiter) => recruiter.recruiterId === recruiterId
    );
    const status =
      desiredRecruiter?.status === "active"
        ? "open"
        : desiredRecruiter?.status === "paused"
        ? "paused"
        : desiredRecruiter?.status === "reopen"
        ? "reopen"
        : "closed";
    setJobStatus(status);
  };

  return (
    <>
      <div>
        <div className="tabContainer__item--head">
          <div className="listCol__head--actions w_100">
            <div className="formRow formRow__4 m_0 w_100">
              <div className="formCol">
                <div className="formField">
                  <div className="formField__group sm">
                    <span className="formField__icon">
                      <SearchSmallIcon />
                    </span>
                    <input
                      type="text"
                      className="formField__input"
                      placeholder="Search a candidate"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TableContainer
          component={Paper}
          className="tableContainer"
          style={{ height: "calc(100dvh - 249px)" }}
        >
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell>S.</TableCell>
                <TableCell onClick={() => getSortByData("firstName")}>
                  <SortColumn thName={"Name"} sortByASC={sortByASC} />
                </TableCell>
                <TableCell onClick={() => getSortByData("email")}>
                  <SortColumn thName={"Email"} sortByASC={sortByASC} />
                </TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Interview</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.rows?.length ? (
                data?.rows.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {page !== 1 ? (page - 1) * 20 + (index + 1) : index + 1}
                      </TableCell>
                      <TableCell>
                        <div className="profile">
                          <div className="profile__initials">
                            {firstLetterCapital(row?.candidate?.firstName[0])}
                          </div>
                          <span className="profile__name">
                            {firstLetterCapital(row?.candidate?.firstName) +
                              " " +
                              firstLetterCapital(row?.candidate?.lastName)}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <span className="cellText__dark">
                            {row?.candidate?.email}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <span className="cellText__dark">
                            {row?.candidate?.countryCode}{" "}
                            {formatPhoneNumber(row?.candidate?.phoneNumber)}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <MeetingStatus time={row.meeting_end_time} />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <button
                            onClick={() => showDetails(row)}
                            className="cellText__dark cell--link"
                          >
                            View Details
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        {row?.recruiterStatus === "pending" && (
                          <div className="actions">
                            <Tooltip title="Approve" placement="top" arrow>
                              <span>
                                <button
                                  onClick={() => {
                                    setBtnName("Approve");
                                    setSelectedRow(row);
                                    checkRecordingExist(row);
                                  }}
                                  disabled={
                                    JobStatus === "closed" ||
                                    selectedJob?.status === "paused"
                                      ? true
                                      : false
                                  }
                                  className={`btn btn__transparent p_0 border_0 ${
                                    JobStatus === "closed" ||
                                    JobStatus === "paused"
                                      ? "disabled"
                                      : ""
                                  }`}
                                >
                                  <Approve />
                                </button>
                              </span>
                            </Tooltip>

                            <Tooltip title="Reject" placement="top" arrow>
                              <span>
                                <button
                                  onClick={() => {
                                    setBtnName("Reject");
                                    setSelectedRow(row);
                                    checkRecordingExist(row);
                                  }}
                                  disabled={
                                    selectedJob?.status === "Closed" ||
                                    selectedJob?.status === "paused"
                                      ? true
                                      : false
                                  }
                                  className={`btn btn__transparent p_0 border_0 ${
                                    selectedJob?.status === "Closed" ||
                                    selectedJob?.status === "paused"
                                      ? "disabled"
                                      : ""
                                  }`}
                                >
                                  <Reject />
                                </button>
                              </span>
                            </Tooltip>
                          </div>
                        )}
                        {openConfirmationModal && (
                          <ConfirmationModal
                            closeModal={() => setOpenConfirmationModal(false)}
                            onSubmit={() => rejectCandidateInterview(row)}
                            heading={"Reject Candidate!"}
                            confirmationMessage={
                              "Are you sure want to reject candidate?"
                            }
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <div className="no-data floating">No Results Found!</div>
              )}
              {openFeedbackModal && (
                <FeedbackModal
                  title={btnName}
                  withoutFeedback={() => withoutFeedback(selectedRow)}
                  withFeedback={() => withFeedback(selectedRow)}
                  closeModal={() => {
                    dispatch(closeRtkModalInterview());
                    setOpenFeedbackModal(false);
                  }}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="pagination pagination__center">
          {data?.rows?.length ? (
            <CustomPagination
              page={page}
              totalPageCount={Math.ceil(data?.count / 20)}
              changePage={(event, value) => changePage(event, value)}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {candidateApproveRejectResponse?.meta?.msg && (
        <SuccessModal
          closeModal={closeModal}
          sucessfullMsg={candidateApproveRejectResponse?.meta?.msg}
          Heading={
            btnName == "Approve" ? "Candidate approved!" : "Candidate rejected!"
          }
        />
      )}
      {(loading ||
        candidateApproveRejectResponse?.loading ||
        interviewRecordingData?.loading) && <Loader />}
      {showErrorModal && (
        <ErrorModal
          errorMessage={"Interview recording not available."}
          footerVisible={true}
          closeModal={() => {
            dispatch(closeRtkModalInterview());
            setShowErrorModal(false);
          }}
        />
      )}
    </>
  );
}

export default ScheduledInterviewsListing;
