import AuthImage from "@/assets/images/auth-img.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import KeyLockSvg from "@/assets/svgs/KeyLockSvg";
import LockSvg from "@/assets/svgs/LockSvg";
import { forgotPasswordSchema } from "@/components/schema/schema";
import { Formik, Form, Field } from "formik";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import forgotPassword from "@/rtk-apis/forgotPassword";
import { closeRtkModal } from "@/store/slices/ForgotPasswordSlice";
import ErrorModal from "@/components/modals/error-modal";
import Loader from "@/elements/Loader";
import { SuccessModal } from "@/components/modals/SuccessModal";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let initialValues = {
    email: "",
  };

  const closeModal = () => {
    dispatch(closeRtkModal());
    navigate("/signin");
  };

  const { loading, meta } = useSelector((globalState) => {
    return globalState.forgotPassword;
  });

  const handleSubmit = (values) => {
    dispatch(forgotPassword({ email: values?.email }));
  };
  const closeErrorModal = () => {
    dispatch(closeRtkModal());
  };

  return (
    <>
      <div className="container__fullScreen bg--light">
        <div className="auth__container">
          <Formik
            initialValues={initialValues}
            validationSchema={forgotPasswordSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ errors, touched }) => (
              <Form className="auth__form">
                <div className="auth__form--logo">
                  <KeyLockSvg />
                </div>
                <img
                  src={AuthImage}
                  className="auth__form--img"
                  alt="Authentication"
                />
                <h4 className="auth__form--heading">Forgot Password?</h4>
                <p className="auth__form--text">
                  Please enter your email address. We will send you an email to
                  reset your password.
                </p>
                <div
                  className={`formField formField__mb5 ${
                    errors?.email && touched?.email ? "fieldError" : ""
                  }`}
                >
                  <label className="formField__label">Email address</label>
                  <div className="formField__group">
                    <span className="formField__icon">
                      <LockSvg />
                    </span>
                    <Field
                      type="text"
                      name="email"
                      className="formField__input"
                      placeholder="Enter email address"
                    />
                  </div>
                  <span className="formField__error">
                    {errors?.email && touched?.email && errors?.email}
                  </span>
                </div>
                <button
                  type="submit"
                  className="btn btn__primary auth__form--submit"
                >
                  Send Email
                </button>
                <div className="auth__form--divider">
                  <span>Or</span>
                </div>
                <Link to="/" className="link link__primary">
                  Back to Sign in
                </Link>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {meta?.status && (
        <SuccessModal
          closeModal={closeModal}
          sucessfullMsg={meta?.msg}
          Heading={"Email Sent!"}
        />
      )}
            {meta?.status === false && (
          <ErrorModal
            errorMessage={meta?.msg}
            closeModal={closeErrorModal}
            footerVisible={true}
          />
        )}
      {loading && <Loader />}
    </>
  );
};

export default ForgotPassword;
