import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import LinkIcon from "@/assets/svgs/LinkIcon";
import PlayIcon from "@/assets/svgs/PlayIcon";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { firstLetterCapital, getUTCRecordingTimeSlot } from "@/utils/helper";
import { RecordingModal } from "@/components/modals/recording-modal";
import candidateRecordingList from "@/rtk-apis/customer/myJobs/candidates/candidateRecordingList";
import CustomPagination from "@/elements/pagination/customPagination";
import GoTOPage from "@/elements/gotopage/gotopage";
import Loader from "@/elements/Loader";

function CandidatesRecordings({
  candidateData,
  changeCandidateViewType,
  fromShortlistedCandidates,
}) {
  const dispatch = useDispatch();
  const [openRecordingModal, setOpenRecordingModal] = useState(false);
  const [recordingLink, setRecordingLink] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [page, setPage] = useState(1);

  const { data, loading } = useSelector((globalState) => {
    return globalState.candidateRecordingList;
  });

  useEffect(() => {
    getCandidateRecordingListAPIData();
    const intervalId = setInterval(() => {
      getCandidateRecordingListAPIData();
    }, 30 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [page, selectedFilter]);

  const getCandidateRecordingListAPIData = () => {
    dispatch(
      candidateRecordingList({
        screeningId: fromShortlistedCandidates
          ? candidateData?.screeningId
          : candidateData?.sreeningId,
        addedFrom: fromShortlistedCandidates ? selectedFilter : "Recruiter",
        page,
        limit: 20,
      })
    );
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  const viewRecording = (recordingLink) => {
    setOpenRecordingModal(true);
    setRecordingLink(recordingLink);
  };

  return (
    <>
      <div className="candidateRecordings">
        <div className="details" style={{ height: "calc(100dvh - 182px)" }}>
          <section className="details__section m_0">
            <button
              onClick={() => changeCandidateViewType("list", candidateData)}
              className="btn btn__white btn__back"
            >
              Back
            </button>
            <div className="initials initials__lg">
              {candidateData?.candidate?.firstName.charAt(0).toUpperCase()}
            </div>
            <div className="details__section--name">
              {firstLetterCapital(candidateData?.candidate?.firstName) +
                " " +
                firstLetterCapital(candidateData?.candidate?.lastName)}
            </div>
            <div className="details__row">
              <div className="details__row--item">
                <span>Email:</span>
                {candidateData?.candidate?.email}
              </div>
            </div>
            <ul className="tags__list">
              <li className="tags__list--item">
                <span>Recruiter Name:</span>
                {firstLetterCapital(candidateData?.recruiter?.firstName) +
                  " " +
                  firstLetterCapital(candidateData?.recruiter?.lastName)}
              </li>
              {candidateData?.candidate?.resumeUrl && (
                <li className="tags__list--item">
                  <Link
                    to={
                      candidateData?.candidate?.resumeUrl
                        ? candidateData?.candidate?.resumeUrl
                        : "#"
                    }
                    target="_blank"
                    className="tag--link"
                  >
                    {candidateData?.candidate?.resumeUrl}
                    <LinkIcon />
                  </Link>
                </li>
              )}
            </ul>
          </section>

          <section className="details__section pt_0">
            <TableContainer
              component={Paper}
              className="tableContainer p_0"
              sx={{ height: "auto !important" }}
            >
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell>S.</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    {fromShortlistedCandidates && (
                      <TableCell>Interviewed By</TableCell>
                    )}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.rows?.length ? (
                    data?.rows?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          <div className="cellText">
                            <span className="cellText__dark">
                              {moment(row?.createdAt).format("D MMM , YYYY")}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="cellText">
                            <span className="cellText__dark">
                              {getUTCRecordingTimeSlot(
                                row?.createdAt,
                                row?.timeSlot
                              )}
                            </span>
                          </div>
                        </TableCell>
                        {fromShortlistedCandidates && (
                          <TableCell>
                            <div className="cellText">
                              <span className="cellText__dark">
                                {row?.addedFrom}
                              </span>
                            </div>
                          </TableCell>
                        )}
                        <TableCell align="right">
                          <div className="actions flex--end">
                            <button
                              className="btn btn__white"
                              onClick={() => viewRecording(row?.link)}
                            >
                              View Rec.
                              <PlayIcon />
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <div className="no-data">No Results Found!</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </section>
        </div>
        <div className="pagination">
          <div className="pagination__result">
          {`${data?.count > page * 20 ? 20 : data?.count} of ${
              data?.count
            } results`}
          </div>
          {data?.count ? (
            <CustomPagination
              totalPageCount={Math.ceil(data?.count / data?.count)}
              changePage={(event, value) => changePage(event, value)}
            />
          ) : (
            ""
          )}
          {data?.count ? (
            <div className="pagination__actions">
              <GoTOPage changePage={changePage} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {loading && <Loader />}
      <RecordingModal
        openModal={openRecordingModal}
        closeModal={() => setOpenRecordingModal(false)}
        recordingLink={recordingLink}
      />
    </>
  );
}

export default CandidatesRecordings;
