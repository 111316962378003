import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { loginSchema } from "@/components/schema/schema";
import AuthImage from "@/assets/images/auth-img.png";
import { Link } from "react-router-dom";
import CloseEye from "@/assets/svgs/CloseEye";
import OpenEye from "@/assets/svgs/OpenEye";
import KeyLockSvg from "@/assets/svgs/KeyLockSvg";
import LockSvg from "@/assets/svgs/LockSvg";
import GoogleSvg from "@/assets/svgs/GoogleSvg";
import MicrosoftSvg from "@/assets/svgs/MicrosoftSvg";
import LinkedInSvg from "@/assets/images/Linkedin.svg";
import ErrorModal from "@/components/modals/error-modal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader from "@/elements/Loader";
import { closeRtkModal } from "@/store/slices/SignInSlice";
import signInRecruiter from "@/rtk-apis/signInRecruiter";
import { GetUserDetails } from "@/utils/helper";
import socialSignIn from "@/rtk-apis/socialSignIn";
import { closeSocialSignInModal } from "@/store/slices/socialSignInSlice";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = GetUserDetails();

  const [UserType, setUserType] = useState("customer");
  const [SignInFor, setSignInFor] = useState("");
  const [eyeForPassword, seteyeForPassword] = useState(false);

  let initialValues = {
    email: "",
    password: "",
  };

  const closeModal = () => {
    dispatch(closeRtkModal());
  };

  const closeSocialSignInErrorModal = () => {
    localStorage.removeItem("socialSignInData");
    dispatch(closeSocialSignInModal());
    navigate("/signin");
  };

  const { loading, meta } = useSelector((globalState) => {
    return globalState.signIn;
  });

  const socialSignInResponse = useSelector((globalState) => {
    return globalState.socialSignInRes;
  });

  useEffect(() => {
    if (socialSignInResponse?.meta?.status && SignInFor) {
      let signInData = {
        ...socialSignInResponse?.data,
        role: UserType,
        provider: SignInFor,
      };
      localStorage.setItem("socialSignInData", JSON.stringify(signInData));
      const form = document.createElement("form");
      form.action = `${process.env.REACT_APP_ORY_DEV_URL}self-service/login?flow=${socialSignInResponse?.data?.flowId}`;
      form.method = "POST";

      const input = document.createElement("input");
      input.type = "hidden";
      input.name = "provider";
      input.value = SignInFor;

      form.appendChild(input);
      document.body.appendChild(form);
      form.submit();
    }
  }, [socialSignInResponse?.meta?.status]);

  const handleSubmit = (values) => {
    dispatch(
      signInRecruiter({ email: values?.email, password: values?.password })
    );
  };
  useEffect(() => {
    if (meta?.status) {
      if (GetUserDetails()?.usertype === "customer") {
        setUserInChatwoot();
        navigate("/customer-dashboard");
      } else {
        setUserInChatwoot();
        navigate("/dashboard");
      }
    }
  }, [meta]);

  const setUserInChatwoot = () => {
    console.log(user, "user");
    if (window.$chatwoot && typeof window.$chatwoot.reset === "function") {
      let obj = {
        id:
          user?.usertype === "recruiter"
            ? user?.recruiter?.recruiterId
            : user?.customer?.employeeId,
        name:
          user?.usertype === "recruiter"
            ? `${user?.recruiter?.firstName} ${user?.recruiter?.lastName}`
            : `${user?.customer?.firstName} ${user?.customer?.lastName}`,
        email:
          user?.usertype === "recruiter"
            ? `${user?.recruiter?.email}`
            : user?.customer?.email,
        phone_number:
          user?.usertype === "recruiter"
            ? `${user?.recruiter?.countryCode}${user?.recruiter?.phoneNumber}`
            : `${user?.customer?.countryCode}${user?.customer?.phoneNumber}`,
        company_name:
          user?.usertype === "recruiter"
            ? null
            : user?.customer?.company?.companyName,
        account_role: user?.usertype,
      };
      window.$chatwoot.setUser(obj.id, {
        name: obj.name,
        email: obj.email,
        phone_number: obj.phone_number,
        company_name: obj.company_name,
      });
      window.$chatwoot.setCustomAttributes({
        account_role: obj.account_role,
      });
    }
  };

  const handleSocialSignIn = (provider) => {
    setSignInFor(provider);
    if (provider) {
      let body = {
        type: provider,
      };
      dispatch(socialSignIn(body));
    }
  };

  return (
    <>
      <div className="container__fullScreen bg--light">
        <div className="auth__container">
          <Formik
            initialValues={initialValues}
            validationSchema={loginSchema}
            onSubmit={(e, values) => handleSubmit(e, values)}
          >
            {({ errors, touched }) => (
              <Form className="auth__form">
                <div className="auth__form--logo">
                  <KeyLockSvg />
                </div>
                <img
                  src={AuthImage}
                  className="auth__form--img"
                  alt="Authentication"
                />
                <div className="auth__form--tabs">
                  <button
                    className={`tabLink ${
                      UserType === "customer" ? "tabLink--active" : ""
                    }`}
                    type="button"
                    onClick={() => setUserType("customer")}
                  >
                    Customer
                  </button>
                  <button
                    className={`tabLink ${
                      UserType === "candidate" ? "tabLink--active" : ""
                    }`}
                    type="button"
                    onClick={() => setUserType("candidate")}
                  >
                    Candidate
                  </button>
                  <button
                    className={`tabLink ${
                      UserType === "recruiter" ? "tabLink--active" : ""
                    }`}
                    type="button"
                    onClick={() => setUserType("recruiter")}
                  >
                    Recruiter
                  </button>
                </div>
                <h4 className="auth__form--heading">Sign In</h4>
                <div
                  className={`formField formField__mb5 ${
                    errors?.email && touched?.email ? "fieldError" : ""
                  }`}
                >
                  <label className="formField__label">Email address</label>
                  <div className="formField__group">
                    <span className="formField__icon">
                      <LockSvg />
                    </span>
                    <Field
                      type="text"
                      className="formField__input"
                      placeholder="Enter email address"
                      name="email"
                      autoComplete="off"
                    />
                  </div>
                  <span className="formField__error">
                    {errors?.email && touched?.email && errors?.email}
                  </span>
                </div>
                <div
                  className={`formField formField__mb5 ${
                    errors?.password && touched?.password ? "fieldError" : ""
                  }`}
                >
                  <label className="formField__label">Password</label>
                  <div className="formField__group">
                    <span className="formField__icon">
                      <LockSvg />
                    </span>
                    <Field
                      type={eyeForPassword ? "text" : "password"}
                      className="formField__input"
                      placeholder="Enter password"
                      name="password"
                      autoComplete="off"
                    />
                    <div
                      onClick={() => seteyeForPassword(!eyeForPassword)}
                      className="formField__icon"
                    >
                      <span>{eyeForPassword ? <OpenEye /> : <CloseEye />}</span>
                    </div>
                  </div>
                  <span className="formField__error">
                    {errors?.password && touched?.password && errors?.password}
                  </span>
                  <p className="auth__form--text forgotpasswordText">
                    <Link to="/forgot-password" className="link link__primary">
                      Forgot password?
                    </Link>
                  </p>
                </div>
                <button
                  type="submit"
                  className="btn btn__primary auth__form--submit"
                >
                  Sign In
                </button>
                <div className="auth__form--footer">
                  Don’t have an account?
                  <Link
                    to="/signup"
                    state={{ signUpAs: UserType }}
                    className="link link__primary"
                  >
                    Sign Up
                  </Link>
                </div>
                <div className="auth__form--divider">
                  <span>Or</span>
                </div>
                <div className="auth__form--footer">
                  <div className="btn__group">
                    <button
                      className="btn btn__white"
                      type="button"
                      onClick={() => handleSocialSignIn("google")}
                    >
                      <GoogleSvg />
                      Google
                    </button>
                    <button
                      className="btn btn__white"
                      type="button"
                      onClick={() => handleSocialSignIn("microsoft")}
                    >
                      <MicrosoftSvg />
                      Microsoft
                    </button>
                    <button className="btn btn__white" type="button">
                      <img
                        src={LinkedInSvg}
                        className="icon"
                        alt="LinkedInSvg"
                      />
                      LinkedIn
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {meta?.status === false && (
          <ErrorModal
            errorMessage={meta?.msg}
            closeModal={closeModal}
            footerVisible={true}
          />
        )}
        {socialSignInResponse?.meta?.status === false && (
          <ErrorModal
            errorMessage={socialSignInResponse?.meta?.msg}
            closeModal={closeSocialSignInErrorModal}
            footerVisible={true}
          />
        )}
        {loading && <Loader />}
      </div>
    </>
  );
};
export default SignIn;
