import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import CloseIcon from "@/assets/svgs/CloseIcon";
import { useSelector, useDispatch } from "react-redux";
import staticData from "@/rtk-apis/staticData/index";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";

function CandidateFilters({
  closeFilter,
  education,
  educationChange,
  experience,
  experienceChange,
  skillSet,
  skillSetChange,
  apply,
  clearFilters,
}) {
  const dispatch = useDispatch();
  const filterExperience = (event, newValue) => {
    experienceChange(newValue);
  };

  const staticDataLocal = useSelector((globalState) => {
    return globalState.static;
  });

  useEffect(() => {
    dispatch(staticData());
  }, []);

  return (
    <>
      <div className="filter__head">
        <h2 className="listCol__head--title">Filter</h2>
        <button
          onClick={() => closeFilter()}
          className="btn btn__transparent border_0 p_0"
        >
          <CloseIcon />
        </button>
      </div>
      <div className="filterContainer">
        <ul className="listCol__list filter__list">
          <li>
            <div className="formRow">
              <div className="formCol">
                <div className="formField">
                  <label className="formField__label">Highest Education</label>
                  <div className="formField">
                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={staticDataLocal?.data?.qualification || []}
                      value={education || ""}
                      name="qualification"
                      onChange={(event, newValue) => educationChange(newValue)}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder="Select"
                          className="autoCompleteInput"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="formCol">
                <div className="formField">
                  <label className="formField__label">Skill set</label>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={staticDataLocal?.data?.skillSet || []}
                    defaultValue={skillSet}
                    name="skillSet"
                    onChange={(event, newValue) => {
                      skillSetChange(newValue);
                    }}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        placeholder="Select"
                        className="autoCompleteInput"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="formCol">
                <div className="formField">
                  <label className="formField__label flex flex--between">
                    Experience (years)
                    <span>{experience[0] + "-" + experience[1]} years</span>
                  </label>
                  <Box fullWidth sx={{ padding: "0 4px" }}>
                    <Slider
                      value={experience}
                      onChange={filterExperience}
                      valueLabelDisplay="auto"
                      min={0}
                      max={99}
                    />
                  </Box>
                  <label
                    className="formField__label flex flex--between"
                    style={{ display: "flex" }}
                  >
                    0<span>99</span>
                  </label>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div className="filter__footer">
          <div className="btn__group flex--between">
            <button onClick={clearFilters} className="btn btn__transparent p_0">
              Clear
            </button>
            <button onClick={apply} className="btn btn__primary">
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CandidateFilters;
