import deleteCustomerNotes from "@/rtk-apis/customer/myJobs/notes/deleteCustomerNotes";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const deleteCustomerNotesSlice = createSlice({
  name: "deleteCustomerNotesSlice",
  initialState,
  reducers: {
    closeDeleteNoteModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCustomerNotes.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteCustomerNotes.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(deleteCustomerNotes.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default deleteCustomerNotesSlice.reducer;

export const { closeDeleteNoteModal } = deleteCustomerNotesSlice.actions;
