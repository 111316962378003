import { createSlice } from "@reduxjs/toolkit";
import signInRecruiter from "@/rtk-apis/signInRecruiter";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const signInSlice = createSlice({
  name: "signIn",
  initialState,
  reducers: {
    closeRtkModal(state, action) {
      state.meta = {};
    },
    logoutUser(state, action) {
      state.meta = {};
      state.data = {};
      state.token = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInRecruiter.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(signInRecruiter.fulfilled, (state, { payload }) => {
        state.data = payload?.data;
        state.token = payload.data?.session_token;
        state.meta = payload?.meta;
        const localData = {
          token: payload?.data?.session_token,
          recruiter: payload?.data?.recruiterDetails,
          sessionid: payload?.data?.session?.id,
          Oryid: payload?.data?.session?.identity?.id,
          usertype: payload?.data?.recruiterDetails ? "recruiter" : "customer",
          customer: payload?.data?.employeeDetails,
        };
        localStorage.setItem("token", JSON.stringify(localData));
        state.loading = false;
      })
      .addCase(signInRecruiter.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.meta?.msg;
      });
  },
});

export default signInSlice.reducer;

export const { closeRtkModal, logoutUser } = signInSlice.actions;
