import React, { useState } from "react";
import TaxPayeridentificationNumberEdit from "@/pages/Settings/TaxInformation/TaxPayeridentificationNumberEdit";
import TaxPayerIdentificationNumberView from "@/pages/Settings/TaxInformation/TaxPayerIdentificationNumberView";

const TaxPayerIdentificationNumber = ({
  taxInfoStatus,
  taxDetails,
  refreshTaxInfo,
  formCompletedStatus
}) => {
  const [
    openTaxPayerIdentificationNoEditPage,
    setOpenTaxPayerIdentificationNoEditPage,
  ] = useState(false);
  const openTaxPayerIdentificationNoEdit = (value) => {
    setOpenTaxPayerIdentificationNoEditPage(value);
    if (!value) {
      refreshTaxInfo();
    }
  };
  return (
    <>
      {openTaxPayerIdentificationNoEditPage ? (
        <TaxPayeridentificationNumberEdit
          changeTaxPayerIdentificationView={openTaxPayerIdentificationNoEdit}
          taxDetails={taxDetails}
        />
      ) : (
        <TaxPayerIdentificationNumberView
          changeTaxPayerIdentificationView={openTaxPayerIdentificationNoEdit}
          taxInfoStatus={taxInfoStatus}
          taxDetails={taxDetails}
          formCompletedStatus={formCompletedStatus}
        />
      )}
    </>
  );
};

export default TaxPayerIdentificationNumber;
