import React from 'react';
import { Navigate } from 'react-router-dom';
import { GetSessionId, GetUserDetails } from '@/utils/helper';

export default function DashboardRoute ({ children }) {
  const token = GetSessionId();
  if (token) {
    const user = GetUserDetails();
    if (user?.customer?.employeeId) {
      return <Navigate to="/customer-dashboard" replace />;
    } else {
      return <Navigate to="/dashboard" replace />;
    }
  }
  return children;
}
