import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CUSTOMER_SHORTLISTED_CANDIDATE_LIST } from '@/rtk-apis/end-points';

const customershortlistedCandidates = createAsyncThunk('customershortlistedCandidates', async (body) => {
  try {
    const response = await httpService.post(CUSTOMER_SHORTLISTED_CANDIDATE_LIST, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default customershortlistedCandidates;
