import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { GET_CANDIDATE_DETAIL } from '@/rtk-apis/end-points';

const candidateDetail = createAsyncThunk('candidateDetail', async (candidateId) => {
  try {
    const response = await httpService.get(`${GET_CANDIDATE_DETAIL}${candidateId}`, {});
    return response;
  } catch (error) {
    return error;
  }
});

export default candidateDetail;
