import React, { useEffect } from "react";
import SideBar from "@/components/layout/SideBar";
import ProfileBlackIcon from "@/assets/svgs/ProfileBlackIcon";
import EmployeeProfileIcon from "@/assets/svgs/EmployeeProfileIcon";
import EmailIcon from "@/assets/svgs/EmailIcon";
import PhoneIcon from "@/assets/svgs/PhoneIcon";
import { Formik, Form, Field } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { formatPhoneNumber, GetUserDetails } from "@/utils/helper";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDown from "@/assets/svgs/ArrowDown";
import MenuItem from "@mui/material/MenuItem";
import staticData from "@/rtk-apis/staticData";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addEmployeeSchema } from "@/components/schema/customer-schema";
import customerAddEmployee from "@/rtk-apis/customer/companyProfile/customerAddEmployee";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import Loader from "@/elements/Loader";
import { closeAddEmployeeModal } from "@/store/slices/customer/companyProfile/customerAddEmployeeSlice";
import customerEditEmployee from "@/rtk-apis/customer/companyProfile/customerEditEmployee";
import { closeEditEmployeeModal } from "@/store/slices/customer/companyProfile/customerEditEmployeeSlice";

const AddEmployee = () => {
  const navigate = useNavigate();
  const loggedInUser = GetUserDetails();
  const dispatch = useDispatch();
  const location = useLocation();

  const staticDataResponse = useSelector((globalState) => {
    return globalState.static;
  });
  const { meta, loading } = useSelector((globalState) => {
    return globalState.customerAddEmployeeResponse;
  });
  const customerUpdateEmployeeResponse = useSelector((globalState) => {
    return globalState.customerEditEmployeeResponse;
  });

  useEffect(() => {
    dispatch(staticData());
  }, []);

  let initialValues = {
    firstName: location ? location?.state?.editEmployeeDetails?.firstName : "",
    lastName: location ? location?.state?.editEmployeeDetails?.lastName : "",
    email: location ? location?.state?.editEmployeeDetails?.email : "",
    role: location ? location?.state?.editEmployeeDetails?.role : "",
    countryCode: "+1",
    phoneNumber: location
      ? location?.state?.editEmployeeDetails?.phoneNumber
      : "",
  };

  const handleSubmit = async (values) => {
    if (location?.state?.editEmployeeMode) {
      let body = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        phoneNumber: values?.phoneNumber ? values?.phoneNumber : "",
        countryCode: values?.countryCode,
        updatedBy: loggedInUser?.customer?.employeeId,
        employeeId: location?.state?.editEmployeeDetails?.employeeId,
        role: values?.role,
        updatedByCustomer: true,
      };
      dispatch(customerEditEmployee(body));
    } else {
      let body = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        role: values?.role,
        countryCode: values?.countryCode,
        phoneNumber: values?.phoneNumber ? values?.phoneNumber : "",
        addedBy: loggedInUser?.customer?.employeeId,
        companyId: loggedInUser?.customer?.company?.id,
        addedByCustomer: true,
      };
      dispatch(customerAddEmployee(body));
    }
  };

  const handleCancel = () => {
    navigate("/customer-company-profile", {
      state: {
        OpenEmployeeList: true,
      },
    });
  };

  const closeSuccessModal = () => {
    if (location?.state?.editEmployeeMode) {
      dispatch(closeEditEmployeeModal());
    } else {
      dispatch(closeAddEmployeeModal());
    }
    handleCancel();
  };

  const closeErrorModal = () => {
    if (location?.state?.editEmployeeMode) {
      dispatch(closeEditEmployeeModal());
    } else {
      dispatch(closeAddEmployeeModal());
    }
  };
  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid layoutGrid--full">
          <div className="layoutGrid__col">
            <div className="dataCol">
              <div className="page page__full">
                <Formik
                  initialValues={initialValues}
                  validationSchema={addEmployeeSchema}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  {({ errors, values, handleChange, handleBlur, touched }) => (
                    <Form className="form p_0">
                      <div className="page__head">
                        <h2 className="page__head--title">
                          {location?.state?.editEmployeeMode
                            ? "Edit Employee"
                            : "Add New Employee"}
                        </h2>

                        <div className="listCol__head--actions">
                          <button
                            className="btn btn__white w_100"
                            type="button"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn__primary w_100"
                            type="submit"
                          >
                            {location?.state?.editEmployeeMode
                              ? "Update"
                              : "Add"}
                          </button>
                        </div>
                      </div>
                      <div className="page__body">
                        <div className="details">
                          <div className="form__section">
                            <h4 className="form__section--title">
                              Employee Details
                            </h4>
                            <div className="formRow formRow__2">
                              {/* EMAIL ADDRESS */}
                              <div className="formCol">
                                <div
                                  className={`formField ${
                                    errors?.email && touched?.email
                                      ? "fieldError"
                                      : ""
                                  }`}
                                >
                                  <label className="formField__label">
                                    Business Email ID
                                  </label>
                                  <div className="formField__group sm">
                                    <Field
                                      type="text"
                                      className={`formField__input sm ${
                                        location?.state?.editEmployeeMode
                                          ? "formField__disabled"
                                          : ""
                                      }`}
                                      placeholder="Email Address"
                                      name="email"
                                      onBlur={handleBlur}
                                      disabled={
                                        location?.state?.editEmployeeMode
                                          ? true
                                          : false
                                      }
                                    />
                                    <span className="formField__icon">
                                      <EmailIcon />
                                    </span>
                                  </div>
                                  <span className="formField__error">
                                    {errors?.email &&
                                      touched?.email &&
                                      errors?.email}
                                  </span>
                                </div>
                              </div>
                              {/* ROLE */}
                              <div className="formCol">
                                <div className="formField">
                                  <div
                                    className={`formField ${
                                      errors?.role && touched?.role
                                        ? "fieldError"
                                        : ""
                                    }`}
                                  >
                                    <label className="formField__label">
                                      Role
                                    </label>
                                    {/* <div className="formField__group sm">
                                      <Field
                                        type="text"
                                        className="formField__input sm"
                                        placeholder="Employee Role"
                                        name="role"
                                        onBlur={handleBlur}
                                      />
                                      <span className="formField__icon">
                                        <EmployeeProfileIcon />
                                      </span>
                                    </div> */}
                                    <FormControl
                                      fullWidth
                                      size="small"
                                      className="formField__select"
                                    >
                                      <Select
                                        onChange={(e) => handleChange(e)}
                                        IconComponent={() => <ArrowDown />}
                                        placeholder="Employee Role"
                                        name="role"
                                        value={values?.role ?? ""}
                                        displayEmpty
                                        renderValue={(selected) => {
                                          if (!selected) {
                                            return (
                                              <span style={{ opacity: "0.8" }}>
                                                Select
                                              </span>
                                            );
                                          }

                                          return selected;
                                        }}
                                      >
                                        {staticDataResponse?.data?.role?.map(
                                          (item, index) => (
                                            <MenuItem key={index} value={item}>
                                              {item}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                    <span className="formField__error">
                                      {errors?.role &&
                                        touched?.role &&
                                        errors?.role}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="formRow formRow__2">
                              {/* FIRST NAME */}
                              <div className="formCol">
                                <div className="formField">
                                  <div
                                    className={`formField ${
                                      errors?.firstName && touched?.firstName
                                        ? "fieldError"
                                        : ""
                                    }`}
                                  >
                                    <label className="formField__label">
                                      Employee First Name
                                    </label>
                                    <div className="formField__group sm">
                                      <Field
                                        type="text"
                                        className="formField__input sm"
                                        placeholder="First Name"
                                        name="firstName"
                                        onBlur={handleBlur}
                                      />
                                      <span className="formField__icon">
                                        <ProfileBlackIcon />
                                      </span>
                                    </div>
                                    <span className="formField__error">
                                      {errors?.firstName &&
                                        touched?.firstName &&
                                        errors?.firstName}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {/* LAST NAME */}
                              <div className="formCol">
                                <div
                                  className={`formField ${
                                    errors?.lastName && touched?.lastName
                                      ? "fieldError"
                                      : ""
                                  }`}
                                >
                                  <label className="formField__label">
                                    Employee Last Name
                                  </label>
                                  <div className="formField__group sm">
                                    <Field
                                      type="text"
                                      className="formField__input sm"
                                      placeholder="Last Name"
                                      name="lastName"
                                      onBlur={handleBlur}
                                    />
                                    <span className="formField__icon">
                                      <ProfileBlackIcon />
                                    </span>
                                  </div>
                                  <span className="formField__error">
                                    {errors?.lastName &&
                                      touched?.lastName &&
                                      errors?.lastName}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="formRow formRow__2">
                              {/* PHONE NUMBER */}
                              <div className="formCol">
                                <div
                                  className={`formField ${
                                    errors?.phoneNumber && touched?.phoneNumber
                                      ? "fieldError"
                                      : ""
                                  }`}
                                >
                                  <label className="formField__label">
                                    Phone Number
                                  </label>
                                  <div className="formField__group formField__phoneNumber sm">
                                    <FormControl
                                      fullWidth
                                      size="small"
                                      className="formField__select"
                                    >
                                      <Select
                                        onChange={(e) => handleChange(e)}
                                        IconComponent={() => <ArrowDown />}
                                        name="countryCode"
                                        value={values?.countryCode ?? ""}
                                        displayEmpty
                                      >
                                        <MenuItem value={"+1"}>{"+1"}</MenuItem>
                                      </Select>
                                    </FormControl>
                                    <Field
                                      type="text"
                                      className="formField__input sm"
                                      placeholder="Phone Number"
                                      maxLength="10"
                                      name="phoneNumber"
                                      onBlur={handleBlur}
                                      value={
                                        values?.phoneNumber
                                          ? formatPhoneNumber(
                                              values?.phoneNumber
                                            )
                                          : ""
                                      }
                                    />
                                    <span className="formField__icon">
                                      <PhoneIcon />
                                    </span>
                                  </div>
                                  <span className="formField__error">
                                    {errors?.phoneNumber &&
                                      touched?.phoneNumber &&
                                      errors?.phoneNumber}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(meta?.status || customerUpdateEmployeeResponse?.meta?.status) && (
        <SuccessModal
          closeModal={closeSuccessModal}
          sucessfullMsg={`Employee has been ${
            location?.state?.editEmployeeMode ? "updated" : "added"
          } successfully.`}
          Heading={`Employee ${
            location?.state?.editEmployeeMode ? "Updated" : "Added"
          }!`}
        />
      )}
      {(meta?.status === false ||
        customerUpdateEmployeeResponse?.meta?.status === false) && (
        <ErrorModal
          errorMessage={
            location?.state?.editEmployeeMode
              ? customerUpdateEmployeeResponse?.meta?.msg
              : meta?.msg
          }
          closeModal={closeErrorModal}
          footerVisible={true}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default AddEmployee;
