import React, { useEffect } from "react";
import PaypalLogo from "@/assets/images/paypal-logo.svg";
import { PAYPAL_API_KEY } from "@/utils/constants";

const AddPaypalMethodModal = ({
  openModal,
  closeModal,
  setOpenAddBankAccountModal,
}) => {
  useEffect(() => {
    paypal.use(["login"], function(login) {
      login.render({
        appid: PAYPAL_API_KEY,
        //authend: "sandbox",
        scopes: "email",
        containerid: "lippButton",
        responseType: "code id_Token",
        locale: "en-us",
        buttonType: "LWP",
        buttonShape: "pill",
        buttonSize: "lg",
        fullPage: "true",
        returnurl: "https://app.laila.co/settings/payment",
      });
    });
  }, []);
  return (
    <>
      <div
        className={`modal modalpaypalWithdrawal  ${
          openModal ? "modal--open" : ""
        }`}
      >
        <div className="modal__dialog">
          <div className="modal__content">
            <div className="modal__body modal-body-left">
              <div className="paypalWithdrawalHead">
                <div className="paypalWithdrawalHead__branding">
                  <img
                    src={PaypalLogo}
                    className="paypalWithdrawalHead__branding__img"
                    alt="Authentication"
                  />
                </div>
                <h1 className="paypalWithdrawalHead__title">
                  Connect you PayPal account to <span>Laila Payments Page</span>
                </h1>
              </div>
              <div className="paypalWithdrawalBody">
                <h4>This lets Laila Payments page: </h4>
                <h5>Receive you personal info</h5>
                <p>
                  Full name, Email, Address, Account verification status, PayPal
                  ID
                </p>
                <p>
                  By connecting, you're agreeing to share your information with Laila Payments Page. 
                  This information will be used in accordance with its <a href="#">Privacy Statements</a>
                  and <a href="#">Terms & Conditions</a>. You can review these permissions and stop future sharing of your
                  info at any time in your PayPal Profile.
                </p>
              </div>
              <div className="paypalWithdrawalFooter">
                <div className="actions">
                  {/* <button className="btn" type="button">
                    Agree and Connect
                  </button> */}
                  <span id="lippButton"></span>
                  <button className="btn btn_transprent" type="button" onClick={()=>closeModal()}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default AddPaypalMethodModal;
