import React, { useEffect, useRef, useState } from "react";
import Loader from "@/elements/Loader";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import staticData from "@/rtk-apis/staticData";
import { GetUserDetails, firstLetterCapital } from "@/utils/helper";
import FormControl from "@mui/material/FormControl";
import ErrorModal from "@/components/modals/error-modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import { SuccessModal } from "@/components/modals/SuccessModal";
import profileDeactivate from "@/rtk-apis/customer/myProfile/profileDeactivate";
import customerEmployeeList from "@/rtk-apis/customer/myProfile/customerEmployeeList";
import { closeDeactivateModal } from "@/store/slices/customer/myProfile/profileDeactivateSlice";
import ConfirmationModal from "@/components/modals/confirmation-modal";

export const ProfileDeactivateModal = ({
  openModal,
  closeModal,
  employeeDetails,
  setRefreshDetails,
  refreshDetails,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const loggedInUser = GetUserDetails();
  const [explainationtext, setExplainationtext] = useState("");
  const [selectedCompanyAdmin, setSelectedCompanyAdmin] = useState("");
  const [selectedCheckboxvalue, setSelectedCheckboxvalue] = useState({});
  const [
    EmployeeListExceptLoggedInUser,
    setEmployeeListExceptLoggedInUser,
  ] = useState([]);
  const [TextareaError, setTextareaError] = useState("");
  const [CompanyAdminDropdownError, setCompanyAdminDropdownError] = useState(
    ""
  );
  const [hideCompanyAdminDropdown, setHideCompanyAdminDropdown] = useState(
    false
  );
  const [
    OpenDeactivateConfirmationModal,
    setOpenDeactivateConfirmationModal,
  ] = useState(false);
  const { data } = useSelector((globalState) => {
    return globalState.static;
  });

  const { meta, loading } = useSelector((globalState) => {
    return globalState.deactivateMyProfileResponse;
  });

  const EmployeeList = useSelector((globalState) => {
    return globalState.getCompanyEmployeeList;
  });
  const handleDeactivateProfile = () => {
    const body = {
      companyId: employeeDetails?.companyId,
      companyName: employeeDetails?.company?.companyName,
      employeeId: employeeDetails?.employeeId,
      employeeName:
        employeeDetails?.firstName + " " + employeeDetails?.lastName,
      reason: explainationtext ?? "",
      newCompanyAdminId: selectedCompanyAdmin?.employeeId
        ? selectedCompanyAdmin?.employeeId
        : null,
      option: selectedCheckboxvalue,
      from: "Employee",
    };
    dispatch(profileDeactivate(body));
    setOpenDeactivateConfirmationModal(false);
  };

  const getEmployeeList = () => {
    let body = {
      companyId: loggedInUser?.customer?.companyId,
      employeeId: loggedInUser?.customer?.employeeId,
      limit: 100,
      page: 1,
      search: "",
      from: "employeeProfile",
    };
    dispatch(customerEmployeeList(body));
  };

  const handleCheckboxChange = (e, item) => {
    const checkboxVal = {
      id: item?.id,
      value: item?.value,
    };
    setSelectedCheckboxvalue(checkboxVal);
    if (item?.value === "Other") {
      setTextareaError("Reason is required.");
    } else {
      setTextareaError("");
    }
    if (selectedCompanyAdmin === "" && !hideCompanyAdminDropdown) {
      setCompanyAdminDropdownError("Company admin is required.");
    } else {
      setCompanyAdminDropdownError("");
    }
  };

  const handleTextAreaChange = (e) => {
    setExplainationtext(e?.target?.value);
    if (selectedCheckboxvalue?.value === "Other" && e?.target?.value === "") {
      setTextareaError("Reason is required.");
    } else {
      setTextareaError("");
    }
  };

  const handleCompanyAdminChange = (value) => {
    setSelectedCompanyAdmin(value);
    if (!value && !hideCompanyAdminDropdown) {
      setCompanyAdminDropdownError("Company admin is required.");
    } else {
      setCompanyAdminDropdownError("");
    }
  };

  function countCompanyAdmins(employees) {
    let count = 0;
    for (const employee of employees) {
      if (
        employee.role === "Company Admin" &&
        employee.employeeId !== loggedInUser?.customer?.employeeId
      ) {
        count++;
      }
    }
    return count;
  }

  useEffect(() => {
    dispatch(staticData());
    getEmployeeList();
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [closeModal]);

  useEffect(() => {
    if (EmployeeList?.data?.count) {
      const NonLoggedInCompanyAdmins = EmployeeList?.data?.rows.filter(
        (employee) =>
          employee.role === "Company Admin" &&
          employee.employeeId !== loggedInUser?.customer?.employeeId
      );
      let count = countCompanyAdmins(NonLoggedInCompanyAdmins);
      if (count > 0 || EmployeeList?.data?.count <= 1) {
        setHideCompanyAdminDropdown(true);
      }
    }
  }, [EmployeeList]);

  useEffect(() => {
    if (EmployeeList?.data?.count) {
      const EmployeeListing = EmployeeList?.data?.rows.filter(
        (employee) => employee.employeeId !== loggedInUser?.customer?.employeeId
      );
      setEmployeeListExceptLoggedInUser(EmployeeListing);
    }
  }, [EmployeeList]);

  const closeSuccessErrorModal = () => {
    dispatch(closeDeactivateModal());
    closeModal();
    setRefreshDetails(!refreshDetails);
    setOpenDeactivateConfirmationModal(false);
  };

  const EmptyModalStates = () => {
    setSelectedCheckboxvalue("");
    setExplainationtext("");
    setSelectedCompanyAdmin("");
    setTextareaError("");
    setCompanyAdminDropdownError("");
  };

  const handleCancel = () => {
    closeModal();
    EmptyModalStates();
  };

  return (
    <>
      <div
        className={`modal modalSuccessfull ${openModal ? "modal--open" : ""}`}
      >
        <div className="modal__dialog">
          <div className="modal__content lg-modal">
            <div className="modal__body modal-body-left">
              <h2 className="modal__header--title bg-red">
                Reason for leaving
              </h2>

              <div className="formField">
                {data?.deactivateAccountOption?.map((item, index) => {
                  return (
                    <div className="formField__checkbox" key={index}>
                      <FormControlLabel
                        className="pb15"
                        onChange={(e) => handleCheckboxChange(e, item)}
                        checked={
                          selectedCheckboxvalue?.id === item?.id ? true : false
                        }
                        control={<Checkbox />}
                      />
                      <span className="pl5">{item?.value}</span>
                    </div>
                  );
                })}
              </div>
              <div className={`formField ${TextareaError ? "fieldError" : ""}`}>
                <label className="formField__label">
                  Please Explain Further
                  <span style={{ opacity: ".5" }}>
                    {`${
                      selectedCheckboxvalue?.value !== "Other"
                        ? " (Optional)"
                        : ""
                    }`}
                  </span>
                </label>
                <textarea
                  className="formField__textarea"
                  value={explainationtext}
                  onChange={(e) => handleTextAreaChange(e)}
                  rows={5}
                  maxLength={300}
                  placeholder="Enter your note here..."
                ></textarea>
                <span className="formField__error">{TextareaError}</span>
              </div>
              {loggedInUser?.customer?.role === "Company Admin" &&
                !hideCompanyAdminDropdown && (
                  <div
                    className={`formField ${
                      CompanyAdminDropdownError ? "fieldError" : ""
                    }`}
                  >
                    <label className="formField__label">Company Admin</label>
                    <FormControl
                      sx={{ width: "100%" }}
                      size="small"
                      className="formField__select"
                    >
                      <Select
                        className="formField__select"
                        fullWidth
                        size="small"
                        displayEmpty
                        onChange={(e) =>
                          handleCompanyAdminChange(e?.target?.value)
                        }
                        value={selectedCompanyAdmin ? selectedCompanyAdmin : ""}
                        renderValue={(selected) => {
                          if (!selected) {
                            return (
                              <span style={{ opacity: "0.8" }}>Select</span>
                            );
                          }

                          return selected
                            ? firstLetterCapital(selected?.firstName) +
                                " " +
                                firstLetterCapital(selected?.lastName)
                            : "";
                        }}
                      >
                        {EmployeeListExceptLoggedInUser?.map(
                          (employee, index) => {
                            return (
                              <MenuItem value={employee} key={index}>
                                {firstLetterCapital(employee?.firstName) +
                                  " " +
                                  firstLetterCapital(employee?.lastName)}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>

                    <span className="formField__error">
                      {CompanyAdminDropdownError}
                    </span>
                  </div>
                )}
              <div className="btn__group flex--start">
                <button className="btn btn__white" onClick={handleCancel}>
                  Cancel
                </button>
                <button
                  className="btn btn__primary"
                  onClick={() => setOpenDeactivateConfirmationModal(true)}
                  disabled={
                    Object.keys(selectedCheckboxvalue).length <= 0 ||
                    TextareaError ||
                    CompanyAdminDropdownError
                      ? true
                      : false
                  }
                >
                  Deactivate
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      {loading && <Loader />}
      {OpenDeactivateConfirmationModal && (
        <ConfirmationModal
          closeModal={() => setOpenDeactivateConfirmationModal(false)}
          onSubmit={handleDeactivateProfile}
          heading={"Deactivate Account!"}
          confirmationMessage={
            "Are you sure you want to send request to deactivate your account?"
          }
        />
      )}
      {meta?.status === true && (
        <SuccessModal
          closeModal={closeSuccessErrorModal}
          sucessfullMsg={meta?.msg}
          Heading={"Deactivate request sent!"}
        />
      )}
      {meta?.status === false && (
        <ErrorModal
          errorMessage={meta?.msg}
          closeModal={closeSuccessErrorModal}
          footerVisible={true}
        />
      )}
    </>
  );
};
