import { createSlice } from '@reduxjs/toolkit';
import downloadJd from '@/rtk-apis/myJobs/downloadJd';

const initialState = {
  message: '',
  data: '',
  meta: {},
  loading: false,
  error: ''
};

const downloadJDSlice = createSlice({
  name: 'downloadJd',
  initialState,
  reducers: {
    cleanJdUrl (state) { state.data = ''; state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadJd.pending, (state) => {
        state.loading = true;
      })
      .addCase(downloadJd.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.data;
        state.meta = payload.meta;
      })
      .addCase(downloadJd.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  }

});

export default downloadJDSlice.reducer;

export const { cleanJdUrl } = downloadJDSlice.actions;
