import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import feedbackQuestions from "@/rtk-apis/myJobs/feedbackQuestions";
import Loader from "@/elements/Loader";
import FeedbackPage from "@/components/HOCs/FeedbackPage";
import { closeRtkModal } from "@/store/slices/myJobs/candidateApproveRejectSlice";
import { SuccessModal } from "@/components/modals/SuccessModal";
import candidateApproveReject from "@/rtk-apis/myJobs/candidateApproveReject";

function ScheduledInterviewsFeedback({ row, goBack, btnTitle }) {
  const dispatch = useDispatch();
  const feedbackQuestionsResponse = useSelector((globalState) => {
    return globalState.feedbackQuestionsResponse;
  });

  const candidateApproveRejectResponse = useSelector((globalState) => {
    return globalState.candidateApproveRejectResponse;
  });

  useEffect(() => {
    dispatch(feedbackQuestions());
  }, []);

  const closeModal = () => {
    dispatch(closeRtkModal());
    goBack();
  };

  const approveReject = (feedback) => {
    dispatch(
      candidateApproveReject({
        interviewId: row.interviewId,
        status: btnTitle == "Approve" ? "approved" : "rejected",
        feedback,
      })
    );
  };

  return (
    <>
      <FeedbackPage
        heading={"Send us your feedback!"}
        onPageSubmit={(feedback) => approveReject(feedback)}
        btnTitle={btnTitle == "Reject" ? "Reject" : "Approve"}
        onBackClick={() => goBack()}
      />

      {candidateApproveRejectResponse?.meta?.msg && (
        <SuccessModal
          closeModal={closeModal}
          sucessfullMsg={candidateApproveRejectResponse?.meta?.msg}
          Heading={
            btnTitle == "Approve"
              ? "Candidate approved!"
              : "Candidate rejected!"
          }
        />
      )}
      {(feedbackQuestionsResponse.loading ||
        candidateApproveRejectResponse.loading) && <Loader />}
    </>
  );
}

export default ScheduledInterviewsFeedback;
