import React, { useEffect, useState } from "react";
import SideBar from "@/components/layout/SideBar";
import CandidatesListing from "@/pages/Candidates/CandidatesListing";
import CandidateDetails from "@/pages/Candidates/CandidateDetails";
import { useSelector } from "react-redux";
import AddEditCandidate from "@/pages/Candidates/AddEditCandidate";

const Candidates = () => {
  const { data } = useSelector((globalState) => {
    return globalState.candidatesListData;
  });

  const [formScreen, setFormScreen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [editCandidateMode, setEditCandidateMode] = useState(false);
  const [responsive, setResponsive] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [value, setTabValue] = useState("1");

  useEffect(() => {
    if (data?.rows?.length) {
      setSelectedCandidate(data.rows[0]);
      setSelectedIndex(0);
    } else {
      setSelectedCandidate({});
    }
  }, [data, value]);

  const indexChanged = (index) => {
    setResponsive(true);
    setSelectedIndex(index);
    setSelectedCandidate(data.rows[index]);
  };

  const openEditForm = () => {
    setEditCandidateMode(true);
    setFormScreen(true);
  };

  const formScreenChange = (value) => {
    setFormScreen(value);
    setResponsive(true);
  };

  const backToList = () => {
    setEditCandidateMode(false);
    setFormScreen(false);
    setResponsive(false);
    setRefreshList(!refreshList);
    setEditCandidateMode(false);
  };

  const updateData = (list) => {
    if (list?.length <= 0) {
      setSelectedCandidate({});
    }
  };
  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid">
          <CandidatesListing
            selectedIndex={selectedIndex}
            changeIndex={(i) => indexChanged(i)}
            setFormScreen={(value) => formScreenChange(value)}
            updateList={(list) => updateData(list)}
            editCandidateMode={editCandidateMode}
            refreshList={refreshList}
            setTabValue={setTabValue}
            value={value}
            setEditCandidateMode={setEditCandidateMode}
          />
          <div
            className={`layoutGrid__col layoutGrid--responsive ${
              responsive ? "layoutGrid--show" : ""
            }`}
          >
            {formScreen ? (
              <AddEditCandidate
                editCandidateMode={editCandidateMode}
                SelectedCandidateData={selectedCandidate}
                backToRecruiterListing={() => backToList()}
                setRefreshList={setRefreshList}
                setEditCandidateMode={setEditCandidateMode}
              />
            ) : (
              <CandidateDetails
                removeResponsiveClass={() => setResponsive(false)}
                openEditForm={openEditForm}
                SelectedCandidateData={selectedCandidate}
                candidateList={data?.rows}
                value={value}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Candidates;
