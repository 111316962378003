import React from "react";

const BagSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3337 17.5V4.16667C13.3337 3.72464 13.1581 3.30072 12.8455 2.98816C12.5329 2.67559 12.109 2.5 11.667 2.5H8.33366C7.89163 2.5 7.46771 2.67559 7.15515 2.98816C6.84259 3.30072 6.66699 3.72464 6.66699 4.16667V17.5M3.33366 5.83333H16.667C17.5875 5.83333 18.3337 6.57953 18.3337 7.5V15.8333C18.3337 16.7538 17.5875 17.5 16.667 17.5H3.33366C2.41318 17.5 1.66699 16.7538 1.66699 15.8333V7.5C1.66699 6.57953 2.41318 5.83333 3.33366 5.83333Z"
        stroke="#6C737F"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default BagSvg;
