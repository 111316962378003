import React from "react";

const TagIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.70833 3.70833H3.71375M11.0696 7.18042L7.18583 11.0642C7.08522 11.1649 6.96574 11.2448 6.83423 11.2993C6.70271 11.3538 6.56174 11.3819 6.41937 11.3819C6.27701 11.3819 6.13604 11.3538 6.00452 11.2993C5.87301 11.2448 5.75353 11.1649 5.65292 11.0642L1 6.41667V1H6.41667L11.0696 5.65292C11.2714 5.85589 11.3846 6.13046 11.3846 6.41667C11.3846 6.70287 11.2714 6.97744 11.0696 7.18042Z"
        stroke="#150823"
        strokeWidth="1.08333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default TagIcon;


