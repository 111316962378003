import React, { useState, useEffect } from "react";
import ScheduledInterviewsListing from "@/pages/MyJobs/ScheduledInterviews/ScheduledInterviewsListing";
import ScheduledInterviewsFeedback from "@/pages/MyJobs/ScheduledInterviews/ScheduledInterviewsFeedback";
import RecentInterviews from "@/pages/MyJobs/ScheduledInterviews/RecentInterviews";
import RecordingInterviews from "@/pages/MyJobs/ScheduledInterviews/RecordingInterviews";
import { useSelector, useDispatch } from "react-redux";
import fetchRecording from "@/rtk-apis/interviews/fetchRecording";
import Loader from "@/elements/Loader";

function ScheduledInterviews({ selectedJob }) {
  const dispatch = useDispatch();

  const { data, loading } = useSelector((globalState) => {
    return globalState.fetchRecordingResponse;
  });

  const [detailPage, setDetailPage] = useState(false);
  const [selectedInterview, setSelectedInterview] = useState({});
  const [openFeedback, setOpenFeedback] = useState("");
  const [recordingPage, setRecordingPage] = useState(false);

  const showDetails = (interview) => {
    dispatch(
      fetchRecording({
        interviewId: interview.interviewId,
      })
    );
    setDetailPage(true);
  };

  const backtoListing = () => {
    setOpenFeedback("");
    setDetailPage(false);
  };

  useEffect(() => {
    if (data?.interviewId) {
      setSelectedInterview(data);
    }
  }, [data]);

  return (
    <>
      {openFeedback ? (
        <ScheduledInterviewsFeedback
          goBack={backtoListing}
          row={selectedInterview}
          btnTitle={openFeedback}
        />
      ) : (
        <>
          {detailPage ? (
            <RecentInterviews
              hideDetail={() => {
                setDetailPage(false);
                setRecordingPage(false);
              }}
              selectedInterview={data || selectedInterview}
              selectedJob={selectedJob}
              openRecordingPage={() => {
                setDetailPage(false);
                setRecordingPage(true);
              }}
            />
          ) : recordingPage ? (
            <RecordingInterviews
              hideDetail={() => {
                setDetailPage(false);
                setRecordingPage(false);
              }}
              selectedInterview={data}
            />
          ) : (
            <ScheduledInterviewsListing
              openFeedbackPage={(btnName) => setOpenFeedback(btnName)}
              showDetails={showDetails}
              selectedJob={selectedJob}
            />
          )}
        </>
      )}
      {loading && <Loader />}
    </>
  );
}

export default ScheduledInterviews;
