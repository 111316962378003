import React from "react";

function InterviewsIcon() {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.0003 6.51515C1.0003 6.75894 1.10526 6.99274 1.29207 7.16512C1.47889 7.3375 1.73227 7.43434 1.99647 7.43434H7.97348L9.96582 9.27273V1.91919C9.96582 1.67541 9.86087 1.44161 9.67405 1.26923C9.48723 1.09684 9.23385 1 8.96965 1H1.99647C1.73227 1 1.47889 1.09684 1.29207 1.26923C1.10526 1.44161 1.0003 1.67541 1.0003 1.91919V6.51515Z"
        stroke="#6C737F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0003 15.0004V13.7277C21.0003 13.0526 20.7097 12.4052 20.1923 11.9278C19.675 11.4504 18.9733 11.1823 18.2417 11.1823H12.7244C11.9928 11.1823 11.2911 11.4504 10.7738 11.9278C10.2565 12.4052 9.96582 13.0526 9.96582 13.7277V15.0004M18.2417 6.09135C18.2417 7.49717 17.0066 8.63681 15.4831 8.63681C13.9595 8.63681 12.7244 7.49717 12.7244 6.09135C12.7244 4.68554 13.9595 3.5459 15.4831 3.5459C17.0066 3.5459 18.2417 4.68554 18.2417 6.09135Z"
        stroke="#6C737F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default InterviewsIcon;
