import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LinkedinIcon from "@/assets/svgs/LinkedinIcon";
import DownloadIcon from "@/assets/svgs/DownloadIcon";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import DefaultImg from "@/assets/images/default-img.png";
import downloadJD from "@/rtk-apis/myJobs/downloadJd";
import Loader from "@/elements/Loader";
import { formattedDate } from "@/utils/helper";
import { cleanJdUrl } from "@/store/slices/myJobs/downloadJDSlice";
import ArrowDownBlue from "@/assets/svgs/ArrowUpBlue";
import DetailModal from "@/components/modals/DetailModal";
import { GetUserDetails, firstLetterCapital } from "@/utils/helper";

function JobDetails({ selectedJobData, middleTabValue }) {
  const user = GetUserDetails();
  const dispatch = useDispatch();
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [jtmDetails, setjtmDetails] = useState({});
  const [JobStatus, setJobStatus] = useState("");

  const { data, loading } = useSelector((globalState) => {
    return globalState.downloadJDResponse;
  });

  const downloadJd = () => {
    dispatch(
      downloadJD({
        jobId: selectedJobData?.jobId,
      })
    );
  };

  useEffect(() => {
    if (data) {
      window.open(data, "_blank");
      dispatch(cleanJdUrl());
    }
  }, [data]);

  const valuesArray = [
    selectedJobData?.company?.address,
    selectedJobData?.company?.city,
    selectedJobData?.company?.state,
    selectedJobData?.company?.zipcode,
    selectedJobData?.company?.country,
  ].filter((value) => value);
  const formattedAddress = valuesArray.join(", ");

  const getCompensationValue = (job) => {
    if (job?.baseSalary === "Range") {
      return `$ ${job?.minCompensation} - $ ${job?.maxCompensation} ${job?.frequency}`;
    } else if (job?.baseSalary === "Starting at") {
      return `$ ${job?.minCompensation} ${job?.frequency}`;
    } else if (job?.baseSalary === "Up to") {
      return `$ ${job?.maxCompensation} ${job?.frequency}`;
    } else if (job?.baseSalary === "Exact Pay") {
      return `$ ${job?.maxCompensation} ${job?.frequency}`;
    } else {
      return `${"--"}`;
    }
  };

  useEffect(() => {
    getJobStatus(selectedJobData);
  }, [selectedJobData]);

  const getJobStatus = (JobData) => {
    const recruiterId = user?.recruiter?.recruiterId;
    let desiredRecruiter = JobData?.approvedRecruiter?.find(
      (recruiter) => recruiter.recruiterId === recruiterId
    );
    const status =
      desiredRecruiter?.status === "active"
        ? "Open"
        : desiredRecruiter?.status === "paused"
        ? "paused"
        : desiredRecruiter?.status === "reopen"
        ? "reopen"
        : "closed";
    setJobStatus(status);
  };

  return (
    <>
      {Object.keys(selectedJobData).length ? (
        <div className="details">
          <div className="jobsProfile">
            <div className="jobsProfile__profile">
              <div className="pic">
                <img
                  src={
                    selectedJobData?.company?.companyLogo
                      ? selectedJobData?.company?.companyLogo
                      : DefaultImg
                  }
                  alt=""
                />
              </div>
              <div className="profileDetail">
                <h4>{selectedJobData?.company?.companyName}</h4>
                <p>{selectedJobData?.company?.industryType}</p>
                <p>{formattedAddress}</p>
              </div>
            </div>
            <div className="jobsProfile__actions">
              {selectedJobData?.company?.status === "delete" && (
                <div className="red-bg-50 red-text-500 alert-tag" style={{fontSize:'13px'}}>
                  Account Deactivated
                </div>
              )}
              <Tooltip title="LinkedIn" placement="top" arrow>
                <Link
                  target={
                    selectedJobData?.company?.linkedin ? "_blank" : undefined
                  }
                  to={
                    selectedJobData?.company?.linkedin
                      ? selectedJobData?.company?.linkedin
                      : "#"
                  }
                  className="btn btn__primary"
                >
                  <LinkedinIcon />
                </Link>
              </Tooltip>
              {middleTabValue == "1" && (
                <Tooltip title="Download JD" placement="top" arrow>
                  <span>
                    <button
                      onClick={downloadJd}
                      className={`btn btn__white ${
                        JobStatus === "closed" || JobStatus === "paused"
                          ? "disabled"
                          : ""
                      }`}
                      disabled={
                        JobStatus === "closed" || JobStatus === "paused"
                          ? true
                          : false
                      }
                    >
                      <DownloadIcon />
                    </button>
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
          <h4 className="details__section--title">Job Details</h4>
          <div className="fieldBox flexRow">
            <div className="flexCol">
              <label className="flexCol__label">Department</label>
              <div className="flexCol__text">{selectedJobData?.department}</div>
            </div>
            <div className="flexCol">
              <label className="flexCol__label">Location</label>
              <div className="flexCol__text">{selectedJobData?.location}</div>
            </div>
          </div>
          <div className="fieldBox flexRow">
            <div className="flexCol">
              <label className="flexCol__label">Employment Type</label>
              <div className="flexCol__text">
                {selectedJobData?.employmentType.join(", ")}
              </div>
            </div>
            <div className="flexCol">
              <label className="flexCol__label">Base Salary</label>
              <div className="flexCol__text">
                {getCompensationValue(selectedJobData)}
              </div>
            </div>
          </div>
          <div className="fieldBox flexRow">
            <div className="flexCol">
              <label className="flexCol__label">Experience</label>
              {selectedJobData?.minExperience ||
              selectedJobData?.maxExperience ? (
                <div className="flexCol__text">
                  {selectedJobData?.minExperience <= 1
                    ? selectedJobData?.minExperience + " Year"
                    : selectedJobData?.minExperience + " Years"}{" "}
                  -{" "}
                  {selectedJobData?.maxExperience <= 1
                    ? selectedJobData?.maxExperience + " Year"
                    : selectedJobData?.maxExperience + " Years"}
                </div>
              ) : (
                "--"
              )}
            </div>
            <div className="flexCol">
              <label className="flexCol__label">Application Deadline</label>
              <div className="flexCol__text">
                {formattedDate(selectedJobData?.applicationDeadline) || "--"}
              </div>
            </div>
          </div>
          <div className="fieldBox flexRow">
            <div className="flexCol">
              <label className="flexCol__label">No of Hires</label>
              <div className="flexCol__text">{selectedJobData?.noOfHires}</div>
            </div>
            <div className="flexCol">
              <label className="flexCol__label">Required Qualification</label>
              <div className="flexCol__text">
                {selectedJobData?.qualification
                  ? selectedJobData?.qualification.join(", ")
                  : "--"}
              </div>
            </div>
          </div>
          <div className="fieldBox flexRow">
            <div className="flexCol">
              <label className="flexCol__label">Job Team Member</label>
              {selectedJobData?.jobTeamMember?.map((jtm, index) => {
                return (
                  <React.Fragment key={index}>
                    <Link
                      className="link link__primary"
                      onClick={(e) => {
                        setOpenDetailModal(true);
                        setjtmDetails(jtm);
                      }}
                    >
                      {firstLetterCapital(jtm?.firstName) +
                        " " +
                        firstLetterCapital(jtm?.lastName)}
                    </Link>
                    {index < selectedJobData.jobTeamMember.length - 1 && ", "}
                  </React.Fragment>
                );
              })}
            </div>
            <div className="flexCol">
              <label className="flexCol__label">Hiring Lead</label>
              <Link
                className="link link__primary"
                onClick={(e) => {
                  setOpenDetailModal(true);
                  setjtmDetails(selectedJobData?.hiringLead[0]);
                }}
              >
                {firstLetterCapital(selectedJobData?.hiringLead[0]?.firstName) +
                  " " +
                  firstLetterCapital(selectedJobData?.hiringLead[0]?.lastName)}
              </Link>
            </div>
          </div>
          <div className="fieldBox flexRow">
            <div className="flexCol">
              <label className="flexCol__label">Company's Website</label>
              {selectedJobData?.company?.websiteLink ? (
                <Link
                  target="_blank"
                  className="link link__primary link__center"
                  to={selectedJobData?.company?.websiteLink}
                >
                  {selectedJobData?.company?.websiteLink}
                  {<ArrowDownBlue />}
                </Link>
              ) : (
                "--"
              )}
            </div>
          </div>
          <div className="fieldBox">
            <label className="flexCol__label">Skill set</label>
            <div className="flexCol__text">
              {selectedJobData?.skillSet
                ? selectedJobData?.skillSet.join(", ")
                : "--"}
            </div>
          </div>
          <div className="fieldBox">
            <label className="flexCol__label">Job Description</label>
            <div
              className="showQuillTags"
              dangerouslySetInnerHTML={{
                __html: selectedJobData?.description
                  ? selectedJobData?.description
                  : "--",
              }}
            ></div>
          </div>
        </div>
      ) : (
        <div className="no-data floating">No Results Found!</div>
      )}
      {loading && <Loader />}
      <DetailModal
        openModal={openDetailModal}
        closeModal={() => setOpenDetailModal(false)}
        data={jtmDetails}
      />
    </>
  );
}

export default JobDetails;
