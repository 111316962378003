import React from 'react';

function ColorPickerIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="19" height="19" rx="9.5" fill="#1B1B1B" />
      <path
        d="M5.5767 2L6.23054 2.3775V3.13249L5.5767 3.50999L4.92285 3.13249V2.3775L5.5767 2Z"
        fill="#123262"
      />
      <path
        d="M6.88431 2L7.53816 2.3775V3.13249L6.88431 3.50999L6.23047 3.13249V2.3775L6.88431 2Z"
        fill="#406595"
      />
      <path
        d="M8.19193 2L8.84578 2.3775V3.13249L8.19193 3.50999L7.53809 3.13249V2.3775L8.19193 2Z"
        fill="#4065C5"
      />
      <path
        d="M9.49955 2L10.1534 2.3775V3.13249L9.49955 3.50999L8.8457 3.13249V2.3775L9.49955 2Z"
        fill="#123293"
      />
      <path
        d="M10.8077 2L11.4615 2.3775V3.13249L10.8077 3.50999L10.1538 3.13249V2.3775L10.8077 2Z"
        fill="#000093"
      />
      <path
        d="M12.1153 2L12.7691 2.3775V3.13249L12.1153 3.50999L11.4614 3.13249V2.3775L12.1153 2Z"
        fill="#0000C4"
      />
      <path
        d="M13.4229 2L14.0767 2.3775V3.13249L13.4229 3.50999L12.769 3.13249V2.3775L13.4229 2Z"
        fill="#000061"
      />
      <path
        d="M4.92387 3.13672L5.57771 3.51422V4.26921L4.92387 4.64671L4.27002 4.26921V3.51422L4.92387 3.13672Z"
        fill="#2B6465"
      />
      <path
        d="M6.23148 3.13672L6.88533 3.51422V4.26921L6.23148 4.64671L5.57764 4.26921V3.51422L6.23148 3.13672Z"
        fill="#2B6495"
      />
      <path
        d="M7.5391 3.13672L8.19295 3.51422V4.26921L7.5391 4.64671L6.88525 4.26921V3.51422L7.5391 3.13672Z"
        fill="#4397C7"
      />
      <path
        d="M8.84672 3.13672L9.50056 3.51422V4.26921L8.84672 4.64671L8.19287 4.26921V3.51422L8.84672 3.13672Z"
        fill="#2B64C5"
      />
      <path
        d="M10.1548 3.13672L10.8087 3.51422V4.26921L10.1548 4.64671L9.50098 4.26921V3.51422L10.1548 3.13672Z"
        fill="#1232C4"
      />
      <path
        d="M11.4624 3.13672L12.1163 3.51422V4.26921L11.4624 4.64671L10.8086 4.26921V3.51422L11.4624 3.13672Z"
        fill="#0000F5"
      />
      <path
        d="M12.7701 3.13672L13.4239 3.51422V4.26921L12.7701 4.64671L12.1162 4.26921V3.51422L12.7701 3.13672Z"
        fill="#3333F5"
      />
      <path
        d="M14.0777 3.13672L14.7315 3.51422V4.26921L14.0777 4.64671L13.4238 4.26921V3.51422L14.0777 3.13672Z"
        fill="#333393"
      />
      <path
        d="M4.26957 4.27344L4.92341 4.65094V5.40593L4.26957 5.78343L3.61572 5.40593V4.65094L4.26957 4.27344Z"
        fill="#719898"
      />
      <path
        d="M5.57719 4.27344L6.23103 4.65094V5.40593L5.57719 5.78343L4.92334 5.40593V4.65094L5.57719 4.27344Z"
        fill="#439798"
      />
      <path
        d="M6.8848 4.27344L7.53865 4.65094V5.40593L6.8848 5.78343L6.23096 5.40593V4.65094L6.8848 4.27344Z"
        fill="#67C9CB"
      />
      <path
        d="M8.19242 4.27344L8.84627 4.65094V5.40593L8.19242 5.78343L7.53857 5.40593V4.65094L8.19242 4.27344Z"
        fill="#5CC9FA"
      />
      <path
        d="M9.50053 4.27344L10.1544 4.65094V5.40593L9.50053 5.78343L8.84668 5.40593V4.65094L9.50053 4.27344Z"
        fill="#4397F7"
      />
      <path
        d="M10.8081 4.27344L11.462 4.65094V5.40593L10.8081 5.78343L10.1543 5.40593V4.65094L10.8081 4.27344Z"
        fill="#2B64F6"
      />
      <path
        d="M12.1158 4.27344L12.7696 4.65094V5.40593L12.1158 5.78343L11.4619 5.40593V4.65094L12.1158 4.27344Z"
        fill="#4065F6"
      />
      <path
        d="M13.4234 4.27344L14.0772 4.65094V5.40593L13.4234 5.78343L12.7695 5.40593V4.65094L13.4234 4.27344Z"
        fill="#3333C4"
      />
      <path
        d="M14.731 4.27344L15.3848 4.65094V5.40593L14.731 5.78343L14.0771 5.40593V4.65094L14.731 4.27344Z"
        fill="#480EC4"
      />
      <path
        d="M3.61527 5.41016L4.26912 5.78765V6.54265L3.61527 6.92015L2.96143 6.54265V5.78765L3.61527 5.41016Z"
        fill="#52976A"
      />
      <path
        d="M4.92289 5.41016L5.57674 5.78765V6.54265L4.92289 6.92015L4.26904 6.54265V5.78765L4.92289 5.41016Z"
        fill="#5CC99C"
      />
      <path
        d="M6.23051 5.41016L6.88435 5.78765V6.54265L6.23051 6.92015L5.57666 6.54265V5.78765L6.23051 5.41016Z"
        fill="#75FBCF"
      />
      <path
        d="M7.53812 5.41016L8.19197 5.78765V6.54265L7.53812 6.92015L6.88428 6.54265V5.78765L7.53812 5.41016Z"
        fill="#75FBFD"
      />
      <path
        d="M8.84623 5.41016L9.50008 5.78765V6.54265L8.84623 6.92015L8.19238 6.54265V5.78765L8.84623 5.41016Z"
        fill="#67C9FA"
      />
      <path
        d="M10.1538 5.41016L10.8077 5.78765V6.54265L10.1538 6.92015L9.5 6.54265V5.78765L10.1538 5.41016Z"
        fill="#5297F7"
      />
      <path
        d="M11.4615 5.41016L12.1153 5.78765V6.54265L11.4615 6.92015L10.8076 6.54265V5.78765L11.4615 5.41016Z"
        fill="#7198F8"
      />
      <path
        d="M12.2086 5.84159L12.7691 5.51802L13.3295 5.84159V6.48872L12.7691 6.81229L12.2086 6.48872V5.84159Z"
        fill="#6666F6"
        stroke="white"
        strokeWidth="0.186824"
      />
      <path
        d="M14.0767 5.41016L14.7305 5.78765V6.54265L14.0767 6.92015L13.4229 6.54265V5.78765L14.0767 5.41016Z"
        fill="#5D0EF5"
      />
      <path
        d="M15.3843 5.41016L16.0382 5.78765V6.54265L15.3843 6.92015L14.7305 6.54265V5.78765L15.3843 5.41016Z"
        fill="#5D0EC4"
      />
      <path
        d="M2.96097 6.54492L3.61482 6.92242V7.67742L2.96097 8.05491L2.30713 7.67742V6.92242L2.96097 6.54492Z"
        fill="#529742"
      />
      <path
        d="M4.26859 6.54492L4.92244 6.92242V7.67742L4.26859 8.05491L3.61475 7.67742V6.92242L4.26859 6.54492Z"
        fill="#5CC971"
      />
      <path
        d="M5.57621 6.54492L6.23006 6.92242V7.67742L5.57621 8.05491L4.92236 7.67742V6.92242L5.57621 6.54492Z"
        fill="#75FBA2"
      />
      <path
        d="M6.88383 6.54492L7.53767 6.92242V7.67742L6.88383 8.05491L6.22998 7.67742V6.92242L6.88383 6.54492Z"
        fill="#92FCCF"
      />
      <path
        d="M8.19193 6.54492L8.84578 6.92242V7.67742L8.19193 8.05491L7.53809 7.67742V6.92242L8.19193 6.54492Z"
        fill="#92FCFD"
      />
      <path
        d="M9.49955 6.54492L10.1534 6.92242V7.67742L9.49955 8.05491L8.8457 7.67742V6.92242L9.49955 6.54492Z"
        fill="#80CAFA"
      />
      <path
        d="M10.8072 6.54492L11.461 6.92242V7.67742L10.8072 8.05491L10.1533 7.67742V6.92242L10.8072 6.54492Z"
        fill="#A4CBFA"
      />
      <path
        d="M12.1148 6.54492L12.7686 6.92242V7.67742L12.1148 8.05491L11.4609 7.67742V6.92242L12.1148 6.54492Z"
        fill="#9999F8"
      />
      <path
        d="M13.4224 6.54492L14.0762 6.92242V7.67742L13.4224 8.05491L12.7686 7.67742V6.92242L13.4224 6.54492Z"
        fill="#9268F6"
      />
      <path
        d="M14.73 6.54492L15.3839 6.92242V7.67742L14.73 8.05491L14.0762 7.67742V6.92242L14.73 6.54492Z"
        fill="#8D3AF6"
      />
      <path
        d="M16.0381 6.54492L16.692 6.92242V7.67742L16.0381 8.05491L15.3843 7.67742V6.92242L16.0381 6.54492Z"
        fill="#8C1AF5"
      />
      <path
        d="M2.30814 7.66602L2.96199 8.04351V8.79851L2.30814 9.17601L1.6543 8.79851V8.04351L2.30814 7.66602Z"
        fill="#2B6419"
      />
      <path
        d="M3.61576 7.66602L4.26961 8.04351V8.79851L3.61576 9.17601L2.96191 8.79851V8.04351L3.61576 7.66602Z"
        fill="#5CC93B"
      />
      <path
        d="M4.92338 7.66602L5.57722 8.04351V8.79851L4.92338 9.17601L4.26953 8.79851V8.04351L4.92338 7.66602Z"
        fill="#75FB4C"
      />
      <path
        d="M6.23099 7.66602L6.88484 8.04351V8.79851L6.23099 9.17601L5.57715 8.79851V8.04351L6.23099 7.66602Z"
        fill="#92FCA3"
      />
      <path
        d="M7.5391 7.66602L8.19295 8.04351V8.79851L7.5391 9.17601L6.88525 8.79851V8.04351L7.5391 7.66602Z"
        fill="#B1FCD0"
      />
      <path
        d="M8.84672 7.66602L9.50056 8.04351V8.79851L8.84672 9.17601L8.19287 8.79851V8.04351L8.84672 7.66602Z"
        fill="#D6FEFE"
      />
      <path
        d="M10.1543 7.66602L10.8082 8.04351V8.79851L10.1543 9.17601L9.50049 8.79851V8.04351L10.1543 7.66602Z"
        fill="#CCCCFB"
      />
      <path
        d="M11.462 7.66602L12.1158 8.04351V8.79851L11.462 9.17601L10.8081 8.79851V8.04351L11.462 7.66602Z"
        fill="#C49BF9"
      />
      <path
        d="M12.7696 7.66602L13.4234 8.04351V8.79851L12.7696 9.17601L12.1157 8.79851V8.04351L12.7696 7.66602Z"
        fill="#BF6BF7"
      />
      <path
        d="M14.0772 7.66602L14.731 8.04351V8.79851L14.0772 9.17601L13.4233 8.79851V8.04351L14.0772 7.66602Z"
        fill="#BC40F6"
      />
      <path
        d="M15.3853 7.66602L16.0391 8.04351V8.79851L15.3853 9.17601L14.7314 8.79851V8.04351L15.3853 7.66602Z"
        fill="#BB27F6"
      />
      <path
        d="M16.6929 7.66602L17.3468 8.04351V8.79851L16.6929 9.17601L16.0391 8.79851V8.04351L16.6929 7.66602Z"
        fill="#8C1AC5"
      />
      <path
        d="M1.65385 8.80273L2.30769 9.18023V9.93523L1.65385 10.3127L1 9.93523V9.18023L1.65385 8.80273Z"
        fill="#123208"
      />
      <path
        d="M2.96146 8.80273L3.61531 9.18023V9.93523L2.96146 10.3127L2.30762 9.93523V9.18023L2.96146 8.80273Z"
        fill="#439741"
      />
      <path
        d="M4.26957 8.80273L4.92341 9.18023V9.93523L4.26957 10.3127L3.61572 9.93523V9.18023L4.26957 8.80273Z"
        fill="#67C94D"
      />
      <path
        d="M5.5767 8.80273L6.23054 9.18023V9.93523L5.5767 10.3127L4.92285 9.93523V9.18023L5.5767 8.80273Z"
        fill="#92FC7A"
      />
      <path
        d="M6.8848 8.80273L7.53865 9.18023V9.93523L6.8848 10.3127L6.23096 9.93523V9.18023L6.8848 8.80273Z"
        fill="#B1FCA3"
      />
      <path
        d="M8.19242 8.80273L8.84627 9.18023V9.93523L8.19242 10.3127L7.53857 9.93523V9.18023L8.19242 8.80273Z"
        fill="#D6FED0"
      />
      <path
        d="M9.50004 8.80273L10.1539 9.18023V9.93523L9.50004 10.3127L8.84619 9.93523V9.18023L9.50004 8.80273Z"
        fill="white"
      />
      <path
        d="M10.8077 8.80273L11.4615 9.18023V9.93523L10.8077 10.3127L10.1538 9.93523V9.18023L10.8077 8.80273Z"
        fill="#F7CEFC"
      />
      <path
        d="M12.1153 8.80273L12.7691 9.18023V9.93523L12.1153 10.3127L11.4614 9.93523V9.18023L12.1153 8.80273Z"
        fill="#F19EF9"
      />
      <path
        d="M13.4229 8.80273L14.0767 9.18023V9.93523L13.4229 10.3127L12.769 9.93523V9.18023L13.4229 8.80273Z"
        fill="#ED70F8"
      />
      <path
        d="M14.731 8.80273L15.3848 9.18023V9.93523L14.731 10.3127L14.0771 9.93523V9.18023L14.731 8.80273Z"
        fill="#EA33F7"
      />
      <path
        d="M16.0386 8.80273L16.6925 9.18023V9.93523L16.0386 10.3127L15.3848 9.93523V9.18023L16.0386 8.80273Z"
        fill="#BB27C5"
      />
      <path
        d="M17.3462 8.80273L18.0001 9.18023V9.93523L17.3462 10.3127L16.6924 9.93523V9.18023L17.3462 8.80273Z"
        fill="#5D0E62"
      />
      <path
        d="M2.30814 9.93945L2.96199 10.317V11.0719L2.30814 11.4494L1.6543 11.0719V10.317L2.30814 9.93945Z"
        fill="#40651A"
      />
      <path
        d="M3.61576 9.93945L4.26961 10.317V11.0719L3.61576 11.4494L2.96191 11.0719V10.317L3.61576 9.93945Z"
        fill="#43972A"
      />
      <path
        d="M4.92338 9.93945L5.57722 10.317V11.0719L4.92338 11.4494L4.26953 11.0719V10.317L4.92338 9.93945Z"
        fill="#92FC5B"
      />
      <path
        d="M6.23099 9.93945L6.88484 10.317V11.0719L6.23099 11.4494L5.57715 11.0719V10.317L6.23099 9.93945Z"
        fill="#B1FC7B"
      />
      <path
        d="M7.5391 9.93945L8.19295 10.317V11.0719L7.5391 11.4494L6.88525 11.0719V10.317L7.5391 9.93945Z"
        fill="#D6FEA4"
      />
      <path
        d="M8.84672 9.93945L9.50056 10.317V11.0719L8.84672 11.4494L8.19287 11.0719V10.317L8.84672 9.93945Z"
        fill="#FFFFD1"
      />
      <path
        d="M10.1543 9.93945L10.8082 10.317V11.0719L10.1543 11.4494L9.50049 11.0719V10.317L10.1543 9.93945Z"
        fill="#F7CECD"
      />
      <path
        d="M11.462 9.93945L12.1158 10.317V11.0719L11.462 11.4494L10.8081 11.0719V10.317L11.462 9.93945Z"
        fill="#F19ECA"
      />
      <path
        d="M12.7696 9.93945L13.4234 10.317V11.0719L12.7696 11.4494L12.1157 11.0719V10.317L12.7696 9.93945Z"
        fill="#ED70C8"
      />
      <path
        d="M14.0772 9.93945L14.731 10.317V11.0719L14.0772 11.4494L13.4233 11.0719V10.317L14.0772 9.93945Z"
        fill="#EB48C7"
      />
      <path
        d="M15.3853 9.93945L16.0391 10.317V11.0719L15.3853 11.4494L14.7314 11.0719V10.317L15.3853 9.93945Z"
        fill="#BB2795"
      />
      <path
        d="M16.6929 9.93945L17.3468 10.317V11.0719L16.6929 11.4494L16.0391 11.0719V10.317L16.6929 9.93945Z"
        fill="#8D3A94"
      />
      <path
        d="M2.96097 11.0762L3.61482 11.4537V12.2087L2.96097 12.5862L2.30713 12.2087V11.4537L2.96097 11.0762Z"
        fill="#5E7729"
      />
      <path
        d="M4.26859 11.0762L4.92244 11.4537V12.2087L4.26859 12.5862L3.61475 12.2087V11.4537L4.26859 11.0762Z"
        fill="#71982C"
      />
      <path
        d="M5.57621 11.0762L6.23006 11.4537V12.2087L5.57621 12.5862L4.92236 12.2087V11.4537L5.57621 11.0762Z"
        fill="#B1FC5C"
      />
      <path
        d="M6.88383 11.0762L7.53767 11.4537V12.2087L6.88383 12.5862L6.22998 12.2087V11.4537L6.88383 11.0762Z"
        fill="#D6FE7D"
      />
      <path
        d="M8.19193 11.0762L8.84578 11.4537V12.2087L8.19193 12.5862L7.53809 12.2087V11.4537L8.19193 11.0762Z"
        fill="#FFFFA6"
      />
      <path
        d="M9.49955 11.0762L10.1534 11.4537V12.2087L9.49955 12.5862L8.8457 12.2087V11.4537L9.49955 11.0762Z"
        fill="#F7CEA0"
      />
      <path
        d="M10.8072 11.0762L11.461 11.4537V12.2087L10.8072 12.5862L10.1533 12.2087V11.4537L10.8072 11.0762Z"
        fill="#F19E9C"
      />
      <path
        d="M12.1148 11.0762L12.7686 11.4537V12.2087L12.1148 12.5862L11.4609 12.2087V11.4537L12.1148 11.0762Z"
        fill="#ED7099"
      />
      <path
        d="M13.4224 11.0762L14.0762 11.4537V12.2087L13.4224 12.5862L12.7686 12.2087V11.4537L13.4224 11.0762Z"
        fill="#EB4897"
      />
      <path
        d="M14.73 11.0762L15.3839 11.4537V12.2087L14.73 12.5862L14.0762 12.2087V11.4537L14.73 11.0762Z"
        fill="#BC4096"
      />
      <path
        d="M16.0381 11.0762L16.692 11.4537V12.2087L16.0381 12.5862L15.3843 12.2087V11.4537L16.0381 11.0762Z"
        fill="#8C1A94"
      />
      <path
        d="M3.61527 12.2109L4.26912 12.5884V13.3434L3.61527 13.7209L2.96143 13.3434V12.5884L3.61527 12.2109Z"
        fill="#66663A"
      />
      <path
        d="M4.92289 12.2109L5.57674 12.5884V13.3434L4.92289 13.7209L4.26904 13.3434V12.5884L4.92289 12.2109Z"
        fill="#A4CB3F"
      />
      <path
        d="M6.23051 12.2109L6.88435 12.5884V13.3434L6.23051 13.7209L5.57666 13.3434V12.5884L6.23051 12.2109Z"
        fill="#D6FE5E"
      />
      <path
        d="M7.53812 12.2109L8.19197 12.5884V13.3434L7.53812 13.7209L6.88428 13.3434V12.5884L7.53812 12.2109Z"
        fill="#FFFF7F"
      />
      <path
        d="M8.84623 12.2109L9.50008 12.5884V13.3434L8.84623 13.7209L8.19238 13.3434V12.5884L8.84623 12.2109Z"
        fill="#F7CE76"
      />
      <path
        d="M10.1538 12.2109L10.8077 12.5884V13.3434L10.1538 13.7209L9.5 13.3434V12.5884L10.1538 12.2109Z"
        fill="#F19E70"
      />
      <path
        d="M11.4615 12.2109L12.1153 12.5884V13.3434L11.4615 13.7209L10.8076 13.3434V12.5884L11.4615 12.2109Z"
        fill="#ED706B"
      />
      <path
        d="M12.7691 12.2109L13.4229 12.5884V13.3434L12.7691 13.7209L12.1152 13.3434V12.5884L12.7691 12.2109Z"
        fill="#EA3368"
      />
      <path
        d="M14.0767 12.2109L14.7305 12.5884V13.3434L14.0767 13.7209L13.4229 13.3434V12.5884L14.0767 12.2109Z"
        fill="#CC3071"
      />
      <path
        d="M15.3843 12.2109L16.0382 12.5884V13.3434L15.3843 13.7209L14.7305 13.3434V12.5884L15.3843 12.2109Z"
        fill="#8D3A65"
      />
      <path
        d="M4.26957 13.3496L4.92341 13.7271V14.4821L4.26957 14.8596L3.61572 14.4821V13.7271L4.26957 13.3496Z"
        fill="#A58C4E"
      />
      <path
        d="M5.57719 13.3496L6.23103 13.7271V14.4821L5.57719 14.8596L4.92334 14.4821V13.7271L5.57719 13.3496Z"
        fill="#CCCC42"
      />
      <path
        d="M6.8848 13.3496L7.53865 13.7271V14.4821L6.8848 14.8596L6.23096 14.4821V13.7271L6.8848 13.3496Z"
        fill="#FFFF54"
      />
      <path
        d="M8.19242 13.3496L8.84627 13.7271V14.4821L8.19242 14.8596L7.53857 14.4821V13.7271L8.19242 13.3496Z"
        fill="#F7CE46"
      />
      <path
        d="M9.50053 13.3496L10.1544 13.7271V14.4821L9.50053 14.8596L8.84668 14.4821V13.7271L9.50053 13.3496Z"
        fill="#F19E4B"
      />
      <path
        d="M10.8081 13.3496L11.462 13.7271V14.4821L10.8081 14.8596L10.1543 14.4821V13.7271L10.8081 13.3496Z"
        fill="#ED702D"
      />
      <path
        d="M12.1158 13.3496L12.7696 13.7271V14.4821L12.1158 14.8596L11.4619 14.4821V13.7271L12.1158 13.3496Z"
        fill="#EC5E57"
      />
      <path
        d="M13.4234 13.3496L14.0772 13.7271V14.4821L13.4234 14.8596L12.7695 14.4821V13.7271L13.4234 13.3496Z"
        fill="#BB2765"
      />
      <path
        d="M14.731 13.3496L15.3848 13.7271V14.4821L14.731 14.8596L14.0771 14.4821V13.7271L14.731 13.3496Z"
        fill="#5D0E32"
      />
      <path
        d="M4.92387 14.4844L5.57771 14.8619V15.6169L4.92387 15.9944L4.27002 15.6169V14.8619L4.92387 14.4844Z"
        fill="#92683C"
      />
      <path
        d="M6.23148 14.4844L6.88533 14.8619V15.6169L6.23148 15.9944L5.57764 15.6169V14.8619L6.23148 14.4844Z"
        fill="#C49B33"
      />
      <path
        d="M7.5391 14.4844L8.19295 14.8619V15.6169L7.5391 15.9944L6.88525 15.6169V14.8619L7.5391 14.4844Z"
        fill="#F19E38"
      />
      <path
        d="M8.84672 14.4844L9.50056 14.8619V15.6169L8.84672 15.9944L8.19287 15.6169V14.8619L8.84672 14.4844Z"
        fill="#FF7236"
      />
      <path
        d="M10.1548 14.4844L10.8087 14.8619V15.6169L10.1548 15.9944L9.50098 15.6169V14.8619L10.1548 14.4844Z"
        fill="#EB4826"
      />
      <path
        d="M11.4624 14.4844L12.1163 14.8619V15.6169L11.4624 15.9944L10.8086 15.6169V14.8619L11.4624 14.4844Z"
        fill="#EA3323"
      />
      <path
        d="M12.7701 14.4844L13.4239 14.8619V15.6169L12.7701 15.9944L12.1162 15.6169V14.8619L12.7701 14.4844Z"
        fill="#BB271A"
      />
      <path
        d="M14.0777 14.4844L14.7315 14.8619V15.6169L14.0777 15.9944L13.4238 15.6169V14.8619L14.0777 14.4844Z"
        fill="#8C1A35"
      />
      <path
        d="M5.5767 15.6211L6.23054 15.9986V16.7536L5.5767 17.1311L4.92285 16.7536V15.9986L5.5767 15.6211Z"
        fill="#5F360F"
      />
      <path
        d="M6.88431 15.6211L7.53816 15.9986V16.7536L6.88431 17.1311L6.23047 16.7536V15.9986L6.88431 15.6211Z"
        fill="#926821"
      />
      <path
        d="M8.19193 15.6211L8.84578 15.9986V16.7536L8.19193 17.1311L7.53809 16.7536V15.9986L8.19193 15.6211Z"
        fill="#BC401E"
      />
      <path
        d="M9.49955 15.6211L10.1534 15.9986V16.7536L9.49955 17.1311L8.8457 16.7536V15.9986L9.49955 15.6211Z"
        fill="#8D3A16"
      />
      <path
        d="M10.8077 15.6211L11.4615 15.9986V16.7536L10.8077 17.1311L10.1538 16.7536V15.9986L10.8077 15.6211Z"
        fill="#8C1A10"
      />
      <path
        d="M12.1153 15.6211L12.7691 15.9986V16.7536L12.1153 17.1311L11.4614 16.7536V15.9986L12.1153 15.6211Z"
        fill="#75140C"
      />
      <path
        d="M13.4229 15.6211L14.0767 15.9986V16.7536L13.4229 17.1311L12.769 16.7536V15.9986L13.4229 15.6211Z"
        fill="#8D3A37"
      />
    </svg>
  );
}

export default ColorPickerIcon;
