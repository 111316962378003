import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { UPDATE_COMPANY_TAGS } from "@/rtk-apis/end-points";

const updateCustomerTags = createAsyncThunk(
  "updateCustomerTags",
  async (body) => {
    try {
      const response = await httpService.put(UPDATE_COMPANY_TAGS, body);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default updateCustomerTags;
