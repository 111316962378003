import React, { useEffect, useRef, useState } from "react";
import ArrowRightIcon from "@/assets/svgs/ArrowRightIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTwoCharacterInitial, GetUserDetails } from "@/utils/helper";
import CheckIconWhite from "@/assets/svgs/CheckIconWhite";
import MarkNotificationAsRead from "@/rtk-apis/customer/notifications/MarkNotificationAsRead";
import ReactTimeago from "react-timeago";
import deleteNotifications from "@/rtk-apis/customer/notifications/deleteNotifications";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import Loader from "@/elements/Loader";
import { closeDeleteNotificationsModal } from "@/store/slices/customer/notifications/deleteNotificationsSlice";

const NotificationIndex = ({ openModal, closeModal, callNotificationApi }) => {
  const modalRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedInUser = GetUserDetails();
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [hoveredNotificationId, setHoveredNotificationId] = useState(null);
  const [OpenConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectDeselectLabel, setSelectDeselectLabel] = useState("");

  // ************************STATES***********************************

  const { data } = useSelector((globalState) => {
    return globalState.getAllNotificationsResponse;
  });

  const ReadNotificationRes = useSelector((globalState) => {
    return globalState.markNotificationAsReadRes;
  });
  const DeleteNotificationsRes = useSelector((globalState) => {
    return globalState.deleteNotificationsRes;
  });

  // **************************USE EFFECTS*****************************

  useEffect(() => {
    if (openModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openModal]);

  useEffect(() => {
    callNotificationApi();
  }, [ReadNotificationRes?.meta?.status, DeleteNotificationsRes?.meta?.status]);

  useEffect(() => {
    if (selectedNotifications.length === data?.rows?.length) {
      setSelectDeselectLabel("Deselect All");
    } else {
      setSelectDeselectLabel("Select All");
    }
  }, [selectedNotifications, data]);

  // *************** FUNCTIONS ****************************************

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  const handleGoToViewNotification = () => {
    navigate("/notifications");
  };

  const handleReadNotification = (item) => {
    if (item?.status === "Unread") {
      if (!selectedNotifications?.length) {
        if (loggedInUser?.usertype === "customer") {
          let body = {
            notificationId: item?.notificationId,
            readAll: false,
            notificationFor: loggedInUser?.usertype,
            companyId: loggedInUser?.customer?.company?.id,
            employeeid: loggedInUser?.customer?.employeeId,
          };
          dispatch(MarkNotificationAsRead(body));
        } else {
          const body = {
            notificationId: item?.notificationId,
            id: loggedInUser?.recruiter?.recruiterId,
            notificationFor: loggedInUser?.usertype,
          };
          dispatch(MarkNotificationAsRead(body));
        }
      }
    }
  };

  const handleMarkAllAsRead = () => {
    if (loggedInUser?.usertype === "customer") {
      const body = {
        notificationId: null,
        readAll: true,
        notificationFor: loggedInUser?.usertype,
        companyId: loggedInUser?.customer?.company?.id,
        employeeid: loggedInUser?.customer?.employeeId,
      };
      dispatch(MarkNotificationAsRead(body));
    } else {
      const body = {
        notificationId: null,
        readAll: true,
        id: loggedInUser?.recruiter?.recruiterId,
        notificationFor: loggedInUser?.usertype,
      };
      dispatch(MarkNotificationAsRead(body));
    }
  };

  const handleSelectDeselectAll = () => {
    if (selectedNotifications.length === data?.rows?.length) {
      setSelectedNotifications([]);
    } else {
      const allNotificationIds = data?.rows?.map((item) => item.notificationId);
      setSelectedNotifications(allNotificationIds);
    }
  };

  const handleToggleNotificationSelection = (notificationId) => {
    setSelectedNotifications((prevSelected) => {
      if (prevSelected.includes(notificationId)) {
        return prevSelected.filter((id) => id !== notificationId);
      } else {
        return [...prevSelected, notificationId];
      }
    });
  };

  const handleMouseEnter = (notificationId) => {
    setHoveredNotificationId(notificationId);
  };

  const handleMouseLeave = () => {
    setHoveredNotificationId(null);
  };

  const handleDeleteNotifications = () => {
    let body = {
      notificationIdArray: selectedNotifications,
    };
    dispatch(deleteNotifications(body));
  };

  const closeRTKModal = () => {
    setOpenConfirmationModal(false);
    dispatch(closeDeleteNotificationsModal());
    setSelectedNotifications([]);
  };

  const handleNavigateToNotificationOrigin = (item) => {
    if (!selectedNotifications?.length) {
      if (item?.navigateTo !== null) {
        handleReadNotification(item);
        navigate(`/${item?.navigateTo}`);
      }
    }
  };

  return (
    <>
      <div
        ref={modalRef}
        className={`notification ${openModal ? "notification--open" : ""}`}
      >
        <div className="notificationDropdown">
          <div className="notification__header">
            <div className="title">
              Notifications <span className="count">{data?.count}</span>
            </div>
            {data?.count > 0 ? (
              <button className="link" onClick={handleMarkAllAsRead}>
                Mark all as read
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="notification__body">
            {data?.count > 0 ? (
              <div className="notification__body__actions">
                <button className="btn btn__primary">
                  {selectedNotifications?.length === data?.rows?.length
                    ? "All"
                    : "Selected"}{" "}
                  <span>{selectedNotifications?.length}</span>
                </button>
                <div className="btn__group">
                  <button className="link" onClick={handleSelectDeselectAll}>
                    {selectDeselectLabel}
                  </button>
                  {selectedNotifications?.length > 0 ? (
                    <button
                      className="link link__danger"
                      onClick={() => setOpenConfirmationModal(true)}
                    >
                      Delete
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="notificationList">
              {data?.rows?.length > 0 &&
                data?.rows?.map((item, index) => {
                  const isSelected = selectedNotifications.includes(
                    item.notificationId
                  );
                  return (
                    <div
                      key={index}
                      className={`item ${
                        item?.status === "Read" ? "" : "item--unread"
                      } ${isSelected ? "item--selectedRemove" : ""} ${
                        hoveredNotificationId === item.notificationId
                          ? "item--selected"
                          : ""
                      }`}
                      onClick={() => handleReadNotification(item)}
                    >
                      <div
                        className="initials initials__md"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleToggleNotificationSelection(
                            item.notificationId
                          );
                        }}
                        onMouseEnter={() =>
                          handleMouseEnter(item.notificationId)
                        }
                        onMouseLeave={handleMouseLeave}
                      >
                        {isSelected ? (
                          <CheckIconWhite />
                        ) : hoveredNotificationId === item.notificationId ? (
                          <div className="initials initials__md">
                            Click to Select
                          </div>
                        ) : (
                          getTwoCharacterInitial(item?.addedByName)
                        )}
                      </div>
                      <div className="item__info">
                        <h4 className="item__info--title">
                          {item?.notificationTitle}
                        </h4>
                        <div className="item__info--subtitle">
                          <p>
                            <ReactTimeago date={new Date(item?.createdAt)} />
                          </p>
                        </div>
                        <span
                          className="arrowRight"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNavigateToNotificationOrigin(item);
                          }}
                        >
                          <ArrowRightIcon />
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="notification__footer">
            {data?.count > 0 ? (
              <button
                className="link link__primary"
                onClick={handleGoToViewNotification}
              >
                View more notifications
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {OpenConfirmationModal && (
        <ConfirmationModal
          closeModal={() => setOpenConfirmationModal(false)}
          onSubmit={handleDeleteNotifications}
          heading={"Delete Notifications!"}
          confirmationMessage={
            "Are you sure you want to delete the selected notifications?"
          }
        />
      )}
      {DeleteNotificationsRes?.meta?.status && (
        <SuccessModal
          closeModal={closeRTKModal}
          sucessfullMsg={DeleteNotificationsRes?.meta?.msg}
          Heading={"Notifications Deleted!"}
        />
      )}
      {DeleteNotificationsRes?.meta?.status === false && (
        <ErrorModal
          errorMessage={DeleteNotificationsRes?.meta?.msg}
          closeModal={closeRTKModal}
          footerVisible={true}
        />
      )}
      {DeleteNotificationsRes?.loading && <Loader />}
    </>
  );
};

export default NotificationIndex;
