import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Approve from "@/assets/svgs/Approve";
import Reject from "@/assets/svgs/Reject";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Tooltip } from "@mui/material";
import {
  firstLetterCapital,
  getInitial,
  GetUserDetails,
  saveInterviewDetails,
  titleCase
} from "@/utils/helper";
import CustomPagination from "@/elements/pagination/customPagination";
import GoTOPage from "@/elements/gotopage/gotopage";
import Loader from "@/elements/Loader";
import ErrorModal from "@/components/modals/error-modal";
import getCustomersEmployeeList from "@/rtk-apis/customer/myJobs/getCustomersEmployeeList";
import checkSchedulePage from "@/rtk-apis/interviews/checkSchedulePage";
import { useSelector, useDispatch } from "react-redux";
import ScheduleInterviewModal from "@/components/modals/ScheduleInterview";
import { closertkmodal } from "@/store/slices/interviews/checkSchedulePageSlice";
import PlayIcon from "@/assets/svgs/PlayIcon";
import { APP_URL } from "@/utils/constants";
import SortColumn from "@/elements/SortColumn";
import MultiSelectTagFilter from '@/components/Filters/multiSelectTagFilter';

function ShortlistedCandidatesListing({
  shortlistedCandidateList,
  setOpenCandidateFeedbackScreen,
  changePage,
  page,
  loading,
  setCandidateStatus,
  setOpenConfirmationModal,
  setCandidateData,
  setOpenFeedbackScreen,
  setCandidateFeedbackData,
  selectedJobData,
  changeCandidateViewType,
  getSortByData,
  sortByASC,
  allFilterTag,
  handleTagFilterChange,
  handleTagFilterClose
}) {
  const dispatch = useDispatch();
  const [OpenErrorModal, setOpenErrorModal] = useState(false);
  const user = GetUserDetails();
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [nylusData, setNylusData] = useState({});

  const handleApproveClick = (status, candidate) => {
    setOpenConfirmationModal(true);
    setCandidateData(candidate);
    setCandidateStatus(status);
  };

  const handleRejectClick = (status, candidate) => {
    setOpenCandidateFeedbackScreen(true);
    setCandidateData(candidate);
    setCandidateStatus(status);
  };

  const checkApprovedRejected = (jmtFeedback, hiringStatus) => {
    let employeeid = user?.customer?.employeeId;
    let showStatus = jmtFeedback.find(
      (employee) => employee.employeeId === employeeid
    );
    return hiringStatus == "pending" ? true : false;
  };

  const checkSchedulePageResponse = useSelector((globalState) => {
    return globalState.checkSchedulePage;
  });

  useEffect(() => {
    let body = {
      companyId: user?.customer?.companyId,
      from: "addjob",
      limit: 20,
      page: 1,
      search: "",
    };
    dispatch(getCustomersEmployeeList(body));
  }, []);

  const EmployeeList = useSelector((globalState) => {
    return globalState.customerEmployeeList;
  });

  const getMembers = () => {
    const employees = EmployeeList?.data?.rows?.filter(
      (item) => item?.role == "Company Admin"
    );
    let members = selectedJobData?.jobTeamMember
      ?.concat(selectedJobData?.hiringLead)
      ?.concat(employees);
    let uniqueMembers = _.uniqBy(members, "employeeId");
    return uniqueMembers;
  };

  const scheduleInterview = (employeeId) => {
    let jtmMemberIds = employeeId.map((item) => item.employeeId);
    let jtmMemberEmail = employeeId.map((item) => item.email);
    setNylusData({ ...nylusData, employeeId });
    hitNylus(jtmMemberEmail, jtmMemberIds);
    setOpenScheduleModal(false);
  };

  const hitNylus = (jtmMemberEmail, jtmMemberIds) => {
    saveInterviewDetails({
      companyId: selectedJobData?.companyId,
      jobId: selectedJobData?.jobId,
      recruiterId: selectedJobData?.recruiter?.recruiterId,
      jmtId: jtmMemberIds,
      candidateId: nylusData?.candidateId,
      employeeId: user?.customer?.employeeId,
      screeningId: nylusData?.screeningId,
    });
    const token = JSON.parse(localStorage.getItem("token"));
    let { nylasAccessToken } = token?.customer;
    dispatch(
      checkSchedulePage({
        accessToken: nylasAccessToken,
        jtmMember: jtmMemberEmail,
        name: nylusData?.candidateName,
        email: nylusData?.candidateEmail,
        url: `${APP_URL}customer-thankyou`,
        meetingUrl: `${APP_URL}meeting`,
      })
    );
  };

  const openScheduleInterviewModal = (data) => {
    setNylusData({
      ...nylusData,
      candidateId: data?.candidateId,
      candidateName:
        data?.candidate?.firstName + " " + data?.candidate?.lastName,
      candidateEmail: data?.candidate?.email,
      screeningId: data?.screeningId,
    });
    setOpenScheduleModal(true);
  };

  const removeNylusUrl = () => {
    dispatch(closertkmodal());
  };

  useEffect(() => {
    if (typeof checkSchedulePageResponse?.data == "string") {
      window.open(`${checkSchedulePageResponse?.data}`, "_blank");
      removeNylusUrl();
    }
  }, [checkSchedulePageResponse?.data]);

  return (
    <>
      <TableContainer
        component={Paper}
        className="tableContainer"
        style={{ height: "calc(100dvh - 182px)" }}
      >
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>S.</TableCell>
              <TableCell onClick={() => getSortByData("firstName")}>
                <SortColumn thName={"Name"} sortByASC={sortByASC} />
              </TableCell>
              <TableCell onClick={() => getSortByData("email")}>
                <SortColumn thName={"Email"} sortByASC={sortByASC} />
              </TableCell>
              <TableCell>Resume</TableCell>
              <TableCell>Interviews</TableCell>
              <TableCell>Feedbacks</TableCell>
              <TableCell>
                <div className="sortingDiv tagFilter">
                  Tags{" "}
                  <MultiSelectTagFilter
                    labels={allFilterTag?.data?.rows}
                    applyFilter={handleTagFilterChange}
                    clearFilter={handleTagFilterClose}
                  />
                </div>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shortlistedCandidateList?.rows?.map((candidate, index) => {
              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>
                    <div className="profile">
                      <div className="profile__initials">
                        {getInitial(candidate?.candidate?.firstName)}
                      </div>
                      <span className="profile__name">
                        {firstLetterCapital(candidate?.candidate?.firstName)}{" "}
                        {firstLetterCapital(candidate?.candidate?.lastName)}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="cellText">
                      <span className="cellText__dark">
                        {candidate?.candidate?.email
                          ? candidate?.candidate?.email
                          : "--"}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="actions">
                      <Link
                        className="btn btn__white"
                        to={
                          candidate?.candidate?.resumeUrl
                            ? candidate?.candidate?.resumeUrl
                            : "#"
                        }
                        target="_blank"
                        onClick={(e) => {
                          if (!candidate?.candidate?.resumeUrl) {
                            e.preventDefault();
                            setOpenErrorModal(true);
                          }
                        }}
                      >
                        Resume
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="cellText">
                      <button
                        className="cellText__dark cell--link"
                        type="button"
                        onClick={() => openScheduleInterviewModal(candidate)}
                      >
                        Schedule
                      </button>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="cellText">
                      <span
                        className="cellText__dark cell--link"
                        onClick={() => {
                          setOpenFeedbackScreen(true);
                          setCandidateFeedbackData(candidate);
                        }}
                      >
                        Feedback
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className="widthAuto">
                  <div className="cellText cellText--tag">
                    {candidate?.interview?.tag?.map(item =>(<span key={`tag${item?.id}`} className="btn btn__primary btn__rounded" style={{background: item?.colorCode}}>
                      {titleCase(item?.tagName)}
                    </span>))}
                  </div>
                </TableCell>
                  <TableCell>
                    {checkApprovedRejected(
                      candidate?.jmtFeedback,
                      candidate?.hiringStatus
                    ) && (
                      <div className="actions flex--end">
                        <button
                          className="btn btn__white"
                          onClick={() =>
                            changeCandidateViewType(
                              "candidateRecording",
                              candidate
                            )
                          }
                        >
                          View Rec.
                          <PlayIcon />
                        </button>
                        <Tooltip title="Approve" placement="top" arrow>
                          <button
                            className="btn btn__transparent border_0"
                            onClick={() =>
                              handleApproveClick("Approved", candidate)
                            }
                          >
                            <Approve />
                          </button>
                        </Tooltip>

                        <Tooltip title="Reject" placement="top" arrow>
                          <button
                            className="btn btn__transparent border_0"
                            onClick={() =>
                              handleRejectClick("Rejected", candidate)
                            }
                          >
                            <Reject />
                          </button>
                        </Tooltip>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {!shortlistedCandidateList?.rows?.length && (
          <div className="no-data">No Results Found!</div>
        )}
      </TableContainer>
      <div className="pagination">
        <div className="pagination__result">
          {`${
            shortlistedCandidateList?.count > page * 20
              ? 20
              : shortlistedCandidateList?.count
          } of ${shortlistedCandidateList?.count} results`}
        </div>
        {shortlistedCandidateList?.count ? (
          <CustomPagination
            totalPageCount={Math.ceil(
              shortlistedCandidateList?.count / shortlistedCandidateList?.count
            )}
            changePage={(event, value) => changePage(event, value)}
          />
        ) : (
          ""
        )}
        {shortlistedCandidateList?.count ? (
          <div className="pagination__actions">
            <GoTOPage changePage={changePage} />
          </div>
        ) : (
          ""
        )}
      </div>
      {openScheduleModal && (
        <ScheduleInterviewModal
          closeModal={() => setOpenScheduleModal(false)}
          onSubmit={scheduleInterview}
          data={getMembers()}
        />
      )}
      {OpenErrorModal && (
        <ErrorModal
          errorMessage={"Resume is not avaliable!"}
          closeModal={() => setOpenErrorModal(false)}
          footerVisible={true}
        />
      )}
      {checkSchedulePageResponse?.meta?.status === false &&
        checkSchedulePageResponse?.meta?.msg && (
          <ErrorModal
            errorMessage={checkSchedulePageResponse?.meta?.msg}
            closeModal={() => setOpenErrorModal(false)}
            footerVisible={true}
          />
        )}

      {(loading || checkSchedulePageResponse?.loading) && <Loader />}
    </>
  );
}

export default ShortlistedCandidatesListing;
