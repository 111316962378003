import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SearchSmallIcon from "@/assets/svgs/SearchSmallIcon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  GetUserDetails,
  formatPhoneNumber,
  firstLetterCapital,
} from "@/utils/helper";
import ErrorModal from "@/components/modals/error-modal";
import approvedCandidatesList from "@/rtk-apis/myJobs/approvedCandidatesList";
import CustomPagination from "@/elements/pagination/customPagination";
import { useSelector, useDispatch } from "react-redux";
import Loader from "@/elements/Loader";
import SortColumn from "@/elements/SortColumn";


function CandidatesListing({ selectedJob, openFeedback }) {
  const user = GetUserDetails();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [OpenErrorModal, setOpenErrorModal] = useState(false);
  const [sortByASC, setSortByASC] = useState(true);
  const [sortByColoumn, setSortByColoumn] = useState("");

  const { data, loading } = useSelector((globalState) => {
    return globalState.approvedCandidatesListResponse;
  });

  useEffect(() => {
    approvedCandidatesListAPI();
  }, [search, page, sortByASC, sortByColoumn]);

  const approvedCandidatesListAPI = () => {
    dispatch(
      approvedCandidatesList({
        jobId: selectedJob?.jobId,
        recruiterId: user?.recruiter?.recruiterId,
        page: search ? 1 : page,
        limit: 20,
        search,
        columnName: sortByColoumn,
        sort: sortByASC ? "ASC" : "DESC",
      })
    );
  };

  const getSortByData = (columnName) => {
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <div>
        <div className="tabContainer__item--head">
          <div className="listCol__head--actions w_100">
            <div className="formRow formRow__4 m_0 w_100">
              <div className="formCol">
                <div className="formField">
                  <div className="formField__group sm">
                    <span className="formField__icon">
                      <SearchSmallIcon />
                    </span>
                    <input
                      type="text"
                      className="formField__input"
                      placeholder="Search a candidate"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TableContainer
          component={Paper}
          className="tableContainer"
          style={{ height: "calc(100dvh - 247px)" }}
        >
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell>S.</TableCell>
                <TableCell onClick={() => getSortByData("firstName")}>
                  <SortColumn thName={"Name"} sortByASC={sortByASC} />
                </TableCell>
                <TableCell onClick={() => getSortByData("email")}>
                  <SortColumn thName={"Email"} sortByASC={sortByASC} />
                </TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Feedbacks</TableCell>
                <TableCell>Resume</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.rows?.length ? (
                data?.rows.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        1
                      </TableCell>
                      <TableCell>
                        <div className="profile">
                          <div className="profile__initials">
                            {firstLetterCapital(row?.candidate?.firstName[0])}
                          </div>
                          <span className="profile__name">
                            {firstLetterCapital(row?.candidate?.firstName) +
                              " " +
                              firstLetterCapital(row?.candidate?.lastName)}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <span className="cellText__dark">
                            {row?.candidate?.email}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <span className="cellText__dark">
                            {row?.candidate?.phoneNumber
                              ? row?.candidate?.countryCode +
                                " " +
                                formatPhoneNumber(row?.candidate?.phoneNumber)
                              : "--"}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <button
                            onClick={() => openFeedback(row)}
                            className="cellText__dark cell--link"
                          >
                            Feedback
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="actions">
                          <Link
                            target="_blank"
                            className="btn btn__white"
                            to={
                              row?.candidate?.resumeUrl
                                ? row?.candidate?.resumeUrl
                                : "#"
                            }
                            onClick={(e) => {
                              if (!row?.candidate?.resumeUrl) {
                                e.preventDefault();
                                setOpenErrorModal(true);
                              }
                            }}
                          >
                            Resume
                          </Link>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <div className="no-data floating">No Results Found!</div>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="pagination pagination__center">
          {data?.rows?.length ? (
            <CustomPagination
              page={page}
              totalPageCount={Math.ceil(data?.count / 20)}
              changePage={(event, value) => changePage(event, value)}
            />
          ) : (
            ""
          )}
          {OpenErrorModal && (
            <ErrorModal
              errorMessage={"Resume is not avaliable!"}
              closeModal={() => setOpenErrorModal(false)}
              footerVisible={true}
            />
          )}
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
}

export default CandidatesListing;
