import React, { useState, useEffect } from "react";
import PlusIcon from "@/assets/svgs/PlusIcon";
import SearchIcon from "@/assets/svgs/SearchIcon";
import FilterIcon from "@/assets/svgs/FilterIcon";
import Dot from "@/assets/svgs/Dot";
import myJobsListCustomer from "@/rtk-apis/customer/myJobs/myJobsListCustomer";
import { useDispatch, useSelector } from "react-redux";
import { firstLetterCapital, GetUserDetails } from "@/utils/helper";
import moment from "moment";
import Loader from "@/elements/Loader";
import CustomPagination from "@/elements/pagination/customPagination";
import CustomerMyJobsFilter from "@/customer-pages/MyJobs/CustomerMyJobsFilter";
import Search from "@/components/Search";

const MyJobListing = ({
  setOpenAddPostJobScreen,
  changeIndex,
  EditJobPostMode,
  RefreshJobList,
  setAddResponsiveness,
  setPage,
  page,
  setSearch,
  search,
  setFiltersApplied,
  filtersApplied,
  selectedJobId
}) => {
  const user = GetUserDetails();
  const role = user?.customer?.role;
  const dispatch = useDispatch();

  const [status, setStatus] = useState("All");
  const [experienceFilterValue, setExperienceFilterValue] = useState([0, 99]);
  const [qualificationFilterValue, setQualificationFilterValue] = useState([]);
  const [tagFilterValue, setTagFilterValue] = useState([]);

  const [showSearch, setShowSearch] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    getMyJobsListAPIData();
  }, [search, page, RefreshJobList, filtersApplied]);

  const getMyJobsListAPIData = () => {
    dispatch(
      myJobsListCustomer({
        companyId: user?.customer?.companyId,
        employeeId: user?.customer?.employeeId,
        page: search ? 1 : page,
        limit: 20,
        experience: experienceFilterValue,
        qualification: qualificationFilterValue,
        tagArray: tagFilterValue,
        status,
        search,
      })
    );
  };

  const { data, loading } = useSelector((globalState) => {
    return globalState.myJobsListCustomer;
  });

  const ApplyJobFilter = () => {
    setPage(1);
    setShowFilter(false);
    setFiltersApplied(!filtersApplied);
  };

  const ClearFilter = () => {
    setStatus("All");
    setExperienceFilterValue([0, 99]);
    setQualificationFilterValue([]);
    setTagFilterValue([]);
    setShowFilter(false);
    setFiltersApplied(false);
  };

  const closeSearch = () => {
    setShowSearch(false);
    setSearch("");
    setPage(1);
  };

  const filterCount = () => {
    let filterCount = 0;
    if (!(experienceFilterValue[0] === 0 && experienceFilterValue[1] === 99)) {
      filterCount += 1;
    }
    if (qualificationFilterValue.length) {
      filterCount += 1;
    }
    if (tagFilterValue.length) {
      filterCount += 1;
    }
    if (
      status == "Open" ||
      status == "Closed" ||
      status == "Paused" ||
      status == "Reopened"
    ) {
      filterCount += 1;
    }
    return `(${filterCount})`;
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <div className="layoutGrid__col">
        <div
          className={`listCol ${showFilter && "listCol__filter"} ${showSearch &&
            "listCol__search"}`}
        >
          <div className="listCol__head listing__head">
            <h2 className="listCol__head--title">My Jobs</h2>
            <div className="listCol__head--actions">
              <button
                className="btn btn__white p_0"
                type="button"
                onClick={() => {
                  setShowSearch(true);
                }}
              >
                <SearchIcon />
              </button>

              <button
                className="btn btn__white p_0"
                type="button"
                onClick={() => setShowFilter(true)}
              >
                <FilterIcon />
              </button>

              {(role === "Company Admin" || role === "Job Creator") && (
                <button
                  className="btn btn__white p_0"
                  type="button"
                  onClick={() => {
                    setOpenAddPostJobScreen(true);
                    setAddResponsiveness(true);
                  }}
                  disabled={EditJobPostMode ? true : false}
                >
                  <PlusIcon />
                </button>
              )}
            </div>
            <Search
              closeSearch={closeSearch}
              searchTerm={(value) => {
                setSearch(value);
                setPage(1);
              }}
            />
          </div>

          <CustomerMyJobsFilter
            closeFilter={() => setShowFilter(false)}
            status={status}
            handleStatusChange={(value) => setStatus(value)}
            handleHigherEduChange={(value) =>
              setQualificationFilterValue(value)
            }
            handleTagChange={(value) => setTagFilterValue(value)}
            qualificationFilterValue={qualificationFilterValue}
            tagFilterValue={tagFilterValue}
            experienceFilterValue={experienceFilterValue}
            handleExperienceSliderChange={(value) =>
              setExperienceFilterValue(value)
            }
            ApplyFilter={ApplyJobFilter}
            clearFilter={ClearFilter}
            showFilter={showFilter}
          />

          {filtersApplied && (
            <div className="listColTitle filterClear">
              <p>Filters applied {filterCount()}</p>
              <button
                className="filterClear__btn"
                type="button"
                onClick={ClearFilter}
              >
                Clear filter
              </button>
            </div>
          )}

          <ul className="listCol__list listing__list">
            {data?.rows?.length ? (
              data?.rows?.map((jobItem, index) => {
                return (
                  <li
                    className={
                      selectedJobId === jobItem?.jobId
                        ? "listCol__list--item listCol__item--selected"
                        : "listCol__list--item"
                    }
                    key={index}
                    onClick={() => {
                      changeIndex(index);
                      setAddResponsiveness(true);
                    }}
                  >
                    <div className="item item--recruiter item--jobs">
                      <div className="item__info">
                        <h4 className="item__info--title">
                          {jobItem?.title
                            ? firstLetterCapital(jobItem?.title)
                            : ""}
                        </h4>
                        <div className="item__info--subtitle">
                          <p>
                            {jobItem?.department ? jobItem?.department : ""}
                          </p>
                        </div>
                        <div className="item__info--subtitle">
                          <p>
                            <span>Created Date: </span>{" "}
                            {jobItem?.createdAt
                              ? moment(jobItem?.createdAt).format(
                                  "D MMM , YYYY"
                                )
                              : ""}
                          </p>
                          <span className="status">
                            <Dot />
                            Status:{" "}
                            <span
                              className={
                                jobItem?.status === "active" ||
                                jobItem?.status === "reopen"
                                  ? "text--primary"
                                  : jobItem?.status === "paused"
                                  ? "text--warning "
                                  : "text--danger"
                              }
                            >
                              {jobItem?.status === "active"
                                ? "Open"
                                : jobItem?.status === "paused"
                                ? "Paused"
                                : jobItem?.status === "reopen"
                                ? "Reopen"
                                : "Closed"}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <div className="no-data">No Results Found!</div>
            )}
          </ul>

          <div className="pagination pagination__center">
            {data?.rows?.length ? (
              <CustomPagination
                page={page}
                totalPageCount={Math.ceil(data?.count / 20)}
                changePage={(event, value) => changePage(event, value)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default MyJobListing;
