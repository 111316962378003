import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { UPDATE_CANDIDATE } from '@/rtk-apis/end-points';

const updateCandidate = createAsyncThunk('updateCandidate', async (body) => {
  try {
    const response = await httpService.put(UPDATE_CANDIDATE, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default updateCandidate;
