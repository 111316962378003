import React, { useState } from "react";
import SearchIcon from "@/assets/svgs/SearchIcon";
import FilterIcon from "@/assets/svgs/FilterIcon";
import DotIcon from "@/assets/svgs/Dot";
import BillingPaymentsFilter from "@/customer-pages/BillingPayments/BillingPaymentsFilter";
import Loader from "@/elements/Loader";
import CustomPagination from "@/elements/pagination/customPagination";
import Search from "@/components/Search";
import { Currency } from "@/utils/helper";

function BillingPaymentsListing({
  JobsData,
  selectedJob,
  setSelectedJob,
  loading,
  setPage,
  page,
  status,
  setStatus,
  setSearch,
  filtersApplied,
  setFiltersApplied,
  setBillingStatus,
  setAddResponsiveClass,
  resetStartEndDateOnJobChange
}) {
  const [showSearch, setShowSearch] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const changePage = (event, value) => {
    setPage(value);
  };

  const ApplyJobFilter = () => {
    setShowFilter(false);
    setFiltersApplied(!filtersApplied);
  };

  const ClearFilter = () => {
    setStatus("All");
    setShowFilter(false);
    setFiltersApplied(false);
  };

  const closeSearch = () => {
    setShowSearch(false);
    setSearch("");
    setPage(1);
  };

  const filterCount = () => {
    let filterCount = 0;
    if (status == "Paid" || status == "Unpaid") {
      filterCount += 1;
    }
    return `(${filterCount})`;
  };

  const handleSelectJob = (item) => {
    setSelectedJob(item?.jobId);
    setBillingStatus(item?.screenings.find((x) => x.billStatus === false));
    setAddResponsiveClass(true);
    resetStartEndDateOnJobChange()
  };

  const calculateUnpaidAmount = (jsonArray) => {
    const foundObject = jsonArray.find((item) => item.billStatus === false);
    if (foundObject) {
      const sumOfUnpaid = jsonArray
        .filter((item) => item.billStatus === false)
        .reduce((sum, item) => sum + item.amount, 0);
      return Currency(sumOfUnpaid);
    } else {
      return Currency(0);
    }
  };

  return (
    <>
      <div className="layoutGrid__col">
        <div
          className={`listCol ${showFilter && "listCol__filter"} ${
            showSearch && "listCol__search"
          }`}
        >
          <div className="listCol__head listing__head">
            <h2 className="listCol__head--title">My Jobs</h2>
            <div className="listCol__head--actions">
              <button
                className="btn btn__white p_0"
                type="button"
                onClick={() => {
                  setShowSearch(true);
                }}
              >
                <SearchIcon />
              </button>

              <button
                className="btn btn__white p_0"
                type="button"
                onClick={() => setShowFilter(true)}
              >
                <FilterIcon />
              </button>
            </div>

            <Search
              closeSearch={closeSearch}
              searchTerm={(value) => setSearch(value)}
            />
          </div>

          <BillingPaymentsFilter
            handleStatusChange={(value) => setStatus(value)}
            status={status}
            ApplyFilter={ApplyJobFilter}
            clearFilter={ClearFilter}
            setShowFilter={setShowFilter}
          />

          {filtersApplied && (
            <div className="listColTitle filterClear">
              <p>Filters applied {filterCount()}</p>
              <button
                className="filterClear__btn"
                type="button"
                onClick={ClearFilter}
              >
                Clear filter
              </button>
            </div>
          )}

          <ul className="listCol__list listing__list">
            {JobsData?.rows?.length > 0 ? (
              JobsData?.rows?.map((item, index) => {
                return (
                  <li
                    className={
                      selectedJob === item?.jobId
                        ? "listCol__list--item listCol__item--selected"
                        : "listCol__list--item"
                    }
                    onClick={() => handleSelectJob(item)}
                    key={index}
                  >
                    <div className="item item--recruiter item--jobs">
                      <div className="item__info">
                        <h4 className="item__info--title">
                          {item?.title ? item?.title : ""}
                        </h4>
                        <div className="item__info--subtitle">
                          <p>{item?.department ? item?.department : ""}</p>
                          <span className="status">
                            <DotIcon />
                            Status:{" "}
                            <span
                              className={
                                item?.screenings.find(
                                  (x) => x.billStatus === false
                                )
                                  ? "text--danger"
                                  : "text--primary"
                              }
                            >
                              {item?.screenings.find(
                                (x) => x.billStatus === false
                              )
                                ? "Unpaid"
                                : "Paid"}
                            </span>
                          </span>
                        </div>
                        <div className="item__info--subtitle">
                          {item?.screenings.find(
                            (x) => x.billStatus === false
                          ) && (
                            <p>
                              <span>Amount: </span>{" "}
                              {calculateUnpaidAmount(item?.screenings)}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <div className="no-data">No Results Found!</div>
            )}
          </ul>

          <div className="pagination pagination__center">
            {JobsData?.rows?.length ? (
              <CustomPagination
                page={page}
                totalPageCount={Math.ceil(JobsData?.count / 20)}
                changePage={(event, value) => changePage(event, value)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
}

export default BillingPaymentsListing;
