import React, { useRef } from "react";
import SearchSmallIcon from "@/assets/svgs/SearchSmallIcon";
import CloseIcon from "@/assets/svgs/CloseIcon";

function Search({ closeSearch, searchTerm, placeholder }) {
  const searchRef = useRef();
  const cancelSearch = () => {
    searchRef.current.value = "";
    closeSearch();
  };
  return (
    <div className="searchContainer">
      <div className="formField">
        <div className="formField__group sm">
          <span className="formField__icon">
            <SearchSmallIcon />
          </span>
          <input
            ref={searchRef}
            type="text"
            className="formField__input"
            placeholder={`Search for ${placeholder || "a job"}`}
            onChange={(e) => searchTerm(e.target.value)}
          />
        </div>
      </div>
      <button
        onClick={cancelSearch}
        className="btn btn__transparent border_0 p_0"
      >
        <CloseIcon />
      </button>
    </div>
  );
}

export default Search;
