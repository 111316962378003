import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { DELETE_NOTIFICATIONS } from "@/rtk-apis/end-points";

const deleteNotifications = createAsyncThunk(
  "deleteNotifications",
  async (body) => {
    try {
      const response = await httpService.put(DELETE_NOTIFICATIONS, body);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default deleteNotifications;
