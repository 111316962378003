import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { ADD_TAGS_TO_COMPANY } from "@/rtk-apis/end-points";

const addTagToCustomer = createAsyncThunk("addTagToCustomer", async (body) => {
  try {
    const response = await httpService.post(ADD_TAGS_TO_COMPANY, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default addTagToCustomer;
