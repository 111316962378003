import React from "react";

function PlayIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.5 1.5L9.5 6L2.5 10.5V1.5Z" fill="#150823" />
    </svg>
  );
}

export default PlayIcon;
