import { createSlice } from "@reduxjs/toolkit";
import recruiterJobList from "@/rtk-apis/recruiterBilling/recruiterJobList";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const recruiterJobListSlice = createSlice({
  name: "recruiterJobListSlice",
  initialState,
  reducers: {
    closeRecruiterJobListModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(recruiterJobList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(recruiterJobList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(recruiterJobList.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default recruiterJobListSlice.reducer;

export const { closeRecruiterJobListModal } = recruiterJobListSlice.actions;
