import React from "react";

function LightOnSvg() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3248 9.67523L4.44059 17.5594M5.29145 11.8375L1.42965 10.9253C1.00478 10.825 0.855347 10.2975 1.16313 9.98859L4.23428 6.91744C4.44059 6.71113 4.72049 6.59292 5.01378 6.59069L8.47635 6.56059M12.8377 2.59842C15.4149 4.35591 17.6441 6.58512 19.4016 9.16226M10.1614 16.7085L11.0736 20.5703C11.1739 20.9952 11.7014 21.1447 12.0103 20.8369L15.0814 17.7657C15.2878 17.5594 15.406 17.2795 15.4082 16.9862L15.4383 13.5236M20.0629 7.35012L20.9639 3.01438C21.2093 1.83454 20.1655 0.790745 18.9856 1.03608L14.6499 1.93713C13.373 2.20254 12.2021 2.83483 11.281 3.75707L7.72362 7.31332C6.36647 8.67047 5.48326 10.4302 5.20447 12.3293L5.19221 12.4107C5.01601 13.6229 5.42193 14.8462 6.28729 15.7127C7.15266 16.5781 8.3771 16.984 9.58928 16.8067L9.67069 16.7944C11.5698 16.5167 13.3295 15.6324 14.6867 14.2753L18.2429 10.719C19.1652 9.7979 19.7975 8.62698 20.0629 7.35012Z"
        stroke="#150823"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LightOnSvg;
