import { createSlice } from "@reduxjs/toolkit";
import myJobsList from "@/rtk-apis/myJobs/myJobs";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const myJobsListSlice = createSlice({
  name: "myJobsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(myJobsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(myJobsList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(myJobsList.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default myJobsListSlice.reducer;
