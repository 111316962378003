import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import BackIcon from "@/assets/svgs/BackIcon";
import CustomPagination from "@/elements/pagination/customPagination";
import GoTOPage from "@/elements/gotopage/gotopage";
import ArrowPaymentSelectDown from "@/assets/svgs/ArrowPaymentSelectDown";
import { useSelector, useDispatch } from "react-redux";
import recruiterCandidateList from "@/rtk-apis/recruiterBilling/recruiterCandidateList";
import Loader from "@/elements/Loader";
import SearchSmallIcon from "@/assets/svgs/SearchSmallIcon";
import { firstLetterCapital, getInitial, GetUserDetails } from "@/utils/helper";
import NotificationButton from "../../components/elements/notificationButton";

const RecruiterBillingCandidateListing = ({
  jobs,
  ActiveJobId,
  setStatus,
  status,
  setSearch,
  search,
  setAddResponsiveClass,
  addResponsiveClass,
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const user = GetUserDetails();

  const [OpenNotificationModal, setOpenNotificationModal] = useState(false);

  const { data, loading } = useSelector((globalState) => {
    return globalState.getRecruiterCandidateList;
  });

  useEffect(() => {
    getRecruiterBillingCandidateListing();
  }, [ActiveJobId, status, search]);

  const getRecruiterBillingCandidateListing = () => {
    dispatch(
      recruiterCandidateList({
        jobId: ActiveJobId,
        recruiterId: user?.recruiter?.recruiterId,
        limit: 20,
        page: page,
        search: search,
        status: status === "All" ? [] : [status],
      })
    );
  };
  const changePage = (event, value) => {
    setPage(value);
  };

  return (
    <>
      {jobs?.rows?.length > 0 ? (
        <div
          className={`layoutGrid__col layoutGrid--responsive  ${
            addResponsiveClass ? "layoutGrid--show" : ""
          }`}
        >
          <div className="dataCol">
            <div className="page page__full">
              <div className="page__head">
                <h2 className="page__head--title">
                  <button
                    className="btn btn__transparent btn__back border_0 p_0"
                    type="button"
                    onClick={() => setAddResponsiveClass(false)}
                  >
                    <BackIcon />
                  </button>
                  Candidates
                </h2>
                <NotificationButton
                  OpenNotificationModal={OpenNotificationModal}
                  setOpenNotificationModal={setOpenNotificationModal}
                />
              </div>

              <div className="page__body">
                <div className="tabContainer">
                  <div className="tabContainer__item">
                    <div className="tabContainer__item--head bg--white">
                      <div className="listCol__head--actions w_100">
                        <div className="formRow formRow__4 m_0 w_100 flexRow--recuiterbilling">
                          <div className="formCol">
                            <div className="formField">
                              <div className="formField__group formSearch sm">
                                <span className="formField__icon">
                                  <SearchSmallIcon />
                                </span>
                                <input
                                  type="text"
                                  className="formField__input"
                                  placeholder="Search a Candidate"
                                  onChange={(e) => setSearch(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="formCol">
                            <div className="formField">
                              <FormControl
                                fullWidth
                                size="small"
                                className="formField__select"
                              >
                                <Select
                                  onChange={(e) => setStatus(e?.target?.value)}
                                  value={status}
                                  IconComponent={() => (
                                    <ArrowPaymentSelectDown />
                                  )}
                                  displayEmpty
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <span style={{ opacity: "0.8" }}>
                                          Payment Status
                                        </span>
                                      );
                                    }

                                    return selected;
                                  }}
                                >
                                  <MenuItem value="All">All</MenuItem>
                                  <MenuItem value="Paid">Paid</MenuItem>
                                  <MenuItem value="Unpaid">Unpaid</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <TableContainer
                      component={Paper}
                      className="tableContainer"
                      style={{ height: "calc(100dvh - 200px)" }}
                    >
                      <Table className="table">
                        <TableHead>
                          <TableRow>
                            <TableCell>S.</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Payment Status</TableCell>
                          </TableRow>
                        </TableHead>
                        {data?.rows?.length > 0 ? (
                          <TableBody>
                            {data?.rows?.map((candidate, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {page > 1
                                      ? (page - 1) * 20 + (index + 1)
                                      : index + 1}
                                  </TableCell>
                                  <TableCell>
                                    <div className="profile">
                                      <div className="profile__initials">
                                        {getInitial(
                                          candidate?.candidate?.firstName
                                        )}
                                      </div>
                                      <span className="profile__name">
                                        {`${firstLetterCapital(
                                          candidate?.candidate?.firstName
                                        )} ${firstLetterCapital(
                                          candidate?.candidate?.lastName
                                        )}`}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      <span className="cellText__dark">
                                        {candidate?.candidate?.email
                                          ? candidate?.candidate?.email
                                          : "--"}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      <span
                                        className={`cellText__dark ${
                                          candidate?.recruiterAmountPaid ===
                                          false
                                            ? "text--danger"
                                            : "text--primary"
                                        }`}
                                      >
                                        {candidate?.recruiterAmountPaid ===
                                        false
                                          ? "Unpaid"
                                          : "Paid"}
                                      </span>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        ) : (
                          <div className="no-data floating">
                            No Results Found!
                          </div>
                        )}
                      </Table>
                    </TableContainer>

                    <div className="pagination">
                      <div className="pagination__result">
                        {`${data?.count > page * 20 ? 20 : data?.count} of ${
                          data?.count
                        } results`}
                      </div>
                      {data?.count ? (
                        <CustomPagination
                          totalPageCount={Math.ceil(data?.count / data?.count)}
                          changePage={(event, value) =>
                            changePage(event, value)
                          }
                        />
                      ) : (
                        ""
                      )}
                      {data?.count ? (
                        <div className="pagination__actions">
                          <GoTOPage changePage={changePage} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-data">No Results Found!</div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default RecruiterBillingCandidateListing;
