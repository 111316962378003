import React from 'react';

function DisableTagIcon() {
  return (
    <svg
      width="22"
      height="25"
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.59 15.91L12.42 23.08C12.2343 23.266 12.0137 23.4135 11.7709 23.5141C11.5281 23.6148 11.2678 23.6666 11.005 23.6666C10.7422 23.6666 10.4819 23.6148 10.2391 23.5141C9.99632 23.4135 9.77575 23.266 9.59 23.08L1 14.5V4.5H11L19.59 13.09C19.9625 13.4647 20.1716 13.9716 20.1716 14.5C20.1716 15.0284 19.9625 15.5353 19.59 15.91Z"
        stroke="#6C737F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 11H8.01"
        stroke="#6C737F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="12" cy="4.5" r="4.5" fill="#F56969" />
      <path
        d="M9.75293 3.81055L14.2149 5.25168M12.7045 2.30011L11.2634 6.76211"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default DisableTagIcon;
