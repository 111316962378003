import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { FETCH_TAX_INFORMATION } from '@/rtk-apis/end-points';
import { GetUserDetails } from '@/utils/helper';

const taxInformation = createAsyncThunk('taxInformation', async () => {
  const user = GetUserDetails();
  try {
    const response = await httpService.get(`${FETCH_TAX_INFORMATION}${user?.recruiter?.recruiterId}`, {});
    return response;
  } catch (error) {
    return error;
  }
});

export default taxInformation;
