import React, { useState, useEffect } from "react";
import logo from "@/assets/images/logo.svg";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ProfileSvg from "@/assets/svgs/ProfileSvg";
import PasswordLock from "@/assets/svgs/PasswordLock";
import USAFlag from "@/assets/svgs/USAFlag";
import CloseEye from "@/assets/svgs/CloseEye";
import OpenEye from "@/assets/svgs/OpenEye";
import { Formik, Form, Field } from "formik";
import {
  registerStepOneSchema,
  candidateStepOneSchema,
} from "@/components/schema/schema";
import { firstLetterCapital, formatPhoneNumber } from "@/utils/helper";
import candidateSignUp from "@/rtk-apis/candidate-rtk-apis/candidateSignUp";
import ErrorModal from "@/components/modals/error-modal";
import Loader from "@/elements/Loader";
import { SuccessModal } from "@/components/modals/SuccessModal";
import { useSelector, useDispatch } from "react-redux";
import { closeCandidateSignUpModal } from "../../store/slices/candidateSlices/candidateSignUpSlice";

function SignUpStepOne(props) {
  // props -we get from customer-pages signup component when admin made the company itself from laila portal and lands on step one via email link and we get values from url params
  // state- we get from siginIn component when signup link redirect it to signup page(this component)
  // signupStepOnevalues- we get if user goes back to sign in page and then again comes to step one page then if the user have filled this current form then we get the data from local storage prepopulated here.

  const { state } = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [SignupOneCandidateValues, setSignupOneCandidateValues] = useState({});
  const signupStepOnevalues = JSON.parse(
    localStorage.getItem("signupSteponeValues")
  );

  const { data, meta, loading } = useSelector((globalState) => {
    return globalState.candidateSignUpRes;
  });

  let initialValues = {
    firstName: signupStepOnevalues
      ? signupStepOnevalues?.firstName
      : props?.firstname,
    lastName: props?.lastname ? props?.lastname : signupStepOnevalues?.lastName,
    phoneNumber: props?.phoneNumber
      ? props?.phoneNumber
      : signupStepOnevalues?.phoneNumber
      ? signupStepOnevalues?.phoneNumber
      : "",
    email: props?.email ? props?.email : signupStepOnevalues?.email,
    password: signupStepOnevalues ? signupStepOnevalues?.password : "",
    confirmPassword: signupStepOnevalues
      ? signupStepOnevalues?.confirmPassword
      : "",
  };

  if (searchParams?.size) {
    initialValues.firstName = searchParams.get("firstName");
    initialValues.lastName = searchParams.get("lastName");
    initialValues.email = searchParams.get("email");
    initialValues.phoneNumber = searchParams.get("phoneNumber");
  }

  const [eyeForPassword, seteyeForPassword] = useState(false);
  const [eyeForConfirmPassword, seteyeForConfirmPassword] = useState(false);
  const [TnC, setTnC] = useState(false);

  const goToStepTwo = (values) => {
    if (state?.signUpAs === "candidate") {
      let body = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        password: values?.password,
        jobDetails: [],
        termAndCondition: true,
        qualificationDetail: [],
        role: "candidate",
      };
      dispatch(candidateSignUp(body));
      // closeRtkModal();
      setSignupOneCandidateValues(values);
    } else {
      values = { ...values, signUpAs: state?.signUpAs, termAndCondition: TnC };
      localStorage.setItem("signupSteponeValues", JSON.stringify(values));
      props.goToStepTwo(values);
    }
  };

  const closeRtkModal = () => {
    dispatch(closeCandidateSignUpModal());
    if (SignupOneCandidateValues && meta?.status) {
      let values = {
        ...SignupOneCandidateValues,
        signUpAs: state?.signUpAs,
        termAndCondition: TnC,
        candidateData: data,
      };
      localStorage.setItem("signupSteponeValues", JSON.stringify(values));
      props.goToStepTwo(values);
    }
  };

  return (
    <>
      <div>
        <section className="signup">
          <div className="signup__flex">
            <div className="signup__item signup__item--bg">
              <div className="welcomeInfo welcomeInfo--center">
                <h1 className="title title--h1">Welcome to Laila</h1>
                <p className="text">The future of recruiting starts here.</p>
              </div>
            </div>
            <div className="signup__item signup__item--form">
              <Formik
                initialValues={initialValues}
                validationSchema={
                  state?.signUpAs === "candidate"
                    ? candidateStepOneSchema
                    : registerStepOneSchema
                }
                onSubmit={(values) => goToStepTwo(values)}
              >
                {({ errors, values, handleBlur, touched }) => (
                  <Form autoComplete="off" className="signup__form">
                    <div className="branding">
                      <div className="branding__logo">
                        <img
                          src={logo}
                          alt="Logo | Laila"
                          className="branding__logo__img"
                        />
                        <p className="branding__subtitle">
                          Sign up as a{" "}
                          {searchParams?.size
                            ? "recruiter"
                            : props?.signUpAs === "customer"
                            ? "customer"
                            : state?.signUpAs}
                        </p>
                      </div>
                      <div className="branding__info">
                        <h1 className="title title--h1">Create Account</h1>
                      </div>
                    </div>
                    {/* firstname */}
                    <div
                      className={`formField formField__mb5 ${
                        errors?.firstName && touched?.firstName
                          ? "fieldError"
                          : ""
                      }`}
                    >
                      <div className="formField__group bg--light">
                        <span className="formField__icon">
                          <ProfileSvg />
                        </span>
                        <Field
                          type="text"
                          className="formField__input bg--light"
                          onBlur={handleBlur}
                          placeholder="Enter first name"
                          name="firstName"
                          value={firstLetterCapital(values?.firstName)}
                        />
                      </div>
                      <span className="formField__error">
                        {errors?.firstName &&
                          touched?.firstName &&
                          errors?.firstName}
                      </span>
                    </div>
                    {/* lastname */}
                    <div
                      className={`formField formField__mb5 ${
                        errors?.lastName && touched?.lastName
                          ? "fieldError"
                          : ""
                      }`}
                    >
                      <div className="formField__group bg--light">
                        <span className="formField__icon">
                          <ProfileSvg />
                        </span>
                        <Field
                          type="text"
                          className="formField__input bg--light"
                          onBlur={handleBlur}
                          placeholder="Enter last name"
                          value={firstLetterCapital(values?.lastName)}
                          name="lastName"
                        />
                      </div>
                      <span className="formField__error">
                        {errors?.lastName &&
                          touched?.lastName &&
                          errors?.lastName}
                      </span>
                    </div>
                    {/* email */}
                    <div
                      className={`formField formField__mb5 ${
                        errors?.email && touched?.email ? "fieldError" : ""
                      }`}
                    >
                      <div className="formField__group bg--light">
                        <span className="formField__icon">
                          <PasswordLock />
                        </span>
                        <Field
                          type="text"
                          className="formField__input bg--light"
                          onBlur={handleBlur}
                          placeholder="Enter email address"
                          name="email"
                          disabled={props?.email}
                        />
                      </div>
                      <span className="formField__error">
                        {errors?.email && touched?.email && errors?.email}
                      </span>
                    </div>
                    {/* Phonenumber */}
                    {state?.signUpAs !== "candidate" && (
                      <div
                        className={`formField formField__mb5 ${
                          errors?.phoneNumber &&
                          touched?.phoneNumber &&
                          errors?.phoneNumber
                            ? "fieldError"
                            : ""
                        }`}
                      >
                        <div className="formField__group formField__phoneNumber formField__phoneNumberFlag bg--light">
                          <FormControl
                            fullWidth
                            size="small"
                            className="formField__select bg--light"
                          >
                            <Select displayEmpty>
                              <MenuItem>
                                <USAFlag />
                                USA
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <Field
                            type="text"
                            className="formField__input sm"
                            placeholder="Phone Number"
                            name="phoneNumber"
                            autoComplete="off"
                            maxLength="10"
                            value={formatPhoneNumber(values?.phoneNumber)}
                          />
                        </div>
                        <span className="formField__error">
                          {errors?.phoneNumber &&
                            touched?.phoneNumber &&
                            errors?.phoneNumber}
                        </span>
                      </div>
                    )}
                    {/* password */}
                    <div
                      className={`formField formField__mb5 ${
                        errors?.password && touched?.password
                          ? "fieldError"
                          : ""
                      }`}
                    >
                      <div className="formField__group bg--light">
                        <span className="formField__icon">
                          <PasswordLock />
                        </span>
                        <Field
                          type={eyeForPassword ? "text" : "password"}
                          className="formField__input bg--light"
                          onBlur={handleBlur}
                          placeholder="Enter new password"
                          name="password"
                          autoComplete="off"
                        />
                        <div
                          onClick={() => seteyeForPassword(!eyeForPassword)}
                          className="formField__icon"
                        >
                          <span>
                            {eyeForPassword ? <OpenEye /> : <CloseEye />}
                          </span>
                        </div>
                      </div>
                      <span className="formField__error">
                        {errors?.password &&
                        touched?.password &&
                        errors?.password ? (
                          errors?.password &&
                          touched?.password &&
                          errors?.password
                        ) : (
                          <i>
                            * The password must contain 8 characters and a
                            combination of lowercase letters, uppercase letters,
                            numbers, and special characters.
                          </i>
                        )}
                      </span>
                    </div>
                    {/* confirm passowrd */}
                    {state?.signUpAs !== "candidate" && (
                      <div
                        className={`formField formField__mb5 ${
                          errors?.confirmPassword && touched?.confirmPassword
                            ? "fieldError"
                            : ""
                        }`}
                      >
                        <div className="formField__group bg--light">
                          <span className="formField__icon">
                            <PasswordLock />
                          </span>
                          <Field
                            type={eyeForConfirmPassword ? "text" : "password"}
                            className="formField__input bg--light"
                            onBlur={handleBlur}
                            placeholder="Confirm new password"
                            name="confirmPassword"
                            autoComplete="off"
                          />
                          <div
                            onClick={() =>
                              seteyeForConfirmPassword(!eyeForConfirmPassword)
                            }
                            className="formField__icon"
                          >
                            <span>
                              {eyeForConfirmPassword ? (
                                <OpenEye />
                              ) : (
                                <CloseEye />
                              )}
                            </span>
                          </div>
                        </div>
                        <span className="formField__error">
                          {errors?.confirmPassword &&
                            touched?.confirmPassword &&
                            errors?.confirmPassword}
                        </span>
                      </div>
                    )}
                    {/* terms conditions */}
                    <div className="form__flexrow">
                      <div className="formField">
                        <div className="formField__checkbox">
                          <FormControlLabel
                            onClick={() => setTnC(!TnC)}
                            control={<Checkbox />}
                          />
                          <span>
                            By creating account you are agree to the
                            <a className="label__link" href="#">
                              Terms & Conditions
                            </a>
                            and
                            <a className="label__link" href="#">
                              Privacy Policy
                            </a>
                            of Laila.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="formField formField__mb5 ">
                      <button
                        type="submit"
                        disabled={TnC ? false : true}
                        className="btn btn__primary btn--xl w_100"
                      >
                        Create Account
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="bottomText">
                <p className="para para--center">
                  Already have an account?{" "}
                  <Link to="/signin" className="link">
                    Sign in
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      {meta?.status && (
        <SuccessModal
          closeModal={closeRtkModal}
          sucessfullMsg={meta?.msg}
          Heading={"Signup done sucessfully!"}
        />
      )}
      {meta?.status === false && (
        <ErrorModal
          errorMessage={meta?.msg}
          closeModal={closeRtkModal}
          footerVisible={true}
        />
      )}
      {loading && <Loader />}
    </>
  );
}

export default SignUpStepOne;
