import React from "react";

function DeclinedJobsIcon() {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="80" height="80" rx="40" fill="#12B76A" />
      <path
        d="M48.8337 58.75V25.4167C48.8337 24.3116 48.3947 23.2518 47.6133 22.4704C46.8319 21.689 45.7721 21.25 44.667 21.25H36.3337C35.2286 21.25 34.1688 21.689 33.3874 22.4704C32.606 23.2518 32.167 24.3116 32.167 25.4167V58.75M23.8337 29.5833H57.167C59.4682 29.5833 61.3337 31.4488 61.3337 33.75V54.5833C61.3337 56.8845 59.4682 58.75 57.167 58.75H23.8337C21.5325 58.75 19.667 56.8845 19.667 54.5833V33.75C19.667 31.4488 21.5325 29.5833 23.8337 29.5833Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.623 60.1787L64.4583 28.5416"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DeclinedJobsIcon;
