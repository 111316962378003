import React from "react";
import BackIcon from "@/assets/svgs/BackIcon";
import Tooltip from "@mui/material/Tooltip";

function CandidateDetailHeader({ candidateData, changeCandidateViewType }) {
  return (
    <>
      <div className="page__head">
        <h2 className="page__head--title">
          <button className="btn btn__transparent btn__back border_0 p_0">
            <BackIcon />
          </button>
          {candidateData?.candidate?.firstName +
            " " +
            candidateData?.candidate?.lastName}
        </h2>
        <div className="page__head--actions">
          <Tooltip title="Back" placement="bottom" arrow>
            <button
              onClick={() => changeCandidateViewType("list", candidateData)}
              className="btn btn__white btn__edit"
            >
              Back
            </button>
          </Tooltip>
        </div>
      </div>
    </>
  );
}

export default CandidateDetailHeader;
