import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { UPDATE_BANK_INFORMATION } from '@/rtk-apis/end-points';

const updateBankDetails = createAsyncThunk('updateBankDetails', async (body) => {
  try {
    const response = await httpService.put(UPDATE_BANK_INFORMATION, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default updateBankDetails;
