import React from "react";

function CopyIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2226_2891)">
        <path
          d="M3.33325 10.0007H2.66659C2.31296 10.0007 1.97382 9.86018 1.72378 9.61013C1.47373 9.36008 1.33325 9.02094 1.33325 8.66732V2.66732C1.33325 2.3137 1.47373 1.97456 1.72378 1.72451C1.97382 1.47446 2.31296 1.33398 2.66659 1.33398H8.66659C9.02021 1.33398 9.35935 1.47446 9.60939 1.72451C9.85944 1.97456 9.99992 2.3137 9.99992 2.66732V3.33398M7.33325 6.00065H13.3333C14.0696 6.00065 14.6666 6.5976 14.6666 7.33398V13.334C14.6666 14.0704 14.0696 14.6673 13.3333 14.6673H7.33325C6.59687 14.6673 5.99992 14.0704 5.99992 13.334V7.33398C5.99992 6.5976 6.59687 6.00065 7.33325 6.00065Z"
          stroke="#384250"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2226_2891">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CopyIcon;
