import { createSlice } from '@reduxjs/toolkit';
import candidateSignUp from '../../../rtk-apis/candidate-rtk-apis/candidateSignUp';

const initialState = {
  message: '',
  data: {},
  meta: {},
  loading: false,
  error: ''
};

const candidateSignUpSlice = createSlice({
  name: 'candidateSignUpSlice',
  initialState,
  reducers: {
    closeCandidateSignUpModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(candidateSignUp.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(candidateSignUp.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(candidateSignUp.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  }

});

export default candidateSignUpSlice.reducer;

export const { closeCandidateSignUpModal } = candidateSignUpSlice.actions;
