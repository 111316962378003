import React, { useEffect, useState } from "react";
import PlusIcon from "@/assets/svgs/PlusIcon";
import { Link } from "react-router-dom";
import EditIcon from "@/assets/svgs/EditIcon";
import LinkIcon from "@/assets/svgs/LinkIcon";
import DotIcon from "@/assets/svgs/Dot";
import ActivateIcon from "@/assets/svgs/ActivateIcon";
import DeactivateIcon from "@/assets/svgs/DeactivateIcon";
import { Tooltip } from "@mui/material";
import recruiterDetails from "@/rtk-apis/recruiterProfile/recruiterDetails";
import addEducationRecruiter from "@/rtk-apis/recruiterProfile/addEducationRecruiter";
import { useDispatch, useSelector } from "react-redux";
import {
  SaveUserDetails,
  firstLetterCapital,
  formatPhoneNumber,
  getGraduationYear,
} from "@/utils/helper";
import { INSTITUTION_LOGO_BUCKET } from "@/utils/constants";
import Loader from "@/elements/Loader";
import EducationalModal from "@/components/modals/EducationalModal";
import { SuccessModal } from "@/components/modals/SuccessModal";
import { closeRtkModal } from "@/store/slices/recruiterProfile/addEducationRecruiterSlice";
import { closeRtkModal as closeActiveRtkModal } from "@/store/slices/recruiterProfile/cancelDeactivateRecruiterSlice";
import UniversitySvg from "@/assets/svgs/UniversitySvg";
import { RecruiterProfileDeactivateModal } from "@/components/modals/recruiterProfileDeactivateModal";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import cancelDeactivateRecruiter from "@/rtk-apis/recruiterProfile/cancelDeactivateRecruiter";
import WishIcon from "@/assets/svgs/WishIcon";
import Rating from "@mui/material/Rating";
import StarIcon from "@/assets/svgs/StarIcon";
import moment from "moment";
import { GetUserDetails, getInitial } from "@/utils/helper";
import NotificationButton from "../../components/elements/notificationButton";

function MyProfileDetails({
  setOpenNotificationModal,
  OpenNotificationModal,
  setOpenDetailPage,
  setOpenEditPage,
}) {
  const user = GetUserDetails();
  const dispatch = useDispatch();
  const [value, setValue] = useState(2);
  const [showFullNotes, setShowFullNotes] = useState({});

  const { data, loading } = useSelector((globalState) => {
    return globalState.recruiterDetailsResponse;
  });

  const addEducationResponse = useSelector((globalState) => {
    return globalState.addEducationRecruiter;
  });

  const cancelDeactivateRecruiterRes = useSelector((globalState) => {
    return globalState.cancelDeactivateRecruiter;
  });

  const [openModal, setOpenModal] = useState(false);
  const [openCompanyDectivateModal, setOpenCompanyDectivateModal] = useState(
    false
  );
  const [openActivationModel, setOpenActivationModel] = useState(false);

  useEffect(() => {
    getDetails(user?.recruiter?.recruiterId);
  }, [user?.recruiter?.recruiterId]);

  // useEffect(() => {
  //   if (data?.email) {
  //     SaveUserDetails(data);
  //   }
  // }, [data?.email]);

  const getDetails = async (recruiterId) => {
    await dispatch(closeRtkModal());
    await dispatch(recruiterDetails(recruiterId));
  };

  const addRecruiterEducation = async (recruiterData) => {
    const eduObj = {
      recruiterId: data?.recruiterId,
      institutionName: recruiterData?.institutionName,
      logoName: recruiterData?.institutionNameLogo,
      degree: recruiterData?.education,
      fieldOfStudy: recruiterData?.majorStudy,
      startMonth: recruiterData?.graduationStartMonth,
      startYear: recruiterData?.graduationStartYear,
      endMonth: recruiterData?.graduationEndMonth,
      endYear: recruiterData?.graduationEndYear,
    };
    await dispatch(addEducationRecruiter(eduObj));
    getDetails();
    setOpenModal(false);
  };

  const closeSuccessModal = () => {
    dispatch(closeRtkModal());
  };

  const activateRecruiter = () => {
    dispatch(cancelDeactivateRecruiter(data?.recruiterId));
    setOpenActivationModel(false);
  };

  const closeActiveSuccessModal = () => {
    dispatch(closeActiveRtkModal());
    getDetails();
  };

  const refreshRecruiterProfile = () => {
    setOpenCompanyDectivateModal(false);
    getDetails();
  };

  const toggleReadMore = (index) => {
    setShowFullNotes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const filteredRatings = data?.ratings?.filter((rating) => !rating?.hide);

  return (
    <>
      <div className="dataCol">
        <div className="page page__full">
          <div className="page__head">
            <h2 className="page__head--title">Profile</h2>
            <div className="listCol__head--actions">
              <Tooltip title="Edit" placement="bottom" arrow>
                <button
                  onClick={() => {
                    setOpenDetailPage(false);
                    setOpenEditPage(true);
                  }}
                  className="btn btn__white btn__edit p_0"
                >
                  <EditIcon />
                </button>
              </Tooltip>
              {data?.cancelStatus ? (
                <Tooltip title="Cancel request" placement="bottom" arrow>
                  <button
                    className="btn btn__white btn__edit p_0"
                    onClick={() => setOpenActivationModel(true)}
                  >
                    <ActivateIcon />
                  </button>
                </Tooltip>
              ) : (
                <Tooltip title="Disable User" placement="bottom" arrow>
                  <button
                    className="btn border-red btn__edit p_0"
                    onClick={() => setOpenCompanyDectivateModal(true)}
                  >
                    <DeactivateIcon />
                  </button>
                </Tooltip>
              )}
              <NotificationButton
                OpenNotificationModal={OpenNotificationModal}
                setOpenNotificationModal={setOpenNotificationModal}
              />
            </div>
          </div>
          <div className="page__body">
            <div className="details">
              <section className="details__section">
                <div className="initials initials__lg">
                  {data?.firstName && firstLetterCapital(data?.firstName[0])}
                </div>
                <div className="details__section--name details__section--name--assignRecruiter d-flex item-align">
                  {firstLetterCapital(data?.firstName) +
                    " " +
                    firstLetterCapital(data?.lastName)}
                  {filteredRatings?.length ? (
                    <div className="reviewCountInfo">
                      <span className="reviewCountInfo__totalRating">
                        {data?.ratingCount?.toFixed(1)} Rating
                      </span>
                      <StarIcon /> <span>{data?.reviewCount} Reviews</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="details__row m_bottomNone">
                  <div className="details__row--item bgGray">
                    <span>Email:</span>
                    {data?.email}
                  </div>
                  {data?.phoneNumber && (
                    <div className="details__row--item bgGray">
                      <span>Phone:</span>
                      {data?.countryCode} {formatPhoneNumber(data?.phoneNumber)}
                    </div>
                  )}
                </div>
              </section>

              <section className="details__section">
                <h4 className="details__section--title">
                  Educational Details
                  <button
                    type="button"
                    className="btn btn__white btn--sm"
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    <PlusIcon />
                  </button>
                </h4>
                <div className="fieldBox flexRow flexRow_3  w__100">
                  {data?.qualificationArray?.length &&
                    data?.qualificationArray.map((item, index) => (
                      <div className="flexCol" key={index}>
                        <div className="educationInfobox">
                          <div className="educationInfobox__educationLogo">
                            {item?.logoName ? (
                              <img
                                src={INSTITUTION_LOGO_BUCKET + item?.logoName}
                                alt=""
                              />
                            ) : (
                              <UniversitySvg />
                            )}
                          </div>
                          <div className="educationInfobox__detail">
                            <h3 className="title">{item?.institutionName}</h3>
                            <div className="subtitle">
                              {item?.degree && <p>{item?.degree}</p>}
                              {item?.fieldOfStudy && (
                                <span>
                                  <DotIcon />
                                  {item?.fieldOfStudy}
                                </span>
                              )}
                            </div>
                            <div className="subtitle">
                              <span>{getGraduationYear(item)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </section>

              <section className="details__section">
                <h4 className="details__section--title">
                  Professional Details
                </h4>
                <div className="fieldBox flexRow">
                  <div className="flexCol">
                    <label className="flexCol__label">Recruiting Fields</label>
                    <div className="flexCol__text">
                      {data?.skillSet?.length
                        ? data?.skillSet.map((item, i) => {
                            return (
                              <span key={i}>
                                {i + 1 === data?.skillSet?.length
                                  ? item
                                  : `${item}, `}
                              </span>
                            );
                          })
                        : "--"}
                    </div>
                  </div>
                  <div className="flexCol">
                    <label className="flexCol__label">
                      Years of experience
                    </label>
                    <div className="flexCol__text">
                      {data?.experience && data?.experience <= 1
                        ? data?.experience + " Year"
                        : data?.experience + " Years"}
                    </div>
                  </div>
                </div>
                <div className="fieldBox flexRow m_bottomNone">
                  <div className="flexCol">
                    <label className="flexCol__label">LinkedIn Account</label>
                    {data?.linkedin ? (
                      <Link
                        to={data?.linkedin ? data?.linkedin : "#"}
                        target="_blank"
                        className="link link__primary link__center"
                      >
                        {data?.linkedin}
                        <LinkIcon />
                      </Link>
                    ) : (
                      "--"
                    )}
                  </div>
                </div>
              </section>

              {filteredRatings?.length ? (
                <section className="details__section">
                  <h4 className="details__section--title">
                    Reviews and Ratings
                  </h4>
                  <div className="reviewSec">
                    <h5 className="viewAllReview">All Reviews</h5>
                    <div className="reviews">
                      {data?.ratings
                        ?.filter((rating) => !rating?.hide)
                        .map((rating, index) => {
                          const isLongNote = rating?.notes?.length > 250;
                          const shouldShowFullNote = showFullNotes[index];
                          const displayedNote =
                            isLongNote && !shouldShowFullNote
                              ? `${rating?.notes.substring(0, 250)}...`
                              : rating?.notes;
                          return (
                            <div className="reviews__row" key={index}>
                              <div className="reviewDetails">
                                <div
                                  className={`pic ${
                                    rating?.company?.companyLogo
                                      ? ""
                                      : "pic__rounded"
                                  }`}
                                >
                                  {rating?.company?.companyLogo ? (
                                    <>
                                      <img
                                        className="pic__img"
                                        src={rating?.company?.companyLogo}
                                        alt=""
                                      />
                                      <span className="wishIcon">
                                        <WishIcon />
                                      </span>
                                    </>
                                  ) : (
                                    <span className="initials">
                                      {getInitial(rating?.company?.companyName)}
                                    </span>
                                  )}
                                </div>
                                <div className="details details--widthAuto">
                                  <h5 className="userName">
                                    <span>
                                      {rating?.company?.companyName}{" "}
                                      <span className="reviewDate">
                                        {moment(rating?.updatedAt).format(
                                          "D MMM, YYYY"
                                        )}
                                      </span>
                                    </span>
                                  </h5>
                                  <div className="ratingsInfo">
                                    <span className="ratings">
                                      <Rating
                                        precision={0.5}
                                        name="read-only"
                                        value={rating?.averageRating}
                                        readOnly
                                        sx={{
                                          "& .MuiRating-iconFilled": {
                                            color: "#28A0E8",
                                          },
                                        }}
                                      />
                                    </span>
                                    {rating?.averageRating} Rating
                                  </div>
                                  <p className="recrutingCunsultationText">
                                    <strong>Recruiting:</strong>{" "}
                                    {rating?.job?.title}
                                  </p>
                                </div>
                              </div>
                              <p>
                                {displayedNote}
                                {isLongNote && (
                                  <button
                                    onClick={() => toggleReadMore(index)}
                                    className="readMoreButton"
                                  >
                                    {shouldShowFullNote
                                      ? "Read Less"
                                      : "Read More"}
                                  </button>
                                )}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </section>
              ) : (
                ""
              )}

              {loading && <Loader />}
              {openModal && (
                <EducationalModal
                  closeModal={() => setOpenModal(false)}
                  onSubmit={addRecruiterEducation}
                />
              )}
              {openCompanyDectivateModal && (
                <RecruiterProfileDeactivateModal
                  openModal={openCompanyDectivateModal}
                  closeModal={() => setOpenCompanyDectivateModal(false)}
                  refreshProfile={() => refreshRecruiterProfile()}
                />
              )}
              {addEducationResponse?.meta?.status === true && (
                <SuccessModal
                  closeModal={closeSuccessModal}
                  sucessfullMsg={addEducationResponse?.meta?.msg}
                  Heading={"Educational Details Added!"}
                />
              )}
              {openActivationModel && (
                <ConfirmationModal
                  closeModal={() => setOpenActivationModel(false)}
                  onSubmit={activateRecruiter}
                  heading={"Cancel Request!"}
                  confirmationMessage={
                    "Are you sure want to cancel deactivation request?"
                  }
                />
              )}
              {cancelDeactivateRecruiterRes?.meta?.status === true && (
                <SuccessModal
                  closeModal={closeActiveSuccessModal}
                  sucessfullMsg={"Deactivation request cancelled."}
                  Heading={"Request Cancel!"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyProfileDetails;
