import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { INTERVIEW_JOIN_MEETING } from '@/rtk-apis/end-points';

const joinMeeting = createAsyncThunk('joinMeeting', async (body) => {
  try {
    const response = await httpService.post(INTERVIEW_JOIN_MEETING, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default joinMeeting;
