import React from "react";

const LogoutSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3003 5.53366C16.3489 6.58265 17.063 7.91903 17.3522 9.37382C17.6414 10.8286 17.4927 12.3365 16.925 13.7068C16.3572 15.0771 15.3959 16.2483 14.1626 17.0723C12.9293 17.8963 11.4794 18.3361 9.9961 18.3361C8.51284 18.3361 7.06289 17.8963 5.82958 17.0723C4.59626 16.2483 3.63496 15.0771 3.06723 13.7068C2.49951 12.3365 2.35084 10.8286 2.64003 9.37382C2.92922 7.91903 3.64328 6.58265 4.69193 5.53366M10.0003 1.66699V10.0003"
        stroke="#F04438"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default LogoutSvg;
