import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { UPADATE_TAX_RESIDENCE_INFORMATION } from '@/rtk-apis/end-points';

const updateTaxResidenceInfo = createAsyncThunk('updateTaxResidenceInfo', async (body) => {
  try {
    const response = await httpService.post(UPADATE_TAX_RESIDENCE_INFORMATION, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default updateTaxResidenceInfo;
