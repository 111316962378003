import { createSlice } from '@reduxjs/toolkit';
import paymentInformation from '@/rtk-apis/payments/paymentInformation';

const initialState = {
  message: '',
  data: {},
  meta: {},
  loading: false,
  error: ''
};

const paymentInformationSlice = createSlice({
  name: 'paymentInformation',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(paymentInformation.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(paymentInformation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(paymentInformation.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  }

});

export default paymentInformationSlice.reducer;

export const { closeRtkModal } = paymentInformationSlice.actions;
