import React from "react";

function JobRequestIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9 4.77778V2.88889C12.9 2.38792 12.7209 1.90748 12.4021 1.55324C12.0833 1.19901 11.6509 1 11.2 1H7.8C7.34913 1 6.91673 1.19901 6.59792 1.55324C6.27911 1.90748 6.1 2.38792 6.1 2.88889V4.77778M2.7 4.77778H16.3C17.2389 4.77778 18 5.62346 18 6.66667V16.1111C18 17.1543 17.2389 18 16.3 18H2.7C1.76112 18 1 17.1543 1 16.1111V6.66667C1 5.62346 1.76112 4.77778 2.7 4.77778Z"
        stroke="#6C737F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 9V12M9.5 15V12M7 12H9.5M12 12H9.5"
        stroke="#6C737F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default JobRequestIcon;
