import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BackIcon from "@/assets/svgs/BackIcon";
import LinkIcon from "@/assets/svgs/LinkIcon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import {
  GetUserDetails,
  firstLetterCapital,
  formatPhoneNumber,
  saveInterviewDetails,
  capitalizeEachWord,
} from "@/utils/helper";
import customerCandidateInterviewData from "@/rtk-apis/customer/interviews/customerCandidateInterviewData";
import CustomPagination from "@/elements/pagination/customPagination";
import { useSelector, useDispatch } from "react-redux";
import Loader from "@/elements/Loader";
import MeetingStatus from "@/components/HOCs/MeetingStatus";
import NotificationButton from "../../components/elements/notificationButton";

function InterviewsDetails({
  selectedCandidate,
  selectedTab,
  selectedRecruiterInterview,
  addResponsiveClass,
  setAddResponsiveClass,
}) {
  const user = GetUserDetails();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const [OpenNotificationModal, setOpenNotificationModal] = useState(false);
  const { data, loading } = useSelector((globalState) => {
    return globalState.customerCandidatesInterviewDataResponse;
  });

  const candidatesInterviewsListResponse = useSelector((globalState) => {
    return globalState.candidatesInterviewsListResponse;
  });

  useEffect(() => {
    interviewListAPI();
  }, [selectedCandidate, page]);

  const interviewListAPI = () => {
    dispatch(
      customerCandidateInterviewData({
        role: user?.customer?.role,
        employeeId: user?.customer?.employeeId,
        companyId: user.customer.companyId,
        candidateId: selectedCandidate?.candidateId,
        page,
        limit: 20,
      })
    );
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  const navigateToRescheduleUrl = (selectedInterview) => {
    let { additional_guests } = selectedInterview?.meetingInfo;
    saveInterviewDetails({
      companyId: selectedInterview?.companyId,
      jobId: selectedInterview?.jobId,
      jmtId: selectedInterview?.jmtId,
      candidateId: selectedInterview?.candidateId,
      recruiterId: selectedInterview?.recruiterId,
      employeeId: selectedInterview?.employeeId,
      adminId: null,
    });
    let url = `https://schedule.nylas.com/${selectedInterview?.meetingInfo?.page_slug}/reschedule/${selectedInterview?.meetingInfo?.edit_hash}`;
    if (additional_guests) {
      url = `${url}?additional_guests=${additional_guests}`;
    }
    window.open(url, "_blank");
  };

  const meetingTimePast = (time) => {
    const today = new Date();
    return today > new Date(time);
  };

  return (
    <>
      <div
        className={`layoutGrid__col layoutGrid--responsive  ${
          addResponsiveClass ? "layoutGrid--show" : ""
        }`}
      >
        <div className="dataCol">
          {candidatesInterviewsListResponse?.data?.rows?.length ? (
            <div className="page page__full">
              <div className="page__head">
                <h2 className="page__head--title">
                  <button
                    className="btn btn__transparent btn__back border_0 p_0"
                    type="button"
                    onClick={() => setAddResponsiveClass(false)}
                  >
                    <BackIcon />
                  </button>
                  Interviews Details
                </h2>
                <NotificationButton
                  OpenNotificationModal={OpenNotificationModal}
                  setOpenNotificationModal={setOpenNotificationModal}
                />
              </div>
              {selectedTab == "1" ? (
                <div className="page__body bg--white">
                  <div className="dataCol__head head--inner head--responsive">
                    <div className="item">
                      {selectedCandidate?.firstName?.length && (
                        <div className="initials initials__lg">
                          {firstLetterCapital(selectedCandidate?.firstName[0])}
                        </div>
                      )}
                      <div className="item__info">
                        <h4 className="item__info--title">
                          {firstLetterCapital(selectedCandidate?.firstName) +
                            " " +
                            firstLetterCapital(selectedCandidate?.lastName)}
                        </h4>
                        <div className="details__row">
                          {selectedCandidate?.phoneNumber && (
                            <div className="details__row--item">
                              <span>Phone:</span>
                              {selectedCandidate?.countryCode}{" "}
                              {formatPhoneNumber(
                                selectedCandidate?.phoneNumber
                              )}
                            </div>
                          )}
                          {selectedCandidate?.email && (
                            <div className="details__row--item">
                              <span>Email:</span>
                              {selectedCandidate?.email}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tabContainer">
                    <TableContainer
                      component={Paper}
                      className="tableContainer"
                      style={{ height: "calc(100dvh - 182px)" }}
                    >
                      <Table className="table">
                        <TableHead>
                          <TableRow>
                            <TableCell>S.</TableCell>
                            <TableCell>Interview Title</TableCell>
                            <TableCell>Interview Date</TableCell>
                            <TableCell>Interview Time</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Job Team Member</TableCell>
                            <TableCell>Interview URL</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        {data?.rows?.length ? (
                          <TableBody>
                            {data?.rows?.map((selectedInterview, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>
                                    {page > 1
                                      ? (page - 1) * 20 + (index + 1)
                                      : index + 1}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <div className="cellText">
                                      <span className="cellText__dark">
                                        {selectedInterview?.title}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      <span className="cellText__dark">
                                        {moment(
                                          selectedInterview?.meeting_start_time
                                        ).format("D MMM, YYYY")}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      <span className="cellText__dark">
                                        {moment(
                                          selectedInterview?.meeting_start_time
                                        ).format("hh:mm A") +
                                          " - " +
                                          moment(
                                            selectedInterview?.meeting_end_time
                                          ).format("hh:mm A")}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      <span className="cellText__dark text--success">
                                        <MeetingStatus
                                          time={
                                            selectedInterview.meeting_end_time
                                          }
                                          interviewStatus={
                                            selectedInterview?.status
                                          }
                                        />
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      <span className="cellText__dark">
                                        {selectedInterview?.jmtMember?.length >
                                        1
                                          ? "Panel Discussion"
                                          : capitalizeEachWord(
                                              selectedInterview?.jmtMember[0]
                                            )}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="cellText">
                                      {selectedInterview?.meetingInfo
                                        ?.location && (
                                        <Link
                                          target={"_blank"}
                                          to={
                                            selectedInterview?.meetingInfo
                                              ?.location
                                          }
                                          onCopy={(e) => {
                                            if (
                                              meetingTimePast(
                                                selectedInterview.meeting_end_time
                                              ) ||
                                              selectedInterview?.status ===
                                                "cancelled"
                                            ) {
                                              e.preventDefault();
                                              return false;
                                            }
                                          }}
                                          className={`flexCol__text text--primary  ${
                                            meetingTimePast(
                                              selectedInterview.meeting_end_time
                                            ) ||
                                            selectedInterview?.status ===
                                              "cancelled"
                                              ? "formField__disabled"
                                              : ""
                                          }`}
                                        >
                                          {
                                            selectedInterview?.meetingInfo
                                              ?.location
                                          }
                                          <LinkIcon />
                                        </Link>
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="actions flex--end">
                                      {selectedInterview?.status ==
                                      "cancelled" ? (
                                        "Cancelled"
                                      ) : (
                                        <button
                                          onClick={() =>
                                            navigateToRescheduleUrl(
                                              selectedInterview
                                            )
                                          }
                                          className="btn btn__white"
                                          disabled={
                                            meetingTimePast(
                                              selectedInterview.meeting_start_time
                                            )
                                              ? true
                                              : false
                                          }
                                        >
                                          Reschedule
                                        </button>
                                      )}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        ) : (
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <div className="no-data floating">
                                  No Results Found!
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                    <div className="pagination pagination__center">
                      {data?.rows?.length ? (
                        <CustomPagination
                          page={page}
                          totalPageCount={Math.ceil(data?.count / 20)}
                          changePage={(event, value) =>
                            changePage(event, value)
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="details">
                  <div className="fieldBox flexRow">
                    <div className="flexCol">
                      <label className="flexCol__label">Interview Title</label>
                      <div className="flexCol__text">
                        {selectedRecruiterInterview.title}
                      </div>
                    </div>
                    <div className="flexCol">
                      <label className="flexCol__label">Interview Date</label>
                      <div className="flexCol__text">
                        {moment(
                          selectedRecruiterInterview?.meeting_start_time
                        ).format("D MMM, YYYY")}
                      </div>
                    </div>
                  </div>
                  <div className="fieldBox flexRow">
                    <div className="flexCol">
                      <label className="flexCol__label">Interview Time</label>
                      <div className="flexCol__text">
                        {moment(
                          selectedRecruiterInterview?.meeting_start_time
                        ).format("hh:mm A") +
                          " - " +
                          moment(
                            selectedRecruiterInterview?.meeting_end_time
                          ).format("hh:mm A")}
                      </div>
                    </div>
                    <div className="flexCol">
                      <label className="flexCol__label">Interview URL</label>
                      {selectedRecruiterInterview?.meetingInfo?.location && (
                        <Link
                          target={"_blank"}
                          to={selectedRecruiterInterview?.meetingInfo?.location}
                          onCopy={(e) => {
                            if (
                              meetingTimePast(
                                selectedRecruiterInterview?.meeting_end_time
                              ) ||
                              selectedRecruiterInterview?.status === "cancelled"
                            ) {
                              e.preventDefault();
                              return false;
                            }
                          }}
                          className={`flexCol__text text--primary  ${
                            meetingTimePast(
                              selectedRecruiterInterview?.meeting_end_time
                            ) ||
                            selectedRecruiterInterview?.status === "cancelled"
                              ? "formField__disabled"
                              : ""
                          }`}
                        >
                          {selectedRecruiterInterview?.meetingInfo?.location}
                          <LinkIcon />
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="fieldBox flexRow">
                    <div className="flexCol">
                      <label className="flexCol__label">Status</label>
                      <div className="flexCol__text text--success">
                        <MeetingStatus
                          time={selectedRecruiterInterview.meeting_end_time}
                          interviewStatus={selectedRecruiterInterview?.status}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="no-data floating">No Results Found!</div>
          )}
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
}

export default InterviewsDetails;
