import React from 'react';
import CloseIcon from '@/assets/svgs/CloseIcon';

const ConfirmationModal = ({
  closeModal,
  confirmationMessage,
  onSubmit,
  heading,
  NoBtnText = 'No',
  showCloseBtn = false,
  closeBtnAction = closeModal
}) => {
  return (
    <>
      <div className={'modal modalPrompt modal--open'}>
        <div className="modal__dialog">
          <div className="modal__content">
            <div className="modal__header">
              <h2 className="modal__header--title">{heading || ''}</h2>
              {showCloseBtn && (
                <button
                  onClick={closeBtnAction}
                  className="btn btn__transparent btnClose"
                >
                  <CloseIcon />
                </button>
              )}
            </div>
            <div className="modal__body">
              <h4 className="promptMessage">{confirmationMessage}</h4>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button className="btn btn__white" onClick={() => closeModal()}>
                  {NoBtnText}
                </button>
                <button className="btn btn__primary" onClick={() => onSubmit()}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default ConfirmationModal;
