import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { candidateFormSchema } from "@/components/schema/schema";
import { Formik, Form, Field } from "formik";
import UploadFileIcon from "@/assets/svgs/UploadFileIcon";
import ArrowDown from "@/assets/svgs/ArrowDown";
import { formatPhoneNumber, GetUserDetails } from "@/utils/helper";
import BackArrow from "@/assets/svgs/BackArrow";
import NameIcon from "@/assets/svgs/NameIcon";
import EmailIcon from "@/assets/svgs/EmailIcon";
import LinkedinIcon from "@/assets/svgs/LinkedinIcon";
import { useDispatch, useSelector } from "react-redux";
import addCandidate from "@/rtk-apis/candidates/addCandidate";
import updateCandidate from "@/rtk-apis/candidates/updateCandidate";
import staticData from "@/rtk-apis/staticData";
import ErrorModal from "@/components/modals/error-modal";
import { SuccessModal } from "@/components/modals/SuccessModal";
import { closeAddModal } from "@/store/slices/candidates/addCandidateSlice";
import { closeRtkModal } from "@/store/slices/candidates/updateCandidateSlice";
import Loader from "@/elements/Loader";
import candidateResumeUpload from "@/rtk-apis/candidates/candidateResumeUpload";
import { closeResumeModal } from "@/store/slices/candidates/candidateResumeUploadSlice";

const AddEditCandidate = ({
  backToRecruiterListing,
  editCandidateMode,
  SelectedCandidateData,
  setEditCandidateMode,
}) => {
  const dispatch = useDispatch();

  const { data } = useSelector((globalState) => {
    return globalState.static;
  });
  const { meta, loading } = useSelector((globalState) => {
    return globalState.addCandidateResponse;
  });

  const addCandidateResponse = useSelector((globalState) => {
    return globalState.updateCandidateResponse;
  });

  let initialValues = {
    firstName: editCandidateMode ? SelectedCandidateData.firstName : "",
    lastName: editCandidateMode ? SelectedCandidateData.lastName : "",
    phoneNumber: editCandidateMode ? SelectedCandidateData.phoneNumber : "",
    email: editCandidateMode ? SelectedCandidateData.email : "",
    experience: editCandidateMode ? SelectedCandidateData.experience : "",
    linkedin: editCandidateMode ? SelectedCandidateData.linkedin : "",
    education: editCandidateMode ? SelectedCandidateData.education : "",
    institutionName: editCandidateMode
      ? SelectedCandidateData.institutionName
      : "",
    graduationYear: editCandidateMode
      ? SelectedCandidateData.graduationYear
      : "",
    countryCode: "+1",
    skillset: editCandidateMode ? SelectedCandidateData.skillset : [],
    role: editCandidateMode ? SelectedCandidateData.role : "",
  };

  const handleFormSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    const userDetails = GetUserDetails();
    if (editCandidateMode) {
      let body = {
        ...values,
        candidateId: SelectedCandidateData?.candidateId,
        resumeUrl,
      };
      dispatch(updateCandidate(body));
    } else {
      let body = {
        ...values,
        countryCode: values?.countryCode ?? "",
        addedBy: userDetails?.recruiter.recruiterId,
        resumeUrl,
      };
      body.graduationYear =
        body?.graduationYear && body.graduationYear.toString();
      dispatch(addCandidate(body));
    }
  };

  useEffect(() => {
    dispatch(staticData());
  }, []);

  const uploadResumeResponse = useSelector((globalState) => {
    return globalState.candidateResumeUploadResponse;
  });

  const [resumeUrl, setResumeUrl] = useState(
    editCandidateMode ? SelectedCandidateData.resumeUrl : ""
  );
  const [resumeName, setResumeName] = useState(
    editCandidateMode ? SelectedCandidateData.resumeUrl.split("/")[4] : ""
  );

  const closeModal = () => {
    dispatch(closeAddModal());
    dispatch(closeRtkModal());
    dispatch(closeResumeModal());
    backToRecruiterListing();
    setResumeUrl("");
    setResumeName("");
    setEditCandidateMode(false);
  };

  const handleFileUpload = (e) => {
    let file = e?.target?.files;
    let formData = new FormData();
    formData.append("resume", file[0]);
    dispatch(candidateResumeUpload(formData));
  };

  useEffect(() => {
    if (uploadResumeResponse?.data?.url) {
      setResumeUrl(uploadResumeResponse?.data?.url ?? "");
      setResumeName(uploadResumeResponse?.data?.url.split("/")[4] ?? "");
    }
  }, [uploadResumeResponse?.data?.url]);

  const closeErrorModal = () => {
    dispatch(closeResumeModal());
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="dataCol">
        <div className="page page__full">
          <Formik
            initialValues={initialValues}
            validationSchema={candidateFormSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              touched,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form className="form p_0" onKeyPress={handleKeyPress}>
                <div className="page__head">
                  <h2 className="page__head--title">
                    <button
                      className="btn btn__transparent btn__back border_0 p_0"
                      onClick={() => backToRecruiterListing()}
                    >
                      <BackArrow />
                    </button>
                    {editCandidateMode
                      ? `${`${SelectedCandidateData?.firstName[0].toUpperCase()}${SelectedCandidateData?.firstName.slice(
                          1
                        )}`} ${`${SelectedCandidateData?.lastName[0].toUpperCase()}${SelectedCandidateData?.lastName.slice(
                          1
                        )}`}`
                      : "Add Candidate"}
                  </h2>
                  <div className="page__head--actions">
                    <button
                      type="button"
                      className="btn btn__white"
                      onClick={() => backToRecruiterListing()}
                    >
                      Cancel
                    </button>
                    <button className="btn btn__primary" type="submit">
                      {editCandidateMode ? "Update" : "Add"}
                    </button>
                  </div>
                </div>
                <div className="page__body p_2">
                  <section className="form__section">
                    <h4 className="form__section--title">Basic Details</h4>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.firstName && touched?.firstName
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">First Name</label>
                          <div className="formField__group sm">
                            <Field
                              type="text"
                              className="formField__input"
                              placeholder="First Name"
                              name="firstName"
                              onBlur={handleBlur}
                            />
                            <span className="formField__icon">
                              <NameIcon />
                            </span>
                          </div>
                          <span className="formField__error">
                            {errors?.firstName &&
                              touched?.firstName &&
                              errors?.firstName}
                          </span>
                        </div>
                      </div>
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.lastName &&
                            touched?.lastName &&
                            errors?.lastName
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">Last Name</label>
                          <div className="formField__group sm">
                            <Field
                              type="text"
                              className="formField__input"
                              placeholder="Last Name"
                              name="lastName"
                              onBlur={handleBlur}
                            />
                            <span className="formField__icon">
                              <NameIcon />
                            </span>
                          </div>
                          <span className="formField__error">
                            {errors?.lastName &&
                              touched?.lastName &&
                              errors?.lastName}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.phoneNumber &&
                            touched?.phoneNumber &&
                            errors?.phoneNumber
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            Phone Number
                          </label>
                          <div className="formField__group formField__phoneNumber sm">
                            <FormControl
                              fullWidth
                              size="small"
                              className="formField__select"
                            >
                              <Select
                                onChange={handleChange}
                                IconComponent={() => <ArrowDown />}
                                name="countryCode"
                                value={values?.countryCode}
                                displayEmpty
                                onBlur={handleBlur}
                              >
                                {data?.countryCode?.map((item, index) => (
                                  <MenuItem key={index} value={item.value}>
                                    {item.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <Field
                              type="text"
                              maxLength="10"
                              className="formField__input sm"
                              placeholder="Phone Number"
                              name="phoneNumber"
                              value={formatPhoneNumber(values?.phoneNumber)}
                            />
                          </div>
                        </div>
                        <span className="formField__error">
                          <span className="formField__error">
                            {errors?.phoneNumber &&
                              touched?.phoneNumber &&
                              errors?.phoneNumber}
                          </span>
                        </span>
                      </div>
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.email && touched?.email && errors?.email
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            Email Address
                          </label>
                          <div className="formField__group sm">
                            <Field
                              type="text"
                              className="formField__input"
                              placeholder="Email Address"
                              name="email"
                              onBlur={handleBlur}
                            />
                            <span className="formField__icon">
                              <EmailIcon />
                            </span>
                          </div>
                          <span className="formField__error">
                            {errors?.email && touched?.email && errors?.email}
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="form__section">
                    <h4 className="form__section--title">
                      Educational Details
                    </h4>

                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div>
                          <label className="formField__label">
                            Attach Resume
                          </label>
                          <label
                            className="formField__group sm"
                            id="resumeUrl"
                            style={{ justifyContent: "space-between" }}
                          >
                            {resumeUrl ? resumeName : "Browse file"}
                            <Field
                              type="file"
                              className="formField__input"
                              placeholder="Browse file"
                              id="resumeUrl"
                              style={{ display: "none" }}
                              name="resumeUrl"
                              accept="image/*, .doc,.docx, .DOC, .DOCX, .pdf, .PDF"
                              onChange={(e) => handleFileUpload(e)}
                            />
                            <span
                              className="formField__icon"
                              style={{ cursor: "pointer" }}
                            >
                              <UploadFileIcon />
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="formCol">
                        <div className={`formField`}>
                          <label className="formField__label">
                            Highest Education
                          </label>
                          <Autocomplete
                            style={
                              errors?.education &&
                              touched?.education && {
                                border: "1px solid #F04438",
                                borderRadius: "6px",
                              }
                            }
                            id="tags-filled"
                            options={data?.qualification}
                            defaultValue={values?.education}
                            value={values?.education}
                            name="education"
                            onChange={(event, newValue) => {
                              setFieldValue("education", newValue);
                            }}
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                placeholder="Select"
                                className="autoCompleteInput"
                                onBlur={(e) => {
                                  if (params?.inputProps?.value) {
                                    setFieldValue(
                                      "education",
                                      params.inputProps.value
                                    );
                                  } else {
                                    setFieldTouched("education", true);
                                  }
                                }}
                              />
                            )}
                          />
                          <span className="formField__error">
                            {errors?.education &&
                              touched?.education &&
                              errors?.education}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="formField">
                          <label className="formField__label">
                            Name of Institution
                          </label>
                          <Autocomplete
                            id="tags-filled"
                            options={data?.nameOfInstitution}
                            defaultValue={values?.institutionName}
                            name="institutionName"
                            onChange={(event, newValue) => {
                              setFieldValue("institutionName", newValue);
                            }}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                placeholder="Select"
                                className="autoCompleteInput"
                                onChange={(event) => {
                                  setFieldValue(
                                    "institutionName",
                                    event?.target?.value
                                  );
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.graduationYear &&
                            touched?.graduationYear &&
                            errors?.graduationYear
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            Graduation Year
                          </label>
                          <div className="formField">
                            <Field
                              type="number"
                              className="formField__input sm"
                              placeholder="Graduation Year"
                              name="graduationYear"
                            />
                          </div>
                          <span className="formField__error">
                            {errors?.graduationYear &&
                              touched?.graduationYear &&
                              errors?.graduationYear}
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="form__section">
                    <h4 className="form__section--title">
                      Professional Details
                    </h4>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.experience &&
                            touched?.experience &&
                            errors?.experience
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            Total Experience (years)
                          </label>
                          <Field
                            type="number"
                            className="formField__input sm"
                            placeholder="Total Experience"
                            name="experience"
                          />
                          <span className="formField__error">
                            {errors?.experience &&
                              touched?.experience &&
                              errors?.experience}
                          </span>
                        </div>
                      </div>
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.linkedin &&
                            touched?.linkedin &&
                            errors?.linkedin
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            LinkedIn Account
                          </label>
                          <div className="formField__group sm">
                            <Field
                              type="text"
                              className="formField__input"
                              placeholder="LinkedIn Account"
                              name="linkedin"
                              value={values?.linkedin}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <span className="formField__icon">
                              <LinkedinIcon />
                            </span>
                          </div>
                          <span className="formField__error">
                            {errors?.linkedin &&
                              touched?.linkedin &&
                              errors?.linkedin}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div>
                          <label className="formField__label">Role</label>
                          <div className="formField__group sm">
                            <Field
                              type="text"
                              className="formField__input sm"
                              placeholder="Role"
                              name="role"
                            />
                            <span className="formField__icon">
                              <LinkedinIcon />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="formRow">
                      <div className="formCol">
                        <div className={`formField`}>
                          <label className="formField__label">Skill set</label>
                          <Autocomplete
                            style={
                              errors?.skillset &&
                              touched?.skillset && {
                                border: "1px solid #F04438",
                                borderRadius: "6px",
                              }
                            }
                            multiple
                            id="tags-filled"
                            options={data?.skillSet}
                            defaultValue={values?.skillset}
                            name="skillset"
                            onChange={(event, newValue) => {
                              setFieldValue("skillset", newValue);
                            }}
                            onBlur={() => setFieldTouched("skillset", true)}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                placeholder="Select"
                                className="autoCompleteInput"
                              />
                            )}
                          />
                          <span className="formField__error">
                            {errors?.skillset &&
                              touched?.skillset &&
                              errors?.skillset}
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {((meta?.status === true && meta?.msg) ||
        (addCandidateResponse?.meta?.status === true &&
          addCandidateResponse?.meta?.msg)) && (
        <SuccessModal
          closeModal={closeModal}
          Heading={
            editCandidateMode ? "Candidate Updated!" : "Candidate Added!"
          }
          sucessfullMsg={meta?.msg || addCandidateResponse?.meta?.msg}
        />
      )}
      {(meta?.status == false ||
        addCandidateResponse?.meta?.status == false) && (
        <ErrorModal
          errorMessage={meta?.msg}
          closeModal={closeModal}
          footerVisible={true}
        />
      )}
      {loading && <Loader />}
      {uploadResumeResponse?.meta?.status === false &&
        uploadResumeResponse?.meta?.msg && (
          <ErrorModal
            errorMessage={uploadResumeResponse?.meta?.msg}
            closeModal={closeErrorModal}
            footerVisible={true}
          />
        )}
    </>
  );
};

export default AddEditCandidate;
