import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { REMOVE_ACH_DEBIT_DETAILS } from '@/rtk-apis/end-points';

const removeACHDebitDetails = createAsyncThunk('removeACHDebitDetails', async (body) => {
  try {
    const response = await httpService.put(REMOVE_ACH_DEBIT_DETAILS, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default removeACHDebitDetails;
