import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDown from "@/assets/svgs/ArrowDown";
import CloseIcon from "@/assets/svgs/CloseIcon";

function BillingPaymentsFilter({
  handleStatusChange,
  status,
  ApplyFilter,
  clearFilter,
  setShowFilter,
}) {
  return (
    <>
      <div className="filter__head">
        <h2 className="listCol__head--title">Filter</h2>
        <button
          className="btn btn__transparent border_0 p_0"
          onClick={() => setShowFilter(false)}
        >
          <CloseIcon />
        </button>
      </div>
      <div className="filterContainer">
        <ul className="listCol__list filter__list">
          <li>
            <div className="formRow">
              <div className="formCol">
                <div className="formField">
                  <label className="formField__label">Status</label>
                  <FormControl
                    fullWidth
                    size="small"
                    className="formField__select"
                  >
                    <Select
                      onChange={(e) => handleStatusChange(e?.target?.value)}
                      IconComponent={() => <ArrowDown />}
                      displayEmpty
                      value={status}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span style={{ opacity: "0.8" }}>Select</span>;
                        }

                        return selected;
                      }}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Paid">Paid</MenuItem>
                      <MenuItem value="Unpaid">Unpaid</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div className="filter__footer">
          <div className="btn__group flex--between">
            <button
              className="btn btn__transparent p_0"
              onClick={() => clearFilter()}
            >
              Clear
            </button>
            <button className="btn btn__primary" onClick={() => ApplyFilter()}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BillingPaymentsFilter;
