import React from "react";

export default function ThumbsUp() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6668 58.6666H10.6668C9.25234 58.6666 7.89579 58.1047 6.89559 57.1045C5.8954 56.1043 5.3335 54.7477 5.3335 53.3333V34.6666C5.3335 33.2521 5.8954 31.8955 6.89559 30.8954C7.89579 29.8952 9.25234 29.3333 10.6668 29.3333H18.6668M37.3335 23.9999V13.3333C37.3335 11.2115 36.4906 9.17669 34.9904 7.6764C33.4901 6.17611 31.4552 5.33325 29.3335 5.33325L18.6668 29.3333V58.6666H48.7468C50.033 58.6811 51.2812 58.2303 52.2612 57.3973C53.2413 56.5642 53.8873 55.405 54.0802 54.1333L57.7602 30.1333C57.8762 29.3689 57.8246 28.5884 57.6091 27.8459C57.3935 27.1034 57.0191 26.4167 56.5118 25.8333C56.0045 25.2499 55.3764 24.7837 54.671 24.4672C53.9657 24.1506 53.1999 23.9912 52.4268 23.9999H37.3335Z"
        stroke="#28A0E8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}