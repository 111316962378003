import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowDown from "@/assets/svgs/ArrowDown";
import logo from "@/assets/images/logo.svg";
import EditIconNew from "@/assets/svgs/EditIconNew";
import PlusIcon from "@/assets/svgs/PlusIcon";
import staticData from "@/rtk-apis/staticData";
import { useDispatch, useSelector } from "react-redux";
import { getYears } from "@/utils/helper";
import ReactQuill from "react-quill";
import { modules, formats, editorStyle } from "@/utils/constants";
import { useNavigate } from "react-router";
import candidateSignUpStepTwo from "../../rtk-apis/candidate-rtk-apis/candidateSignUpStepTwo";
import ErrorModal from "@/components/modals/error-modal";
import Loader from "@/elements/Loader";
import { SuccessModal } from "@/components/modals/SuccessModal";
import { closeCandidateStepTwoModal } from "../../store/slices/candidateSlices/candidateSignUpStepTwoSlice";
import { candidateSignUpJobDetailSchema } from "../schema/candidate-schema";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";

const CandidateSignUpStepTwo = ({ candidateData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ExperiencesArray, setExperiencesArray] = useState([]);
  const [showExperienceForm, setShowExperienceForm] = useState(true);
  const [showExperienceBox, setShowExperienceBox] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [currentlyWorkHere, setcurrentlyWorkHere] = useState(false);
  const [DisableField, setDisableField] = useState(false);

  const [initialValues, setInitialValues] = useState({
    jobTitle: "",
    company: "",
    startMonth: "",
    startYear: "",
    endMonth: "",
    endYear: "",
    description: "",
    currentlyWorkHere: false,
  });

  // **********RESPONSES**************************

  const { data: staticDataRes } = useSelector((globalState) => {
    return globalState.static;
  });

  const { meta, loading } = useSelector((globalState) => {
    return globalState.candidateSignUpStepTwoRes;
  });

  // **********USE EFFECTS**************************
  useEffect(() => {
    getStaticData();
  }, []);

  // ************FUNCTIONS*************************

  const getStaticData = () => {
    dispatch(staticData());
  };

  const handleFormSubmit = (values, resetForm) => {
    if (ExperiencesArray?.length && !editMode) {
      // call api when user adds various experineces
      let array = [...ExperiencesArray, values];
      let updatedExperienceArray = Array.from(
        new Set(array.map(JSON.stringify))
      ).map(JSON.parse);
      let body = {
        candidateId: candidateData?.candidateId,
        jobDetails: updatedExperienceArray,
        qualificationDetail: [],
      };
      dispatch(candidateSignUpStepTwo(body));
    } else if (editMode) {
      const updatedExperiences = [...ExperiencesArray, values];
      setExperiencesArray(updatedExperiences);
      setEditMode(false);
      setEditIndex(null);
      let body = {
        candidateId: candidateData?.candidateId,
        jobDetails: updatedExperiences,
        qualificationDetail: [],
      };
      dispatch(candidateSignUpStepTwo(body));
    } else if (ExperiencesArray?.length <= 0) {
      // call api when he enters only one existing experience
      let body = {
        candidateId: candidateData?.candidateId,
        jobDetails: [values],
        qualificationDetail: [],
      };
      dispatch(candidateSignUpStepTwo(body));
    }
    resetForm();
  };

  const handleAddAnotherExperience = (values) => {
    setEditMode(false);
    setInitialValues({
      jobTitle: "",
      company: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      description: "",
      currentlyWorkHere: false,
    });
    setcurrentlyWorkHere(false);
    setShowExperienceForm(false);
    setShowExperienceBox(true);
    setExperiencesArray((prevExperiences) => [...prevExperiences, values]);
  };

  const handleAddAnotherForm = (resetForm) => {
    setShowExperienceForm(true);
    setShowExperienceBox(true);
    resetForm();
  };

  const handleEditExperience = (editIndex) => {
    setEditMode(true);
    setEditIndex(editIndex);
    setShowExperienceForm(true);
    setShowExperienceBox(true);
    const experienceToEdit = ExperiencesArray[editIndex];
    setInitialValues(experienceToEdit);
    setExperiencesArray((prevExperiences) =>
      prevExperiences.filter((_, index) => index !== editIndex)
    );
  };

  const closeRtkModal = () => {
    dispatch(closeCandidateStepTwoModal());
    if (meta?.status) {
      localStorage.removeItem("signupSteponeValues");
      navigate("/signin");
    }
  };

  const isButtonDisabled = (error, value, initialValues) => {
    let status = false;
    if (showExperienceForm) {
      if (Object.keys(error)?.length != 0) {
        status = true;
      } else if (
        Object.keys(error)?.length == 0 &&
        _.isEqual(value, initialValues)
      ) {
        status = true;
      } else if (value?.description === "<p><br></p>") {
        status = true;
      }
      return status;
    } else {
      return status;
    }
  };

  const handleCheckboxChange = (value, setFieldValue) => {
    setcurrentlyWorkHere(!currentlyWorkHere);
    if (!currentlyWorkHere) {
      setExperiencesArray((prevExperiences) => {
        return prevExperiences.map((experience) => ({
          ...experience,
          currentlyWorkHere: false,
        }));
      });
    }
    if (value) {
      setFieldValue("endMonth", "");
      setFieldValue("endYear", "");
      setDisableField(true);
    } else {
      setDisableField(false);
    }
  };
  return (
    <>
      <div className="layoutGrid layoutGrid--full">
        <div className="layoutGrid__col">
          <div className="dataCol">
            <div className="page page__full page--candidateSignUpProfile">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={candidateSignUpJobDetailSchema}
                onSubmit={(values, { resetForm }) =>
                  handleFormSubmit(values, resetForm)
                }
              >
                {({
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  touched,
                  setFieldValue,
                  setFieldError,
                  resetForm,
                  setTouched,
                }) => {
                  return (
                    <Form className="form p_0">
                      <div className="page__head">
                        <h2 className="page__head--title">
                          <div className="branding">
                            <div className="branding__logo">
                              <img
                                src={logo}
                                alt="Logo | Laila"
                                className="branding__logo__img"
                              />
                            </div>
                          </div>
                        </h2>
                      </div>
                      <div className="page__body">
                        <section className="form__section">
                          <div className="candidateSignup">
                            <div className="candidateSignup__header">
                              <h4 className="title">
                                Provide Your Job Details
                              </h4>
                              <p>
                                Please enter your job details to help us
                                providing you with better services
                              </p>
                            </div>
                            {showExperienceBox &&
                              ExperiencesArray?.map((experience, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="candidateSignup__infoBox"
                                  >
                                    <div className="title">
                                      <h3>{experience?.jobTitle}</h3>
                                      <button
                                        className="link link--dark"
                                        type="button"
                                        onClick={() =>
                                          handleEditExperience(index)
                                        }
                                        disabled={editMode}
                                      >
                                        <EditIconNew />
                                        Edit
                                      </button>
                                    </div>
                                    <h4>{experience?.company}.</h4>
                                    <h5>
                                      {`${experience?.startMonth} ${experience?.startYear}`}{" "}
                                      {experience?.endMonth &&
                                      experience?.endYear
                                        ? ` -${experience?.endMonth} ${experience?.endYear}`
                                        : ""}
                                    </h5>
                                    <p>
                                      <div
                                        key={new Date().getTime()}
                                        className="showQuillTags"
                                        dangerouslySetInnerHTML={{
                                          __html: experience?.description
                                            ? experience?.description
                                            : "--",
                                        }}
                                      ></div>
                                    </p>
                                  </div>
                                );
                              })}

                            {showExperienceForm ? (
                              <div className="candidateSignup__body">
                                {ExperiencesArray?.length > 0 ? (
                                  <h4 className="form__section--title">
                                    Another Experience
                                  </h4>
                                ) : (
                                  ""
                                )}
                                <div className="formRow">
                                  <div className="formCol">
                                    <div
                                      className={`formField ${
                                        errors?.jobTitle && touched?.jobTitle
                                          ? "fieldError"
                                          : ""
                                      }`}
                                    >
                                      <label className="formField__label">
                                        Job Title
                                      </label>
                                      <div className="formField__group sm">
                                        <Field
                                          type="text"
                                          className="formField__input sm"
                                          placeholder="Recent Job Title"
                                          name="jobTitle"
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                      <span className="formField__error">
                                        {errors?.jobTitle &&
                                          touched?.jobTitle &&
                                          errors?.jobTitle}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="formRow">
                                  <div className="formCol">
                                    <div
                                      className={`formField ${
                                        errors?.company && touched?.company
                                          ? "fieldError"
                                          : ""
                                      }`}
                                    >
                                      <label className="formField__label">
                                        Company
                                      </label>
                                      <div className="formField__group sm">
                                        <Field
                                          type="text"
                                          className="formField__input sm"
                                          placeholder="Recent Company"
                                          name="company"
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                      <span className="formField__error">
                                        {errors?.company &&
                                          touched?.company &&
                                          errors?.company}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="formRow formRow__2">
                                  <div className="formCol">
                                    <div
                                      className={`formField ${
                                        errors?.startMonth &&
                                        touched?.startMonth
                                          ? "fieldError"
                                          : ""
                                      }`}
                                    >
                                      <label className="formField__label">
                                        Start Month
                                      </label>
                                      <FormControl
                                        sx={{ width: "100%" }}
                                        size="small"
                                        className="formField__select"
                                      >
                                        <Select
                                          name="startMonth"
                                          value={values?.startMonth}
                                          onChange={(event) => {
                                            setFieldValue(
                                              "startMonth",
                                              event?.target?.value
                                            );
                                          }}
                                          onBlur={(e) => {
                                            if (e) {
                                              setTouched({
                                                ...touched,
                                                startMonth: true,
                                              });
                                            }
                                          }}
                                          IconComponent={() => <ArrowDown />}
                                          displayEmpty
                                          renderValue={(selected) => {
                                            if (!selected) {
                                              return (
                                                <span
                                                  style={{ opacity: "0.8" }}
                                                >
                                                  Select Month
                                                </span>
                                              );
                                            }

                                            return selected;
                                          }}
                                        >
                                          {staticDataRes?.yearMonth?.month.map(
                                            (item, index) => (
                                              <MenuItem
                                                value={item}
                                                key={index}
                                              >
                                                {item}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                      <span className="formField__error">
                                        {errors?.startMonth &&
                                          touched?.startMonth &&
                                          errors?.startMonth}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="formCol">
                                    <div
                                      className={`formField ${
                                        errors?.startYear && touched?.startYear
                                          ? "fieldError"
                                          : ""
                                      }`}
                                    >
                                      <label className="formField__label">
                                        Start Year
                                      </label>
                                      <FormControl
                                        sx={{ width: "100%" }}
                                        size="small"
                                        className="formField__select"
                                      >
                                        <Select
                                          name="startMonth"
                                          value={values?.startYear}
                                          onChange={(event) => {
                                            setFieldValue(
                                              "startYear",
                                              event?.target?.value
                                            );
                                          }}
                                          onBlur={(e) => {
                                            if (e) {
                                              setTouched({
                                                ...touched,
                                                startYear: true,
                                              });
                                            }
                                          }}
                                          IconComponent={() => <ArrowDown />}
                                          displayEmpty
                                          renderValue={(selected) => {
                                            if (!selected) {
                                              return (
                                                <span
                                                  style={{ opacity: "0.8" }}
                                                >
                                                  Select Year
                                                </span>
                                              );
                                            }

                                            return selected;
                                          }}
                                        >
                                          {getYears(
                                            staticDataRes?.yearMonth?.startYear
                                          ).map((item, index) => (
                                            <MenuItem value={item} key={index}>
                                              {item}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <span className="formField__error">
                                        {errors?.startYear &&
                                          touched?.startYear &&
                                          errors?.startYear}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="formRow formRow__2">
                                  {/* END MONTH */}
                                  <div className="formCol">
                                    <div
                                      className={`formField ${
                                        errors?.endMonth ? "fieldError" : ""
                                      }`}
                                    >
                                      <label className="formField__label">
                                        End Month
                                      </label>
                                      <FormControl
                                        sx={{ width: "100%" }}
                                        size="small"
                                        className="formField__select"
                                      >
                                        <Select
                                          name="endMonth"
                                          value={values?.endMonth}
                                          onChange={(event) => {
                                            setFieldValue(
                                              "endMonth",
                                              event?.target?.value
                                            );
                                          }}
                                          IconComponent={() => <ArrowDown />}
                                          displayEmpty
                                          disabled={DisableField}
                                          renderValue={(selected) => {
                                            if (!selected) {
                                              return (
                                                <span
                                                  style={{ opacity: "0.8" }}
                                                >
                                                  Select Month
                                                </span>
                                              );
                                            }

                                            return selected;
                                          }}
                                        >
                                          {staticDataRes?.yearMonth?.month.map(
                                            (item, index) => (
                                              <MenuItem
                                                value={item}
                                                key={index}
                                              >
                                                {item}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                      <span className="formField__error">
                                        {errors?.endMonth}
                                      </span>
                                    </div>
                                  </div>
                                  {/* END YEAR */}
                                  <div className="formCol">
                                    <div
                                      className={`formField ${
                                        errors?.endYear ? "fieldError" : ""
                                      }`}
                                    >
                                      <label className="formField__label">
                                        End Year
                                      </label>
                                      <FormControl
                                        sx={{ width: "100%" }}
                                        size="small"
                                        className="formField__select"
                                      >
                                        <Select
                                          name="endYear"
                                          value={values?.endYear}
                                          disabled={DisableField}
                                          onChange={(event) => {
                                            setFieldValue(
                                              "endYear",
                                              event?.target?.value
                                            );
                                          }}
                                          IconComponent={() => <ArrowDown />}
                                          displayEmpty
                                          renderValue={(selected) => {
                                            if (!selected) {
                                              return (
                                                <span
                                                  style={{ opacity: "0.8" }}
                                                >
                                                  Select Year
                                                </span>
                                              );
                                            }

                                            return selected;
                                          }}
                                        >
                                          {getYears(
                                            staticDataRes?.yearMonth?.startYear
                                          ).map((item, index) => (
                                            <MenuItem value={item} key={index}>
                                              {item}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <span className="formField__error">
                                        {errors?.endYear}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="formRow">
                                  <div className="formCol">
                                    <div className="formField">
                                      <ReactQuill
                                        name="description"
                                        value={values?.description}
                                        modules={modules}
                                        formats={formats}
                                        style={editorStyle}
                                        onChange={(newValue) =>
                                          setFieldValue("description", newValue)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="candidateSignup__footer">
                              {showExperienceForm ? (
                                <div className="formField">
                                  <div className="formField__checkbox">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={values?.currentlyWorkHere}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "currentlyWorkHere",
                                              e.target.checked
                                            );
                                            handleCheckboxChange(
                                              e?.target?.checked,
                                              setFieldValue
                                            );
                                          }}
                                          name="currentlyWorkHere"
                                        />
                                      }
                                      label="I currently work here"
                                    />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="formField">
                                {showExperienceForm ? (
                                  <button
                                    className="btn btn--addExperience p_0"
                                    type="button"
                                    disabled={isButtonDisabled(
                                      errors,
                                      values,
                                      initialValues
                                    )}
                                    onClick={() =>
                                      handleAddAnotherExperience(values)
                                    }
                                  >
                                    <PlusIcon /> Add Another Experience
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn--addExperience p_0"
                                    type="button"
                                    onClick={() =>
                                      handleAddAnotherForm(resetForm)
                                    }
                                  >
                                    <PlusIcon /> Add
                                  </button>
                                )}
                              </div>
                              <div className="formField ">
                                <button
                                  type="submit"
                                  className="btn btn__primary btn--xl w_100"
                                  disabled={isButtonDisabled(
                                    errors,
                                    values,
                                    initialValues
                                  )}
                                >
                                  Submit
                                </button>
                              </div>

                              <div className="candidateSignup__footer__text">
                                <span>Or</span>
                              </div>
                              <div className="candidateSignup__footer__text">
                                <Link
                                  to="/candidate-profile-details"
                                  state={{ candidateData: candidateData }}
                                  className="link link__primary"
                                >
                                  Click here for Student
                                </Link>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {meta?.status && (
        <SuccessModal
          closeModal={closeRtkModal}
          sucessfullMsg={meta?.msg}
          Heading={"Candidate Details added!"}
        />
      )}
      {meta?.status === false && (
        <ErrorModal
          errorMessage={meta?.msg}
          closeModal={closeRtkModal}
          footerVisible={true}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default CandidateSignUpStepTwo;
