import { createSlice } from '@reduxjs/toolkit';
import addTagToCandidate from '@/rtk-apis/customer/tags/addTagToCandidate';

const initialState = {
  message: '',
  data: {},
  meta: {},
  loading: false,
  error: ''
};

const addTagToCandidateSlice = createSlice({
  name: 'addTagToCandidate',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTagToCandidate.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addTagToCandidate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(addTagToCandidate.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  }

});

export default addTagToCandidateSlice.reducer;

export const { closeRtkModal } = addTagToCandidateSlice.actions;
