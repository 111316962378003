import React, { useState, useEffect } from "react";
import SearchIcon from "@/assets/svgs/SearchIcon";
import FilterIcon from "@/assets/svgs/FilterIcon";
import InterviewsFilter from "@/customer-pages/Interviews/InterviewsFilter";
import Tab from "@mui/material/Tab";
import Search from "@/components/Search";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import { GetUserDetails } from "@/utils/helper";
import { useSelector, useDispatch } from "react-redux";
import InterviewsCandidatesListing from "@/customer-pages/Interviews/InterviewsCandidatesListing";
import InterviewsLailaListing from "@/customer-pages/Interviews/InterviewsLailaListing";
import candidatesInterviewsList from "@/rtk-apis/customer/interviews/candidatesInterviewsList";
import Loader from "@/elements/Loader";
import CustomPagination from "@/elements/pagination/customPagination";

function InterviewsListing({
  selectedIndex,
  selectedCandidate,
  changeIndex,
  changeTab,
  selectedRecruiterInterview,
  setAddResponsiveClass,
}) {
  const user = GetUserDetails();
  const [value, setValue] = useState("1");
  const [showSearch, setShowSearch] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [candidateList, setCandidateList] = useState([]);
  const [page, setPage] = useState(1);
  const [applyFilters, setApplyFilters] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("All");

  const { data, loading } = useSelector((globalState) => {
    return globalState.candidatesInterviewsListResponse;
  });

  const handleTablistChange = (event, newValue) => {
    setValue(newValue);
    changeTab(newValue);
    setStartDate("");
    setEndDate("");
    setStatus("All");
    setPage(1);
    setApplyFilters(false)
  };

  useEffect(() => {
    candidatesInterviewsListAPI();
  }, [search, page, value, applyFilters]);

  useEffect(() => {
    setCandidateList(data?.rows);
  }, [data]);

  const candidatesInterviewsListAPI = () => {
    dispatch(
      candidatesInterviewsList({
        role: user?.customer?.role,
        employeeId: user?.customer?.employeeId,
        companyId: user?.customer?.companyId,
        type: value == "1" ? "candidate" : "admin",
        page,
        limit: 20,
        search,
        startTime: startDate,
        endTime: endDate,
        status,
        jtm: null,
      })
    );
  };

  const closeSearch = () => {
    setShowSearch(false);
    setSearch("");
    setPage(1);
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  const indexAndCandidateChange = (index, candidate) => {
    changeIndex(index, candidate);
  };

  const apply = () => {
    setApplyFilters(true);
    setShowFilter(false);
  };

  const clearFilters = () => {
    setApplyFilters(false);
    setStartDate("");
    setEndDate("");
    setStatus("All");
    setShowFilter(false);
  };

  const filterApplied = () => {
    let filterCount = 0;
    if (startDate) {
      filterCount += 1;
    }
    if (endDate) {
      filterCount += 1;
    }
    if (status == "Upcoming" || status == "Past") {
      filterCount += 1;
    }
    return `(${filterCount})`;
  };

  return (
    <>
      <div className="layoutGrid__col">
        <div
          className={`listCol ${showFilter && "listCol__filter"} ${
            showSearch && "listCol__search"
          }`}
        >
          <div className="listCol__head listing__head">
            <h2 className="listCol__head--title">Interviews</h2>
            <div className="listCol__head--actions">
              <button
                onClick={() => {
                  setShowSearch(true);
                }}
                className="btn btn__white p_0"
              >
                <SearchIcon />
              </button>

              <button
                onClick={() => {
                  setShowFilter(true);
                }}
                className="btn btn__white p_0"
              >
                <FilterIcon />
              </button>
            </div>
            <Search
              placeholder={value == "1" ? "a candidate" : "an interview"}
              closeSearch={closeSearch}
              searchTerm={(value) => setSearch(value)}
            />
          </div>

          <InterviewsFilter
            closeFilter={() => setShowFilter(false)}
            apply={() => apply()}
            clearFilters={() => clearFilters()}
            startDateChange={(val) => setStartDate(val)}
            endDateChange={(val) => setEndDate(val)}
            statusChange={(val) => setStatus(val)}
            status={status}
          />

          {applyFilters && (
            <div className="listColTitle filterClear">
              <p>Filters applied {filterApplied()}</p>
              <button
                onClick={() => clearFilters()}
                className="filterClear__btn"
              >
                Clear filter
              </button>
            </div>
          )}

          <Box className="tabContainer">
            <TabContext value={value}>
              <Box className="tabContainer__head">
                <TabList onChange={handleTablistChange}>
                  <Tab label="Candidates" value="1" />
                  <Tab label="Laila" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1" className="tabContainer__item">
                <InterviewsCandidatesListing
                  changeIndex={(index, candidate) =>
                    indexAndCandidateChange(index, candidate)
                  }
                  selectedCandidate={selectedCandidate}
                  selectedIndex={selectedIndex}
                  candidateList={candidateList}
                  setAddResponsiveClass={setAddResponsiveClass}
                />
              </TabPanel>

              <TabPanel value="2" className="tabContainer__item">
                <InterviewsLailaListing
                  selectedRecruiterInterview={(index, value) =>
                    selectedRecruiterInterview(index, value)
                  }
                  selectedIndex={selectedIndex}
                  data={data}
                  setAddResponsiveClass={setAddResponsiveClass}
                />
              </TabPanel>
              <div className="pagination pagination__center">
                {data?.rows?.length ? (
                  <CustomPagination
                    page={page}
                    totalPageCount={Math.ceil(data?.count / 20)}
                    changePage={(event, value) => changePage(event, value)}
                  />
                ) : (
                  ""
                )}
              </div>
              {loading && <Loader />}
            </TabContext>
          </Box>
        </div>
      </div>
    </>
  );
}

export default InterviewsListing;
