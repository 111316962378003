import editCustomerNotes from "@/rtk-apis/customer/myJobs/notes/editCustomerNotes";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const editCustomerNotesSlice = createSlice({
  name: "editCustomerNotesSlice",
  initialState,
  reducers: {
    closeEditNoteModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editCustomerNotes.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(editCustomerNotes.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(editCustomerNotes.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default editCustomerNotesSlice.reducer;

export const { closeEditNoteModal } = editCustomerNotesSlice.actions;
