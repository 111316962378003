import React from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

const ArrowFirst = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.83333 11.3332L4.5 7.99984L7.83333 4.6665M12.5 11.3332L9.16667 7.99984L12.5 4.6665"
        stroke="#150823"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ArrowLast = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66667 11.3332L12 7.99984L8.66667 4.6665M4 11.3332L7.33333 7.99984L4 4.6665"
        stroke="#150823"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ArrowPrevious = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 12L6.5 8L10.5 4"
        stroke="#150823"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ArrowNext = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 12L10.5 8L6.5 4"
        stroke="#150823"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

function CustomPagination({ page, totalPageCount, changePage }) {
  return (
    <Pagination
      page={page}
      count={totalPageCount}
      variant="outlined"
      shape="rounded"
      showFirstButton
      showLastButton
      onChange={(event, value) => changePage(event, value)}
      renderItem={(item) => (
        <PaginationItem
          slots={{
            first: ArrowFirst,
            previous: ArrowPrevious,
            next: ArrowNext,
            last: ArrowLast,
          }}
          {...item}
        />
      )}
    />
  );
}

export default CustomPagination;
