import { createSlice } from '@reduxjs/toolkit';
import updateCandidate from '@/rtk-apis/candidates/updateCandidate';

const initialState = {
  message: '',
  data: {},
  meta: {},
  loading: false,
  error: ''
};

const updateCandidateSlice = createSlice({
  name: 'updateCandidate',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCandidate.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateCandidate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(updateCandidate.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  }

});

export default updateCandidateSlice.reducer;

export const { closeRtkModal } = updateCandidateSlice.actions;
