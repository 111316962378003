import React, { useState, useEffect } from "react";
import EditIcon from "@/assets/svgs/EditIcon";
import { getMonthLastWednesday, decryptData, USDNumberFormat } from "@/utils/helper";

const WithdrawScheduleView = ({ withdrawScheduleInfo, openEditWithdrawl }) => {
  return (
    <>
      <section className="details__section">
        <h4 className="details__section--title">
          Withdrawal Schedule
          <button
            className="btn btn__white btn--sm"
            onClick={() => openEditWithdrawl()}
          >
            <EditIcon />
          </button>
        </h4>
        <div className="paymentlist">
          <div className="paymentlist__item">
            <div className="infoBox">
              <div className="infoBox__detail">
                <h5>{`Monthly ( next on ${getMonthLastWednesday()} )`}</h5>
                <p>
                  Only when balance is {`${USDNumberFormat(withdrawScheduleInfo?.withdrawalAmount)}`} or more. 
                </p>
              </div>
            </div>
          </div>
          <div className="paymentlist__item">
            <div className="infoBox">
              {withdrawScheduleInfo?.["methodDetail"]?.["methodType"] ===
              "Paypal" ? (
                <div className="infoBox__detail">
                  <h5>
                    Paypal - {withdrawScheduleInfo["methodDetail"]["email"]}
                  </h5>
                  <p className="greenText">Active</p>
                </div>
              ) : (
                <div className="infoBox__detail">
                  <h5>
                    Bank account ending in{" "}
                    {decryptData(
                      withdrawScheduleInfo["methodDetail"]["accountNumber"]
                    ).slice(-4)}
                  </h5>
                  <p className="greenText">Active</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WithdrawScheduleView;
