import React from "react";

function PasswordLock() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.33333 9.66663V6.33329C6.33333 5.22822 6.77232 4.16842 7.55372 3.38701C8.33512 2.60561 9.39493 2.16663 10.5 2.16663C11.6051 2.16663 12.6649 2.60561 13.4463 3.38701C14.2277 4.16842 14.6667 5.22822 14.6667 6.33329V9.66663M4.66667 9.66663H16.3333C17.2538 9.66663 18 10.4128 18 11.3333V17.1666C18 18.0871 17.2538 18.8333 16.3333 18.8333H4.66667C3.74619 18.8333 3 18.0871 3 17.1666V11.3333C3 10.4128 3.74619 9.66663 4.66667 9.66663Z"
        stroke="#384250"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PasswordLock;
