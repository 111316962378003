import React from "react";

function HyperLinkIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1867_9625)">
        <path
          d="M6.66668 8.66697C6.95298 9.04972 7.31825 9.36642 7.73771 9.5956C8.15717 9.82477 8.62102 9.96105 9.09778 9.99519C9.57454 10.0293 10.0531 9.96055 10.5009 9.79349C10.9487 9.62643 11.3554 9.36502 11.6933 9.02697L13.6933 7.02697C14.3005 6.3983 14.6365 5.55629 14.6289 4.6823C14.6213 3.80831 14.2708 2.97227 13.6527 2.35424C13.0347 1.73621 12.1987 1.38565 11.3247 1.37806C10.4507 1.37046 9.60869 1.70644 8.98001 2.31364L7.83334 3.45364M9.33334 7.33364C9.04704 6.95088 8.68177 6.63418 8.26231 6.40501C7.84285 6.17584 7.37901 6.03956 6.90224 6.00541C6.42548 5.97127 5.94695 6.04006 5.49911 6.20711C5.05128 6.37417 4.6446 6.63559 4.30668 6.97364L2.30668 8.97364C1.69948 9.60231 1.3635 10.4443 1.3711 11.3183C1.37869 12.1923 1.72926 13.0283 2.34728 13.6464C2.96531 14.2644 3.80135 14.615 4.67534 14.6226C5.54933 14.6301 6.39134 14.2942 7.02001 13.687L8.16001 12.547"
          stroke="#384250"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1867_9625">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HyperLinkIcon;
