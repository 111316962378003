import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIconGray from "@/assets/svgs/CloseIconGray";
import { Formik, Form, Field } from "formik";
import { GetUserDetails, decryptData, isButtonDisabled } from "@/utils/helper";
import { VerifyACHMethodSchema } from "@/components/schema/schema";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import { closeRtkModal } from "@/store/slices/customer/payments/verifyPaymentMethodSlice";
import verifyACHPaymentMethod from "@/rtk-apis/customer/payments/verifyACHPaymentMethod";
import Loader from "@/elements/Loader";

const VerifyACHPaymentModal = ({
  openModal,
  closeModal,
  paymentMethodId,
  refreshPage,
}) => {
  const userDetails = GetUserDetails();
  const dispatch = useDispatch();
  const initialValues = {
    // firstDeposit: "",
    // secondDeposit: "",
    descriptorCode: "",
  };

  const { loading, data, meta } = useSelector((globalState) => {
    return globalState.verifyPaymentMethod;
  });

  const handleSubmit = (values) => {
    let obj = {
      methodId: paymentMethodId,
      companyId: userDetails?.customer?.companyId,
      // firstDeposit: values?.firstDeposit,
      // secondDeposit: values?.secondDeposit,
      descriptorCode: `${values?.descriptorCode}`,
    };
    dispatch(verifyACHPaymentMethod(obj));
  };

  const closeErrorModal = () => {
    dispatch(closeRtkModal());
    closeModal();
  };

  const closeSuccessModal = () => {
    dispatch(closeRtkModal());
    closeModal();
    refreshPage();
  };

  return (
    <>
      <div
        className={`modal modalPaymentWithdrawal ${
          openModal ? "modal--open" : ""
        }`}
      >
        <div className="modal__dialog">
          <Formik
            initialValues={initialValues}
            validationSchema={VerifyACHMethodSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ errors, values, touched }) => (
              <Form>
                <div className="modal__content modal__content--md">
                  <div className="modal__header">
                    <h2 className="modal__header--title">
                      Verify Bank Account
                    </h2>

                    <button
                      className="btn btn__transparent btnClose"
                      onClick={() => closeModal(true)}
                    >
                      <CloseIconGray />
                    </button>
                  </div>
                  <div className="modal__body modal-body-left">
                    <p>
                      A small deposit was sent to your bank account. To verify
                      this account, please enter the 6-character code found at
                      the beginning of the statement description for this
                      deposit. The description will start with “SM” and end with
                      “-LAILA.CO”.
                    </p>
                    <p>
                      <b>Example:</b> If the description is “SMUFH7-LAILA.CO”,
                      enter “SMUFH7”.
                    </p>

                    {/* <div className="formRow formRow__2 w_100">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.firstDeposit && touched?.firstDeposit
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            First Deposit
                          </label>
                          <div className="formField__group sm">
                            <Field
                              value={values?.firstDeposit}
                              type="number"
                              className="formField__input sm"
                              placeholder=""
                              name="firstDeposit"
                            />
                          </div>
                          <span className="formField__error">
                            {errors?.firstDeposit &&
                              touched?.firstDeposit &&
                              errors?.firstDeposit}
                          </span>
                        </div>
                      </div>
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.secondDeposit && touched?.secondDeposit
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            Second Deposit
                          </label>
                          <Field
                            value={values?.secondDeposit}
                            type="number"
                            className="formField__input sm"
                            placeholder=""
                            name="secondDeposit"
                          />
                          <span className="formField__error">
                            {errors?.secondDeposit &&
                              touched?.secondDeposit &&
                              errors?.secondDeposit}
                          </span>
                        </div>
                      </div>
                    </div> */}
                    <div className="formRow w_100">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.descriptorCode && touched?.descriptorCode
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            Descriptor Code
                          </label>
                          <div className="formField__group sm gap-0">
                            <Field
                              // style={{ opacity: 0.45 }}
                              type="text"
                              className="formField__input sm"
                              placeholder="SM11AA"
                              name="descriptorCode"
                              maxLength={6}
                            />
                            <span className="prefix">-LAILA.CO</span>
                          </div>
                          <span className="formField__error">
                            {errors?.descriptorCode &&
                              touched?.descriptorCode &&
                              errors?.descriptorCode}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="btn__group">
                      <button
                        onClick={() => closeModal(true)}
                        className="btn btn__white"
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn__primary"
                        type="submit"
                        disabled={
                          isButtonDisabled(errors, values, initialValues) ||
                          values?.descriptorCode?.length !== 6 ||
                          !values?.descriptorCode?.includes("SM")
                        }
                      >
                        Verify
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      {loading && <Loader />}
      {meta?.status && (
        <SuccessModal
          closeModal={closeSuccessModal}
          sucessfullMsg={meta?.msg}
          Heading={"Verify Details!"}
        />
      )}
      {meta?.status === false && (
        <ErrorModal
          errorMessage={meta?.msg}
          closeModal={closeErrorModal}
          footerVisible={true}
        />
      )}
    </>
  );
};

export default VerifyACHPaymentModal;
