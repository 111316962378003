import { createSlice } from "@reduxjs/toolkit";
import addReviewsRatingsToRecruiter from "@/rtk-apis/customer/myJobs/reviewsRatings/addReviewsRatingsToRecruiter";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const addReviewsRatingsToRecruiterSlice = createSlice({
  name: "addReviewsRatingsToRecruiterSlice",
  initialState,
  reducers: {
    closeAddReviewsRatingsModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addReviewsRatingsToRecruiter.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addReviewsRatingsToRecruiter.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(addReviewsRatingsToRecruiter.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default addReviewsRatingsToRecruiterSlice.reducer;

export const {
  closeAddReviewsRatingsModal,
} = addReviewsRatingsToRecruiterSlice.actions;
