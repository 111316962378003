import React, { useEffect, useState } from "react";
import moment from "moment";
import { GetUserDetails } from "@/utils/helper";
import interviewList from "@/rtk-apis/interviews/interviewList";
import { useSelector, useDispatch } from "react-redux";
import Loader from "@/elements/Loader";
import MeetingStatus from "@/components/HOCs/MeetingStatus";

function InterviewsLailaListing({ selectedIndex, selectedRecruiterInterview }) {
  const user = GetUserDetails();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const { data, loading } = useSelector((globalState) => {
    return globalState.interviewListResponse;
  });

  useEffect(() => {
    interviewListAPI();
  }, [page]);

  useEffect(() => {
    if (data?.rows?.length > 0) selectedRecruiterInterview(0, data?.rows[0]);
    else selectedRecruiterInterview(0, {});
  }, [data]);

  const interviewListAPI = () => {
    dispatch(
      interviewList({
        recruiterId: user?.recruiter?.recruiterId,
        page,
        limit: 20,
        type: "admin",
      })
    );
  };
  return (
    <>
      <ul className="listCol__list listing__list">
        {data?.rows?.length > 0 ? (
          data?.rows?.map((selectedInterview, index) => {
            return (
              <li
                key={index}
                onClick={() =>
                  selectedRecruiterInterview(index, selectedInterview)
                }
                className={
                  selectedIndex === index
                    ? "listCol__list--item listCol__item--selected"
                    : "listCol__list--item"
                }
              >
                <div className="item item--recruiter item--jobs">
                  <div className="item__info">
                    <h4 className="item__info--title">
                      {selectedInterview?.title}
                    </h4>
                    <div className="item__info--subtitle">
                      <p>
                        {moment(selectedInterview?.meeting_start_time).format(
                          "D MMM, YYYY"
                        )}
                      </p>
                    </div>
                    <div className="item__info--subtitle">
                      <span className="status">
                        Status:{" "}
                        <span className="text--success">
                          <MeetingStatus
                            time={selectedInterview.meeting_end_time}
                            interviewStatus={selectedInterview?.status}
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <div className="no-data">No Results Found!</div>
        )}
      </ul>
      <div className="pagination"></div>
      {loading && <Loader />}
    </>
  );
}

export default InterviewsLailaListing;
