import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SideBar from "@/components/layout/SideBar";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@/assets/svgs/EditIcon";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import LinkIcon from "@/assets/svgs/LinkIcon";
import DefaultImg from "@/assets/images/default-img.png";
import CopyIcon from "@/assets/svgs/CopyIcon";
import { firstLetterCapital, GetUserDetails } from "@/utils/helper";
import { useDispatch, useSelector } from "react-redux";
import CompanyProfileEdit from "@/customer-pages/CompanyProfile/CompanyProfileEdit";
import companyProfileDetails from "@/rtk-apis/customer/companyProfile/getCompanyProfileDetails";
import Loader from "@/elements/Loader";
import UsersWhiteSvg from "@/assets/svgs/UsersWhiteSvg";
import { CompanyProfileDeactivateModal } from "@/components/modals/companyProfileDeactivate-modal";
import cancelCompanyDeactivationRequest from "@/rtk-apis/customer/companyProfile/cancelCompanyDeactivationRequest";
import { closeReqModal } from "@/store/slices/customer/companyProfile/cancelCompanyDeactivateRequestSlice";
import ActivateIcon from "@/assets/svgs/ActivateIcon";
import DeactivateIcon from "@/assets/svgs/DeactivateIcon";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import EmployeeList from "@/customer-pages/Profile/EmployeeList";
import { useLocation } from "react-router-dom";
import NotificationButton from "../../components/elements/notificationButton";

function CustomerCompanyProfile() {
  const dispatch = useDispatch();
  const copyLinkedinText = useRef(null);
  const userDetails = GetUserDetails();
  const role = userDetails?.customer?.role;
  const location = useLocation();

  const [OpenNotificationModal, setOpenNotificationModal] = useState(false);
  const [OpenEditCompanyPage, setOpenEditCompanyPage] = useState(false);
  const [OpenEmployeeList, setOpenEmployeeList] = useState(
    location ? location?.state?.OpenEmployeeList : false
  );
  const [refreshDetails, setRefreshDetails] = useState(false);
  const [openCompanyDectivateModal, setOpenCompanyDectivateModal] = useState(
    false
  );
  const [OpenCompanyActivationModel, setOpenCompanyActivationModel] = useState(
    false
  );

  const cancelRequestResponse = useSelector((globalState) => {
    return globalState.cancelCompanyDeactivateReqResponse;
  });

  const { data, loading } = useSelector((globalState) => {
    return globalState.getCompanyDetails;
  });

  useEffect(() => {
    getCompanyDetails();
  }, [OpenEditCompanyPage]);

  useEffect(() => {
    getCompanyDetails();
  }, [refreshDetails]);

  const getCompanyDetails = async () => {
    const companyid = userDetails?.customer?.companyId;
    dispatch(companyProfileDetails(companyid));
  };

  function copyToClipboard() {
    copyLinkedinText.current.select();
    document.execCommand("copy");
  }

  const handleEditCompanyDetailsClick = () => {
    setOpenEditCompanyPage(true);
  };

  const refreshCompanyProfileDetail = () => {
    setOpenCompanyDectivateModal(false);
    setRefreshDetails(!refreshDetails);
  };
  const handleCancelDeactiveReq = () => {
    const companyid = userDetails?.customer?.companyId;
    const employeeid = userDetails?.customer?.employeeId;
    let body = {
      companyId: companyid,
      employeeId: employeeid,
      from: "Customer",
    };
    dispatch(cancelCompanyDeactivationRequest(body));
    setOpenCompanyActivationModel(false);
  };
  const closeSuccessModal = () => {
    dispatch(closeReqModal());
    setRefreshDetails(!refreshDetails);
    setOpenCompanyActivationModel(false);
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        {OpenEditCompanyPage ? (
          <CompanyProfileEdit
            data={data}
            setOpenEditCompanyPage={setOpenEditCompanyPage}
          />
        ) : (
          <div className="layoutGrid layoutGrid--full">
            <div className="layoutGrid__col">
              <div className="dataCol">
                <div className="page page__full">
                  <div className="page__head">
                    <h2 className="page__head--title">
                      {firstLetterCapital(data?.companyName)}
                    </h2>
                    {role === "Company Admin" && (
                      <div className="listCol__head--actions">
                        <Tooltip title="Edit" placement="bottom" arrow>
                          <button
                            className="btn btn__white btn__edit p_0"
                            onClick={() => handleEditCompanyDetailsClick()}
                          >
                            <EditIcon />
                          </button>
                        </Tooltip>

                        {data?.cancelStatus === false ? (
                          <Tooltip
                            title="Disable Account"
                            placement="bottom"
                            arrow
                          >
                            <button
                              className="btn border-red btn__edit p_0"
                              onClick={() => setOpenCompanyDectivateModal(true)}
                            >
                              <DeactivateIcon />
                            </button>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="Cancel request"
                            placement="bottom"
                            arrow
                          >
                            <button
                              className="btn btn__white btn__edit p_0"
                              onClick={() =>
                                setOpenCompanyActivationModel(true)
                              }
                            >
                              <ActivateIcon />
                            </button>
                          </Tooltip>
                        )}
                        <NotificationButton
                          OpenNotificationModal={OpenNotificationModal}
                          setOpenNotificationModal={setOpenNotificationModal}
                        />
                      </div>
                    )}
                  </div>
                  {/* DETAIL PAGE STARTS */}
                  {OpenEmployeeList ? (
                    <EmployeeList setOpenEmployeeList={setOpenEmployeeList} />
                  ) : (
                    <div className="page__body">
                      <div className="details">
                        <section className="form__section">
                          <div className="flex flex--between">
                            <h4 className="form__section--title">
                              Company Information
                            </h4>
                            <div className="item">
                              <button
                                onClick={() => setOpenEmployeeList(true)}
                                className="btn btn__primary"
                              >
                                <UsersWhiteSvg /> Employees list
                              </button>
                            </div>
                          </div>
                          <div className="formRow">
                            <div className="formCol">
                              <div className="formField">
                                <div className="uploadField">
                                  <div className="uploadImage">
                                    <img
                                      src={
                                        data?.companyLogo
                                          ? data?.companyLogo
                                          : DefaultImg
                                      }
                                      alt="Company"
                                      width="140"
                                      height="140"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="fieldBox flexRow">
                            <div className="flexCol">
                              <label className="flexCol__label">
                                Company Name
                              </label>
                              <div className="flexCol__text">
                                {data?.companyName}
                              </div>
                            </div>
                            <div className="flexCol">
                              <label className="flexCol__label">
                                Company Website
                              </label>
                              <Link
                                className="flexCol__text text--primary"
                                to={data?.websiteLink ? data?.websiteLink : "#"}
                                target="_blank"
                              >
                                {data?.websiteLink ? data?.websiteLink : "--"}
                                {data?.websiteLink && <LinkIcon />}
                              </Link>
                            </div>
                          </div>
                          <div className="fieldBox flexRow">
                            <div className="flexCol">
                              <label className="flexCol__label">
                                Company Linkedin{" "}
                              </label>
                              <div
                                className="formField__group sm"
                                style={{ maxWidth: "475px" }}
                              >
                                <input
                                  type="text"
                                  ref={copyLinkedinText}
                                  className="formField__input"
                                  value={data?.linkedin ? data?.linkedin : ""}
                                  name="companyLinkedIn"
                                  placeholder="Company LinkedIn"
                                  readOnly
                                />
                                <span
                                  className={`formField__icon cursor--pointer ${
                                    data?.linkedin ? "" : "iconDisabled"
                                  }`}
                                  onClick={copyToClipboard}
                                >
                                  <CopyIcon />
                                </span>
                              </div>
                            </div>
                            <div className="flexCol">
                              <label className="flexCol__label">
                                Industry Type
                              </label>
                              <div className="flexCol__text">
                                {data?.industryType?.join(", ")}
                              </div>
                            </div>
                          </div>
                        </section>

                        <section className="details__section">
                          <h4 className="details__section--title">Address</h4>
                          <div className="fieldBox">
                            <label className="flexCol__label">Address</label>
                            <div className="flexCol__text">
                              {data?.address ? data?.address : "--"}
                            </div>
                          </div>
                          <div className="fieldBox flexRow">
                            <div className="flexCol">
                              <label className="flexCol__label">Country</label>
                              <div className="flexCol__text">
                                {data?.countryName ? data?.countryName : "--"}
                              </div>
                            </div>
                            <div className="flexCol">
                              <label className="flexCol__label">Zipcode</label>
                              <div className="flexCol__text">
                                {data?.zipcode ? data?.zipcode : "--"}
                              </div>
                            </div>
                          </div>
                          <div className="fieldBox flexRow">
                            <div className="flexCol">
                              <label className="flexCol__label">State</label>
                              <div className="flexCol__text">
                                {data?.stateName ? data?.stateName : "--"}
                              </div>
                            </div>
                            <div className="flexCol">
                              <label className="flexCol__label">City</label>
                              <div className="flexCol__text">
                                {data?.city ? data?.city : "--"}
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {loading || (cancelRequestResponse?.loading && <Loader />)}
      <CompanyProfileDeactivateModal
        openModal={openCompanyDectivateModal}
        closeModal={() => setOpenCompanyDectivateModal(false)}
        refreshCompanyProfileDetail={refreshCompanyProfileDetail}
      />
      {OpenCompanyActivationModel && (
        <ConfirmationModal
          closeModal={() => setOpenCompanyActivationModel(false)}
          onSubmit={handleCancelDeactiveReq}
          heading={"Cancel Request!"}
          confirmationMessage={
            "Are you sure want to cancel deactivation request?"
          }
        />
      )}
      {cancelRequestResponse?.meta?.status === true && (
        <SuccessModal
          closeModal={closeSuccessModal}
          sucessfullMsg={cancelRequestResponse?.meta?.msg}
          Heading={"Request Cancelled!"}
        />
      )}
      {cancelRequestResponse?.meta?.status === false && (
        <ErrorModal
          errorMessage={cancelRequestResponse?.meta?.msg}
          closeModal={closeSuccessModal}
          footerVisible={true}
        />
      )}
    </>
  );
}

export default CustomerCompanyProfile;
