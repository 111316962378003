import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { ADD_COMPANY_DETAILS_ON_SOCIAL_SIGNIN } from "@/rtk-apis/end-points";

const addCompanyOnSocialSignIn = createAsyncThunk(
  "addCompanyOnSocialSignIn",
  async (body) => {
    try {
      const response = await httpService.post(
        ADD_COMPANY_DETAILS_ON_SOCIAL_SIGNIN,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default addCompanyOnSocialSignIn;
