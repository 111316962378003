import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { MY_JOBS_LIST_CUSTOMER } from '@/rtk-apis/end-points';

const myJobsListCustomer = createAsyncThunk('myJobsListCustomer', async (body) => {
  try {
    const response = await httpService.post(MY_JOBS_LIST_CUSTOMER, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default myJobsListCustomer;
