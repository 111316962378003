import addCustomerNotes from "@/rtk-apis/customer/myJobs/notes/addCustomerNotes";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const addCustomerNotesSlice = createSlice({
  name: "addCustomerNotesSlice",
  initialState,
  reducers: {
    closeAddNoteModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCustomerNotes.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addCustomerNotes.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(addCustomerNotes.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default addCustomerNotesSlice.reducer;

export const { closeAddNoteModal } = addCustomerNotesSlice.actions;
