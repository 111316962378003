import { createSlice } from "@reduxjs/toolkit";
import scheduledInterviews from "@/rtk-apis/myJobs/scheduledInterviews";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const scheduledInterviewsSlice = createSlice({
  name: "scheduledInterviews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(scheduledInterviews.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(scheduledInterviews.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(scheduledInterviews.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default scheduledInterviewsSlice.reducer;
