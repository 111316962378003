import { createSlice } from '@reduxjs/toolkit';
import customerCandidatesInterviewData from '@/rtk-apis/customer/interviews/customerCandidateInterviewData';

const initialState = {
  data: {},
  meta: {},
  loading: false,
  error: ''
};

const customerCandidatesInterviewDataSlice = createSlice({
  name: 'customerCandidatesInterviewDataSlice',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(customerCandidatesInterviewData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(customerCandidatesInterviewData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(customerCandidatesInterviewData.rejected, (state, action) => {
        state.loading = false;
      });
  }

});

export default customerCandidatesInterviewDataSlice.reducer;

export const { closeRtkModal } = customerCandidatesInterviewDataSlice.actions;
