import { createSlice } from "@reduxjs/toolkit";
import feedbackQuestions from "@/rtk-apis/myJobs/feedbackQuestions";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const feedbackQuestionsSlice = createSlice({
  name: "feedbackQuestions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(feedbackQuestions.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(feedbackQuestions.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.data;
        state.meta = payload.meta;
      })
      .addCase(feedbackQuestions.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default feedbackQuestionsSlice.reducer;
