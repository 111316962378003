import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CANDIDATE_APPROVE_REJECT } from '@/rtk-apis/end-points';

const candidateApproveReject = createAsyncThunk('candidateApproveReject', async (body) => {
  try {
    const response = await httpService.put(CANDIDATE_APPROVE_REJECT, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default candidateApproveReject;
