import { createSlice } from "@reduxjs/toolkit";
import customerActivateEmployee from "@/rtk-apis/customer/companyProfile/customerActivateEmploee";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const customerActivateEmployeeSlice = createSlice({
  name: "customerActivateEmployeeSlice",
  initialState,
  reducers: {
    closeActivateEmployeeModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customerActivateEmployee.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(customerActivateEmployee.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(customerActivateEmployee.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default customerActivateEmployeeSlice.reducer;

export const {
  closeActivateEmployeeModal,
} = customerActivateEmployeeSlice.actions;
