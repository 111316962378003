import React, { useEffect, useRef } from 'react';

const ErrorModal = ({ closeModal, errorMessage, Heading, footerVisible }) => {
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener('click', checkIfClickedOutside);
    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, [closeModal]);

  return (
    <div className={'modal modalSuccessfull modal--open'}>
      <div className="modal__dialog">
        <div className="modal__content">
          <div className="modal__header">
            <div className="modal__header--logo">
              <svg
                width="100"
                height="100"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M50 33.333V49.9997M50 66.6663H50.0417M91.6667 49.9997C91.6667 73.0115 73.0119 91.6663 50 91.6663C26.9882 91.6663 8.33337 73.0115 8.33337 49.9997C8.33337 26.9878 26.9882 8.33301 50 8.33301C73.0119 8.33301 91.6667 26.9878 91.6667 49.9997Z"
                  stroke="#D92D20"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h2 className="modal__header--title">{Heading || ''}</h2>
          </div>
          <div className="modal__body">
            <h4 className="promptMessage">
              {errorMessage || ''}
            </h4>
          </div>
          <div className="modal__footer">
            <div className="btn__group">
              {footerVisible && (
                <button className="btn btn__white" onClick={() => closeModal()}>
                  Got it
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal__backdrop"></div>
    </div>
  );
};

export default ErrorModal;
