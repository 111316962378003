import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CUSTOMER_UPDATE_MY_PROFILE } from '@/rtk-apis/end-points';

const updateMyProfileDetails = createAsyncThunk('updateMyProfileDetails', async (body) => {
  try {
    const response = await httpService.put(CUSTOMER_UPDATE_MY_PROFILE, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default updateMyProfileDetails;
