import React, { useEffect } from "react";
import CloseIcon from "@/assets/svgs/CloseIcon";
import ArrowDown from "@/assets/svgs/ArrowDown";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { useSelector, useDispatch } from "react-redux";
import staticData from "@/rtk-apis/staticData";

function MyJobsFilter({
  experience,
  experienceChange,
  qualification,
  closeFilter,
  validityStatusChange,
  educationChange,
  apply,
  clearFilters,
  validityStatus,
}) {
  const dispatch = useDispatch();

  const filterExperience = (event, newValue) => {
    experienceChange(newValue);
  };

  const { data } = useSelector((globalState) => {
    return globalState.static;
  });

  useEffect(() => {
    dispatch(staticData());
  }, []);

  return (
    <>
      <div className="filter__head">
        <h2 className="listCol__head--title">Filter</h2>
        <button
          onClick={() => closeFilter()}
          className="btn btn__transparent border_0 p_0"
        >
          <CloseIcon />
        </button>
      </div>

      <div className="filterContainer">
        <ul className="listCol__list filter__list">
          <li>
            <div className="formRow">
              <div className="formCol">
                <div className="formField">
                  <label className="formField__label">Status</label>
                  <FormControl
                    fullWidth
                    size="small"
                    className="formField__select"
                  >
                    <Select
                      onChange={(e) => validityStatusChange(e.target.value)}
                      IconComponent={() => <ArrowDown />}
                      displayEmpty
                      value={validityStatus}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span style={{ opacity: "0.8" }}>Select</span>;
                        }
                        return selected === "Reopened" ? "Reopen" : selected;
                      }}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Open">Open</MenuItem>
                      <MenuItem value="Closed">Closed</MenuItem>
                      <MenuItem value="Paused">Paused</MenuItem>
                      <MenuItem value="Reopened">Reopen</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="formCol">
                <div className="formField">
                  <label className="formField__label">Education</label>
                  <Autocomplete
                    id="tags-filled"
                    multiple
                    options={
                      data?.qualification?.length ? data?.qualification : []
                    }
                    value={qualification}
                    name="education"
                    onChange={(event, newValue) => {
                      educationChange(newValue);
                    }}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        placeholder="Select"
                        className="autoCompleteInput"
                        onChange={(event) =>
                          educationChange(event?.target?.value)
                        }
                      />
                    )}
                  />
                </div>
              </div>

              <div className="formCol">
                <div className="formField">
                  <label className="formField__label flex flex--between">
                    Experience (years)
                    <span>{experience[0] + "-" + experience[1]} years</span>
                  </label>
                  <Box fullWidth sx={{ padding: "0 4px" }}>
                    <Slider
                      value={experience}
                      onChange={filterExperience}
                      valueLabelDisplay="auto"
                      min={0}
                      max={99}
                    />
                  </Box>
                  <label
                    className="formField__label flex flex--between"
                    style={{ display: "flex" }}
                  >
                    0<span>99</span>
                  </label>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div className="filter__footer">
          <div className="btn__group flex--between">
            <button onClick={clearFilters} className="btn btn__transparent p_0">
              Clear
            </button>
            <button onClick={apply} className="btn btn__primary">
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyJobsFilter;
