import { createSlice } from "@reduxjs/toolkit";
import checkSchedulePage from "@/rtk-apis/interviews/checkSchedulePage";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const checkSchedulePageSlice = createSlice({
  name: "checkSchedulePage",
  initialState,
  reducers: {
    closertkmodal(state) {
      state.data = {};
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkSchedulePage.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(checkSchedulePage.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(checkSchedulePage.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default checkSchedulePageSlice.reducer;

export const { closertkmodal } = checkSchedulePageSlice.actions;
