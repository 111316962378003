import React from "react";

const UniversitySvg = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1481 4764 c-1368 -274 -1311 -260 -1311 -325 0 -15 10 -39 22 -53
13 -13 172 -122 353 -241 182 -119 329 -222 328 -228 -1 -7 -56 -296 -124
-642 -82 -424 -120 -640 -115 -658 33 -134 396 -290 1051 -453 797 -198 1707
-308 2015 -244 85 18 135 52 153 102 8 24 68 320 134 658 l118 615 334 67
c184 38 335 67 337 65 1 -1 -33 -180 -75 -397 l-78 -395 -64 -42 c-35 -23 -72
-47 -82 -55 -19 -13 -187 -521 -187 -564 0 -12 9 -33 20 -47 18 -22 42 -29
239 -67 132 -26 229 -40 245 -36 45 11 54 51 76 327 11 141 20 265 20 277 0
11 -25 55 -55 97 l-55 77 92 474 c68 349 90 479 82 492 -12 21 -47 44 -979
658 -1131 744 -1189 781 -1210 787 -11 3 -589 -109 -1284 -249z m1875 -322
c340 -224 775 -510 967 -636 191 -126 346 -230 345 -232 -2 -1 -513 -104
-1136 -229 l-1133 -226 -97 62 c-53 35 -446 293 -872 574 -426 281 -820 540
-875 575 -54 36 -93 66 -85 68 91 20 2239 450 2251 451 8 1 294 -182 635 -407z
m-1667 -1050 c354 -233 654 -427 667 -432 17 -7 229 32 795 145 425 85 775
152 778 150 4 -5 -188 -1014 -195 -1023 -1 -2 -34 13 -73 32 -538 271 -2187
595 -2736 537 -54 -5 -101 -9 -102 -7 -3 2 138 738 188 984 7 35 14 50 22 45
7 -4 302 -198 656 -431z m-434 -752 c762 -51 1888 -300 2328 -515 42 -21 77
-41 77 -45 0 -15 -172 -23 -325 -16 -635 28 -1635 224 -2212 432 -151 54 -298
123 -286 135 19 18 214 22 418 9z m3449 -207 l24 -38 -16 -197 c-14 -175 -18
-198 -33 -198 -15 0 -119 20 -185 35 -21 5 -20 10 40 199 59 187 62 196 96
215 19 11 38 21 42 21 4 0 19 -17 32 -37z"
        />
        <path
          d="M3710 1740 c-327 -38 -800 -91 -1050 -119 -250 -28 -511 -58 -580
-66 -69 -8 -429 -48 -800 -89 -812 -90 -798 -88 -857 -117 -165 -79 -272 -321
-260 -584 17 -367 219 -652 465 -658 53 -2 942 95 1737 188 72 9 587 67 1146
129 1120 126 1064 116 1154 200 57 53 109 140 142 241 25 74 27 92 27 245 0
139 -4 178 -22 245 -53 189 -170 353 -298 416 -51 25 -80 33 -137 35 -43 1
-314 -25 -667 -66z m747 -120 c246 -141 306 -695 95 -881 -24 -21 -58 -41 -76
-44 -17 -3 -284 -34 -594 -69 -416 -46 -562 -59 -558 -50 32 84 38 343 12 492
-19 104 -69 282 -109 385 -18 45 -22 67 -14 68 7 0 257 29 557 63 300 35 565
64 590 65 33 1 59 -7 97 -29z m-1389 -205 c81 -194 124 -396 124 -580 0 -121
-20 -246 -45 -278 -9 -12 -89 -24 -342 -52 -181 -20 -349 -39 -371 -42 l-42
-5 16 63 c24 100 21 336 -6 475 -22 109 -70 271 -110 370 l-20 51 356 40 c196
23 368 40 382 40 21 -2 29 -14 58 -82z m-939 -77 c83 -183 134 -409 135 -598
0 -128 -17 -246 -43 -288 -5 -9 -205 -35 -635 -83 -345 -39 -629 -69 -632 -67
-2 3 7 31 21 63 45 100 59 178 58 325 0 113 -4 151 -25 231 -29 106 -78 211
-133 283 -20 26 -35 49 -33 50 2 2 257 31 568 66 593 66 683 76 688 79 2 0 16
-27 31 -61z m-1494 -132 c84 -35 173 -167 215 -318 11 -40 20 -77 20 -83 0 -6
-26 -26 -57 -44 -53 -32 -58 -33 -67 -16 -108 183 -229 248 -367 196 l-50 -20
7 32 c22 104 79 201 143 244 40 27 104 30 156 9z m-125 -423 c40 -28 59 -53
101 -138 62 -127 91 -139 191 -79 32 20 62 34 65 30 10 -9 -15 -116 -40 -171
-84 -182 -231 -209 -360 -65 -51 57 -63 90 -77 210 -20 178 33 273 120 213z"
        />
      </g>
    </svg>
  );
};
export default UniversitySvg;
