import React from "react";
import moment from "moment";
import CloseIcon from "@/assets/svgs/CloseIcon";
import ArrowDown from "@/assets/svgs/ArrowDown";
import DatePickerIcon from "@/assets/svgs/DatePickerIcon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function InterviewsFilter({
  closeFilter,
  apply,
  clearFilters,
  startDateChange,
  endDateChange,
  statusChange,
  status,
}) {
  const handleSelectChange = (event) => {
    statusChange(event.target.value);
  };

  const startDate = (val) => {
    let originalDateString = JSON.stringify(val);
    let date = new Date(originalDateString.split("T")[0]);
    let formatedDate = moment(date, "YYYY-MM-DD")
      .add(1,"days")
      .format("YYYY-MM-DD");
    startDateChange(formatedDate);
  };

  const endDate = (val) => {
    let originalDateString = JSON.stringify(val);
    let date = new Date(originalDateString.split("T")[0]);
    let formatedDate = moment(date, "YYYY-MM-DD")
      .add(1,"days")
      .format("YYYY-MM-DD");
    endDateChange(formatedDate);
  };
  return (
    <>
      <div className="filter__head">
        <h2 className="listCol__head--title">Filter</h2>
        <button
          onClick={() => closeFilter()}
          className="btn btn__transparent border_0 p_0"
        >
          <CloseIcon />
        </button>
      </div>

      <div className="filterContainer">
        <ul className="listCol__list filter__list">
          <li>
            <div className="formRow formRow__2">
              <div className="formCol">
                <div className="formField">
                  <label className="formField__label">Start Date</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={(val) => startDate(val)}
                      className="formField__datepicker"
                      slots={{ openPickerIcon: DatePickerIcon }}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <div className="formCol">
                <div className="formField">
                  <label className="formField__label">End Date</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={(val) => endDate(val)}
                      className="formField__datepicker"
                      slots={{ openPickerIcon: DatePickerIcon }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div className="formRow">
              <div className="formCol">
                <div className="formField">
                  <label className="formField__label">Status</label>
                  <div className="formField">
                    <FormControl
                      fullWidth
                      size="small"
                      className="formField__select"
                    >
                      <Select
                        onChange={handleSelectChange}
                        IconComponent={() => <ArrowDown />}
                        displayEmpty
                        value={status}
                        renderValue={(selected) => {
                          if (!selected) {
                            return (
                              <span style={{ opacity: "0.8" }}>Select</span>
                            );
                          }

                          return selected;
                        }}
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="Upcoming">Upcoming</MenuItem>
                        <MenuItem value="Past">Past</MenuItem>
                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div className="filter__footer">
          <div className="btn__group flex--between">
            <button onClick={clearFilters} className="btn btn__transparent p_0">
              Clear
            </button>
            <button onClick={apply} className="btn btn__primary">
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default InterviewsFilter;
