import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CUSTOMER_EMPLOYEE_DETAIL } from '@/rtk-apis/end-points';

const customerEmployeeDetail = createAsyncThunk('customerEmployeeDetail', async (employeeId) => {
  try {
    const response = await httpService.get(`${CUSTOMER_EMPLOYEE_DETAIL}${employeeId}`, {});
    return response;
  } catch (error) {
    return error;
  }
});

export default customerEmployeeDetail;
