import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { FORGOT_PASSWORD } from '@/rtk-apis/end-points';

const forgotPassword = createAsyncThunk('forgotPassword', async (body) => {
  try {
    const response = await httpService.post(FORGOT_PASSWORD, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default forgotPassword;
