import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { CUSTOMER_DEACTIVATE_ACCOUNT } from "@/rtk-apis/end-points";

const CompanyDeactivateAccount = createAsyncThunk(
  "CompanyDeactivateAccount",
  async (body) => {
    try {
      const response = await httpService.put(CUSTOMER_DEACTIVATE_ACCOUNT, body);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default CompanyDeactivateAccount;
