import React from "react";

function PhoneIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6665 11.2797V13.2797C14.6672 13.4654 14.6292 13.6492 14.5548 13.8193C14.4804 13.9894 14.3713 14.1421 14.2345 14.2676C14.0977 14.3932 13.9362 14.4887 13.7603 14.5482C13.5844 14.6077 13.398 14.6298 13.2131 14.6131C11.1617 14.3902 9.19113 13.6892 7.45979 12.5664C5.84901 11.5428 4.48335 10.1772 3.45979 8.56641C2.33311 6.82721 1.63195 4.84707 1.41313 2.78641C1.39647 2.60205 1.41838 2.41625 1.47746 2.24082C1.53654 2.0654 1.63151 1.9042 1.7563 1.76749C1.8811 1.63077 2.033 1.52155 2.20232 1.44675C2.37164 1.37196 2.55469 1.33325 2.73979 1.33307H4.73979C5.06333 1.32989 5.37699 1.44446 5.6223 1.65543C5.86761 1.8664 6.02784 2.15937 6.07313 2.47974C6.15754 3.11978 6.31409 3.74822 6.53979 4.35307C6.62949 4.59169 6.6489 4.85102 6.59573 5.10033C6.54256 5.34964 6.41903 5.57848 6.23979 5.75974L5.39313 6.60641C6.34216 8.27544 7.7241 9.65737 9.39313 10.6064L10.2398 9.75974C10.4211 9.5805 10.6499 9.45697 10.8992 9.4038C11.1485 9.35063 11.4078 9.37004 11.6465 9.45974C12.2513 9.68544 12.8797 9.84199 13.5198 9.92641C13.8436 9.97209 14.1394 10.1352 14.3508 10.3847C14.5622 10.6343 14.6746 10.9528 14.6665 11.2797Z" stroke="#384250" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default PhoneIcon;
