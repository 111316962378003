import React, { useState, useEffect } from "react";
import CloseIcon from "@/assets/svgs/CloseIcon";
import ArrowDown from "@/assets/svgs/ArrowDown";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import staticData from "@/rtk-apis/staticData";
import { GetUserDetails } from "@/utils/helper";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

function CustomerMyJobsFilter({
  closeFilter,
  handleStatusChange,
  status,
  handleHigherEduChange,
  qualificationFilterValue,
  experienceFilterValue,
  handleExperienceSliderChange,
  ApplyFilter,
  clearFilter,
  handleTagChange,
  tagFilterValue,
  showFilter,
}) {
  const dispatch = useDispatch();
  const userDetails = GetUserDetails();

  const ExperienceSliderChange = (event, newValue) => {
    handleExperienceSliderChange(newValue);
  };

  useEffect(() => {
    dispatch(staticData(`companyId=${userDetails?.customer?.companyId}`));
  }, [showFilter]);

  const { data } = useSelector((globalState) => {
    return globalState.static;
  });

  return (
    <>
      <div className="filter__head">
        <h2 className="listCol__head--title">Filter</h2>
        <button
          className="btn btn__transparent border_0 p_0"
          onClick={closeFilter}
        >
          <CloseIcon />
        </button>
      </div>
      <div className="filterContainer">
        <ul className="listCol__list filter__list">
          <li>
            <div className="formRow">
              <div className="formCol">
                <div className="formField">
                  <label className="formField__label">Status</label>
                  <div className="formField">
                    <FormControl
                      fullWidth
                      size="small"
                      className="formField__select"
                    >
                      <Select
                        onChange={(e) => handleStatusChange(e?.target?.value)}
                        IconComponent={() => <ArrowDown />}
                        displayEmpty
                        value={status}
                        renderValue={(selected) => {
                          if (!selected) {
                            return (
                              <span style={{ opacity: "0.8" }}>Select</span>
                            );
                          }

                          return selected === "Reopened" ? "Reopen" : selected;
                        }}
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="Open">Open</MenuItem>
                        <MenuItem value="Closed">Closed</MenuItem>
                        <MenuItem value="Paused">Paused</MenuItem>
                        <MenuItem value="Reopened">Reopen</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className="formCol">
                <div className="formField">
                  <label className="formField__label">
                    Required Qualification
                  </label>
                  <div className="formField">
                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={data?.qualification ? data?.qualification : []}
                      defaultValue={qualificationFilterValue}
                      value={qualificationFilterValue}
                      name="qualification"
                      onChange={(event, newValue) => {
                        handleHigherEduChange(newValue);
                      }}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder="Select"
                          className="autoCompleteInput"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="formCol">
                <div className="formField">
                  <label className="formField__label">Tags</label>
                  <div className="formField">
                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={data?.tag ? data?.tag : []}
                      defaultValue={tagFilterValue}
                      value={tagFilterValue}
                      name="tag"
                      onChange={(event, newValue) => {
                        handleTagChange(newValue);
                      }}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder="Select"
                          className="autoCompleteInput"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="formCol">
                <div className="formField">
                  <label className="formField__label flex flex--between">
                    Experience (years)
                    <span>
                      {experienceFilterValue[0] +
                        "-" +
                        experienceFilterValue[1]}{" "}
                      years
                    </span>
                  </label>
                  <Box sx={{ padding: "0 4px" }}>
                    <Slider
                      value={experienceFilterValue}
                      onChange={ExperienceSliderChange}
                      valueLabelDisplay="auto"
                      min={0}
                      max={99}
                    />
                  </Box>
                  <label
                    className="formField__label flex flex--between"
                    style={{ display: "flex" }}
                  >
                    0<span>99</span>
                  </label>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div className="filter__footer">
          <div className="btn__group flex--between">
            <button
              className="btn btn__transparent p_0"
              onClick={() => clearFilter()}
            >
              Clear
            </button>
            <button className="btn btn__primary" onClick={() => ApplyFilter()}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerMyJobsFilter;
