import { createSlice } from '@reduxjs/toolkit';
import candidateRecordingList from '@/rtk-apis/customer/myJobs/candidates/candidateRecordingList';

const initialState = {
  message: '',
  data: {},
  meta: {},
  token: '',
  loading: false,
  error: ''
};

const candidateRecordingListSlice = createSlice({
  name: 'candidateRecordingList',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(candidateRecordingList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(candidateRecordingList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(candidateRecordingList.rejected, (state, action) => {
        state.loading = false;
      });
  }

});

export default candidateRecordingListSlice.reducer;

export const { closeRtkModal } = candidateRecordingListSlice.actions;
