import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import getFeedbackQuestionslist from "@/rtk-apis/customer/myJobs/shortlistedCandidates/feedbackQuestionsList";

function FeedbackPage({ heading, onPageSubmit, onBackClick, btnTitle }) {
  const dispatch = useDispatch();
  const [feedbackData, setFeedbackData] = useState([]);
  const [btnDisable, setBtnDisable] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});

  const { data, loading } = useSelector((globalState) => {
    return globalState.getFeedbackQuesList;
  });

  useEffect(() => {
    getFeedbackQuestions();
  }, []);

  useEffect(() => {
    if (data?.length) {
      var newFeedbackArr = data.map(function (el) {
        var o = Object.assign({}, el);
        o.answer = "";
        return o;
      });
      setFeedbackData(newFeedbackArr);
    }
  }, [data]);

  const getFeedbackQuestions = async () => {
    dispatch(getFeedbackQuestionslist());
  };

  const changeTextArea = (e, index) => {
    let quesArray = feedbackData;
    quesArray[index]["answer"] = e?.target?.value;
    setFeedbackData(quesArray);
    validateForm(quesArray);
  };

  const validateForm = (formData) => {
    const errors = {};
    formData.forEach((item, index) => {
      if (item.answer.trim() === "" && item.question !== "Custom note") {
        errors[index] = "Answer is required.";
      } else {
        delete errors[index];
      }
    });
    setValidationErrors(errors);
    setBtnDisable(Object.keys(errors).length > 0);
  };

  return (
    <>
      <div className="dataCol__head feedback--head">
        <h4 className="details__section--title">{heading}</h4>
        <div className="formField__label">
          We would like to hear from you, please share your experience or
          feedback with us. We’ll be very happy improve ourself
        </div>
      </div>

      <div className="p_2">
        <div className="formRow formRow__2">
          {data?.length &&
            data?.map((ques, index) => (
              <div className="formCol">
                <div
                  className={`formField ${
                    validationErrors[index] ? "fieldError" : ""
                  }`}
                >
                  <label className="formField__label">{ques?.question}</label>
                  <textarea
                    className="formField__textarea"
                    rows={3}
                    onChange={(e) => changeTextArea(e, index)}
                  ></textarea>
                  {validationErrors[index] && (
                    <span className="formField__error">
                      {validationErrors[index]}
                    </span>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="btn__group flex--start">
          <button className="btn btn__white" onClick={() => onBackClick()}>
            Back
          </button>
          <button
            className="btn btn__primary"
            disabled={btnDisable}
            onClick={() => {
              onPageSubmit(feedbackData);
              setBtnDisable(true);
            }}
          >
            {btnTitle}
          </button>
        </div>
      </div>
    </>
  );
}

export default FeedbackPage;
