import React from "react";

function CloseIconCircle() {
  return (
    <svg
      width="4"
      height="5"
      viewBox="0 0 4 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.258606 1.20508L3.77517 4.72165M3.77517 1.20508L0.258606 4.72165"
        stroke="black"
        strokeWidth="0.439571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CloseIconCircle;
