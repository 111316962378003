import { createSlice } from '@reduxjs/toolkit';
import recruiterDetails from '@/rtk-apis/recruiterProfile/recruiterDetails';

const initialState = {
  message: '',
  data: {},
  meta: {},
  loading: false,
  error: ''
};

const recruiterDetailsSlice = createSlice({
  name: 'recruiterDetails',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(recruiterDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(recruiterDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(recruiterDetails.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  }

});

export default recruiterDetailsSlice.reducer;

export const { closeRtkModal } = recruiterDetailsSlice.actions;
