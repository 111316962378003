import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { JOB_ACCEPT_DECLINE } from '@/rtk-apis/end-points';

const jobAcceptDecline = createAsyncThunk('jobAcceptDecline', async (body) => {
  try {
    const response = await httpService.put(JOB_ACCEPT_DECLINE, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default jobAcceptDecline;
