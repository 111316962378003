import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { CANCEL_CUSTOMER_DEACTIVATE_ACCOUNT } from "@/rtk-apis/end-points";

const cancelCompanyDeactivationRequest = createAsyncThunk(
  "cancelCompanyDeactivationRequest",
  async (body) => {
    try {
      const response = await httpService.put(
        `${CANCEL_CUSTOMER_DEACTIVATE_ACCOUNT}${body?.companyId}/${body?.employeeId}/${body?.from}`,
        {}
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default cancelCompanyDeactivationRequest;
