import React, { useState } from "react";
import CandidatesListing from "@/pages/MyJobs/ApprovedCandidates/CandidatesListing";
import CandidatesFeedback from "@/pages/MyJobs/ApprovedCandidates/CandidatesFeedback";

function Candidates({ selectedJob }) {
  const [feedback, setFeedback] = useState({});
  const [showFeedback, setShowFeedback] = useState(false);

  const clickedFeedback = (row) => {
    setShowFeedback(true);
    setFeedback(row?.feedback);
  };

  return (
    <>
      {!showFeedback && (
        <CandidatesListing
          selectedJob={selectedJob}
          openFeedback={(row) => clickedFeedback(row)}
        />
      )}
      {showFeedback && (
        <CandidatesFeedback
          goBack={() => setShowFeedback(false)}
          feedback={feedback}
        />
      )}
    </>
  );
}

export default Candidates;
