import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { BILLING_RECRUITER_JOBLIST } from "@/rtk-apis/end-points";

const recruiterJobList = createAsyncThunk("recruiterJobList", async (body) => {
  try {
    const response = await httpService.post(BILLING_RECRUITER_JOBLIST, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default recruiterJobList;
