import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { DELETE_JOB_TAGS } from "@/rtk-apis/end-points";

const removeJobTags = createAsyncThunk("removeJobTags", async (body) => {
  try {
    const response = await httpService.put(DELETE_JOB_TAGS, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default removeJobTags;
