import React, { useState, useEffect, useRef } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SideBar from "@/components/layout/SideBar";
import PlusIcon from "@/assets/svgs/PlusIcon";
import SearchIcon from "@/assets/svgs/SearchIcon";
import ArrowDownTag from "@/assets/svgs/ArrowDownTag";
import EditTagIcon from "@/assets/svgs/EditTagIcon";
import DisableTagIcon from "@/assets/svgs/DisableTagIcon";
import EnableTagIcon from "@/assets/svgs/EnableTagIcon";
import Tooltip from "@mui/material/Tooltip";
import ColorPickersDropdown from "@/components/colorPicker/ColorPickersDropdown";
import { useDispatch, useSelector } from "react-redux";
import { GetUserDetails, firstLetterCapital } from "@/utils/helper";
import getCustomerAllTags from "@/rtk-apis/customer/settings/customerTags/getCustomerAllTags";
import CustomPagination from "@/elements/pagination/customPagination";
import GoTOPage from "@/elements/gotopage/gotopage";
import Loader from "@/elements/Loader";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowDown from "@/assets/svgs/ArrowDown";
import customerEmployeeList from "@/rtk-apis/customer/myProfile/customerEmployeeList";
import MultiSelectEmployeeFilter from "@/customer-pages/Tags/multiselectEmployeeFilter";
import AddEditCustomerTagsModal from "@/components/modals/AddEditCustomerTagsModal";
import activateDeactivateCustomerTags from "@/rtk-apis/customer/settings/customerTags/activateDeactivateCustomerTags";
import { closeActivateDeactivateTagsModal } from "@/store/slices/customer/settings/customerTags/activateDeactivateCustomerTagsSlice";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import ErrorModal from "@/components/modals/error-modal";
import { SuccessModal } from "@/components/modals/SuccessModal";
import NotificationButton from "../../components/elements/notificationButton";

const CustomerTags = () => {
  const dispatch = useDispatch();
  const searchRef = useRef();
  const user = GetUserDetails();
  const [page, setPage] = useState(1);
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [openAddEditTagsModal, setOpenAddEditTagsModal] = useState(false);
  const [openColorPickerIndex, setOpenColorPickerIndex] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("all");
  const [employeeFilterValue, setEmployeeFilterValue] = useState([]);
  const [SelectedTagData, setSelectedTagData] = useState({});
  const [filterApplied, setfilterApplied] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [OpenNotificationModal, setOpenNotificationModal] = useState(false);

  const { data, loading } = useSelector((globalState) => {
    return globalState.getCustomerAllTagsRes;
  });

  const EmployeeList = useSelector((globalState) => {
    return globalState.getCompanyEmployeeList;
  });

  const ActivateDeactivateTagRes = useSelector((globalState) => {
    return globalState.activateDeactivateCustomerTagsRes;
  });

  useEffect(() => {
    fetchCustomerAllTags();
  }, [page, searchText, status, filterApplied]);

  useEffect(() => {
    getCompanyEmployeeList();
  }, []);

  const fetchCustomerAllTags = async () => {
    let body = {
      limit: 20,
      page: page,
      tagName: searchText,
      status: status,
      companyArray: [user?.customer?.company?.id],
      employeeArray: employeeFilterValue,
    };
    dispatch(getCustomerAllTags(body));
  };

  const clickToOpenColorPicker = (index) => {
    if (index === openColorPickerIndex) {
      setOpenColorPicker(!openColorPicker);
    } else {
      setOpenColorPicker(true);
    }
    setOpenColorPickerIndex(index);
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  const getCompanyEmployeeList = () => {
    let body = {
      companyId: user?.customer?.companyId,
      employeeId: user?.customer?.employeeId,
    };
    dispatch(customerEmployeeList(body));
  };

  const handleApplyFilter = (options) => {
    setfilterApplied(!filterApplied);
    setEmployeeFilterValue(options);
  };
  const handleClearFilter = () => {
    setEmployeeFilterValue([]);
    setfilterApplied(!filterApplied);
  };

  const refreshPage = () => {
    fetchCustomerAllTags();
  };

  const editCustomerTags = (data) => {
    setSelectedTagData(data);
    setOpenAddEditTagsModal(true);
  };

  const activateDeactivateTag = (data) => {
    setSelectedTagData(data);
    setOpenConfirmationModal(true);
  };

  const handleActivateDeactivateTag = () => {
    const status = SelectedTagData?.activate ? "deactivate" : "activate";
    let body = {
      tagId: SelectedTagData?.id,
      status: status,
      employeeId: user?.customer?.employeeId,
    };
    dispatch(activateDeactivateCustomerTags(body));
  };
  const closeSuccessModal = () => {
    dispatch(closeActivateDeactivateTagsModal());
    setOpenConfirmationModal(false);
    refreshPage();
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid layoutGrid--full">
          <div className="dataCol">
            <div className="page page__full">
              <div className="page__head">
                <h2 className="page__head--title">Tags</h2>
                <NotificationButton
                  OpenNotificationModal={OpenNotificationModal}
                  setOpenNotificationModal={setOpenNotificationModal}
                />
              </div>
              <div className="page__body bg--white">
                <div className="details">
                  <div className="listCol__head--actions w_100">
                    <div className="formRow formRow__3 m_0 w_100 formRow--flexEnd">
                      {/* SEARCH */}
                      <div className="formCol">
                        <div className="formField">
                          <div className="formField__group sm">
                            <span className="formField__icon">
                              <SearchIcon />
                            </span>
                            <input
                              type="text"
                              ref={searchRef}
                              className="formField__input"
                              placeholder="Search a Tag"
                              onChange={(e) => setSearchText(e?.target?.value)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* STATUS */}
                      <div className="formCol">
                        <div className="formField">
                          <FormControl
                            fullWidth
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              onChange={(e) => setStatus(e?.target?.value)}
                              IconComponent={() => <ArrowDown />}
                              name="Status"
                              value={status}
                              displayEmpty
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ opacity: "0.8" }}>
                                      Select Status
                                    </span>
                                  );
                                }

                                return selected === "all"
                                  ? "All tags"
                                  : selected === "activate"
                                  ? " Active tags"
                                  : " Deactivated tags";
                              }}
                            >
                              <MenuItem key={"all"} value={"all"}>
                                All tags
                              </MenuItem>
                              <MenuItem key={"activate"} value={"activate"}>
                                Active tags
                              </MenuItem>
                              <MenuItem key={"deactivate"} value={"deactivate"}>
                                Deactivated tags
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {/* FILTER */}
                      <div className="formCol">
                        <button className="btn btn__white p_0">
                          <div className="sortingDiv tagFilter">
                            <MultiSelectEmployeeFilter
                              labels={EmployeeList?.data?.rows}
                              applyFilter={(options) =>
                                handleApplyFilter(options)
                              }
                              clearFilter={handleClearFilter}
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                    <button className="btn btn__white btn__white--hoverNone w_fitContent">
                      Total Tags: <strong>{data?.count}</strong>
                    </button>
                    <button
                      className="btn btn__white w_fitContent"
                      onClick={() => setOpenAddEditTagsModal(true)}
                    >
                      <PlusIcon /> Add Tag
                    </button>
                  </div>
                  <div className="tabContainer">
                    <div className="tabContainer__item">
                      <TableContainer className="tableContainer">
                        <Table className="table">
                          <TableHead>
                            <TableRow>
                              <TableCell>S.</TableCell>
                              <TableCell>Tag Color</TableCell>
                              <TableCell>Tag Name</TableCell>
                              <TableCell>Created By</TableCell>
                              <TableCell>Role</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          {data?.rows?.length > 0 ? (
                            <TableBody>
                              {data?.rows?.map((item, index) => {
                                return (
                                  <TableRow
                                    key={`tagRow${index}`}
                                    className={`${
                                      !item?.activate ? "tagError" : ""
                                    }`}
                                  >
                                    <TableCell>
                                      {" "}
                                      {page > 1
                                        ? (page - 1) * 20 + (index + 1)
                                        : index + 1}
                                    </TableCell>
                                    <TableCell>
                                      <div
                                        className="tagDropdown"
                                        onClick={() =>
                                          clickToOpenColorPicker(index)
                                        }
                                      >
                                        <span
                                          className="tagDropdown__colorFill"
                                          style={{
                                            backgroundColor: item?.colorCode,
                                          }}
                                        ></span>
                                        <ArrowDownTag />
                                      </div>
                                      {openColorPicker &&
                                        openColorPickerIndex === index && (
                                          <ColorPickersDropdown
                                            open={openColorPicker}
                                            handleColorChange={() => {}}
                                            close={() =>
                                              setOpenColorPicker(false)
                                            }
                                            // onSubmit={updateTagColor}
                                            // tagData={item}
                                          />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                      <button
                                        className="btnTag"
                                        style={{
                                          backgroundColor: item?.colorCode,
                                        }}
                                      >
                                        {item?.tagName}
                                      </button>
                                    </TableCell>
                                    <TableCell>{`${firstLetterCapital(
                                      item?.employee?.firstName
                                    )} ${firstLetterCapital(
                                      item?.employee?.lastName
                                    )}`}</TableCell>
                                    <TableCell>
                                      {item?.employee?.role}
                                    </TableCell>
                                    <TableCell>
                                      <div className="actions flex--end">
                                        <Tooltip
                                          title="Edit"
                                          arrow
                                          placement="top"
                                        >
                                          <button
                                            className="btn p_0 border_0"
                                            onClick={() =>
                                              editCustomerTags(item)
                                            }
                                          >
                                            <EditTagIcon />
                                          </button>
                                        </Tooltip>
                                        {item?.activate ? (
                                          <Tooltip
                                            title="Deactivate"
                                            arrow
                                            placement="top"
                                          >
                                            <span
                                              onClick={() =>
                                                activateDeactivateTag(item)
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              <DisableTagIcon />
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            title="Activate"
                                            arrow
                                            placement="top"
                                          >
                                            <span
                                              onClick={() =>
                                                activateDeactivateTag(item)
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              <EnableTagIcon />
                                            </span>
                                          </Tooltip>
                                        )}
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          ) : (
                            <div className="no-data floating">
                              No Results Found!
                            </div>
                          )}
                        </Table>
                      </TableContainer>
                      <div className="pagination">
                        <div className="pagination__result">
                          {`${data?.count > page * 20 ? 20 : data?.count} of ${
                            data?.count
                          } results`}
                        </div>
                        {data?.count ? (
                          <CustomPagination
                            page={page}
                            totalPageCount={Math.ceil(data?.count / 20)}
                            changePage={(event, value) =>
                              changePage(event, value)
                            }
                          />
                        ) : (
                          ""
                        )}
                        {data?.count ? (
                          <div className="pagination__actions">
                            <GoTOPage changePage={changePage} />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      <AddEditCustomerTagsModal
        openModal={openAddEditTagsModal}
        closeModal={() => {
          setOpenAddEditTagsModal(false);
          setSelectedTagData({});
        }}
        tagData={SelectedTagData}
        refreshPage={refreshPage}
      />
      {openConfirmationModal && (
        <ConfirmationModal
          closeModal={() => setOpenConfirmationModal(false)}
          onSubmit={handleActivateDeactivateTag}
          heading={`${
            SelectedTagData?.activate ? "Deactivate" : "Activate"
          } Tag!`}
          confirmationMessage={`Are you sure want to ${
            SelectedTagData?.activate ? "deactivate" : "activate"
          } tag?`}
        />
      )}
      {ActivateDeactivateTagRes?.meta?.status && (
        <SuccessModal
          closeModal={closeSuccessModal}
          sucessfullMsg={`${
            SelectedTagData?.activate
              ? "Tag has been deactivated successfully."
              : "Tag has been activated successfully."
          }`}
          Heading={`${
            SelectedTagData?.activate ? "Deactivate" : "Activate"
          } Tag!`}
        />
      )}
      {ActivateDeactivateTagRes?.meta?.status === false && (
        <ErrorModal
          errorMessage={ActivateDeactivateTagRes?.meta?.msg}
          closeModal={closeSuccessModal}
          footerVisible={true}
        />
      )}
    </>
  );
};

export default CustomerTags;
