import axios from 'axios';
import { API_URL } from '@/utils/constants';
import { setupInterceptorsTo } from '@/rtk-apis/axios-config/axios-config';

const httpService = setupInterceptorsTo(
  axios.create({
    baseURL: API_URL
  })
);

export default httpService;
