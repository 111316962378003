import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { PROFILE_DEACTIVATE } from "@/rtk-apis/end-points";

const profileDeactivate = createAsyncThunk(
  "profileDeactivate",
  async (body) => {
    try {
      const response = await httpService.put(PROFILE_DEACTIVATE, body);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default profileDeactivate;
