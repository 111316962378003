import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { VERIFY_ACH_PAYMENT_METHOD } from '@/rtk-apis/end-points';

const verifyACHPaymentMethod = createAsyncThunk('verifyACHPaymentMethod', async (body) => {
  try {
    const response = await httpService.put(VERIFY_ACH_PAYMENT_METHOD, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default verifyACHPaymentMethod;
