import React, { useState } from "react";
import SideBar from "@/components/layout/SideBar";
import MyProfileDetails from "@/pages/MyProfile/MyProfileDetails";
import MyProfileEdit from "@/pages/MyProfile/MyProfileEdit";

const MyProfile = () => {
  const [OpenEditPage, setOpenEditPage] = useState(false);
  const [OpenDetailPage, setOpenDetailPage] = useState(true);
  const [OpenNotificationModal, setOpenNotificationModal] = useState(false);

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid layoutGrid--full">
          {OpenDetailPage && (
            <MyProfileDetails
              setOpenNotificationModal={setOpenNotificationModal}
              OpenNotificationModal={OpenNotificationModal}
              setOpenDetailPage={setOpenDetailPage}
              setOpenEditPage={setOpenEditPage}
            />
          )}
          <div
            className={`layoutGrid__col layoutGrid--responsive ${
              OpenEditPage ? "layoutGrid--show" : ""
            }`}
          >
            {OpenEditPage && (
              <MyProfileEdit
                setOpenEditPage={setOpenEditPage}
                setOpenDetailPage={setOpenDetailPage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
