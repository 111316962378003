import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { GET_ALL_NOTIFICATIONS_LIST } from "@/rtk-apis/end-points";

const getAllNotificationsList = createAsyncThunk(
  "getAllNotificationsList",
  async (body) => {
    try {
      const response = await httpService.post(GET_ALL_NOTIFICATIONS_LIST, body);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default getAllNotificationsList;
