import React, { useState } from "react";
import InterviewsListing from "@/pages/MyJobs/Interviews/InterviewsListing";
import InterviewsDetails from "@/pages/MyJobs/Interviews/InterviewsDetails";

function Interviews({ selectedJob }) {
  const [selectedCandiate, setSelectedCandiate] = useState({});
  const [detailPage, setDetailPage] = useState(false);

  const showDetails = (candidate) => {
    setSelectedCandiate(candidate);
    setDetailPage(true);
  };
  return (
    <>
      {detailPage ? (
        <InterviewsDetails
          hideDetail={() => setDetailPage(false)}
          candidate={selectedCandiate}
          selectedJob={selectedJob}
        />
      ) : (
        <InterviewsListing
          selectedJob={selectedJob}
          showDetails={showDetails}
        />
      )}
    </>
  );
}

export default Interviews;
