import React from "react";

const UsersWhiteSvg = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3333 16.5V14.8333C13.3333 13.9493 12.9821 13.1014 12.357 12.4763C11.7319 11.8512 10.884 11.5 9.99999 11.5H4.16666C3.28261 11.5 2.43476 11.8512 1.80964 12.4763C1.18452 13.1014 0.833328 13.9493 0.833328 14.8333V16.5M16.6667 5.66667V10.6667M19.1667 8.16667H14.1667M10.4167 4.83333C10.4167 6.67428 8.92428 8.16667 7.08333 8.16667C5.24238 8.16667 3.74999 6.67428 3.74999 4.83333C3.74999 2.99238 5.24238 1.5 7.08333 1.5C8.92428 1.5 10.4167 2.99238 10.4167 4.83333Z" stroke="white" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default UsersWhiteSvg;
