import React from "react";
import CloseIconGray from "@/assets/svgs/CloseIconGray";
import BankingImg2 from "@/assets/images/banking-img01.png";
import PaypalImg from "@/assets/images/paypal-icon.png";

const AddWithdrawalMethod = ({
  openModal,
  closeModal,
  setOpenAddBankAccountModal,
  setOpenPaypalMethodModal,
}) => {
  return (
    <>
      <div
        className={`modal modalPaymentWithdrawal  ${
          openModal ? "modal--open" : ""
        }`}
      >
        <div className="modal__dialog">
          <div className="modal__content modal__content--lg">
            <div className="modal__header">
              <h2 className="modal__header--title">Add a withdrawal method</h2>
              <button
                className="btn btn__transparent btnClose"
                onClick={closeModal}
              >
                <CloseIconGray />
              </button>
            </div>
            <div className="modal__body modal-body-left">
              <p>
                To start your subscription, input your card details to make
                payment.
                <span className="d_block">
                  You will be redirected to your banks authorization page.
                </span>
              </p>
              <h4 className="title">Available options</h4>
              <div className="paymentlist">
                <div className="paymentlist__item">
                  <div className="infoBox">
                    <div className="infoBox__icon">
                      <img
                        className="infoBox__icon__img"
                        src={PaypalImg}
                        alt="Paypal Img"
                      />
                    </div>
                    <div className="infoBox__detail">
                      <h5>Paypal</h5>
                      <ul>
                        <li>$2 USD Laila withdrawal fee</li>
                        <li>
                          Paypal may charge additional fees for sending and withdrawing funds
                        </li>
                        <li>Set up will take you to Laila</li>
                      </ul>
                    </div>
                  </div>
                  <div className="bntActions">
                    <button
                      className="btn btn__primary"
                      type="button"
                      onClick={() => {
                        setOpenPaypalMethodModal(true);
                        closeModal();
                      }}
                    >
                      Set up
                    </button>
                  </div>
                </div>
                <div className="paymentlist__item">
                  <div className="infoBox">
                    <div className="infoBox__icon">
                      <img
                        className="infoBox__icon__img"
                        src={BankingImg2}
                        alt="Bank Img"
                      />
                    </div>
                    <div className="infoBox__detail">
                      <h5>ACH - Direct Debit</h5>
                      <ul>
                        <li>Available for USA bank accounts</li>
                      </ul>
                    </div>
                  </div>
                  <div className="bntActions">
                    <button
                      className="btn btn__primary"
                      type="button"
                      onClick={() => setOpenAddBankAccountModal(true)}
                    >
                      Set up
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default AddWithdrawalMethod;
