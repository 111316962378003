import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { Formik, Form, Field } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Loader from "@/elements/Loader";
import { GetUserDetails, replaceLast } from "@/utils/helper";
import updateTaxResidenceInfo from "@/rtk-apis/tax/updateTaxResidenceInfo";
import { taxResidentSchema } from "@/components/schema/schema";
import { closeRtkModal } from "@/store/slices/tax/updateTaxResidenceInfoSlice";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import staticData from "@/rtk-apis/staticData";

const TaxResidenceEdit = ({ changeTaxResidentView, taxDetails }) => {
  const dispatch = useDispatch();
  const user = GetUserDetails();
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const add3Ref = useRef();
  const add1DivRef = useRef();
  const add3DivRef = useRef();

  const [addressCountry, setAddressCountry] = useState("");
  const [addressCountryCode, setAddressCountryCode] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressStateCode, setAddressStateCode] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressZipcode, setAddressZipcode] = useState("");
  const [addressAddress1, setAddressAddress1] = useState("");
  const [showAddressFiels, setShowAddressFiels] = useState(
    taxDetails?.addressLine1 ? true : false
  );
  const [currentTime, setCurrentTime] = useState(new Date());

  const [country, setCountry] = useState({
    value: taxDetails?.countryCode || "",
    name: taxDetails?.countryName || "",
  });

  const handleAddressChange = (value, inputName) => {
    if (value?.length >= 3 && inputName === "address3") {
      add3DivRef.current.style.display = "none";
      add1DivRef.current.style.display = "block";
      inputRef.current.focus();
    } else if (value?.length < 3 && inputName === "address1") {
      add1DivRef.current.style.display = "none";
      add3DivRef.current.style.display = "block";
      add3Ref.current.focus();
    } else {
      if (value?.length < 3) {
        add1DivRef.current.style.display = "none";
        add3DivRef.current.style.display = "block";
        add3Ref.current.focus();
      } else {
        add3DivRef.current.style.display = "none";
        add1DivRef.current.style.display = "block";
        inputRef.current.focus();
      }
    }
  };

  useEffect(() => {
    if (add3DivRef.current) {
      add3DivRef.current.style.display = "none";
    }
    const options = {
      componentRestrictions: {
        country: country?.value ? country?.value : "US",
      },
    };
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", async function() {
      setShowAddressFiels(true);
      const place = await autoCompleteRef.current.getPlace();
      let addressComponents = place["address_components"];
      let address1 = place["formatted_address"];
      let street = "";
      let city = "";
      let state = "";
      let stateCode = "";
      let country = "";
      let countryCode = "";
      let zipCode = "";

      for (let i = 0; i < addressComponents.length; i++) {
        let types = addressComponents[i]["types"];
        if (types.includes("street_number") || types.includes("route")) {
          street = addressComponents[i]["long_name"];
        } else if (types.includes("locality")) {
          city = addressComponents[i]["long_name"];
        } else if (types.includes("administrative_area_level_1")) {
          state = addressComponents[i]["long_name"];
          stateCode = addressComponents[i]["short_name"];
        } else if (types.includes("country")) {
          country = addressComponents[i]["long_name"];
          countryCode = addressComponents[i]["short_name"];
        } else if (types.includes("postal_code")) {
          zipCode = addressComponents[i]["long_name"];
        }
      }
      const addressSplit = address1.split(city);
      let modifiedAddress = "";
      if (addressSplit.length && addressSplit[0].length) {
        modifiedAddress = replaceLast(addressSplit[0], ", ", "");
      }
      let streetAddress = modifiedAddress.includes(place["name"])
        ? modifiedAddress
        : `${place["name"]}`;
      setAddressCountry(country);
      setAddressCountryCode(countryCode);
      setAddressState(state);
      setAddressStateCode(stateCode);
      setAddressCity(city);
      setAddressZipcode(zipCode);
      setAddressAddress1(streetAddress);
      setCurrentTime(new Date());
    });
  }, [country]);

  const initialValues = {
    country: taxDetails?.countryName || "",
    address1: taxDetails?.addressLine1 || "",
    address2: taxDetails?.addressLine2 || "",
    city: taxDetails?.city || "",
    state: taxDetails?.stateName || "",
    zipcode: taxDetails?.zipCode || "",
  };

  const updateTaxResidenceData = useSelector((globalState) => {
    return globalState.updateTaxResidenceInfo;
  });

  const { data } = useSelector((globalState) => {
    return globalState.static;
  });

  useEffect(() => {
    dispatch(staticData());
  }, []);

  const isButtonDisabled = (error, value, initialValues) => {
    let status = false;
    if (value?.state) {
      delete error["state"];
    }
    if (value?.city) {
      delete error["city"];
    }
    if (value?.zipcode) {
      delete error["zipcode"];
    }
    if (Object.keys(error)?.length != 0) {
      status = true;
    } else if (
      Object.keys(error)?.length == 0 &&
      _.isEqual(value, initialValues)
    ) {
      status = true;
    }
    if (
      Object.keys(error)?.length == 0 &&
      value?.country === "United States" &&
      !value?.zipcode
    ) {
      status = true;
    }
    return status;
  };

  const handleSubmit = (values) => {
    let obj = {
      recruiterId: user?.recruiter?.recruiterId,
      taxResidence: {
        countryCode: addressCountryCode,
        countryName: values?.country,
        addressLine1: values?.address1,
        addressLine2: values?.address2,
        city: values?.city,
        stateCode: addressStateCode,
        stateName: values?.state,
        zipCode: values?.zipcode,
      },
    };
    dispatch(updateTaxResidenceInfo(obj));
  };

  const closeModal = () => {
    changeTaxResidentView(false);
    dispatch(closeRtkModal());
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={taxResidentSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          errors,
          values,
          handleChange,
          handleBlur,
          touched,
          setFieldValue,
          setFieldTouched,
        }) => {
          useEffect(() => {
            if (addressCountry) {
              setFieldValue("country", addressCountry);
              setFieldValue("state", addressState);
              setFieldValue("city", addressCity);
              setFieldValue("zipcode", addressZipcode);
              setFieldValue("address1", addressAddress1);
            }
          }, [
            addressCountry,
            addressState,
            addressCity,
            addressZipcode,
            addressAddress1,
            currentTime,
          ]);

          return (
            <Form>
              <section className="details__section">
                <h4 className="details__section--title">Tax Residence</h4>
                <p>
                  Your tax residence information is part of the Laila W-9 or W-8
                  form process.
                </p>
                <div className="formRow">
                  <div className="formCol">
                    <div className="formField">
                      <label className="formField__label">Country</label>
                      <Autocomplete
                        id="tags-filled"
                        options={data?.taxCountry}
                        getOptionLabel={(option) => option?.name || option}
                        defaultValue={values?.country || ""}
                        value={values?.country}
                        name="country"
                        onChange={(event, newValue) => {
                          setCountry(newValue);
                          setFieldValue("country", newValue?.name);
                          setFieldValue("state", "");
                          setFieldValue("city", "");
                          setFieldValue("zipcode", "");
                          setFieldValue("address1", "");
                          setShowAddressFiels(false);
                        }}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            placeholder="Select"
                            className="autoCompleteInput"
                          />
                        )}
                      />
                      {/* <Field
                        type="text"
                        className="formField__input sm"
                        name="country"
                        placeholder="Country"
                      /> */}
                    </div>
                  </div>
                </div>
                {values?.country && (
                  <div className="formRow" ref={add1DivRef}>
                    <div className="formCol">
                      <div className="formField">
                        <label className="formField__label">Address</label>
                        <div className="formField__group sm">
                          <input
                            type="text"
                            className="formField__input sm"
                            name="address1"
                            placeholder="Address"
                            ref={inputRef}
                            value={values?.address1}
                            onChange={(e) => {
                              handleAddressChange(e?.target?.value, "address1");
                              setFieldValue("address1", e?.target?.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {values?.country && (
                  <div className="formRow" ref={add3DivRef}>
                    <div className="formCol">
                      <div className="formField">
                        <label className="formField__label">Address</label>
                        <div className="formField__group sm">
                          <input
                            type="text"
                            className="formField__input sm"
                            name="address3"
                            placeholder="Address"
                            ref={add3Ref}
                            value={values?.address1}
                            onChange={(e) => {
                              handleAddressChange(e?.target?.value, "address3");
                              setFieldValue("address1", e?.target?.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {showAddressFiels && (
                  <div className="formRow formRow__2">
                    <div className="formCol">
                      <div className="formField">
                        <label className="formField__label">State</label>
                        <FormControl
                          fullWidth
                          size="small"
                          className="formField__select"
                        >
                          <Field
                            type="text"
                            className="formField__input sm"
                            name="state"
                            placeholder="State"
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="formCol">
                      <div className="formField">
                        <label className="formField__label">City</label>
                        <FormControl
                          fullWidth
                          size="small"
                          className="formField__select"
                        >
                          <Field
                            type="text"
                            className="formField__input sm"
                            name="city"
                            placeholder="City"
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                )}
                {showAddressFiels && (
                  <div className="formRow">
                    <div className="formCol">
                      <div className="formField">
                        <label className="formField__label">Zipcode</label>
                        <div className="formField__group sm">
                          <Field
                            type="text"
                            className="formField__input sm"
                            name="zipcode"
                            placeholder="Zipcode"
                            onChange={(e) => {
                              if (e?.target?.value?.length <= 10) {
                                setFieldValue("zipcode", e?.target?.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="formRow">
                  <div className="btn__group btn__group--end">
                    <button
                      className="btn btn__white"
                      onClick={() => changeTaxResidentView(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn__primary"
                      type="submit"
                      disabled={isButtonDisabled(errors, values, initialValues)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </section>
            </Form>
          );
        }}
      </Formik>
      {updateTaxResidenceData?.loading && <Loader />}
      {updateTaxResidenceData?.meta?.status && (
        <SuccessModal
          closeModal={closeModal}
          sucessfullMsg={updateTaxResidenceData?.meta?.msg}
          Heading={"Tax Residence!"}
        />
      )}
      {updateTaxResidenceData?.meta?.status === false && (
        <ErrorModal
          errorMessage={updateTaxResidenceData?.meta?.msg}
          closeModal={closeModal}
          footerVisible={true}
        />
      )}
    </div>
  );
};

export default TaxResidenceEdit;
