import { createSlice } from '@reduxjs/toolkit';
import getCustomersEmployeeList from '@/rtk-apis/customer/myJobs/getCustomersEmployeeList';

const initialState = {
  message: '',
  data: {},
  meta: {},
  token: '',
  loading: false,
  error: ''
};

const getCustomersEmployeeListSlice = createSlice({
  name: 'getCustomersEmployeeList',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomersEmployeeList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCustomersEmployeeList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(getCustomersEmployeeList.rejected, (state, action) => {
        state.loading = false;
      });
  }

});

export default getCustomersEmployeeListSlice.reducer;

export const { closeRtkModal } = getCustomersEmployeeListSlice.actions;
