import { createSlice } from '@reduxjs/toolkit';
import cancelCompanyDeactivationRequest from '@/rtk-apis/customer/companyProfile/cancelCompanyDeactivationRequest';

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: ""
};

const CancelCompanyDeactivateRequestSlice = createSlice({
  name: 'CancelCompanyDeactivateRequestSlice',
  initialState,
  reducers: {
    closeReqModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelCompanyDeactivationRequest.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(cancelCompanyDeactivationRequest.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(cancelCompanyDeactivationRequest.rejected, (state, action) => {
        state.loading = false;
      });
  }
});

export default CancelCompanyDeactivateRequestSlice.reducer;

export const { closeReqModal } = CancelCompanyDeactivateRequestSlice.actions;
