import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PlusIconCircle from "@/assets/svgs/PlusIconCircle";
import Tooltip from "@mui/material/Tooltip";
import Approve from "@/assets/svgs/Approve";
import Reject from "@/assets/svgs/Reject";
import CloseIconCircle from "@/assets/svgs/CloseIconCircle";
import FilterIcon from "@/assets/svgs/FilterIcon";
import interviewList from "@/rtk-apis/customer/myJobs/interview/interviewList";
import removeTagDetails from "@/rtk-apis/customer/tags/removeTagDetails";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import {
  GetUserDetails,
  firstLetterCapital,
  capitalizeEachWord,
  titleCase
} from "@/utils/helper";
import CustomPagination from "@/elements/pagination/customPagination";
import GoTOPage from "@/elements/gotopage/gotopage";
import MeetingStatus from "@/components/HOCs/MeetingStatus";
import Loader from "@/elements/Loader";
import SortColumn from "@/elements/SortColumn";
import AddTagsModal from "@/components/modals/AddTagsModal";
import ErrorModal from "@/components/modals/error-modal";
import { SuccessModal } from "@/components/modals/SuccessModal";
import { closeRtkModal } from "@/store/slices/customer/tags/removeTagDetailsSlice";
import MultiSelectTagFilter from '@/components/Filters/multiSelectTagFilter';
import getAllTagsList from "@/rtk-apis/customer/tags/getAllTagsList";

function AllInterviews({ changeInterviewViewType, jobData }) {
  const dispatch = useDispatch();
  const user = GetUserDetails();
  const [page, setPage] = useState(1);
  const [sortByASC, setSortByASC] = useState(true);
  const [openTagConfModal, setOpenTagConfModal] = useState(false);
  const [OpenAddTagsModal, setOpenAddTagsModal] = useState(false);
  const [selectedTagDetails, setSelectedTagDetails] = useState({});
  const [sortByColoumn, setSortByColoumn] = useState("");

  const { data, loading } = useSelector((globalState) => {
    return globalState.myJobInterviewList;
  });

  const removeTagDeta = useSelector((globalState) => {
    return globalState.removeTagDetails;
  });

  const allFilterTag = useSelector((globalState) => {
    return globalState?.getAllTagsListRes;
  });

  useEffect(() => {
    dispatch(
      getAllTagsList({
        companyId: user?.customer?.companyId,
        tagName: "",
        page: "1",
        limit: "20",
        from: "addTagFetch",
        status: "activate"
      })
    );
  }, []);

  const getInterviewRole = () => {
    if (user?.customer?.role === "Company Admin") {
      return "Company Admin";
    } else if (jobData?.hiringLeadArray?.includes(user?.customer?.employeeId)) {
      return "Hiring Lead";
    } else {
      return "Employee";
    }
  };

  useEffect(() => {
    let listObj = {
      limit: 20,
      page,
      columnName: sortByColoumn,
      sort: sortByASC ? "ASC" : "DESC",
    };
    listObj.role = getInterviewRole();
    listObj.companyId = user?.customer?.companyId;
    listObj.employeeId = user?.customer?.employeeId;
    listObj.jobId = jobData?.jobId;
    getInterviewListAPIData(listObj);
  }, [page, jobData?.jobId, sortByColoumn, sortByASC]);

  const getSortByData = (columnName) => {
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
  };

  const getInterviewListAPIData = (obj) => {
    dispatch(interviewList(obj));
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  const openRemoveConfModal = (canId, intrvId, tagId) => {
    setSelectedTagDetails({
      candidateId: canId,
      interviewId: intrvId,
      tagId: tagId
    })
    setOpenTagConfModal(true)
  }

  const openAddTagCandidateModal = (canId, intrvId, tags) => {
    setOpenAddTagsModal(true);
    setSelectedTagDetails({
      candidateId: canId,
      interviewId: intrvId,
      tags: tags
    })
  }

  const removeCustomerTag = () => {
    dispatch(removeTagDetails(selectedTagDetails))
  }

  const closeRemoveResModal = () => {
    dispatch(closeRtkModal());
    setOpenTagConfModal(false);
    refreshPage();
  }

  const refreshPage = () => {
    dispatch(
      getAllTagsList({
        companyId: user?.customer?.companyId,
        tagName: "",
        page: "1",
        limit: "20",
        from: "addTagFetch",
        status: "activate"
      })
    );
    
    let listObj = {
      limit: 20,
      page,
      columnName: sortByColoumn,
      sort: sortByASC ? "ASC" : "DESC",
    };
    listObj.role = getInterviewRole();
    listObj.companyId = user?.customer?.companyId;
    listObj.employeeId = user?.customer?.employeeId;
    listObj.jobId = jobData?.jobId;
    getInterviewListAPIData(listObj);
  }

  const handleTagFilterChange = (selectedOption) => {
    let listObj = {
      limit: 20,
      page,
      columnName: sortByColoumn,
      sort: sortByASC ? "ASC" : "DESC",
    };
    listObj.role = getInterviewRole();
    listObj.companyId = user?.customer?.companyId;
    listObj.employeeId = user?.customer?.employeeId;
    listObj.jobId = jobData?.jobId;
    listObj.tagArray = selectedOption;
    getInterviewListAPIData(listObj);
  }

  const handleTagFilterClose = () => {
    let listObj = {
      limit: 20,
      page,
      columnName: sortByColoumn,
      sort: sortByASC ? "ASC" : "DESC",
    };
    listObj.role = getInterviewRole();
    listObj.companyId = user?.customer?.companyId;
    listObj.employeeId = user?.customer?.employeeId;
    listObj.jobId = jobData?.jobId;
    getInterviewListAPIData(listObj);
  }

  return (
    <>
      <TableContainer
        component={Paper}
        className="tableContainer"
        style={{ height: "calc(100dvh - 182px)" }}
      >
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>S.</TableCell>
              <TableCell onClick={() => getSortByData("firstName")}>
                <SortColumn thName={"Name"} sortByASC={sortByASC} />
              </TableCell>
              <TableCell onClick={() => getSortByData("email")}>
                <SortColumn thName={"Email"} sortByASC={sortByASC} />
              </TableCell>
              {/* <TableCell>Job Team member</TableCell> */}
              <TableCell>Status</TableCell>
              <TableCell>
                <div className="sortingDiv tagFilter">
                  Tags{" "}
                  <MultiSelectTagFilter
                    labels={allFilterTag?.data?.rows}
                    applyFilter={handleTagFilterChange}
                    clearFilter={handleTagFilterClose}
                  />
                </div>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.rows?.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell>
                  <div className="profile">
                    <div className="profile__initials">
                      {row?.candidate?.firstName?.charAt(0)?.toUpperCase()}
                    </div>
                    <span className="profile__name">
                      {firstLetterCapital(row?.candidate?.firstName) +
                        " " +
                        firstLetterCapital(row?.candidate?.lastName)}
                    </span>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="cellText">
                    <span className="cellText__dark">
                      {row?.candidate?.email}
                    </span>
                  </div>
                </TableCell>
                {/* <TableCell>
                  <div className="cellText">
                    <span className="cellText__dark">
                      {row?.jmtMember?.length > 1
                        ? "Panel Discussion"
                        : capitalizeEachWord(row?.jmtMember[0])}
                    </span>
                  </div>
                </TableCell> */}
                <TableCell>
                  <div className="cellText">
                    <MeetingStatus time={row.meeting_end_time} />
                  </div>
                </TableCell>
                <TableCell className="widthAuto">
                  <div className="cellText cellText--tag">
                    {row?.tag?.map(item =>(<span key={`tag${item?.id}`} className="btn btn__primary btn__rounded" style={{background: item?.colorCode}}>
                      {titleCase(item?.tagName)}
                      <button className="closebtn" onClick={()=>openRemoveConfModal(row?.candidateId, row?.interviewId, item?.id)}>
                        <CloseIconCircle />
                      </button>
                    </span>))}
                    {
                      row?.tag?.length < 2 && <button
                      className="addTagBtn"
                      type="button"
                      onClick={() => openAddTagCandidateModal(row?.candidateId, row?.interviewId, row?.tag)}
                    >
                      <PlusIconCircle />
                    </button>
                    }
                  </div>
                </TableCell>
                <TableCell>
                  <div className="cellText">
                    <Link
                      onClick={() => {
                        changeInterviewViewType("interviewDetail", row);
                      }}
                      className="cellText__dark cell--link"
                    >
                      View Details
                    </Link>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="actions flex--end actions--approveReject">
                    <Tooltip title="Approve" placement="top" arrow>
                      <button
                        className="btn btn__transparent border_0 approve"
                        onClick={() => {
                          setCandidateAction("Approve");
                          setCandidateData(row);
                          checkRecordingExist(row);
                        }}
                      >
                        <Approve />
                      </button>
                    </Tooltip>

                    <Tooltip title="Reject" placement="top" arrow>
                      <button
                        className="btn btn__transparent border_0 reject"
                        onClick={() => {
                          setCandidateAction("Reject");
                          setCandidateData(row);
                          checkRecordingExist(row);
                        }}
                      >
                        <Reject />
                      </button>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!data?.rows?.length && (
          <div className="no-data">No Results Found!</div>
        )}
      </TableContainer>
      <div className="pagination">
        <div className="pagination__result">
          {`${data?.count > page * 20 ? 20 : data?.count} of ${
            data?.count
          } results`}
        </div>
        {data?.count ? (
          <CustomPagination
            totalPageCount={Math.ceil(data?.count / data?.count)}
            changePage={(event, value) => changePage(event, value)}
          />
        ) : (
          ""
        )}
        {data?.count ? (
          <div className="pagination__actions">
            <GoTOPage changePage={changePage} />
          </div>
        ) : (
          ""
        )}
      </div>
      {loading && <Loader />}
      {OpenAddTagsModal && 
      <AddTagsModal
        openModal={OpenAddTagsModal}
        closeModal={() => setOpenAddTagsModal(false)}
        tagData={selectedTagDetails}
        refreshPage={refreshPage}
        fromJobSection={false}
      />}
      {openTagConfModal && (
        <ConfirmationModal
          closeModal={() => setOpenTagConfModal(false)}
          onSubmit={removeCustomerTag}
          heading={"Remove Tag!"}
          confirmationMessage={"Are you sure you want to remove this tag?"}
        />
      )}
      {removeTagDeta?.meta?.status && (
        <SuccessModal
          closeModal={closeRemoveResModal}
          sucessfullMsg={removeTagDeta?.meta?.msg}
          Heading={"Remove Tag!"}
        />
      )}
      {removeTagDeta?.meta?.status === false && (
        <ErrorModal
          errorMessage={removeTagDeta?.meta?.msg}
          closeModal={closeRemoveResModal}
          footerVisible={true}
        />
      )}
    </>
  );
}

export default AllInterviews;
