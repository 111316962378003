import { createSlice } from '@reduxjs/toolkit';
import forgotPassword from '@/rtk-apis/forgotPassword';

const initialState = {
  message: '',
  data: {},
  meta: {},
  loading: false,
  error: ''
};

const ForgotPasswordSlice = createSlice({
  name: 'ForgotPassword',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, { payload }) => {
        state.meta = payload?.meta;
        state.loading = false;
      })
      .addCase(forgotPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.meta?.msg;
      });
  }
});

export default ForgotPasswordSlice.reducer;

export const { closeRtkModal } = ForgotPasswordSlice.actions;
