import React from "react";

function EmployeeProfileIcon() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6665 13V11.6667C10.6665 10.9594 10.3856 10.2811 9.88545 9.78105C9.38536 9.28095 8.70708 9 7.99984 9H3.33317C2.62593 9 1.94765 9.28095 1.44755 9.78105C0.947456 10.2811 0.666504 10.9594 0.666504 11.6667V13M11.3332 6.33333L12.6665 7.66667L15.3332 5M8.33317 3.66667C8.33317 5.13943 7.13926 6.33333 5.6665 6.33333C4.19374 6.33333 2.99984 5.13943 2.99984 3.66667C2.99984 2.19391 4.19374 1 5.6665 1C7.13926 1 8.33317 2.19391 8.33317 3.66667Z"
        stroke="#384250"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EmployeeProfileIcon;
