import React, { useEffect, useRef, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import staticData from "@/rtk-apis/staticData";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CompanyDeactivateAccount from "@/rtk-apis/customer/companyProfile/companyDeactivateAccount";
import { GetUserDetails } from "@/utils/helper";
import { SuccessModal } from "@/components/modals/SuccessModal";
import { closeRtkModal } from "@/store/slices/customer/companyProfile/companyDeactivateAccountSlice";
import ErrorModal from "@/components/modals/error-modal";
import Loader from "@/elements/Loader";
import ConfirmationModal from "@/components/modals/confirmation-modal";

export const CompanyProfileDeactivateModal = ({
  openModal,
  closeModal,
  refreshCompanyProfileDetail,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const userDetails = GetUserDetails();

  const [checkboxValue, setCheckboxValue] = useState({});
  const [explainationtext, setExplainationtext] = useState("");
  const [textareaError, setTextareaError] = useState("");
  const { data } = useSelector((globalState) => {
    return globalState.static;
  });
  const deactivatedCompanyResponse = useSelector((globalState) => {
    return globalState.deactivateCompanyResponse;
  });
  const [
    OpenDeactivateConfirmationModal,
    setOpenDeactivateConfirmationModal,
  ] = useState(false);

  useEffect(() => {
    dispatch(staticData());
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [closeModal]);

  const handleCheckboxChange = (e, item) => {
    const checkboxVal = {
      id: item?.id,
      value: item?.value,
    };
    setCheckboxValue(checkboxVal);
    if (item?.value === "Other") {
      setTextareaError("Reason is required.");
    } else {
      setTextareaError("");
    }
  };

  const handleTextAreaChange = (e) => {
    setExplainationtext(e?.target?.value);
    if (checkboxValue?.value === "Other" && e?.target?.value === "") {
      setTextareaError("Reason is required.");
    } else {
      setTextareaError("");
    }
  };

  const handleDeactivateCompany = () => {
    const employeeName =
      userDetails?.customer?.firstName + " " + userDetails?.customer?.lastName;
    const body = {
      companyId: userDetails?.customer?.companyId,
      option: checkboxValue,
      reason: explainationtext,
      employeeId: userDetails?.customer?.employeeId,
      companyName: userDetails?.customer?.company?.companyName,
      employeeName,
      from: "Customer",
    };
    dispatch(CompanyDeactivateAccount(body));
    setOpenDeactivateConfirmationModal(false);
  };

  const closeSuccessModal = () => {
    dispatch(closeRtkModal());
    refreshCompanyProfileDetail();
    setCheckboxValue({});
    setExplainationtext("");
    setTextareaError("");
    setOpenDeactivateConfirmationModal(false);
  };

  const handleCancel = () => {
    closeModal();
    refreshCompanyProfileDetail();
    setCheckboxValue({});
    setExplainationtext("");
    setTextareaError("");
  };

  return (
    <>
      <div
        className={`modal modalSuccessfull ${openModal ? "modal--open" : ""}`}
      >
        <div className="modal__dialog">
          <div className="modal__content lg-modal">
            <div className="modal__body modal-body-left">
              <div className="popup-alert">
                Note: All employees will lose their access once company account
                is deactivated.
              </div>
              <h2 className="modal__header--title bg-red">
                Reason for leaving
              </h2>

              <div className="formField">
                {data?.deactivateAccountOption?.map((item, index) => {
                  return (
                    <div className="formField__checkbox" key={index}>
                      <FormControlLabel
                        className="pb15"
                        onChange={(e) => handleCheckboxChange(e, item)}
                        checked={checkboxValue?.id === item?.id ? true : false}
                        control={<Checkbox />}
                      />
                      <span className="pl5">{item?.value}</span>
                    </div>
                  );
                })}
              </div>
              <div className={`formField ${textareaError ? "fieldError" : ""}`}>
                <label className="formField__label">
                  Reason for Deactivation
                  <span style={{ opacity: ".5" }}>
                    {`${checkboxValue?.value !== "Other" ? " (Optional)" : ""}`}
                  </span>
                </label>
                <textarea
                  className="formField__textarea"
                  value={explainationtext}
                  onChange={(e) => handleTextAreaChange(e)}
                  rows={5}
                  maxLength={300}
                  placeholder="Enter your note here..."
                ></textarea>
                <span className="formField__error">{textareaError}</span>
              </div>
              <div className="btn__group flex--start">
                <button className="btn btn__white" onClick={handleCancel}>
                  Cancel
                </button>
                <button
                  className="btn btn__primary"
                  disabled={!checkboxValue.id || textareaError}
                  onClick={() => setOpenDeactivateConfirmationModal(true)}
                >
                  Deactivate
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      {deactivatedCompanyResponse?.loading && <Loader />}
      {OpenDeactivateConfirmationModal && (
        <ConfirmationModal
          closeModal={() => setOpenDeactivateConfirmationModal(false)}
          onSubmit={handleDeactivateCompany}
          heading={"Deactivate Account!"}
          confirmationMessage={
            "Are you sure you want to send request to deactivate your company account?"
          }
        />
      )}
      {deactivatedCompanyResponse?.meta?.status === true && (
        <SuccessModal
          closeModal={closeSuccessModal}
          sucessfullMsg={deactivatedCompanyResponse?.meta?.msg}
          Heading={"Deactivate request sent!"}
        />
      )}
      {deactivatedCompanyResponse?.meta?.status === false && (
        <ErrorModal
          errorMessage={deactivatedCompanyResponse?.meta?.msg}
          closeModal={closeSuccessModal}
          footerVisible={true}
        />
      )}
    </>
  );
};
