import { createSlice } from "@reduxjs/toolkit";
import addNPSRating from "@/rtk-apis/customer/myJobs/reviewsRatings/addNPSRating";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const addNPSRatingSlice = createSlice({
  name: "addNPSRatingSlice",
  initialState,
  reducers: {
    closeAddNPSRatingModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNPSRating.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addNPSRating.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(addNPSRating.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default addNPSRatingSlice.reducer;

export const { closeAddNPSRatingModal } = addNPSRatingSlice.actions;
