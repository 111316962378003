import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CHANGE_PASSWORD } from '@/rtk-apis/end-points';

const changePassword = createAsyncThunk('changePassword', async (body) => {
  try {
    const response = await httpService.put(CHANGE_PASSWORD, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default changePassword;
