import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { INTERVIEW_LIST } from '@/rtk-apis/end-points';

const interviewList = createAsyncThunk('interviewList', async (body) => {
  try {
    const response = await httpService.post(INTERVIEW_LIST, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default interviewList;
