import React from "react";

const HomeSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.181 6.25763V5.06176H15.5185V7.56924V7.77921L15.6684 7.92623L18.4522 10.6561L18.2207 10.8832L17.7061 10.3786L16.856 9.54493V10.7355V17.8337H11.8372V12.4314V11.9314H11.3372H8.66216H8.16216V12.4314V17.8337H3.14335V10.7355V9.54493L2.29327 10.3786L1.77867 10.8832L1.54713 10.6561L9.99967 2.36729L14.3309 6.61462L15.181 7.44824V6.25763ZM10.3498 3.1647L9.99967 2.8214L9.64959 3.1647L3.63079 9.06694L3.48087 9.21396V9.42393V17.022V17.522H3.98087H7.32465H7.82465V17.022V11.6198H12.1747V17.022V17.522H12.6747H16.0185H16.5185V17.022V9.42393V9.21396L16.3686 9.06694L10.3498 3.1647Z"
        stroke="#6C737F"
      ></path>
    </svg>
  );
};
export default HomeSvg;
