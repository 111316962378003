import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { APPROVE_REJECT_INTERVIEW_CANDIDATE } from '@/rtk-apis/end-points';

const approveRejectInterviewCandidate = createAsyncThunk('approveRejectInterviewCandidate', async (body) => {
  try {
    const response = await httpService.post(APPROVE_REJECT_INTERVIEW_CANDIDATE, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default approveRejectInterviewCandidate;
