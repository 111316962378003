import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { CANDIDATE_SIGN_UP_STEP_TWO } from "@/rtk-apis/end-points";

const candidateSignUpStepTwo = createAsyncThunk(
  "candidateSignUpStepTwo",
  async (body) => {
    try {
      const response = await httpService.put(CANDIDATE_SIGN_UP_STEP_TWO, body);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default candidateSignUpStepTwo;
