import React from "react";

function CandidatesFeedback({ candidateData, changeCandidateViewType }) {
  const { feedback } = candidateData;
  const adminFeedback = feedback.filter((t) => t.role === "Admin");

  return (
    <>
      <div className="dataCol__head head--inner head--responsive">
        <div className="item">
          <div className="item__info">
            <h4 className="item__info--title">Laila</h4>
          </div>
        </div>

        <div className="dataCol__head--actions">
          <button
            onClick={() => changeCandidateViewType("list", candidateData)}
            className="btn btn__white w_fitContent"
            type="button"
          >
            Back
          </button>
        </div>
      </div>

      <div className="formContainer">
        <form className="form">
          <div className="formRow formRow__2">
            {adminFeedback?.[0]?.["feedBackData"].map((item) => (
              <div className="formCol">
                <div className="formField">
                  <label className="formField__label">{item?.question}</label>
                  <textarea className="formField__textarea" disabled rows={3}>
                    {item?.answer}
                  </textarea>
                </div>
              </div>
            ))}
          </div>
        </form>
        {!adminFeedback?.[0]?.["feedBackData"].length && (
          <div className="no-data floating">No Result Found!</div>
        )}
      </div>
    </>
  );
}

export default CandidatesFeedback;
