import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CUSTOMER_EMPLOYEE_JOBS } from '@/rtk-apis/end-points';

const customerEmployeeJobs = createAsyncThunk('customerEmployeeJobs', async (body) => {
  try {
    const response = await httpService.post(CUSTOMER_EMPLOYEE_JOBS, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default customerEmployeeJobs;
