import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { APPROVED_CANDIDATES_LIST } from '@/rtk-apis/end-points';

const approvedCandidatesList = createAsyncThunk('approvedCandidatesList', async (body) => {
  try {
    const response = await httpService.post(APPROVED_CANDIDATES_LIST, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default approvedCandidatesList;
