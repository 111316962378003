export const EmailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
export const ValidPhoneNumberRegex = /^\(\d{3}\)\s?\d{3}-\d{4}$/;
export const phoneNumberRegex = /^\d{10}$/;
export const isValidName = /^[a-zA-Z !@#$%^&*(),.?":{}|<>]*$/;
export const isValidEmployeeName = /^[A-Za-z._\s]+$/;
export const isValidCompanyName = /^[A-Za-z.@_\s]+$/;
export const URLRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
export const LinkedInRegex = /^https:\/\/www\.linkedin\.com\/.*$/;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
export const TinNumberRegx = /[A-Z]{5}\d{4}[A-Z]/;
export const SSNNumberRegx = /^\d{3}-\d{2}-\d{4}$/;
export const EINNumberRegx = /^\d{2}-\d{7}$/;
export const NumberRegx = /^\d+$/;