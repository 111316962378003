import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseIconGray from "@/assets/svgs/CloseIconGray";
import LinkIcon from "@/assets/svgs/LinkIcon";
import InfoIcon from "@/assets/svgs/InfoIcon";
import RedInfoIcon from "@/assets/svgs/RedInfoIcon";

const RecruiterReassignModal = ({
  openModal,
  closeModal,
  selectedJob,
  staticData,
  setRecruiterCheckboxValue,
  RecruiterCheckboxValue,
  onSubmit,
}) => {
  const handleCheckboxChange = (e, item) => {
    const newValue = RecruiterCheckboxValue === item.value ? "" : item.value;
    setRecruiterCheckboxValue(newValue);
  };

  const handleRecruiterInfoClick = () => {
    localStorage.setItem(
      "recruiterDetailPage",
      JSON.stringify({ recruiterPage: true })
    );
    window.open("/customer-my-jobs", "_blank");
  };
  return (
    <>
      <div className={`modal modalJobStatus ${openModal ? "modal--open" : ""}`}>
        <div className="modal__dialog">
          <div className="modal__content modal__content--md">
            <div className="modal__header">
              <h2 className="modal__header--title">{selectedJob?.title}</h2>
              <button
                className="btn btn__transparent btnClose"
                onClick={() => {
                  closeModal();
                  setRecruiterCheckboxValue("");
                }}
              >
                <CloseIconGray />
              </button>
            </div>
            <div className="modal__body modal-body-left">
              <h3 className="tagline">
                <span className="tagline--label tagline--label--primary">
                  Reopen job
                </span>{" "}
                <span className="tagline--text">(Step 2 of 2)</span>
              </h3>
              <h4 className="title">Reason for Reopening this job?</h4>
              <div className="formField">
                {staticData?.recruiterOptionForReopen?.map((item, index) => {
                  return (
                    <div className="formField__checkbox" key={index}>
                      <FormControlLabel
                        className={`pb15 ${
                          selectedJob?.recruiter?.deleteStatus &&
                          item?.value === "old"
                            ? "disabled"
                            : ""
                        }`}
                        control={<Checkbox />}
                        disabled={
                          selectedJob?.recruiter?.deleteStatus &&
                          item?.value === "old"
                            ? true
                            : false
                        }
                        onChange={(e) => handleCheckboxChange(e, item)}
                        checked={RecruiterCheckboxValue === item?.value}
                      />
                      <span className="pl5">
                        <span
                          className={
                            selectedJob?.recruiter?.deleteStatus &&
                            item?.value === "old"
                              ? "disabled"
                              : ""
                          }
                        >
                          {item?.text}
                        </span>
                        {item?.value === "old" && (
                          <button
                            className={`link text--primary ${
                              selectedJob?.recruiter?.deleteStatus
                                ? "text--danger"
                                : ""
                            }`}
                            onClick={() => {
                              if (!selectedJob?.recruiter?.deleteStatus) {
                                handleRecruiterInfoClick();
                              }
                            }}
                            disabled={
                              selectedJob?.recruiter?.deleteStatus
                                ? true
                                : false
                            }
                          >
                            {selectedJob?.recruiter?.deleteStatus ? (
                              <>
                                <RedInfoIcon />
                                Recruiter is not available. Please select
                                "Assign a new Recruiter"
                              </>
                            ) : (
                              <>
                                <InfoIcon />
                                View Recruiter’s info
                                <LinkIcon />
                              </>
                            )}
                          </button>
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button
                  type="button"
                  className="btn btn__white"
                  onClick={() => {
                    closeModal();
                    setRecruiterCheckboxValue("");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn__primary"
                  onClick={() => onSubmit()}
                  disabled={RecruiterCheckboxValue === "" ? true : false}
                >
                  Reopen Job
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default RecruiterReassignModal;
