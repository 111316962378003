import ReopenAJobPost from "@/rtk-apis/customer/myJobs/reopenAJobPost";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const ReopenAJobPostSlice = createSlice({
  name: "addJobPost",
  initialState,
  reducers: {
    closeReopenJobModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ReopenAJobPost.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(ReopenAJobPost.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(ReopenAJobPost.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default ReopenAJobPostSlice.reducer;

export const { closeReopenJobModal } = ReopenAJobPostSlice.actions;
