import React, { useEffect, useRef, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import staticData from "@/rtk-apis/staticData";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import deactivateRecruiter from "@/rtk-apis/recruiterProfile/deactivateRecruiter";
import { GetUserDetails } from "@/utils/helper";
import { SuccessModal } from "@/components/modals/SuccessModal";
import { closeRtkModal } from "@/store/slices/recruiterProfile/deactivateRecruiterSlice";
import ErrorModal from "@/components/modals/error-modal";
import Loader from "@/elements/Loader";
import ConfirmationModal from "@/components/modals/confirmation-modal";

export const RecruiterProfileDeactivateModal = ({
  openModal,
  closeModal,
  refreshProfile,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const userDetails = GetUserDetails();

  const [checkboxValue, setCheckboxValue] = useState({});
  const [explainationtext, setExplainationtext] = useState("");
  const [textareaError, setTextareaError] = useState("");
  const [deactivateConfModal, setDeactivateConfModal] = useState(false);

  const { data, loading } = useSelector((globalState) => {
    return globalState.static;
  });
  const deactivateRecruiterData = useSelector((globalState) => {
    return globalState.deactivateRecruiter;
  });

  useEffect(() => {
    dispatch(staticData());
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [closeModal]);

  const handleCheckboxChange = (e, item) => {
    const checkboxVal = {
      id: item?.id,
      value: item?.value,
    };
    setCheckboxValue(checkboxVal);
    if (item?.value === "Other") {
      setTextareaError("Reason is required.");
    } else {
      setTextareaError("");
    }
  };

  const handleTextAreaChange = (e) => {
    setExplainationtext(e?.target?.value);
    if (checkboxValue?.value === "Other" && e?.target?.value === "") {
      setTextareaError("Reason is required.");
    } else {
      setTextareaError("");
    }
  };

  const handleDeactivateRecruiter = () => {
    const body = {
      option: checkboxValue,
      reason: explainationtext,
      recruiterId: userDetails?.recruiter?.recruiterId,
      from: "Recruiter",
    };
    dispatch(deactivateRecruiter(body));
    setDeactivateConfModal(false);
  };

  const closeSuccessModal = () => {
    dispatch(closeRtkModal());
    setCheckboxValue({});
    setExplainationtext("");
    setTextareaError("");
    closeModal();
    refreshProfile();
  };

  const handleCancel = () => {
    closeModal();
    setCheckboxValue({});
    setExplainationtext("");
    setTextareaError("");
  };

  return (
    <>
      <div
        className={`modal modalSuccessfull ${
          openModal && !loading ? "modal--open" : ""
        }`}
      >
        <div className="modal__dialog">
          <div className="modal__content lg-modal">
            <div className="modal__body modal-body-left">
              <h2 className="modal__header--title bg-red">
                Reason for leaving
              </h2>

              <div className="formField">
                {data?.deactivateAccountOption?.map((item, index) => {
                  return (
                    <div className="formField__checkbox" key={index}>
                      <FormControlLabel
                        className="pb15"
                        onChange={(e) => handleCheckboxChange(e, item)}
                        checked={checkboxValue?.id === item?.id ? true : false}
                        control={<Checkbox />}
                      />
                      <span className="pl5">{item?.value}</span>
                    </div>
                  );
                })}
              </div>
              <div className={`formField ${textareaError ? "fieldError" : ""}`}>
                <label className="formField__label">
                  Please Explain Further{" "}
                  <span style={{ opacity: ".5" }}>
                    {`${
                      Object.keys(checkboxValue)?.length &&
                      checkboxValue["value"] !== "Other"
                        ? "(Optional)"
                        : ""
                    }`}
                  </span>
                </label>
                <textarea
                  className="formField__textarea"
                  value={explainationtext}
                  onChange={(e) => handleTextAreaChange(e)}
                  rows={5}
                  maxLength={300}
                  placeholder="Enter your note here..."
                ></textarea>
                <span className="formField__error">{textareaError}</span>
              </div>
              <div className="btn__group flex--start">
                <button className="btn btn__white" onClick={handleCancel}>
                  Cancel
                </button>
                <button
                  className="btn btn__primary"
                  disabled={!checkboxValue.id || textareaError}
                  onClick={() => setDeactivateConfModal(true)}
                >
                  Deactivate
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      {deactivateRecruiterData?.loading && <Loader />}
      {deactivateRecruiterData?.meta?.status === true && (
        <SuccessModal
          closeModal={closeSuccessModal}
          sucessfullMsg={deactivateRecruiterData?.meta?.msg}
          Heading={"Deactivate request sent!"}
        />
      )}
      {deactivateRecruiterData?.meta?.status === false && (
        <ErrorModal
          errorMessage={deactivateRecruiterData?.meta?.msg}
          closeModal={closeSuccessModal}
          footerVisible={true}
        />
      )}
      {deactivateConfModal && (
        <ConfirmationModal
          closeModal={() => setDeactivateConfModal(false)}
          onSubmit={handleDeactivateRecruiter}
          heading={"Deactivate Account!"}
          confirmationMessage={
            "Are you sure you want to send request to deactivate your account?"
          }
        />
      )}
    </>
  );
};
