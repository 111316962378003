import React, { useState } from "react";
import CandidatesListing from "@/customer-pages/MyJobs/Candidates/CandidatesListing";

function Candidates({ selectedJobData, changeCandidateViewType }) {
  return (
    <>
      <CandidatesListing
        changeCandidateViewType={changeCandidateViewType}
        selectedJobData={selectedJobData}
      />
    </>
  );
}

export default Candidates;
