import { io } from "socket.io-client";

let socket;

export const getSocket = () => {
  if (!socket) {
    socket = io("http://api.dev-app.laila.co/", { query: {} });
  }
  return socket;
};
