import React, { useState } from "react";
import SideBar from "@/components/layout/SideBar";
import InterviewsListing from "@/pages/Interviews/InterviewsListing";
import InterviewsDetails from "@/pages/Interviews/InterviewsDetails";
import RecordingInterviews from "@/pages/Interviews/RecordingInterviews";

function Interviews() {
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState("1");
  const [selectedRecruiterInterview, setSelectedRecruiterInterview] = useState(
    {}
  );
  const [responsive, setResponsive] = useState(false);
  const [recordingPage, setRecordingPage] = useState(false);
  const [selectedInterviewForRecording, setsSlectedInterviewForRecording] =
    useState(null);

  const changeIndex = (index, candidate, makeResponsive) => {
    if (makeResponsive) {
      setResponsive(true);
    }
    setSelectedCandidate(candidate);
    setSelectedIndex(index);
  };

  const selectedInterviewForRecruter = (index, value) => {
    setSelectedRecruiterInterview(value);
    setSelectedIndex(index);
  };
  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid">
          <InterviewsListing
            changeIndex={(index, candidate, makeResponsive) =>
              changeIndex(index, candidate, makeResponsive)
            }
            selectedCandidate={selectedCandidate}
            selectedIndex={selectedIndex}
            changeTab={(value) => setSelectedTab(value)}
            selectedRecruiterInterview={(index, value) =>
              selectedInterviewForRecruter(index, value)
            }
          />
          <div
            className={`layoutGrid__col layoutGrid--responsive ${
              responsive ? "layoutGrid--show" : ""
            }`}
          >
            {recordingPage ? (
              <RecordingInterviews
                hideDetail={() => {
                  setRecordingPage(false);
                }}
                selectedInterview={selectedInterviewForRecording}
                selectedCandidate={selectedCandidate}
              />
            ) : (
              <InterviewsDetails
                openRecordingPage={(value) => {
                  setRecordingPage(true);
                  setsSlectedInterviewForRecording(value);
                }}
                selectedCandidate={selectedCandidate}
                selectedTab={selectedTab}
                selectedRecruiterInterview={selectedRecruiterInterview}
                removeResponsiveClass={() => setResponsive(false)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Interviews;
