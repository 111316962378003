import { createSlice } from '@reduxjs/toolkit';
import signUpCustomer from '@/rtk-apis/customer/signUpCustomer';

const initialState = {
  message: '',
  data: {},
  meta: {},
  token: '',
  loading: false,
  error: ''
};

const signUpCustomerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    closeRtkModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUpCustomer.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(signUpCustomer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(signUpCustomer.rejected, (state, action) => {
        state.loading = false;
      });
  }
});

export default signUpCustomerSlice.reducer;

export const { closeRtkModal } = signUpCustomerSlice.actions;
