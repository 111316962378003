import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LinkIcon from "@/assets/svgs/LinkIcon";
import EditIconNew from "@/assets/svgs/EditIconNew";
import DeleteIcon from "@/assets/svgs/DeleteIcon";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { firstLetterCapital } from "@/utils/helper";
import { useSelector, useDispatch } from "react-redux";
import getJobData from "@/rtk-apis/customer/myJobs/getJobData";
import ErrorModal from "@/components/modals/error-modal";
import Loader from "@/elements/Loader";
import { SuccessModal } from "@/components/modals/SuccessModal";
import AddNotesModal from "@/components/modals/AddNotesModal";
import {
  GetUserDetails,
  checkFor_CompanyAdmin_JobCreater_HiringLead,
  checkForCompanyAdmin_HiringLead_JTM_JobCreater,
} from "@/utils/helper";
import addCustomerNotes from "@/rtk-apis/customer/myJobs/notes/addCustomerNotes";
import { closeAddNoteModal } from "@/store/slices/customer/myJobs/notes/addCustomerNotesSlice";
import { closeEditNoteModal } from "@/store/slices/customer/myJobs/notes/editCustomerNotesSlice";
import editCustomerNotes from "@/rtk-apis/customer/myJobs/notes/editCustomerNotes";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import deleteCustomerNotes from "@/rtk-apis/customer/myJobs/notes/deleteCustomerNotes";
import { closeDeleteNoteModal } from "@/store/slices/customer/myJobs/notes/deleteCustomerNotesSlice";

function JobDetails({
  selectedJobData,
  setOpenRecruiterDetailPage,
  joblistlength,
}) {
  const userDetails = GetUserDetails();
  const role = userDetails?.customer?.role;
  const dispatch = useDispatch();
  const [OpenAddEditNotesModal, setOpenAddEditNotesModal] = useState(false);
  const [OpenConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [EditNotesMode, setEditNotesMode] = useState(false);
  const [NotesTitle, setNotesTitle] = useState("");
  const [SelectedShareWith, setSelectedShareWith] = useState([]);
  const [NotesText, setNotesText] = useState("");
  const [EditNoteId, setEditNoteId] = useState("");
  const [DeleteNoteId, setDeleteNoteId] = useState("");
  const [AddCollapseClassToNotes, setAddCollapseClassToNotes] = useState(false);
  const [AddCollapseClassToDescp, setAddCollapseClassToDescp] = useState(false);
  const [ShareWith, setShareWith] = useState([]);

  const { data, loading } = useSelector((globalState) => {
    return globalState.getJobDataRes;
  });
  const EditNotesResponse = useSelector((globalState) => {
    return globalState.editCustomerNotesRes;
  });
  const DeleteNotesResponse = useSelector((globalState) => {
    return globalState.deleteCustomerNotesRes;
  });
  const AddNotesResponse = useSelector((globalState) => {
    return globalState.addCustomerNotesRes;
  });

  useEffect(() => {
    if (selectedJobData?.jobId) {
      getJobDetails();
    }
  }, [
    AddNotesResponse?.meta?.status,
    EditNotesResponse?.meta?.status,
    DeleteNotesResponse?.meta?.status,
    selectedJobData?.jobId,
  ]);

  useEffect(() => {
    setOpenAddEditNotesModal(false);
    setSelectedShareWith([]);
  }, [AddNotesResponse?.meta?.status, EditNotesResponse?.meta?.status]);

  useEffect(() => {
    setOpenConfirmationModal(false);
  }, [DeleteNotesResponse?.meta?.status]);

  useEffect(() => {
    if (data && Object.keys(data)?.length > 0) {
      let tempArr = [
        {
          id: "Laila Team",
          value: "Laila Team",
        },
      ];
      data?.jobTeamMember.map((t) => {
        tempArr.push({
          id: t.employeeId,
          value: `${firstLetterCapital(t.firstName)} ${firstLetterCapital(
            t.lastName
          )}`,
        });
      });
      data?.hiringLead.map((t) => {
        tempArr.push({
          id: t.employeeId,
          value: `${firstLetterCapital(t.firstName)} ${firstLetterCapital(
            t.lastName
          )}`,
        });
      });
      if (data?.assignedId) {
        tempArr.push({
          id: data?.assignedId,
          value: `Recruiter - ${firstLetterCapital(
            data?.recruiter?.firstName
          )} ${firstLetterCapital(data?.recruiter?.lastName)}`,
        });
      }
      // tempArr.push({
      //   id: data?.employeeId,
      //   value: `${data?.employee?.firstName} ${data?.employee?.lastName}`,
      // });
      let res = Array.from(new Set(tempArr.map(JSON.stringify))).map(
        JSON.parse
      );
      setShareWith(res);
    }
  }, [data]);

  const getJobDetails = () => {
    let jobid = selectedJobData?.jobId;
    dispatch(getJobData(jobid));
  };

  const handleOnAddEditNotes = () => {
    if (!EditNotesMode) {
      const body = {
        title: NotesTitle,
        description: NotesText,
        jobId: selectedJobData?.jobId,
        addedBy: userDetails?.customer?.employeeId,
        from: "Customer",
        shareWith: SelectedShareWith,
      };
      dispatch(addCustomerNotes(body));
    } else {
      const body = {
        notesId: EditNoteId,
        title: NotesTitle,
        description: NotesText,
        jobId: selectedJobData?.jobId,
        from: "Customer",
        updatedById: userDetails?.customer?.employeeId,
        shareWith: SelectedShareWith,
      };
      dispatch(editCustomerNotes(body));
    }
  };

  const handleOpenDeleteConfModal = (note) => {
    setDeleteNoteId(note?.id);
    setOpenConfirmationModal(true);
  };

  const handleDeleteNote = () => {
    const body = {
      notesId: DeleteNoteId,
      jobId: selectedJobData?.jobId,
    };
    dispatch(deleteCustomerNotes(body));
  };

  const resetAddNoteModal = () => {
    setNotesTitle("");
    setNotesText("");
    setEditNotesMode(false);
    setSelectedShareWith([]);
  };

  const handleOpenAddNoteModal = () => {
    setOpenAddEditNotesModal(true);
  };

  const handleOpenEditNotesModal = (note) => {
    setOpenAddEditNotesModal(true);
    setEditNotesMode(true);
    setEditNoteId(note?.id);
    setNotesText(note?.description);
    setNotesTitle(note?.title);
    setSelectedShareWith(note?.shareWith);
  };

  const handleCloseModal = () => {
    resetAddNoteModal();
    setOpenAddEditNotesModal(false);
  };

  const closeAddEditNoteRtkModal = () => {
    if (EditNotesMode) {
      dispatch(closeEditNoteModal());
    } else {
      dispatch(closeAddNoteModal());
    }
    resetAddNoteModal();
  };

  const closeDeleteRtkModal = () => {
    dispatch(closeDeleteNoteModal());
  };

  const handleRecruiterClick = () => {
    setOpenRecruiterDetailPage(true);
  };

  const getCompensationValue = (job) => {
    if (job?.baseSalary === "Range") {
      return `$ ${job?.minCompensation} - $ ${job?.maxCompensation} ${job?.frequency}`;
    } else if (job?.baseSalary === "Starting at") {
      return `$ ${job?.minCompensation} ${job?.frequency}`;
    } else if (job?.baseSalary === "Up to") {
      return `$ ${job?.maxCompensation} ${job?.frequency}`;
    } else if (job?.baseSalary === "Exact Pay") {
      return `$ ${job?.maxCompensation} ${job?.frequency}`;
    } else {
      return `${"--"}`;
    }
  };

  return (
    <>
      {joblistlength ? (
        <div className="details">
          <section className="form__section form__section--accordion active bxshadow">
            <h4 className="details__section--title">Job Details</h4>
            <div className="fieldBox flexRow">
              <div className="flexCol">
                <label className="flexCol__label">Job Title</label>
                <div className="flexCol__text">
                  {data?.title ? data?.title : "--"}
                </div>
              </div>
              <div className="flexCol">
                <label className="flexCol__label">Department</label>
                <div className="flexCol__text">
                  {data?.department ? data?.department : "--"}
                </div>
              </div>
            </div>
            <div className="fieldBox flexRow">
              <div className="flexCol">
                <label className="flexCol__label">Location</label>
                <div className="flexCol__text">
                  {data?.location ? data?.location : "--"}
                </div>
              </div>
              <div className="flexCol">
                <label className="flexCol__label">Employment Type</label>
                <div className="flexCol__text">
                  {data?.employmentType
                    ? data?.employmentType.join(", ")
                    : "--"}
                </div>
              </div>
            </div>
            <div className="fieldBox flexRow">
              <div className="flexCol">
                <label className="flexCol__label">Base Salary</label>
                <div className="flexCol__text">
                  {getCompensationValue(data)}
                </div>
              </div>
              <div className="flexCol">
                <label className="flexCol__label">Application Deadline</label>
                <div className="flexCol__text">
                  {data?.applicationDeadline
                    ? moment(data?.applicationDeadline).format("MMM D, YYYY")
                    : ""}
                </div>
              </div>
            </div>
            <div className="fieldBox flexRow">
              <div className="flexCol">
                <label className="flexCol__label">Experience</label>
                <div className="flexCol__text">
                  {data?.minExperience <= 1
                    ? data?.minExperience + " Year"
                    : data?.minExperience + " Years"}{" "}
                  -{" "}
                  {data?.maxExperience <= 1
                    ? data?.maxExperience + " Year"
                    : data?.maxExperience + " Years"}
                </div>
              </div>
              <div className="flexCol">
                <label className="flexCol__label">Required Qualification</label>
                <div className="flexCol__text">
                  {data?.qualification ? data?.qualification.join(", ") : "--"}
                </div>
              </div>
            </div>
            <div className="fieldBox flexRow">
              <div className="flexCol">
                <label className="flexCol__label">Hiring Lead</label>
                <div className="tags__list--item">
                  {data?.hiringLead?.length > 0
                    ? firstLetterCapital(data?.hiringLead[0]?.firstName) +
                      " " +
                      firstLetterCapital(data?.hiringLead[0]?.lastName)
                    : "--"}
                </div>
              </div>
              <div className="flexCol">
                <label className="flexCol__label">Assigned Recruiter</label>
                <div
                  className="tags__list--item"
                  onClick={() => {
                    if (
                      data?.recruiter &&
                      data?.recruiterStatus === "accepted"
                    ) {
                      handleRecruiterClick();
                    }
                  }}
                >
                  <Link to="#" className="flexCol__text text--primary">
                    {data?.recruiter && data?.recruiterStatus === "accepted"
                      ? firstLetterCapital(data?.recruiter?.firstName) +
                        " " +
                        firstLetterCapital(data?.recruiter?.lastName)
                      : "--"}
                    {data?.recruiter &&
                      data?.recruiterStatus === "accepted" && <LinkIcon />}
                  </Link>
                </div>
              </div>
            </div>
            <div className="fieldBox flexRow">
              <div className="flexCol">
                <label className="flexCol__label">Job Team Member</label>
                <ul className="tags__list m_0">
                  {data?.jobTeamMember?.length &&
                    data?.jobTeamMember?.map((member, index) => {
                      return (
                        <li className="tags__list--item" key={index}>
                          {firstLetterCapital(member?.firstName)}{" "}
                          {firstLetterCapital(member?.lastName)}
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="flexCol">
                <label className="flexCol__label">No of Hires</label>
                <div className="flexCol__text">
                  {data?.noOfHires ? data?.noOfHires : "--"}
                </div>
              </div>
            </div>
            <div className="fieldBox">
              <label className="flexCol__label">Skill set</label>
              <div className="flexCol__text">{data?.skillSet?.join(", ")}</div>
            </div>
          </section>
          {/* JOB DESCRIPTION */}
          <section
            className={`form__section form__section--accordion ${
              AddCollapseClassToDescp ? "active" : ""
            } bxshadow`}
          >
            <h4 className="form__section--title">
              <span
                className="title"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  setAddCollapseClassToDescp(!AddCollapseClassToDescp)
                }
              >
                Job Description
              </span>
              <span
                className="icon"
                onClick={() =>
                  setAddCollapseClassToDescp(!AddCollapseClassToDescp)
                }
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 10L11 14L15 10M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                    stroke="#6C737F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </h4>
            <div className="accordionData">
              <div className="formRow">
                <div className="formCol">
                  <div className="formField">
                    <ReactQuill
                      name="description"
                      value={data?.description}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* NOTES */}
          {checkForCompanyAdmin_HiringLead_JTM_JobCreater(
            selectedJobData,
            role
          ) && (
            <section
              className={`form__section form__section--accordion mb_45 ${
                AddCollapseClassToNotes ? "active" : ""
              }  bxshadow`}
            >
              <h4 className="form__section--title">
                <span
                  className="title"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setAddCollapseClassToNotes(!AddCollapseClassToNotes)
                  }
                >
                  Notes
                </span>
                <span
                  className="icon"
                  onClick={() =>
                    setAddCollapseClassToNotes(!AddCollapseClassToNotes)
                  }
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 10L11 14L15 10M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                      stroke="#6C737F"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </h4>
              <div className="accordionData">
                <div className="formRow">
                  {checkFor_CompanyAdmin_JobCreater_HiringLead(
                    selectedJobData,
                    role
                  ) && (
                    <button
                      className="btn btn__primary"
                      onClick={handleOpenAddNoteModal}
                      type="button"
                    >
                      Add Note
                    </button>
                  )}
                </div>
                <div className="notesList">
                  {data?.notes?.length
                    ? [
                        ...data.notes?.filter((item) => {
                          return (
                            item.shareWith &&
                            item.shareWith.some((share) => {
                              return (
                                share?.id ===
                                  userDetails?.customer?.employeeId ||
                                userDetails?.customer?.employeeId ===
                                  item?.addedBy ||
                                role === "CompanyAdmin"
                              );
                            })
                          );
                        }),
                      ]
                        ?.sort(
                          (a, b) =>
                            new Date(b.createdTime) - new Date(a.createdTime)
                        )
                        ?.filter(
                          (note) =>
                            note?.from === "Customer" ||
                            note?.from === "customer"
                        )
                        .map((item, index) => {
                          return (
                            <div className="noteCard" key={index}>
                              <h3 className="title">
                                {item?.title}
                                <div className="btn__group">
                                  {checkFor_CompanyAdmin_JobCreater_HiringLead(
                                    selectedJobData,
                                    role
                                  ) && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn p_0 border_0"
                                        onClick={() =>
                                          handleOpenEditNotesModal(item)
                                        }
                                      >
                                        <EditIconNew />
                                      </button>
                                      <button
                                        type="button"
                                        className="btn p_0 border_0"
                                        onClick={() =>
                                          handleOpenDeleteConfModal(item)
                                        }
                                      >
                                        <DeleteIcon />
                                      </button>
                                    </>
                                  )}
                                </div>
                              </h3>
                              <p>
                                <div
                                  key={new Date().getTime()}
                                  className="showQuillTags"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description
                                      ? item?.description
                                      : "--",
                                  }}
                                ></div>
                              </p>
                            </div>
                          );
                        })
                    : ""}
                </div>
              </div>
            </section>
          )}
        </div>
      ) : (
        <div className="no-data floating">No Results Found!</div>
      )}
      <AddNotesModal
        openModal={OpenAddEditNotesModal}
        closeModal={handleCloseModal}
        setNotesTitle={setNotesTitle}
        NotesTitle={NotesTitle}
        setNotesText={setNotesText}
        NotesText={NotesText}
        handleOnAddEditNotes={handleOnAddEditNotes}
        EditNotesMode={EditNotesMode}
        shareWithArray={ShareWith}
        setSelectedShareWith={setSelectedShareWith}
        selectedShareWithArray={SelectedShareWith}
        jobData={data}
      />
      {(AddNotesResponse?.meta?.status || EditNotesResponse?.meta?.status) && (
        <SuccessModal
          closeModal={closeAddEditNoteRtkModal}
          sucessfullMsg={
            EditNotesMode
              ? EditNotesResponse?.meta?.msg
              : AddNotesResponse?.meta?.msg
          }
          Heading={EditNotesMode ? "Note Updated!" : "Note Added!"}
        />
      )}
      {(AddNotesResponse?.meta?.status === false ||
        EditNotesResponse?.meta?.status === false) && (
        <ErrorModal
          errorMessage={
            EditNotesMode
              ? EditNotesResponse?.meta?.msg
              : AddNotesResponse?.meta?.msg
          }
          closeModal={closeAddEditNoteRtkModal}
          footerVisible={true}
        />
      )}
      {OpenConfirmationModal && (
        <ConfirmationModal
          closeModal={() => setOpenConfirmationModal(false)}
          onSubmit={handleDeleteNote}
          heading={"Delete Note!"}
          confirmationMessage={"Are you sure want to delete note?"}
        />
      )}
      {DeleteNotesResponse?.meta?.status && (
        <SuccessModal
          closeModal={closeDeleteRtkModal}
          sucessfullMsg={DeleteNotesResponse?.meta?.msg}
          Heading={"Note Deleted!"}
        />
      )}
      {loading && <Loader />}
    </>
  );
}

export default JobDetails;
