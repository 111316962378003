import { configureStore } from "@reduxjs/toolkit";
import authSlice from "@/store/slices/AuthSlice";
import ForgotPasswordSlice from "@/store/slices/ForgotPasswordSlice";
import ResetPasswordSlice from "@/store/slices/ResetPasswordSlice";
import signInSlice from "@/store/slices/SignInSlice";
import StaticDataSlice from "@/store/slices/StaticDataSlice";
import jobRequestsListSlice from "@/store/slices/jobRequestsListSlice";
import jobAcceptDeclineSlice from "@/store/slices/jobAcceptDeclineSlice";
import candidatesListSlice from "@/store/slices/candidates/candidatesListSlice";
import addCandidateSlice from "@/store/slices/candidates/addCandidateSlice";
import updateCandidateSlice from "@/store/slices/candidates/updateCandidateSlice";
import updateRecruiterSlice from "@/store/slices/recruiterProfile/updateRecruiterSlice";
import changePasswordSlice from "@/store/slices/changePasswordSlice";
import deleteCandidateSlice from "@/store/slices/candidates/deleteCandidateSlice";
import myJobsListSlice from "@/store/slices/myJobs/myJobsListSlice";
import myJobsListCustomerSlice from "@/store/slices/customer/myJobs/myJobsListCustomerSlice";
import downloadJDSlice from "@/store/slices/myJobs/downloadJDSlice";
import scheduledInterviewsSlice from "@/store/slices/myJobs/scheduledInterviewsSlice";
import approvedCandidatesListSlice from "@/store/slices/myJobs/approvedCandidatesListSlice";
import candidateApproveRejectSlice from "@/store/slices/myJobs/candidateApproveRejectSlice";
import feedbackQuestionsSlice from "@/store/slices/myJobs/feedbackQuestionsSlice";
import interviewListSlice from "@/store/slices/interviews/interviewListSlice";
import createInterviewSlice from "@/store/slices/interviews/createInterviewSlice";
import checkSchedulePageSlice from "@/store/slices/interviews/checkSchedulePageSlice";

import signUpCustomerSlice from "@/store/slices/customer/SignUpCustomerSlice";
import candidateListSlice from "@/store/slices/customer/myJobs/candidates/candidateListSlice";
import getCustomersEmployeeListSlice from "@/store/slices/customer/myJobs/getCustomersEmployeeListSlice";
import addJobPostSlice from "@/store/slices/customer/myJobs/addJobPostSlice";
import candidateDetailSlice from "@/store/slices/customer/myJobs/candidates/candidateDeatailSlice";
import candidateRecordingListSlice from "@/store/slices/customer/myJobs/candidates/candidateRecordingListSlice";
import deleteCustomerJobPostSlice from "@/store/slices/customer/myJobs/deleteCustomerJobPostSlice";
import shortlistedcandidatesSlice from "@/store/slices/customer/myJobs/shortlistedCandidates/shortlistedcandidatesSlice";
import approveRejectInterviewCandidateSlice from "@/store/slices/customer/myJobs/interview/approveRejectInterviewCandidateSlice";
import getApprovedCandidatesListSlice from "@/store/slices/customer/myJobs/approvedCandidates/approvedCandidatesListSlice";
import getFeedbackQuestionListSlice from "@/store/slices/customer/myJobs/shortlistedCandidates/feedbackQuestionsListSlice";
import candidatesInterviewsListSlice from "@/store/slices/customer/interviews/candidatesInterviewsListSlice";
import customerCandidatesInterviewDataSlice from "@/store/slices/customer/interviews/customerCandidateInterviewDataSlice";
import myJobInterviewListSlice from "@/store/slices/customer/myJobs/interview/interviewListSlice";
import getCompanyProfileDetailsSlice from "@/store/slices/customer/companyProfile/getCompanyProfileDetailsSlice";
import getStateCityDataSlice from "@/store/slices/customer/companyProfile/getStateCitySlice";
import getCityDataSlice from "@/store/slices/customer/companyProfile/getCityDataSlice";
import uploadLogoSlice from "@/store/slices/customer/companyProfile/uploadLogoSlice";
import updateCompanyProfileSlice from "@/store/slices/customer/companyProfile/updateCompanyDetailsSlice";
import getDashboardDataSlice from "@/store/slices/customer/dashboard/getDashboardDataSlice";
import updateMyProfileSlice from "@/store/slices/customer/myProfile/updateMyProfileSlice";
import customerEmployeeDetailSlice from "@/store/slices/customer/myProfile/customerEmployeeDetailSlice";
import checkMeetingSlice from "@/store/slices/customer/myJobs/interview/checkMeetingSlice";
import joinMeetingSlice from "@/store/slices/customer/myJobs/interview/joinMeetingSlice";
import recruiterDashboardSlice from "@/store/slices/recruiterDashboard/recruiterDashboardSlice";
import billingJobsListingSlice from "@/store/slices/customer/billingAndPayments/billingJobsListingSlice";
import billingCandidatesListingSlice from "@/store/slices/customer/billingAndPayments/billingCandidatesListingSlice";
import billingPaymentSuccessSlice from "@/store/slices/customer/billingAndPayments/billingPaymentSuccessSlice";
import billingConfirmPaymentSlice from "@/store/slices/customer/billingAndPayments/billingConfirmPaymentSlice";
import candidateInterviewsSlice from "@/store/slices/interviews/candidateInterviewsSlice";
import candidateResumeUploadSlice from "@/store/slices/candidates/candidateResumeUploadSlice";
import billingCancelPaymentSlice from "@/store/slices/customer/billingAndPayments/billingCancelPaymentSlice";
import getSearchFieldSlice from "@/store/slices/customer/myJobs/getSearchFieldSlice";
import recruiterDetailsOnSignUpSlice from "@/store/slices/signUp/recruiterDetailsOnSignUpSlice";
import recruiterDetailsSlice from "@/store/slices/recruiterProfile/recruiterDetailsSlice";
import interviewDetailPageDataSlice from "@/store/slices/customer/myJobs/interview/interviewDetailPageDataSlice";
import fetchRecordingSlice from "@/store/slices/interviews/fetchRecordingSlice";
import getCompanyEmployeeListSlice from "@/store/slices/customer/companyProfile/getCompanyEmployeeListSlice";
import getCompanyEmployeeJobsSlice from "@/store/slices/customer/companyProfile/getCompanyEmployeeJobsSlice";
import companyDeactivateAccountSlice from "@/store/slices/customer/companyProfile/companyDeactivateAccountSlice";
import profileDeactivateSlice from "@/store/slices/customer/myProfile/profileDeactivateSlice";
import cancelCompanyDeactivateRequestSlice from "@/store/slices/customer/companyProfile/cancelCompanyDeactivateRequestSlice";
import addEducationRecruiterSlice from "@/store/slices/recruiterProfile/addEducationRecruiterSlice";
import pauseAjobPostSlice from "@/store/slices/customer/myJobs/pauseAjobPostSlice";
import reopenAJobPostSlice from "@/store/slices/customer/myJobs/reopenAJobPostSlice";
import taxInformationSlice from "@/store/slices/tax/taxInformationSlice";
import updateTaxResidenceInfoSlice from "@/store/slices/tax/updateTaxResidenceInfoSlice";
import updateTaxIdentificationInfoSlice from "@/store/slices/tax/updateTaxIdentificationInfoSlice";
import deactivateRecruiterSlice from "@/store/slices/recruiterProfile/deactivateRecruiterSlice";
import cancelDeactivateRecruiterSlice from "@/store/slices/recruiterProfile/cancelDeactivateRecruiterSlice";
import recruiterJobListSlice from "@/store/slices/recruiterBillingPayment/recruiterJobListSlice";
import recruiterCandidateListSlice from "@/store/slices/recruiterBillingPayment/recruiterCandidateListSlice";
import paymentInformationSlice from "@/store/slices/payments/paymentInformationSlice";
import addBankDetailsSlice from "@/store/slices/payments/addBankDetailsSlice";
import updateBankDetailsSlice from "@/store/slices/payments/updateBankDetailsSlice";
import deleteBankDetailsSlice from "@/store/slices/payments/deleteBankDetailsSlice";
import addWithdrawlDetailsSlice from "@/store/slices/payments/addWithdrawlDetailsSlice";
import addPaypalDetailsSlice from "@/store/slices/payments/addPaypalDetailsSlice";
import customerAddEmployeeSlice from "@/store/slices/customer/companyProfile/customerAddEmployeeSlice";
import customerEditEmployeeSlice from "@/store/slices/customer/companyProfile/customerEditEmployeeSlice";
import customerActivateEmployeeSlice from "@/store/slices/customer/companyProfile/customerActivateEmployeeSlice";
import customerDeactivateEmployeeSlice from "@/store/slices/customer/companyProfile/customerDeactivateEmployeeSlice";
import addACHDebitDetailsSlice from "@/store/slices/customer/payments/addACHDebitDetailsSlice";
import editACHDebitDetailsSlice from "@/store/slices/customer/payments/editACHDebitDetailsSlice";
import removeACHDebitDetailsSlice from "@/store/slices/customer/payments/removeACHDebitDetailsSlice";
import getpaymentMethodsSlice from "@/store/slices/customer/payments/getpaymentMethodsSlice";
import setPrimaryDebitMethodSlice from "@/store/slices/customer/payments/setPrimaryDebitMethodSlice";
import addReviewsRatingsToRecruiterSlice from "@/store/slices/customer/myJobs/reviewsRatings/addReviewsRatingsToRecruiterSlice";
import getRecruiterDetailsSlice from "@/store/slices/customer/myJobs/getRecruiterDetailsSlice";
import addNPSRatingSlice from "@/store/slices/customer/myJobs/reviewsRatings/addNPSRatingSlice";
import addCreditCardDetailsSlice from "@/store/slices/customer/payments/addCreditCardDetailsSlice";
import verifyPaymentMethodSlice from "@/store/slices/customer/payments/verifyPaymentMethodSlice";
import getCardTypeSlice from "@/store/slices/customer/payments/getCardTypeSlice";
import updateReviewsRatingsSlice from "@/store/slices/customer/myJobs/reviewsRatings/updateReviewsRatingsSlice";
import removeTagDetailsSlice from "@/store/slices/customer/tags/removeTagDetailsSlice";
import getAllTagsListSlice from "@/store/slices/customer/tags/getAllTagsListSlice";
import addTagToCandidateSlice from "@/store/slices/customer/tags/addTagToCandidateSlice";
import editCustomerNotesSlice from "@/store/slices/customer/myJobs/notes/editCustomerNotesSlice";
import deleteCustomerNotesSlice from "@/store/slices/customer/myJobs/notes/deleteCustomerNotesSlice";
import addCustomerNotesSlice from "@/store/slices/customer/myJobs/notes/addCustomerNotesSlice";
import getCustomerNotesSlice from "@/store/slices/customer/myJobs/notes/getCustomerNotesSlice";
import addTagsToJobSlice from "@/store/slices/customer/tags/jobTagsSlice/addTagsToJobSlice";
import removeJobTagsSlice from "@/store/slices/customer/tags/jobTagsSlice/removeJobTagsSlice";
import getJobDataSlice from "@/store/slices/customer/myJobs/getJobDataSlice";
import getCustomerAllTagsSlice from "@/store/slices/customer/settings/customerTags/getCustomerAllTagsSlice";
import addTagsToCustomerSlice from "@/store/slices/customer/settings/customerTags/addTagsToCustomerSlice";
import updateCustomerTagsSlice from "@/store/slices/customer/settings/customerTags/updateCustomerTagsSlice";
import activateDeactivateCustomerTagsSlice from "@/store/slices/customer/settings/customerTags/activateDeactivateCustomerTagsSlice";
import getAllNotificationsListSlice from "@/store/slices/customer/notifications/getAllNotificationsListSlice";
import markNotificationAsReadSlice from "@/store/slices/customer/notifications/markNotificationAsReadSlice";
import deleteNotificationsSlice from "@/store/slices/customer/notifications/deleteNotificationsSlice";
import NewCustomerSignUpSlice from "@/store/slices/customer/NewCustomerSignUpSlice";
import candidateSignUpSlice from "@/store/slices/candidateSlices/candidateSignUpSlice";
import candidateSignUpStepTwoSlice from "@/store/slices/candidateSlices/candidateSignUpStepTwoSlice";
import socialSignInSlice from "@/store/slices/socialSignInSlice";
import socialSignInStepTwoSlice from "@/store/slices/socialSignInStepTwoSlice";
import addCompanyOnSocialSignInSlice from "@/store/slices/addCompanyOnSocialSignInSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    static: StaticDataSlice,
    signIn: signInSlice,
    socialSignInRes: socialSignInSlice,
    socialSignInStepTwoRes: socialSignInStepTwoSlice,
    addCompanyOnSocialSignInRes:addCompanyOnSocialSignInSlice,
    forgotPassword: ForgotPasswordSlice,
    resetPassword: ResetPasswordSlice,

    jobRequestsListData: jobRequestsListSlice,
    jobAcceptDecline: jobAcceptDeclineSlice,
    candidatesListData: candidatesListSlice,
    addCandidateResponse: addCandidateSlice,
    updateCandidateResponse: updateCandidateSlice,
    updateRecruiterResponse: updateRecruiterSlice,
    changePasswordResponse: changePasswordSlice,
    deleteCandidateResponse: deleteCandidateSlice,
    myJobsListResponse: myJobsListSlice,
    downloadJDResponse: downloadJDSlice,
    scheduledInterviewsResponse: scheduledInterviewsSlice,
    approvedCandidatesListResponse: approvedCandidatesListSlice,
    recruiterDashboardResponse: recruiterDashboardSlice,
    candidateInterviewsResponse: candidateInterviewsSlice,
    candidateResumeUploadResponse: candidateResumeUploadSlice,
    recruiterDetailsOnSignUpResponse: recruiterDetailsOnSignUpSlice,
    recruiterDetailsResponse: recruiterDetailsSlice,
    fetchRecordingResponse: fetchRecordingSlice,
    getRecuiterBillingJobList: recruiterJobListSlice,
    getRecruiterCandidateList: recruiterCandidateListSlice,

    signUpCustomer: signUpCustomerSlice,
    myJobsListCustomer: myJobsListCustomerSlice,
    candidateListing: candidateListSlice,
    customerEmployeeList: getCustomersEmployeeListSlice,
    addCustomerJobPostResponse: addJobPostSlice,
    candidateDetail: candidateDetailSlice,
    candidateRecordingList: candidateRecordingListSlice,
    deleteJobPostResponse: deleteCustomerJobPostSlice,
    interviewList: interviewListSlice,
    customerShortlistedcandidatesList: shortlistedcandidatesSlice,
    approveRejectInterviewCandidate: approveRejectInterviewCandidateSlice,
    getApprovedCandidatesListing: getApprovedCandidatesListSlice,
    getFeedbackQuesList: getFeedbackQuestionListSlice,
    candidateApproveRejectResponse: candidateApproveRejectSlice,
    feedbackQuestionsResponse: feedbackQuestionsSlice,
    interviewListResponse: interviewListSlice,
    createInterviewResponse: createInterviewSlice,
    myJobInterviewList: myJobInterviewListSlice,
    getCompanyDetails: getCompanyProfileDetailsSlice,
    stateCityData: getStateCityDataSlice,
    cityData: getCityDataSlice,
    uploadLogo: uploadLogoSlice,
    updateCompanyProfile: updateCompanyProfileSlice,
    candidatesInterviewsListResponse: candidatesInterviewsListSlice,
    customerCandidatesInterviewDataResponse: customerCandidatesInterviewDataSlice,
    getDashboardData: getDashboardDataSlice,
    updateMyProfile: updateMyProfileSlice,
    employeeDetails: customerEmployeeDetailSlice,
    checkSchedulePage: checkSchedulePageSlice,
    checkMeeting: checkMeetingSlice,
    joinMeeting: joinMeetingSlice,
    billingPaymentJobsListing: billingJobsListingSlice,
    billingPaymentCandidateListing: billingCandidatesListingSlice,
    billingPaymentSuccessResponse: billingPaymentSuccessSlice,
    billingConfirmPaymentResponse: billingConfirmPaymentSlice,
    billingCancelPaymentResponse: billingCancelPaymentSlice,
    interviewDetailPageResponse: interviewDetailPageDataSlice,
    getSearchField: getSearchFieldSlice,
    getCompanyEmployeeList: getCompanyEmployeeListSlice,
    getCompanyEmployeeJobs: getCompanyEmployeeJobsSlice,
    deactivateCompanyResponse: companyDeactivateAccountSlice,
    deactivateMyProfileResponse: profileDeactivateSlice,
    cancelCompanyDeactivateReqResponse: cancelCompanyDeactivateRequestSlice,
    addEducationRecruiter: addEducationRecruiterSlice,
    pauseAJobPostResponse: pauseAjobPostSlice,
    ReopenAJobPostResponse: reopenAJobPostSlice,
    taxInformationResponse: taxInformationSlice,
    updateTaxResidenceInfo: updateTaxResidenceInfoSlice,
    updateTaxIdentificationInfo: updateTaxIdentificationInfoSlice,
    deactivateRecruiter: deactivateRecruiterSlice,
    cancelDeactivateRecruiter: cancelDeactivateRecruiterSlice,
    paymentInformation: paymentInformationSlice,
    addBankDetails: addBankDetailsSlice,
    updateBankDetails: updateBankDetailsSlice,
    deleteBankDetails: deleteBankDetailsSlice,
    addWithdrawlDetails: addWithdrawlDetailsSlice,
    addPaypalDetails: addPaypalDetailsSlice,
    customerAddEmployeeResponse: customerAddEmployeeSlice,
    customerEditEmployeeResponse: customerEditEmployeeSlice,
    customerActivateEmployeeRes: customerActivateEmployeeSlice,
    customerDeactivateEmployeeRes: customerDeactivateEmployeeSlice,
    addACHDebitDetailsRes: addACHDebitDetailsSlice,
    editACHDebitDetailsRes: editACHDebitDetailsSlice,
    getpaymentMethods: getpaymentMethodsSlice,
    removeACHDebitDetailsRes: removeACHDebitDetailsSlice,
    setPrimaryDebitMethod: setPrimaryDebitMethodSlice,
    addReviewsRatingsToRecruiterResponse: addReviewsRatingsToRecruiterSlice,
    getRecruiterDetailsResponse: getRecruiterDetailsSlice,
    addNPSRatingResponse: addNPSRatingSlice,
    addCreditCardDetailsRes: addCreditCardDetailsSlice,
    verifyPaymentMethod: verifyPaymentMethodSlice,
    getCardTypeData: getCardTypeSlice,
    updateReviewsRatingsRes: updateReviewsRatingsSlice,
    removeTagDetails: removeTagDetailsSlice,
    getAllTagsListRes: getAllTagsListSlice,
    addTagToCandidate: addTagToCandidateSlice,
    editCustomerNotesRes: editCustomerNotesSlice,
    deleteCustomerNotesRes: deleteCustomerNotesSlice,
    addCustomerNotesRes: addCustomerNotesSlice,
    getCustomerNotesRes: getCustomerNotesSlice,
    addTagsToJobResponse: addTagsToJobSlice,
    removeJobTagsResponse: removeJobTagsSlice,
    getJobDataRes: getJobDataSlice,
    getCustomerAllTagsRes: getCustomerAllTagsSlice,
    addTagsToCustomerRes: addTagsToCustomerSlice,
    updateCustomerTagsRes: updateCustomerTagsSlice,
    activateDeactivateCustomerTagsRes: activateDeactivateCustomerTagsSlice,
    getAllNotificationsResponse: getAllNotificationsListSlice,
    markNotificationAsReadRes: markNotificationAsReadSlice,
    deleteNotificationsRes: deleteNotificationsSlice,
    NewCustomerSignUpRes: NewCustomerSignUpSlice,

    candidateSignUpRes: candidateSignUpSlice,
    candidateSignUpStepTwoRes: candidateSignUpStepTwoSlice,
  },
});

export default store;
