import { createSlice } from "@reduxjs/toolkit";
import jobRequestsList from "@/rtk-apis/jobRequests/jobRequestsList";

const initialState = {
  message: "",
  data: {},
  loading: false,
  error: "",
};

const jobRequestsListSlice = createSlice({
  name: "JobRequestsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(jobRequestsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(jobRequestsList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(jobRequestsList.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default jobRequestsListSlice.reducer;
