import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DatePickerIcon from "@/assets/svgs/DatePickerIcon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowDown from "@/assets/svgs/ArrowDown";
import { useDispatch, useSelector } from "react-redux";
import billingCandidateListing from "@/rtk-apis/customer/billingAndPayments/billingCandidateListing";
import Loader from "@/elements/Loader";
import { firstLetterCapital, getInitial, Currency } from "@/utils/helper";
import ErrorModal from "@/components/modals/error-modal";
import CustomPagination from "@/elements/pagination/customPagination";
import GoTOPage from "@/elements/gotopage/gotopage";
import moment from "moment";
import billingPaymentSuccess from "@/rtk-apis/customer/billingAndPayments/billingPaymentSuccess";
import BackIcon from "@/assets/svgs/BackIcon";
import { APP_URL } from "@/utils/constants";
import SortColumn from "@/elements/SortColumn";
import dayjs from "dayjs";
import NotificationButton from "../../components/elements/notificationButton";

export const BillingCandidateListing = ({
  selectedJob,
  billingStatus,
  jobdata,
  addResponsiveClass,
  setAddResponsiveClass,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  setCandidateStatus,
  candidateStatus,
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const [OpenNotificationModal, setOpenNotificationModal] = useState(false);
  const [OpenErrorModal, setOpenErrorModal] = useState(false);
  const [sortByASC, setSortByASC] = useState(true);
  const [sortByColoumn, setSortByColoumn] = useState("");

  const { data, loading } = useSelector((globalState) => {
    return globalState.billingPaymentCandidateListing;
  });

  const paymentSuccessResponse = useSelector((globalState) => {
    return globalState.billingPaymentSuccessResponse;
  });

  const getCandidatesListing = () => {
    let body = {
      jobId: selectedJob,
      status: candidateStatus,
      page: page,
      limit: 20,
      billingStartingDate: startDate,
      billingEndDate: endDate,
      columnName: sortByColoumn,
      sort: sortByASC ? "ASC" : "DESC",
    };
    dispatch(billingCandidateListing(body));
  };

  const getSortByData = (columnName) => {
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  const billingStartDate = (val) => {
    let originalDateString = JSON.stringify(val);
    let date = new Date(originalDateString.split("T")[0]);
    let formatedDate = moment(date, "YYYY-MM-DD")
      .add(1, "days")
      .format("YYYY-MM-DD");
    setStartDate(formatedDate);
  };

  const billingEndDate = (val) => {
    let originalDateString = JSON.stringify(val);
    let date = new Date(originalDateString.split("T")[0]);
    let formatedDate = moment(date, "YYYY-MM-DD")
      .add(1, "days")
      .format("YYYY-MM-DD");
    setEndDate(formatedDate);
  };

  const onPayClick = (amount, screeningid, companyid) => {
    let body = {
      amount: amount,
      screeningId: screeningid,
      companyId: companyid,
      // successUrl: "http://localhost:3000/payment-success",
      successUrl: `${APP_URL}payment-success`,
      // cancelUrl: "http://localhost:3000/payment-cancel",
      cancelUrl: `${APP_URL}payment-cancel`,
    };
    dispatch(billingPaymentSuccess(body));
  };

  useEffect(() => {
    if (typeof paymentSuccessResponse?.data == "string") {
      window.open(`${paymentSuccessResponse?.data}`, "_blank");
    }
  }, [paymentSuccessResponse?.data]);

  useEffect(() => {
    if (selectedJob) {
      getCandidatesListing();
    }
  }, [
    selectedJob,
    startDate,
    endDate,
    candidateStatus,
    sortByASC,
    sortByColoumn,
  ]);

  const shouldDisableDate = (date) => {
    return startDate ? dayjs(date).isBefore(dayjs(startDate), "day") : false;
  };

  return (
    <>
      <div
        className={`layoutGrid__col layoutGrid--responsive  ${
          addResponsiveClass ? "layoutGrid--show" : ""
        }`}
      >
        <div className="dataCol">
          <div className="page page__full">
            <div className="page__head">
              <h2 className="page__head--title">
                <button
                  className="btn btn__transparent btn__back border_0 p_0"
                  type="button"
                  onClick={() => setAddResponsiveClass(false)}
                >
                  <BackIcon />
                </button>
                Candidates
              </h2>
              <NotificationButton
                OpenNotificationModal={OpenNotificationModal}
                setOpenNotificationModal={setOpenNotificationModal}
              />
            </div>

            <div className="page__body">
              <div className="tabContainer">
                <div className="tabContainer__item">
                  {billingStatus && (
                    <div className="alert">
                      Payment must be paid within 2 weeks after the invoice has
                      been generated
                    </div>
                  )}

                  <div className="tabContainer__item--head bg--white">
                    <div className="listCol__head--actions w_100">
                      <div className="flexRow m_0 w_100">
                        <div
                          className="formField formField--smFull"
                          style={{ maxWidth: "300px" }}
                        >
                          <FormControl
                            fullWidth
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              onChange={(event) =>
                                setCandidateStatus(event?.target?.value)
                              }
                              IconComponent={() => <ArrowDown />}
                              displayEmpty
                              value={candidateStatus}
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ opacity: "0.8" }}>
                                      Payment Status
                                    </span>
                                  );
                                }

                                return selected;
                              }}
                            >
                              <MenuItem value="All">All</MenuItem>
                              <MenuItem value="Paid">Paid</MenuItem>
                              <MenuItem value="Unpaid">Unpaid</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="formField flexRow--datepicker datepickerBillingDatepicker">
                          <label className="formField__label">
                            Billing Date
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              onChange={(val) => billingStartDate(val)}
                              className="formField__datepicker"
                              slots={{ openPickerIcon: DatePickerIcon }}
                              label="From"
                              value={startDate}
                            />
                          </LocalizationProvider>

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              className="formField__datepicker"
                              onChange={(val) => billingEndDate(val)}
                              slots={{ openPickerIcon: DatePickerIcon }}
                              label="To"
                              shouldDisableDate={shouldDisableDate}
                              value={endDate}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <TableContainer
                    component={Paper}
                    className="tableContainer"
                    style={{ height: "calc(100dvh - 198px)" }}
                  >
                    <Table className="table">
                      <TableHead>
                        <TableRow>
                          <TableCell>S.</TableCell>
                          <TableCell onClick={() => getSortByData("firstName")}>
                            <SortColumn thName={"Name"} sortByASC={sortByASC} />
                          </TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>Payment Status</TableCell>
                          <TableCell>Payment Date</TableCell>
                          <TableCell>Billing Date</TableCell>
                          <TableCell>Invoice</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.rows?.length > 0 || jobdata?.length > 0 ? (
                          data?.rows?.map((item, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                  {index + 1}
                                </TableCell>
                                <TableCell>
                                  <div className="profile">
                                    <div className="profile__initials">
                                      {getInitial(item?.candidate?.firstName)}
                                    </div>
                                    <span className="profile__name">
                                      {firstLetterCapital(
                                        item?.candidate?.firstName
                                      )}{" "}
                                      {firstLetterCapital(
                                        item?.candidate?.lastName
                                      )}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="cellText">
                                    <span className="cellText__dark">
                                      {Currency(
                                        item?.amount ? item?.amount : 0
                                      )}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="cellText">
                                    <span
                                      className={`cellText__dark ${
                                        item?.paymentLinkData
                                          ?.payment_status === "paid"
                                          ? "text--success"
                                          : "text--danger"
                                      }`}
                                    >
                                      {item?.paymentLinkData?.payment_status ===
                                      "paid"
                                        ? "Paid"
                                        : "Unpaid"}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="cellText">
                                    <span className="cellText__dark">
                                      {item?.paymentDate
                                        ? moment(item?.paymentDate).format(
                                            "D MMM, YYYY"
                                          )
                                        : "--"}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="cellText">
                                    <span className="cellText__dark">
                                      {item?.invoiceDate
                                        ? moment(item?.invoiceDate).format(
                                            "D MMM, YYYY"
                                          )
                                        : "--"}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="cellText">
                                    <Link
                                      to={
                                        item?.invoiceLink
                                          ? item?.invoiceLink
                                          : "#"
                                      }
                                      target="_blank"
                                      onClick={(e) => {
                                        if (!item?.invoiceLink) {
                                          e.preventDefault();
                                          setOpenErrorModal(true);
                                        }
                                      }}
                                      className="cellText__dark cell--link"
                                    >
                                      View Invoice
                                    </Link>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <div className="actions flex--end">
                                    <button
                                      disabled={
                                        item?.paymentLinkData
                                          ?.payment_status === "paid"
                                          ? true
                                          : false
                                      }
                                      className="btn btn__white minwidthAuto"
                                      onClick={() =>
                                        onPayClick(
                                          item?.amount,
                                          item?.sreeningId,
                                          item?.companyId
                                        )
                                      }
                                    >
                                      Pay
                                    </button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell>
                              <div className="no-data floating">
                                No Results Found!
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div className="pagination">
                    <div className="pagination__result">
                      {`${data?.count > page * 20 ? 20 : data?.count} of ${
                        data?.count
                      } results`}
                    </div>
                    {data?.count ? (
                      <CustomPagination
                        totalPageCount={Math.ceil(data?.count / data?.count)}
                        changePage={(event, value) => changePage(event, value)}
                      />
                    ) : (
                      ""
                    )}
                    {data?.count ? (
                      <div className="pagination__actions">
                        <GoTOPage changePage={changePage} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      {OpenErrorModal && (
        <ErrorModal
          errorMessage={"Invoice is not avaliable!"}
          closeModal={() => setOpenErrorModal(false)}
          footerVisible={true}
        />
      )}
    </>
  );
};
