import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDown from "@/assets/svgs/ArrowDown";
import BackIcon from "@/assets/svgs/BackIcon";
import DatePickerIcon from "@/assets/svgs/DatePickerIcon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import JobIcon from "@/assets/svgs/JobIcon";
import EditIconNew from "@/assets/svgs/EditIconNew";
import DeleteIcon from "@/assets/svgs/DeleteIcon";
import LocationIcon from "@/assets/svgs/LocationIcon";
import { Formik, Form, Field } from "formik";
import { postAJobSchema } from "@/components/schema/customer-schema";
import staticData from "@/rtk-apis/staticData";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import getCustomersEmployeeList from "@/rtk-apis/customer/myJobs/getCustomersEmployeeList";
import {
  GetUserDetails,
  firstLetterCapital,
  checkFor_CompanyAdmin_JobCreater_HiringLead,
  checkForCompanyAdmin_HiringLead_JTM_JobCreater,
} from "@/utils/helper";
import moment from "moment";
import addJobPost from "@/rtk-apis/customer/myJobs/addJobPost";
import dayjs from "dayjs";
import { SuccessModal } from "@/components/modals/SuccessModal";
import { closeRtkModal } from "@/store/slices/customer/myJobs/addJobPostSlice";
import Loader from "@/elements/Loader";
import ErrorModal from "@/components/modals/error-modal";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import getSearchField from "@/rtk-apis/customer/myJobs/getSearchField";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { MenuProps, MaxHeight, modules, formats } from "@/utils/constants";

const JobPost = ({
  setOpenAddPostJobScreen,
  selectedJobData,
  EditJobPostMode,
  setEditJobPostMode,
  backToJobDetailpage,
  addResponsiveness,
  setAddResponsiveness,
  handleOpenAddEditNotesModal,
  handleDeleteNote,
  NotesArray,
  handleEditNotes,
  setShareWith,
  ShareWith,
}) => {
  const dispatch = useDispatch();
  const userDetails = GetUserDetails();
  const role = userDetails?.customer?.role;
  const [AddCollapseClassToNotes, setAddCollapseClassToNotes] = useState(false);
  const [AddCollapseClassToDescp, setAddCollapseClassToDescp] = useState(false);
  const [departmentArr, setDepartmentArr] = useState([]);
  const [ApplicationDeadlineDate, setApplicationDeadlineDate] = useState(
    EditJobPostMode
      ? moment(selectedJobData?.applicationDeadline).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD")
  );
  const EmployeeList = useSelector((globalState) => {
    return globalState.customerEmployeeList;
  });
  const [JTMArray, setJTMArray] = useState(
    EditJobPostMode ? selectedJobData?.jobTeamMember : []
  );
  const [HiringLeadArray, setHiringLeadArray] = useState([]);

  const addUpdateJobPostResponse = useSelector((globalState) => {
    return globalState.addCustomerJobPostResponse;
  });

  const getSearchFieldData = useSelector((globalState) => {
    return globalState.getSearchField;
  });

  useEffect(() => {
    if (EditJobPostMode && EmployeeList?.data?.rows?.length) {
      let newArray = EmployeeList?.data?.rows?.filter(
        (item) =>
          item?.employeeId === selectedJobData?.hiringLead?.[0]?.employeeId
      );
      setHiringLeadArray(newArray);
    }
  }, [EditJobPostMode, EmployeeList]);

  useEffect(() => {
    if (JTMArray?.length || HiringLeadArray?.length) {
      let tempArr = [
        {
          id: "Laila Team",
          value: "Laila Team",
        },
      ];
      JTMArray.map((t) => {
        tempArr.push({
          id: t.employeeId,
          value: `${firstLetterCapital(t.firstName)} ${firstLetterCapital(
            t.lastName
          )}`,
        });
      });
      HiringLeadArray.map((t) => {
        tempArr.push({
          id: t.employeeId,
          value: `${firstLetterCapital(t.firstName)} ${firstLetterCapital(
            t.lastName
          )}`,
        });
      });
      if (EditJobPostMode && selectedJobData?.assignedId) {
        tempArr.push({
          id: selectedJobData?.assignedId,
          value: `Recruiter - ${firstLetterCapital(
            selectedJobData?.recruiter?.firstName
          )} ${firstLetterCapital(selectedJobData?.recruiter?.lastName)}`,
        });
      }
      // tempArr.push({
      //   id: userDetails?.customer?.employeeId,
      //   value: `${userDetails?.customer?.firstName} ${userDetails?.customer?.lastName}`,
      // });
      let res = Array.from(new Set(tempArr.map(JSON.stringify))).map(
        JSON.parse
      );
      setShareWith(res);
    }
  }, [JTMArray, HiringLeadArray]);

  let initialValues = {
    title: EditJobPostMode ? selectedJobData?.title : "",
    department: EditJobPostMode ? selectedJobData?.department : "",
    location: EditJobPostMode ? selectedJobData?.location : "",
    employmentType: EditJobPostMode ? selectedJobData?.employmentType : [],
    maxCompensation: EditJobPostMode ? selectedJobData?.maxCompensation : "",
    minCompensation: EditJobPostMode ? selectedJobData?.minCompensation : "",
    applicationDeadline: EditJobPostMode
      ? dayjs(new Date(selectedJobData?.applicationDeadline))
      : dayjs(new Date()),
    minExperience: EditJobPostMode ? selectedJobData?.minExperience : "",
    maxExperience: EditJobPostMode ? selectedJobData?.maxExperience : "",
    qualification: EditJobPostMode ? selectedJobData?.qualification : [],
    hiringLead: EditJobPostMode
      ? selectedJobData?.hiringLead?.[0]?.employeeId
      : "",
    jobTeamMember: EditJobPostMode ? selectedJobData?.jobTeamMember : [],
    noOfHires: EditJobPostMode ? selectedJobData?.noOfHires : "",
    skillSet: EditJobPostMode ? selectedJobData?.skillSet : [],
    description: EditJobPostMode ? selectedJobData?.description : "",
    baseSalary: EditJobPostMode ? selectedJobData?.baseSalary : "Range",
    frequency: EditJobPostMode ? selectedJobData?.frequency : "",
    minBaseSalary: EditJobPostMode ? selectedJobData?.minCompensation : "",
    maxBaseSalary: EditJobPostMode ? selectedJobData?.maxCompensation : "",
    starttingAmount: EditJobPostMode ? selectedJobData?.minCompensation : "",
    uptoAmount: EditJobPostMode ? selectedJobData?.maxCompensation : "",
    exactAmount: EditJobPostMode ? selectedJobData?.maxCompensation : "",
  };

  const handleApplicationDeadlineDate = (val) => {
    let date = new Date(val.$d);
    let FormatedDate = moment(date, "YYYY-MM-DD");
    setApplicationDeadlineDate(FormatedDate);
  };

  useEffect(() => {
    let body = {
      companyId: userDetails?.customer?.companyId,
      from: "addjob",
      limit: 20,
      page: 1,
      search: "",
    };
    dispatch(getCustomersEmployeeList(body));
  }, []);

  useEffect(() => {
    dispatch(staticData(`companyId=${userDetails?.customer?.companyId}`));
  }, []);

  const { data } = useSelector((globalState) => {
    return globalState.static;
  });

  useEffect(() => {
    if (data?.department?.length) {
      setDepartmentArr(data?.department);
    }
  }, [data]);

  useEffect(() => {
    if (getSearchFieldData?.data?.length) {
      setDepartmentArr(getSearchFieldData?.data);
    }
  }, [getSearchFieldData?.data]);

  const getMinCompensation = (data) => {
    if (data?.baseSalary === "Range") {
      return data?.minBaseSalary;
    } else if (data?.baseSalary === "Starting at") {
      return data?.starttingAmount;
    } else if (data?.baseSalary === "Up to") {
      return data?.uptoAmount;
    } else if (data?.baseSalary === "Exact Pay") {
      return data?.exactAmount;
    }
  };

  const getMaxCompensation = (data) => {
    if (data?.baseSalary === "Range") {
      return data?.maxBaseSalary;
    } else if (data?.baseSalary === "Starting at") {
      return data?.starttingAmount;
    } else if (data?.baseSalary === "Up to") {
      return data?.uptoAmount;
    } else if (data?.baseSalary === "Exact Pay") {
      return data?.exactAmount;
    }
  };

  const handleSubmit = async (values) => {
    const userDetails = GetUserDetails();
    if (EditJobPostMode) {
      let body = {
        ...values,
        title: values?.title,
        applicationDeadline: ApplicationDeadlineDate,
        companyId: userDetails?.customer?.companyId,
        employeeId: userDetails?.customer?.employeeId,
        minExperience: Number(values?.minExperience),
        maxExperience: Number(values?.maxExperience),
        notes: NotesArray ?? [],
        minCompensation: getMinCompensation(values).toString(),
        maxCompensation: getMaxCompensation(values).toString(),
        noOfHires: Number(values?.noOfHires),
        jobId: selectedJobData?.jobId ?? "",
        hiringLead: EmployeeList?.data?.rows?.length
          ? EmployeeList?.data?.rows.filter(
              (item) => item?.employeeId == values?.hiringLead
            )
          : [],
      };
      delete body.minBaseSalary;
      delete body.maxBaseSalary;
      delete body.starttingAmount;
      delete body.uptoAmount;
      delete body.exactAmount;
      dispatch(addJobPost(body));
    } else {
      let body = {
        ...values,
        applicationDeadline: ApplicationDeadlineDate,
        companyId: userDetails?.customer?.companyId,
        employeeId: userDetails?.customer?.employeeId,
        minExperience: Number(values?.minExperience),
        maxExperience: Number(values?.maxExperience),
        noOfHires: Number(values?.noOfHires),
        notes: NotesArray ?? [],
        minCompensation: getMinCompensation(values).toString(),
        maxCompensation: getMaxCompensation(values).toString(),
        hiringLead: EmployeeList?.data?.rows?.length
          ? EmployeeList?.data?.rows.filter(
              (item) => item?.employeeId == values?.hiringLead
            )
          : [],
      };
      delete body.minBaseSalary;
      delete body.maxBaseSalary;
      delete body.starttingAmount;
      delete body.uptoAmount;
      delete body.exactAmount;
      dispatch(addJobPost(body));
    }
  };

  const closeModal = () => {
    dispatch(closeRtkModal());
    backToJobDetailpage();
    setEditJobPostMode(false);
  };

  const closeErrorModal = () => {
    dispatch(closeRtkModal());
  };

  const departmentInputChange = (text) => {
    dispatch(
      getSearchField({
        companyId: userDetails?.customer?.companyId,
        search: text,
        type: "department",
      })
    );
  };

  return (
    <>
      <div
        className={`layoutGrid__col layoutGrid--responsive  ${
          addResponsiveness ? "layoutGrid--show" : ""
        }`}
      >
        <div className="dataCol">
          <div className="page page__full">
            <Formik
              initialValues={initialValues}
              validationSchema={postAJobSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({
                errors,
                values,
                handleChange,
                handleBlur,
                touched,
                setFieldValue,
              }) => {
                return (
                  <Form className="form p_0">
                    <div className="page__head">
                      <h2 className="page__head--title">
                        <button
                          className="btn btn__transparent btn__back border_0 p_0"
                          type="button"
                          onClick={() => {
                            setAddResponsiveness(false);
                            setOpenAddPostJobScreen(false);
                            setEditJobPostMode(false);
                            setShareWith([]);
                          }}
                        >
                          <BackIcon />
                        </button>
                        Post a job
                      </h2>
                      <div className="page__head--actions">
                        <button
                          className="btn btn__white"
                          type="button"
                          onClick={() => {
                            setOpenAddPostJobScreen(false);
                            setEditJobPostMode(false);
                            setAddResponsiveness(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button className="btn btn__primary" type="submit">
                          {EditJobPostMode ? "Update" : "Add"}
                        </button>
                      </div>
                    </div>

                    <div className="page__body p_2">
                      <section className="form__section">
                        <h4 className="form__section--title">Job Details</h4>
                        <div className="formRow formRow__2">
                          <div className="formCol">
                            <div
                              className={`formField ${
                                errors?.title && touched?.title && errors?.title
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                Job Title
                              </label>
                              <div className="formField__group sm">
                                <Field
                                  type="text"
                                  className="formField__input sm"
                                  placeholder="Job Title"
                                  name="title"
                                  onBlur={handleBlur}
                                />
                                <span className="formField__icon">
                                  <JobIcon />
                                </span>
                              </div>
                              <span className="formField__error">
                                {errors?.title &&
                                  touched?.title &&
                                  errors?.title}
                              </span>
                            </div>
                          </div>
                          <div className="formCol">
                            <div
                              className={`formField ${
                                errors?.department &&
                                touched?.department &&
                                errors?.department
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                Department
                              </label>
                              <Autocomplete
                                id="tags-filled"
                                options={departmentArr}
                                defaultValue={values?.department}
                                name="department"
                                onChange={(event, newValue) => {
                                  setFieldValue("department", newValue);
                                }}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="filled"
                                    placeholder="Select"
                                    onChange={(e) =>
                                      departmentInputChange(e?.target?.value)
                                    }
                                    className="autoCompleteInput"
                                  />
                                )}
                              />
                              <span className="formField__error">
                                {errors?.department &&
                                  touched?.department &&
                                  errors?.department}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="formRow formRow__2">
                          <div className="formCol">
                            <div
                              className={`formField ${
                                errors?.location &&
                                touched?.location &&
                                errors?.location
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                Location
                              </label>
                              <div className="formField__group sm">
                                <Field
                                  type="text"
                                  className="formField__input sm"
                                  placeholder="Location"
                                  name="location"
                                  onBlur={handleBlur}
                                />
                                <span className="formField__icon">
                                  <LocationIcon />
                                </span>
                              </div>
                              <span className="formField__error">
                                {errors?.location &&
                                  touched?.location &&
                                  errors?.location}
                              </span>
                            </div>
                          </div>
                          <div className="formCol">
                            <div
                              className={`formField ${
                                errors?.employmentType &&
                                touched?.employmentType &&
                                errors?.employmentType
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                Employment Type
                              </label>
                              <FormControl
                                sx={{ width: "100%" }}
                                size="small"
                                className="formField__select"
                              >
                                <Select
                                  id="multiple-checkbox"
                                  multiple
                                  value={values?.employmentType}
                                  onChange={handleChange}
                                  name="employmentType"
                                  onBlur={handleBlur}
                                  renderValue={(selected) => {
                                    if (selected?.length === 0) {
                                      return (
                                        <span style={{ opacity: "0.8" }}>
                                          Select
                                        </span>
                                      );
                                    } else {
                                      return selected?.join(", ");
                                    }
                                  }}
                                  MenuProps={MenuProps}
                                  displayEmpty
                                  IconComponent={() => <ArrowDown />}
                                  classes={{ root: "multiSelect" }}
                                >
                                  {data?.employmentType?.length &&
                                    data?.employmentType.map((name) => (
                                      <MenuItem key={name} value={name}>
                                        <Checkbox
                                          checked={
                                            values?.employmentType?.indexOf(
                                              name
                                            ) > -1
                                          }
                                        />
                                        <ListItemText primary={name} />
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                              <span className="formField__error">
                                {errors?.employmentType &&
                                  touched?.employmentType &&
                                  errors?.employmentType}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="formRow formRow__2">
                          <div className="formCol">
                            <div
                              className={`formField ${
                                errors?.baseSalary &&
                                touched?.baseSalary &&
                                errors?.baseSalary
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                Base Salary
                              </label>
                              <FormControl
                                fullWidth
                                size="small"
                                className="formField__select"
                              >
                                <Select
                                  onChange={handleChange}
                                  IconComponent={() => <ArrowDown />}
                                  name="baseSalary"
                                  value={values?.baseSalary}
                                  displayEmpty
                                  onBlur={handleBlur}
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <span style={{ opacity: "0.8" }}>
                                          Select
                                        </span>
                                      );
                                    }

                                    return selected;
                                  }}
                                >
                                  {data?.baseSalaryOption?.length &&
                                    data?.baseSalaryOption.map((name) => (
                                      <MenuItem key={name} value={name}>
                                        <ListItemText primary={name} />
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                              <span className="formField__error">
                                {errors?.baseSalary &&
                                  touched?.baseSalary &&
                                  errors?.baseSalary}
                              </span>
                            </div>
                          </div>
                          <div className="formCol">
                            <div
                              className={`formField ${
                                errors?.frequency &&
                                touched?.frequency &&
                                errors?.frequency
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                Frequency
                              </label>
                              <FormControl
                                fullWidth
                                size="small"
                                className="formField__select"
                              >
                                <Select
                                  onChange={handleChange}
                                  IconComponent={() => <ArrowDown />}
                                  name="frequency"
                                  value={values?.frequency}
                                  displayEmpty
                                  onBlur={handleBlur}
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <span style={{ opacity: "0.8" }}>
                                          Select
                                        </span>
                                      );
                                    }

                                    return selected;
                                  }}
                                >
                                  {data?.frequency?.length &&
                                    data?.frequency.map((name) => (
                                      <MenuItem key={name} value={name}>
                                        <ListItemText primary={name} />
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                              <span className="formField__error">
                                {errors?.frequency &&
                                  touched?.frequency &&
                                  errors?.frequency}
                              </span>
                            </div>
                          </div>
                        </div>
                        {values?.baseSalary === "Range" && (
                          <div className="formRow formRow__2">
                            <div
                              className={`formRow formRow__2 formRow__sm ${
                                (errors?.minBaseSalary &&
                                  touched?.minBaseSalary &&
                                  errors?.minBaseSalary) ||
                                (errors?.maxBaseSalary &&
                                  touched?.maxBaseSalary &&
                                  errors?.maxBaseSalary)
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <div className="formCol flexField">
                                <FormControl
                                  fullWidth
                                  size="small"
                                  className="formField__select"
                                  sx={{ maxWidth: "60px" }}
                                >
                                  <Select
                                    IconComponent={() => <ArrowDown />}
                                    displayEmpty
                                    value="$"
                                    MenuProps={MaxHeight}
                                    renderValue={(selected) => {
                                      if (!selected) {
                                        return (
                                          <span style={{ opacity: "0.8" }}>
                                            Select
                                          </span>
                                        );
                                      }

                                      return selected;
                                    }}
                                  >
                                    <MenuItem>$</MenuItem>
                                  </Select>
                                </FormControl>
                                <div
                                  className={`formField ${
                                    errors?.minBaseSalary &&
                                    touched?.minBaseSalary &&
                                    errors?.minBaseSalary
                                      ? "fieldError"
                                      : ""
                                  }`}
                                >
                                  <label className="formField__label">
                                    From
                                  </label>
                                  <Field
                                    type="number"
                                    className="formField__input sm"
                                    placeholder="Enter Amt"
                                    name="minBaseSalary"
                                    min={0}
                                  />
                                  <span className="formField__error pr_1">
                                    {errors?.minBaseSalary &&
                                      touched?.minBaseSalary &&
                                      errors?.minBaseSalary}
                                  </span>
                                </div>
                              </div>
                              <div className="formCol flexField">
                                <FormControl
                                  fullWidth
                                  size="small"
                                  className="formField__select"
                                  sx={{ maxWidth: "60px" }}
                                >
                                  <Select
                                    IconComponent={() => <ArrowDown />}
                                    displayEmpty
                                    value="$"
                                    MenuProps={MaxHeight}
                                    renderValue={(selected) => {
                                      if (!selected) {
                                        return (
                                          <span style={{ opacity: "0.8" }}>
                                            Select
                                          </span>
                                        );
                                      }

                                      return selected;
                                    }}
                                  >
                                    <MenuItem>$</MenuItem>
                                  </Select>
                                </FormControl>
                                <div
                                  className={`formField ${
                                    errors?.maxBaseSalary &&
                                    touched?.maxBaseSalary &&
                                    errors?.maxBaseSalary
                                      ? "fieldError"
                                      : ""
                                  }`}
                                >
                                  <label className="formField__label">To</label>
                                  <Field
                                    type="number"
                                    className="formField__input sm"
                                    placeholder="Enter Amt"
                                    name="maxBaseSalary"
                                    min={0}
                                  />
                                  <span className="formField__error pr_1">
                                    {errors?.maxBaseSalary &&
                                      touched?.maxBaseSalary &&
                                      errors?.maxBaseSalary}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {values?.baseSalary === "Starting at" && (
                          <div className="formRow formRow__2">
                            <div
                              className={`formCol flexField formRow__sm ${
                                errors?.starttingAmount ? "fieldError" : ""
                              }`}
                            >
                              <FormControl
                                fullWidth
                                size="small"
                                className="formField__select"
                                sx={{ maxWidth: "60px" }}
                              >
                                <Select
                                  IconComponent={() => <ArrowDown />}
                                  displayEmpty
                                  value="$"
                                  MenuProps={MaxHeight}
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <span style={{ opacity: "0.8" }}>
                                          Select
                                        </span>
                                      );
                                    }

                                    return selected;
                                  }}
                                >
                                  <MenuItem>$</MenuItem>
                                </Select>
                              </FormControl>
                              <div className="formField">
                                <Field
                                  type="number"
                                  className="formField__input sm"
                                  placeholder="Enter Amt"
                                  name="starttingAmount"
                                  min={0}
                                />
                              </div>
                              <span className="formField__error">
                                {errors?.starttingAmount}
                              </span>
                            </div>
                          </div>
                        )}
                        {values?.baseSalary === "Up to" && (
                          <div className="formRow formRow__2">
                            <div
                              className={`formCol flexField formRow__sm ${
                                errors?.uptoAmount ? "fieldError" : ""
                              }`}
                            >
                              <FormControl
                                fullWidth
                                size="small"
                                className="formField__select"
                                sx={{ maxWidth: "60px" }}
                              >
                                <Select
                                  IconComponent={() => <ArrowDown />}
                                  displayEmpty
                                  value="$"
                                  MenuProps={MaxHeight}
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <span style={{ opacity: "0.8" }}>
                                          Select
                                        </span>
                                      );
                                    }

                                    return selected;
                                  }}
                                >
                                  <MenuItem>$</MenuItem>
                                </Select>
                              </FormControl>
                              <div className="formField">
                                <Field
                                  type="number"
                                  className="formField__input sm"
                                  placeholder="Enter Amt"
                                  name="uptoAmount"
                                  min={0}
                                />
                                <span className="formField__error">
                                  {errors?.uptoAmount}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        {values?.baseSalary === "Exact Pay" && (
                          <div className="formRow formRow__2">
                            <div
                              className={`formCol flexField formRow__sm ${
                                errors?.exactAmount ? "fieldError" : ""
                              }`}
                            >
                              <FormControl
                                fullWidth
                                size="small"
                                className="formField__select"
                                sx={{ maxWidth: "60px" }}
                              >
                                <Select
                                  IconComponent={() => <ArrowDown />}
                                  displayEmpty
                                  value="$"
                                  MenuProps={MaxHeight}
                                  renderValue={(selected) => {
                                    if (!selected) {
                                      return (
                                        <span style={{ opacity: "0.8" }}>
                                          Select
                                        </span>
                                      );
                                    }

                                    return selected;
                                  }}
                                >
                                  <MenuItem>$</MenuItem>
                                </Select>
                              </FormControl>
                              <div className="formField">
                                <Field
                                  type="number"
                                  className="formField__input sm"
                                  placeholder="Enter Amt"
                                  name="exactAmount"
                                  min={0}
                                />
                                <span className="formField__error">
                                  {errors?.exactAmount}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className={`formRow formRow__2`}>
                          <div className="formCol">
                            <div
                              className={`formField ${
                                errors?.applicationDeadline ? "fieldError" : ""
                              }`}
                            >
                              <label className="formField__label">
                                Application Deadline
                              </label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  className="formField__datepicker"
                                  slots={{ openPickerIcon: DatePickerIcon }}
                                  name="applicationDeadline"
                                  value={dayjs(ApplicationDeadlineDate)}
                                  selected={dayjs(ApplicationDeadlineDate)}
                                  onChange={(val) => {
                                    handleApplicationDeadlineDate(val);
                                    setFieldValue("applicationDeadline", val);
                                  }}
                                  onBlur={handleBlur}
                                  closeOnSelect={true}
                                  disablePast={true}
                                />
                              </LocalizationProvider>
                              <span className="formField__error">
                                {errors?.applicationDeadline}
                              </span>
                            </div>
                          </div>
                          <div className="formCol">
                            <div className="formField">
                              <label className="formField__label">
                                Experience ( Years )
                              </label>
                              <div className="formField__group formField__minmax sm border_0">
                                <div className="formRow formRow__2 w_100 formRowSelect">
                                  <div
                                    className={`formField__group sm ${
                                      errors?.minExperience &&
                                      touched?.minExperience &&
                                      errors?.minExperience
                                        ? "fieldError"
                                        : ""
                                    }`}
                                  >
                                    <Field
                                      name="minExperience"
                                      type="number"
                                      className="formField__input fieldMin sm"
                                      placeholder="Min"
                                      onBlur={handleBlur}
                                      min={0}
                                      max={99}
                                    />
                                    <label className="formField__label">
                                      Min
                                    </label>
                                    <span className="formField__error pr_1">
                                      {errors?.minExperience &&
                                        touched?.minExperience &&
                                        errors?.minExperience}
                                    </span>
                                  </div>
                                  <div
                                    className={`formField__group sm ${
                                      errors?.maxExperience &&
                                      touched?.maxExperience &&
                                      errors?.maxExperience
                                        ? "fieldError"
                                        : ""
                                    }`}
                                  >
                                    <Field
                                      name="maxExperience"
                                      type="number"
                                      className="formField__input fieldMax sm"
                                      placeholder="Max"
                                      onBlur={handleBlur}
                                      min={0}
                                      max={99}
                                    />
                                    <label className="formField__label">
                                      Max
                                    </label>
                                    <span className="formField__error">
                                      {errors?.maxExperience &&
                                        touched?.maxExperience &&
                                        errors?.maxExperience}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`formRow formRow__2 ${
                            (errors?.minExperience &&
                              touched?.minExperience &&
                              errors?.minExperience) ||
                            (errors?.maxExperience &&
                              touched?.maxExperience &&
                              errors?.maxExperience)
                              ? "mb_3 formSpace"
                              : ""
                          }`}
                        >
                          <div className="formCol">
                            <div
                              className={`formField ${
                                errors?.qualification &&
                                touched?.qualification &&
                                errors?.qualification
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                Required Qualification
                              </label>
                              <Autocomplete
                                multiple
                                id="tags-filled"
                                options={data?.qualification}
                                defaultValue={values?.qualification}
                                name="qualification"
                                onChange={(event, newValue) => {
                                  for (var i = 1; i < newValue.length; i++) {
                                    newValue[i] = firstLetterCapital(
                                      newValue[i]
                                    );
                                  }
                                  setFieldValue("qualification", newValue);
                                }}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="filled"
                                    placeholder="Select"
                                    className="autoCompleteInput"
                                  />
                                )}
                              />
                              <span className="formField__error">
                                {errors?.qualification &&
                                  touched?.qualification &&
                                  errors?.qualification}
                              </span>
                            </div>
                          </div>
                          <div className="formCol">
                            <div
                              className={`formField ${
                                errors?.hiringLead &&
                                touched?.hiringLead &&
                                errors?.hiringLead
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                Hiring Lead
                              </label>
                              <FormControl
                                fullWidth
                                size="small"
                                className="formField__select"
                              >
                                {}
                                <Select
                                  onChange={(e) => {
                                    setFieldValue(
                                      "hiringLead",
                                      e?.target?.value
                                    );
                                    setHiringLeadArray(
                                      EmployeeList?.data?.rows.filter(
                                        (item) =>
                                          item?.employeeId == e?.target?.value
                                      )
                                    );
                                  }}
                                  IconComponent={() => <ArrowDown />}
                                  displayEmpty
                                  name="hiringLead"
                                  value={
                                    typeof values?.hiringLead === "string"
                                      ? values?.hiringLead
                                      : values?.hiringLead?.[0]?.employeeId
                                  }
                                  renderValue={(selected) => {
                                    if (!selected?.length) {
                                      return (
                                        <span style={{ opacity: "0.8" }}>
                                          Select
                                        </span>
                                      );
                                    } else {
                                      let selectedEmp = EmployeeList?.data?.rows?.filter(
                                        (item) => item?.employeeId == selected
                                      );
                                      if (selectedEmp?.length) {
                                        return (
                                          firstLetterCapital(
                                            selectedEmp[0]?.firstName
                                          ) +
                                          " " +
                                          firstLetterCapital(
                                            selectedEmp[0]?.lastName
                                          )
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {EmployeeList?.data?.rows?.map(
                                    (item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item?.employeeId}
                                        >
                                          {firstLetterCapital(item?.firstName) +
                                            " " +
                                            firstLetterCapital(item?.lastName)}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                              <span className="formField__error">
                                {errors?.hiringLead &&
                                  touched?.hiringLead &&
                                  errors?.hiringLead}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="formRow formRow__2">
                          <div className="formCol">
                            <div
                              className={`formField ${
                                errors?.jobTeamMember &&
                                touched?.jobTeamMember &&
                                errors?.jobTeamMember
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                Job Team Member
                              </label>
                              <FormControl
                                sx={{ width: "100%" }}
                                size="small"
                                className="formField__select"
                              >
                                <Select
                                  id="multiple-checkbox"
                                  multiple
                                  value={values?.jobTeamMember}
                                  onChange={(e) => {
                                    let originalArr = e?.target?.value;
                                    let arr = e?.target?.value;
                                    if (arr?.length) {
                                      let selectedArray = [];
                                      const lastItem = arr[arr.length - 1];
                                      const checkArr = arr.slice(
                                        0,
                                        arr.length - 1
                                      );
                                      selectedArray = checkArr?.length
                                        ? checkArr.filter(
                                            (item) =>
                                              item?.employeeId ==
                                              lastItem.employeeId
                                          )
                                        : [];
                                      if (selectedArray?.length) {
                                        let RequiredArr = checkArr.filter(
                                          (item) =>
                                            item.employeeId !==
                                            lastItem.employeeId
                                        );
                                        setFieldValue(
                                          "jobTeamMember",
                                          RequiredArr
                                        );
                                        setJTMArray(RequiredArr);
                                      } else {
                                        setFieldValue(
                                          "jobTeamMember",
                                          originalArr
                                        );
                                        setJTMArray(originalArr);
                                      }
                                    }
                                  }}
                                  name="jobTeamMember"
                                  onBlur={handleBlur}
                                  renderValue={(selected) => {
                                    if (selected?.length === 0) {
                                      return (
                                        <span style={{ opacity: "0.8" }}>
                                          Select
                                        </span>
                                      );
                                    } else {
                                      const selectedNames = selected
                                        .map(
                                          (item) =>
                                            `${firstLetterCapital(
                                              item.firstName
                                            )} ${firstLetterCapital(
                                              item.lastName
                                            )}`
                                        )
                                        .join(", ");
                                      return selectedNames;
                                    }
                                  }}
                                  MenuProps={MenuProps}
                                  displayEmpty
                                  IconComponent={() => <ArrowDown />}
                                  classes={{ root: "multiSelect" }}
                                >
                                  {EmployeeList?.data?.rows?.length &&
                                    EmployeeList?.data?.rows?.map(
                                      (item, index) => (
                                        <MenuItem key={index} value={item}>
                                          <Checkbox
                                            checked={
                                              values?.jobTeamMember?.filter(
                                                (row) =>
                                                  row.employeeId ==
                                                  item.employeeId
                                              )?.length >= 1
                                            }
                                          />
                                          <ListItemText
                                            primary={
                                              firstLetterCapital(
                                                item?.firstName
                                              ) +
                                              " " +
                                              firstLetterCapital(item?.lastName)
                                            }
                                          />
                                        </MenuItem>
                                      )
                                    )}
                                </Select>
                              </FormControl>
                              <span className="formField__error">
                                {errors?.jobTeamMember &&
                                  touched?.jobTeamMember &&
                                  errors?.jobTeamMember}
                              </span>
                            </div>
                          </div>
                          <div className="formCol">
                            <div
                              className={`formField ${
                                errors?.noOfHires &&
                                touched?.noOfHires &&
                                errors?.noOfHires
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                No of Hires
                              </label>
                              <div className="formField__group sm">
                                <Field
                                  type="number"
                                  className="formField__input sm"
                                  placeholder=" No of Hires"
                                  name="noOfHires"
                                  onBlur={handleBlur}
                                />
                              </div>
                              <span className="formField__error">
                                {errors?.noOfHires &&
                                  touched?.noOfHires &&
                                  errors?.noOfHires}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="formRow">
                          <div className="formCol">
                            <div
                              className={`formField ${
                                errors?.skillSet &&
                                touched?.skillSet &&
                                errors?.skillSet
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                Skill set
                              </label>
                              <Autocomplete
                                multiple
                                id="tags-filled"
                                options={data?.skillSet}
                                defaultValue={values?.skillSet}
                                name="skillSet"
                                onChange={(event, newValue) => {
                                  for (var i = 1; i < newValue.length; i++) {
                                    newValue[i] = firstLetterCapital(
                                      newValue[i]
                                    );
                                  }
                                  setFieldValue("skillSet", newValue);
                                }}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="filled"
                                    placeholder="Select"
                                    className="autoCompleteInput"
                                  />
                                )}
                              />
                              <span className="formField__error">
                                {errors?.skillSet &&
                                  touched?.skillSet &&
                                  errors?.skillSet}
                              </span>
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* Job Description */}
                      <section
                        className={`form__section form__section--accordion ${
                          AddCollapseClassToDescp ? "active" : ""
                        } bxshadow`}
                      >
                        <h4 className="form__section--title">
                          <span
                            className="title"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setAddCollapseClassToDescp(
                                !AddCollapseClassToDescp
                              )
                            }
                          >
                            Job Description
                          </span>
                          <span
                            className="icon"
                            onClick={() =>
                              setAddCollapseClassToDescp(
                                !AddCollapseClassToDescp
                              )
                            }
                          >
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7 10L11 14L15 10M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                                stroke="#6C737F"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </h4>
                        <div className="accordionData">
                          <div className="formRow">
                            <div className="formCol">
                              <div
                                className={`formField ${
                                  errors?.description &&
                                  touched?.description &&
                                  errors?.description
                                    ? "fieldError"
                                    : ""
                                }`}
                              >
                                <ReactQuill
                                  name="description"
                                  value={values?.description}
                                  modules={modules}
                                  formats={formats}
                                  onChange={(newValue) =>
                                    setFieldValue("description", newValue)
                                  }
                                />
                              </div>
                              <span className="formField__error">
                                {errors?.description &&
                                  touched?.description &&
                                  errors?.description}
                              </span>
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* Notes */}
                      {(EditJobPostMode
                        ? checkForCompanyAdmin_HiringLead_JTM_JobCreater(
                            selectedJobData,
                            role
                          )
                        : checkFor_CompanyAdmin_JobCreater_HiringLead(
                            selectedJobData,
                            role
                          )) && (
                        <section
                          className={`form__section form__section--accordion ${
                            AddCollapseClassToNotes ? "active" : ""
                          } bxshadow`}
                        >
                          <h4 className="form__section--title">
                            <span
                              className="title"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                setAddCollapseClassToNotes(
                                  !AddCollapseClassToNotes
                                )
                              }
                            >
                              Notes
                            </span>
                            <span
                              className="icon"
                              onClick={() =>
                                setAddCollapseClassToNotes(
                                  !AddCollapseClassToNotes
                                )
                              }
                            >
                              <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7 10L11 14L15 10M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                                  stroke="#6C737F"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                          </h4>
                          <div className="accordionData">
                            {checkFor_CompanyAdmin_JobCreater_HiringLead(
                              selectedJobData,
                              role
                            ) && (
                              <div className="formRow">
                                <button
                                  className="btn btn__primary"
                                  onClick={handleOpenAddEditNotesModal}
                                  type="button"
                                >
                                  Add note
                                </button>
                              </div>
                            )}
                            <div className="notesList">
                              {NotesArray?.length
                                ? [...NotesArray]
                                    .sort(
                                      (a, b) =>
                                        new Date(b.createdTime) -
                                        new Date(a.createdTime)
                                    )
                                    ?.filter(
                                      (item) =>
                                        item?.from === "Customer" ||
                                        item?.from === "customer"
                                    )
                                    .map((note, index) => {
                                      return (
                                        <div className="noteCard" key={index}>
                                          <h3 className="title">
                                            {note?.title}
                                            <div className="btn__group">
                                              {checkFor_CompanyAdmin_JobCreater_HiringLead(
                                                selectedJobData,
                                                role
                                              ) && (
                                                <>
                                                  <button
                                                    type="button"
                                                    className="btn p_0 border_0"
                                                    onClick={() =>
                                                      handleEditNotes(note)
                                                    }
                                                  >
                                                    <EditIconNew />
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn p_0 border_0"
                                                    onClick={() =>
                                                      handleDeleteNote(note?.id)
                                                    }
                                                  >
                                                    <DeleteIcon />
                                                  </button>
                                                </>
                                              )}
                                            </div>
                                          </h3>
                                          <p>
                                            <div
                                              key={new Date().getTime()}
                                              className="showQuillTags"
                                              dangerouslySetInnerHTML={{
                                                __html: note?.description
                                                  ? note?.description
                                                  : "--",
                                              }}
                                            ></div>
                                          </p>
                                        </div>
                                      );
                                    })
                                : ""}
                            </div>
                          </div>
                        </section>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>

      {addUpdateJobPostResponse?.meta?.status === true && (
        <SuccessModal
          closeModal={closeModal}
          Heading={EditJobPostMode ? "Job Updated!" : "Job Added!"}
          sucessfullMsg={addUpdateJobPostResponse?.meta?.msg}
        />
      )}

      {addUpdateJobPostResponse?.meta?.status === false &&
        addUpdateJobPostResponse?.meta?.msg && (
          <ErrorModal
            errorMessage={addUpdateJobPostResponse?.meta?.msg}
            closeModal={closeErrorModal}
            footerVisible={true}
          />
        )}

      {addUpdateJobPostResponse?.loading && <Loader />}
    </>
  );
};

export default JobPost;
