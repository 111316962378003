import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import LinkIcon from "@/assets/svgs/LinkIcon";
import {
  formatPhoneNumber,
  firstLetterCapital,
  saveInterviewDetails,
  getUTCRecordingTimeSlot,
} from "@/utils/helper";
import MeetingStatus from "@/components/HOCs/MeetingStatus";
import interviewDetailPageData from "@/rtk-apis/customer/myJobs/interview/interviewDetailPageData";
import { useDispatch, useSelector } from "react-redux";
import Loader from "@/elements/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PlayIcon from "@/assets/svgs/PlayIcon";
import { RecordingModal } from "@/components/modals/recording-modal";
import CustomPagination from "@/elements/pagination/customPagination";
import GoTOPage from "@/elements/gotopage/gotopage";

function InterviewsDetails({ interviewDetails, changeInterviewViewType }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openRecordingModal, setOpenRecordingModal] = useState(false);
  const [recordingLink, setRecordingLink] = useState("");
  const [page, setPage] = useState(1);

  const interviewData = useSelector((globalState) => {
    return globalState.interviewDetailPageResponse;
  });

  const changePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getInterviewDetails();
    meetingTimePast();
  }, []);

  const navigateToRescheduleUrl = () => {
    let { additional_guests } = interviewData?.data?.meetingInfo;
    saveInterviewDetails({
      companyId: interviewData?.data?.companyId,
      jobId: interviewData?.data?.jobId,
      jmtId: interviewData?.data?.jmtId,
      candidateId: interviewData?.data?.candidateId,
      recruiterId: interviewData?.data?.recruiterId
        ? interviewData?.data?.recruiterId
        : null,
      employeeId: interviewData?.data?.employeeId,
      adminId: null,
    });
    let url = `https://schedule.nylas.com/${interviewData?.data?.meetingInfo?.page_slug}/reschedule/${interviewData?.data?.meetingInfo?.edit_hash}`;
    if (additional_guests) {
      url = `${url}?additional_guests=${additional_guests}`;
    }
    window.open(url, "_blank");
  };

  const getJobTeamMembersName = (jobTeamMember) => {
    const initialValue = jobTeamMember?.length ? "" : "--";
    const teamMembersName = jobTeamMember?.reduce(
      (accumulator, currentValue) => {
        let nameArray = currentValue?.split(" ");
        let newArr = nameArray?.map((item) => firstLetterCapital(item));
        return accumulator
          ? accumulator + ", " + newArr.join(" ")
          : newArr.join(" ");
      },
      initialValue
    );
    return teamMembersName;
  };

  const getInterviewDetails = () => {
    let obj = {
      interviewId: interviewDetails?.interviewId,
      screeningId: interviewDetails?.screeningId,
    };
    dispatch(interviewDetailPageData(obj));
  };

  const viewRecording = (recordingLink) => {
    setOpenRecordingModal(true);
    setRecordingLink(recordingLink);
  };

  const meetingTimePast = (time) => {
    const today = new Date();
    return today > new Date(time);
  };

  return (
    <>
      <div className="dataCol__head head--inner head--responsive">
        <div className="item">
          <div className="initials initials__lg">
            {interviewData?.data?.candidate?.firstName
              ?.charAt(0)
              ?.toUpperCase()}
          </div>
          <div className="item__info">
            <h4 className="item__info--title">
              {firstLetterCapital(interviewData?.data?.candidate?.firstName) +
                " " +
                firstLetterCapital(interviewData?.data?.candidate?.lastName)}
            </h4>
            <div className="details__row">
              {interviewData?.data?.candidate?.phoneNumber && (
                <div className="details__row--item">
                  <span>Phone:</span>
                  +1{" "}
                  {formatPhoneNumber(
                    interviewData?.data?.candidate?.phoneNumber
                  )}
                </div>
              )}
              {interviewData?.data?.candidate?.email && (
                <div className="details__row--item">
                  <span>Email:</span> {interviewData?.data?.candidate?.email}
                </div>
              )}
            </div>
            <ul className="tags__list">
              {interviewData?.data?.candidate?.education && (
                <li className="tags__list--item">
                  {interviewData?.data?.candidate?.education
                    ? interviewData?.data?.candidate?.education
                    : ""}
                </li>
              )}
              {interviewData?.data?.candidate?.role && (
                <li className="tags__list--item">
                  {interviewData?.data?.candidate?.role
                    ? interviewData?.data?.candidate?.role
                    : ""}
                </li>
              )}
              {interviewData?.data?.candidate?.experience > 0 ? (
                <li className="tags__list--item">
                  {interviewData?.data?.candidate?.experience === 1
                    ? interviewData?.data?.candidate?.experience + " Yr Exp"
                    : interviewData?.data?.candidate?.experience +
                      " Yrs Exp"}{" "}
                </li>
              ) : (
                <li className="tags__list--item">
                  {interviewData?.data?.candidate?.experience <= 0
                    ? "Fresher"
                    : ""}
                </li>
              )}
            </ul>
          </div>
        </div>

        <div className="dataCol__head--actions">
          <button
            onClick={() => changeInterviewViewType("list", interviewData?.data)}
            className="btn btn__white w_fitContent"
            type="button"
          >
            Back
          </button>
          {!meetingTimePast(interviewData?.data?.meeting_end_time) &&
            !interviewData?.data?.recordings?.length && (
              <button
                onClick={() => navigateToRescheduleUrl()}
                className="btn btn__primary w_fitContent"
                type="button"
              >
                Reschedule
              </button>
            )}
        </div>
      </div>
      <div className="details">
        <h4 className="details__section--title">Interview Details</h4>
        <div className="fieldBox flexRow">
          <div className="flexCol">
            <label className="flexCol__label">Interview Title</label>
            <div className="flexCol__text">{interviewData?.data?.title}</div>
          </div>
          <div className="flexCol">
            <label className="flexCol__label">Interview Date</label>
            <div className="flexCol__text">
              {moment(interviewData?.data?.meeting_start_time).format(
                "D MMM , YYYY"
              )}
            </div>
          </div>
        </div>
        <div className="fieldBox flexRow">
          <div className="flexCol">
            <label className="flexCol__label">Interview Time</label>
            <div className="flexCol__text">
              {moment(interviewData?.data?.meeting_start_time).format(
                "hh:mm A"
              ) +
                " - " +
                moment(interviewData?.data?.meeting_end_time).format("hh:mm A")}
            </div>
          </div>
          <div className="flexCol">
            <label className="flexCol__label">Interview URL</label>
            <Link
              to={interviewData?.data?.meetingInfo?.location}
              target="_blank"
              onCopy={(e) => {
                if (meetingTimePast(interviewData?.data?.meeting_end_time)) {
                  e.preventDefault();
                  return false;
                }
              }}
              className={`flexCol__text text--primary  ${
                meetingTimePast(interviewData?.data?.meeting_end_time)
                  ? "formField__disabled"
                  : ""
              }`}
            >
              {interviewData?.data?.meetingInfo?.location}
              <LinkIcon />
            </Link>
          </div>
        </div>
        <div className="fieldBox flexRow">
          <div className="flexCol">
            <label className="flexCol__label">Job Team member</label>
            <div className="flexCol__text">
              {getJobTeamMembersName(interviewData?.data?.jmtMember)}
            </div>
          </div>
          <div className="flexCol">
            <label className="flexCol__label">Status</label>
            <MeetingStatus time={interviewData?.data?.meeting_end_time} />
          </div>
        </div>
      </div>
      {interviewData?.loading && <Loader />}
      <TableContainer
        component={Paper}
        className="tableContainer"
        style={{ height: "auto", minHeight: "500px" }}
      >
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>S.</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Interviewed By</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {interviewData?.data?.recordings?.length ? (
            <TableBody>
              {interviewData?.data?.recordings?.length &&
                interviewData?.data?.recordings?.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {page === 1 ? index + 1 : (page - 1) * 20 + (index + 1)}
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <span className="cellText__dark">
                            {moment(
                              interviewData?.data?.meeting_start_time
                            ).format("D  MMM , YYYY")}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <span className="cellText__dark">
                            {getUTCRecordingTimeSlot(
                              interviewData?.data?.meeting_start_time,
                              row?.timeSlot
                            )}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="cellText">
                          <span className="cellText__dark">
                            {row?.addedFrom}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="actions flex--end">
                          <button
                            className="btn btn__white"
                            onClick={() => viewRecording(row?.link)}
                          >
                            View Rec.
                            <PlayIcon />
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          ) : (
            <TableBody><TableRow><TableCell><div className="no-data floating">No Results Found!</div></TableCell></TableRow></TableBody>
          )}
        </Table>
      </TableContainer>
      <div className="pagination">
        <div className="pagination__result">
          {`${
            interviewData?.data?.recordings?.length > page * 20
              ? 20
              : interviewData?.data?.recordings?.length
          } of ${interviewData?.data?.recordings?.length} results`}
        </div>
        {interviewData?.data?.recordings?.length ? (
          <CustomPagination
            totalPageCount={Math.ceil(
              interviewData?.data?.recordings?.length / 20
            )}
            changePage={(event, value) => changePage(event, value)}
          />
        ) : (
          ""
        )}
        {interviewData?.data?.recordings?.length ? (
          <div className="pagination__actions">
            <GoTOPage changePage={changePage} />
          </div>
        ) : (
          ""
        )}
      </div>
      <RecordingModal
        openModal={openRecordingModal}
        closeModal={() => {
          setOpenRecordingModal(false);
        }}
        recordingLink={recordingLink}
      />
    </>
  );
}

export default InterviewsDetails;
