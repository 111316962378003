import React from 'react';

const TagsSvg = () => {
  return (
    <svg className='tagSvg'
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.06252 4.06185H4.06794M11.4238 7.53393L7.54002 11.4177C7.43941 11.5184 7.31993 11.5983 7.18841 11.6528C7.0569 11.7073 6.91593 11.7354 6.77356 11.7354C6.63119 11.7354 6.49022 11.7073 6.35871 11.6528C6.2272 11.5983 6.10772 11.5184 6.0071 11.4177L1.35419 6.77018V1.35352H6.77085L11.4238 6.00643C11.6255 6.20941 11.7388 6.48398 11.7388 6.77018C11.7388 7.05638 11.6255 7.33096 11.4238 7.53393Z"
        stroke="#6C737F"
        strokeWidth="1.08333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default TagsSvg;
