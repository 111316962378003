import React from "react";

function Questionnaire({ selectedJob }) {
  return (
    <>
      <div className="questionnaire">
        {selectedJob?.addQuestionaire?.length ? (
          <ul className="questionnaire__list">
            {selectedJob?.addQuestionaire.map((item, key) => {
              return (
                <li key={key} className="questionnaire__list--item">
                  {item}
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="tableContainer">
            <div className="no-data floating">No Results Found!</div>
          </div>
        )}
      </div>
    </>
  );
}

export default Questionnaire;
