import * as Yup from "yup";
import * as CONSTANTS from "@/utils/constants";
import { phoneNumberRegex, LinkedInRegex, URLRegex } from "@/utils/regex";

export const postAJobSchema = Yup.object().shape({
  title: Yup.string()
    .min(1, CONSTANTS.JOBTITLE.validation.valid)
    .required(CONSTANTS.JOBTITLE.validation.valid),
  department: Yup.string().required("Please select department"),
  location: Yup.string()
    .min(1, CONSTANTS.LOCATION.validation.valid)
    .required(CONSTANTS.LOCATION.validation.valid),
  employmentType: Yup.array()
    .required()
    .min(1, CONSTANTS.EMPLOYMENT_TYPE.validation.valid)
    .of(Yup.string().required()),
  applicationDeadline: Yup.date()
    .required("Application Deadline is required")
    .min(new Date(), "Application Deadline must be a future date."),
  noOfHires: Yup.number().min(
    0,
    "No of hires must be greater than or equal to 0."
  ),
  minExperience: Yup.number()
    .required(CONSTANTS.MIN_EXPERIENCE.validation.required)
    .min(0, CONSTANTS.MIN_EXPERIENCE.validation.valid)
    .max(99, CONSTANTS.MIN_EXPERIENCE.validation.maxValid),
  maxExperience: Yup.number()
    .required(CONSTANTS.MAX_EXPERIENCE.validation.required)
    .min(1, CONSTANTS.MAX_EXPERIENCE.validation.valid)
    .max(99, CONSTANTS.MAX_EXPERIENCE.validation.maxValid)
    .test(
      "minExperience",
      "Max exp must be equal to or greater than min exp.",
      function(value) {
        if (value) {
          const { minExperience } = this.parent;
          return minExperience <= value;
        } else {
          return true;
        }
      }
    ),
  minCompensation: Yup.number()
    .min(0, CONSTANTS.MIN_COMPENSATION.validation.valid)
    .test({
      name: "maxCompensation",
      message: "Min is required.",
      test: function(value) {
        const { maxCompensation } = this.parent;
        return (
          !maxCompensation ||
          (maxCompensation && value !== undefined && value !== null)
        );
      },
    }),
  maxCompensation: Yup.number()
    .min(0, CONSTANTS.MAX_COMPENSATION.validation.valid)
    .test(
      "minCompensation",
      CONSTANTS.MAX_COMPENSATION.validation.valid,
      function(value) {
        if (value) {
          const { minCompensation } = this.parent;
          return minCompensation <= value;
        } else {
          return true;
        }
      }
    )
    .test({
      name: "minCompensation",
      message: "Max is required.",
      test: function(value) {
        const { minCompensation } = this.parent;
        return (
          !minCompensation ||
          (minCompensation && value !== undefined && value !== null)
        );
      },
    }),
  qualification: Yup.array()
    .required()
    .min(1, CONSTANTS.REQUIRED_QUALIFICATION.validation.valid)
    .of(Yup.string().required()),
  hiringLead: Yup.string()
    .required(CONSTANTS.HIRING_LEAD.validation.required)
    .min(1, CONSTANTS.HIRING_LEAD.validation.valid),
  jobTeamMember: Yup.array()
    .required(CONSTANTS.JOB_TEAM_MEMBER.validation.required)
    .min(1, CONSTANTS.JOB_TEAM_MEMBER.validation.valid),
  skillSet: Yup.array()
    .required()
    .min(1, CONSTANTS.SKILL_SET.validation.valid)
    .of(Yup.string().required()),
  description: Yup.string()
    .min(1, CONSTANTS.JOBDESCRIPTION.validation.valid)
    .required(CONSTANTS.JOBDESCRIPTION.validation.valid),
  baseSalary: Yup.string().required("Base salary is required."),
  frequency: Yup.string().required("Friquency is required."),
  minBaseSalary: Yup.string().when("baseSalary", {
    is: (value) => value === "Range",
    then: (schema) =>
      schema
        .required("Min base salary is required.")
        .min(1, CONSTANTS.MIN_COMPENSATION.validation.valid)
        .test({
          name: "maxBaseSalary",
          message: "Min is required.",
          test: function(value) {
            const { maxBaseSalary } = this.parent;
            return (
              !maxBaseSalary ||
              (maxBaseSalary && value !== undefined && value !== null)
            );
          },
        }),
    otherwise: (schema) => schema,
  }),
  maxBaseSalary: Yup.string().when("baseSalary", {
    is: (value) => value === "Range",
    then: (schema) =>
      schema
        .required("Max base salary is required.")
        .min(1, CONSTANTS.MAX_COMPENSATION.validation.valid)
        .test(
          "minBaseSalary",
          CONSTANTS.MAX_COMPENSATION.validation.valid,
          function(value) {
            if (value) {
              const { minBaseSalary } = this.parent;
              return minBaseSalary <= +value;
            } else {
              return true;
            }
          }
        )
        .test({
          name: "minBaseSalary",
          message: "Max is required.",
          test: function(value) {
            const { minBaseSalary } = this.parent;
            return (
              !minBaseSalary ||
              (minBaseSalary && value !== undefined && value !== null)
            );
          },
        }),
    otherwise: (schema) => schema,
  }),
  starttingAmount: Yup.string().when("baseSalary", {
    is: (value) => value === "Starting at",
    then: (schema) => schema.required("Amount is required."),
    otherwise: (schema) => schema,
  }),
  uptoAmount: Yup.string().when("baseSalary", {
    is: (value) => value === "Up to",
    then: (schema) => schema.required("Amount is required."),
    otherwise: (schema) => schema,
  }),
  exactAmount: Yup.string().when("baseSalary", {
    is: (value) => value === "Exact Pay",
    then: (schema) => schema.required("Amount is required."),
    otherwise: (schema) => schema,
  }),
});

export const editCompanyProfileSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(1, CONSTANTS.COMPANYNAME.validation.valid)
    .required(CONSTANTS.COMPANYNAME.validation.valid),
  industryType: Yup.array()
    .required()
    .min(1, CONSTANTS.INDUSTRY_TYPE.validation.valid)
    .of(Yup.string().required()),
  // zipcode: Yup.string()
  //   .max(6, CONSTANTS.ZIPCODE.validation.valid)
  //   .min(4, CONSTANTS.ZIPCODE.validation.valid),
  linkedin: Yup.string().matches(
    LinkedInRegex,
    CONSTANTS.LINKEDIN.validation.valid
  ),
  websiteLink: Yup.string().matches(
    URLRegex,
    CONSTANTS.WEBSITE_LINK.validation.valid
  ),
  // country: Yup.string().required(CONSTANTS.COUNTRY.validation.valid),
  // state: Yup.string().required(CONSTANTS.STATE.validation.valid),
  // city: Yup.string().required(CONSTANTS.CITY.validation.valid),
  zipcode: Yup.string().when("country", {
    is: (country) => country === 'United States',
    then: (s) => s.required("Please enter zipcode"),
  }),
});

export const myProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1, CONSTANTS.FIRST_NAME.validation.valid)
    .required(CONSTANTS.FIRST_NAME.validation.valid),
  lastName: Yup.string()
    .min(1, CONSTANTS.LAST_NAME.validation.valid)
    .required(CONSTANTS.LAST_NAME.validation.valid),
  phoneNumber: Yup.string().matches(
    phoneNumberRegex,
    CONSTANTS.PHONE_NUMBER.validation.valid
  ),
});

export const addEmployeeSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1, CONSTANTS.FIRST_NAME.validation.valid)
    .required(CONSTANTS.FIRST_NAME.validation.valid),
  lastName: Yup.string()
    .min(1, CONSTANTS.LAST_NAME.validation.valid)
    .required(CONSTANTS.LAST_NAME.validation.valid),
  phoneNumber: Yup.string().matches(
    phoneNumberRegex,
    CONSTANTS.PHONE_NUMBER.validation.valid
  ),
  email: Yup.string()
    .email(CONSTANTS.EMAIL.validation.valid)
    .required(CONSTANTS.EMAIL.validation.required),
  role: Yup.string().required(CONSTANTS.ROLE.validation.valid),
});

export const addTagSchema = Yup.object().shape({
  tagName: Yup.string().trim().required('Tag name is required'),
  colorCode: Yup.string().trim().required('Color code is required')
});
