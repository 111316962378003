import React, { useEffect, useState } from "react";
import SideBar from "@/components/layout/SideBar";
import NotificationIcon from "@/assets/svgs/NotificationIcon";
import CheckIconWhite from "@/assets/svgs/CheckIconWhite";
import ArrowRightIcon from "@/assets/svgs/ArrowRightIcon";
import { useDispatch, useSelector } from "react-redux";
import { getTwoCharacterInitial, GetUserDetails } from "@/utils/helper";
import getAllNotificationsList from "@/rtk-apis/customer/notifications/getAllNotificationsList";
import MarkNotificationAsRead from "@/rtk-apis/customer/notifications/MarkNotificationAsRead";
import ReactTimeago from "react-timeago";
import deleteNotifications from "@/rtk-apis/customer/notifications/deleteNotifications";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import Loader from "@/elements/Loader";
import CustomPagination from "@/elements/pagination/customPagination";
import GoTOPage from "@/elements/gotopage/gotopage";
import { closeDeleteNotificationsModal } from "@/store/slices/customer/notifications/deleteNotificationsSlice";
import BackArrow from "@/assets/svgs/BackArrow";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowDown from "@/assets/svgs/ArrowDown";
import moment from "moment";

const ViewAllNotifications = () => {
  const loggedInUser = GetUserDetails();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [hoveredNotificationId, setHoveredNotificationId] = useState(null);
  const [OpenConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectDeselectLabel, setSelectDeselectLabel] = useState("");
  const [FilterValue, setFilterValue] = useState("All");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // **************** STATES *************************************************

  const { data, loading, meta } = useSelector((globalState) => {
    return globalState.getAllNotificationsResponse;
  });

  const ReadNotificationRes = useSelector((globalState) => {
    return globalState.markNotificationAsReadRes;
  });

  const DeleteNotificationsRes = useSelector((globalState) => {
    return globalState.deleteNotificationsRes;
  });

  // ********************* USE EFFECTS ****************************************

  useEffect(() => {
    const interval = setInterval(() => {
      getAllNotifications();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getAllNotifications();
  }, [
    ReadNotificationRes?.meta?.status,
    DeleteNotificationsRes?.meta?.status,
    page,
    endDate,
  ]);

  useEffect(() => {
    if (selectedNotifications.length === data?.rows?.length) {
      setSelectDeselectLabel("Deselect All");
    } else {
      setSelectDeselectLabel("Select All");
    }
  }, [selectedNotifications, data]);

  useEffect(() => {
    if (FilterValue) {
      if (FilterValue === "All") {
        setStartDate("");
        setEndDate("");
      } else {
        const value =
          FilterValue === "Today"
            ? "day"
            : FilterValue === "Current Week"
            ? "week"
            : FilterValue === "Current Month"
            ? "month"
            : "year";
        setStartDate(
          moment()
            .startOf(value)
            .format("YYYY-MM-DDTHH:mm:ss")
        );
        setEndDate(
          moment()
            .endOf(value)
            .format("YYYY-MM-DDTHH:mm:ss")
        );
      }
    }
  }, [FilterValue]);

  // ******************** FUNCTIONS ********************************************

  const changePage = (event, value) => {
    setPage(value);
  };

  const getAllNotifications = () => {
    if (loggedInUser?.usertype === "customer") {
      let body = {
        notificationFor: loggedInUser?.usertype,
        companyId: loggedInUser?.customer?.company?.id,
        employeeid: loggedInUser?.customer?.employeeId,
        page,
        limit: 20,
        filterOption: FilterValue,
        startDate,
        endDate,
      };
      dispatch(getAllNotificationsList(body));
    } else {
      let body = {
        id: loggedInUser?.recruiter?.recruiterId,
        notificationFor: loggedInUser?.usertype,
        page,
        limit: 20,
        filterOption: FilterValue,
        startDate,
        endDate,
      };
      dispatch(getAllNotificationsList(body));
    }
  };
  const handleReadNotification = (item) => {
    if (item?.status === "Unread") {
      if (!selectedNotifications?.length) {
        if (loggedInUser?.usertype === "customer") {
          let body = {
            notificationId: item?.notificationId,
            readAll: false,
            notificationFor: loggedInUser?.usertype,
            companyId: loggedInUser?.customer?.company?.id,
            employeeid: loggedInUser?.customer?.employeeId,
          };
          dispatch(MarkNotificationAsRead(body));
        } else {
          const body = {
            notificationId: item?.notificationId,
            id: loggedInUser?.recruiter?.recruiterId,
            notificationFor: loggedInUser?.usertype,
          };
          dispatch(MarkNotificationAsRead(body));
        }
      }
    }
  };

  const handleMarkAllAsRead = () => {
    if (loggedInUser?.usertype === "customer") {
      const body = {
        notificationId: null,
        readAll: true,
        notificationFor: loggedInUser?.usertype,
        companyId: loggedInUser?.customer?.company?.id,
        employeeid: loggedInUser?.customer?.employeeId,
      };
      dispatch(MarkNotificationAsRead(body));
    } else {
      const body = {
        notificationId: null,
        readAll: true,
        id: loggedInUser?.recruiter?.recruiterId,
        notificationFor: loggedInUser?.usertype,
      };
      dispatch(MarkNotificationAsRead(body));
    }
  };

  const handleSelectDeselectAll = () => {
    if (selectedNotifications.length === data?.rows?.length) {
      setSelectedNotifications([]);
    } else {
      const allNotificationIds = data?.rows?.map((item) => item.notificationId);
      setSelectedNotifications(allNotificationIds);
    }
  };

  const handleToggleNotificationSelection = (notificationId) => {
    setSelectedNotifications((prevSelected) => {
      if (prevSelected.includes(notificationId)) {
        return prevSelected.filter((id) => id !== notificationId);
      } else {
        return [...prevSelected, notificationId];
      }
    });
  };

  const handleMouseEnter = (notificationId) => {
    setHoveredNotificationId(notificationId);
  };

  const handleMouseLeave = () => {
    setHoveredNotificationId(null);
  };

  const handleDeleteNotifications = () => {
    let body = {
      notificationIdArray: selectedNotifications,
    };
    dispatch(deleteNotifications(body));
  };

  const closeRTKModal = () => {
    setOpenConfirmationModal(false);
    dispatch(closeDeleteNotificationsModal());
    setSelectedNotifications([]);
  };

  const handleNavigateToNotificationOrigin = (item) => {
    if (!selectedNotifications?.length) {
      if (item?.navigateTo !== null) {
        handleReadNotification(item);
        navigate(`/${item?.navigateTo}`);
      }
    }
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid layoutGrid--full">
          <div className="layoutGrid__col">
            <div className="page page__full">
              <div className="page__head">
                <h2 className="page__head--title">
                  Notification
                </h2>
                <div className="listCol__head--actions">
                  <button
                    className="btn btn__white btn--notification btn--notification--active"
                    type="button"
                  >
                    <NotificationIcon />
                  </button>
                </div>
              </div>
              <div className="page__body">
                <div className="details">
                  <div className="notificationDetails">
                    <div className="notificationDetails__header">
                      <div className="notificationDetails__header__title">
                        <div className="title">
                          All Notifications{" "}
                          <span className="count">{data?.count}</span>
                        </div>
                        <div className="formField">
                          <FormControl
                            fullWidth
                            size="small"
                            className="formField__select"
                          >
                            <Select
                              onChange={(e) => setFilterValue(e?.target?.value)}
                              IconComponent={() => <ArrowDown />}
                              name="Status"
                              value={FilterValue}
                              displayEmpty
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span
                                      style={{
                                        opacity: "0.8",
                                      }}
                                    >
                                      Select
                                    </span>
                                  );
                                }

                                return selected;
                              }}
                            >
                              <MenuItem key={"All"} value={"All"}>
                                All
                              </MenuItem>
                              <MenuItem key={"Today"} value={"Today"}>
                                Today
                              </MenuItem>
                              <MenuItem
                                key={"Current Week"}
                                value={"Current Week"}
                              >
                                Current Week
                              </MenuItem>
                              <MenuItem
                                key={"Current Month"}
                                value={"Current Month"}
                              >
                                Current Month
                              </MenuItem>
                              <MenuItem
                                key={"Current Year"}
                                value={"Current Year"}
                              >
                                Current Year
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {data?.count > 0 ? (
                        <button className="link" onClick={handleMarkAllAsRead}>
                          Mark all as read
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    {data?.count > 0 ? (
                      <div className="notificationDetails__body">
                        <div className="notificationDetails__body__actions">
                          <button className="btn btn__primary">
                            {selectedNotifications?.length ===
                            data?.rows?.length
                              ? "All"
                              : "Selected"}{" "}
                            <span>{selectedNotifications?.length}</span>
                          </button>
                        </div>
                        <div className="notificationDetails__body__header">
                          <div className="btn__group btn__group--end w_100">
                            <button
                              className="link"
                              onClick={handleSelectDeselectAll}
                            >
                              {selectDeselectLabel}
                            </button>
                            {selectedNotifications?.length > 0 ? (
                              <button
                                className="link link__danger"
                                onClick={() => setOpenConfirmationModal(true)}
                              >
                                Delete
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="notificationList">
                          {data?.rows?.length > 0 &&
                            data?.rows?.map((item, index) => {
                              const isSelected = selectedNotifications.includes(
                                item.notificationId
                              );
                              return (
                                <div
                                  key={index}
                                  className={`item ${
                                    item?.status === "Read"
                                      ? ""
                                      : "item--unread"
                                  } ${
                                    isSelected ? "item--selectedRemove" : ""
                                  } ${
                                    hoveredNotificationId ===
                                    item.notificationId
                                      ? "item--selected"
                                      : ""
                                  }`}
                                  onClick={() => handleReadNotification(item)}
                                >
                                  <div
                                    className="initials initials__md"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleToggleNotificationSelection(
                                        item.notificationId
                                      );
                                    }}
                                    onMouseEnter={() =>
                                      handleMouseEnter(item.notificationId)
                                    }
                                    onMouseLeave={handleMouseLeave}
                                  >
                                    {isSelected ? (
                                      <CheckIconWhite />
                                    ) : hoveredNotificationId ===
                                      item.notificationId ? (
                                      <div className="initials initials__md">
                                        Click to Select
                                      </div>
                                    ) : (
                                      getTwoCharacterInitial(item?.addedByName)
                                    )}
                                  </div>
                                  <div className="item__info">
                                    <h4 className="item__info--title">
                                      {item?.notificationTitle}
                                    </h4>
                                    <div className="item__info--subtitle">
                                      <p>
                                        <ReactTimeago
                                          date={new Date(item?.createdAt)}
                                        />
                                      </p>
                                    </div>
                                    <span
                                      className="arrowRight"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleNavigateToNotificationOrigin(
                                          item
                                        );
                                      }}
                                    >
                                      <ArrowRightIcon />
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <div className="notificationDetails__noData">
                        <div className="no-data floating tableFloating">
                          No Results Found!
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pagination">
            <div className="pagination__result">
              {data?.count > 0
                ? `${data?.count > page * 20 ? 20 : data?.count} of ${
                    data?.count
                  } results`
                : ""}
            </div>
            {data?.count ? (
              <CustomPagination
                totalPageCount={Math.ceil(data?.count / 20)}
                changePage={(event, value) => changePage(event, value)}
                page={page}
              />
            ) : (
              ""
            )}
            {data?.count ? (
              <div className="pagination__actions">
                <GoTOPage changePage={changePage} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {OpenConfirmationModal && (
        <ConfirmationModal
          closeModal={() => setOpenConfirmationModal(false)}
          onSubmit={handleDeleteNotifications}
          heading={"Delete Notifications!"}
          confirmationMessage={
            "Are you sure you want to delete the selected notifications?"
          }
        />
      )}
      {DeleteNotificationsRes?.meta?.status && (
        <SuccessModal
          closeModal={closeRTKModal}
          sucessfullMsg={DeleteNotificationsRes?.meta?.msg}
          Heading={"Notifications Deleted!"}
        />
      )}
      {DeleteNotificationsRes?.meta?.status === false && (
        <ErrorModal
          errorMessage={DeleteNotificationsRes?.meta?.msg}
          closeModal={closeRTKModal}
          footerVisible={true}
        />
      )}
      {(DeleteNotificationsRes?.loading || loading) && <Loader />}
    </>
  );
};

export default ViewAllNotifications;
