import { createSlice } from "@reduxjs/toolkit";
import getAllNotificationsList from "@/rtk-apis/customer/notifications/getAllNotificationsList";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const getAllNotificationsListSlice = createSlice({
  name: "getAllNotificationsListSlice",
  initialState,
  reducers: {
    closeRtkModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllNotificationsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllNotificationsList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(getAllNotificationsList.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default getAllNotificationsListSlice.reducer;

export const { closeRtkModal } = getAllNotificationsListSlice.actions;
