import React from "react";

function AddressIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1867_10112)">
        <path
          d="M5.33329 12.0002L0.666626 14.6668V4.00016L5.33329 1.3335M5.33329 12.0002L10.6666 14.6668M5.33329 12.0002V1.3335M10.6666 14.6668L15.3333 12.0002V1.3335L10.6666 4.00016M10.6666 14.6668V4.00016M10.6666 4.00016L5.33329 1.3335"
          stroke="#384250"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1867_10112">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AddressIcon;
