import React, { useEffect, useState } from "react";
import SideBar from "@/components/layout/SideBar";
import JobRequestsListing from "@/pages/JobRequests/JobRequestsListing";
import JobRequestDetails from "@/pages/JobRequests/JobRequestDetails";
import { useSelector } from "react-redux";

const JobRequests = () => {
  const { data } = useSelector((globalState) => {
    return globalState.jobRequestsListData;
  });
  const [responsive, setResponsive] = useState(false);
  const [selectedJobRequest, setSelectedJobRequest] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (data?.rows?.length) setSelectedJobRequest(data.rows[0]);
    else setSelectedJobRequest({});
  }, [data]);

  const indexChanged = (index) => {
    setResponsive(true);
    setSelectedIndex(index);
    setSelectedJobRequest(data.rows[index]);
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid">
          <JobRequestsListing
            selectedIndex={selectedIndex}
            changeIndex={(i) => indexChanged(i)}
          />
          <div 
            className={`layoutGrid__col layoutGrid--responsive ${
              responsive ? "layoutGrid--show" : ""
            }`}
          >
            <JobRequestDetails 
              selectedJobRequest={selectedJobRequest} 
              removeResponsiveClass={() => setResponsive(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default JobRequests;
