import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowDown from '@/assets/svgs/ArrowDown';
import { MenuProps } from '@/utils/constants';
import { firstLetterCapital } from '@/utils/helper';

const ScheduleInterviewModal = ({ closeModal, onSubmit, data = [] }) => {
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const handleSelectChange = (e) => {
    const {
      target: { value }
    } = e;

    setSelectedEmployee(typeof value === 'string' ? value.split(',') : value);
  };

  const checkEmailExist = (emplyees, email) => {
    const emailarr = emplyees.filter((emp) => emp?.email === email);
    return !!emailarr?.length;
  };

  return (
    <>
      <div className={'modal modalPrompt modal--open'}>
        <div className="modal__dialog">
          <div className="modal__content">
            <div className="modal__header">
              <h2 className="modal__header--title">Schedule Interview</h2>
            </div>
            <div className="modal__body">
              <div className="formField">
                <FormControl
                  sx={{ width: '100%' }}
                  size="small"
                  className="formField__select"
                >
                  <Select
                    id="multiple-checkbox"
                    multiple
                    value={selectedEmployee}
                    onChange={handleSelectChange}
                    name="skillSet"
                    renderValue={(selected) => {
                      if (selected?.length) {
                        const names = selected.map(
                          (a) =>
                            firstLetterCapital(a.firstName) +
                            ' ' +
                            firstLetterCapital(a.lastName)
                        );
                        return names.join(', ');
                      } else {
                        return 'Select a member';
                      }
                    }}
                    MenuProps={MenuProps}
                    displayEmpty
                    IconComponent={() => <ArrowDown />}
                    classes={{ root: 'multiSelect' }}
                  >
                    {data?.map((item) => (
                      <MenuItem key={item?.employeeId} value={item}>
                        <Checkbox
                          checked={checkEmailExist(
                            selectedEmployee,
                            item?.email
                          )}
                        />
                        <ListItemText
                          primary={
                            firstLetterCapital(item?.firstName) +
                            ' ' +
                            firstLetterCapital(item?.lastName)
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button className="btn btn__white" onClick={() => closeModal()}>
                  Cancel
                </button>
                <button
                  className="btn btn__primary"
                  onClick={() => onSubmit(selectedEmployee)}
                >
                  Schedule
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default ScheduleInterviewModal;
