import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import SideBar from "@/components/layout/SideBar";
import RedInfoIcon from "@/assets/svgs/RedInfoIcon";
import InfoIcon from "@/assets/svgs/InfoIcon";
import Loader from "@/elements/Loader";
import BankingImg from "@/assets/images/banking-icon.png";
import CustomerACHPaymentModal from "@/components/modals/CustomerACHPaymentModal";
import AddCustomerACHDetailModal from "@/components/modals/AddCustomerACHDetailModal";
import CustomerCardPaymentModal from "@/components/modals/CustomerCardPaymentModal";
import PaymentMethodsList from "@/customer-pages/Payments/PaymentMethodsList";
import getPaymentMethods from "@/rtk-apis/customer/payments/getPaymentMethods";
import NotificationButton from "../../components/elements/notificationButton";

const CustomerPayment = () => {
  const dispatch = useDispatch();
  const [editMethodData, setEditMethodData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openACHModal, setOpenACHModal] = useState(false);
  const [openCardModal, setOpenCardModal] = useState(false);
  const [PrimaryPaymentMethod, setPrimaryPaymentMethod] = useState([]);
  const [PaymentMethodList, setPaymentMethodList] = useState([]);
  const [OpenNotificationModal, setOpenNotificationModal] = useState(false);

  const { data, loading, meta } = useSelector((globalState) => {
    return globalState.getpaymentMethods;
  });

  useEffect(() => {
    if (data?.paymentMethodDetail?.length) {
      const primaryArray = data?.paymentMethodDetail?.filter(
        (item) => item.default === true
      );
      setPrimaryPaymentMethod(primaryArray);
      const additionalMethodArray = data?.paymentMethodDetail?.filter(
        (item) => !item.default
      );
      setPaymentMethodList(additionalMethodArray);
    } else {
      setPrimaryPaymentMethod([]);
      setPaymentMethodList([]);
    }
  }, [data]);

  useEffect(() => {
    getPaymentMethodsList();
  }, []);

  const getPaymentMethodsList = () => {
    dispatch(getPaymentMethods());
  };

  const openACHDetailModal = () => {
    setOpenModal(false);
    setOpenACHModal(true);
  };

  const openCardDetailModal = () => {
    setOpenModal(false);
    setOpenCardModal(true);
  };

  const openCustomerACHDetailModal = (data) => {
    setEditMethodData(data);
    setOpenACHModal(true);
  };

  const refreshPage = () => {
    getPaymentMethodsList();
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid layoutGrid--full">
          <div className="dataCol">
            <div className="page page__full">
              <div className="page__head">
                <h2 className="page__head--title">Payments</h2>
                <NotificationButton
                  OpenNotificationModal={OpenNotificationModal}
                  setOpenNotificationModal={setOpenNotificationModal}
                />
              </div>
              <div className="page__body">
                <div className="details details--paymentDetails">
                  <section className="details__section">
                    <h4 className="details__section--title">Billing Methods</h4>
                    <div className="paymentDetail">
                      <div className="paymentDetail__box paymentDetail__box--900">
                        <p>
                          You haven’t set up any billing methods yet. Your
                          billing method will be charged only when your
                          available balance from Laila earnings is not
                          sufficient to pay for your monthly membership and/or
                          Connects.
                        </p>
                        <button
                          className="btn btn__primary"
                          onClick={() => setOpenModal(true)}
                        >
                          Add payment method
                        </button>
                      </div>
                    </div>
                  </section>
                  <PaymentMethodsList
                    PaymentMethodList={PaymentMethodList}
                    PrimaryPaymentMethod={PrimaryPaymentMethod}
                    openACHDetailModal={openCustomerACHDetailModal}
                    openPaymentModal={() => setOpenModal(true)}
                    refreshPage={refreshPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      {openModal && (
        <CustomerACHPaymentModal
          openModal={openModal}
          openACHDetailModal={() => openACHDetailModal()}
          openCardDetailModal={() => openCardDetailModal()}
          closeModal={() => setOpenModal(false)}
        />
      )}
      {openACHModal && (
        <AddCustomerACHDetailModal
          openModal={openACHModal}
          closeModal={() => setOpenACHModal(false)}
          data={editMethodData}
          refreshPage={refreshPage}
        />
      )}
      {openCardModal && (
        <CustomerCardPaymentModal
          openModal={openCardModal}
          closeModal={() => setOpenCardModal(false)}
          refreshPage={refreshPage}
          setOpenCardModal={setOpenCardModal}
        />
      )}
    </>
  );
};

export default CustomerPayment;
