import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OpenEye from "@/assets/svgs/OpenEye";
import CloseEye from "@/assets/svgs/CloseEye";
import AuthImage from "@/assets/images/auth-img.png";
import KeyLockSvg from "@/assets/svgs/KeyLockSvg";
import { resetSchema } from "@/components/schema/schema";
import PasswordLock from "@/assets/svgs/PasswordLock";
import { Formik, Form, Field } from "formik";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader from "@/elements/Loader";
import resetPassword from "@/rtk-apis/resetPassword";
import { SuccessModal } from "@/components/modals/SuccessModal";
import { closeRtkModal } from "@/store/slices/ResetPasswordSlice";
import ErrorModal from "@/components/modals/error-modal";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [eyeForPassword, seteyeForPassword] = useState(false);
  const [eyeForConfirmPassword, seteyeForConfirmPassword] = useState(false);

  const url = new URL(window?.location?.href);
  const searchParams = url.searchParams;
  const RegisteredEmail = searchParams.get("email");
  const key = searchParams.get("key");

  let initialValues = {
    password: "",
    confirmPassword: "",
  };

  const closeModal = () => {
    dispatch(closeRtkModal());
    navigate("/customer-dashboard");
  };

  const closeErrorModal = () => {
    dispatch(closeRtkModal());
  };

  const { loading, meta } = useSelector((globalState) => {
    return globalState.resetPassword;
  });

  const handleSubmit = (values) => {
    dispatch(
      resetPassword({
        email: RegisteredEmail,
        role: "recruiter",
        password: values?.password,
        key
      })
    );
  };

  return (
    <>
      <div className="container__fullScreen bg--light">
        <div className="auth__container">
          <Formik
            initialValues={initialValues}
            validationSchema={resetSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ errors, touched }) => (
              <Form className="auth__form">
                <div className="auth__form--logo">
                  <KeyLockSvg />
                </div>
                <img
                  src={AuthImage}
                  className="auth__form--img"
                  alt="Authentication"
                />
                <h4 className="auth__form--heading">Password Recovery</h4>
                <div
                  className={`formField formField__mb5 ${
                    errors?.password && touched?.password ? "fieldError" : ""
                  }`}
                >
                  <div className="formField__group bg--light">
                    <span className="formField__icon">
                      <PasswordLock />
                    </span>
                    <Field
                      type={eyeForPassword ? "text" : "password"}
                      className="formField__input bg--light"
                      placeholder="Enter new password"
                      name="password"
                      autoComplete="off"
                    />
                    <div
                      onClick={() => seteyeForPassword(!eyeForPassword)}
                      className="formField__icon"
                    >
                      <span>{eyeForPassword ? <OpenEye /> : <CloseEye />}</span>
                    </div>
                  </div>
                  <span className="formField__error">
                    {errors?.password &&
                    touched?.password &&
                    errors?.password ? (
                      errors?.password && touched?.password && errors?.password
                    ) : (
                      <i>
                        * The password must contain 8 characters and a
                        combination of lowercase letters, uppercase letters,
                        numbers, and special characters.
                      </i>
                    )}
                  </span>
                </div>
                <div
                  className={`formField formField__mb5 ${
                    errors?.confirmPassword && touched?.confirmPassword
                      ? "fieldError"
                      : ""
                  }`}
                >
                  <div className="formField__group bg--light">
                    <span className="formField__icon">
                      <PasswordLock />
                    </span>
                    <Field
                      type={eyeForConfirmPassword ? "text" : "password"}
                      className="formField__input bg--light"
                      placeholder="Confirm new password"
                      name="confirmPassword"
                      autoComplete="off"
                    />
                    <div
                      onClick={() =>
                        seteyeForConfirmPassword(!eyeForConfirmPassword)
                      }
                      className="formField__icon"
                    >
                      <span>
                        {eyeForConfirmPassword ? <OpenEye /> : <CloseEye />}
                      </span>
                    </div>
                  </div>
                  <span className="formField__error">
                    {errors?.confirmPassword &&
                      touched?.confirmPassword &&
                      errors?.confirmPassword}
                  </span>
                </div>
                <button
                  type="submit"
                  className="btn btn__primary auth__form--submit"
                >
                  Submit
                </button>
                <div className="auth__form--divider">
                  <span>Or</span>
                </div>
                <Link to="/" className="link link__primary">
                  Back to Sign in
                </Link>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {meta?.status && (
        <SuccessModal
          closeModal={closeModal}
          sucessfullMsg={meta?.msg}
          Heading={"Password Changed!"}
        />
      )}
      {meta?.status === false && (
        <ErrorModal
          errorMessage={meta?.msg}
          closeModal={closeErrorModal}
          footerVisible={true}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default ResetPassword;
