import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { CHECK_SCHEDUAL_PAGE } from '@/rtk-apis/end-points';

const checkSchedulePage = createAsyncThunk('checkSchedulePage', async (body) => {
  try {
    const response = await httpService.post(CHECK_SCHEDUAL_PAGE, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default checkSchedulePage;
