import React, { useState, useEffect } from "react";
import SideBar from "@/components/layout/SideBar";
import BillingPaymentsListing from "@/customer-pages/BillingPayments/BillingPaymentsListing";
import { BillingCandidateListing } from "@/customer-pages/BillingPayments/BillingCandidateListing";
import { useDispatch, useSelector } from "react-redux";
import { GetUserDetails } from "@/utils/helper";
import billingJobListing from "@/rtk-apis/customer/billingAndPayments//billingJobListing";

function BillingPayments() {
  const dispatch = useDispatch();
  const userDetails = GetUserDetails();
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("All");
  const [search, setSearch] = useState("");
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [billingStatus, setBillingStatus] = useState(false);
  const [addResponsiveClass, setAddResponsiveClass] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [candidateStatus, setCandidateStatus] = useState("All");


  const { data, loading } = useSelector((globalState) => {
    return globalState.billingPaymentJobsListing;
  });

  const [selectedJob, setSelectedJob] = useState("");

  // **************FUNCTIONS******************************
  const getJobsListing = () => {
    let body = {
      companyId: userDetails?.customer?.companyId,
      page,
      limit: 20,
      status,
      role: "Finance Admin",
      search,
    };
    dispatch(billingJobListing(body));
  };

  // ****************USE EFFECTS****************
  useEffect(() => {
    if (data?.rows?.length > 0) setSelectedJob(data?.rows[0]?.jobId);
  }, [data]);

  const resetStartEndDateOnJobChange=()=>{
    setEndDate("")
    setStartDate("")
    setCandidateStatus("All")
  }

  useEffect(() => {
    getJobsListing();
  }, [search, page, filtersApplied]);

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid">
          <BillingPaymentsListing
            JobsData={data}
            selectedJob={selectedJob}
            setSelectedJob={setSelectedJob}
            loading={loading}
            setPage={setPage}
            page={page}
            status={status}
            setStatus={setStatus}
            search={search}
            setSearch={setSearch}
            setFiltersApplied={setFiltersApplied}
            filtersApplied={filtersApplied}
            setBillingStatus={setBillingStatus}
            setAddResponsiveClass={setAddResponsiveClass}
            resetStartEndDateOnJobChange={resetStartEndDateOnJobChange}
          />

          <BillingCandidateListing
            selectedJob={selectedJob}
            billingStatus={billingStatus}
            jobdata={data}
            addResponsiveClass={addResponsiveClass}
            setAddResponsiveClass={setAddResponsiveClass}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
            setCandidateStatus={setCandidateStatus}
            candidateStatus={candidateStatus}
          />
        </div>
      </div>
    </>
  );
}

export default BillingPayments;
