import { createSlice } from "@reduxjs/toolkit";
import uploadCustomerLogo from "@/rtk-apis/customer/companyProfile/uploadCustomerLogo";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const uploadLogoSlice = createSlice({
  name: "getStateCityData",
  initialState,
  reducers: {
    removeLogo(state, action) {
      state.data = {};
      state.meta = {};
    },
    closeUploadErrorModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadCustomerLogo.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(uploadCustomerLogo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(uploadCustomerLogo.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default uploadLogoSlice.reducer;

export const { removeLogo, closeUploadErrorModal } = uploadLogoSlice.actions;
