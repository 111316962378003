import React, { useState } from "react";
import SideBar from "@/components/layout/SideBar";
import InterviewsListing from "@/customer-pages/Interviews/InterviewsListing";
import InterviewsDetails from "@/customer-pages/Interviews/InterviewsDetails";

function Interviews() {
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState("1");
  const [addResponsiveClass, setAddResponsiveClass] = useState(false);
  const [selectedRecruiterInterview, setSelectedRecruiterInterview] = useState(
    {}
  );

  const changeIndex = (index, candidate) => {
    setSelectedCandidate(candidate);
    setSelectedIndex(index);
  };

  const selectedInterviewForRecruiter = (index, value) => {
    setSelectedRecruiterInterview(value);
    setSelectedIndex(index);
  };
  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid">
          <InterviewsListing
            changeIndex={(index, candidate) => changeIndex(index, candidate)}
            selectedCandidate={selectedCandidate}
            selectedIndex={selectedIndex}
            changeTab={(value) => setSelectedTab(value)}
            selectedRecruiterInterview={(index, value) =>
              selectedInterviewForRecruiter(index, value)
            }
            setAddResponsiveClass={setAddResponsiveClass}
          />
          <InterviewsDetails
            selectedCandidate={selectedCandidate}
            selectedTab={selectedTab}
            selectedRecruiterInterview={selectedRecruiterInterview}
            addResponsiveClass={addResponsiveClass}
            setAddResponsiveClass={setAddResponsiveClass}
          />
        </div>
      </div>
    </>
  );
}

export default Interviews;
