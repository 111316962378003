import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { GET_RECRUITER_DETAILS } from "@/rtk-apis/end-points";

const getRecruiterDetails = createAsyncThunk(
  "getRecruiterDetails",
  async (recruiterId) => {
    try {
      const response = await httpService.get(
        `${GET_RECRUITER_DETAILS}${recruiterId}`,
        {}
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export default getRecruiterDetails;
