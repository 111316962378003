import React from "react";

const PaymentsSvg = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 0C0.677734 0 0 0.677734 0 1.5V8.5C0 9.32226 0.677734 10 1.5 10H12.5C13.3223 10 14 9.32226 14 8.5V1.5C14 0.677734 13.3223 0 12.5 0H1.5ZM1.5 1H12.5C12.7832 1 13 1.2168 13 1.5V8.5C13 8.7832 12.7832 9 12.5 9H1.5C1.2168 9 1 8.7832 1 8.5V1.5C1 1.2168 1.2168 1 1.5 1ZM5 1.5C3.07227 1.5 1.5 3.07227 1.5 5C1.5 6.92773 3.07227 8.5 5 8.5C5.74219 8.5 6.43164 8.27344 7 7.875C7.56641 8.27148 8.25586 8.5 9 8.5C10.9277 8.5 12.5 6.92773 12.5 5C12.5 3.07227 10.9277 1.5 9 1.5C8.25586 1.5 7.56641 1.72852 7 2.125C6.43164 1.72656 5.74219 1.5 5 1.5ZM5 2.5C5.64062 2.5 6.21484 2.74805 6.65625 3.14062C6.45117 3.39648 6.2832 3.68555 6.17188 4H7.28125C7.35156 4.16016 7.41797 4.32617 7.45312 4.5H6.04688C6.01953 4.66211 6 4.82812 6 5C6 5.17187 6.01953 5.33789 6.04688 5.5H7.45312C7.41797 5.67383 7.35156 5.83984 7.28125 6H6.17188C6.2832 6.31445 6.45117 6.60351 6.65625 6.85937C6.21484 7.25195 5.64062 7.5 5 7.5C3.61328 7.5 2.5 6.38672 2.5 5C2.5 3.61328 3.61328 2.5 5 2.5ZM9 2.5C10.3867 2.5 11.5 3.61328 11.5 5C11.5 6.38672 10.3867 7.5 9 7.5C8.54297 7.5 8.11914 7.37109 7.75 7.15625C8.2207 6.56055 8.5 5.81445 8.5 5C8.5 4.18555 8.2207 3.43945 7.75 2.84375C8.11914 2.62891 8.54297 2.5 9 2.5Z"
        fill="#6C737F"
        fillOpacity="0.8"
      />
    </svg>
  );
};
export default PaymentsSvg;
