import { createSlice } from '@reduxjs/toolkit';
import newCustomerSignUp from '../../../rtk-apis/customer/newCustomerSignUp';

const initialState = {
  message: '',
  data: {},
  meta: {},
  token: '',
  loading: false,
  error: ''
};

const NewCustomerSignUpSlice = createSlice({
  name: 'NewCustomerSignUpSlice',
  initialState,
  reducers: {
    closenewCustModal (state, action) { state.meta = {}; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(newCustomerSignUp.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(newCustomerSignUp.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(newCustomerSignUp.rejected, (state, action) => {
        state.loading = false;
      });
  }
});

export default NewCustomerSignUpSlice.reducer;

export const { closenewCustModal } = NewCustomerSignUpSlice.actions;
