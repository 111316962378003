import React from "react";
import BackIcon from "@/assets/svgs/BackIcon";

function InterviewDetailHeader({ interviewType }) {
  return (
    <>
      <div className="page__head">
        <h2 className="page__head--title">
          <button className="btn btn__transparent btn__back border_0 p_0">
            <BackIcon />
          </button>
          {interviewType == "interviewDetail"
            ? "Interview Details"
            : interviewType == "candidateFeedBack" || "interviewFeedback"
            ? "Feedback"
            : "Reject"}
        </h2>
      </div>
    </>
  );
}

export default InterviewDetailHeader;
