import React, { useState, useEffect } from "react";
import ApprovedCandidatesListings from "@/customer-pages/MyJobs/ApprovedCandidates/ApprovedCandidatesListings";
import ApprovedCandidatesFeedback from "@/customer-pages/MyJobs/ApprovedCandidates/ApprovedCandidatesFeedback";
import { useDispatch, useSelector } from "react-redux";
import getApprovedCandidatesList from "@/rtk-apis/customer/myJobs/approveCandidates/approveCandidatesList";
import { GetUserDetails } from "@/utils/helper";
import getAllTagsList from "@/rtk-apis/customer/tags/getAllTagsList";

function ApprovedCandidates({
  selectedJob,
  RejectedCandidatesTabShow,
  getShortlistScreen,
}) {
  const dispatch = useDispatch();
  const userDetails = GetUserDetails();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [OpenFeedbackScreen, setOpenFeedbackScreen] = useState(false);
  const [CandidateData, setCandidateData] = useState({});
  const [sortByASC, setSortByASC] = useState(true);
  const [sortByColoumn, setSortByColoumn] = useState("");
  const [selectedTagsArr, setSelectedTagsArr] = useState([]);

  const changePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getApprovedCandidates();
  }, [
    search,
    page,
    getShortlistScreen,
    sortByASC,
    sortByColoumn,
    RejectedCandidatesTabShow,
  ]);

  useEffect(() => {
    setOpenFeedbackScreen(false);
  }, [getShortlistScreen]);

  const getRole = () => {
    const role = userDetails?.customer?.role;
    if (role === "Company Admin") {
      return role;
    } else if (
      selectedJob?.hiringLeadArray?.includes(userDetails?.customer?.employeeId)
    ) {
      return "Hiring Lead";
    } else {
      return role;
    }
  };

  const getApprovedCandidates = async (tagArr = selectedTagsArr) => {
    let body = {
      employeeId: userDetails?.customer?.employeeId,
      companyId: userDetails?.customer?.companyId,
      jobId: selectedJob?.jobId,
      role: getRole(),
      status: RejectedCandidatesTabShow ? "Rejected" : "Approved",
      page,
      limit: 20,
      tagArray: tagArr,
      columnName: sortByColoumn,
      sort: sortByASC ? "ASC" : "DESC",
    };
    dispatch(getApprovedCandidatesList(body));
  };

  const { data, loading } = useSelector((globalState) => {
    return globalState.getApprovedCandidatesListing;
  });

  const allFilterTag = useSelector((globalState) => {
    return globalState?.getAllTagsListRes;
  });

  useEffect(() => {
    dispatch(
      getAllTagsList({
        companyId: userDetails?.customer?.companyId,
        tagName: "",
        page: "1",
        limit: "20",
        from:"addTagFetch"
      })
    );
  }, []);

  const handleTagFilterChange = (selectedOption) => {
    setSelectedTagsArr(selectedOption)
    getApprovedCandidates(selectedOption);
  }

  const handleTagFilterClose = () => {
    setSelectedTagsArr([]);
    getApprovedCandidates([]);
  }

  const getSortByData = (columnName) => {
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
  };

  return (
    <>
      {OpenFeedbackScreen ? (
        <ApprovedCandidatesFeedback
          setOpenFeedbackScreen={setOpenFeedbackScreen}
          jobData={CandidateData}
          status={status}
          selectedJob={selectedJob}
        />
      ) : (
        <ApprovedCandidatesListings
          approvedCandidateData={data}
          setOpenFeedbackScreen={setOpenFeedbackScreen}
          page={page}
          changePage={changePage}
          loading={loading}
          setCandidateData={setCandidateData}
          getSortByData={(columnName) => getSortByData(columnName)}
          sortByASC={sortByASC}
          sortByColoumn={sortByColoumn}
          allFilterTag={allFilterTag}
          handleTagFilterChange={handleTagFilterChange}
          handleTagFilterClose={handleTagFilterClose}
        />
      )}
    </>
  );
}

export default ApprovedCandidates;
