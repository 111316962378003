import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { GET_JOB_DETAILS } from "@/rtk-apis/end-points";

const getJobData = createAsyncThunk("getJobData", async (jobId) => {
  try {
    const response = await httpService.get(`${GET_JOB_DETAILS}${jobId}`, {});
    return response;
  } catch (error) {
    return error;
  }
});

export default getJobData;
