import { createSlice } from "@reduxjs/toolkit";
import updateCustomerTags from "@/rtk-apis/customer/settings/customerTags/updateCustomerTags";

const initialState = {
  message: "",
  data: {},
  meta: {},
  loading: false,
  error: "",
};

const updateCustomerTagsSlice = createSlice({
  name: "updateCustomerTagsSlice",
  initialState,
  reducers: {
    closeUpdateTagsModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCustomerTags.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateCustomerTags.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload?.meta;
        state.data = payload?.data;
      })
      .addCase(updateCustomerTags.rejected, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = false;
      });
  },
});

export default updateCustomerTagsSlice.reducer;

export const { closeUpdateTagsModal } = updateCustomerTagsSlice.actions;
