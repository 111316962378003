import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useSelector, useDispatch } from "react-redux";
import getCustomerNotes from "@/rtk-apis/customer/myJobs/notes/getCustomerNotes";
import Loader from "@/elements/Loader";
import { GetUserDetails } from "@/utils/helper";

function Notes({ selectedJob }) {
  const dispatch = useDispatch();
  const user = GetUserDetails();
  const recruiterId = user?.recruiter?.recruiterId;

  const [radioValue, setRadioValue] = useState("Admin");

  const { data, meta, loading } = useSelector((globalState) => {
    return globalState.getCustomerNotesRes;
  });

  useEffect(() => {
    if (selectedJob?.jobId && recruiterId && radioValue) {
      getNotes();
    }
  }, [radioValue]);

  const getNotes = () => {
    let body = {
      jobId: selectedJob?.jobId,
      recruiterId: radioValue === "Admin" ? "Laila Team" : recruiterId,
      from: radioValue,
    };
    dispatch(getCustomerNotes(body));
  };
  const changeRadioButton = (e) => {
    setRadioValue(e?.target?.value);
  };
  return (
    <>
      <div className="page">
        <div className="notes">
          <div className="notes__head">
            <FormControl>
              <RadioGroup defaultValue="byLaila">
                <FormControlLabel
                  value="Admin"
                  control={<Radio />}
                  checked={radioValue === "Admin"}
                  label="By laila"
                  onChange={(e) => changeRadioButton(e)}
                />
                <FormControlLabel
                  value="Customer"
                  control={<Radio />}
                  label="By Customer"
                  checked={radioValue === "Customer"}
                  onChange={(e) => changeRadioButton(e)}
                />
              </RadioGroup>
            </FormControl>
          </div>
          {data.length > 0 &&
          data?.filter((note) => note.from === radioValue).length > 0 ? (
            <ul className="notes__list">
              {data &&
                data
                  ?.filter((note) => note.from === radioValue)
                  .map((item) => {
                    return (
                      <li className="notes__list--item">
                        <div className="notes--title">
                          <p>{item?.title}</p>
                        </div>
                        <div className="notes--description">
                          <div
                            key={new Date().getTime()}
                            className="showQuillTags"
                            dangerouslySetInnerHTML={{
                              __html: item?.description
                                ? item?.description
                                : "--",
                            }}
                          ></div>
                        </div>
                      </li>
                    );
                  })}
            </ul>
          ) : (
            <div className="tableContainer">
              <div className="no-data floating">No Results Found!</div>
            </div>
          )}
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
}

export default Notes;
