import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import ArrowDown from "@/assets/svgs/ArrowDown";
import SearchSmallIcon from "@/assets/svgs/SearchSmallIcon";
import ShareIcon from "@/assets/svgs/ShareIcon";
import { MenuProps } from "@/utils/constants";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SuccessModal } from "@/components/modals/SuccessModal";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import GoTOPage from "@/elements/gotopage/gotopage";
import ErrorModal from "@/components/modals/error-modal";
import {
  formatPhoneNumber,
  firstLetterCapital,
  GetUserDetails,
  saveInterviewDetails,
} from "@/utils/helper";
import candidatesList from "@/rtk-apis/candidates/candidatesList";
import CustomPagination from "@/elements/pagination/customPagination";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader from "@/elements/Loader";
import staticData from "@/rtk-apis/staticData/index";
import downloadJD from "@/rtk-apis/myJobs/downloadJd";
import { cleanJdUrl } from "@/store/slices/myJobs/downloadJDSlice";
import createInterview from "@/rtk-apis/interviews/createInterview";
import { removeNylusData } from "@/store/slices/interviews/createInterviewSlice";
import { APP_URL, LAILA_LOGO } from "@/utils/constants";
import SortColumn from "@/elements/SortColumn";

function InterviewsListing({ showDetails, selectedJob }) {
  const dispatch = useDispatch();
  const user = GetUserDetails();
  const [search, setSearch] = useState("");
  const [candidateList, setCandidateList] = useState([]);
  const [page, setPage] = useState(1);
  const [skillSet, setSkillSet] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [candidate, setCandidate] = useState({});
  const [OpenErrorModal, setOpenErrorModal] = useState(false);
  const [sortByASC, setSortByASC] = useState(true);
  const [sortByColoumn, setSortByColoumn] = useState("");
  const [JobStatus, setJobStatus] = useState("");

  const { data, loading } = useSelector((globalState) => {
    return globalState.candidatesListData;
  });

  const staticDataLocal = useSelector((globalState) => {
    return globalState.static;
  });

  const downloadJDResponse = useSelector((globalState) => {
    return globalState.downloadJDResponse;
  });

  const createInterviewResponse = useSelector((globalState) => {
    return globalState.createInterviewResponse;
  });

  useEffect(() => {
    candidatesListAPIData();
  }, [search, page, qualification, skillSet, sortByASC, sortByColoumn]);

  useEffect(() => {
    dispatch(staticData());
    setCandidateList(data?.rows);
  }, [data]);

  const candidatesListAPIData = () => {
    dispatch(
      candidatesList({
        recruiterId: "",
        page:
          search || skillSet.length > 0 || qualification.length > 0 ? 1 : page,
        limit: 20,
        status: null,
        search,
        skillSet,
        education: qualification,
        experience: [0, 99],
        columnName: sortByColoumn,
        sort: sortByASC ? "ASC" : "DESC",
      })
    );
  };

  const getSortByData = (columnName) => {
    setSortByColoumn(columnName);
    setSortByASC(!sortByASC);
  };

  const changePage = (event, val) => {
    setPage(val);
  };

  const shareResume = (candidate) => {
    dispatch(
      downloadJD({
        jobId: selectedJob?.jobId,
        email: candidate?.email,
      })
    );
  };

  const closeModal = () => {
    dispatch(cleanJdUrl());
  };

  const scheduleMeeting = (candidate) => {
    setCandidate(candidate);
    const token = JSON.parse(localStorage.getItem("token"));
    let { nylasAccessToken } = token?.recruiter;
    dispatch(
      createInterview({
        accessToken: nylasAccessToken,
      })
    );
  };

  const hitNylus = () => {
    saveInterviewDetails({
      companyId: selectedJob?.companyId,
      jobId: selectedJob?.jobId,
      candidateId: candidate?.candidateId,
      employeeId: null,
      meetingTitle: createInterviewResponse?.data?.title,
      meetingId: createInterviewResponse?.data?.id,
      recruiterId: user?.recruiter?.recruiterId,
    });
    const token = JSON.parse(localStorage.getItem("token"));
    let { nylasAccessToken } = token?.recruiter;
    setTimeout(() => {
      window?.nylas.scheduler?.show({
        pageDomain: "",
        auth: {
          accessToken: nylasAccessToken,
        },
        style: {
          tintColor: false ? "#FF0000" : "#32325d",
          backgroundColor: false ? "#424242" : "white",
          modalTitle: "Laila Interview",
        },
        defaults: {
          event: {
            title: "Interview",
            location: `${APP_URL}meeting/${createInterviewResponse?.data?.id}`,
            duration: 60,
          },
          appearance: {
            thank_you_redirect: `${APP_URL}thankyou`,
            company_name: "Laila",
            logo: LAILA_LOGO,
          },
          booking: {
            confirmation_emails_to_host: true,
            confirmation_emails_to_guests: true,
            calendar_invite_to_guests: true,
            min_booking_notice: 120,
            min_buffer: 0,
            additional_guests_hidden: true,
            available_days_in_future: 360,
          },
        },
      });
      dispatch(removeNylusData());
    }, 1000);
  };

  useEffect(() => {
    if (createInterviewResponse?.data?.id) hitNylus();
  }, [createInterviewResponse]);

  useEffect(() => {
    getJobStatus(selectedJob);
  }, [selectedJob]);

  const getJobStatus = (JobData) => {
    const recruiterId = user?.recruiter?.recruiterId;
    let desiredRecruiter = JobData?.approvedRecruiter?.find(
      (recruiter) => recruiter.recruiterId === recruiterId
    );
    const status =
      desiredRecruiter?.status === "active"
        ? "open"
        : desiredRecruiter?.status === "paused"
        ? "paused"
        : desiredRecruiter?.status === "reopen"
        ? "reopen"
        : "closed";
    setJobStatus(status);
  };

  return (
    <div>
      <div className="tabContainer__item--head">
        <div className="listCol__head--actions w_100">
          <div className="formRow formRow__4 m_0 w_100">
            <div className="formCol">
              <div className="formField">
                <div className="formField__group formSearch sm">
                  <span className="formField__icon">
                    <SearchSmallIcon />
                  </span>
                  <input
                    type="text"
                    className="formField__input"
                    placeholder="Search a Candidate/Role"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="formCol">
              <div className="formField">
                <FormControl
                  fullWidth
                  size="small"
                  className="formField__select"
                >
                  <Select
                    onChange={(e) => setSkillSet(e.target.value)}
                    multiple
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return (
                          <span style={{ opacity: "0.8" }}>
                            Select Skill Set
                          </span>
                        );
                      } else {
                        return selected?.join(", ");
                      }
                    }}
                    MenuProps={MenuProps}
                    displayEmpty
                    IconComponent={() => <ArrowDown />}
                    classes={{ root: "multiSelect" }}
                    value={skillSet}
                  >
                    {staticDataLocal?.data?.skillSet?.map((name, index) => {
                      return (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={skillSet?.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="formCol">
              <div className="formField">
                <FormControl
                  fullWidth
                  size="small"
                  className="formField__select"
                >
                  <Select
                    onChange={(e) => setQualification(e.target.value)}
                    multiple
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return (
                          <span style={{ opacity: "0.8" }}>
                            Select Qualification
                          </span>
                        );
                      } else {
                        return selected?.join(", ");
                      }
                    }}
                    MenuProps={MenuProps}
                    displayEmpty
                    IconComponent={() => <ArrowDown />}
                    classes={{ root: "multiSelect" }}
                    value={qualification}
                  >
                    {staticDataLocal?.data?.qualification?.map(
                      (name, index) => {
                        return (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={qualification?.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableContainer
        component={Paper}
        className="tableContainer"
        style={{ height: "calc(100dvh - 249px)" }}
      >
        <Table className="table tableClickable">
          <TableHead>
            <TableRow>
              <TableCell>S.</TableCell>
              <TableCell onClick={() => getSortByData("firstName")}>
                <SortColumn thName={"Name"} sortByASC={sortByASC} />
              </TableCell>
              <TableCell onClick={() => getSortByData("email")}>
                <SortColumn thName={"Email"} sortByASC={sortByASC} />
              </TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Resume</TableCell>
              <TableCell>Interview</TableCell>
              <TableCell>Share</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {candidateList?.length ? (
              candidateList.map((candidate, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {page !== 1 ? (page - 1) * 20 + (index + 1) : index + 1}
                    </TableCell>
                    <TableCell>
                      <div className="profile">
                        <div className="profile__initials">
                          {firstLetterCapital(candidate?.firstName[0])}
                        </div>
                        <span
                          onClick={() => showDetails(candidate)}
                          className="profile__name"
                        >
                          {firstLetterCapital(candidate?.firstName) +
                            " " +
                            firstLetterCapital(candidate?.lastName)}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="cellText">
                        <span className="cellText__dark">
                          {candidate?.email}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="cellText">
                        <span className="cellText__dark">
                          {candidate?.countryCode}{" "}
                          {formatPhoneNumber(candidate?.phoneNumber)}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="actions">
                        <Link
                          to={candidate?.resumeUrl ? candidate?.resumeUrl : "#"}
                          target="_blank"
                          className="btn btn__white"
                          onClick={(e) => {
                            if (!candidate?.resumeUrl) {
                              e.preventDefault();
                              setOpenErrorModal(true);
                            }
                          }}
                        >
                          Resume
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="cellText">
                        <button
                          onClick={() => scheduleMeeting(candidate)}
                          // className="cellText__dark cell--link"
                          className={`cellText__dark cell--link ${
                            JobStatus === "closed" || JobStatus === "paused"
                              ? "formField__disabled"
                              : ""
                          }`}
                          disabled={
                            JobStatus === "closed" || JobStatus === "paused"
                              ? true
                              : false
                          }
                        >
                          Schedule
                        </button>
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      <div className="actions">
                        <Tooltip title="Share JD" placement="top" arrow>
                          <span>
                            <button
                              onClick={() => shareResume(candidate)}
                              className={`btn btn__transparent p_0 border_0 ${
                                JobStatus === "closed" || JobStatus === "paused"
                                  ? "disabled"
                                  : ""
                              }`}
                              disabled={
                                JobStatus === "closed" || JobStatus === "paused"
                                  ? true
                                  : false
                              }
                            >
                              <ShareIcon />
                            </button>
                          </span>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell>
                  <div className="no-data floating">No Results Found!</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="pagination">
        <div className="pagination__result">
          {`${data?.count > page * 20 ? 20 : data?.count} of ${
            data?.count
          } results`}
        </div>
        {candidateList?.length ? (
          <CustomPagination
            page={page}
            totalPageCount={Math.ceil(data?.count / 20)}
            changePage={(event, value) => changePage(event, value)}
          />
        ) : (
          ""
        )}
        {data?.count ? (
          <div className="pagination__actions">
            <GoTOPage changePage={(e, value) => changePage(e, value)} />
          </div>
        ) : (
          ""
        )}
      </div>
      {downloadJDResponse?.meta?.status && (
        <SuccessModal
          closeModal={closeModal}
          sucessfullMsg={downloadJDResponse?.meta?.msg}
          Heading={"JD shared!"}
        />
      )}
      {OpenErrorModal && (
        <ErrorModal
          errorMessage={"Resume is not avaliable!"}
          closeModal={() => setOpenErrorModal(false)}
          footerVisible={true}
        />
      )}
      {(loading ||
        downloadJDResponse.loading ||
        createInterviewResponse.loading) && <Loader />}
    </div>
  );
}

export default InterviewsListing;
