import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { REOPEN_A_JOB } from "@/rtk-apis/end-points";

const ReopenAJobPost = createAsyncThunk("ReopenAJobPost", async (body) => {
  try {
    const response = await httpService.put(REOPEN_A_JOB, body);
    return response;
  } catch (error) {
    return error;
  }
});

export default ReopenAJobPost;
