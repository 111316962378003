import React from "react";

function TickIcon() {
  return (
    <svg
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1L3.8125 7.1875L1 4.375"
        stroke="#150823"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TickIcon;
