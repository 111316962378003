import React, { useState } from "react";
import CloseIconGray from "@/assets/svgs/CloseIconGray";
import { useDispatch, useSelector } from "react-redux";
import addNPSRating from "@/rtk-apis/customer/myJobs/reviewsRatings/addNPSRating";
import { GetUserDetails } from "@/utils/helper";
import ErrorModal from "@/components/modals/error-modal";
import Loader from "@/elements/Loader";
import { SuccessModal } from "@/components/modals/SuccessModal";
import { closeAddNPSRatingModal } from "@/store/slices/customer/myJobs/reviewsRatings/addNPSRatingSlice";
import { closeAddReviewsRatingsModal } from "@/store/slices/customer/myJobs/reviewsRatings/addReviewsRatingsToRecruiterSlice";

const ThankyouReviewRatingModal = ({
  openModal,
  recruiterDetails,
  selectedJob,
  setOpenThankyouModal,
  setOpenReviewRatingsModal,
  refreshRecruiterDetails,
  setRefreshRecruiterDetails,
}) => {
  const dispatch = useDispatch();
  const loggedInUser = GetUserDetails();
  const [RatingValue, setRatingValue] = useState("");
  const [Notes, setNotes] = useState("");
  const [OpenSuccessfullModal, setOpenSuccessfullModal] = useState(false);

  const { meta, loading } = useSelector((globalState) => {
    return globalState.addNPSRatingResponse;
  });
  const Ratings = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleRatingClick = (value) => {
    setRatingValue(value);
  };

  const handleTextAreaChange = (e) => {
    setNotes(e?.target?.value);
  };

  const handleThankyouPageSubmit = async () => {
    let body = {
      recruiterId: recruiterDetails?.recruiterId,
      companyId: loggedInUser?.customer?.companyId,
      employeeId: loggedInUser?.customer?.employeeId,
      jobId: selectedJob?.jobId,
      npsRating: RatingValue,
      npsNotes: Notes,
    };
    await dispatch(addNPSRating(body));
    setRefreshRecruiterDetails(!refreshRecruiterDetails);
  };

  const handleNoThankyou = () => {
    setOpenThankyouModal(false);
    setOpenSuccessfullModal(true);
    setRefreshRecruiterDetails(!refreshRecruiterDetails);
    dispatch(closeAddReviewsRatingsModal());
  };

  const closeRTKModal = () => {
    dispatch(closeAddNPSRatingModal());
    dispatch(closeAddReviewsRatingsModal());
    // setOpenThankyouModal(false);
    // setOpenReviewRatingsModal(false);
    setOpenSuccessfullModal(false);
  };

  const closeRTKSuccessModal = () => {
    dispatch(closeAddNPSRatingModal());
    dispatch(closeAddReviewsRatingsModal());
    setOpenThankyouModal(false);
    setOpenReviewRatingsModal(false);
    setOpenSuccessfullModal(false);
  };
  return (
    <>
      <div
        className={`modal thankYouReviewRating ${
          openModal ? "modal--open" : ""
        }`}
      >
        <div className="modal__dialog">
          <div className="modal__content modal__content--lg">
            <div className="modal__body">
              <div className="modal__header">
                <div className="modal__header--logo">
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M58.6666 29.5465V31.9998C58.6633 37.7503 56.8013 43.3456 53.3581 47.9513C49.915 52.5571 45.0753 55.9264 39.5609 57.5569C34.0464 59.1873 28.1526 58.9915 22.7585 56.9987C17.3644 55.0058 12.759 51.3227 9.62915 46.4986C6.4993 41.6746 5.0127 35.968 5.39106 30.23C5.76942 24.492 7.99247 19.03 11.7287 14.6587C15.4649 10.2873 20.514 7.24082 26.1231 5.97352C31.7322 4.70623 37.6006 5.28603 42.8533 7.62647M58.6666 10.6665L31.9999 37.3598L23.9999 29.3598"
                      stroke="#28A0E8"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <h2 className="modal__header--title">Thank You!</h2>
                <p className="para">
                  {" "}
                  Your Review has been successfully submitted.{" "}
                </p>
              </div>

              <div className="messageBox">
                <h3 className="messageBox__title">
                  On a scale of 0 to 10, how likely are you to recommend
                  <br />
                  Laila to a friend or colleague?
                </h3>
                <div className="ratingInfo">
                  <div className="ratingCount">
                    {Ratings?.map((item, index) => {
                      return (
                        <div
                          className={`ratingCount__value ${
                            index <= RatingValue ? "selected" : ""
                          }`}
                          key={index}
                          onClick={() => handleRatingClick(item)}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                  <div className="ratingCountInfo">
                    <div className="ratingCountInfo__item">
                      Not likely at all
                    </div>
                    <div className="ratingCountInfo__item">
                      Extremely likely
                    </div>
                  </div>
                </div>
                <div className="formField">
                  <div className="fromField__label">
                    Note: We value your feedback! If you have any private
                    comments regarding your recruiter experience or how Laila
                    performed, please share them here. Your comments will not be
                    shared with the recruiter and will only be visible to
                    management team at Laila.
                  </div>
                  <div className="feedbackCommentBox">
                    <textarea
                      className="formField__textarea"
                      rows={3}
                      value={Notes}
                      onChange={(e) => handleTextAreaChange(e)}
                      placeholder="Add your comments..."
                      maxLength={500}
                    ></textarea>
                    <span className="feedbackCommentBox__charactersText">
                      {Notes?.length}/500 characters
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button
                  type="button"
                  className="btn btn__white"
                  onClick={handleNoThankyou}
                >
                  No, Thanks
                </button>
                <button
                  type="button"
                  className="btn btn__primary"
                  disabled={RatingValue ? false : true}
                  onClick={handleThankyouPageSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      {meta?.status && (
        <SuccessModal
          closeModal={closeRTKSuccessModal}
          sucessfullMsg={
            "Review / rating has been submitted successfully for the recruiter."
          }
          Heading={"Review Added!"}
        />
      )}
      {OpenSuccessfullModal && (
        <SuccessModal
          closeModal={() => {
            dispatch(closeAddReviewsRatingsModal());
            setOpenSuccessfullModal(false);
            setOpenReviewRatingsModal(false);
          }}
          sucessfullMsg={
            "Review / rating has been submitted successfully for the recruiter."
          }
          Heading={"Review Added!"}
        />
      )}
      {meta?.status === false && (
        <ErrorModal
          errorMessage={meta?.msg}
          closeModal={closeRTKModal}
          footerVisible={true}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default ThankyouReviewRatingModal;
