import React, { useState } from "react";
import EditIcon from "@/assets/svgs/EditIcon";
import OpenEye from "@/assets/svgs/OpenEye";
import CloseEye from "@/assets/svgs/CloseEye";
import moment from "moment";
import { maskSSNnumber, decryptData } from "@/utils/helper";

const TaxPayerIdentificationView = ({
  changeTaxPayerIdentificationView,
  taxInfoStatus,
  taxDetails = {},
}) => {
  const [showNumbers, setShowNumbers] = useState(false);

  const changeViewType = () => {
    setShowNumbers(!showNumbers);
  };

  const taxFormString = Object.keys(taxDetails).length ? taxDetails?.taxPayerCitizenType === "none-us-person" ? "W-8" : "W-9" : "W-9 or W-8";

  return (
    <section
      className={`details__section ${
        taxInfoStatus === false ? "sectionDisable" : ""
      }`}
    >
      <h4 className="details__section--title">
        <span className="titleWithMessage">
          Taxpayer Identification
          {Object.keys(taxDetails).length ? (
            <span className="successMessage">Completed</span>
          ) : (
            <span className="errorMessage">Not Completed</span>
          )}
        </span>
        {taxInfoStatus !== false ? (
          <button
            type="button"
            className="btn btn__white btn--sm"
            onClick={() => changeTaxPayerIdentificationView(true)}
          >
            <EditIcon />
          </button>
        ): ""}
      </h4>
      <p>
        Your taxpayer identification information will be included as {taxFormString} form.
      </p>
      {Object.keys(taxDetails).length ? (
        <div className="typerinfolist">
          <div className="typerinfolist__item">
            <h5>Name of entity/individual</h5>
            <p>{taxDetails?.taxPayerName}</p>
          </div>
          <div className="typerinfolist__item">
            <h5>Business Name</h5>
            <p>{taxDetails?.businessName}</p>
          </div>
          <div className="typerinfolist__item">
            <h5>Federal tax classification</h5>
            <p>{taxDetails?.taxClassification?.value}</p>
          </div>
          {taxDetails?.taxPayerCitizenType === "none-us-person" ? (
            <>
              <div className="typerinfolist__item">
                <h5>Country of {taxDetails?.taxClassification?.value === "Individual" ? "citizenship" : "incorporation"} </h5>
                <div className="typerinfolist__item__passwordText">
                  <p>{taxDetails?.citizenShipCountry?.name}</p>
                </div>
              </div>
              {taxDetails?.dateOfBirth ? (
                <div className="typerinfolist__item">
                  <h5>Date of Birth</h5>
                  <div className="typerinfolist__item__passwordText">
                    <p>
                      {moment(taxDetails?.dateOfBirth).format("MM/DD/YYYY")}
                    </p>
                  </div>
                </div>
              ) : ""}
            </>
          ) : (
            <div className="typerinfolist__item">
              <h5>SSN/EIN #</h5>
              <div className="typerinfolist__item__passwordText">
                {showNumbers ? (
                  <>
                    <p>
                      {decryptData(taxDetails?.idenficationNumber)}
                    </p>
                    <button
                      className="btn btn__primary btn__outlined"
                      onClick={() => changeViewType()}
                    >
                      <CloseEye />
                    </button>
                  </>
                ) : (
                  <>
                    <p>
                      {maskSSNnumber(
                        taxDetails?.idenficationNumber,
                        taxDetails?.idenficationNumberType
                      )}
                    </p>
                    <button
                      className="btn btn__primary btn__outlined"
                      onClick={() => changeViewType()}
                    >
                      <OpenEye />
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      ) : ""}
    </section>
  );
};

export default TaxPayerIdentificationView;
