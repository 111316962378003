import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '@/rtk-apis/axios-config/http-service';
import { RECRUITER_DASHBOARD } from '@/rtk-apis/end-points';

const recruiterDashboard = createAsyncThunk('recruiterDashboard', async (recruiterId) => {
  try {
    const response = await httpService.get(`${RECRUITER_DASHBOARD}${recruiterId}`, {});
    return response;
  } catch (error) {
    return error;
  }
});

export default recruiterDashboard;
