import React, { useEffect, useRef } from "react";

export const SuccessModal = ({ closeModal, sucessfullMsg, Heading }) => {
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [closeModal]);

  return (
    <div className={`modal modalSuccessfull modal--open`}>
      <div className="modal__dialog">
        <div className="modal__content">
          <div className="modal__header">
            <div className="modal__header--logo">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M58.6666 29.5465V31.9998C58.6633 37.7503 56.8013 43.3456 53.3581 47.9513C49.915 52.5571 45.0753 55.9264 39.5609 57.5569C34.0464 59.1873 28.1526 58.9915 22.7585 56.9987C17.3644 55.0058 12.759 51.3227 9.62915 46.4986C6.4993 41.6746 5.0127 35.968 5.39106 30.23C5.76942 24.492 7.99247 19.03 11.7287 14.6587C15.4649 10.2873 20.514 7.24082 26.1231 5.97352C31.7322 4.70623 37.6006 5.28603 42.8533 7.62647M58.6666 10.6665L31.9999 37.3598L23.9999 29.3598"
                  stroke="#28A0E8"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h2 className="modal__header--title">{Heading ? Heading : ""}</h2>
          </div>
          <div className="modal__body">
            <h4 className="promptMessage">
              {sucessfullMsg ? sucessfullMsg : ""}
            </h4>
          </div>
          <div className="modal__footer">
            <div className="btn__group">
              <button
                type="button"
                className="btn btn__white"
                onClick={() => closeModal()}
              >
                Got it
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal__backdrop"></div>
    </div>
  );
};
